import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  Input,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { TableContainer } from '@mui/material'
import { useSelector } from 'react-redux'
import { nanoid } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import {
  invoiceApproved,
  invoiceSubmitted,
  refrigerantService,
  refrigerantWeight
} from '../../lib/Constants'
import LoadingBox from './LoadingBox'
import { equalObjects } from '../../lib/Global'
import { ConfirmDialog } from '../form/ConfirmDialog'
import { TextInput } from '../accountsettings/TextInput'
import GlobalSelect from '../form/Select'

const useStyles = makeStyles(theme => ({
  summaryLabel: {
    backgroundColor: theme.colors.invoiceColors.summary_bg,
    fontSize: '12px',
    height: '22px',
    paddingTop: '3px',
    paddingBottom: '3px',
    color: theme.colors.invoiceColors.dark_grey,
    fontWeight: '500',
    borderBottom: '0px'
  },
  tableHeader: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.invoiceColors.table_text,
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  centerHeader: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.invoiceColors.table_text,
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders,
    textAlign: 'center'
  },
  firstHeader: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.invoiceColors.table_text,
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingLeft: '27px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  tableRow: {
    height: '54px'
  },
  tableItem: {
    fontSize: '12px',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  tableRemoveItem: {
    fontSize: '12px',
    width: '12px',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  tableDescItem: {
    fontSize: '12px',
    width: '52%',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  deleteRow: {
    color: theme.colors.invoiceColors.disabled_grey,
    size: '12px'
  },
  selectItem: {
    fontSize: '12px',
    minWidth: '130px',
    maxWidth: '130px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  firstItem: {
    fontSize: '12px',
    minWidth: '130px',
    maxWidth: '130px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders,
    paddingLeft: '27px'
  },
  innerSelectItem: {
    fontSize: '12px',
    width: '140px'
  },
  selectItemButton: {
    fontSize: '12px',
    backgroundColor: theme.colors.invoiceColors.blue_button,
    color: 'white',
    '&.Mui-disabled': {
      color: theme.colors.invoiceColors.disabled_grey,
      backgroundColor: 'white'
    }
  },
  descriptionItem: {
    fontSize: '12px',
    width: '100%',
    paddingLeft: '0px'
  },
  inputNumericItem: {
    fontSize: '12px',
    minWidth: '70px',
    textAlign: 'center'
  },
  inputQtyItem: {
    fontSize: '12px',
    minWidth: '50px',
    textAlign: 'center'
  },
  inputAmountItem: {
    fontSize: '12px',
    minWidth: '95px',
    textAlign: 'center'
  },
  addButton: {
    color: theme.colors.invoiceColors.blue_button,
    backgroundColor: 'white',
    marginTop: '17px',
    marginBottom: '17px',
    fontSize: '15px',
    '&.Mui-disabled': {
      color: theme.colors.invoiceColors.disabled_grey,
      backgroundColor: 'white'
    }
  },
  addText: {
    color: theme.colors.invoiceColors.dark_grey,
    fontSize: '12px'
  },
  select: { fontSize: '12px', width: '120px' },
  blueColor: {
    '& .MuiSelect-root': {
      color: `${theme.colors.iconBlue} !important`
    }
  },
  dialogMessage: {
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'left'
  },
  dialogWrapper: {
    width: '500px',
    padding: '20px'
  },
  serviceAndWeight: {
    display: 'flex',
    gap: '20px'
  },
  modalRow: {
    display: 'flex',
    flexDirection: 'column'
  },
  modalLabel: {
    fontSize: '12px',
    fontWeight: '700',
    minWidth: '120px',
    textAlign: 'left'
  },
  modalInput: {
    flex: 1,
    width: '100%'
  },
  weightInputs: {
    display: 'flex',
    gap: '8px'
  },
  weightInput: {
    width: '120px'
  },
  editIcon: {
    color: theme.colors.blue,
    fontSize: '14px'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  modalField: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  serviceField: {
    flex: 1
  },
  weightField: {
    flex: 1
  },
  acceptButton: {
    '&.MuiButton-root.Mui-disabled': {
      backgroundColor: theme.colors.grey_2,
      color: theme.colors.text_grey
    }
  },
  refrigerantButtonsContainer: {
    padding: '0px 35px'
  }
}))

function createData(
  id,
  editable,
  item,
  description,
  trade,
  rate,
  // uncomment if needed
  // tax,
  quantity,
  amount,
  factor
) {
  return {
    id,
    editable,
    item,
    description,
    trade,
    rate,
    // uncomment if needed
    // tax,
    quantity,
    amount,
    factor
  }
}

function CustomSelect(props) {
  const classes = useStyles()
  const {
    updateItems,
    items,
    rowId,
    value,
    setDisableAddItem,
    disabled,
    setEnableAutoSave
  } = props
  const [itemSelected, setItemSelected] = useState(value)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState(null)

  const refrigerantLineItem = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.invoices?.refrigerant_line_item
  )

  const handleSelect = event => {
    setItemSelected(event.target.value)
    if (event.target.value === t('invoices.items.refrigerant')) {
      props.onRefrigerantSelect(rowId)
    } else {
      updateItemAndData(event.target.value)
    }
    if (props.onSelectChange) {
      props.onSelectChange(event.target.value)
    }
  }

  const updateItemAndData = selectedValue => {
    // find index
    const objIdx = items.findIndex(item => item.id === rowId)
    // update object
    const newItem = { ...items[objIdx] }
    // set discount factor
    newItem.factor = selectedValue === 'Discount' ? -1 : 1
    newItem.item = selectedValue
    items[objIdx] = newItem

    updateItems([...items])
    setEnableAutoSave(false)
    setDisableAddItem(true)
  }

  useEffect(() => {
    setItemSelected(value)
  }, [value, items])

  return (
    <FormControl>
      <Select
        id="select-small"
        onChange={handleSelect}
        className={`${classes.select} ${
          itemSelected?.toString() === '0' ? classes.blueColor : null
        }`}
        value={itemSelected}
        disabled={disabled}
        onOpen={event => {
          setAnchorEl(event.currentTarget)
        }}
        onClose={() => setAnchorEl(null)}
        disableUnderline
        IconComponent={() => <></>}
        MenuProps={{
          anchorEl: anchorEl,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        <MenuItem
          value="0"
          disabled
          classes={{ root: classes.innerSelectItem }}
          style={anchorEl ? { display: 'none' } : null}
        >
          {t('invoices.select_one')}
        </MenuItem>
        <MenuItem
          value={t('invoices.items.other')}
          classes={{ root: classes.innerSelectItem }}
        >
          {t('invoices.items.other')}
        </MenuItem>
        <MenuItem
          value={t('invoices.items.discount')}
          classes={{ root: classes.innerSelectItem }}
        >
          {t('invoices.items.discount')}
        </MenuItem>
        <MenuItem
          value={t('invoices.items.management')}
          classes={{ root: classes.innerSelectItem }}
        >
          {t('invoices.items.management')}
        </MenuItem>
        <MenuItem
          value={t('invoices.items.markup')}
          classes={{ root: classes.innerSelectItem }}
        >
          {t('invoices.items.markup')}
        </MenuItem>
        <MenuItem
          value={t('invoices.items.overhead')}
          classes={{ root: classes.innerSelectItem }}
        >
          {t('invoices.items.overhead')}
        </MenuItem>
        <MenuItem
          value={t('invoices.items.rental')}
          classes={{ root: classes.innerSelectItem }}
        >
          {t('invoices.items.rental')}
        </MenuItem>
        <MenuItem
          value={t('invoices.items.shipping')}
          classes={{ root: classes.innerSelectItem }}
        >
          {t('invoices.items.shipping')}
        </MenuItem>
        {refrigerantLineItem && (
          <MenuItem
            value={t('invoices.items.refrigerant')}
            classes={{ root: classes.innerSelectItem }}
          >
            {t('invoices.items.refrigerant')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )
}

function EnhancedTable(props) {
  const {
    itemTag,
    tradeTag,
    addItemTag,
    updateItems,
    items,
    status,
    enableAutoSave,
    setEnableAutoSave,
    loading,
    scheduled,
    disableButtons,
    setDisableButtons,
    setDisableSubmitButton
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [disableAddItem, setDisableAddItem] = useState(false)
  const [disableRemoveItem, setdisableRemoveItem] = useState(false)
  const [editEnabled, setEditEnabled] = useState(true)
  const [editInProgress, setEditInProgress] = useState(false)
  const [open, setOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [currentEditingId, setCurrentEditingId] = useState(null)
  const [currentDescription, setCurrentDescription] = useState('')
  const [refrigerantData, setRefrigerantData] = useState({
    type: '',
    service: refrigerantService[0].value,
    pounds: '',
    ounces: ''
  })
  const [allFieldsFilled, setAllFieldsFilled] = useState(false)
  const rows = [
    createData(
      nanoid(),
      true,
      itemTag === t('invoices.items.other') ? '0' : itemTag,
      '',
      tradeTag,
      0,
      0,
      0,
      0,
      1
    )
  ]
  useEffect(() => {
    if (!items?.length > 0) {
      updateItems(rows)
      setdisableRemoveItem(false)
    }
    if (
      status === t('invoices.in_states.approved').toLowerCase() ||
      status === t('invoices.in_states.submitted').toLowerCase() ||
      status === t('invoices.in_states.paid').toLowerCase() ||
      status === t('invoices.in_states.void').toLowerCase() ||
      status === t('invoices.in_states.hold').toLowerCase()
    ) {
      setEditEnabled(false)
      setDisableAddItem(true)
      setdisableRemoveItem(true)
    }
    if (items?.length === 1) {
      items[0].amount !== 0 ? setDisableAddItem(false) : setDisableAddItem(true)
    }
    if (items?.length === 0) {
      setdisableRemoveItem(true)
    }
  }, [items, status])

  const handleAddItem = () => {
    setDisableAddItem(true)
    setdisableRemoveItem(false)
    setEnableAutoSave(false)
    if (items?.length > 0) {
      updateItems([
        ...items,
        createData(
          nanoid(),
          true,
          itemTag === t('invoices.items.other') ? 0 : itemTag,
          '',
          tradeTag,
          0,
          0,
          0,
          0,
          1
        )
      ])
    }
  }
  let rawValue = 0

  const columns = [
    {
      field: 'item',
      headerName: t('invoices.column_names.item')
    },
    {
      field: 'description',
      headerName: t('invoices.column_names.description')
    },
    {
      field: 'trade',
      headerName: t('invoices.column_names.trade')
    },
    {
      field: 'rate',
      headerName: t('invoices.column_names.rate')
    },
    // uncomment if needed
    // {
    //   field: 'tax',
    //   headerName: t('invoices.column_names.tax')
    // },
    {
      field: 'quantity',
      headerName: t('invoices.column_names.qty')
    },
    {
      field: 'amount',
      headerName: t('invoices.column_names.item_amount')
    },
    {
      field: 'delete',
      headerName: ''
    }
  ]

  const handleItemChange = (event, id) => {
    event.preventDefault()
    setEditInProgress(true)
    setEnableAutoSave(false)
    const fieldName = event.target.getAttribute('name')
    // get unformatted value from component
    let fieldValue = fieldName === 'description' ? event.target.value : rawValue

    // Enforce description 255 character limit
    if (fieldName === 'description' && fieldValue.length > 255) {
      fieldValue = fieldValue.substring(0, 255)
    }
    // find index
    const objIdx = items.findIndex(item => item.id === id)
    // update object
    const newItem = { ...items[objIdx] }
    // calculate amount from rate, tax and qty
    if (
      fieldName === 'rate' ||
      // uncomment if needed
      // fieldName === 'tax' ||
      fieldName === 'quantity'
    ) {
      // If the type of the value is numeric it means there were more than 2 decimals
      newItem[fieldName] =
        typeof fieldValue === 'number'
          ? newItem[fieldName]
          : !fieldValue || Number(fieldValue) === 0
          ? 0
          : Number(fieldValue)

      newItem.factor = newItem.item === 'Discount' ? -1 : 1
      const amountPlusTax = Number(newItem.rate)
      // uncomment if needed
      //  + (newItem.rate * newItem.tax) / 100
      const totalAmount = amountPlusTax * newItem.quantity * newItem.factor
      newItem.amount = totalAmount
    } else {
      newItem[fieldName] = fieldValue
    }

    // update items
    items[objIdx] = newItem
    updateItems([...items])
    if (newItem.amount !== 0) {
      setDisableAddItem(false)
      setdisableRemoveItem(false)
    } else {
      // disable autosave items
      setEnableAutoSave(false)
      setDisableAddItem(true)
    }
  }

  const [oldItem, setOldItem] = useState()
  const handleFocusLost = (event, id) => {
    // find index
    const objIdx = items.findIndex(item => item.id === id)
    const newItem = { ...items[objIdx] }
    // save Items
    if (
      !enableAutoSave &&
      newItem.amount !== 0 &&
      editInProgress &&
      !equalObjects(oldItem, newItem)
    ) {
      setEnableAutoSave(true)
      setEditInProgress(false)
    }
    if (equalObjects(oldItem, newItem)) {
      setEditInProgress(false)
      setDisableSubmitButton(false)
    }
  }

  const handleFocus = (event, id) => {
    event.target.select()
    const objIdx = items.findIndex(item => item.id === id)
    const currentItem = { ...items[objIdx] }
    setOldItem(currentItem)
    setEditInProgress(true)
    setDisableSubmitButton(true)
  }

  const handleRemoveItem = (event, id) => {
    const data = items.filter(item => item.id !== id)
    updateItems(data)
    setDisableButtons(true)
    setEnableAutoSave(true)
  }

  const handleUpdate = item => {
    setEnableAutoSave(false)
    updateItems([...item])
  }

  const parseRefrigerantDescription = description => {
    const [type = '', service = '', weight = ''] = description
      .split('|')
      .map(part => part.trim())

    const [pounds = '', ounces = ''] = weight
      .split(refrigerantWeight.lbs)
      .map(part => part.replace(refrigerantWeight.oz, '').trim())

    return { type, service, pounds, ounces }
  }

  const handleOpenModal = id => {
    const item = items.find(item => item.id === id)
    if (item && item.description) {
      const { type, service, pounds, ounces } = parseRefrigerantDescription(
        item.description
      )
      setRefrigerantData({ type, service, pounds, ounces })
      setCurrentDescription(item.description)
    }
    setCurrentEditingId(id)
    setOpen(true)
    setIsEditing(true)
  }

  const handleRefrigerantDataChange = (field, value) => {
    setRefrigerantData(prev => {
      const newData = { ...prev, [field]: value.trim() }
      const { type, service, pounds, ounces } = newData
      const newDescription = `${type} | ${service}${
        pounds || ounces ? ` | ${pounds} lbs ${ounces} oz` : ''
      }`
      setCurrentDescription(newDescription)
      // Check if all fields are filled
      const areAllFieldsFilled = type && service && pounds && ounces
      setAllFieldsFilled(areAllFieldsFilled)
      return newData
    })
  }

  const handleDialogConfirm = () => {
    if (allFieldsFilled) {
      const updatedItems = items.map(item => {
        if (item.id === currentEditingId) {
          return {
            ...item,
            item: t('invoices.items.refrigerant'),
            description: currentDescription
          }
        }
        return item
      })

      updateItems(updatedItems)
      setEnableAutoSave(true)
      setOpen(false)
      setIsEditing(false)
      setCurrentEditingId(null)
    }
  }

  const handleDialogCancel = () => {
    const updatedItems = items.map(item => {
      if (item.id === currentEditingId) {
        return {
          ...item,
          item: t('invoices.items.other'),
          description: ''
        }
      }
      return item
    })

    updateItems(updatedItems)
    setOpen(false)
    setIsEditing(false)
    setCurrentEditingId(null)
  }

  const refrigerantDialog = () => {
    useEffect(() => {
      if (isEditing && currentEditingId) {
        const item = items.find(item => item.id === currentEditingId)
        if (item && item.description) {
          const parsedData = parseRefrigerantDescription(item.description)
          setRefrigerantData(parsedData)
          // Check if all fields are filled when editing
          const { type, service, pounds, ounces } = parsedData
          setAllFieldsFilled(type && service && pounds && ounces)
        }
      }
    }, [isEditing, currentEditingId, items])

    return (
      <div className={classes.dialogWrapper}>
        <div className={classes.modalContent}>
          <div className={classes.modalField}>
            <Typography className={classes.dialogMessage}>
              {t('invoices.refrigerant_item.type')}
            </Typography>
            <TextInput
              id="refrigerant-type"
              name="refrigerant-type"
              label="Enter refrigerant type.. (ie. R22)"
              value={refrigerantData.type}
              handleChange={e =>
                handleRefrigerantDataChange('type', e.target.value)
              }
              required
            />
          </div>
          <div className={classes.serviceAndWeight}>
            <div className={`${classes.modalField} ${classes.serviceField}`}>
              <Typography className={classes.modalLabel}>
                {' '}
                {t('invoices.refrigerant_item.service')}
              </Typography>
              <GlobalSelect
                options={refrigerantService}
                onChange={value =>
                  handleRefrigerantDataChange('service', value)
                }
                field="service"
                value={refrigerantData.service}
                displayvalue
                required
              />
            </div>
            <div className={`${classes.modalField} ${classes.weightField}`}>
              <Typography className={classes.modalLabel}>
                {t('invoices.refrigerant_item.weight')}
              </Typography>
              <div className={classes.weightInputs}>
                <TextInput
                  id="pounds"
                  name="pounds"
                  label={refrigerantWeight.lbs}
                  className={classes.weightInput}
                  value={refrigerantData.pounds}
                  handleChange={e =>
                    handleRefrigerantDataChange('pounds', e.target.value)
                  }
                  required
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
                <TextInput
                  id="ounces"
                  name="ounces"
                  label={refrigerantWeight.oz}
                  className={classes.weightInput}
                  value={refrigerantData.ounces}
                  handleChange={e =>
                    handleRefrigerantDataChange('ounces', e.target.value)
                  }
                  required
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault()
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={8} classes={{ root: classes.summaryLabel }}>
              {loading ? '' : `${itemTag} ${t('invoices.summary')}`}
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={column.field + index}
                classes={{
                  root:
                    index === 0
                      ? classes.firstHeader
                      : index === 3 || index === 4 || index === 5
                      ? classes.centerHeader
                      : classes.tableHeader
                }}
              >
                {loading ? (
                  <LoadingBox width="100%" height="20px" />
                ) : (
                  column.headerName
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((row, index) => (
            <TableRow
              key={row.id + '-' + index}
              classes={{ root: classes.tableRow }}
            >
              <TableCell
                classes={{
                  root: index === 0 ? classes.firstItem : classes.selectItem
                }}
              >
                {itemTag === t('invoices.items.other') ? (
                  <CustomSelect
                    updateItems={handleUpdate}
                    items={items}
                    rowId={row.id}
                    value={row.item}
                    setdisableRemoveItem={setdisableRemoveItem}
                    setDisableAddItem={setDisableAddItem}
                    disabled={!editEnabled || scheduled || row?.vendor_edited}
                    setEnableAutoSave={setEnableAutoSave}
                    onRefrigerantSelect={handleOpenModal}
                  ></CustomSelect>
                ) : (
                  row.item
                )}
              </TableCell>
              <TableCell classes={{ root: classes.tableDescItem }}>
                <Box display="flex" alignItems="center">
                  <Input
                    classes={{ root: classes.descriptionItem }}
                    placeholder={t('invoices.description_placeholder')}
                    name="description"
                    multiline
                    maxRows={3}
                    onChange={event => handleItemChange(event, row.id)}
                    onBlur={event => handleFocusLost(event, row.id)}
                    onFocus={event => handleFocus(event, row.id)}
                    disableUnderline
                    value={row.description}
                    disabled={
                      !editEnabled ||
                      scheduled ||
                      row?.vendor_edited ||
                      row.item === t('invoices.items.refrigerant')
                    }
                  />
                  {row.item === t('invoices.items.refrigerant') &&
                    row.description &&
                    status !== invoiceApproved &&
                    status !== invoiceSubmitted && (
                      <IconButton
                        onClick={() => handleOpenModal(row.id)}
                        size="small"
                      >
                        <FontAwesomeIcon
                          icon="fa-light fa-pencil"
                          className={classes.editIcon}
                        />
                      </IconButton>
                    )}
                </Box>
              </TableCell>
              <TableCell classes={{ root: classes.tableItem }}>
                {row.trade}
              </TableCell>
              <TableCell classes={{ root: classes.tableItem }}>
                <NumberFormat
                  name="rate"
                  thousandSeparator
                  customInput={Input}
                  disableUnderline
                  prefix="$"
                  allowEmptyFormatting
                  decimalScale={2}
                  decimalSeparator="."
                  allowNegative={false}
                  value={row.rate}
                  onValueChange={values => (rawValue = values.value)}
                  onChange={event => handleItemChange(event, row.id)}
                  onBlur={event => handleFocusLost(event, row.id)}
                  onFocus={event => handleFocus(event, row.id)}
                  classes={{
                    input: classes.inputNumericItem
                  }}
                  disabled={
                    !editEnabled ||
                    scheduled ||
                    (row?.vendor_edited && row?.rate !== 0)
                  }
                  inputProps={{ maxLength: 11 }}
                />
              </TableCell>
              {/* uncomment if needed */}
              {/* <TableCell classes={{ root: classes.tableItem }}>
                  <NumberFormat
                    name="tax"
                    customInput={Input}
                    disableUnderline
                    decimalScale={0}
                    suffix="%"
                    value={row.tax}
                    onValueChange={values => (rawValue = values.value)}
                    onChange={event => handleItemChange(event, row.id)}
                    onBlur={event => handleFocusLost(event, row.id)}
                    onFocus={event => handleFocus(event, row.id)}
                    classes={{
                      input: classes.inputNumericItem
                    }}
                    disabled={!editEnabled || scheduled}
                  />
              </TableCell> */}
              <TableCell classes={{ root: classes.tableItem }}>
                <NumberFormat
                  name="quantity"
                  thousandSeparator
                  allowNegative={false}
                  customInput={Input}
                  disableUnderline
                  value={row.quantity}
                  onValueChange={values => (rawValue = values.value)}
                  onChange={event => handleItemChange(event, row.id)}
                  onBlur={event => handleFocusLost(event, row.id)}
                  onFocus={event => handleFocus(event, row.id)}
                  classes={{
                    input: classes.inputQtyItem
                  }}
                  disabled={
                    !editEnabled ||
                    scheduled ||
                    (row?.vendor_edited && row?.quantity !== 0)
                  }
                  inputProps={{ maxLength: 4 }}
                />
              </TableCell>
              <TableCell classes={{ root: classes.tableItem }}>
                <NumberFormat
                  name="amount"
                  thousandSeparator
                  customInput={Input}
                  disableUnderline
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={2}
                  value={row.amount}
                  disabled
                  classes={{
                    input: classes.inputAmountItem
                  }}
                />
              </TableCell>
              <TableCell classes={{ root: classes.tableRemoveItem }}>
                {status !== invoiceApproved &&
                status !== invoiceSubmitted &&
                !disableRemoveItem &&
                !scheduled &&
                !row?.vendor_edited ? (
                  <IconButton
                    onClick={event => handleRemoveItem(event, row.id)}
                    size="small"
                    disabled={disableButtons}
                  >
                    <FontAwesomeIcon
                      icon="fa-regular fa-circle-xmark"
                      style={{ fontSize: '12px' }}
                    />
                  </IconButton>
                ) : (
                  <div style={{ width: '18px' }} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {loading ? (
        <LoadingBox width="20%" height="20px" margin="20px 0px" />
      ) : status !== t('invoices.in_states.approved').toLowerCase() &&
        status !== t('invoices.in_states.submitted').toLowerCase() &&
        status !== t('invoices.in_states.paid').toLowerCase() &&
        status !== t('invoices.in_states.hold').toLowerCase() &&
        status !== t('invoices.in_states.void').toLowerCase() &&
        !scheduled ? (
        <Grid container alignItems="center">
          <Grid item>
            <IconButton
              onClick={event => handleAddItem(event)}
              classes={{ root: classes.addButton }}
              disabled={disableAddItem || disableButtons}
              size="small"
            >
              <FontAwesomeIcon
                icon="fa-solid fa-circle-plus"
                classes={{ root: classes.addButton }}
              />
            </IconButton>
          </Grid>
          <Grid item>
            <Box classes={{ root: classes.addText }}>{addItemTag}</Box>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title={t('invoices.items.refrigerant')}
        message={refrigerantDialog()}
        acceptlabel={'Done'}
        cancellabel={'Cancel'}
        handleAccept={handleDialogConfirm}
        handleCancel={handleDialogCancel}
        customAcceptButtonStyle={classes.acceptButton}
        disableAccept={!allFieldsFilled}
        customClasses={{
          buttonsContainer: classes.refrigerantButtonsContainer
        }}
      />
    </TableContainer>
  )
}

export const InvoiceDetailComponent = props => {
  const {
    itemTag,
    tradeTag,
    addItemTag,
    updateItems,
    items,
    status,
    enableAutoSave,
    setEnableAutoSave,
    loading,
    scheduled,
    disableButtons,
    setDisableButtons,
    setDisableSubmitButton
  } = props
  return (
    <EnhancedTable
      itemTag={itemTag}
      tradeTag={tradeTag}
      addItemTag={addItemTag}
      updateItems={updateItems}
      items={items}
      status={status}
      enableAutoSave={enableAutoSave}
      setEnableAutoSave={setEnableAutoSave}
      loading={loading}
      scheduled={scheduled}
      disableButtons={disableButtons}
      setDisableButtons={setDisableButtons}
      setDisableSubmitButton={setDisableSubmitButton}
    />
  )
}
