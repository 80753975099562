export const workOrders = () => {
  return {
    search_placeholder: 'Search',
    export_selected: 'Download',
    download_selected: 'Download selected',
    download_all: 'Download all',
    filter_counts: 'Show Counts',
    filter_sites: 'Use My Sites',
    advanced_button: 'Advanced Search',
    reset_button: 'Clear All Filters',
    counts: 'Counts',
    email: 'Email',
    cancel: 'Cancel',
    data_export: 'Email Report',
    open_invoice: 'Open Invoice',
    export_message:
      'The report will be sent to user_email_address.  Unfortunately, it exceeds the limit to be downloaded here. Pease allow a few minutes for this report to be sent and be sure to check your spam/junk folders.',
    date_ranges: {
      today: 'Today',
      yesterday: 'Yesterday',
      this_month: 'This Month',
      last_month: 'Last Month',
      last_two_months: 'Last Two Months',
      last_three_months: 'Last Three Months',
      last_six_months: 'Last Six Months'
    },
    wo_states: {
      open: 'Open',
      active: 'In Progress',
      returning: 'Returning',
      complete: 'Completed',
      completed: 'Completed',
      completed_warning: 'Completed',
      cancelled: 'Cancelled',
      expired: 'Expired',
      no_status: 'No Status',
      incomplete: 'Incomplete',
      in_progress: 'In Progress',
      no_work_order: 'No Work Order'
    },
    column_names: {
      client_name: 'Client Name',
      site_name: 'Site Name',
      trade: 'Trade',
      service: 'Service',
      won: 'Work Order #',
      opendate: 'Start Date',
      duedate: 'End Date',
      wostat: 'WO Status',
      invoices: 'Invoice Status',
      priority: 'Priority',
      gc_scope: 'Total GC Scope',
      general_notes: 'Description',
      nte: 'NTE',
      client_tracking_number: 'Client Tracking #'
    },
    advanced_fields: {
      state: 'State',
      city: 'City',
      site: 'Site name',
      wonum: 'Work order number',
      open_date: 'Work order open date',
      wodate: 'Work order date',
      past_due: 'Past Due',
      service: 'Service',
      trade: 'Trade',
      wo_status: 'Work order status',
      call_type: 'Work Order Type'
    },
    toggle_labels: {
      all: 'All',
      custom: 'Custom',
      not_selected: 'Not Selected',
      select_all: 'Select All'
    },
    details_fields: {
      open: 'Open',
      expiration: 'Expiration',
      priority: 'Priority',
      billing: 'Client Billing Method',
      type: 'Type',
      agreement: 'Agreement',
      trip: 'Trip',
      description: 'Description',
      no_call_type: 'Work Order Type Undefined'
    },
    missing_card: {
      trip_not_created: 'Trip Not Created',
      no_activities_found: 'No Activities found',
      no_activities_found_detail:
        'A trip for this work order has not been created.',
      empty_trip:
        'No new data has been found.  Field captured details update automatically after check in and check out.'
    },
    csv: {
      client_name: 'Client Name',
      address: 'Address',
      store_name: 'Location Name',
      sitetitle: 'Site Name',
      sitesub: 'Site Location',
      priority: 'Priority',
      city: 'Site City',
      state: 'Site State',
      trade: 'Trade',
      service: 'Service',
      pon: 'PO Number',
      won: 'Work Order #',
      sa_number: 'Service Appointment #',
      start_date: 'Start Date',
      end_date: 'End Date',
      eta: 'ETA',
      opendate: 'Open Date',
      duedate: 'Due Date',
      call_type: 'Work Order Type',
      wostat: 'WO status',
      site_address: 'Site Address',
      site_zip: 'Site Zip',
      trip_num: 'Trip Number',
      checkin_ftc: 'Checkin FTC',
      checkout_ftc: 'Checkout FTC',
      checkin_web: 'Checkin Web',
      checkout_web: 'Checkout Web',
      tasks_ftc: 'Tasks FTC',
      photos_ftc: 'Photos FTC',
      tasks_web: 'Tasks Web',
      external_id: 'External ID',
      status: 'Status',
      invoice_status: 'Invoice Status',
      checkin: 'Clock In',
      checkout: 'Clock Out',
      time_logs: 'Time Logs',
      tasks: 'Tasks',
      photo_title: 'Photo Title',
      photo_url: 'Photo URL',
      notes: 'Notes',
      notes_response: 'Notes Response',
      picker: 'Picker',
      picker_response: 'Picker Response',
      signature: 'Signature',
      signature_url: 'Signature URL',
      photo_title_ap: 'Photo Title AP',
      photo_url_ap: 'Photo URL AP',
      photo_title_pb: 'Photo Title PB',
      photo_url_pb: 'Photo URL PB',
      photo_title_pa: 'Photo Title PA',
      photo_url_pa: 'Photo URL PA'
    },
    trips: {
      before: 'Before',
      after: 'After',
      origin: 'Source',
      eta: 'ETA',
      checkin: 'Clocked In',
      checkout: 'Clocked Out',
      no_checkin: 'Not clocked in',
      checklist: 'Tasks Performed',
      photos: 'Photos',
      photos_after: 'Photos After',
      photos_before: 'Photos Before',
      web_trip: 'Web Portal',
      mobile: 'Mobile',
      description: 'Trip Description',
      notes: 'Notes',
      signature: 'Signature',
      add_note: 'Add Note',
      photos_remaining: '{v1} Photos Remaining',
      one_photos_remaining: '1 Photo Remaining',
      mandatory: '(required)',
      fields_required: 'All fields in red are required to update the trip.',
      sign: 'sign off form',
      enter_signature_name: 'Enter signature name',
      attachments: 'Attachments'
    },
    audit_card: {
      legend: 'Audit Trial will be coming soon!\nThank you for your patience.',
      work_order: 'Work Order',
      created: 'created',
      trip: 'Trip',
      has_created: 'has been created',
      eta_set: 'ETA is set for',
      check_in: 'The contractor has checked in',
      check_out: 'The contractor has checked out',
      has_changed: 'has changed to'
    },
    apply_advanced: 'Apply Search',
    activities: 'Activities',
    audit: 'Audit Trail',
    details: 'Details',
    due: 'Due',
    empty_message_title: 'Whoops...',
    empty_message:
      'There are no work orders in your account. Contact your Management company to get new work!',
    empty_message_filters:
      'Your search did not return any results. Check your search criteria and try again.',
    please: 'Please',
    try: 'try again',
    clear_search: 'Clear Search',
    checkin_message:
      'Field crews have not checked in.  Field captured details update automatically after check in and check out.',
    checkout_message: 'Field captured data will update automatically. ',
    expired_message:
      'The service appointment has expired due to no activity. Contact your representative if another service appointment is required.',
    cancelled_message:
      'The service appointment has been cancelled. There are no field services.',
    images_message:
      'Photos will update automatically once the field crews check out.',
    sort: 'Sort',
    clear: 'Clear',
    highest: 'Highest',
    lowest: 'Lowest',
    oldest: 'Oldest',
    newest: 'Newest',
    service_appointment: 'Service Appointment',
    service_short: 'Service Appt.',
    field_service: 'Field Services',
    audit_trail: {
      created: 'Service Appt {v1} created',
      expired: 'Service Appt {v1} expired',
      cancelled: 'Service Appt {v1} cancelled',
      checkIn: 'Clocked In, FTC',
      checkOut: 'Clocked Out, FTC',
      eta: 'ETA Update',
      invoiceCreated: 'Invoice Created, Draft',
      submit: 'Invoice Submitted',
      decline: 'Invoice Declined',
      approve: 'Invoice Approved',
      share: 'Share an update',
      cancel: 'Cancel update',
      wo_created: 'Work Order Created',
      to: 'To',
      internal: 'Internal Only',
      edit: 'Edit',
      reply: 'Reply',
      share_button: 'Share',
      cancel_button: 'Cancel',
      at: 'at',
      estimated_service_start: 'ETA Update'
    },
    wo_details: {
      location: 'Location Name',
      address: 'Address',
      priority: 'Priority',
      start_date: 'Start Date',
      end_date: 'End Date',
      trade: 'Trade',
      services: 'Services',
      location_contacts: 'Location Contacts',
      notes: 'Notes',
      attachments: 'Attachments',
      nte: 'NTE',
      service_appointment: 'Service Appt.',
      general_notes: 'Work Order Notes',
      instructions: 'Service Appointment Notes'
    },
    upload_messages: {
      clock_in: 'Uploading check in log',
      clock_out: 'Uploading check out log',
      repair: 'Uploading {v1} form',
      resources: 'Uploading resources'
    },
    construction_form: {
      qty: 'Qty',
      item: 'Item',
      product_name: 'Product Name',
      completion_status: 'Item Completion Status',
      select: 'Select',
      additional_comments: 'Additional Comments',
      notes: 'Notes',
      photos: 'Photos',
      complete_action_required: 'Complete Action - Required',
      return_on: 'Return On',
      ending_on: 'Ending On',
      confirmation: 'Confirmation',
      uploading_consttruction_form: 'Uploading Construction Form',
      work_complete: 'Work Complete?',
      return_visit: 'Return Visit',
      if_work_not_complete:
        'If work is not complete select "return visit required"',
      confirm_work_complete: 'Confirm Work Complete?',
      by_selecting_yes_work_complete:
        'By selecting "Yes" you attest that no additional services or workorders are required and the property is safe for intended use.',
      complete_0: '0% Complete',
      complete_25: '25% Complete',
      complete_50: '50% Complete',
      complete_75: '75% Complete',
      complete_100: '100% Complete',
      complete: 'Complete'
    },
    messages: {
      max_records:
        'Warning: Report generation is limited to a maximum of 5,000 records. Please reduce the number of selected records.'
    },
    line_item: {
      enter_name: 'Enter Name',
      item_number_name: 'Item {{number}} name',
      enter_description: 'Enter Description here..',
      item_description: ' Item Description',
      item: 'Item',
      name: 'Name',
      description: 'Description',
      qty: 'QTY',
      quantity: 'Quantity',
      unit_price: 'Unit Price',
      total: 'Total',
      tax: 'Tax'
    },
    sa_abbr: 'Service Appt.',
    reason: 'Reason',
    enter_return_reason: 'Enter reason here (optional)',
    complete_form: 'Required Actions',
    complete_all_required:
      'All work order actions must be completed before clocking out',
    attachment: 'Attachment'
  }
}
