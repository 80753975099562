/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'

/** Material UI **/
import { Box, FormLabel, makeStyles, Typography } from '@material-ui/core'
import FormControl from '@mui/material/FormControl'
import GlobalSelect from './Select'
import { useTranslation } from 'react-i18next'
import GlobalTimeInput from './TimeInput'
import GlobalInput from './TextInput'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    marginBottom: 10
  },
  label: {
    fontSize: '12px',
    color: theme.colors.grey,
    marginBottom: '0.5em'
  },
  timeContainer: {
    gap: '1em',
    [theme.breakpoints.down('md')]: {
      gap: '0.5em'
    }
  },
  days: {
    minWidth: '11em',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto'
    }
  },
  error: {
    marginLeft: '5px',
    marginBottom: '1em',
    fontSize: '10px',
    color: theme.colors.errorText
  },
  iconMargin: {
    marginRight: '7px'
  }
}))

export default function GlobalLaborHours(props) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { laborhours, hoursError, setHoursError } = props
  const [hours, setHours] = useState()

  useEffect(() => {
    setHours({ ...laborhours })
  }, [laborhours])

  const weekend = [
    { label: t('company_profile.none'), value: 'none' },
    { label: t('company_profile.weekend'), value: 'weekend' },
    { label: t('company_profile.saturday'), value: 'saturday' },
    { label: t('company_profile.sunday'), value: 'sunday' }
  ]

  const handleChange = (value, field) => {
    hours[field] = value
    props.onChange(value, field)

    if (field !== 'weekend_days') {
      if (field === 'time_from' || field === 'time_to') {
        hoursValidation(hours.time_from, hours.time_to, 'weekdays')
      } else {
        hoursValidation(
          hours.weekend_time_from,
          hours.weekend_time_to,
          'weekend'
        )
      }
    }
  }

  // convert to a standard format (ISO8601) in order to work on IOS devices as well
  const convertTo24Hour = time => {
    const matches = time?.match(/(\d{1,2}):(\d{2})\s*(AM|PM)/)
    if (!matches) {
      return null // or any other value to indicate an error
    }
    const [hourStr, minuteStr, meridiem] = matches.slice(1)
    let hour = parseInt(hourStr, 10)
    if (meridiem === 'PM' && hour !== 12) {
      hour += 12
    } else if (meridiem === 'AM' && hour === 12) {
      hour = 0
    }
    return `${hour?.toString().padStart(2, '0')}:${minuteStr}`
  }

  const hoursValidation = (timeFrom, timeTo, days) => {
    const errorTmp = { ...hoursError }
    if (timeFrom || timeTo) {
      // initialize with same date for comparisson
      const date1 = new Date(`2022-01-01T${convertTo24Hour(timeFrom)}:00.000Z`)
      const date2 = new Date(`2022-01-01T${convertTo24Hour(timeTo)}:00.000Z`)
      if (date1.getTime() < date2.getTime()) {
        errorTmp[days] = false
        setHoursError(errorTmp)
      } else {
        errorTmp[days] = true
        setHoursError(errorTmp)
      }
    } else {
      setHoursError(false)
    }
  }

  const isWeekendDayNone = laborhours?.weekend_days === 'none'
  return (
    <FormControl variant="standard" fullWidth={true}>
      <Box display="flex" flexDirection="row" className={classes.timeContainer}>
        <GlobalInput
          label={props.labeldays}
          value={t('company_profile.weekdays')}
          readOnly
          className={classes.days}
          disabled={props.disableComponent}
        />
        <Box display="flex" flexDirection="column" >
          <Typography id="text-label" className={classes.label}>
            {props.labelhours}
          </Typography>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              className={classes.timeContainer}
            >
              <GlobalTimeInput
                field="time_from"
                onChange={handleChange}
                value={
                  laborhours?.time_from
                    ? moment(laborhours?.time_from, 'hh:mm A')
                    : ''
                }
                disabled={props.disableComponent}
                placeholder={t('company_profile.placeholder.open')}
                format="h:mm A"
                use12Hours
              />
              <GlobalTimeInput
                field="time_to"
                onChange={handleChange}
                value={
                  laborhours?.time_to
                    ? moment(laborhours?.time_to, 'hh:mm A')
                    : ''
                }
                disabled={props.disableComponent}
                placeholder={t('company_profile.placeholder.close')}
                format="h:mm A"
                use12Hours
              />
            </Box>
            {hoursError.weekdays && (
              <FormLabel className={classes.error}>
                {t('company_profile.error.hours_operation')}
              </FormLabel>
            )}
          </Box>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" className={classes.timeContainer}>
        <GlobalSelect
          options={weekend}
          onChange={handleChange}
          defaultValue="weekend"
          field="weekend_days"
          value={laborhours?.weekend_days}
          disabled={props.disableComponent}
          displayvalue
        />
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="row"
            className={classes.timeContainer}
          >
            <GlobalTimeInput
              field="weekend_time_from"
              onChange={handleChange}
              value={
                laborhours?.weekend_time_from
                  ? moment(laborhours?.weekend_time_from, 'hh:mm A')
                  : ''
              }
              disabled={props.disableComponent || isWeekendDayNone}
              placeholder={t('company_profile.placeholder.open')}
              format="h:mm A"
              use12Hours
              allowClear
            />
            <GlobalTimeInput
              field="weekend_time_to"
              onChange={handleChange}
              value={
                laborhours?.weekend_time_to
                  ? moment(laborhours?.weekend_time_to, 'hh:mm A')
                  : ''
              }
              disabled={props.disableComponent || isWeekendDayNone}
              placeholder={t('company_profile.placeholder.close')}
              format="h:mm A"
              use12Hours
            />{' '}
          </Box>
          {hoursError.weekend && (
            <FormLabel className={classes.error}>
              {t('company_profile.error.hours_operation')}
            </FormLabel>
          )}
        </Box>
      </Box>
      <div className={classes.bottomSpacing}></div>
    </FormControl>
  )
}
