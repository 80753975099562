export const general = () => {
  return {
    labels: {
      cancel: 'Cancel',
      apply: 'Apply',
      first_name: 'First Name',
      last_name: 'Last Name',
      email: 'Email',
      submit: 'Submit',
      select: 'Select',
      search: 'Search',
      back: 'Back',
      save: 'Save',
      loading: 'Loading',
      add: 'Add',
      photo: 'Photo',
      not_available: 'Not available',
      wo: 'WO',
      download: 'Download',
      accept: 'Accept',
      download_selected: 'Download Selected (#)',
      download_all: 'Download All (# Total)',
      reset_filters: 'Reset filters',
      no_items: 'No items found',
      yes: 'Yes',
      no: 'No',
      on: 'On',
      off: 'Off',
      continue: 'Continue',
      next: 'Next',
      confirm: 'Confirm',
      done: 'Done'
    },
    messages: {
      errors: {
        error: 'Error!',
        same_parent: 'Whoops! Your email address is already associated with this reporting company. Please reach out to your company contact for support.',
        required: 'This field is mandatory.',
        email: 'The email address you entered is invalid.',
        password_does_not_match: 'The passwords entered do not match.',
        no_sites_found:
          "We can't find what you are looking for. Please check your search criteria.",
        size: 'The file size cannot exceed 25 MB.',
        no_results: 'No results',
        length_6: 'Field must be at least 6 characters long',
        phone: 'Invalid phone number',
        user_creation_title: 'User Creation Error',
        user_creation:
          'An error has occured when creating the user. Please try again.',
        download_error:
          'There was an error downloading your file, please contact your administrator or team support.'
      },
      navigation: {
        redirecting: 'Redirecting',
        redirect_app: 'Redirecting to the app'
      }
    },
    switch_company: {
      title: 'Switch reporting company?',
      message:
        'You are about to change the reporting company, make sure you have saved all your work before doing this change.',
      invite_user:
        'A user with that email is already registered on the FieldTech Connect platform. Do you want to send an invite email to grant that user access to this company?',
      user_exists:
        'This email address is already used for another company and can not be used again. Please try a different email address for this user.',
      same_parent: 'A user with this email already exists.'
    },
    delete_user: {
      title: 'Warning!',
      message:
        'You are about to delete this user from your company. They will no longer have access the this web portal and the FieldTech Connect mobile application.',
      highlight_question: 'Would you like to continue?'
    },
    delete_confirmed: {
      title: 'Success!',
      message:
        'Process finished successfully. The user %EMAIL% has been deleted.'
    },
    popup: {
      alert: 'Alert',
      send_invite: 'Send invite email?',
      invite_accept_redirect:
        'Invite accepted. Redirecting to subcontractor portal.',
      invite_accept_change_user:
        'Invite accepted. Remember to log in with the correct credentials.',
      invite_failed: 'Invite failed. Please contact your administrator.',
      send: 'Send',
      ok: 'Ok',
      cancel: 'Cancel'
    },
    format: {
      short_date: 'MM/DD/yyyy',
      time_am_pm: 'hh:mm A',
      short_date_time: 'MM/DD/yyyy hh:mm A',
      filter_date: 'MM/DD',
      full_date: 'MM-DD-YYYY HH:mm:ss Z'
    },
    date_formats: {
      audit_trail_format: 'hh:mm a MM/DD/YY'
    },
    attachment_picker: {
      size_message: 'File size exceeds 5MB limit. Please choose a smaller file.'
    }
  }
}
