import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  snackbar: false,
  snackMessage: null
}

/**
 * Create loading slice to show and hide a loader overlay
 *
 * @type {Slice<{loading: boolean}, {hide(*): void, show(*): void}, string>}
 */
const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {
    show (state) {
      state.loading = true
    },
    hide (state) {
      state.loading = false
    },
    showSnackbar (state) {
      state.snackbar = true
    },
    hideSnackbar (state) {
      state.snackbar = false
    },
    setSnackMessage (state, action) {
      state.snackMessage = action.payload
    }
  }
})

export const loadingActions = loadingSlice.actions

export default loadingSlice.reducer
