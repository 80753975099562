import { makeStyles } from '@material-ui/core'

export const filePreviewStyles = makeStyles(theme => ({
  fileBox: {
    border: `0.5px solid var(--gray-5, ${theme.colors.workOrders.dotsBack})`,
    borderRadius: '7px 7px 0px 0px',
    padding: '3px',
    height: '83px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .pg-viewer-wrapper': {
      overflow: 'hidden'
    },
    pointerEvents: 'none'
  },
  thumbBox: {
    border: `0.5px solid var(--gray-5, ${theme.colors.workOrders.dotsBack})`,
    borderRadius: '0px 0px 0px 7px',
    height: '17px',
    pointerEvents: 'none'
  }
}))

export const auditTrailStyles = props =>
  makeStyles(theme => ({
    root: {
      padding: '0px',
      boxShadow:
        '6px 9px 43px ' + theme.colors.workOrders.detailsCardBorderColor
    },
    field: {
      marginLeft: '5px',
      marginBottom: '5px',
      color: theme.colors.text,
      fontSize: '12px',
      fontWeight: '900'
    },
    fieldData: {
      marginLeft: '5px',
      marginBottom: '30px',
      color: theme.colors.text,
      fontSize: '14px',
      fontWeight: '400'
    },
    trailContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '25px'
    },
    date: {
      fontSize: '10px',
      color: theme.colors.columnTitle,
      width: '65px',
      height: '22px',
      margin: 'auto 0px'
    },
    type: {
      fontSize: '14px',
      color: theme.colors.text,
      fontWeight: '500'
    },
    author: {
      fontSize: '10px',
      color: theme.colors.textButton
    },
    stepper: {
      position: 'relative',
      '& .MuiStepConnector-line': {
        position: 'absolute',
        left: '6px',
        top: '27px',
        height: props?.boxHeight ?? '125px',
        borderColor: theme.colors.disabledField
      },
      '& .MuiStepLabel-iconContainer': {
        margin: '0px 0px 9.5px 1.5px'
      },
      '& ..MuiStepLabel-root': {
        alignSelf: 'flex-start'
      }
    },
    stepperLast: {
      position: 'relative',
      '& .MuiStepConnector-line': {
        position: 'absolute',
        left: '6px',
        top: '27px',
        height: '55px',
        borderColor: theme.colors.disabledField
      },
      '& .MuiStepLabel-iconContainer': {
        margin: '0px 0px 9.5px 1.5px'
      },
      '& ..MuiStepLabel-root': {
        alignSelf: 'flex-start'
      }
    },
    stepperNoConnector: {
      position: 'relative',
      '& .MuiStepConnector-line': {
        display: 'none'
      },
      '& .MuiStepLabel-iconContainer': {
        margin: '0px 0px 9.5px 1.5px'
      },
      '& ..MuiStepLabel-root': {
        alignSelf: 'flex-start'
      }
    },
    roundItem: {
      width: '9px',
      height: '9px',
      borderRadius: '15px',
      position: 'absolute',
      top: '18px'
    },
    wo_created: { backgroundColor: theme.colors.invoiceColors.invoice_created },
    checkIn: { backgroundColor: theme.colors.workOrders.excel },
    checkOut: { backgroundColor: theme.colors.gray },
    invoiceCreated: {
      backgroundColor: theme.colors.invoiceColors.invoice_created
    },
    submit: { backgroundColor: theme.colors.invoiceColors.dark_grey },
    decline: { backgroundColor: theme.colors.invoiceColors.red_error },
    cancelled: { backgroundColor: theme.colors.invoiceColors.red_error },
    estimated_service_start: { backgroundColor: theme.colors.textGray },
    expired: { backgroundColor: theme.colors.invoiceColors.red_error },
    approve: { backgroundColor: theme.colors.invoiceColors.invoice_approved },
    message: { backgroundColor: theme.colors.purple },
    checkMark: {
      fontSize: '18px',
      color: 'blue',
      marginLeft: 'auto'
    },
    smallAdornment: {
      borderTop: `1px solid ${theme.colors.disabledField}`,
      marginLeft: '69px',
      width: '8px'
    },
    bigAdornment: {
      borderTop: `1px solid ${theme.colors.disabledField}`,
      marginLeft: '67px',
      marginBottom: '3px',
      marginTop: '-42px',
      width: '12px'
    },
    adornmentEnd: {
      '& .MuiStepConnector-line': {
        height: '9px',
        minHeight: 0
      }
    },
    stepperContainer: {
      paddingTop: 0,
      marginLeft: '-28px'
    },
    labelsDiv: {
      marginTop: '4px',
      marginLeft: '10px'
    },
    shareTypo: {
      fontSize: '11px',
      fontWeight: '400',
      backgroundColor: theme.colors.backdropColor,
      color: theme.colors.textButton,
      height: '37px',
      width: '300px',
      margin: '6px 0px',
      padding: '12px',
      borderRadius: '6px',
      cursor: 'pointer'
    },
    shareBox: {
      display: 'flex',
      backgroundColor: theme.colors.signInButton.background,
      height: 'auto',
      width: '300px',
      padding: '0px 9px !important'
    },
    shareButton: {
      textTransform: 'none',
      fontSize: '14px',
      fontWeight: '400',
      color: theme.colors.iconBlue,
      padding: 0,
      marginBottom: '10px'
    },
    addIcon: {
      width: '20px',
      height: '20px',
      marginRight: '4px'
    },
    wrapperClassName: {
      fontSize: '12px',
      margin: '3px 0px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '280px',
      height: '150px'
    },
    wrapperClassNameReply: {
      fontSize: '12px',
      margin: '3px 0px',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '280px',
      height: '120px'
    },
    toolbarClassName: {
      border: 'none',
      padding: '0px',
      paddingTop: '5px',
      position: 'relative',
      order: 2,
      backgroundColor: '#FFFF',
      marginTop: '15px',
      maxWidth: '270px',
      '& .rdw-option-wrapper': {
        backgroundColor: '#FFFF',
        minWidth: '15px',
        height: '15px',
        border: 'none',
        margin: '0px 2px',
        padding: '3px'
      },
      '& .rdw-inline-wrapper': {
        marginLeft: '25px'
      },
      '& .rdw-option-wrapper img': {
        width: '12px',
        height: '12px'
      },
      '& .rdw-inline-wrapper .rdw-option-wrapper:first-child img': {
        width: '10px',
        height: '10px'
      },
      '& .rdw-list-wrapper': {
        marginLeft: '12px'
      },
      '& .rdw-list-wrapper .rdw-option-wrapper img': {
        width: '11px',
        height: '11px'
      }
    },
    attachButton: {
      background: theme.palette.primary.contrastText,
      height: '18px',
      position: 'absolute',
      '& button:hover': {
        boxShadow: `1px 1px 0px ${theme.colors.textGray}`
      },
      display: 'flex'
    },
    button: {
      width: '18px',
      height: '18px',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      background: theme.colors.signInButton.background,
      '& svg:hover': {
        border: 'none'
      }
    },
    replyButton: {
      width: '18px',
      height: '18px',
      border: 'none',
      outline: 'none',
      cursor: 'pointer',
      background: theme.palette.primary.contrastText,
      '& svg:hover': {
        border: 'none'
      }
    },
    icon: {
      width: '13px',
      height: '14px',
      marginTop: '3px'
    },
    attachInput: {
      display: 'none'
    },
    cancelButton: {
      textTransform: 'none',
      fontSize: '14px',
      fontWeight: '400',
      color: theme.colors.workOrderColors.declined,
      padding: 0,
      marginBottom: '10px'
    },
    cancelIcon: {
      width: '20px',
      height: '20px',
      marginRight: '4px'
    },
    toLabel: {
      fontSize: '11px',
      fontWeight: '700',
      color: theme.colors.text,
      marginBottom: '-3px',
      marginTop: '3px',
      marginRight: '5px'
    },
    envSelector: {
      borderRadius: '15px',
      backgroundColor: theme.colors.iconBlue,
      color: theme.colors.backdropColor,
      border: 'none',
      fontSize: '8.32px',
      fontWeight: '700',
      height: '20px',
      padding: '4px 4px 4px 15px !important',
      display: 'flex',
      cursor: 'pointer'
    },
    envSelectorDis: {
      borderRadius: '15px',
      backgroundColor: theme.colors.iconBlue,
      color: theme.colors.backdropColor,
      border: 'none',
      fontSize: '8.32px',
      fontWeight: '700',
      height: '20px',
      padding: '4px 4px 4px 15px !important',
      display: 'flex'
    },
    iconStyle: {
      marginTop: '-4px',
      height: '20px'
    },
    menuItem: {
      fontSize: '10px',
      fontWeight: '400',
      color: theme.colors.gray
    },
    topBar: {
      display: 'flex',
      marginTop: '8px'
    },
    shareMessage: {
      backgroundColor: theme.colors.iconBlue,
      textTransform: 'none',
      fontSize: '9px',
      fontWeight: '700',
      borderRadius: '15px',
      padding: '7px 24px',
      marginLeft: '208px',
      marginTop: '-30px',
      marginBottom: '10px',
      height: '27px',
      width: '60px',
      color: 'white'
    },
    shareMessageReply: {
      backgroundColor: theme.colors.iconBlue,
      textTransform: 'none',
      fontSize: '9px',
      fontWeight: '700',
      borderRadius: '15px',
      padding: '7px 24px',
      marginLeft: '198px',
      marginTop: '-30px',
      marginBottom: '10px',
      height: '27px',
      width: '60px'
    },
    shareButtonDiv: {
      display: 'flex'
    },
    messageBox: {
      border: '0.5px solid #E0E0E0',
      borderRadius: '8px',
      padding: '13px 9px 13px 17px !important',
      marginLeft: '7px',
      width: '100%',
      maxWidth: '300px'
    },
    messagesBox: {
      paddingTop: '12px !important'
    },
    messageTypo: {
      fontSize: '11px',
      fontWeight: '400',
      color: theme.colors.text,
      '& p': {
        marginTop: '5px',
        marginBottom: '5px'
      }
    },
    connector: {
      position: 'absolute',
      left: '5.45px'
    },
    environment: {
      backgroundColor: theme.colors.textGray,
      color: theme.colors.backdropColor,
      borderRadius: '15px',
      fontSize: '8px',
      fontWeight: '700',
      padding: '3px 10px',
      width: 'fit-content'
    },
    buttonlabel: {
      fontSize: '8px',
      fontWeight: '400',
      color: theme.colors.gray
    },
    buttonDiv: {
      marginRight: '4px',
      width: '30px',
      borderRadius: '6px',
      textAlign: 'center',
      cursor: 'pointer'
    },
    replyBox: {
      backgroundColor: theme.colors.profile.avatar_bg,
      borderRadius: '8px',
      border: `0.5px solid var(--gray-5, ${theme.colors.workOrders.dotsBack})`,
      padding: '13px !important',
      marginTop: '10px'
    },
    highlightedContent: {
      fontSize: '10px',
      fontWeight: '400',
      color: theme.colors.text,
      display: 'inline'
    },
    replyEditorBox: {
      border: `0.5px solid var(--gray-5, ${theme.colors.workOrders.dotsBack})`,
      borderRadius: '6px',
      maxWidth: '285px'
    },
    highlightoff: {
      width: '12px',
      height: '12px',
      color: theme.colors.invoiceColors.red_error
    },
    replyIcon: {
      marginRight: '7px'
    },
    buttonlabelRed: {
      fontSize: '8px',
      fontWeight: '400',
      color: theme.colors.invoiceColors.red_error
    },
    buttonBlurryDiv: {
      marginRight: '4px',
      width: '30px',
      borderRadius: '6px',
      textAlign: 'center',
      cursor: 'pointer',
      opacity: 0.4
    },
    replyEditDiv: {
      display: 'flex',
      margin: '7px 0px'
    },
    deleteIcon: {
      cursor: 'pointer',
      width: '13px',
      height: '13px',
      color: theme.colors.textButton,
      marginRight: '4px'
    },
    fileName: {
      color: theme.palette.primary.light,
      fontSize: '11px',
      fontWeight: '400',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical'
    },
    fileListBox: {
      display: 'flex',
      margin: '8px 12px'
    },
    editorDivider: {
      padding: '0px 12px'
    },
    imageBox: {
      cursor: 'pointer',
      '& .pg-viewer-wrapper': {
        overflow: 'hidden',
        height: '76px'
      },
      display: 'flex'
    },
    fileDiv: {
      margin: '3px',
      cursor: 'pointer'
    },
    deleteFile: {
      color: theme.colors.textButton,
      width: '12px',
      height: '12px',
      marginLeft: '75px',
      marginBottom: '-14px',
      cursor: 'pointer'
    },
    filesGrid: {
      width: '100%',
      margin: '13px 3px 10px 3px'
    },
    boxArrowRep: {
      width: '10px'
    },
    imageAttach: {
      maxHeight: '85px',
      maxWidth: '85px',
      marginTop: '3px',
      border: '0.5px solid #E0E0E0',
      borderRadius: '7px'
    }
  }))
