export const contactUs = () => {
  return {
    title: 'CONTÁCTENOS',
    subtitle_1: 'Porfavor ingrese sus datos y le contactaremos',
    company_name: 'Nombre de la compañía',
    message: 'Mensaje',
    email_sent: 'EMAIL ENVIADO!',
    subtitle_2: 'Gracias por enviar su solicitud',
    subtitle_3:
      'Un representante de Automated Decision se contactará con usted pronto con respecto a su solicitud'
  }
}
