export const requestAccess = () => {
  return {
    title: 'CREAR CUENTA',
    subtitle_1: 'Por favor complete los campos debajo',
    company_domain: 'Dominio de la empresa',
    next: 'Siguiente',
    enter_access_code: 'Ingrese código de registro',
    have_account: 'Ya tienes una cuenta?',
    call_us: 'o llámenos al ######',
    email_us: 'Envíanos un correo electrónico',
    first_name: 'Nombre',
    last_name: 'Apellido',
    email_address: 'Correo',
    create_account: 'Crear Cuenta',
    finish_account: 'Termine de crear su cuenta',
    create_message: 'Ingrese su código de registro para crear su cuenta.',
    username: 'Nombre de Usuario',
    user_title: 'Seleccione Título',
    user_roles: {
      accounting: 'Contabilidad',
      account_manager: 'Administrador de Cuenta',
      administrator: 'Administrador',
      crew_member: 'Miembro de Equipo',
      crew_leader: 'Lider de Equipo',
      dispatcher: 'Despachador',
      field_supervisor: 'Supervisor de Campo',
      installer: 'Instalador',
      manager: 'Gerente',
      operations_manager: 'Gerente de Operaciones',
      owner: 'Dueño',
      president: 'Presidente',
      technician: 'Técnico'
    },
    admin_roles: {
      accounting: 'Contabilidad',
      account_manager: 'Administrador de Cuenta',
      administrator: 'Administrador',
      dispatcher: 'Despachador',
      operations_manager: 'Gerente de Operaciones',
      owner: 'Dueño',
      president: 'Presidente'
    },
    mcs_roles: {
      super_admin: 'Super Administrador',
      portal: 'Usuario de Portal',
      mobile: 'Solo Móbil'
    },
    messages: {
      errors: {
        invalid_domain_structure:
          'El código de registro que ingresó no es válido.',
        mandatory_field: 'Campo Requerido',
        invalid_email: 'Email Incorrecto'
      }
    }
  }
}
