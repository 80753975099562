// main components
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AccessPanel } from './AccessPanel'

// mui components
import {
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Box
} from '@material-ui/core'
import { AddCircleRounded } from '@material-ui/icons'
// services
import * as ApiServices from '../../services/ApiService'
import { userHasAuthorization } from '../../services/AuthService'
import ReactGA from 'react-ga4'
import { rolePermissionsArr } from '../../lib/Constants'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    boxShadow: '6px 9px 43px ' + theme.colors.profile.shadowCard,
    borderBottom: `1px solid ${theme.colors.disabledField}`
  },
  title: {
    fontSize: '20px',
    margin: '23px 0px 22px 20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '23px',
    color: theme.colors.company.title
  },
  actions: {
    display: 'flex',
    padding: '0px 0px'
  },
  addButton: {
    marginLeft: 'auto',
    marginRight: '12px',
    color: theme.palette.primary.light
  },
  content: {
    padding: '0px 20px 30px 20px'
  },
  itemDivider: {
    borderBottom: '1px solid ' + theme.colors.grey_2,
    paddingBottom: '15px'
  },
  roleItem: {
    fontSize: '16px',
    fontWeight: 600,
    paddingBottom: '2em',
    minWidth: '10em',
    [theme.breakpoints.down('xs')]: {
      minWidth: '7em'
    }
  },
  rolePermission: {
    fontSize: '12px',
    fontWeight: '400',
    margin: '20px 4px 0px 0px',
    lineHeight: '14px'
  },
  roleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  comingSoon: {
    fontSize: '12px',
    fontWeight: '400',
    margin: '6px 4px 0px 0px',
    lineHeight: '14px'
  },
  displayFlex: {
    display: 'flex',
    marginTop: '10px',
    '& p': {
      color: theme.colors.textGray
    }
  }
}))

export const RolesCard = props => {
  const { roles, updateRoles } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const [openPanel, setOpenPanel] = useState(false)
  const [dataPanel, setDataPanel] = useState({
    id: 0,
    name: '',
    workorders: 'not_selected',
    sites: 'not_selected',
    company_settings: 'not_selected'
  })

  const handleNewPanel = () => {
    setDataPanel({
      id: 0,
      name: '',
      workorders: 'not_selected',
      sites: 'not_selected',
      company_settings: 'not_selected'
    })
    setOpenPanel(true)
  }

  const handleNewAccess = async (name, workorders, sites, companySettings) => {
    try {
      await ApiServices.createRolWithScopes(
        name,
        workorders,
        sites,
        companySettings
      )
      setOpenPanel(false)
      updateRoles()

      ReactGA.event({
        category: 'request',
        action: 'create_access_role'
      })
    } catch (e) {
      console.error(e)
      setOpenPanel(false)
    }
  }

  const handleChangeAccess = async (
    id,
    name,
    workorders,
    sites,
    companySettings
  ) => {
    try {
      await ApiServices.updateRolWithScopes(
        id,
        name,
        workorders,
        sites,
        companySettings
      )
      setOpenPanel(false)
      updateRoles()

      ReactGA.event({
        category: 'request',
        action: 'update_access_role'
      })
    } catch (e) {
      console.error(e)
      setOpenPanel(false)
    }
  }

  const handleDeleteAccess = async id => {
    try {
      await ApiServices.deleteRolWithScopes(id)
      setOpenPanel(false)
      updateRoles()

      ReactGA.event({
        category: 'request',
        action: 'delete_access_role'
      })
    } catch (e) {
      console.error(e)
      setOpenPanel(false)
    }
  }

  const accountSettingsPermission = r => r !== 'account_settings'

  return (
    <Card className={classes.card} data-testid={'company_access_card'}>
      <CardActions disableSpacing classes={{ root: classes.actions }}>
        <Typography classes={{ root: classes.title }}>
          {t('company_settings.card.roles')}
        </Typography>
        {userHasAuthorization('company_settings.manage_access:write') && (
          <IconButton
            aria-label="delete"
            classes={{ root: classes.addButton }}
            onClick={handleNewPanel}
            data-testid={'add_company_access_button'}
          >
            <AddCircleRounded />
          </IconButton>
        )}
      </CardActions>
      <CardContent classes={{ root: classes.content }}>
        <Box display="flex" flexDirection="column">
          {roles
            ?.sort((a, b) => b.name.length - a.name.length)
            .map(role => (
              <div key={role.name} className={classes.itemDivider}>
                <Box display="flex" flexDirection="row" alignItems="baseline">
                  <Typography classes={{ root: classes.roleItem }}>
                    {role.name}
                  </Typography>
                  <Box className={classes.roleContainer}>
                    <Typography className={classes.rolePermission}>
                      {t('company_profile.roles_card.manage')}
                    </Typography>
                    {Object.keys(role?.permissions)
                      .filter(accountSettingsPermission)
                      .sort((a, b) => {
                        const indexA = rolePermissionsArr.indexOf(a)
                        const indexB = rolePermissionsArr.indexOf(b)
                        return indexA - indexB
                      })
                      .map((rol, index, array) => {
                        const length = array.filter(
                          accountSettingsPermission
                        ).length
                        return (
                          <Typography
                            key={index}
                            className={classes.rolePermission}
                          >
                            {t('company_profile.roles_card.' + rol)}
                            {index === length - 1
                              ? '.'
                              : index === length - 2
                              ? ' ' + t('company_profile.roles_card.and')
                              : ', '}
                          </Typography>
                        )
                      })}
                  </Box>
                </Box>
              </div>
            ))}
          <div className={classes.displayFlex}>
            <Typography classes={{ root: classes.roleItem }}>
              {t('company_profile.roles_card.view_only')}
            </Typography>
            <Typography className={classes.comingSoon}>
              {t('company_profile.roles_card.coming_soon')}
            </Typography>
          </div>
        </Box>
      </CardContent>
      <AccessPanel
        data={dataPanel}
        open={openPanel}
        setOpen={setOpenPanel}
        handleNew={handleNewAccess}
        handleChange={handleChangeAccess}
        handleDelete={handleDeleteAccess}
        roles={roles}
      />
    </Card>
  )
}
