import React, { useRef } from 'react'
import { Divider } from '@material-ui/core'
import { AttachFile as AttachIcon } from '@material-ui/icons'
import { auditTrailStyles } from '../../styles/classes/WorkOrderClasses'

export const AuditAttachButton = props => {
  const classes = auditTrailStyles()()
  const fileInput = useRef(null)

  const handleClick = event => {
    fileInput.current.click()
  }

  const onFilechange = e => {
    props.setFiles([...e.target.files])
  }

  return (
    <div aria-label="rdw-attach-control" className={classes.attachButton}>
      <input
        ref={fileInput}
        name="file"
        type="file"
        multiple
        onChange={onFilechange}
        className={classes.attachInput}
      />
      <button
        className={props.reply ? classes.replyButton : classes.button}
        onClick={handleClick}
      >
        <AttachIcon className={classes.icon} />
      </button>
      <Divider orientation="vertical" />
    </div>
  )
}
