/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react'
import { makeStyles, FormControl } from '@material-ui/core'
import moment from 'moment'
import { TimePicker, Calendar } from 'antd'
import { useTranslation } from 'react-i18next'
import 'antd/dist/antd.css'

const useStyles = makeStyles(theme => ({
  calendar: {
    '& .ant-picker-input': {
      boxShadow: 'none',
      '& input': {
        color: theme.colors.basicDisabledButtonColor,
        cursor: 'pointer',
        '&::placeholder': {
          color: theme.colors.iconBlue,
          opacity: 1
        }
      }
    },
    '& .ant-picker-calendar-year-select': {
      display: 'none'
    },
    '& .ant-picker-calendar-header': {
      justifyContent: 'center'
    },
    '& .ant-radio-group': {
      display: 'none'
    },
    '& .ant-picker-focused': {
      width: '300px'
    },
    cursor: 'pointer',
    boxShadow: 'none',
    zIndex: 9
  },
  hidden: {
    border: 'none',
    zIndex: 1,
    marginTop: '-32px'
  },
  overlay: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0
  },
  timeWidth: {
    width: '32%'
  }
}))

export const DateTimeFilter = props => {
  const [selectedDate, setSelectedDate] = useState()
  const [openTime, setOpenTime] = useState(false)
  const [disabled, setDisabled] = useState()
  const { t } = useTranslation()

  const classes = useStyles()

  useEffect(() => {
    props.values
      ? setSelectedDate(new Date(props.values))
      : setSelectedDate(
          props.defaultDate ||
            moment().format(t('general.format.short_date_time'))
        )

    handleChange(
      props.values ? moment(new Date(props.values)) : moment(props.defaultDate),
      true,
      true
    )
  }, [props.id])

  const handleChange = (date, update = false, forceStop) => {
    handleTimeChange(date)
    if (!disabled) {
      setSelectedDate(
        date ? date.format(t('general.format.short_date_time')) : null
      )
      if (date === null) props.setValues(null)
      if (update) props.setValues(date.valueOf())
      if (!forceStop) setOpenTime(!openTime)
    }
  }
  const handleTimeChange = date => {
    if (date <= moment()) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  const disabledDate = current => {
    // Can not select days before today and today
    if (props.minDate) {
      return current.isBefore(new Date(props.minDate))
    }
    return (
      current &&
      (current <= moment().subtract(1, 'days').endOf('day') ||
        current > moment.unix(props.maxDate).endOf('day'))
    )
  }

  return (
    <>
      <Calendar
        onSelect={handleTimeChange}
        onChange={handleChange}
        disabledDate={disabledDate}
        format="MM/DD/yyyy hh:mm A"
        popupStyle={{ zIndex: 2000 }}
        value={selectedDate ? moment(selectedDate) : undefined}
        className={classes.calendar}
        fullscreen={false}
      />
      <br />
      <FormControl variant="outlined" className={classes.timeWidth}>
        <TimePicker
          format="hh:mm A"
          use12Hours
          disabledDate={current => {
            if (props.minDate) {
              return current.isBefore(new Date(props.minDate))
            }
            return current && current <= moment().subtract(1, 'h, m')
          }}
          popupStyle={{ zIndex: 2000 }}
          open={openTime}
          value={selectedDate ? moment(selectedDate) : undefined}
          onOk={date => handleChange(date, true)}
          onBlur={() => setOpenTime(false)}
          onClick={() => setOpenTime(true)}
          showNow={false}
          placement="bottomCenter"
          clearIcon
        />
      </FormControl>
    </>
  )
}
