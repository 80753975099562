import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// MUI Components
import {
  IconButton,
  InputAdornment,
  makeStyles,
  Container,
  FormControl,
  Button,
  Box
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// main components
import { MainTable } from '../components/workorders/MainTable'
import { ExportCard } from '../components/shared/ExportCard'
import { useWoSearch } from '../components/workorders/useWoSearch'

import { exportWorkOrders, csvData } from '../services/ApiService'

import {
  CustomOutlinedInput,
  HighlightButton
} from '../styles/mui_custom_components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import { CompanySwitch } from '../components/CompanySwitchDropDown'

import { store } from '../store'
import { loadingActions } from '../store/loading'
import { Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { isRunningInIframe } from '../lib/Global'
import { useParams } from 'react-router-dom'
import { setIframeOriginatingCompany } from '../lib/Api'
import { ConfirmDialog } from '../components/form/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  globalFiltersContainer: {
    height: '55px',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: '48px',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content'
    }
  },
  SearchInput: {
    paddingRight: 0,
    '& .MuiInputBase-root': {
      '& input': {
        fontSize: '12px'
      }
    },
    '& .MuiInputBase-input': {
      fontSize: '12px'
    },
    '&.MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)'
      }
    },
    height: '32px',
    width: '230px',
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    }
  },
  datesInput: {
    height: '32px'
  },
  Counts: {
    fontSize: '12px',
    fontWeight: '400'
  },
  select: {
    '&:focus': {
      backgroundColor: theme.colors.transparent,
      padding: '0px 0px'
    }
  },
  selector: {
    height: '32px',
    fontSize: '12px',
    '& .MuiSelect-outlined': {
      paddingRight: '0px'
    }
  },
  exportButton: {
    fontSize: '12px',
    color: theme.colors.iconBlue,
    border: '1px solid ' + theme.colors.disabledField,
    borderRadius: '45px',
    textTransform: 'none',
    padding: '10px 30px',
    lineHeight: '14px',
    marginLeft: '5px'
  },
  advancedButton: {
    paddingBottom: '5px',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    marginLeft: '15px',
    marginTop: '-20px'
  },
  resetButton: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    padding: '0px 30px'
  },
  tableContainer: {
    margin: '0px',
    padding: '0px',
    width: '100%',
    maxWidth: '100%'
  },
  countsContainer: {
    margin: '0px 54px',
    padding: '0px 0px'
  },
  exportDialog: {
    borderRadius: '8px'
  },
  exportTitle: {
    color: theme.colors.text,
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '33px 0px',
    textAlign: 'center'
  },
  exportContent: {
    color: theme.colors.text,
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'center',
    width: '389px',
    margin: '0px 35px 33px 35px'
  },
  dialogButton: {
    marginLeft: 'auto',
    height: '40px',
    width: '84px',
    fontSize: '14px'
  },
  dialogContent: {
    display: 'flex !important'
  },
  serviceBox: {
    marginRight: '1px',
    textAlign: 'right'
  },
  switchContainer: {
    width: '250px',
    paddingTop: '20px'
  },
  loader: {
    fontSize: '12px',
    color: theme.colors.textButton,
    padding: '0px 20px'
  }
}))

const initFilters = {
  site_name: '',
  trade: '',
  service: '',
  wostat: '',
  won: '',
  pon: '',
  eta: '',
  opendate: '',
  duedate: '',
  state: '',
  city: '',
  pastdue: '',
  call_type: '',
  asc_sort: '',
  desc_sort: '',
  invoices: '',
  gc_scope: '',
  general_notes: '',
  nte: '',
  client_name: '',
  priority: '',
  client_tracking_number: ''
}

window.currentSearchIndex = 0

const WorkOrders = () => {
  const params = useParams()
  const { t } = useTranslation()
  const searchParams = window.location.hash.replace('#', '?')
  const userStore = useSelector(state => state.auth.user)
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const [searchSendValue, setSearchSendValue] = useState('')
  const [searchIndex, setSearchIndex] = useState(0)
  const [searchEnabled, setSearchEnabled] = useState(false)

  const queryWon = new URLSearchParams(searchParams).get('won') ?? ''
  const querySites = new URLSearchParams(searchParams).get('sites') ?? ''
  const queryTrades = new URLSearchParams(searchParams).get('trades') ?? ''
  const queryStatus = new URLSearchParams(searchParams).get('status') ?? ''
  const queryGcScope = new URLSearchParams(searchParams).get('gc_scope') ?? ''
  const queryDescription =
    new URLSearchParams(searchParams).get('general_notes') ?? ''
  const queryNte = new URLSearchParams(searchParams).get('nte') ?? ''

  const [openAdvanced, setOpenAdvanced] = useState(false)
  const [selected, setSelected] = useState([])
  const [tablePage, setTablePage] = useState(1)

  const [openExport, setOpenExport] = useState(false)
  const [openBigExport, setOpenBigExport] = useState(false)
  const [reportData, setReportData] = useState([])
  const [downloading, setDownloading] = useState(false)
  const [exportByIds, setExportByIds] = useState(false)
  const [displayDownloadError, setDisplayDownloadError] = useState(false)

  const [filters, setFilters] = useState({
    ...initFilters,
    won: queryWon,
    sites: querySites,
    trade: queryTrades,
    wostat: queryStatus,
    gc_scope: queryGcScope,
    general_notes: queryDescription,
    nte: queryNte
  })

  // iframe switch
  if (isRunningInIframe()) {
    setIframeOriginatingCompany(params.id)
  }

  // switching company
  const [displaySwitch, setDisplaySwitch] = useState(false)

  history.replaceState(null, null, ' ')

  useEffect(() => {
    if (userStore.userInfo?.companyIds?.length > 1 && !isRunningInIframe()) {
      setDisplaySwitch(true)
    } else {
      setDisplaySwitch(false)
    }
  }, [])

  const { loading, workOrders, setWorkOrders, chips, hasMore } = useWoSearch(
    false,
    searchSendValue,
    filters.client_name,
    filters.site_name,
    filters.trade,
    filters.service,
    filters.won,
    filters.opendate,
    filters.duedate,
    filters.wostat,
    filters.invoices,
    filters.gc_scope,
    filters.general_notes,
    filters.nte,
    filters.priority,
    filters.client_tracking_number,
    filters.asc_sort,
    filters.desc_sort,
    30,
    tablePage,
    searchEnabled,
    userStore.userInfo.configurations.integrations.trades
  )

  useEffect(() => {
    setTablePage(1)
    setSearchEnabled(true)
    setTimeout(() => {
      setSearchEnabled(false)
    }, 500)
  }, [searchSendValue, filters])

  useEffect(() => {
    window.currentSearchIndex = searchIndex
    if (searchValue !== searchSendValue) {
      setSearchSendValue(searchValue)
    }
  }, [searchIndex])

  useEffect(() => {
    if (searchValue.length >= 4 || searchValue.length === 0) {
      const timer = setTimeout(() => {
        setSearchSendValue(searchValue)
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [searchValue])

  const handleChangeSearch = event => {
    const value = event.target.value
    setSearchValue(value)
  }

  const handleKeyDownSearch = event => {
    if (event.keyCode === 13 && searchValue.length >= 4) {
      setSearchIndex(searchIndex + 1)
    }
  }

  const handleClearGlobalSearch = event => {
    setSearchValue('')
    setSearchIndex(searchIndex + 1)
  }

  const handleBlurSearch = () => {
    if (searchValue.length >= 4) {
      /* Blur delayed if clear exists */
      setTimeout(() => {
        setSearchIndex(searchIndex + 1)
      }, 100)
    }
  }

  const handleCleanFilters = () => {
    setTimeout(() => {
      setSearchValue('')
      setFilters(initFilters)
      setSearchIndex(searchIndex + 1)
      setSelected([])
    }, 1000)
  }

  const requestByIds = ids => {
    let woIds = null
    setDownloading(true)
    if (ids) {
      woIds = ids === 'all' ? '' : ids.join(',')
    } else {
      woIds = selected.join(',')
    }
    csvData(
      false,
      searchSendValue,
      filters.client_name,
      filters.site_name,
      filters.trade,
      filters.service,
      filters.won,
      filters.opendate,
      filters.duedate,
      filters.wostat,
      filters.invoices,
      filters.priority,
      filters.tracking,
      filters.general_notes,
      filters.asc_sort,
      filters.desc_sort,
      woIds,
      isRunningInIframe() ? params.id : userStore?.userInfo?.company_id
    )
      .then(result => {
        // download the file from the result.path URL
        if (result?.path?.length > 0) {
          const link = document.createElement('a')
          link.href = result.path
          link.target = '_blank'
          // extract file name from the path
          const fileName = result.path.split('/').pop()
          link.setAttribute('download', fileName)
          link.click()
        } else {
          setDisplayDownloadError(true)
        }
      })
      .finally(() => {
        setDownloading(false)
        store.dispatch(loadingActions.hide())
        setExportByIds(false)
      })
  }
  const exportCSVByIds = ids => {
    setExportByIds(true)
    requestByIds(ids)
  }
  const exportLargeFile = () => {
    try {
      exportWorkOrders(
        false,
        searchSendValue,
        filters.client_name,
        filters.location,
        filters.trade,
        filters.service,
        filters.wo_number,
        filters.open_date,
        filters.close_date,
        filters.wo_status,
        filters.invoices,
        filters.priority,
        filters.tracking,
        filters.asc_sort,
        filters.desc_sort
      )
    } catch (error) {
      console.error(error)
    }
  }

  const validateFilters = () => {
    let value = true
    // eslint-disable-next-line array-callback-return
    Object.keys(initFilters).map(element => {
      if (initFilters[element] !== filters[element]) {
        value = false
      }
      return null
    })
    if (searchValue.length !== 0) {
      value = false
    }
    return value
  }

  const handleEmailBigExport = () => {
    setOpenBigExport(false)
    exportLargeFile()
  }

  return (
    <>
      <Container
        style={{ overflow: 'hidden' }}
        className={classes.globalFiltersContainer}
        data-testid={'wo_page'}
      >
        <FormControl variant="outlined" data-testid={'search_field'}>
          <CustomOutlinedInput
            value={searchValue}
            className={classes.SearchInput}
            id="component-outlined"
            placeholder={t('general.labels.search')}
            onChange={handleChangeSearch}
            onKeyDown={handleKeyDownSearch}
            onBlur={handleBlurSearch}
            startAdornment={
              <InputAdornment position="start">
                <FontAwesomeIcon
                  icon={['far', 'search']}
                  size="xs"
                  color="grey"
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                {searchValue.length > 0 ? (
                  <IconButton onClick={handleClearGlobalSearch}>
                    <ClearIcon style={{ fontSize: 'small' }} fontSize="small" />
                  </IconButton>
                ) : (
                  <div />
                )}
              </InputAdornment>
            }
            autoComplete="off"
          />
        </FormControl>
        {!validateFilters() && (
          <Button
            data-testid={'reset_button'}
            size="small"
            classes={{ root: classes.resetButton }}
            onClick={handleCleanFilters}
          >
            {t('general.labels.reset_filters')}
          </Button>
        )}
        <div>
          {selected.length > 0 ? (
            <Button
              size="small"
              classes={{ root: classes.exportButton }}
              onClick={() => setOpenExport(!openExport)}
              variant="outlined"
            >
              {downloading ? (
                <FontAwesomeIcon
                  icon="fa-regular fa-loader"
                  spin
                  className={classes.loader}
                />
              ) : (
                t('work_orders.export_selected')
              )}
            </Button>
          ) : null}
          <ExportCard
            requestData={requestByIds}
            allBooks={reportData}
            setAllBooks={setReportData}
            open={openExport}
            setOpen={setOpenExport}
            chips={chips}
            rows={workOrders}
            selected={selected}
            setOpenBigExport={setOpenBigExport}
            downloading={downloading}
            exportLargeFile={exportLargeFile}
            exportByIds={exportByIds}
            parent={'work_orders'}
          />
        </div>

        <Box flex={1} />
        {displaySwitch && (
          <Box classes={{ root: classes.switchContainer }}>
            <CompanySwitch hideLabel />
          </Box>
        )}
      </Container>

      <Container classes={{ root: classes.tableContainer }}>
        <MainTable
          chips={chips}
          content={workOrders}
          openAdvanced={openAdvanced}
          setOpenAdvanced={setOpenAdvanced}
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          hasMore={hasMore}
          setTablePage={setTablePage}
          filters={filters}
          setFilters={setFilters}
          handleCleanFilters={handleCleanFilters}
          validateFilters={validateFilters}
          setSearchEnabled={setSearchEnabled}
          exportCSVByIds={exportCSVByIds}
          setWorkOrders={setWorkOrders}
        />
        <Dialog
          classes={{ root: classes.exportDialog }}
          onClose={() => setOpenBigExport(false)}
          open={openBigExport}
          fullWidth={true}
          maxWidth="sm"
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography classes={{ root: classes.exportTitle }}>
                {t('work_orders.data_export')}
              </Typography>
              <Typography classes={{ root: classes.exportContent }}>
                {t('work_orders.export_message').replace(
                  'user_email_address',
                  userStore?.userInfo?.email
                )}
              </Typography>
            </Grid>
          </Grid>
          <DialogContent classes={{ root: classes.dialogContent }}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={4}>
                <HighlightButton
                  variant="contained"
                  classes={{ root: classes.cancelButton }}
                  onClick={() => setOpenBigExport(false)}
                >
                  {t('work_orders.cancel')}
                </HighlightButton>
              </Grid>
              <Grid item xs={4}>
                <HighlightButton
                  variant="contained"
                  classes={{ root: classes.dialogButton }}
                  onClick={handleEmailBigExport}
                >
                  {t('work_orders.email')}
                </HighlightButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <ConfirmDialog
          open={displayDownloadError}
          setOpen={setDisplayDownloadError}
          message={t('general.messages.errors.download_error')}
          handleAccept={() => {
            setDisplayDownloadError(false)
          }}
          acceptlabel="Ok"
        />
      </Container>
    </>
  )
}

export default WorkOrders
