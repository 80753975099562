import { createSlice } from '@reduxjs/toolkit'

const initialInvoicesState = {
  filters: {
    inStatus: '',
    won: '',
    client_name: '',
    client_location: '',
    invoice: '',
    amount: null,
    createDate: '',
    dueDate: '',
    payDate: '',
    achCheckNumber: '',
    asc_sort: '',
    desc_sort: ''
  },
  invoices: [],
  openInvoices: [],
  loadingFiles: false
}

/**
 * Create a slice of reducer for invoices
 *
 * @type {Slice<{filters: null}, {setFilters(*): void}, string>}
 */
const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: initialInvoicesState,
  reducers: {
    setFilters (state, action) {
      state.filters = action.payload
    },
    setInvoices (state, action) {
      state.invoices = action.payload
    },
    setOpenInvoices (state, action) {
      state.openInvoices = action.payload
    },
    setLoadingFiles (state, action) {
      state.loadingFiles = action.payload
    }
  }
})

export const invoicesActions = invoicesSlice.actions

export default invoicesSlice.reducer
