import { React, useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  makeStyles,
  ThemeProvider,
  Typography
} from '@material-ui/core'
import { ProfileSteps } from '../components/companyprofile/ProfileSteps'
import { useTranslation } from 'react-i18next'
import { CompanyProfileComponent } from '../components/companyprofile/CompanyProfileComponent'
import { InsuranceComponent } from '../components/companyprofile/InsuranceComponent'
import { ClientsComponent } from '../components/companyprofile/ClientsComponent'
import { ServiceComponent } from '../components/companyprofile/ServiceComponent'
import { UsersComponent } from '../components/companyprofile/UsersComponent'
import { useHistory } from 'react-router-dom'
import {
  getBase64,
  validateEmail,
  getSelectedZiCodesNumber,
  profileMandatoryValidation,
  calculateCompliance,
  getSignedS3Url,
  uploadWithSignedUrl,
  validatePhone
} from '../lib/Global'
import {
  getCompanyProfile,
  getTermsAndConditions,
  updateCompany
} from '../services/ApiService'
import { useSelector } from 'react-redux'
import { getRoles } from '../lib/Api'
import {
  buttonSettingsDisabled,
  checkboxTheme,
  enableButtonStyle,
  outlinedButtonStyle
} from '../styles/mui_custom_theme'
import { cloneDeep } from 'lodash'
import { callLocationApi, parseStateList } from '../services/ApiLocationService'
import { maxFileSize } from '../lib/Constants'
import moment from 'moment'
import { TermsComponent } from '../components/companyprofile/TermsComponent'
import { store } from '../store'
import { loadingActions } from '../store/loading'

const useStyles = makeStyles(theme => ({
  // styles for Profile steps
  firstBox: {
    color: theme.colors.iconBlue,
    height: '56px',
    borderRadius: '10px 0 0 10px',
    border: '0.5px solid ' + theme.colors.profile.borders,
    textAlign: 'center',
    padding: '18px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      height: '66px'
    }
  },
  middleBox: {
    color: theme.colors.invoiceColors.disabled_grey,
    height: '56px',
    border: '0.5px solid ' + theme.colors.profile.borders,
    borderRight: 'none',
    textAlign: 'center',
    padding: '18px',
    cursor: 'not-allowed',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      height: '66px'
    }
  },
  middleCompleteBox: {
    color: theme.colors.iconBlue,
    height: '56px',
    border: '0.5px solid ' + theme.colors.profile.borders,
    textAlign: 'center',
    padding: '18px',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      height: '66px'
    }
  },
  lastBox: {
    color: theme.colors.invoiceColors.disabled_grey,
    height: '56px',
    border: '0.5px solid ' + theme.colors.profile.borders,
    borderRadius: '0 10px 10px 0',
    textAlign: 'center',
    padding: '18px',
    cursor: 'not-allowed',
    [theme.breakpoints.down('md')]: {
      padding: '5px',
      height: '66px'
    }
  },
  lastCompleteBox: {
    color: theme.colors.iconBlue,
    height: '56px',
    borderRadius: '0 10px 10px 0',
    border: '0.5px solid ' + theme.colors.profile.borders,
    textAlign: 'center',
    padding: '18px',
    cursor: 'pointer'
  },
  // page layout
  mainContainer: {
    padding: '20px 0 0 0',
    maxWidth: '100%',
    minHeight: '100%',
    overflowY: 'auto',
    backgroundColor: 'white'
  },
  profileContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  profileComponent: {
    width: '70%',
    minWidth: '1169px',
    marginTop: '20px',
    padding: '20px 0 0 0',
    boxShadow: '6px 9px 43px 0px ' + theme.colors.invoiceColors.shadow,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      width: '100%',
      marginTop: '0',
      padding: '0'
    }
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 84px',
    paddingLeft: '20px'
  },
  termsLabel: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.text
  },
  continueButton: {
    background: theme.colors.iconBlue,
    color: 'white',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '36px',
    width: '160px',
    alignSelf: 'flex-end',
    marginBottom: '37px',
    marginTop: '48px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignSelf: 'center',
      marginRight: 0
    }
  },
  dialogPaper: {
    width: '577px',
    height: '302px',
    borderRadius: '8px'
  },
  dialogTitle: {
    fontWeight: '700',
    fontSize: '24px',
    textAlign: 'center',
    letterSpacing: '0.05em'
  },
  dialogSubtitle: {
    fontWeight: '700',
    fontSize: '18px',
    textAlign: 'center',
    letterSpacing: '0.05em'
  },
  dialogContentFont: {
    fontSize: '18px',
    textAlign: 'center',
    color: theme.colors.basicDisabledButtonColor,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '21px',
    paddingBottom: '20px'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: '10px'
  }
}))

export default function CompanyProfile() {
  const classes = useStyles()
  const { t } = useTranslation()
  const wHeight = getWindowHeight()
  const [currentTab, setCurrentTab] = useState(0)
  const [isComplete, setIsComplete] = useState(false)
  const history = useHistory()
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const userStore = useSelector(state => state.auth.user)
  const [profile, setProfile] = useState(async () => {
    return await getCompanyInfo(userStore.userInfo.company_id)
  })

  const [states, setStates] = useState()
  const [roles, setRoles] = useState([])
  const [roleOptions, setRoleOptions] = useState(null)
  const [completeUsers, setCompleteUsers] = useState('no_users')
  const [openDialog, setOpenDialog] = useState(false)
  const [dispatchError, setDispatchError] = useState(false)
  const [invoiceError, setInvoiceError] = useState(false)
  const [profileError, setProfileError] = useState({
    dispatchError: false,
    invoiceError: false,
    businessPhoneError: false,
    afterHoursPhoneError: false
  })
  const [fileError, setFileError] = useState({
    w9: false,
    coi: false,
    comp: false,
    bank: false,
    contractor_license: false,
    direct_deposit_form: false
  })
  const [logoError, setLogoError] = useState(false)
  const [validHours, setValidHours] = useState(true)
  const [complianceFields, setComplianceFields] = useState({})
  const [hideRates, setHideRates] = useState()
  const [readonlyRates, setReadonlyRates] = useState()
  const [mandatoryRates, setMandatoryRates] = useState()
  const [touched, setTouched] = useState(false)
  const [touched2, setTouched2] = useState(false)
  const [hasTerms, setHasTerms] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [termsContent, setTermsContent] = useState('')
  const [dynamicTrades, setDynamicTrades] = useState()
  const [industriesCatalog, setIndustriesCatalog] = useState()
  const [servicesCatalog, setServicesCatalog] = useState()
  const containerRef = useRef(null)

  useEffect(async () => {
    try {
      setComplianceFields(
        userStore.userInfo?.configurations?.portals?.subcontractor?.onboarding
          .compliance
      )
      const roles = await getRoles(userStore.userInfo.originating_company)
      setRoles(roles)
      if (!roles || roles.length === 0) return

      const updatedRoles = {}
      roles.forEach(item => {
        updatedRoles[item.id] = item.name
      })
      setRoleOptions(updatedRoles)
      'display_rates' in
      userStore?.userInfo?.configurations?.portals?.subcontractor?.onboarding
        ? setHideRates(
            !userStore?.userInfo?.configurations?.portals?.subcontractor
              ?.onboarding.display_rates
          )
        : setHideRates(false)
      'readonly_rates' in
      userStore?.userInfo?.configurations?.portals?.subcontractor?.onboarding
        ? setReadonlyRates(
            userStore?.userInfo?.configurations?.portals?.subcontractor
              ?.onboarding?.readonly_rates
          )
        : setReadonlyRates(false)
      'mandatory_rates' in
      userStore?.userInfo?.configurations?.portals?.subcontractor?.onboarding
        ? setMandatoryRates(
            userStore?.userInfo?.configurations?.portals?.subcontractor
              ?.onboarding?.mandatory_rates
          )
        : setMandatoryRates(false)
    } catch (error) {
      console.error(error)
      setRoles([])
    }
  }, [])

  useEffect(async () => {
    if (complianceFields && 'terms' in complianceFields) {
      const newSteps = [...steps]
      newSteps[4].visible = true
      setHasTerms(true)
      setSteps(newSteps)
      const termsAndConditions = await getTermsAndConditions(
        userStore.userInfo.originating_company,
        'all'
      )
      setTermsContent(termsAndConditions?.content)
    }
  }, [complianceFields])

  async function getCompanyInfo(companyId) {
    try {
      const companyInfo = await getCompanyProfile(companyId)
      setDynamicTrades(companyInfo?.dynamicTrades)

      const allServices = companyInfo?.dynamicTrades?.reduce((result, obj) => {
        Object.entries(obj.services).forEach(([key, value]) => {
          result[key] = value
        })
        return result
      }, {})
      setServicesCatalog(allServices)
      setIndustriesCatalog(companyInfo?.industriesCatalog)
      if (companyInfo) {
        // if there is more than 1 contact redirect since it's not the main contact
        // comment to enable Onboarding process in existing companies
        if (companyInfo.contacts?.length > 1) {
          history.replace('/')
        }
        // set data for profile tab
        const data = {
          update_information: true,
          name: companyInfo.name,
          legal_entity: companyInfo?.legal_entity
            ? companyInfo?.legal_entity
            : '',
          dba: companyInfo?.dba,
          address: { ...companyInfo?.address },
          country: companyInfo?.country ? companyInfo?.country : [],
          support_24_7: companyInfo?.support_24_7 ?? false,
          business_hours: {
            phone: companyInfo?.business_hours?.phone,
            time_from: companyInfo?.business_hours?.time_from,
            time_to: companyInfo?.business_hours?.time_to,
            weekend_days:
              companyInfo?.business_hours?.weekend_days ?? 'weekend',
            weekend_time_from: companyInfo?.business_hours?.weekend_time_from,
            weekend_time_to: companyInfo?.business_hours?.weekend_time_to
          },
          after_hours: {
            phone: companyInfo?.after_hours?.phone,
            time_from: companyInfo?.after_hours?.weekdays?.time_from,
            time_to: companyInfo?.after_hours?.weekdays?.time_to,
            weekend_days: companyInfo?.after_hours?.weekend?.days ?? 'weekend',
            weekend_time_from: companyInfo?.after_hours?.weekend?.time_from,
            weekend_time_to: companyInfo?.after_hours?.weekend?.time_to
          },
          dispatch_email: companyInfo?.dispatch_email,
          invoice_email: companyInfo?.invoice_email,
          logo: {
            url: companyInfo?.logo?.url
          },
          // set data for insurance tab
          ein: companyInfo?.ein,
          states_registered: companyInfo?.states_registered
            ? companyInfo?.states_registered
            : [{ state: '', license: '' }],
          insurance_questions: {
            q_trucks: companyInfo?.insurance_questions?.q_trucks,
            q_field_employees:
              companyInfo?.insurance_questions?.q_field_employees,
            q_administrative_employees:
              companyInfo?.insurance_questions?.q_administrative_employees,
            q_business_years:
              companyInfo?.insurance_questions?.q_business_years,
            q_subcontractors:
              companyInfo?.insurance_questions?.q_subcontractors ?? false
          },
          w9: {
            url: companyInfo?.w9?.url,
            file_name: companyInfo?.w9?.file_name,
            file_uploaded: false
          },
          coi: {
            url: companyInfo?.coi?.url,
            file_name: companyInfo?.coi?.file_name,
            coi_month: companyInfo?.coi?.coi_month
              ? companyInfo?.coi?.coi_month
              : '',
            coi_year: companyInfo?.coi?.coi_year
              ? companyInfo?.coi?.coi_year
              : '',
            coi_policy: companyInfo?.coi?.coi_policy,
            file_uploaded: false
          },
          comp: {
            url: companyInfo?.comp?.url,
            file_name: companyInfo?.comp?.file_name,
            comp_month: companyInfo?.comp?.comp_month
              ? companyInfo?.comp?.comp_month
              : '',
            comp_year: companyInfo?.comp?.comp_year
              ? companyInfo?.comp?.comp_year
              : '',
            comp_policy: companyInfo?.comp?.comp_policy,
            file_uploaded: false
          },
          bank: {
            url: companyInfo?.bank?.url,
            file_name: companyInfo?.bank?.file_name,
            bank_name: companyInfo?.bank?.bank_name,
            bank_account: companyInfo?.bank?.bank_account,
            bank_routing: companyInfo?.bank?.bank_routing,
            payment_options: companyInfo?.bank?.payment_options,
            file_uploaded: false,
            bank_check_payee: companyInfo?.bank?.bank_check_payee
          },
          // set data for clients and trades
          clients: companyInfo?.clients ? [...companyInfo?.clients] : [],
          trades: companyInfo?.trades ? [...companyInfo?.trades] : [],
          affiliateId: companyInfo?.affiliate_id,
          // set data for service areas
          service_area: companyInfo?.service_area
            ? [...companyInfo?.service_area]
            : [],
          // set TC data
          terms_and_conditions: { ...companyInfo?.terms_and_conditions },
          // contractor license
          contractor_license: {
            url: companyInfo?.contractor_license?.url,
            file_name: companyInfo?.contractor_license?.file_name,
            file_uploaded: false
          },
          // direct deposit form
          direct_deposit_form: {
            url: companyInfo?.direct_deposit_form?.url,
            file_name: companyInfo?.direct_deposit_form?.file_name,
            file_uploaded: false
          }
        }
        setAgreeTerms(!!companyInfo?.terms_and_conditions?.signed)
        setProfile(data)
        handleValidations(data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  function getWindowHeight() {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size[1]
  }

  useEffect(() => {
    const items = [...steps]
    if (currentTab >= items.length) {
      return
    }
    if (currentTab > 0) {
      items[currentTab - 1].complete = isComplete
      items[currentTab].view = true
    }
    if (currentTab === 5) {
      items[currentTab].style = { root: classes.lastCompleteBox }
    } else if (currentTab > 0) {
      items[currentTab].style = { root: classes.middleCompleteBox }
    }
    if (currentTab === 3 || currentTab === 4) handleValidations({ ...profile })
    setSteps(items)
  }, [currentTab])

  const [steps, setSteps] = useState([
    {
      label: t('company_profile.company_profile'),
      complete: false,
      view: true,
      visible: true,
      style: { root: classes.firstBox }
    },
    {
      label: t('company_profile.info_insurance'),
      complete: false,
      view: false,
      visible: true,
      style: { root: classes.middleBox }
    },
    {
      label: t('company_profile.clients_trades'),
      complete: false,
      view: false,
      visible: true,
      style: { root: classes.middleBox }
    },
    {
      label: t('company_profile.service_area'),
      complete: false,
      view: false,
      visible: true,
      style: { root: classes.middleBox }
    },
    {
      label: t('company_profile.terms'),
      complete: false,
      view: false,
      visible: false,
      style: { root: classes.middleBox }
    },
    {
      label: t('company_profile.users'),
      complete: false,
      view: false,
      visible: true,
      style: { root: classes.lastBox }
    }
  ])

  useEffect(() => {
    handleValidations(profile)
  }, [fileError, validHours])

  useEffect(async () => {
    // load states/territories
    if (currentTab === 0) {
      setStates(await loadAsyncStates())
    }
    handleValidations(profile)
  }, [profile, agreeTerms])

  const loadAsyncStates = async () => {
    const countries = profile?.country?.length
    let statesArray = []
    if (countries === 1) {
      const statesData = await callLocationApi('POST', '/states', {
        country: profile?.country[0]
      })
      if (statesData?.data?.states) {
        statesArray = parseStateList(statesData?.data?.states)
      }
    } else if (countries > 1) {
      const newCountries = [...profile.country]
      const index = newCountries.indexOf('Mexico')
      if (index > -1) newCountries.splice(newCountries.indexOf('Mexico'), 1)
      newCountries.forEach(async element => {
        const statesData = await callLocationApi('POST', '/states', {
          country: element
        })
        statesArray.push(...parseStateList(statesData?.data?.states))
      })
    }
    return statesArray
  }

  function profileComponent(tab) {
    switch (tab) {
      case 0:
        return (
          <CompanyProfileComponent
            profile={profile}
            handleChange={handleChange}
            handleImageChange={handleImageChange}
            showLogo={true}
            dispatchError={dispatchError}
            invoiceError={invoiceError}
            logoError={logoError}
            helperText={t('company_profile.error.file_size').replace(
              '$size$',
              maxFileSize
            )}
            setValidHours={setValidHours}
            requiredFields={complianceFields?.information?.fields}
            touched={touched}
            setTouched={setTouched}
            touched2={touched2}
            setTouched2={setTouched2}
            profileError={profileError}
          />
        )
      case 1:
        return (
          <InsuranceComponent
            profile={profile}
            handleChange={handleChange}
            handleFileChange={handleFileChange}
            states={states}
            setFileUploaded={setFileUploaded}
            fileError={fileError}
            setFileError={setFileError}
            requiredFields={complianceFields?.insurance?.fields}
            clientId={userStore.userInfo.parent_subdomain}
          />
        )
      case 2:
        return (
          <ClientsComponent
            profile={profile}
            handleChange={handleChange}
            displayClients={
              userStore?.userInfo?.configurations?.portals?.subcontractor
                ?.onboarding?.compliance?.tradesServices?.industries
            }
            catalogClients={industriesCatalog}
            displayTrades={dynamicTrades}
            hideRates={hideRates}
            readonlyRates={readonlyRates}
            mandatoryRates={mandatoryRates}
            servicesCatalog={servicesCatalog}
          />
        )
      case 3: {
        if (containerRef.current) {
          containerRef.current.scrollTop = 0
        }
        return (
          <ServiceComponent profile={profile} handleChange={handleChange} />
        )
      }
      case 4:
        return (
          <TermsComponent
            content={termsContent}
            originatingCompany={userStore.userInfo.originating_company}
            data={profile?.terms_and_conditions}
          />
        )
      case 5:
        return (
          <UsersComponent
            roles={roles}
            roleOptions={roleOptions}
            affiliateId={profile.affiliateId}
            setCompleteUsers={setCompleteUsers}
          />
        )
      default:
        // Redirect to company settings
        history.push({ pathname: 'company-settings' })
        break
    }
  }

  const setProfileLogo = imageData => {
    const data = { ...profile }
    data.logo.url = imageData
    setProfile(data)
  }
  const handleImageChange = event => {
    const file = event.target.files[0]
    const fileSize = file.size / 1024 / 1024
    if (fileSize > maxFileSize) {
      setLogoError(true)
    } else {
      setLogoError(false)
      getBase64(file, result => setProfileLogo(result))
    }
  }

  const setFileUploaded = (fileData, field, fileName) => {
    const data = { ...profile }
    data[field].url = fileData
    data[field].file_name = fileName
    data[field].date_created = moment().unix()
    data[field].file_uploaded = true
    setProfile(data)
    handleValidations(data)
  }
  const handleFileChange = async (file, field) => {
    const errorTmp = { ...fileError }
    const fileSize = file.size / 1024 / 1024
    if (fileSize > maxFileSize) {
      errorTmp[field] = true
      setFileError(errorTmp)
      setFileUploaded('', field, '')
    } else {
      const extension = file.name.split('.').pop()
      const timestamp =
        userStore.userInfo.parent_subdomain === 'mcs360' ? '_' + Date.now() : ''
      const fileName =
        field +
        '_' +
        userStore.userInfo.company_id +
        timestamp +
        '.' +
        extension
      const uploadPath =
        'subcontractor_documents/' +
        userStore.userInfo.company_id +
        '/' +
        field +
        '/' +
        fileName
      errorTmp[field] = false
      setFileError(errorTmp)
      const signedUrl = await getSignedS3Url(uploadPath, file.type, 'putObject')
      // upload file to S3
      const s3Path = await uploadWithSignedUrl(
        signedUrl,
        file,
        uploadPath,
        userStore.userInfo.parent_subdomain
      )
      if (s3Path) {
        setFileUploaded(s3Path, field, file.name)
      } else {
        setFileUploaded('', field, '')
      }
      // hide loading action
      store.dispatch(loadingActions.hide())
    }
  }

  const handleChange = (value, field) => {
    const data = { ...profile }
    if (!field) return
    if (field.match(/coi_.*/)) {
      data.coi[field] = value
      data.coi.file_uploaded = true
    } else if (field.match(/comp_.*/)) {
      data.comp[field] = value
      data.comp.file_uploaded = true
    } else if (field.match(/bank_.*/)) {
      data.bank[field] = value
    } else if (field.match(/q_.*/)) {
      data.insurance_questions[field] = value
    } else if (field === 'country') {
      const countries = typeof value === 'string' ? value.split(',') : value
      data.country = countries
    } else if (field === 'payment_options') {
      data.bank[field] = value
      if (value === 'check') {
        data.bank.bank_account = ''
        data.bank.bank_routing = ''
      } else {
        data.bank.bank_check_payee = ''
      }
    } else if (field === 'terms') {
      setAgreeTerms(value)
      data.terms_and_conditions = value
        ? {
            user_id: userStore?.userId,
            name:
              userStore?.userInfo?.firstName +
              ' ' +
              userStore?.userInfo?.lastName,
            signed_on: new Date().getTime(),
            signed: true
          }
        : {
            signed: false
          }
    } else {
      data[field] = value
    }
    // restore default values
    if (data.support_24_7) {
      data.after_hours = {
        phone: data.after_hours.phone,
        weekend_days: 'weekend',
        time_from: null,
        time_to: null,
        weekend_time_from: null,
        weekend_time_to: null
      }
    }
    setProfile(data)
    handleValidations(data)
  }

  const parseAreaServiceToApi = serviceAreaList => {
    const newServiceAreaArray = []
    for (const serviceArea of serviceAreaList) {
      const newServiceArea = cloneDeep(serviceArea)
      if (newServiceArea?.zip) {
        newServiceArea.zip_length = getSelectedZiCodesNumber(newServiceArea.zip)
        delete newServiceArea.zip
      }
      newServiceAreaArray.push(newServiceArea)
    }
    return newServiceAreaArray
  }

  const handleContinue = async (force = false) => {
    try {
      const newProfile = { ...profile }
      if (currentTab === 5) {
        // TODO validate minimum users are created
        if (completeUsers === 'complete' || force) {
          delete newProfile.affiliateId
        } else {
          setOpenDialog(true)
          return
        }
      }

      if (currentTab === 3) {
        newProfile.service_area = parseAreaServiceToApi(newProfile.service_area)
      }
      // save data on every step
      newProfile.step = currentTab

      // formatted clients for SF
      newProfile.formatted_clients = [...newProfile?.clients]
        .map(client => {
          return industriesCatalog[client]
        })
        .join(', ')

      // formatted states of registration for SF
      newProfile.formatted_states = [...newProfile?.states_registered]
        .map(state => {
          return state.license
            ? state.state + ' - ' + state.license
            : state.state
        })
        .join(', ')

      delete newProfile.affiliateId
      if (newProfile.bank?.payment_options === 'check') {
        delete newProfile.bank?.bank_account
        delete newProfile.bank?.bank_routing
      } else {
        delete newProfile.bank?.bank_check_payee
      }
      // flag needed for SF update
      newProfile.updateSF = true
      // remove map data from service areas
      const serviceAreas = newProfile.service_area
      serviceAreas.forEach(area => {
        delete area.zip
      })
      newProfile.service_area = [...serviceAreas]
      const compliance = calculateCompliance(
        profile,
        userStore?.userInfo?.configurations?.portals?.subcontractor?.onboarding
          .compliance,
        completeUsers === 'complete'
      )
      newProfile.compliance = compliance
      delete newProfile.service_request_enabled
      delete newProfile.request_cancel_reasons
      if (newProfile.configs === null) {
        delete newProfile.configs
      }
      await updateCompany(userStore.userInfo.company_id, newProfile)
      setIsComplete(true)
      // if no terms configuration omit Terms tab
      const step =
        currentTab === 3 && !hasTerms ? currentTab + 2 : currentTab + 1
      setCurrentTab(step)
    } catch (error) {
      console.error(error)
    }
  }

  const handleValidations = data => {
    switch (currentTab) {
      case 0:
        setButtonDisabled(!profileValidation(data))
        break
      case 1:
        setButtonDisabled(!insuranceValidation(data))
        break
      case 2:
        setButtonDisabled(!clientsAndTradesValidation(data))
        break
      case 3:
        setButtonDisabled(!areaServiceValidation(data))
        break
      case 4:
        setButtonDisabled(!data?.terms_and_conditions?.signed)
        break
      case 5:
        setButtonDisabled(false)
        break
      default:
        setButtonDisabled(true)
        break
    }
  }

  const areaServiceValidation = data => {
    if (profile?.country?.length === 1 && profile?.country[0] === 'Mexico') {
      return true
    }
    return data.service_area?.length
  }

  const profileValidation = data => {
    const errors = { ...profileError }
    if (
      (data?.invoice_email && !validateEmail(data?.invoice_email)) ||
      (data?.dispatch_email && !validateEmail(data?.dispatch_email))
    ) {
      setDispatchError(
        data?.dispatch_email && !validateEmail(data?.dispatch_email)
      )
      setInvoiceError(
        data?.invoice_email && !validateEmail(data?.invoice_email)
      )
      return false
    }
    // phone validation
    if (
      data?.business_hours?.phone &&
      !validatePhone(data?.business_hours?.phone)
    ) {
      errors.businessPhoneError = true
      setProfileError(errors)
      return false
    }
    if (data?.after_hours?.phone && !validatePhone(data?.after_hours?.phone)) {
      errors.afterHoursPhoneError = true
      setProfileError(errors)
      return false
    }
    setProfileError({
      dispatchError: false,
      invoiceError: false,
      businessPhoneError: false,
      afterHoursPhoneError: false
    })
    setDispatchError(false)
    setInvoiceError(false)
    const mandatoryValidation = profileMandatoryValidation(
      complianceFields?.information?.fields,
      data
    )
    const checkBusinessHours = checkLaborHours(data?.business_hours)
    const checkAfterhours = checkLaborHours(data?.after_hours)
    return mandatoryValidation && checkBusinessHours && checkAfterhours
  }

  function checkLaborHours(hours) {
    if (hours?.time_from || hours?.time_to) {
      if (validateTime(hours?.time_from, hours?.time_to)) return false
    }
    if (hours?.weekend_time_from || hours?.weekend_time_to) {
      if (validateTime(hours?.weekend_time_from, hours?.weekend_time_to)) {
        return false
      }
    }
    return true
  }

  function validateTime(timeFrom, timeTo) {
    // initialize with same date for comparisson
    const date1 = new Date('2022-01-01 ' + timeFrom)
    const date2 = new Date('2022-01-01 ' + timeTo)
    if (date1.getTime() < date2.getTime()) {
      return false
    } else {
      return true
    }
  }

  const insuranceValidation = data => {
    const d = new Date()
    const currentMonth = d.getMonth() + 1
    const currentYear = d.getFullYear()
    let coiValidation = true
    let compValidation = true
    let stateValidation = false
    const states = data?.states_registered
    states?.length > 1 &&
      states?.forEach(register => {
        if (!register.state) {
          stateValidation = true
        }
      })
    if (data?.coi?.coi_month > 0 || data?.coi?.coi_year > 0) {
      coiValidation = data?.coi?.coi_month && data?.coi?.coi_year
      if (
        currentYear === data?.coi?.coi_year &&
        data?.coi?.coi_month < currentMonth
      ) {
        coiValidation = false
      }
    }
    if (data?.comp?.comp_month > 0 || data?.comp?.comp_year > 0) {
      compValidation = data?.comp?.comp_month && data?.comp?.comp_year
      if (
        currentYear === data?.comp?.comp_year &&
        data?.comp?.comp_month < currentMonth
      ) {
        compValidation = false
      }
    }
    const validateFileError =
      fileError.bank ||
      fileError.coi ||
      fileError.comp ||
      fileError.w9 ||
      fileError.contractor_license ||
      fileError.direct_deposit_form
    const mandatoryValidation = profileMandatoryValidation(
      complianceFields?.insurance?.fields,
      data
    )
    return (
      mandatoryValidation &&
      coiValidation &&
      compValidation &&
      !validateFileError &&
      !stateValidation
    )
  }

  const clientsAndTradesValidation = data => {
    let validateRates = true
    // check mandatory rates
    if (mandatoryRates) {
      let controlRates = true
      validateRates = data?.trades.every(obj => {
        // Check if rates are not an empty string or undefined
        for (const key in obj.rates) {
          if (obj.rates[key] === undefined || obj.rates[key] === '') {
            controlRates = false
            break
          }
        }
        return controlRates
      })
    }
    // check if at least one client and trade are selected
    const validateClients = data?.clients
    const validateTrades = data?.trades
    const validateServices = validateTrades?.filter(
      trade => trade.services.length > 0
    )
    return (
      validateClients?.length > 0 &&
      validateTrades?.length > 0 &&
      validateServices.length > 0 &&
      validateRates
    )
  }

  return (
    <Container
      className={classes.mainContainer}
      style={{ height: wHeight - 60 + 'px' }}
      ref={containerRef}
    >
      <ProfileSteps
        currentStatus={steps}
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
      />
      <Box className={classes.profileContainer}>
        <Box className={classes.profileComponent}>
          {profileComponent(currentTab)}
          <Box className={classes.buttonsContainer}>
            {currentTab === 4 ? (
              <ThemeProvider theme={checkboxTheme}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreeTerms}
                      onChange={event => {
                        handleChange(event.target.checked, 'terms')
                      }}
                    />
                  }
                  label={
                    <Typography className={classes.termsLabel}>
                      {t('company_profile.read_terms')}
                    </Typography>
                  }
                />
              </ThemeProvider>
            ) : (
              <div />
            )}
            <Button
              variant="contained"
              className={classes.continueButton}
              onClick={() => {
                handleContinue()
              }}
              disabled={buttonDisabled}
            >
              {currentTab === 4
                ? t('company_profile.accept')
                : t('company_profile.continue')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => setOpenDialog(false)}
        classes={{ paper: classes.dialogPaper }}
        BackdropProps={{ invisible: true }}
        style={{ zIndex: 2000 }}
        disableAutoFocus={false}
      >
        <DialogTitle
          disableTypography={true}
          classes={{ root: classes.dialogTitle }}
        >
          {completeUsers === 'no_users'
            ? t('company_profile.title.no_users')
            : t('company_profile.title.unsaved_changes')}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {completeUsers === 'no_users' && (
            <FormLabel className={classes.dialogSubtitle}>
              {t('company_profile.labels.no_users')}
            </FormLabel>
          )}
          <FormLabel className={classes.dialogContentFont}>
            {completeUsers === 'no_users'
              ? t('company_profile.labels.no_users_message')
              : t('company_profile.labels.incomplete_users')}
          </FormLabel>

          <Box className={classes.dialogButtonContainer}>
            <ThemeProvider theme={buttonSettingsDisabled}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                type="submit"
                style={{
                  ...outlinedButtonStyle,
                  marginRight: 0
                }}
                onClick={() => setOpenDialog(false)}
              >
                {t('general.labels.cancel')}
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                type="submit"
                style={{
                  ...enableButtonStyle,
                  marginRight: 0
                }}
                onClick={() => {
                  handleContinue(true)
                  setOpenDialog(false)
                }}
              >
                {t('company_profile.continue')}
              </Button>
            </ThemeProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  )
}
