export const general = () => {
  return {
    labels: {
      cancel: 'Cancelar',
      apply: 'Aplicar',
      first_name: 'Nombre',
      last_name: 'Apellido',
      email: 'Email',
      submit: 'Enviar',
      select: 'Seleccionar',
      search: 'Buscar',
      back: 'Volver',
      save: 'Guardar',
      loading: 'Cargando',
      add: 'Añadir',
      photo: 'Foto',
      not_available: 'No Disponible',
      wo: 'OT',
      download: 'Descargar',
      accept: 'Aceptar',
      download_selected: 'Descargar Seleccionados (#)',
      download_all: 'Descargar Todos (Total #)',
      reset_filters: 'Borrar filtros',
      no_items: 'Items no encontrados',
      yes: 'Si',
      no: 'No',
      on: 'Encendido',
      off: 'Apagado',
      continue: 'Continuar',
      next: 'Siguiente',
      confirm: 'Confirmar',
      done: 'Hecho'
    },
    messages: {
      errors: {
        error: 'Error!',
        same_parent: '¡Vaya! Tu dirección de correo electrónico ya está asociada con esta empresa de informes. Por favor, contacta a tu contacto de la empresa para recibir soporte.',
        required: 'Este campo es obligatorio.',
        email: 'El Correo Electrónico introducido no es válido.',
        password_does_not_match: 'Las contraseñas ingresadas no coinciden.',
        no_sites_found:
          'No podemos encontrar lo que busca. Por favor, compruebe sus criterios de búsqueda.',
        size: 'El tamaño del archivo no puede exceder los 25 MB.',
        no_results: 'No hay resultados',
        length_6: 'El campo debe tener al menos 6 caracteres',
        phone: 'Número de teléfono inválido',
        user_creation_title: 'Error de Creación de Usuario',
        user_creation:
          'Un error ha ocurrido al crear el usuario. Por favor intente de nuevo.',
        download_error:
          'Hubo un error al descargar el archivo, por favor contacte con el administrador o el equipo de soporte.'
      },
      navigation: {
        redirect: 'Redireccionar',
        redirect_app: 'Redireccionar a la aplicación'
      }
    },
    switch_company: {
      title: 'Cambiar de compañía?',
      message:
        'Está a punto de cambiar de compañía, asegurese de guardar todo su trabajo antes de realizar el cambio.',
      invite_user:
        'Un usuario con ese correo ya está registrado en la plataforma FieldTech Connect. Desea enviar un correo de invitación a ese usuario para dar acceso a esta compañía?',
      user_exists:
        'Este correo ya está registrado en otra compañía y no puede ser usado nuevamente. Por favor use un correo diferente para este usuario.',
      same_parent: 'Un usuario con este correo electrónico ya existe.'
    },
    delete_user: {
      title: 'Advertencia!',
      message:
        'Está a punto de eliminar este usuario de su compañía. Perderá accesso a este portal y a la aplicación móbil FieldTech Connect.',
      highlight_question: '¿Desea continuar?'
    },
    delete_confirmed: {
      title: 'Éxito!',
      message:
        'Proceso finalizado correctamente. El usuario %EMAIL% ha sido eliminado.'
    },
    popup: {
      alert: 'Alerta',
      send_invite: 'Enviar invitación?',
      invite_accept_redirect:
        'Invitación aceptada. Redirigiendo al portal del subcontratista.',
      invite_accept_change_user:
        'Invitación aceptada. Por favor ingrese al portal con las credenciales correctas.',
      invite_failed:
        'Invitación fallida. Por favor contacte con su administador.',
      send: 'Enviar',
      ok: 'Ok',
      cancel: 'Cancelar'
    },
    format: {
      short_date: 'DD/MM/yyyy',
      time_am_pm: 'hh:mm A',
      short_date_time: 'DD/MM/yyyy hh:mm A',
      filter_date: 'DD/MM',
      full_date: 'DD-MM-YYYY HH:mm:ss Z'
    },
    date_formats: {
      audit_trail_format: 'hh:mm a MM/DD/YY'
    },
    attachment_picker: {
      size_message:
        'El tamaño del archivo supera el límite de 5 MB. Elija un archivo más pequeño.'
    }
  }
}
