// main components
import React from 'react'

// mui components
import { LinearProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    colorPrimary: {
        background: theme.colors.workOrders.buttonPrimary
    },
    barColorPrimary: {
        background: `linear-gradient(to right, ${theme.colors.workOrders.buttonPrimary},${theme.colors.workOrders.gradient},${theme.colors.workOrders.buttonPrimary})`
    },
    dimensions: {
        height: '21px',
        width: '100px'
    }
}))

const LoadingBox = (props) => {
    const classes = useStyles()

    return (
        <LinearProgress
            {...props}
            style={{ ...props }}
            classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary,
                root: classes.dimensions
            }}
        />
    )
}

export default LoadingBox
