import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  InputBase,
  makeStyles
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  switchDialog: {
    borderRadius: '9px',
    padding: '15px'
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '24px',
    alignItems: 'center',
    textAlign: 'center'
  },
  dialogContent: {
    fontSize: '18px',
    color: theme.colors.basicDisabledButtonColor,
    textAlign: 'center'
  },
  dialogHighlightText: {
    fontSize: '18px',
    fontWeight: '700',
    color: theme.colors.basicDisabledButtonColor,
    textAlign: 'center'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '30px',
    width: '100%',
    padding: '0px 20px'
  },
  buttonStyle: {
    borderRadius: '44px',
    textTransform: 'none',
    fontSize: '14.29px',
    padding: '10px 30px',
    fontWeight: 'bold',
    minWidth: '90px',
    height: '42px',
    color: theme.colors.dialog.text_grey,
    '&:hover': {
      backgroundColor: theme.colors.dialog.hover,
      borderColor: theme.colors.dialog.hover,
      boxShadow: 'none'
    }
  },
  okButton: {
    borderRadius: '44px',
    textTransform: 'none',
    fontSize: '14.29px',
    padding: '10px 30px',
    fontWeight: 'bold',
    minWidth: '90px',
    height: '42px',
    color: theme.colors.dialog.text_white,
    border: '0.5px solid ' + theme.colors.dialog.ok_button,
    backgroundColor: theme.colors.dialog.ok_button,
    '&:hover': {
      backgroundColor: theme.colors.dialog.hover,
      borderColor: theme.colors.dialog.hover,
      boxShadow: 'none'
    },
    '&:disabled': {
      backgroundColor: theme.colors.grey_2,
      color: theme.colors.text_grey
    }
  },
  notesTextBox: {
    width: '100%',
    fontSize: '14.29px',
    padding: '10px',
    '& .MuiInputBase-inputMultiline': {
      height: '500px !important'
    }
  },
  switchDialogNotes: {
    borderRadius: '9px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '850px'
  }
}))

export const ConfirmDialog = props => {
  const classes = useStyles()
  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      classes={{
        paper: props.showInputBase
          ? classes.switchDialogNotes
          : classes.switchDialog
      }}
    >
      <DialogTitle>
        <Typography className={classes.dialogTitle}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent style={props.style}>
        <DialogContentText
          classes={{ root: classes.dialogContent }}
          id="alert-dialog-slide-description"
        >
          {props.message}
        </DialogContentText>
        {props.highlightText && (
          <DialogContentText classes={{ root: classes.dialogHighlightText }}>
            {props.highlightText}
          </DialogContentText>
        )}
        {props.showInputBase && (
          <InputBase
            id="dialog-input"
            placeholder={'Describe what you did on your field service visit.'}
            classes={{ root: classes.notesTextBox }}
            multiline
            rows={4}
            fullWidth
            value={props.dialogNotes}
            onChange={props.handleDialogNotesChange}
            inputProps={{ maxLength: 131000 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box
          className={`${classes.buttonsContainer} ${props?.customClasses?.buttonsContainer}`}
        >
          {props.cancellabel && (
            <Button
              variant="outlined"
              className={classes.buttonStyle}
              onClick={props.handleCancel ? props.handleCancel : handleClose}
            >
              {props.cancellabel}
            </Button>
          )}
          {props.acceptlabel && (
            <Button
              variant="outlined"
              className={`${classes.okButton} ${props.customAcceptButtonStyle}`}
              onClick={() => props.handleAccept()}
              disabled={
                props.disableAccept ||
                (props.showInputBase && !props.dialogNotes?.trim())
              }
            >
              {props.acceptlabel}
            </Button>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}
