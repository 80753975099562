/* eslint-disable camelcase */
// main components
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getCompanyProfile,
  getCompanyUsers,
  getTermsAndConditions,
  updateCompany
} from '../services/ApiService'
import {
  getRoles,
  getUser,
  setIframeAccessToken,
  setIframeOriginatingCompany
} from '../lib/Api'

import { RolesCard } from '../components/companysettings/RolesCard'
import { UsersCard } from '../components/companysettings/UsersCard'
import * as CryptoJS from 'crypto-js'

// mui components
import {
  Typography,
  Container,
  makeStyles,
  Box,
  Card,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  ThemeProvider,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { ProfileInfoCard } from '../components/companysettings/ProfileInfoCard'
import { TradesServicesCard } from '../components/companysettings/TradesServicesCard'
import { ServiceAreaCard } from '../components/companysettings/ServiceAreaCard'
import { cloneDeep } from 'lodash'
import { CompanyProfileComponent } from '../components/companyprofile/CompanyProfileComponent'
import { InsuranceComponent } from '../components/companyprofile/InsuranceComponent'
import { ClientsComponent } from '../components/companyprofile/ClientsComponent'
import { ServiceComponent } from '../components/companyprofile/ServiceComponent'
import {
  calculateCompliance,
  clearCacheData,
  getBase64,
  getSelectedZiCodesNumber,
  profileMandatoryValidation,
  serviceAreasArray,
  validateEmail,
  isRunningInIframe,
  getSignedS3Url,
  uploadWithSignedUrl,
  validatePhone
} from '../lib/Global'
import {
  callLocationApi,
  parseStateList,
  setIframeZipAccessToken
} from '../services/ApiLocationService'
import {
  maxFileSize,
  minResolution,
  minRatio,
  maxRatio,
  imageExtensions
} from '../lib/Constants'
import { CompanySwitch } from '../components/CompanySwitchDropDown'
import moment from 'moment'
import { TermsComponent } from '../components/companyprofile/TermsComponent'
import { TermsAndConditionsCard } from '../components/companysettings/TermsAndConditionsCard'
import { checkboxTheme } from '../styles/mui_custom_theme'
import { setIframeUser } from '../services/AuthService'
import { useParams } from 'react-router-dom'
import { store } from '../store'
import { loadingActions } from '../store/loading'

const useStyles = makeStyles(theme => ({
  '@global': {
    '.pac-container': {
      zIndex: '999999 !important',
      width: '303px !important'
    }
  },
  container: {
    padding: '0px',
    margin: '0px',
    maxWidth: '100%',
    width: '100%',
    height: '400px'
  },
  cardsContainer: {
    gap: '3em',
    margin: '0px 47px',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      margin: '0',
      gridGap: '0'
    }
  },
  card: {
    borderRadius: '8px',
    boxShadow: '6px 9px 43px ' + theme.colors.profile.shadowCard,
    marginBottom: '2em',
    padding: '1em'
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    color: theme.colors.text,
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '16px'
    }
  },
  titleContainer: {
    padding: '24px 42px 30px 42px',
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridGap: '10px'
    }
  },
  hideTitleContainer: {
    display: 'none'
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: '700',
    color: theme.colors.text
  },
  avatar: {
    width: '140px',
    height: '140px',
    '&.MuiAvatar-img': {
      objectFit: 'contain'
    }
  },
  emptyAvatar: {
    width: '140px',
    height: '140px',
    backgroundColor: 'white',
    border: '4px solid black'
  },
  editButton: {
    marginLeft: 'auto',
    marginRight: '0',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.05em',
    lineHeight: '19px'
  },
  dialog: {
    width: '80%'
  },
  saveButton: {
    background: theme.colors.iconBlue,
    color: 'white',
    textTransform: 'none',
    fontSize: '20px',
    fontWeight: 'bold',
    borderRadius: '36px',
    width: '160px',
    alignSelf: 'flex-end',
    marginRight: '0',
    marginBottom: '15px',
    marginTop: '15px'
  },
  editComponent: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '70%',
      minWidth: '70%',
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%'
      }
    },
    '& .MuiDialogContent-root': {
      padding: '0px'
    }
  },
  error: {
    color: theme.colors.workOrderColors.no_work_order,
    paddingLeft: '42px'
  },
  compliant: {
    color: theme.colors.workOrderColors.approved,
    paddingLeft: '42px',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0
    }
  },
  dialogTitle: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  dialogText: {
    fontSize: '18px',
    fontWeight: '500'
  },
  dialogSmallText: {
    fontSize: '12px',
    fontWeight: '400'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '30px 84px',
    paddingLeft: '20px'
  },
  background: {
    maxWidth: '100%',
    backgroundColor: theme.colors.complianceBlue,
    height: '400px',
    position: 'absolute',
    top: 0,
    zIndex: -1
  }
}))

const CompanySettings = props => {
  const params = useParams()
  const [isIframe] = useState(isRunningInIframe())
  const { t } = useTranslation()
  const classes = useStyles()
  const userStore = useSelector(state => state.auth.user)
  const [company, setCompany] = useState(null)
  const [updatedCompany, setUpdatedCompany] = useState(null)
  const [roles, setRoles] = useState(null)
  const [ftcUsers, setFtcUsers] = useState([])
  const [mobileUsers, setMobileUsers] = useState([])
  const [compliance, setCompliance] = useState('')
  const [open, setOpen] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [logoData, setLogoData] = useState()
  const [component, setComponent] = useState('')
  const [states, setStates] = useState()
  const [dispatchError, setDispatchError] = useState(false)
  const [invoiceError, setInvoiceError] = useState(false)
  const [fileError, setFileError] = useState({
    w9: false,
    coi: false,
    comp: false,
    bank: false
  })
  const [logoError, setLogoError] = useState()
  const [validHours, setValidHours] = useState(true)
  const [complianceFields, setComplianceFields] = useState({})
  const [hideRates, setHideRates] = useState()
  const [readonlyRates, setReadonlyRates] = useState()
  const [mandatoryRates, setMandatoryRates] = useState()
  const [displayClients, setDisplayClients] = useState()
  const [touched, setTouched] = useState(false)
  const [touched2, setTouched2] = useState(false)
  const [displaySwitch, setDisplaySwitch] = useState(false)

  const [serviceAreas, setServiceAreas] = useState([])
  const [serviceChips, setServiceChips] = useState([])
  const [moreAreas, setMoreAreas] = useState(false)
  const [termsContent, setTermsContent] = useState('')
  const [hasTerms, setHasTerms] = useState(false)
  const [agreeTerms, setAgreeTerms] = useState(false)
  const [logoURL, setLogoURL] = useState('')
  const [usersUpdated, setUsersUpdated] = useState(false)
  const [serviceAreaLoaded, setServiceAreaLoaded] = useState(false)
  const [user, setUser] = useState(null)
  const [servicesCatalog, setServicesCatalog] = useState()
  const [industriesCatalog, setIndustriesCatalog] = useState()
  const [reloadUsers, setReloadUsers] = useState(false)
  const [clientId, setClientId] = useState('')
  const [companyId, setCompanyId] = useState()
  const [profileError, setProfileError] = useState({
    dispatchError: false,
    invoiceError: false,
    businessPhoneError: false,
    afterHoursPhoneError: false
  })

  useEffect(async () => {
    if (isRunningInIframe()) {
      init(params.id, params.access_token)
    } else {
      loadInitialInformation()
    }
    store.dispatch(loadingActions.hide())
  }, [])

  useEffect(() => {
    if (companyId !== 'undefined' && companyId !== 0) {
      updateRoles()
      updateUsers()
    }
  }, [companyId])

  useEffect(async () => {
    setCompliance(calculateComplianceField())
    // load states/territories
    setStates(await loadAsyncStates())

    if (complianceFields && 'terms' in complianceFields) {
      setHasTerms(true)
      const termsAndConditions = await getTermsAndConditions(
        userStore.userInfo.originating_company,
        'all'
      )
      setTermsContent(termsAndConditions?.content)
    }
    if (company?.logo?.url) {
      setLogoURL(company.logo.url)
    }
    setUpdatedCompany({ ...company })
  }, [company])

  useEffect(() => {
    setCompliance(calculateComplianceField())
  }, [updatedCompany])

  useEffect(() => {
    setCompliance(calculateComplianceField())
    const calculatedCompliance = calculateComplianceField()?.split('%')[0]
    if (
      updatedCompany?.compliance !== 100 &&
      ftcUsers?.length + mobileUsers?.length > 1 &&
      Number(calculatedCompliance) !== updatedCompany?.compliance &&
      Object.keys(updatedCompany).length > 0
    ) {
      handleSave()
    }
  }, [ftcUsers, mobileUsers])

  useEffect(() => {
    handleValidations(updatedCompany)
  }, [fileError, validHours])

  useEffect(async () => {
    const areas = company?.service_area
    if (areas?.length > 0 && !serviceAreaLoaded) {
      setServiceAreaLoaded(true)
      loadServiceAreas(areas)
    }
  }, [updatedCompany])

  useEffect(() => {
    if (reloadUsers) {
      updateUsers()
    }
  }, [reloadUsers])

  const getCurrentUser = () => {
    let currentUser = userStore
    if (isIframe) {
      currentUser = {
        userInfo: user
      }
    }
    return currentUser
  }
  const loadInitialInformation = async initialUser => {
    const currentUser =
      typeof initialUser !== 'undefined'
        ? { userInfo: initialUser }
        : getCurrentUser()
    const companyIdTmp = isIframe
      ? currentUser?.userInfo?.lastLoginCompany
      : currentUser?.userInfo?.company_id
    setCompanyId(companyIdTmp)
    if (currentUser?.userInfo?.companyIds?.length > 1 && !isIframe) {
      setDisplaySwitch(true)
    } else {
      setDisplaySwitch(false)
    }
    setClientId(currentUser?.userInfo?.parent_subdomain)

    try {
      clearCacheData()
      setLogoURL(null)
      // compliance
      setComplianceFields(
        currentUser.userInfo?.configurations?.portals?.subcontractor?.onboarding
          ?.compliance
      )
      const response = await getCompanyProfile(companyIdTmp)

      const allServices = response?.dynamicTrades?.reduce((result, obj) => {
        Object.entries(obj.services).forEach(([key, value]) => {
          result[key] = value
        })
        return result
      }, {})
      setServicesCatalog(allServices)
      setIndustriesCatalog(response?.industriesCatalog)
      const fieldsToCheck = [
        'bank',
        'coi',
        'comp',
        'contractor_license',
        'direct_deposit_form',
        'w9'
      ]
      fieldsToCheck.forEach(field => {
        if (Object.hasOwnProperty.call(response, field)) {
          response[field] && (response[field].file_uploaded = false)
        }
      })
      setCompany(response)
      if (response?.service_area?.length > 0) {
        loadServiceAreas(response?.service_area)
      }
    } catch (error) {
      console.error('Error retrieving company profile: ', error)
    }

    currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding &&
    'display_rates' in
      currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding
      ? setHideRates(
          !currentUser?.userInfo?.configurations?.portals?.subcontractor
            ?.onboarding?.display_rates
        )
      : setHideRates(false)

    currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding &&
    'readonly_rates' in
      currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding
      ? setReadonlyRates(
          currentUser?.userInfo?.configurations?.portals?.subcontractor
            ?.onboarding?.readonly_rates
        )
      : setReadonlyRates(false)

    currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding &&
    'mandatory_rates' in
      currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding
      ? setMandatoryRates(
          currentUser?.userInfo?.configurations?.portals?.subcontractor
            ?.onboarding?.mandatory_rates
        )
      : setMandatoryRates(false)

    currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding
      ?.compliance?.tradesServices &&
    'industries' in
      currentUser?.userInfo?.configurations?.portals?.subcontractor?.onboarding
        ?.compliance?.tradesServices
      ? setDisplayClients(
          currentUser?.userInfo?.configurations?.portals?.subcontractor
            ?.onboarding?.compliance?.tradesServices?.industries
        )
      : setDisplayClients([])

    if (currentUser?.userInfo?.companyIds?.length > 1 && !isRunningInIframe()) {
      setDisplaySwitch(true)
    } else {
      setDisplaySwitch(false)
    }
  }

  useEffect(() => {
    if (usersUpdated) {
      updateUsers()
      setUsersUpdated(false)
    }
  }, [usersUpdated])

  const loadServiceAreas = areas => {
    setMoreAreas(areas?.length > 5)
    setServiceAreas(areas.slice(0, 5))
    setServiceChips(serviceAreasArray(areas.slice(0, 5)))
  }

  const loadAsyncStates = async () => {
    const countries = company?.country?.length
    let statesArray = []
    if (countries === 1) {
      const statesData = await callLocationApi('POST', '/states', {
        country: company?.country[0]
      })
      if (statesData?.data?.states) {
        statesArray = parseStateList(statesData?.data?.states)
      }
    } else if (countries > 1) {
      const newCountries = [...company.country]
      const index = newCountries.indexOf('Mexico')
      if (index > -1) newCountries.splice(newCountries.indexOf('Mexico'), 1)
      newCountries.forEach(async element => {
        const statesData = await callLocationApi('POST', '/states', {
          country: element
        })
        statesArray.push(...parseStateList(statesData?.data?.states))
      })
    }
    return statesArray
  }

  const updateRoles = async () => {
    try {
      const response = await getRoles(companyId)
      setRoles(response)
    } catch (error) {
      console.error(error)
      setRoles([])
    }
  }

  const updateUsers = async () => {
    try {
      if (typeof companyId === 'undefined') return
      setMobileUsers([])
      setFtcUsers([])
      const response = await getCompanyUsers(companyId)
      const mobile = response.filter(
        user => !user.roles || user.roles === 'no_value'
      )
      setMobileUsers(mobile)
      const ftc = response.filter(
        user => user.roles && user.roles !== 'no_value'
      )
      setFtcUsers(ftc)
    } catch (error) {
      console.error(error)
      setMobileUsers([])
      setFtcUsers([])
    }
  }

  const handleClose = () => {
    setOpen(false)
    setOpenConfirm(false)
    setAgreeTerms(false)
    setUpdatedCompany({ ...company })
    if (component === 'service') {
      setServiceAreaLoaded(false)
    }
  }
  const handleOpen = () => {
    setUpdatedCompany({ ...company })
    setOpen(true)
  }
  const parseAreaServiceToApi = serviceAreaList => {
    const newServiceAreaArray = []
    for (const serviceArea of serviceAreaList) {
      const newServiceArea = cloneDeep(serviceArea)
      if (newServiceArea.zip) {
        newServiceArea.zip_length = getSelectedZiCodesNumber(newServiceArea.zip)
        delete newServiceArea.zip
      }
      newServiceAreaArray.push(newServiceArea)
    }
    return newServiceAreaArray
  }

  const handleSave = async () => {
    try {
      const newProfile = { ...updatedCompany }
      delete newProfile.company
      delete newProfile.id
      delete newProfile.external_token
      delete newProfile.client_ids

      newProfile.update_information = true
      // set step to control file upload only on Insurance info edit
      if (component === 'insurance') {
        newProfile.step = 1
      }
      if (component === 'service') {
        // remove polygon data from service areas
        const serviceAreas = parseAreaServiceToApi(newProfile.service_area)
        serviceAreas?.forEach(area => {
          delete area.zip
        })
        newProfile.service_area = serviceAreas ? [...serviceAreas] : []
      } else {
        delete newProfile.service_area
      }
      // format fields to save on SF
      if (newProfile?.clients) {
        const industriesKeysArray = Object.keys(industriesCatalog)
        const filteredClients = newProfile.clients.filter(client => {
          return industriesKeysArray?.includes(client)
        })
        newProfile.clients = filteredClients
        newProfile.formatted_clients = [...newProfile?.clients]
          .map(client => {
            return industriesCatalog[client]
          })
          .join(', ')
      }
      if (newProfile?.states_registered) {
        newProfile.formatted_states = [...newProfile?.states_registered]
          .map(state => {
            return state.license
              ? state.state + ' - ' + state.license
              : state.state
          })
          .join(', ')
      }
      // flag needed for SF update
      newProfile.updateSF = true

      // chage email flag to avoid multiple emails
      newProfile.contacts = newProfile?.contacts?.map(contact => ({
        ...contact,
        send_email: false
      }))
      newProfile.compliance = calculateComplianceField()
        ? parseInt(calculateComplianceField().split('%')[0])
        : undefined
      delete newProfile.dynamicTrades
      delete newProfile.industriesCatalog
      delete newProfile.service_request_enabled
      delete newProfile.request_cancel_reasons
      if (newProfile.configs === null) {
        delete newProfile.configs
      }
      await updateCompany(companyId, newProfile)
      // get updated object
      const response = await getCompanyProfile(companyId)
      if (response.bank) {
        response.bank.file_uploaded = false
      }
      if (response.coi) {
        response.coi.file_uploaded = false
      }
      if (response.comp) {
        response.comp.file_uploaded = false
      }
      if (response.contractor_license) {
        response.contractor_license.file_uploaded = false
      }
      if (response.direct_deposit_form) {
        response.direct_deposit_form.file_uploaded = false
      }
      if (response.w9) {
        response.w9.file_uploaded = false
      }
      setCompany(response)
      handleClose()
    } catch (error) {
      console.error(error)
    }
  }

  function editComponent(targetComponent) {
    switch (targetComponent) {
      case 'profile':
        return (
          <CompanyProfileComponent
            profile={updatedCompany}
            handleChange={handleChange}
            showLogo={false}
            dispatchError={dispatchError}
            invoiceError={invoiceError}
            setValidHours={setValidHours}
            requiredFields={complianceFields?.information?.fields}
            touched={touched}
            setTouched={setTouched}
            touched2={touched2}
            setTouched2={setTouched2}
            profileError={profileError}
          />
        )
      case 'insurance':
        return (
          <InsuranceComponent
            profile={updatedCompany}
            handleChange={handleChange}
            states={states}
            handleFileChange={handleFileChange}
            setFileUploaded={setFileUploaded}
            fileError={fileError}
            setFileError={setFileError}
            requiredFields={complianceFields?.insurance?.fields}
            clientId={userStore.userInfo.parent_subdomain}
          />
        )
      case 'trades':
        return (
          <ClientsComponent
            profile={updatedCompany}
            handleChange={handleChange}
            displayClients={displayClients}
            catalogClients={industriesCatalog}
            displayTrades={updatedCompany?.dynamicTrades}
            hideRates={hideRates}
            readonlyRates={!isIframe && readonlyRates}
            mandatoryRates={mandatoryRates}
            servicesCatalog={servicesCatalog}
          />
        )
      case 'service':
        return (
          <ServiceComponent
            profile={updatedCompany}
            handleProfileChange={setUpdatedCompany}
            handleChange={handleChange}
          />
        )
      case 'terms':
        return (
          <TermsComponent
            content={termsContent}
            originatingCompany={userStore.userInfo.originating_company}
            data={updatedCompany?.terms_and_conditions}
          />
        )
      default:
        break
    }
  }

  const handleChange = (value, field) => {
    const data = { ...updatedCompany }
    if (!field) return
    if (field.match(/coi_.*/)) {
      const tempData = { ...data?.coi }
      tempData[field] = value
      data.coi = { ...tempData }
      data.coi.file_uploaded = true
    } else if (field.match(/comp_.*/)) {
      const tempData = { ...data?.comp }
      tempData[field] = value
      data.comp = { ...tempData }
      data.comp.file_uploaded = true
    } else if (field.match(/bank_.*/)) {
      const tempData = { ...data?.bank }
      tempData[field] = value
      data.bank = { ...tempData }
    } else if (field.match(/q_.*/)) {
      const tempData = { ...data?.insurance_questions }
      tempData[field] = value
      data.insurance_questions = { ...tempData }
    } else if (field === 'country') {
      const countries = typeof value === 'string' ? value.split(',') : value
      data.country = countries
    } else if (field === 'payment_options') {
      const tempData = { ...data?.bank }
      tempData[field] = value
      if (value === 'check') {
        tempData.bank_account = ''
        tempData.bank_routing = ''
      } else {
        tempData.bank_check_payee = ''
      }
      data.bank = { ...tempData }
    } else if (field === 'terms') {
      setAgreeTerms(value)
      data.terms_and_conditions = value
        ? {
            user_id: userStore?.userId,
            name:
              userStore?.userInfo?.firstName +
              ' ' +
              userStore?.userInfo?.lastName,
            signed_on: new Date().getTime(),
            signed: true
          }
        : {
            signed: false
          }
    } else {
      data[field] = value
    }
    // restore default values
    if (data.support_24_7) {
      data.after_hours = {
        phone: data.after_hours.phone,
        weekend_days: 'weekend',
        time_from: null,
        time_to: null,
        weekend_time_from: null,
        weekend_time_to: null
      }
    }
    setUpdatedCompany({ ...data })
    handleValidations(data)
  }

  const setFileUploaded = (fileData, field, fileName) => {
    const data = { ...updatedCompany }
    const tempData = { ...data[field] }
    tempData.url = fileData
    tempData.file_name = fileName
    tempData.date_created = moment().unix()
    tempData.file_uploaded = true
    data[field] = { ...tempData }
    setUpdatedCompany({ ...data })
    handleValidations(data)
  }
  const handleFileChange = async (file, field) => {
    const clientId = userStore.userInfo.parent_subdomain
    const errorTmp = { ...fileError }
    const fileSize = file.size / 1024 / 1024

    if (fileSize > maxFileSize) {
      errorTmp[field] = true
      setFileError(errorTmp)
      setFileUploaded('', field, '')
    } else {
      const extension = file.name.split('.').pop()
      const timestamp =
        userStore.userInfo.parent_subdomain === 'mcs360' ? '_' + Date.now() : ''
      const fileName = field + '_' + companyId + timestamp + '.' + extension
      const uploadPath =
        'subcontractor_documents/' + companyId + '/' + field + '/' + fileName
      errorTmp[field] = false
      setFileError(errorTmp)
      // generate S3 presigned URL for file upload
      const signedUrl = await getSignedS3Url(uploadPath, file.type, 'putObject')
      // upload file to S3
      const s3Path = await uploadWithSignedUrl(
        signedUrl,
        file,
        uploadPath,
        clientId
      )
      if (s3Path) {
        setFileUploaded(s3Path, field, file.name)
      } else {
        setFileUploaded('', field, '')
      }
      // hide loading action
      store.dispatch(loadingActions.hide())
    }
  }

  function equalObjects(object1, object2) {
    if (!object1 || !object2) return
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)
    if (keys1.length !== keys2.length) {
      return false
    }
    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = isObject(val1) && isObject(val2)
      if (
        (areObjects && !equalObjects(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false
      }
    }
    return true
  }
  function isObject(object) {
    return object != null && typeof object === 'object'
  }

  const calculateComplianceField = () => {
    const total = calculateCompliance(
      updatedCompany,
      userStore?.userInfo?.configurations?.portals?.subcontractor?.onboarding
        ?.compliance,
      mobileUsers.length + ftcUsers.length > 1
    )
    return total + '% ' + t('company_settings.compliant')
  }

  const handleImageChange = async event => {
    const file = event.target.files[0]
    const fileSize = file.size / 1024 / 1024
    if (file) {
      const reader = new FileReader()

      reader.onload = e => {
        const image = new Image()
        image.src = e.target.result

        image.onload = async () => {
          // check file size
          if (fileSize > maxFileSize) {
            setLogoError(
              t('company_profile.error.file_size').replace(
                '{size}',
                maxFileSize
              )
            )
            setOpenConfirm(true)
            return
          }
          // check file resolution
          if (image.width < 1024 || image.height < 1024) {
            setLogoError(
              t('company_profile.error.file_resolution').replace(
                '{res}',
                minResolution
              )
            )
            setOpenConfirm(true)
            return
          }
          // check image aspect ratio
          if (
            minRatio > image.width / image.height ||
            image.width / image.height > maxRatio
          ) {
            setLogoError(t('company_profile.error.file_aspect_ratio'))
            setOpenConfirm(true)
            return
          }
          setLogoError(null)
          try {
            getBase64(file, result => openConfirmDialog(result))
          } catch (error) {
            console.log(error)
          }
        }
      }
      reader.readAsDataURL(file)
    }
    event.target.value = null
  }

  const openConfirmDialog = imageData => {
    setLogoData(imageData)
    setOpenConfirm(true)
  }

  const updateProfileLogo = async () => {
    const data = { ...company, logo: company?.logo ?? { url: '' } }
    data.logo.url = logoData
    data.updateSF = true
    setUpdatedCompany(data)
    handleClose()
    try {
      delete data.company
      delete data.id
      delete data.external_token
      delete data.client_ids
      delete data.dynamicTrades
      delete data.industriesCatalog
      delete data.service_request_enabled
      delete data.request_cancel_reasons
      delete data.service_area
      if (data.configs === null) {
        delete data.configs
      }
      data.compliance = calculateComplianceField()
        ? parseInt(calculateComplianceField().split('%')[0])
        : undefined
      await updateCompany(companyId, data)
      setCompany({
        ...company,
        logo: { ...data.logo },
        compliance: data.compliance
      })
      setLogoURL(data.logo.url)
    } catch (error) {
      console.error(error)
    }
  }
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const handleValidations = data => {
    switch (component) {
      case 'profile':
        setButtonDisabled(!profileValidation(data))
        break
      case 'insurance':
        setButtonDisabled(!insuranceValidation(data))
        break
      case 'trades':
        setButtonDisabled(!clientsAndTradesValidation(data))
        break
      case 'service':
        setButtonDisabled(!serviceAreaValidation(data))
        break
      case 'terms':
        setButtonDisabled(!data?.terms_and_conditions?.signed)
        break
      default:
        setButtonDisabled(true)
        break
    }
  }

  const profileValidation = data => {
    const errors = { ...profileError }
    if (
      (data?.invoice_email && !validateEmail(data?.invoice_email)) ||
      (data?.dispatch_email && !validateEmail(data?.dispatch_email))
    ) {
      setDispatchError(
        data?.dispatch_email && !validateEmail(data?.dispatch_email)
      )
      setInvoiceError(
        data?.invoice_email && !validateEmail(data?.invoice_email)
      )
      return false
    }
    // phone validation
    if (
      data?.business_hours?.phone &&
      !validatePhone(data?.business_hours?.phone)
    ) {
      errors.businessPhoneError = true
      setProfileError(errors)
      return false
    }
    if (data?.after_hours?.phone && !validatePhone(data?.after_hours?.phone)) {
      errors.afterHoursPhoneError = true
      setProfileError(errors)
      return false
    }
    setProfileError({
      dispatchError: false,
      invoiceError: false,
      businessPhoneError: false,
      afterHoursPhoneError: false
    })
    setDispatchError(false)
    setInvoiceError(false)
    const mandatoryValidation = isRunningInIframe()
      ? true
      : profileMandatoryValidation(complianceFields?.information?.fields, data)
    return mandatoryValidation && validHours
  }

  const insuranceValidation = data => {
    const d = new Date()
    const currentMonth = d.getMonth() + 1
    const currentYear = d.getFullYear()
    let coiValidation = true
    let compValidation = true
    let stateValidation = false
    const states = data?.states_registered
    states?.length > 1 &&
      states?.forEach(register => {
        if (!register.state) {
          stateValidation = true
        }
      })
    if (data?.coi?.coi_month > 0 || data?.coi?.coi_year > 0) {
      coiValidation = data?.coi?.coi_month && data?.coi?.coi_year
      if (
        currentYear === data?.coi?.coi_year &&
        data?.coi?.coi_month < currentMonth
      ) {
        coiValidation = false
      }
    }
    if (data?.comp?.comp_month > 0 || data?.comp?.comp_year > 0) {
      compValidation = data?.comp?.comp_month && data?.comp?.comp_year
      if (
        currentYear === data?.comp?.comp_year &&
        data?.comp?.comp_month < currentMonth
      ) {
        compValidation = false
      }
    }
    const validateFileError =
      fileError.bank || fileError.coi || fileError.comp || fileError.w9

    const mandatoryValidation = isRunningInIframe()
      ? true
      : profileMandatoryValidation(complianceFields?.insurance?.fields, data)
    return (
      mandatoryValidation &&
      coiValidation &&
      compValidation &&
      !validateFileError &&
      !stateValidation
    )
  }

  const clientsAndTradesValidation = data => {
    let validateRates = true
    // check mandatory rates
    if (mandatoryRates) {
      let controlRates = true
      validateRates = data?.trades.every(obj => {
        // Check if rates are not an empty string or undefined
        for (const key in obj.rates) {
          if (obj.rates[key] === undefined || obj.rates[key] === '') {
            controlRates = false
            break
          }
        }
        return controlRates
      })
    }
    // check if at least one client and trade are selected
    const validateClients = data?.clients
    const validateTrades = data?.trades
    const validateServices = validateTrades?.filter(
      trade => trade.services.length > 0
    )
    return (
      validateClients?.length > 0 &&
      validateTrades?.length > 0 &&
      validateServices.length > 0 &&
      validateRates
    )
  }

  const serviceAreaValidation = data => {
    return data.service_area?.length
  }

  const base64ToArrayBuffer = base64 => {
    // eslint-disable-next-line
    const binary_string = atob(base64)
    // eslint-disable-next-line
    const len = binary_string.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  const arrayBufferToBase64 = arrayBuffer => {
    return btoa(
      new Uint8Array(arrayBuffer).reduce(function (data, byte) {
        return data + String.fromCharCode(byte)
      }, '')
    )
  }

  // eslint-disable-next-line
  const init = async (id, access_token) => {
    // Decrypt token
    // gets the IV from the encrypted string
    const arrayBuffer = base64ToArrayBuffer(decodeURIComponent(access_token))
    const iv = CryptoJS.enc.Base64.parse(
      arrayBufferToBase64(arrayBuffer.slice(0, 16))
    )
    const encryptedStr = arrayBufferToBase64(
      arrayBuffer.slice(16, arrayBuffer.byteLength)
    )

    const aesOptions = {
      iv: iv,
      mode: CryptoJS.mode.CBC
    }

    const decryptObj = CryptoJS.AES.decrypt(
      encryptedStr,
      CryptoJS.enc.Base64.parse('Wm/vchJxyEW9xEYZ6JmXcw=='),
      aesOptions
    )

    const accessToken = decryptObj.toString(CryptoJS.enc.Utf8)
    setIframeAccessToken(accessToken)
    setIframeZipAccessToken(accessToken)

    try {
      setIframeOriginatingCompany(params.id)
      const currentUser = await getUser(true)
      setUser(currentUser)
      setIframeUser(accessToken, currentUser)
      loadInitialInformation(currentUser)
      window.parent.postMessage('iframeContentLoaded', '*')
    } catch (error) {
      // force reload in case of error retreiving data.
      console.error(
        'Failed to retrieve information, page will be reloaded. ',
        error
      )
      location.reload()
    }
  }

  return (
    <Container className={classes.container}>
      <Box
        className={classes.background}
        style={{
          width:
            compliance?.length > 0 ? compliance.split('%')[0] + '%' : '100%'
        }}
      />
      <Box
        display="flex"
        flexDirection="row"
        className={
          isIframe ? classes.hideTitleContainer : classes.titleContainer
        }
      >
        {!isIframe && (
          <Typography classes={{ root: classes.title }}>
            {t('company_settings.title')}
          </Typography>
        )}
        <Typography
          className={`${classes.title} ${
            typeof compliance !== 'undefined' && compliance?.includes('100%')
              ? classes.compliant
              : classes.error
          }`}
        >
          {compliance?.includes('undefined') ? '' : compliance}
        </Typography>
        <Box flex={1} />
        {displaySwitch && <CompanySwitch />}
      </Box>
      <Box display="flex" className={classes.cardsContainer}>
        <Box flex={1}>
          {/* Logo card */}
          <Card className={classes.card}>
            <Box display="flex" flexDirection="row">
              <Avatar
                alt="profile"
                src={logoURL}
                className={
                  updatedCompany?.logo?.url
                    ? classes.avatar
                    : classes.emptyAvatar
                }
                imgProps={{ style: { objectFit: 'contain' } }}
              >
                {' '}
              </Avatar>
              <label htmlFor="profile-logo" className={classes.editButton}>
                <Button
                  id="profile-logo"
                  component="label"
                  className={classes.editButton}
                >
                  {t('company_settings.buttons.edit')}
                  <input
                    hidden
                    accept={imageExtensions.join(', ')}
                    type="file"
                    onChange={handleImageChange}
                  />
                </Button>
              </label>
            </Box>
          </Card>
          {/* Profile card */}
          <ProfileInfoCard
            profile={company}
            setOpen={handleOpen}
            setComponent={setComponent}
            clientId={clientId}
          />
          {
            /* terms and conditions */
            hasTerms && (
              <TermsAndConditionsCard
                setOpen={handleOpen}
                setComponent={setComponent}
                content={termsContent}
                originatingCompany={userStore.userInfo.originating_company}
                data={company?.terms_and_conditions}
              />
            )
          }
          {/* trades & services card */}
          <TradesServicesCard
            setOpen={handleOpen}
            setComponent={setComponent}
            hideRates={hideRates}
            clients={company?.clients}
            catalogClients={industriesCatalog}
            trades={company?.trades}
            displayTrades={updatedCompany?.dynamicTrades}
            servicesCatalog={servicesCatalog}
          />
        </Box>
        <Box flex={1} marginBottom="4em">
          <ServiceAreaCard
            serviceAreas={serviceAreas}
            setOpen={handleOpen}
            setComponent={setComponent}
            chips={serviceChips}
            moreAreas={moreAreas}
          />
          {/* Web portal users */}
          <UsersCard
            roles={roles}
            company={company}
            cardtitle={t('company_settings.card.web_users')}
            users={ftcUsers}
            updateUsers={updateUsers}
            mobile={false}
            setReloadUsers={setReloadUsers}
          />
          {/* Mobile only users */}
          <UsersCard
            roles={roles}
            company={company}
            cardtitle={t('company_settings.card.field_users')}
            users={mobileUsers}
            updateUsers={updateUsers}
            mobile={true}
            setReloadUsers={setReloadUsers}
          />
          <RolesCard updateRoles={updateRoles} roles={roles} />
        </Box>
      </Box>
      {/* Edit profile dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="body"
        className={classes.editComponent}
      >
        <DialogContent dividers={scroll === 'paper'}>
          {editComponent(component)}
        </DialogContent>
        <Box className={classes.buttonsContainer}>
          {component === 'terms' && !company?.terms_and_conditions?.signed ? (
            <ThemeProvider theme={checkboxTheme}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreeTerms}
                    onChange={event => {
                      handleChange(event.target.checked, 'terms')
                    }}
                  />
                }
                label={
                  <Typography className={classes.dialogSmallText}>
                    {t('company_profile.read_terms')}
                  </Typography>
                }
              />
            </ThemeProvider>
          ) : (
            <div />
          )}
          {component === 'terms' && company?.terms_and_conditions?.signed ? (
            <Typography className={classes.dialogSmallText}>
              {'Accepted by ' +
                company?.terms_and_conditions?.name +
                ' on ' +
                moment(
                  new Date(company?.terms_and_conditions?.signed_on)
                ).format(t('general.format.short_date')) +
                ' at ' +
                moment(
                  new Date(company?.terms_and_conditions?.signed_on)
                ).format(t('general.format.time_am_pm'))}
            </Typography>
          ) : (
            <Button
              variant="contained"
              onClick={handleSave}
              className={classes.saveButton}
              disabled={equalObjects(company, updatedCompany) || buttonDisabled}
            >
              {component === 'terms'
                ? t('company_profile.accept')
                : t('company_settings.card.save')}
            </Button>
          )}
        </Box>
      </Dialog>
      <Dialog
        open={openConfirm}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography classes={{ root: classes.dialogTitle }}>
            {t('company_settings.card.update')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className={classes.dialogText}
          >
            {logoError ?? t('company_settings.card.update_question')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('company_settings.card.cancel')}
          </Button>
          {logoError ? (
            ''
          ) : (
            <Button onClick={async () => await updateProfileLogo()} autoFocus>
              {t('company_settings.card.save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default CompanySettings
