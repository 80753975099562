import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'

/** Material UI **/
import {
  CustomTextField,
  HighlightButton
} from '../../styles/mui_custom_components'
import { LockOutlined } from '@material-ui/icons'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core'

/** Validations **/
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

/** Redux **/
import { useDispatch } from 'react-redux'
import { loadingActions } from '../../store/loading'

/** Services **/
import { login, resetPassword } from '../../services/AuthService'
import { handleSpace } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  mainGrid: {
    minHeight: '70vh',
    transform: 'scale(0.8)'
  },
  mainItem: {
    maxWidth: '30em',
    paddingTop: '4em'
  },
  icon: {
    width: '1em',
    position: 'relative',
    top: '-4px'
  },
  title: {
    fontWeight: '700',
    fontSize: '30px'
  },
  subtitle: {
    marginTop: '30px',
    fontWeight: '300',
    fontSize: '20px'
  },
  buttons: {
    marginTop: theme.spacing(4)
  }
}))

const ForgotPasswordCode = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [newPassword, setNewPassword] = useState('')
  const [reEnterNewPassword, setReEnterNewPassword] = useState('')
  const [isValidPassword, setIsValidPassword] = useState()

  /** VALIDATIONS **/
  const validationSchema = yup.object().shape({
    code: yup.string().required(t('general.messages.errors.required')),
    new_password: yup
      .string()
      .required(t('general.messages.errors.required'))
      .min(6, t('general.messages.errors.length_6')),
    re_enter_new_password: yup
      .string()
      .required(t('general.messages.errors.required'))
      .oneOf(
        [yup.ref('new_password'), null],
        t('general.messages.errors.password_does_not_match')
      )
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    resetOptions: {
      keepErrors: false
    }
  })

  const onSubmit = async () => {
    try {
      dispatch(loadingActions.show())
      const queryParams = new URLSearchParams(window.location.search)
      const selector = queryParams.get('selector')
      const token = queryParams.get('token')
      const apiToken = queryParams.get('apiToken')
      const user = queryParams.get('u')
      await resetPassword(
        selector,
        token,
        newPassword,
        reEnterNewPassword,
        apiToken
      )
      await login(user, newPassword)
      dispatch(loadingActions.hide())
    } catch (error) {
      console.error(error)
      dispatch(loadingActions.hide())
    }
  }

  const handleNewPasswordChange = event => {
    setNewPassword(event.target.value)
  }

  const handleReEnterNewPasswordChange = event => {
    setReEnterNewPassword(event.target.value)
  }

  useEffect(() => {
    // Password Validation
    if (newPassword !== reEnterNewPassword || newPassword.length < 6) {
      setIsValidPassword(false)
    } else {
      setIsValidPassword(true)
    }
  }, [newPassword, reEnterNewPassword])

  return (
    <div>
      <Grid
        data-testid={'forgot_password_code_page'}
        className={classes.mainGrid}
        container
        spacing={0}
        direction="column"
        alignItems="center"
      >
        <Grid className={classes.mainItem} item xs={12}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography
                align="center"
                className={classes.title}
                component="h4"
                variant="h4"
              >
                {t('forgot_password_code.title')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                className={classes.subtitle}
                component="h4"
                variant="h4"
              >
                {t('forgot_password_code.subtitle_1')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mt={4}>
                <form
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <CustomTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    id="new_password"
                    placeholder={t('forgot_password_code.new_password')}
                    name="new_password"
                    error={!!errors.new_password}
                    helperText={
                      errors.new_password && errors.new_password.message
                    }
                    {...register('new_password')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlined />
                        </InputAdornment>
                      )
                    }}
                    onInput={handleNewPasswordChange}
                    onFocus={e => e.target.select()}
                    onKeyDown={e => handleSpace(e)}
                  />

                  <CustomTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    name="re_enter_new_password"
                    placeholder={t(
                      'forgot_password_code.re_enter_new_password'
                    )}
                    id="re_enter_new_password"
                    error={!!errors.re_enter_new_password}
                    helperText={
                      errors.re_enter_new_password &&
                      errors.re_enter_new_password.message
                    }
                    {...register('re_enter_new_password')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockOutlined />
                        </InputAdornment>
                      )
                    }}
                    onInput={handleReEnterNewPasswordChange}
                    onFocus={e => e.target.select()}
                    onKeyDown={e => handleSpace(e)}
                  />

                  <Grid
                    className={classes.buttons}
                    container
                    justifyContent="flex-end"
                    spacing={3}
                  >
                    <Grid item>
                      <HighlightButton
                        data-testid={'submit_button'}
                        disabled={!isValidPassword}
                        type="submit"
                        variant="contained"
                        onClick={onSubmit}
                      >
                        {t('forgot_password_code.reset_password')}
                      </HighlightButton>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ForgotPasswordCode
