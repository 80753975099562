import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

/** Redux **/
import { authActions } from '../store/signIn'
import { invoicesActions } from '../store/invoices'
import { useDispatch, useSelector } from 'react-redux'
import { websocketActions } from '../store/websocket'

/** Material UI **/
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Grow,
  Typography
} from '@material-ui/core'
import { StyledNavTab, StyledNavTabs } from '../styles/mui_custom_components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/** Services **/
import { userHasAuthorization } from '../services/AuthService'
import { removeAuthorizationHeader } from '../lib/Api'
import { connectAdLogo, navBarHeaderHeight, Configs } from '../lib/Constants'
import { w3cwebsocket as W3CWebSocket } from 'websocket'
import '../styles/bounce.css'

const useStyles = makeStyles(theme => ({
  root: {
    '&.Mui-disabled': {
      backgroundColor: 'transparent !important'
    }
  },
  disabled: {
    backgroundColor: 'transparent'
  },
  menu: {
    borderRadius: '0px',
    height: navBarHeaderHeight,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    maxWidth: '170px',
    maxHeight: '30px'
  },
  menuItem: {
    fontSize: '12px'
  },
  menuIcon: {
    height: '23px',
    color: theme.colors.text
  },
  navBar: {
    backgroundColor: theme.colors.navBarColor,
    height: navBarHeaderHeight,
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100%',
    zIndex: 1000
  },
  icon: {
    color: theme.colors.settings.fieldInfo,
    textAlign: 'center',
    width: '50px',
    height: '22px',
    marginLeft: '8px'
  },
  yesClient: {
    color: theme.palette.primary.light,
    fontWeight: '500',
    fontSize: '20px'
  },
  noClient: {
    color: theme.colors.settings.fieldInfo,
    fontWeight: '500',
    fontSize: '20px'
  },
  bvLabel: {
    color: theme.colors.basicDisabledButtonColor,
    fontWeight: '500',
    fontSize: '14px'
  },
  flexDiv: {
    display: 'flex'
  },
  alertIcon: {
    width: '20px'
  },
  alert: {
    width: '100%'
  },
  alertTypo: {
    fontSize: '16px',
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    width: '100%',
    display: 'flex'
  },
  snackbar: {
    width: '100%',
    padding: '0px 50px'
  },
  logoImage: {
    width: '170px',
    height: navBarHeaderHeight,
    padding: '12px 0',
    objectFit: 'contain'
  },
  finalGrid: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  header: {
    height: navBarHeaderHeight,
    display: 'flex',
    flexDirection: 'row'
  },
  rightMenu: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0',
      marginRight: '-16px'
    }
  },
  displayBoxMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline-flex'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  displayBox: {
    display: 'inline-flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('md')]: {
      display: 'inline-flex'
    }
  },
  displayBoxSm: {
    display: 'inline-flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex'
    }
  },
  subcontractorName: {
    margin: 'auto 0px auto -5px',
    color: theme.colors.text,
    fontSize: '24px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontFamily: "-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'"
  },
  alertClass: {
    borderRadius: '50px !important',
    backgroundColor: 'rgba(89, 152, 251, 1) !important',
    height: '44px',
    width: 'calc(100% - 30px)'
  },
  alertMessage: {
    display: 'flex'
  },
  snackMessage: {
    fontSize: '13px',
    fontWeight: '700',
    color: theme.colors.white
  },
  snackAction: {
    fontSize: '13px',
    fontWeight: '700',
    color: 'rgba(2, 28, 163, 1)',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  snackIcon: {
    background: theme.colors.white,
    top: '-11px',
    left: '-13px'
  },
  snackRoot: {
    marginTop: '80px',
    width: '100%'
  }
}))

export const NavBar = () => {
  const location = useLocation()
  const userStore = useSelector(state => state.auth.user)
  const loadingFiles = useSelector(state => state.invoices.loadingFiles)
  const websocketStore = useSelector(state => state.websocket)
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const history = useHistory()

  const [value, setValue] = useState()
  const [anchorEl, setAnchorEl] = useState()
  const [anchorEl2, setAnchorEl2] = useState()
  const isMenuOpen = Boolean(anchorEl)
  const isMenuOpen2 = Boolean(anchorEl2)
  const [menuDisabled, setMenuDisabled] = useState(false)
  const [webSocketStatus, setWebSocketStatus] = useState('closed')

  const handleProfileMenuOpen = event => {
    if (loadingFiles) return
    setAnchorEl(event.currentTarget)
  }

  const handleMenuOpen = event => {
    setAnchorEl2(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleMenu2Close = () => {
    setAnchorEl2(null)
  }

  const handleChangeNavBar = (event, newValue) => {
    history.push(newValue)
  }

  const handleChangeMenu = route => {
    setAnchorEl(null)
    history.push(route)
  }
  const handleChangeMenu2 = route => {
    setAnchorEl2(null)
    history.push(route)
  }

  const logoutHandler = () => {
    dispatch(authActions.logout())
    removeAuthorizationHeader()
    history.replace('/')
  }

  const classes = useStyles()

  useEffect(() => {
    const WsClient = new W3CWebSocket(Configs.webSocketServerUrl)

    WsClient.onopen = () => {
      console.info('WebSocket Client Connected, attempting to login')
      setWebSocketStatus('open')
      WsClient.send(
        JSON.stringify({
          type: 'login',
          data: {
            username: Configs.webSocketServerUser,
            password: Configs.webSocketServerPassword
          }
        })
      )
    }
    WsClient.onmessage = (message) => {
      if (!message.isTrusted) {
        setWebSocketStatus('closed')
        console.alert('Warning, message received from WS not trusted.')
        return
      }

      console.info('Message received: %s', message.data)

      let response = {}
      try {
        response = JSON.parse(message.data)
      } catch (error) {
        console.error('Error, Response malformed.')
        return
      }

      if (response.error === false) {
        switch (response.code) {
          case 201:
            setWebSocketStatus('logged-in')
            WsClient.send(
              JSON.stringify({
                type: 'subscribe',
                data: {
                  channel: userStore.userInfo?.company_id,
                  userId: userStore.userInfo?.id
                }
              })
            )
            break
          case 202:
            setWebSocketStatus('subscribed')
            break
          case 200:
            parseResponse(response)
            break
        }
      } else {
        console.error('Response error', response.message)
      }
    }
    WsClient.onclose = function (event) {
      setWebSocketStatus('closed')
      console.info('WebSocket Client Connection closed:', event.reason)
    }
    WsClient.onerror = function () {
      setWebSocketStatus('error')
      console.error('Connection to WS Error')
    }
    console.info(webSocketStatus)
    // Clean up the WebSocket connection on component unmount
    return () => {
      WsClient.close()
    }
  }, [])

  const parseResponse = (response) => {
    try {
      const parsed = JSON.parse(response.message.data)
      console.info('websocket record', parsed)
      let snackState = null
      switch (parsed.type) {
        case 'NEW_WO':
        case 'UPDATE_WO':
          snackState = 'WO'
          break
        case 'NEW_INV':
        case 'UPDATE_INV':
          snackState = 'INV'
          break
      }
      if (!snackState) return
      if ((snackState === 'WO' && location.pathname === '/work-orders')) {
        dispatch(websocketActions.setWorkOrders([...websocketStore.workOrders, { ...parsed.data, id: parsed.data.id ?? `new_${parsed.data.external_id}`, socket: true }]))
      } else if (snackState === 'INV' && location.pathname === '/invoices') {
        dispatch(websocketActions.setInvoices([...websocketStore.invoices, { ...parsed.data, _id: parsed.data.id ?? `new_${parsed.data.external_id}`, socket: true }]))
      }
    } catch (error) {
      console.error('Error, Response malformed.', error)
    }
  }

  useEffect(() => {
    // set navbar value
    if (location.pathname === '/sign-in') {
      setValue('/work-orders')
    } else if (location.pathname === '/createInvoice') {
      setValue('/invoices')
    } else {
      setValue(location.pathname)
    }

    // disable menus
    if (location.pathname === '/company-profile') {
      setMenuDisabled(true)
    } else {
      setMenuDisabled(false)
    }

    // clean invoices saved data
    if (
      location.pathname !== '/createInvoice' &&
      location.pathname !== '/invoices'
    ) {
      dispatch(invoicesActions.setInvoices([]))
      dispatch(
        invoicesActions.setFilters({
          inStatus: '',
          won: '',
          client_name: '',
          client_location: '',
          invoice: '',
          amount: null,
          createDate: '',
          dueDate: '',
          payDate: '',
          achCheckNumber: '',
          asc_sort: '',
          desc_sort: ''
        })
      )
    }
  }, [location.pathname])

  const getCompanyLogo = () => {
    if (!userStore.userInfo.logo?.url) {
      return connectAdLogo
    }
    return userStore.userInfo.logo?.url
  }

  return (
    <Box pl={3} pr={3} className={classes.navBar}>
      <Grid container className={classes.header}>
        <Box className={classes.displayBoxMobile}>
          <Grid>
            <IconButton
              className={classes.menu}
              aria-label="menu options"
              aria-controls={'men2'}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
              disableRipple
            >
              <FontAwesomeIcon
                icon={['fal', 'bars']}
                className={classes.menuIcon}
              />
            </IconButton>
          </Grid>
        </Box>
        <Grid item xs={0} sm={9} className={classes.header}>
          <Box className={classes.displayBox} pr={2}>
            <Box className={classes.logoContainer}>
              <img
                alt={'Connect AD Platform'}
                className={classes.logo}
                src={getCompanyLogo()}
              />
            </Box>
          </Box>
          <Box className={classes.displayBoxSm}>
            <Grow in={true}>
              <StyledNavTabs value={value} onChange={handleChangeNavBar}>
                <StyledNavTab style={{ display: 'none' }} value={''} />
                {userHasAuthorization('workorders:read') && (
                  <StyledNavTab
                    value={'/work-orders'}
                    label={t('nav_bar.work_orders')}
                    iconPosition="end"
                    disabled={menuDisabled}
                  />
                )}
                {userHasAuthorization('sites:read') && (
                  <StyledNavTab
                    value={'/refreshpage'}
                    label={t('nav_bar.sites')}
                    disabled={menuDisabled}
                  />
                )}
                {userHasAuthorization('invoices:read') && (
                  <StyledNavTab
                    value={'/invoices'}
                    label={t('nav_bar.invoices')}
                    iconPosition="end"
                    disabled={menuDisabled}
                  />
                )}
                <StyledNavTab
                  style={{ display: 'none' }}
                  label={t('nav_bar.proposals')}
                  disabled={menuDisabled}
                />
                <StyledNavTab
                  style={{ display: 'none' }}
                  value={'/account-settings'}
                  disabled={menuDisabled}
                />
                <StyledNavTab
                  style={{ display: 'none' }}
                  value={'/company-settings'}
                  disabled={menuDisabled}
                />
                <StyledNavTab
                  style={{ display: 'none' }}
                  value={'/company-profile'}
                  disabled={menuDisabled}
                />
              </StyledNavTabs>
            </Grow>
          </Box>
        </Grid>
        <Grid align="right" item xs={10} sm={3} className={classes.finalGrid}>
          <Typography className={classes.subcontractorName}>
            {userStore.userInfo?.company_name}
          </Typography>
          <IconButton
            className={`${classes.menu} ${classes.rightMenu}`}
            edge="end"
            aria-label="account of current user"
            aria-controls={'men1'}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            disabled={loadingFiles}
            disableRipple
          >
            <FontAwesomeIcon
              icon={['fal', 'grid']}
              className={classes.menuIcon}
            />
          </IconButton>
        </Grid>
      </Grid>
      <Menu
        id={'men1'}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
      >
        {/* TODO: un hide dropdown options when needed */}
        <MenuItem
          style={{ display: 'none' }}
          className={classes.menuItem}
          value={'/manage-account'}
        >
          {t('nav_bar.manage_account')}
        </MenuItem>
        {userHasAuthorization('account_settings:read') && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleChangeMenu('/account-settings')}
          >
            {t('nav_bar.account_settings')}
          </MenuItem>
        )}
        {userHasAuthorization('company_settings:read') && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleChangeMenu('/company-settings')}
          >
            {t('nav_bar.company_settings')}
          </MenuItem>
        )}
        <MenuItem
          style={{ display: 'none' }}
          className={classes.menuItem}
          value={'/help'}
        >
          {t('nav_bar.help')}
        </MenuItem>
        <MenuItem
          style={{ display: 'none' }}
          className={classes.menuItem}
          value={'/send-feedback'}
        >
          {t('nav_bar.send_feedback')}
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={logoutHandler}>
          {t('nav_bar.log_out')}
        </MenuItem>
      </Menu>
      <Menu
        id={'men2'}
        open={isMenuOpen2}
        onClose={handleMenu2Close}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        getContentAnchorEl={null}
      >
        {userHasAuthorization('workorders:read') && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleChangeMenu2('/work-orders')}
          >
            {t('nav_bar.work_orders')}
          </MenuItem>
        )}
        {userHasAuthorization('invoices:read') && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleChangeMenu2('/invoices')}
          >
            {t('nav_bar.invoices')}
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}
