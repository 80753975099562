import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Container, FormLabel } from '@material-ui/core'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import * as CryptoJS from 'crypto-js'

import {
  getUser,
  getWoByIdWithAuth,
  setIframeAccessToken,
  setIframeOriginatingCompany
} from '../lib/Api'
import { ActivitiesCard } from '../components/workorders/ActivitiesCard'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0
  },
  loading: {
    justifyItems: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '200px',
    paddingTop: '100px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    fontSize: '16px'
  },
  loadingDivider: {
    marginBottom: '10px'
  },
  ready: {
    opacity: 1,
    width: 350
  },
  not_ready: {
    opacity: 0.1,
    width: 350
  }
}))

export const ServiceAppointment = props => {
  const params = useParams()
  const { t } = useTranslation()
  const classes = useStyles()
  const [ready, setReady] = useState(false)
  const [finished, setFinished] = useState(false)
  const [workOrder, setWorkOrder] = useState(null)
  const [loadingMessage, setLoadingMessage] = useState(null)
  const [user, setUser] = useState(null)
  const [tempData, setTempData] = useState({})

  useEffect(() => {
    init(params.id, params.access_token)
  }, [params])

  useEffect(() => {
    if (finished) {
      init(params.id, params.access_token)
    }
  }, [finished])

  const base64ToArrayBuffer = base64 => {
    // eslint-disable-next-line
    const binary_string = atob(base64)
    // eslint-disable-next-line
    const len = binary_string.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i)
    }
    return bytes.buffer
  }

  const arrayBufferToBase64 = arrayBuffer => {
    return btoa(
      new Uint8Array(arrayBuffer).reduce(function (data, byte) {
        return data + String.fromCharCode(byte)
      }, '')
    )
  }

  // eslint-disable-next-line
  const init = async (id, access_token) => {
    setReady(false)
    // Decrypt token
    // gets the IV from the encrypted string
    const arrayBuffer = base64ToArrayBuffer(decodeURIComponent(access_token))
    const iv = CryptoJS.enc.Base64.parse(
      arrayBufferToBase64(arrayBuffer.slice(0, 16))
    )
    const encryptedStr = arrayBufferToBase64(
      arrayBuffer.slice(16, arrayBuffer.byteLength)
    )

    const aesOptions = {
      iv: iv,
      mode: CryptoJS.mode.CBC
    }

    const decryptObj = CryptoJS.AES.decrypt(
      encryptedStr,
      CryptoJS.enc.Base64.parse('Wm/vchJxyEW9xEYZ6JmXcw=='),
      aesOptions
    )

    const accessToken = decryptObj.toString(CryptoJS.enc.Utf8)
    setIframeAccessToken(accessToken)

    // Download WO
    try {
      const wo = await getWoByIdWithAuth(true, id)
      setWorkOrder(wo)
      setIframeOriginatingCompany(wo.originating_company_id)

      const user = await getUser(true)
      setUser(user)
      setReady(true)
    } catch (error) {
      // force WO reload in case of error retreiving the WO.
      location.reload()
    }
  }

  const getWindowHeight = () => {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size[1]
  }

  const handleRefetch = async () => {
    init(params.id, params.access_token)
  }

  return (
    <Container className={classes.container}>
      <Box className={ready ? classes.ready : classes.not_ready}>
        {workOrder && user && (
          <ActivitiesCard
            type="iframe"
            getWindowHeight={getWindowHeight}
            data={workOrder}
            handleRefetch={handleRefetch}
            index={0}
            length={1}
            photosFtc={workOrder.ftc?.photos ?? []}
            setPhotos={() => {}}
            setPhotoIndex={() => {}}
            updateWoData={data => {
              const obj = data
              if (obj.id === workOrder.id) {
                if (obj.updateEta) {
                  delete obj.updateEta
                } else {
                  workOrder.status = 'completed'
                }
                workOrder.invoice = data.invoice
                workOrder.est_service_start = data.est_service_start
              }
            }}
            setReady={setReady}
            externalUser={user}
            setMessage={setLoadingMessage}
            setEtaLog={() => {}}
            setFinished={setFinished}
            tempData={tempData}
            setTempData={setTempData}
          />
        )}
      </Box>
      <FormLabel
        style={{ display: ready ? 'none' : 'block' }}
        classes={{ root: classes.loading }}
      >
        <div className={classes.loadingDivider}>
          {(loadingMessage ?? t('general.labels.loading')) + '\n'}
        </div>
        <Spin
          indicator={<LoadingOutlined spin />}
          style={{ display: ready ? 'none' : 'block' }}
        />
      </FormLabel>
    </Container>
  )
}
