import React, { useEffect, useState } from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { NotesComponent } from './repair/NotesComponent'
import CheckItem from './fieldServiceForm/CheckItem'
import DropDownSelector from './fieldServiceForm/DropDownSelector'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { SignatureComponent } from './repair/SignatureComponent'
import { useTranslation } from 'react-i18next'
import PhotoSelector from './fieldServiceForm/PhotoSelector'
import MultiButtonSelect from './fieldServiceForm/MultiButtonSelect'
import LineItemsComponent from './repair/LineItemsComponent'
import DateTimePicker from '../form/DateTimePicker'
import { isNotEmpty } from '../../lib/Global'
import FileSelector from './fieldServiceForm/FileSelector'

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    fontWeight: '700',
    fontSize: '14px',
    padding: '5px',
    marginBottom: '10px'
  },
  sectionItem: {
    marginBottom: '10px',
    marginRight: '0px'
  },
  checkItem: {
    marginBottom: '5px',
    marginRight: '0px'
  },
  fieldTitle: {
    marginBottom: '5px',
    marginLeft: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  smallChip: {
    border: '1px solid ' + theme.colors.textGray,
    borderRadius: '45px',
    fontSize: '12px',
    fontWeight: '400',
    padding: '9px 12px',
    margin: '20px 20px 0px 0px',
    lineHeight: '14px',
    cursor: 'default'
  }
}))

export const FieldServiceForm = props => {
  const {
    repairData,
    config,
    readOnly,
    woData,
    externalUser,
    setPhotos,
    setPhotoIndex,
    setMandatoryComplete
  } = props
  const userData = useSelector(state => state.auth?.user?.userInfo)
  const [serviceData, setServiceData] = useState()
  const [repair, setRepair] = useState({})
  const classes = useStyles()
  const { t } = useTranslation()
  const [mandatoryFields, setMandatoryFields] = useState()
  useEffect(() => {
    initServiceData(repair)
    if (!repairData?.data && config) {
      const defaultData = getDefaultObjectData(config?.configurations)
      setServiceData(defaultData)
      return
    }
    const groupedData = {}
    const data = repairData?.data

    for (const key in data) {
      if (key === 'repair_photos') {
        const repairPhotos = data[key]

        for (const repairKey in repairPhotos) {
          const [sectionKey, extractedKey] = repairKey.split('|')
          if (!groupedData[sectionKey]) {
            groupedData[sectionKey] = {}
          }

          const photoData = repairPhotos[repairKey]
          if (!groupedData[sectionKey][extractedKey]) {
            groupedData[sectionKey][extractedKey] = {}
          }
          groupedData[sectionKey][extractedKey] = photoData
        }
      } else {
        groupedData[key] = { ...groupedData[key], ...data[key] }
      }
    }
    if (Object.keys(groupedData).length === 0) {
      isEmpty(repairData?.data)
        ? setServiceData(getDefaultObjectData(config?.configurations))
        : setServiceData(repairData?.data)
    } else {
      setServiceData(groupedData)
    }
  }, [config, repairData])

  useEffect(() => {
    if (!readOnly) {
      const allComplete = mandatoryFields?.every(element => element.complete)
      setMandatoryComplete(allComplete)
    }
  }, [mandatoryFields])

  // Load Repair data
  function initServiceData(repair) {
    if (!repair || isEmpty(repair)) {
      repair.work_order_id = woData?.id
      repair.type = 'service'
      repair.data = { ...repairData?.data }
      repair.user_id = externalUser ? externalUser.id : userData.id
      repair.external_id = woData.external_id
      repair.access = ['adftc']
      repair.status = 'active'
      repair.date_created = Math.round(Date.now() / 1000)
    }
    setRepair(repair)
  }

  function getQuestionFromConfig(questionId) {
    const question = config.configurations.flatMap(config => {
      return config.page_questions.flatMap(pageQuestion => {
        const sectionRoot = Object.keys(pageQuestion)[0]
        const questionElement = pageQuestion[sectionRoot].questions
        return questionElement.filter(question => question[questionId])
      })
    })
    return question[0][questionId]
  }

  function getDefaultObjectData(configData) {
    const emptyRepairData = {}
    const mandatoryArray = configData.flatMap(config => {
      return config.page_questions.flatMap(pageQuestion => {
        const sectionRoot = Object.keys(pageQuestion)[0]
        const questionElement = pageQuestion[sectionRoot].questions
        return questionElement
          .filter(question => question[Object.keys(question)[0]].required)
          .map(question => {
            const questionId = Object.keys(question)[0]
            const questionType = question[questionId].type

            return {
              id: questionId,
              type: questionType,
              required: true,
              complete: questionType === 'checklist_item' ? true : null
            }
          })
      })
    })
    setMandatoryFields(mandatoryArray)

    configData.forEach(config => {
      config.page_questions.forEach(pageQuestion => {
        const sectionRoot = Object.keys(pageQuestion)[0]
        const questionElement = pageQuestion[sectionRoot].questions
        const questionIds = questionElement.map(
          question => Object.keys(question)[0]
        )
        emptyRepairData[sectionRoot] = {}
        questionIds.forEach(questionIdElement => {
          const element = questionElement.find(
            obj => Object.keys(obj)[0] === questionIdElement
          )[questionIdElement]
          emptyRepairData[sectionRoot][questionIdElement] =
            element.type === 'checklist_item' ? false : null
        })
      })
    })
    return emptyRepairData
  }

  function evaluateMandatoryAnswer(answer, question) {
    const questionConfig = getQuestionFromConfig(question.id)
    let requiredPhotos = []
    let requiredAttachments = []
    let allPhotosSelected = []
    let allAttachmentsSelected = []
    switch (question.type) {
      case 'checklist_item':
        return typeof answer !== 'undefined'
      case 'dropdown_selection':
        return answer.includes(true)
      case 'text_description':
        return isNotEmpty(answer)
      case 'photos':
        requiredPhotos = questionConfig?.photos?.filter(photo => photo.required)

        allPhotosSelected = requiredPhotos.every(requiredItem =>
          answer.some(answerItem => answerItem.title === requiredItem.title)
        )
        return allPhotosSelected
      case 'attachments':
        requiredAttachments = questionConfig?.attachments?.filter(
          file => file.required
        )

        allAttachmentsSelected = requiredAttachments?.every(requiredItem =>
          answer.some(answerItem => answerItem.title === requiredItem.title)
        )
        return allAttachmentsSelected
      case 'signature':
        if (typeof answer[0]?.image !== 'undefined') {
          return questionConfig.attributes === 'entered_name_required'
            ? answer[0]?.name?.length > 0
            : false
        }
        return false
      case 'multi_button_selection':
        return answer.includes(true)
      case 'line_item': {
        if (answer?.length === 0 || !answer) {
          return false
        }

        let fullfilledItems = true
        if (answer?.length > 0) {
          for (const item of answer) {
            if (!item.quantity || !item.name) {
              fullfilledItems = false
              break
            }
          }
        }
        return fullfilledItems
      }

      default:
        return !!answer
    }
  }
  const handleChangeValue = (section, questionId, answer) => {
    if (typeof answer !== 'undefined' && !readOnly) {
      const updatedQuestions = mandatoryFields?.map(question => {
        if (question.id === questionId) {
          // Update the complete value for the specific ID
          return {
            ...question,
            complete: evaluateMandatoryAnswer(answer, question)
          }
        }
        // For other questions, return the original object
        return question
      })
      setMandatoryFields(updatedQuestions)
    }
    const tmpData = { ...serviceData }
    tmpData[section][questionId] = answer
    setServiceData(tmpData)
    repair.data = { ...tmpData }
    props.onUpdate({ ...repair })
  }

  const handleOpenPhotos = (ind, photos) => {
    setPhotos(photos)
    setPhotoIndex(ind)
  }

  const renderQuestions = (question, answer, section, questionId) => {
    switch (question.type) {
      case 'text_description':
        if (question.input_field_type === 'date_time') {
          return (
            <DateTimePicker
              question={question}
              disabled={readOnly}
              answer={answer}
              onUpdate={handleChangeValue}
              section={section}
              questionId={questionId}
            />
          )
        }
        return (
          <Box className={classes.sectionItem}>
            <NotesComponent
              mandatory={question.required}
              title={question.title}
              notes={answer}
              disabled={readOnly}
              section={section}
              questionId={questionId}
              onUpdate={handleChangeValue}
              configSchema="v2"
              type={question.input_field_type === 'numeric_only' && 'number'}
            />
          </Box>
        )
      case 'checklist_item':
        return (
          <Box className={classes.checkItem}>
            <CheckItem
              section={section}
              questionId={questionId}
              question={question}
              answer={answer}
              readOnly={readOnly}
              changeEvent={handleChangeValue}
              mandatory={question.required}
            />
          </Box>
        )
      case 'dropdown_selection': {
        const options = []
        question.options.map((option, index) =>
          options.push({
            name: option,
            selected: answer && !!answer[index]
          })
        )
        return (
          <Box className={classes.sectionItem}>
            <DropDownSelector
              section={section}
              questionId={questionId}
              question={question}
              answer={answer}
              options={options}
              readOnly={readOnly}
              onUpdate={handleChangeValue}
              mandatory={question.required}
            />
          </Box>
        )
      }
      case 'multi_button_selection': {
        return (
          <Box>
            <MultiButtonSelect
              section={section}
              questionId={questionId}
              question={question}
              answer={answer}
              options={question.buttons}
              readOnly={readOnly}
              mandatory={question.required}
              singleSelection={
                question.number_of_selections === 'single_selection'
              }
              onUpdate={handleChangeValue}
            />
          </Box>
        )
      }
      case 'photos': {
        return (
          <Box className={classes.sectionItem}>
            <PhotoSelector
              question={question}
              section={section}
              questionId={questionId}
              answer={answer}
              readOnly={readOnly}
              onUpdate={handleChangeValue}
              mandatory={question.required}
              handleOpenPhotos={handleOpenPhotos}
            />
          </Box>
        )
      }
      case 'attachments': {
        return (
          <Box className={classes.sectionItem}>
            <FileSelector
              question={question}
              section={section}
              questionId={questionId}
              answer={answer}
              readOnly={readOnly}
              onUpdate={handleChangeValue}
              mandatory={question.required}
              handleOpenPhotos={handleOpenPhotos}
            />
          </Box>
        )
      }
      case 'signature':
        return (
          <Box className={classes.sectionItem}>
            <SignatureComponent
              disabled={readOnly}
              mandatory={question.required}
              handleOpenPhotos={handleOpenPhotos}
              onUpdate={handleChangeValue}
              headerText={question.title || t('work_orders.trips.signature')}
              data={answer && answer.length > 0 ? answer[0] : null}
              configSchema="v2"
              section={section}
              questionId={questionId}
            />
          </Box>
        )
      case 'line_items':
        return (
          <Box className={classes.sectionItem}>
            <LineItemsComponent
              question={question}
              lineItemsList={answer}
              mandatory={question.required}
              disabled={readOnly}
              onUpdate={handleChangeValue}
              section={section}
              questionId={questionId}
            />
          </Box>
        )
      default:
        return (
          <Box className={classes.sectionItem}>
            <NotesComponent
              mandatory={question.required}
              title={question.title}
              notes={answer}
              disabled={readOnly}
              section={section}
              questionId={questionId}
              onUpdate={handleChangeValue}
              configSchema="v2"
            />
          </Box>
        )
    }
  }

  return (
    <Box>
      {config?.configurations?.map((page, index) => (
        <Box key={page?.page_title + index}>
          {/* TODO handle multiple pages */}
          {page?.page_questions?.map(section =>
            Object.keys(section).map(
              key =>
                serviceData &&
                serviceData[key] && (
                  <Box key={key}>
                    <Typography className={classes.sectionTitle}>
                      {section[key].title}
                    </Typography>
                    {section[key].questions.map(
                      question =>
                        Object.keys(question)[0] in serviceData[key] && (
                          <Box key={Object.keys(question)[0]}>
                            {renderQuestions(
                              question[Object.keys(question)[0]],
                              serviceData[key][Object.keys(question)[0]],
                              key,
                              Object.keys(question)[0]
                            )}
                          </Box>
                        )
                    )}
                  </Box>
                )
            )
          )}
        </Box>
      ))}
    </Box>
  )
}
