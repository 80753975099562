import React from 'react'
import { Typography } from '@mui/material'
import { assetsStyles } from '../../styles/classes/CommonClasses'
import { docTypes } from '../../lib/Constants'
import customTheme from '../../styles/mui_theme'

export const FileThumbCorner = (props) => {
  const classes = assetsStyles()

  const newColor = props.type === docTypes.pdf.key
    ? customTheme.colors.workOrders.pdf
    : props.type.includes(docTypes.doc.key)
      ? customTheme.colors.workOrders.doc
      : props.type.includes(docTypes.xls.key) || props.type.includes(docTypes.csv.key)
        ? customTheme.colors.workOrders.excel
        : ''
  return (
    <div className={classes.typoDiv}>
      <Typography noWrap className={classes.thumbnail} style={{ color: newColor }}>
        {props.name}
      </Typography>
      <div className={classes.thumbDiv} >
        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10.3374 8.31047L0.75 16.289L0.75 7.50042C0.75 3.7725 3.77208 0.750418 7.5 0.750418L18.9283 0.750418L10.3374 8.31047Z"
            fill={newColor}
            stroke={newColor}
            strokeWidth="0.5"/>
        </svg>
      </div>
    </div>
  )
}
