/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, makeStyles } from '@material-ui/core'
import moment from 'moment'
import { DatePicker, Space, TimePicker } from 'antd'
import 'antd/dist/antd.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
  mainInput1: {
    ...theme.filtersClasses.mainInput1,
    '& .ant-picker-input': {
      boxShadow: 'none',
      '& input': {
        color: theme.colors.basicDisabledButtonColor,
        cursor: 'pointer',
        '&::placeholder': {
          color: theme.colors.iconBlue,
          opacity: 1
        }
      }
    },
    cursor: 'pointer',
    boxShadow: 'none',
    zIndex: 9
  },
  mainInput2: {
    ...theme.filtersClasses.mainInput2,
    '& .ant-picker-input': {
      boxShadow: 'none',
      '& input': {
        color: theme.colors.basicDisabledButtonColor,
        cursor: 'pointer',
        '&::placeholder': {
          color: theme.colors.iconBlue,
          opacity: 1
        }
      }
    },
    cursor: 'pointer',
    boxShadow: 'none',
    zIndex: 9,
    paddingLeft: '5px'
  },
  block: {
    display: 'block'
  },
  hidden: {
    border: 'none',
    zIndex: 1,
    marginTop: '-32px'
  },
  overlay: {
    position: 'fixed',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0
  }
}))

export const DateTimeFilter = props => {
  const [selectedDate, setSelectedDate] = useState(null)
  const [openTime, setOpenTime] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [disabled, setDisabled] = useState()

  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    props.values && setSelectedDate(new Date(props.values))
  }, [props.values])

  const handleChange = (date, update = false) => {
    let shouldUpdate = update
    if (props?.disableTimeSelector) {
      shouldUpdate = true
    }
    handleTimeChange(date)
    if (!disabled) {
      setSelectedDate(date ? date.format('MM/DD/yyyy hh:mm A') : null)
      if (date === null) props.setValues(null)
      if (shouldUpdate) props.setValues(date.valueOf())
      if (!props?.disableTimeSelector) {
        setOpenTime(!openTime)
      }
    }
  }
  const handleTimeChange = date => {
    if (props.canSelectAnytime) {
      setDisabled(false)
      return
    }
    if (date <= moment()) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  useEffect(() => {
    if (props.values === '') {
      setSelectedDate('')
    }
  }, [props.values])

  const disabledDate = current => {
    // Can not select days before today and today
    return (
      current &&
      (current <= moment().subtract(1, 'days').endOf('day') ||
        current > moment.unix(props.maxDate).endOf('day'))
    )
  }
  const disabledTime = current => {
    // Can not select time before current time and current time
    return current && current <= moment().subtract(1, 'h, m')
  }

  let datePickerClassName =
    props.type === 1 ? classes.mainInput1 : classes.mainInput2
  if (props.datePickerStyles) {
    datePickerClassName = props.datePickerStyles
  }

  return (
    <Space direction="horizontal" size={12} className={classes.block}>
      <FormControl variant="outlined" fullWidth>
        <DatePicker
          disabled={props?.disabled}
          format="MM/DD/yyyy hh:mm A"
          disabledDate={
            props.disabledDate !== undefined ? props.disabledDate : disabledDate
          }
          popupStyle={{ zIndex: 2000 }}
          className={datePickerClassName}
          placement="bottomRight"
          value={selectedDate ? moment(selectedDate) : undefined}
          onChange={date => handleChange(date, false)}
          placeholder={props?.placeholder || t('general.labels.select')}
          style={{ cursor: 'pointer' }}
          suffixIcon={<FontAwesomeIcon icon={['far', 'angle-down']} />}
          showNow={false}
          onOpenChange={open => {
            setCalendarOpen(open)
            if (!props?.disableTimeSelector) {
              setOpenTime(!open)
            }
          }}
          inputReadOnly
          onSelect={handleTimeChange}
        />
        <TimePicker
          disabled={props?.disabled}
          format="MM/DD/yyyy hh:mm A"
          use12Hours
          disabledDate={
            props.disabledTime !== undefined ? props.disabledTime : disabledTime
          }
          popupStyle={{ zIndex: 2000 }}
          className={classes.hidden}
          open={openTime}
          value={selectedDate ? moment(selectedDate) : undefined}
          onSelect={handleTimeChange}
          onOk={date => handleChange(date, true)}
          onBlur={() => setOpenTime(false)}
          showNow={false}
          placement="bottomRight"
        />
        {openTime && calendarOpen && <div className={classes.overlay} />}
      </FormControl>
    </Space>
  )
}
