export const signIn = () => {
  return {
    slogan: '“The easiest way to connect to your jobs for faster payment.”',
    title: 'SIGN IN',
    sign_in_google: 'Sign in with Google',
    sign_in_microsoft: 'Sign in with Microsoft',
    email_address: 'Email Address',
    password: 'Password',
    remember_me: 'Remember me',
    forgot_password: 'Forgot password?',
    request_access: 'Request Access',
    register: 'Register',
    sign_in: 'Sign In',
    no_account: 'Don’t have an account?',
    create_account: 'Create one here',
    messages: {
      missing_fields: 'Both fields are required to login',
      wrong_user_password: 'Invalid credentials, please try again.',
      user_no_permission: 'User does not have permission to access the portal.'
    },
    masquerade: {
      title: 'Masquerade',
      subtitle:
        'Select a client for masquerading to help troubleshoot issues, view work orders and manage users.',
      skip: 'Skip',
      go: 'Go',
      search_client: 'Search Client Name',
      frequently: 'Frequently used',
      no_frequently: 'There are no frequently used clients.'
    },
    contact_us: 'Contact us',
    logout: {
      title: 'Your Session Ended',
      message:
        'We’re sorry but something has happened to your session. Sign in again, to ensure all your work is up to date.'
    },
    invalid_user: {
      title: 'Invalid Credentials',
      message: 'The credentials entered are not valid. Please try again.'
    }
  }
}
