import React from 'react'
import {
  Box,
  Button,
  Card,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getCompany } from '../../services/ApiService'
import moment from 'moment'
import { generateTermsPDF } from '../../lib/Global'
import { useDispatch } from 'react-redux'
import { loadingActions } from '../../store/loading'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    boxShadow: '6px 9px 43px ' + theme.colors.profile.shadowCard,
    marginBottom: '2em',
    padding: '1em',
    color: theme.colors.text
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: '700'
  },
  fileCard: {
    backgroundColor: theme.colors.grey_2,
    margin: '1em 0em',
    padding: '0.5em',
    border: '1px solid ' + theme.colors.profile.darkCard,
    borderRadius: '12px',
    boxShadow: 'none',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      margin: '0',
      width: '100%'
    }
  },
  fileTitle: {
    fontSize: '12px',
    marginBottom: '0.5em'
  },
  fileData: {
    fontSize: '12px',
    paddingBottom: '0.5em'
  },
  fileMissingData: {
    fontStyle: 'italic',
    color: theme.colors.grey
  },
  fileLink: {
    fontSize: '12px',
    textDecoration: 'none',
    color: theme.colors.iconBlue,
    paddingBottom: '0.5em',
    wordBreak: 'break-all'
  },
  editButton: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '0',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.05em',
    lineHeight: '19px'
  },
  cardError: {
    color: theme.colors.errorColor,
    fontSize: '12px',
    marginBottom: '0.5em'
  }
}))

export const TermsAndConditionsCard = props => {
  const classes = useStyles()
  const { data, originatingCompany, content } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const AgreementCard = props => {
    const classes = useStyles()
    const { t } = useTranslation()
    const signDate = data?.signed
      ? moment(new Date(data?.signed_on)).format(t('general.format.short_date'))
      : null

    return (
      <Card className={classes.fileCard}>
        <Box>
          <Link onClick={generatePDF} target="_blank" rel="noopener">
            <Typography className={classes.fileLink}>
              {t('company_profile.terms')}
            </Typography>
          </Link>
          <Typography
            className={signDate ? classes.fileTitle : classes.cardError}
          >
            {signDate
              ? t('company_settings.terms_card.accepted') + ': ' + signDate
              : t('company_settings.terms_card.not_accepted')}
          </Typography>
        </Box>
      </Card>
    )
  }

  async function generatePDF() {
    dispatch(loadingActions.show())
    const company = await getCompany(originatingCompany)
    const signDate = moment(new Date(data?.signed_on)).format(
      t('general.format.short_date')
    )
    const signTime = moment(new Date(data?.signed_on)).format(
      t('general.format.time_am_pm')
    )
    generateTermsPDF(
      company.name,
      content,
      data?.signed,
      data?.name,
      signDate,
      signTime,
      t
    )
    dispatch(loadingActions.hide())
  }

  const EditButton = props => {
    const classes = useStyles()
    return (
      <Button className={classes.editButton} onClick={props.onClick}>
        {props.label}
      </Button>
    )
  }

  const handleEditProfile = () => {
    props.setComponent('terms')
    props.setOpen(true)
  }
  return (
    <Card className={classes.card}>
      <Box display="flex" flexDirection="row">
        <Typography classes={{ root: classes.cardTitle }}>
          {t('company_settings.card.terms')}
        </Typography>
        <EditButton
          label={t('company_settings.buttons.edit')}
          onClick={handleEditProfile}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        className={classes.sectionDivider}
      ></Box>
      <Box className={classes.marginContainer}>
        <Box className={classes.sectionDivider}></Box>
        <AgreementCard />
      </Box>
    </Card>
  )
}
