import React from 'react'
import {
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { TableContainer } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import LoadingBox from './LoadingBox'
import PropTypes from 'prop-types'
import { mcsEditableInvoiceStatus } from '../../lib/Constants'

const useStyles = makeStyles(theme => ({
  summaryLabel: {
    backgroundColor: theme.colors.invoiceColors.summary_bg,
    fontSize: '12px',
    height: '22px',
    paddingTop: '3px',
    paddingBottom: '3px',
    color: theme.colors.invoiceColors.dark_grey,
    fontWeight: '700',
    borderBottom: '0px'
  },
  tableHeader: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.invoiceColors.table_text,
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  tableRow: {
    height: '54px'
  },
  tableItem: {
    fontSize: '12px',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  productFamily: {
    fontSize: '12px',
    minWidth: '175px',
    maxWidth: '175px',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  selectItem: {
    fontSize: '12px',
    minWidth: '333px',
    maxWidth: '333px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  task: {
    fontSize: '12px',
    width: '38%',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  inputAmountItem: {
    fontSize: '12px',
    minWidth: '68px',
    textAlign: 'right'
  },
  inputRateItem: {
    fontSize: '12px',
    minWidth: '68px'
  },
  inputQtyItem: {
    fontSize: '12px'
  },
  rightAlignment: {
    '& :last-child': {
      textAlign: 'right'
    }
  }
}))

function EnhancedTable(props) {
  const { itemTag, items, loading, setData } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const columns = [
    {
      field: 'item',
      headerName: t('invoices.column_names.item')
    },
    {
      field: 'task',
      headerName: t('invoices.column_names.task')
    },
    {
      field: 'product_family',
      headerName: t('invoices.column_names.product_family')
    },
    {
      field: 'rate',
      headerName: t('invoices.column_names.rate')
    },
    {
      field: 'qty',
      headerName: t('invoices.column_names.qty')
    },
    {
      field: 'amount',
      headerName: t('invoices.column_names.item_amount')
    }
  ]

  const handleQuantityChange = (e, row) => {
    setData(prevData => ({
      ...prevData,
      line_items: prevData.line_items.map(item =>
        item.id === row.id
          ? {
              ...item,
              quantity: parseInt(e.target.value),
              amount: item.rate * parseInt(e.target.value),
              subtotal_line_items: item.rate * parseInt(e.target.value)
            }
          : item
      )
    }))
  }

  const handleRateChange = (e, row) => {
    const rateNumber = parseFloat(e.target.value.replace('$', ''))
    setData(prevData => ({
      ...prevData,
      line_items: prevData.line_items.map(item =>
        item.id === row.id
          ? {
              ...item,
              rate: rateNumber,
              amount: item.quantity * rateNumber,
              subtotal_line_items: item.quantity * rateNumber
            }
          : item
      )
    }))
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={8} classes={{ root: classes.summaryLabel }}>
              {loading ? '' : `${itemTag}`}
            </TableCell>
          </TableRow>
          <TableRow className={classes.rightAlignment}>
            {columns.map((column, index) => (
              <TableCell
                key={column.field + index}
                classes={{ root: classes.tableHeader }}
              >
                {loading ? (
                  <LoadingBox width="100%" height="20px" />
                ) : mcsEditableInvoiceStatus.includes(props.status) ? (
                  column.headerName
                ) : (
                  column.field !== 'rate' && column.headerName
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((row, index) => (
            <TableRow
              key={row.id + '-' + index}
              classes={{ root: classes.tableRow }}
            >
              <TableCell classes={{ root: classes.selectItem }}>
                {row.item}
              </TableCell>
              <TableCell classes={{ root: classes.task }}>{row.task}</TableCell>
              <TableCell classes={{ root: classes.productFamily }}>
                {row.product_name}
              </TableCell>

              {mcsEditableInvoiceStatus.includes(props.status) ? (
                <TableCell classes={{ root: classes.tableItem }}>
                  <NumberFormat
                    name="rate"
                    thousandSeparator
                    customInput={Input}
                    disableUnderline
                    prefix="$"
                    allowEmptyFormatting
                    decimalScale={2}
                    decimalSeparator="."
                    allowNegative={false}
                    onChange={e => handleRateChange(e, row)}
                    value={row.rate}
                    disabled={!(mcsEditableInvoiceStatus.includes(props.status))}
                    classes={{
                      input: classes.inputRateItem
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell classes={{ root: classes.tableItem }} />
              )}
              <TableCell classes={{ root: classes.tableItem }}>
                <NumberFormat
                  name="quantity"
                  customInput={Input}
                  disableUnderline
                  onChange={e => handleQuantityChange(e, row)}
                  value={row.quantity}
                  disabled={!(mcsEditableInvoiceStatus.includes(props.status))}
                  classes={{
                    input: classes.inputQtyItem
                  }}
                />
              </TableCell>

              <TableCell classes={{ root: classes.tableItem }}>
                <NumberFormat
                  name="amount"
                  thousandSeparator
                  customInput={Input}
                  disableUnderline
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={2}
                  value={row.amount}
                  disabled
                  classes={{
                    input: classes.inputAmountItem
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

EnhancedTable.propTypes = {
  itemTag: PropTypes.string,
  items: PropTypes.object,
  loading: PropTypes.bool,
  status: PropTypes.string
}

export const McssfrScheduledDetailComponent = props => {
  const { itemTag, items, loading, status, setData } = props
  return (
    <EnhancedTable
      itemTag={itemTag}
      items={items}
      setData={setData}
      loading={loading}
      status={status}
    />
  )
}
