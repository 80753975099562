export const signIn = () => {
  return {
    slogan:
      '“La forma más fácil de conectarse a sus trabajos para un pago más rápido.”',
    title: 'INICIAR SESIÓN',
    sign_in_google: 'Inicie sesión con Google',
    sign_in_microsoft: 'Inicie sesión con Microsoft',
    email_address: 'Correo',
    password: 'Contraseña',
    remember_me: 'Recordarme',
    forgot_password: 'Olvidó su contraseña?',
    request_access: 'Solicitar Acceso',
    register: 'Registrarse',
    sign_in: 'Iniciar Sesión',
    no_account: '¿No tiene una cuenta?',
    create_account: 'Cree una aquí',
    messages: {
      missing_fields: 'Los dos campos son requeridos para iniciar sesión',
      wrong_user_password:
        'Usuario o contraseña incorrectos. Por favor intente nuevamente.',
      user_no_permission: 'Credenciales Inválidas, intente nuevamente.'
    },
    masquerade: {
      title: 'Máscara',
      subtitle:
        'Seleccione un cliente para enmascarar su usuario y que se le permita ayudar en la solucion de problemas específicos.',
      skip: 'Ignorar',
      go: 'Entrar',
      search_client: 'Buscar el Nombre del Cliente',
      frequently: 'Frecuentemente usados',
      no_frequently: 'No existen clientes usados últimamente'
    },
    contact_us: 'Contáctanos',
    logout: {
      title: 'Su Sesión ha Expirado',
      message:
        'Lo sentimos, algo ha pasado con su sesión. Inicie sesión de nuevo para asegurar que su trabajo este actualizado.'
    },
    invalid_user: {
      title: 'Credentiales incorrectas',
      message: 'Las credenciales ingresadas no son válidas. Por favor, intente nuevamente.'
    }
  }
}
