import React from 'react'
import { FadeLoader } from 'react-spinners'
import { Box, makeStyles } from '@material-ui/core'
import { connectSubcontractorLogo } from '../lib/Constants'

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  logo: {
    height: '40px',
    paddingBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      height: '25px',
      paddingBottom: '10px'
    }
  }
}))

export const LoadingSplash = () => {
  const classes = useStyles()
  return (
    <Box className={classes.container}>
      <img
        src={connectSubcontractorLogo}
        alt="Connectad Platform"
        className={classes.logo}
      />
      <FadeLoader loading={true} height={12} width={4} />
    </Box>
  )
}
