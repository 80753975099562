import {
  Box,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
  makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { checkItemType } from '../../../lib/Constants'

const useStyles = makeStyles(theme => ({
  checkContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  fieldTitle: {
    marginLeft: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700',
    width: '10rem'
  },
  checkbox: {
    '&.MuiFormControlLabel-root': {
      marginRight: '6px'
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '10px'
    }
  },
  mandatory: {
    color: theme.colors.errorColor
  },
  checkPadding: {
    padding: '0px',
    marginRight: '3px'
  },
  singleItemMargin: {
    marginLeft: '6px'
  }
}))

export default function CheckItem(props) {
  const {
    section,
    questionId,
    question,
    answer,
    readOnly,
    changeEvent,
    mandatory
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [value, setValue] = useState(false)

  useEffect(() => {
    setValue(answer)
  }, [])
  useEffect(() => {
    changeEvent(section, questionId, value)
  }, [value])

  const handleChange = event => {
    checkItemType.includes(event.target.name)
      ? setValue(event.target.checked)
      : setValue(!event.target.checked)
  }

  return (
    <Box className={classes.checkContainer}>
      <Typography
        className={`${classes.fieldTitle} ${
          !readOnly && mandatory ? classes.mandatory : ''
        }`}
      >
        {question.title}
      </Typography>
      {question.selection_option === 'check_box' && (
        <Checkbox
          disabled={readOnly}
          checked={value}
          size="small"
          name="single_check"
          onChange={handleChange}
          color="primary"
          className={classes.singleItemMargin}
        />
      )}
      {question.selection_option === 'toggle' && (
        <Switch
          checked={value}
          disabled={readOnly}
          inputProps={{ 'aria-label': 'controlled' }}
          name="toggle_check"
          onChange={handleChange}
          color="primary"
          className={classes.singleItemMargin}
        />
      )}
      {(question.selection_option === 'on_off' ||
        question.selection_option === 'yes_no') && (
        <div>
          <FormControlLabel
            className={classes.checkbox}
            labelPlacement="top"
            control={
              <Checkbox
                disabled={readOnly}
                checked={value}
                name="yes_check"
                size="small"
                onChange={handleChange}
                color="primary"
                className={classes.checkPadding}
              />
            }
            label={
              question.selection_option === 'yes_no'
                ? t('general.labels.yes')
                : t('general.labels.on')
            }
          />
          <FormControlLabel
            className={classes.checkbox}
            labelPlacement="top"
            control={
              <Checkbox
                disabled={readOnly}
                checked={!value}
                name="no_check"
                size="small"
                onChange={handleChange}
                color="primary"
                className={classes.checkPadding}
              />
            }
            label={
              question.selection_option === 'yes_no'
                ? t('general.labels.no')
                : t('general.labels.off')
            }
          />
        </div>
      )}
    </Box>
  )
}
