import React from 'react'

/** Redux **/
import { useSelector } from 'react-redux'

/** Material UI **/
import {
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Grid,
  makeStyles
} from '@material-ui/core'

/** Components **/
import ftcLandingLogo from '../assets/images/ftcLandingAdminNew-min.jpg'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.colors.backdropColor
  },
  leftImage: {
    width: '100%',
    objectFit: 'cover'
  },
  imageContainer: {
    width: 'calc(100% - 720px)',
    overflow: 'hidden'
  },
  adminContainer: {
    width: '720px'
  },
  body: {
    minHeight: 'calc(100vh - 170px)',
    backgroundColor: theme.colors.backdropColor,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  background: { backgroundColor: 'white' }
}))

export const AdminContainer = props => {
  const loading = useSelector(state => state.loading.loading)
  const classes = useStyles()

  return (
    <div className={classes.background} hidden={loading}>
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container className={classes.container}>
        <Grid item className={classes.imageContainer}>
          <Box
            className={classes.container}
            sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}
          >
            <img
              className={classes.leftImage}
              alt={'ftc_logo'}
              src={ftcLandingLogo}
              height={'100%'}
            />
          </Box>
        </Grid>
        <Grid item className={classes.adminContainer}>
          <Box className={classes.body}>{props.children}</Box>
        </Grid>
      </Grid>
    </div>
  )
}
