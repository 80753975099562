import decode from 'jwt-decode'
import _ from 'lodash'
import moment from 'moment'
import { callZipApi } from '../services/ApiLocationService'
import { companyProfileFiles, sseClientKeys, woFixedStatus } from './Constants'
import html2pdf from 'html2pdf.js'
import { getS3SignedURL } from '../services/ApiService'
import { store } from '../store'
import { loadingActions } from '../store/loading'
import md5 from 'md5'

export const decodeToken = token => {
  try {
    return decode(token)
  } catch (error) {
    console.log('decode token: ', token)
    // eslint-disable-next-line
    throw {
      name: 'Token Error',
      message: error.message,
      code: 5000
    }
  }
}

export const convertToSlug = text => {
  return text
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/[^\w-]+/g, '')
}

export const validateEmail = email => {
  const re =
    /* eslint-disable-next-line */
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const validatePhone = phone => {
  /* eslint-disable-next-line */
  const response = /^[0-9]{10}$/
  return response.test(phone)
}

export const degrees2meters = (lon, lat) => {
  const x = (lon * 20037508.34) / 180
  let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180)
  y = (y * 20037508.34) / 180
  return [x, y]
}

export const getBase64 = (file, callback) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    callback(reader.result)
  }
  reader.onerror = function (error) {
    console.log('Error: ', error)
  }
}

/**
 * Format the photos from the repair and return it to be used as an array
 */
export const getPhotosFromRepair = (repair, photosFromRepair) => {
  let tmpPhotoStore = []
  if (photosFromRepair) {
    const objectKeys = Object.keys(photosFromRepair)
    for (let i = 0; i < objectKeys.length; i++) {
      try {
        if (photosFromRepair[`photo_${i}`]) {
          tmpPhotoStore.push({
            uri: photosFromRepair[`photo_${i}`],
            title: photosFromRepair[`photo_${i}_title`],
            description: photosFromRepair[`photo_${i}_description`],
            attributes: photosFromRepair[`photo_${i}_attr`],
            repair_id: repair?.id
          })
        }
      } catch (error) {
        tmpPhotoStore = []
      }
    }
  }
  return tmpPhotoStore
}

/**
 * Return status of the WO taking into account if the WO expired
 * @param {WorkOrder} wo
 * @returns status
 */
export const getWOstatus = wo => {
  const canWOsExpire = () => {
    const state = store.getState()
    return state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
      ?.workorders?.can_wos_expire
  }
  if (
    wo?.combinedStatus?.length > 1 &&
    wo?.combinedStatus?.includes('cancelled') &&
    wo?.combinedStatus?.includes('completed')
  ) {
    return 'completed_warning'
  }
  if (woFixedStatus.includes(wo?.status)) return wo?.status
  if (
    !canWOsExpire() &&
    moment(wo?.expiration_date).isBefore(moment().subtract(3, 'days').unix())
  ) {
    return 'expired'
  }
  return wo?.status
}

/**
 * Return parsed array for map component
 * @param {Array} zipCodeList
 * @returns parsed array
 */
export const parseMapPin = zipCodeList => {
  const parsedArray = []
  for (const item of zipCodeList) {
    if (item.selected) {
      parsedArray.push({
        id: item.id,
        zip: item.zip,
        text: item.city,
        lat: item.lat,
        lng: item.lon
      })
    }
  }
  return parsedArray
}
/**
 * Return parsed array for map component
 * @param {Array} zipCodeList
 * @returns parsed array
 */
export const parseMapUnselectedPin = zipCodeList => {
  const parsedArray = []
  for (const item of zipCodeList) {
    if (!item.selected) {
      parsedArray.push({
        id: item.zip,
        zip: item.zip,
        text: item.city,
        lat: item.lat,
        lng: item.lon
      })
    }
  }
  return parsedArray
}

/**
 *
 * @param {Array} zipCodesList array of zip codes object
 * @returns The number of selected zip codes
 */
export const getSelectedZiCodesNumber = zipCodesList => {
  let zipCodesLength = 0
  for (const item of zipCodesList) {
    if (item.selected) zipCodesLength++
  }
  return zipCodesLength
}

/**
 * vendor onboarding mandatory fields validation
 * @param {Object} fields mandatory fields
 * @param {Object} data company profile information
 * @returns true validation passed else return false
 */
export const profileMandatoryValidation = (fields, data) => {
  const mandatoryValidation =
    fields &&
    Object.keys(fields).map(key => {
      try {
        // check fields with a particular structure
        if (
          key === 'address' &&
          (_.isEmpty(data?.address) || data[key]?.address?.length === 0)
        ) {
          return false
        }
        if (key === 'country' && data[key]?.length === 0) {
          return false
        }
        if (key === 'states_registered' && data[key]?.length === 0) {
          return false
        }
        if (key === 'states_registered' && data[key]?.length > 0) {
          let stateValidation = true
          const states = data?.states_registered
          states?.forEach(register => {
            if (!register.state) {
              stateValidation = false
            }
          })
          return stateValidation
        }
        if (companyProfileFiles.includes(key) && !data[key]?.url) {
          return false
        }
        if (key === 'coi_policy') {
          return data?.coi?.coi_policy?.length !== 0
        }
        if (key === 'comp_policy') {
          return data?.comp?.comp_policy?.length !== 0
        }
        if (key === 'coi_date') {
          if (Number(data?.coi?.coi_month) === 0) return false
          if (Number(data?.coi?.coi_year) === 0) return false
          return true
        }
        if (key === 'comp_date') {
          if (Number(data?.comp?.comp_month) === 0) return false
          if (Number(data?.comp?.comp_year) === 0) return false
          return true
        }
        if (key.match(/^bank_.*/)) {
          if (!data?.bank[key]) {
            return false
          } else {
            return true
          }
        }
        if (key.match(/^q_.*/)) {
          if (!data?.insurance_questions[key]) {
            return false
          } else {
            return true
          }
        }
        // check other fields
        if (!data[key]) {
          return false
        } else {
          return true
        }
      } catch (error) {
        // return true for absent fields
        return true
      }
    })
  const response =
    typeof mandatoryValidation === 'undefined'
      ? false
      : !mandatoryValidation?.includes(false)
  return response
}

/**
 * Find central point of a set of coordinates
 * @param {Array<Object>} data array of coordinates
 * @returns coordinates of central point
 */
export const averageGeoLocation = coords => {
  if (coords.length === 1) {
    return coords[0]
  }
  let x = 0.0
  let y = 0.0
  let z = 0.0
  for (const coord of coords) {
    const latitude = (coord.lat * Math.PI) / 180
    const longitude = (coord.lng * Math.PI) / 180
    x += Math.cos(latitude) * Math.cos(longitude)
    y += Math.cos(latitude) * Math.sin(longitude)
    z += Math.sin(latitude)
  }
  const total = coords.length
  x = x / total
  y = y / total
  z = z / total
  const centralLongitude = Math.atan2(y, x)
  const centralSquareRoot = Math.sqrt(x * x + y * y)
  const centralLatitude = Math.atan2(z, centralSquareRoot)
  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI
  }
}

export const serviceAreasArray = areas => {
  if (Array.isArray(areas)) {
    return areas.map((item, index) => `Service Area ${index + 1}`)
  }
  return []
}

/**
 * Find coordinates from opensearch
 */
export const getCoordinatesFromZipList = async (zipIds, setReady) => {
  const chunkSize = 500
  const zipResponse = []
  setReady(false)
  for (let index = 0; index < zipIds.length; index += chunkSize) {
    const zipIdsChunk = zipIds.slice(index, index + chunkSize)
    // query polygon data for selected area
    const response = await callZipApi('POST', '/areas/', {
      zipId: JSON.stringify(zipIdsChunk)
    })
    zipResponse.push(response)
  }
  setReady(true)
  return zipResponse.flat()
}
export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index
}

/**
 * prevent spaces on key events
 */
export const handleSpace = event => {
  if (event.keyCode === 32) {
    event.preventDefault()
  }
}

/**
 * Calculate profile compliance
 */
export const calculateCompliance = (profile, requirements, usersCondition) => {
  if (!profile) return
  if (!requirements) return

  let total = 0
  // COMPANY INFORMATION
  for (const field in requirements.information.fields) {
    if (profile[field]) {
      total +=
        (requirements.information.section_total *
          requirements.information.fields[field]) /
        100
    }
  }

  // COMPANY INSURANCE
  for (const field in requirements.insurance.fields) {
    let fieldFound = false
    if (
      field === 'coi_date' &&
      profile?.coi?.coi_month &&
      profile?.coi?.coi_year
    ) {
      fieldFound = true
    }
    switch (field) {
      case 'coi_date':
        fieldFound = profile?.coi?.coi_month && profile?.coi?.coi_year
        break
      case 'coi_policy':
        fieldFound = !!profile?.coi?.coi_policy
        break
      case 'coi':
        fieldFound = !!profile?.coi?.url
        break
      case 'comp_date':
        fieldFound = profile?.comp?.comp_month && profile?.comp?.comp_year
        break
      case 'comp_policy':
        fieldFound = !!profile?.comp?.comp_policy
        break
      case 'comp':
        fieldFound = !!profile?.comp?.url
        break
      case 'payment_options':
        fieldFound = !!profile?.bank?.payment_options
        break
      case 'bank_name':
        fieldFound = !!profile?.bank?.bank_name
        break
      case 'bank':
        fieldFound = !!profile?.bank?.url
        break
      case 'q_trucks':
      case 'q_field_employees':
      case 'q_administrative_employees':
      case 'q_business_years':
      case 'q_subcontractors':
        fieldFound = !!profile?.insurance_questions[field]
        break
      case 'bank_check_payee':
        fieldFound = !!profile?.bank?.bank_check_payee
        break
      default:
        fieldFound = false
        break
    }
    if (profile[field] || fieldFound) {
      total +=
        (requirements.insurance?.section_total *
          requirements.insurance.fields[field]) /
        100
    }
  }

  // COMPANY TRADES AND SERVICES
  if (
    Array.isArray(profile?.trades) &&
    profile?.trades?.length > 0 &&
    profile?.trades[0].services?.length > 0
  ) {
    total += requirements.tradesServices.section_total
  }

  // COMPANY SERVICE AREA
  if (
    Array.isArray(profile?.service_area) &&
    profile?.service_area.length > 0
  ) {
    total += requirements.serviceArea.section_total
  }

  // Terms and conditions
  if (
    profile?.terms_and_conditions?.signed &&
    requirements?.terms?.section_total
  ) {
    total += requirements.terms.section_total
  }

  // COMPANY USERS
  if (usersCondition) {
    total += requirements.users.section_total
  }

  return total
}

export const getMainTableFilters = (defaultFilters, columns) => {
  // if no columns from configs return default: true
  if (!columns || columns.length === 0) {
    return defaultFilters.filter(item => item.default)
  }
  // order columns according to config
  const filterFields = new Set(columns.map(item => item.field))
  return defaultFilters.filter(item => filterFields.has(item.id))
}

export const generateTermsPDF = async (
  companyName,
  contentElementHTML,
  signed,
  userName,
  signDate,
  signTime,
  translations
) => {
  const downloadImagesAndReplaceSrc = async contentElement => {
    const parser = new DOMParser()
    const contentElementDOM = parser.parseFromString(
      contentElement,
      'text/html'
    )
    const imgElements = contentElementDOM.querySelectorAll('img')

    // Convert NodeList to an array
    const imgArray = Array.from(imgElements)

    await Promise.all(
      imgArray.map(async img => {
        const imageUrl = img.src
        try {
          const response = await fetch(imageUrl)
          const blob = await response.blob()
          const dataURL = await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          })
          img.src = dataURL // Replace img src with dataURL
        } catch (error) {
          console.error('Error downloading image:', error)
        }
      })
    )

    return contentElementDOM.body.innerHTML
  }

  try {
    const contentElement = await downloadImagesAndReplaceSrc(contentElementHTML)

    const options = {
      margin: 20,
      filename: `${
        companyName + translations('company_settings.terms_file.file_name')
      }${signed ? ' ' + signDate : ''}.pdf`,
      jsPDF: { format: 'a4' }
    }

    html2pdf()
      .from(contentElement)
      .set(options)
      .toPdf()
      .get('pdf')
      .then(pdf => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)

          // set header
          pdf.setFontSize(8)
          pdf.setTextColor('#828282')
          pdf.text(
            20,
            5,
            translations('company_settings.terms_file.confidential').replace(
              '%company%',
              companyName
            )
          )
          pdf.setFontSize(9)
          pdf.setTextColor('#333333')
          pdf.text(
            20,
            10,
            signed
              ? translations('company_settings.terms_file.accepted_by')
                  .replace('%name%', userName)
                  .replace('%date%', signDate)
                  .replace('%time%', signTime)
              : ''
          )

          // set footer
          pdf.setFontSize(7)
          pdf.text(
            pdf.internal.pageSize.getWidth() / 2,
            pdf.internal.pageSize.getHeight() - 5,
            i + '/' + totalPages
          )
          pdf.text(
            pdf.internal.pageSize.getWidth() - 50,
            pdf.internal.pageSize.getHeight() - 5,
            'powered by the ConnectAD Platform'
          )
        }
      })
      .save()
  } catch (error) {
    console.error('Error generating PDF:', error)
  }
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

// Function to clear complete cache data
export const clearCacheData = () => {
  caches.keys().then(names => {
    names.forEach(name => {
      caches.delete(name)
    })
  })
}

export const isRunningInIframe = () => {
  try {
    return window !== window.top
    // eslint-disable-next-line no-unreachable
  } catch (e) {
    return false // Assuming not in an iframe by default
  }
}

export const getSignedS3Url = async (path, contentType, action) => {
  // generates a presigned URL to download/upload file
  try {
    const signedUrl = await getS3SignedURL(
      path.split('subcontractor_documents/')[1],
      action,
      contentType
    )
    return signedUrl
  } catch (error) {
    store.dispatch(loadingActions.hide())
    console.error('File could not be downloaded ', error)
  }
}

export const downloadWithSignedUrl = async (url, clientId, name) => {
  // download file from S3
  const sseKey = Buffer.alloc(32, sseClientKeys[clientId])
  const encodedSseKey = encodeURI(sseKey.toString('base64'))
  const md5Hash = Buffer.from(encodeURI(md5(sseKey)), 'hex').toString('base64')
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'x-amz-server-side-encryption-customer-algorithm': 'AES256',
        'x-amz-server-side-encryption-customer-key': encodedSseKey,
        'x-amz-server-side-encryption-customer-key-MD5': md5Hash
      }
    })
    const byteArray = await response.arrayBuffer()
    const blob = new Blob([byteArray], {
      type: 'application/octet-stream'
    })
    const link = document.createElement('a')
    link.target = '_blank'
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', name)
    document.body.appendChild(link)
    link.click()
    link.remove()
    store.dispatch(loadingActions.hide())
  } catch (error) {
    store.dispatch(loadingActions.hide())
    console.error('File could not be downloaded ', error)
  }
}

export const uploadWithSignedUrl = async (
  signedUrl,
  file,
  uploadPath,
  clientId
) => {
  // upload file to S3
  const sseKey = Buffer.alloc(32, sseClientKeys[clientId])
  const encodedSseKey = encodeURI(sseKey.toString('base64'))
  const md5Hash = Buffer.from(encodeURI(md5(sseKey)), 'hex').toString('base64')

  try {
    const response = await fetch(signedUrl, {
      method: 'PUT',
      body: file,
      headers: {
        'Content-Type': file.type,
        'x-amz-server-side-encryption-customer-algorithm': 'AES256',
        'x-amz-server-side-encryption-customer-key': encodedSseKey,
        'x-amz-server-side-encryption-customer-key-MD5': md5Hash
      }
    })
    if (response?.ok) {
      return uploadPath
    }
  } catch (error) {
    console.error('File could not be uploaded ', error)
  }
}

export const equalObjects = (obj1, obj2) => {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  // If all keys and values match, return true
  return true
}

export function isNotEmpty(value) {
  if (value == null) {
    return false
  }
  if (typeof value === 'string') {
    return value.trim() !== '' && value.trim().length > 0
  }
  if (typeof value === 'number') {
    return !isNaN(value)
  }

  if (Array.isArray(value)) {
    return value.length > 0
  }

  if (typeof value === 'object') {
    return Object.keys(value).length > 0
  }
  return true
}

export const getOriginatingCompanyName = userInfo => {
  if (userInfo.parent_companies.length) {
    return userInfo.parent_companies[0]?.name ?? 'Management Company'
  } else {
    return 'Management Company'
  }
}

export function formatPhoneNumber(phoneNumber) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return phoneNumber
}

export const resizeImage = (file, maxSize = 600) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = readerEvent => {
      const image = new Image()
      image.onload = () => {
        const canvas = document.createElement('canvas')
        let { width, height } = image

        if (width > height) {
          if (width > maxSize) {
            height *= maxSize / width
            width = maxSize
          }
        } else {
          if (height > maxSize) {
            width *= maxSize / height
            height = maxSize
          }
        }

        canvas.width = width
        canvas.height = height

        // Get the 2D context of the canvas
        const ctx = canvas.getContext('2d')
        // Set the background color to white
        ctx.fillStyle = 'white'
        ctx.fillRect(0, 0, width, height)
        // Draw the image on the canvas
        ctx.drawImage(image, 0, 0, width, height)

        const resizedDataUrl = canvas.toDataURL(file.type)
        resolve({
          dataUrl: resizedDataUrl,
          width,
          height
        })
      }
      image.onerror = reject
      image.src = readerEvent.target.result
    }
    reader.onerror = reject
  })
}

export const isBase64Data = str => {
  if (typeof str !== 'string') return false
  const base64Regex = /^data:([A-Za-z-+.+/]+);base64,(.+)$/
  return base64Regex.test(str)
}

export const getContentTypeFromBase64 = base64String => {
  const match = base64String.match(/^data:([A-Za-z-+.+/]+);base64,/)
  return match ? match[1] : 'application/octet-stream'
}

export const getFileExtensionFromMimeType = mimeType => {
  const mimeTypeToExtension = {
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'application/pdf': 'pdf',
    'text/csv': 'csv',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx'
  }
  return mimeTypeToExtension[mimeType] || 'unknown'
}
