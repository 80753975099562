import React from 'react'
// import { useTranslation } from 'react-i18next'
import moment from 'moment'

// mui components
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { FormLabel } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import AttachmentComponent from '../form/AttachmentComponent'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    padding: '9px',
    boxShadow: '6px 9px 43px ' + theme.colors.workOrders.detailsCardBorderColor
  },
  field: {
    marginLeft: '5px',
    marginBottom: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  fieldData: {
    marginLeft: '5px',
    marginBottom: '30px',
    color: theme.colors.text,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '1px'
  },
  alignment: {
    paddingLeft: '5px'
  }
}))

export const WoDetails = props => {
  const { workOrder, attachments } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Card className={classes.root}>
      <CardContent
        style={{ paddingBottom: '3px' }}
        className={classes.alignment}
      >
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.location')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {workOrder?.store_name}
        </FormLabel>
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.address')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {workOrder?.address}
        </FormLabel>
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.nte')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {workOrder?.nte
            ? `$${workOrder?.nte}`
            : t('general.labels.not_available')}
        </FormLabel>
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.priority')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {workOrder?.priority}
        </FormLabel>
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.start_date')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {moment(new Date(workOrder?.open_date * 1000)).format(
            t('general.format.short_date_time')
          )}
        </FormLabel>
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.end_date')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {workOrder?.expiration_date === 0
            ? ''
            : moment(new Date(workOrder?.expiration_date * 1000)).format(
                t('general.format.short_date_time')
              )}
        </FormLabel>
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.trade')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {workOrder?.category}
        </FormLabel>
        <FormLabel component="legend" className={classes.field}>
          {t('work_orders.wo_details.services')}
        </FormLabel>
        <FormLabel component="legend" className={classes.fieldData}>
          {workOrder?.services?.map(item => item.name).join(', ')}
        </FormLabel>

        {workOrder?.contact_person &&
          (workOrder.contact_person[0] || workOrder.contact_person[1]) && (
            <>
              <FormLabel component="legend" className={classes.field}>
                {t('work_orders.wo_details.location_contacts')}
              </FormLabel>
              <FormLabel component="legend" className={classes.fieldData}>
                {workOrder?.contact_person && workOrder?.contact_person[0]}
                {workOrder?.contact_person && workOrder?.contact_person[1]}
              </FormLabel>
            </>
          )}
        {workOrder?.notes && (
          <>
            <FormLabel component="legend" className={classes.field}>
              {t('work_orders.wo_details.notes')}
            </FormLabel>
            <FormLabel component="legend" className={classes.fieldData}>
              {workOrder?.notes}
            </FormLabel>
          </>
        )}
        {workOrder?.general_notes && (
          <>
            <FormLabel component="legend" className={classes.field}>
              {t('work_orders.wo_details.general_notes')}
            </FormLabel>
            <FormLabel component="legend" className={classes.fieldData}>
              {workOrder?.general_notes}
            </FormLabel>
          </>
        )}
        {workOrder?.instructions && (
          <>
            <FormLabel component="legend" className={classes.field}>
              {t('work_orders.wo_details.instructions')}
            </FormLabel>
            <FormLabel component="legend" className={classes.fieldData}>
              {workOrder?.instructions}
            </FormLabel>
          </>
        )}
        {attachments && attachments.length > 0 && (
          <>
            <FormLabel component="legend" className={classes.field}>
              {t('work_orders.wo_details.attachments')}
            </FormLabel>
            <AttachmentComponent attachments={attachments} />
          </>
        )}
      </CardContent>
    </Card>
  )
}
