import { React, useEffect } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import LoadingBox from './LoadingBox'

const useStyles = makeStyles(theme => ({
  timeline: {
    overflowY: 'auto'
  },
  timelineStep: {
    '&.MuiTimelineItem-root': {
      minHeight: 'auto'
    }
  },
  timelineDetail: {
    fontSize: '12px',
    fontWeight: '500'
  },
  timelineCaption: {
    fontSize: '10px',
    fontWeight: '400'
  },
  timelineDot: {
    width: '37px',
    height: '37px',
    alignItems: 'center',
    margin: '0px !important',
    backgroundColor: theme.colors.invoiceColors.dark_grey + ' !important'
  },
  timelineDotText: {
    color: 'white',
    fontSize: '12px',
    fontWeight: '600',
    padding: '5px'
  },
  timelineHeader: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '21px',
    paddingBottom: '20px'
  },
  conector: {
    width: '1px',
    borderLeft: '1px dashed ' + theme.colors.invoiceColors.dark_grey,
    backgroundColor: 'white !important',
    '&.MuiTimelineConnector-root': {
      height: '30px'
    }
  },
  oppositeContent: {
    flex: '0.01 !important',
    padding: '0px !important'
  }
}))

function BasicTimeline(props) {
  const { steps, wHeight, loading } = props
  const classes = useStyles()
  const { t } = useTranslation()

  useEffect(() => {
    if (steps) {
      // sort in descending order
      steps.sort((a, b) => b.timestamp - a.timestamp)
    }
  }, [steps])
  return (
    <Timeline style={{ height: wHeight }} classes={{ root: classes.timeline }}>
      <Typography classes={{ root: classes.timelineHeader }}>
        {loading ? '' : t('invoices.audit')}
      </Typography>
      {loading
        ? [1, 2, 3, 4].map(index => (
            <LoadingBox
              key={`loading-${index}`}
              width="100%"
              height="50px"
              marginBottom="10px"
            />
          ))
        : steps?.map((step, index, rows) => (
            <TimelineItem
              key={step.timestamp}
              classes={{ root: classes.timelineStep }}
            >
              <TimelineOppositeContent
                classes={{ root: classes.oppositeContent }}
              ></TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot classes={{ root: classes.timelineDot }}>
                  <Typography classes={{ root: classes.timelineDotText }}>
                    {step.action.slice(0, 2).toUpperCase()}
                  </Typography>
                </TimelineDot>
                {index + 1 < rows.length ? (
                  <TimelineConnector classes={{ root: classes.conector }} />
                ) : (
                  ''
                )}
              </TimelineSeparator>
              <TimelineContent>
                <Typography classes={{ root: classes.timelineDetail }}>
                  {t('invoices.audit_actions.' + step.action)}
                </Typography>
                <Typography classes={{ root: classes.timelineCaption }}>
                  {moment(new Date(step.timestamp * 1000)).format(
                    t('general.format.short_date_time')
                  )}
                </Typography>
                <Typography classes={{ root: classes.timelineDetail }}>
                  {step.notes ? step.notes : ''}
                </Typography>
              </TimelineContent>
            </TimelineItem>
          ))}
    </Timeline>
  )
}
export const InvoiceTimeline = props => {
  const { steps, wHeight, loading } = props
  return <BasicTimeline steps={steps} wHeight={wHeight} loading={loading} />
}
