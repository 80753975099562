import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// MUI Components
import {
  IconButton,
  InputAdornment,
  makeStyles,
  Container,
  FormControl,
  Button,
  Box
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// main components
import { MainTable } from '../components/invoices/MainTable'
import { InStatusBar } from '../components/invoices/StatusBar'
import { InMcsStatusBar } from '../components/invoices/McsStatusBar'
import { useInvoiceSearch } from '../components/invoices/useInvoiceSearch'

import { CustomOutlinedInput } from '../styles/mui_custom_components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { invoiceNotSubmitted, mcssfr, mcs360 } from '../lib/Constants'

// services
import {
  getTermsAndConditions,
  getCompanyBillDisclosure, csvInvoicesData
} from '../services/ApiService'
import { authActions } from '../store/signIn'
import { isRunningInIframe } from '../lib/Global'
import { useParams } from 'react-router-dom'
import { setIframeOriginatingCompany } from '../lib/Api'
import { websocketActions } from '../store/websocket'
import { ExportCard } from '../components/shared/ExportCard'
import { store } from '../store'
import { loadingActions } from '../store/loading'
import { ConfirmDialog } from '../components/form/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  globalFiltersContainer: {
    height: '55px',
    width: '40%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    float: 'left',
    paddingLeft: '48px',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      float: 'none',
      height: '50px'
    }
  },
  statusBarContainer: {
    height: '55px',
    width: '60%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: theme.colors.white,
    padding: '0px',
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      height: 'auto'
    }
  },
  SearchInput: {
    paddingRight: 0,
    width: '230px',
    '& .MuiInputBase-root': {
      '& input': {
        fontSize: '12px'
      }
    },
    '& .MuiInputBase-input': {
      fontSize: '12px'
    },
    height: '32px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginRight: '0'
    }
  },
  datesInput: {
    height: '32px'
  },
  Counts: {
    fontSize: '12px',
    fontWeight: '400'
  },
  select: {
    '&:focus': {
      backgroundColor: theme.colors.transparent,
      padding: '0px 0px'
    }
  },
  selector: {
    height: '32px',
    fontSize: '12px',
    '& .MuiSelect-outlined': {
      paddingRight: '0px'
    }
  },
  exportButton: {
    marginLeft: '15px',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    padding: '0px 0px',
    marginTop: '1px'
  },
  exportInvoiceButton: {
    fontSize: '12px',
    color: theme.colors.iconBlue,
    border: '1px solid ' + theme.colors.disabledField,
    borderRadius: '45px',
    textTransform: 'none',
    padding: '10px 30px',
    lineHeight: '14px',
    marginLeft: '5px'
  },
  advancedButton: {
    paddingBottom: '5px',
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    marginLeft: '15px',
    marginTop: '-20px'
  },
  resetButton: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    padding: '0px 30px'
  },
  createButton: {
    color: 'primary',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'none',
    marginLeft: '15px',
    width: '145px',
    height: '45px',
    // button hidden temporarily
    display: 'none'
  },
  tableContainer: {
    margin: '0px',
    padding: '0px',
    width: '100%',
    maxWidth: '100%',
    [theme.breakpoints.down('xs')]: {
      width: 'fit-content',
      float: 'none'
    }
  },
  countsContainer: {
    margin: '0px 54px',
    padding: '0px 0px'
  },
  exportDialog: {
    borderRadius: '8px'
  },
  exportTitle: {
    color: theme.colors.text,
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '33px 0px',
    textAlign: 'center'
  },
  exportContent: {
    color: theme.colors.text,
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'center',
    width: '389px',
    margin: '0px 35px 33px 35px'
  },
  dialogButton: {
    marginLeft: 'auto',
    height: '40px',
    width: '84px',
    fontSize: '14px'
  },
  dialogContent: {
    display: 'flex !important'
  },
  serviceBox: {
    marginRight: '1px',
    textAlign: 'right'
  },
  reverseCol: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

const initFilters = {
  inStatus: '',
  won: '',
  client_name: '',
  client_location: '',
  invoice: '',
  amount: null,
  createDate: '',
  dueDate: '',
  payDate: '',
  achCheckNumber: '',
  asc_sort: '',
  desc_sort: ''
}

window.currentSearchIndex = 0

const Invoices = () => {
  const params = useParams()
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')
  const [searchSendValue, setSearchSendValue] = useState('')
  const [searchIndex, setSearchIndex] = useState(0)
  const [openExport, setOpenExport] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [reportData, setReportData] = useState([])
  const [exportByIds, setExportByIds] = useState(false)
  const [displayDownloadError, setDisplayDownloadError] = useState(false)

  const userStore = useSelector(state => state.auth.user)
  const [companyId, setCompanyId] = useState(
    isRunningInIframe() ? params.id : userStore.userInfo.company_id
  )

  const [openAdvanced, setOpenAdvanced] = useState(false)
  const [selected, setSelected] = useState([])
  const [tablePage, setTablePage] = useState(1)

  const filtersStore = useSelector(state => state.invoices.filters)
  const websocketStore = useSelector(state => state.websocket)

  const [filters, setFilters] = useState({
    ...filtersStore
  })

  const [searchEnabled, setSearchEnabled] = useState(false)

  history.replaceState(null, null, ' ')

  const { loading, invoices, setInvoices, chips, hasMore, openInvoices } =
    useInvoiceSearch(
      false,
      searchSendValue,
      filters.won,
      filters.inStatus,
      filters.client_name,
      filters.client_location,
      filters.invoice,
      filters.amount,
      filters.createDate,
      filters.dueDate,
      filters.payDate,
      filters.achCheckNumber,
      filters.asc_sort,
      filters.desc_sort,
      companyId,
      30,
      tablePage,
      searchEnabled
    )

  const [filteredInvoices, setFilteredInvoices] = useState(invoices)
  const [searchStatus, setSearchStatus] = useState('')
  const [disclosureLoaded, setDisclosureLoaded] = useState(false)
  if (isRunningInIframe()) {
    setIframeOriginatingCompany(params.id)
  }

  const getBillDisclosureInfo = async () => {
    const [termsAndConditions, billDisclosure] = await Promise.all([
      getTermsAndConditions(
        userStore.userInfo.originating_company,
        'bill_disclosure'
      ),
      getCompanyBillDisclosure(
        isRunningInIframe() ? params.id : userStore.userInfo.company_id
      )
    ])
    setDisclosureLoaded(true)
    dispatch(
      authActions.setBillDisclosure({
        ...billDisclosure.bill_disclosure,
        ...termsAndConditions?.bill_disclosure
      })
    )
  }

  useEffect(() => {
    setTablePage(1)
    setSearchEnabled(true)
    setTimeout(() => {
      setSearchEnabled(false)
    }, 500)
  }, [searchSendValue, filters])

  useEffect(() => {
    const filteredData = filterByStatus(invoices)
    setFilteredInvoices(filteredData)
    if (!disclosureLoaded && invoices?.length > 0) {
      getBillDisclosureInfo()
    }
  }, [searchStatus, invoices, filters])

  useEffect(() => {
    window.currentSearchIndex = searchIndex
    if (searchValue !== searchSendValue) {
      setSearchSendValue(searchValue)
    }
  }, [searchIndex])

  useEffect(() => {
    if (searchValue.length >= 4) {
      const timer = setTimeout(() => {
        setSearchSendValue(searchValue)
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [searchValue])

  useEffect(() => {
    console.info('websocket invoices', websocketStore.invoices)
    if (websocketStore.invoices && websocketStore.invoices.length > 0) {
      const foundIds = []
      websocketStore.invoices.forEach(invoice => {
        const found = invoices.find(
          inv => inv.external_id === invoice.external_id
        )
        if (found) foundIds.push(found.external_id)
      })
      setInvoices(records => [
        ...websocketStore.invoices,
        ...records.filter(invoice => !foundIds.includes(invoice.external_id))
      ])
      dispatch(websocketActions.setInvoices([]))
    }
  }, [websocketStore.invoices])

  const filterByStatus = invoices => {
    if (!searchStatus || searchStatus === '') {
      return invoices
    }
    let filterValues = []
    if (
      searchStatus === t('invoices.in_states.not_submitted').toLocaleLowerCase()
    ) {
      filterValues = invoiceNotSubmitted
    } else {
      filterValues = [searchStatus]
    }
    const filteredData = openInvoices.filter(
      invoice => filterValues.indexOf(invoice.status) !== -1
    )
    return filteredData
  }

  const handleChangeSearch = event => {
    const value = event.target.value
    setSearchValue(value)
  }

  const handleKeyDownSearch = event => {
    if (event.keyCode === 13 && searchValue.length >= 4) {
      setSearchIndex(searchIndex + 1)
    }
  }

  const handleClearGlobalSearch = event => {
    setSearchValue('')
    setSearchIndex(searchIndex + 1)
  }

  const handleBlurSearch = () => {
    if (searchValue.length >= 4) {
      /* Blur delayed if clear exists */
      setTimeout(() => {
        setSearchIndex(searchIndex + 1)
      }, 100)
    }
  }

  const handleCleanFilters = () => {
    setFilters(initFilters)
    setSearchValue('')
    setSearchIndex(searchIndex + 1)
    setCompanyId(
      isRunningInIframe() ? params.id : userStore.userInfo.company_id
    )
    setSelected([])
    setSearchStatus('')
  }

  const validateFilters = () => {
    let value = true
    // eslint-disable-next-line array-callback-return
    Object.keys(initFilters).map(element => {
      if (initFilters[element] !== filters[element]) {
        value = false
      }
      return null
    })
    if (searchValue.length !== 0) {
      value = false
    }
    return value
  }

  const requestByIds = ids => {
    let invoiceIds = null
    setDownloading(true)
    if (ids) {
      invoiceIds = ids === 'all' ? '' : ids.join(',')
    } else {
      invoiceIds = selected.join(',')
    }
    csvInvoicesData(
      false,
      searchSendValue,
      filters.won,
      filters.inStatus,
      filters.client_name,
      filters.client_location,
      filters.invoice,
      filters.amount,
      filters.createDate,
      filters.dueDate,
      filters.payDate,
      filters.achCheckNumber,
      filters.asc_sort,
      filters.desc_sort,
      companyId,
      30,
      tablePage,
      invoiceIds
    )
      .then(result => {
        // download the file from the result.path URL
        if (result?.path?.length > 0) {
          const link = document.createElement('a')
          link.href = result.path
          link.target = '_blank'
          // extract file name from the path
          const fileName = result.path.split('/').pop()
          link.setAttribute('download', fileName)
          link.click()
        } else {
          setDisplayDownloadError(true)
        }
      })
      .finally(() => {
        setDownloading(false)
        store.dispatch(loadingActions.hide())
        setExportByIds(false)
      })
  }

  return (
    <div>
      <div className={classes.reverseCol}>
        <Container
          style={{ overflow: 'hidden' }}
          className={classes.globalFiltersContainer}
          data-testid={'invoice_page'}
        >
          <FormControl variant="outlined" data-testid={'search_field'}>
            <CustomOutlinedInput
              value={searchValue}
              className={classes.SearchInput}
              id="component-outlined"
              placeholder={t('general.labels.search')}
              onChange={handleChangeSearch}
              onKeyDown={handleKeyDownSearch}
              onBlur={handleBlurSearch}
              type="search"
              startAdornment={
                <InputAdornment position="start">
                  <FontAwesomeIcon
                    icon={['far', 'search']}
                    size="xs"
                    color="grey"
                  />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleClearGlobalSearch}>
                    {searchValue.length > 0 ? (
                      <ClearIcon
                        style={{ fontSize: 'small' }}
                        fontSize="small"
                      />
                    ) : (
                      <div />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              autoComplete="off"
            />
          </FormControl>
          <Box className={classes.filtersContainer}>
            {(!validateFilters()) && (
              <Button
                data-testid={'reset_button'}
                size="small"
                classes={{ root: classes.resetButton }}
                onClick={handleCleanFilters}
              >
                {t('general.labels.reset_filters')}
              </Button>
            )}
          </Box>
          <div>
            {selected.length > 0 ? (
              <Button
                size="small"
                classes={{ root: classes.exportInvoiceButton }}
                onClick={() => setOpenExport(!openExport)}
                variant="outlined"
              >
                {downloading ? (
                  <FontAwesomeIcon
                    icon="fa-regular fa-loader"
                    spin
                    className={classes.loader}
                  />
                ) : (
                  t('invoices.export_invoice_selected')
                )}
              </Button>
            ) : null}
            <ExportCard
              requestData={requestByIds}
              allBooks={reportData}
              setAllBooks={setReportData}
              open={openExport}
              setOpen={setOpenExport}
              chips={chips}
              rows={[]}
              selected={selected}
              downloading={downloading}
              exportByIds={exportByIds}
              parent={'invoices'}
            />
          </div>
          <Button
            variant="outlined"
            color="primary"
            classes={{ root: classes.createButton }}
            href="/createInvoice"
          >
            {t('invoices.in_actions.create')}
          </Button>
        </Container>
        <Container classes={{ root: classes.statusBarContainer }}>
          {userStore &&
          userStore.userInfo.parent_subdomain !== mcssfr &&
          userStore.userInfo.parent_subdomain !== mcs360 ? (
            <InStatusBar
              content={openInvoices}
              setSearchStatus={setSearchStatus}
            ></InStatusBar>
          ) : (
            <InMcsStatusBar
              content={openInvoices}
              setSearchStatus={setSearchStatus}
            ></InMcsStatusBar>
          )}
        </Container>
      </div>
      <Container classes={{ root: classes.tableContainer }}>
        <MainTable
          chips={chips}
          content={filteredInvoices}
          openAdvanced={openAdvanced}
          setOpenAdvanced={setOpenAdvanced}
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          hasMore={hasMore}
          setTablePage={setTablePage}
          filters={filters}
          setFilters={setFilters}
          handleCleanFilters={handleCleanFilters}
          validateFilters={validateFilters}
          setSearchEnabled={setSearchEnabled}
          openInvoices={openInvoices}
          totalCount={
            searchStatus !== '' ? filteredInvoices.length : chips.totalCount
          }
        />
        <ConfirmDialog
          open={displayDownloadError}
          setOpen={setDisplayDownloadError}
          message={t('general.messages.errors.download_error')}
          handleAccept={() => {
            setDisplayDownloadError(false)
          }}
          acceptlabel="Ok"
        />
      </Container>
    </div>
  )
}

export default Invoices
