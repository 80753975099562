import {
  Box,
  Button,
  Card,
  InputLabel,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import GlobalInput from '../form/TextInput'
import { downloadWithSignedUrl, getSignedS3Url } from '../../lib/Global'
import GlobalNumberInput from '../form/NumberInput'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    boxShadow: '6px 9px 43px ' + theme.colors.profile.shadowCard,
    marginBottom: '2em',
    padding: '1em',
    color: theme.colors.text
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: '700'
  },
  editButton: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '0',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.05em',
    lineHeight: '19px'
  },
  cardSubtitle: {
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: '1em'
  },
  sectionDivider: {
    marginTop: '2em'
  },
  boxContainer: {
    gap: '1em'
  },
  marginContainer: {
    marginRight: '1em'
  },
  disabledText: {
    '& input.Mui-disabled': {
      WebkitTextFillColor: theme.colors.text,
      padding: '0.5em 1em'
    }
  },
  disabledTextCenter: {
    '& input.Mui-disabled': {
      textAlign: 'center',
      WebkitTextFillColor: theme.colors.text,
      padding: '0.5em 1em',
      marginTop: '0.5em'
    }
  },
  phoneInput: {
    padding: '0.5em 1em !important'
  },
  question: {
    fontSize: '12px',
    paddingTop: '12px',
    color: theme.colors.grey,
    [theme.breakpoints.down('xs')]: {
      paddingRight: '12px'
    }
  },
  fileCard: {
    backgroundColor: theme.colors.grey_2,
    margin: 'auto 1em 1em',
    padding: '0.5em',
    border: '1px solid ' + theme.colors.profile.darkCard,
    borderRadius: '12px',
    boxShadow: 'none',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      margin: '0',
      width: '100%'
    }
  },
  fileTitle: {
    fontSize: '12px',
    fontWeight: '500',
    marginBottom: '0.5em'
  },
  fileData: {
    fontSize: '12px',
    paddingBottom: '0.5em'
  },
  fileMissingData: {
    fontStyle: 'italic',
    color: theme.colors.grey
  },
  fileLink: {
    fontSize: '12px',
    textDecoration: 'underline',
    color: theme.colors.iconBlue,
    paddingBottom: '0.5em',
    wordBreak: 'break-all'
  },
  questionPill: {
    textAlign: 'center',
    padding: '0.5em 1em !important',
    border: '1px solid ' + theme.colors.profile.border_input,
    borderRadius: '45px',
    fontSize: '12px',
    cursor: 'default',
    margin: '10px 0px'
  },
  flex: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  fileCardMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gridGap: '5px'
    }
  },
  justifyContent: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      alignItems: 'flex-end'
    }
  }
}))

const EditButton = props => {
  const classes = useStyles()
  return (
    <Button className={classes.editButton} onClick={props.onClick}>
      {props.label}
    </Button>
  )
}

const FileCard = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  function toMonthName(monthNumber) {
    const date = new Date()
    date.setDate(1)
    date.setMonth(monthNumber - 1)

    // using visitor's default locale
    return date.toLocaleString([], {
      month: 'short'
    })
  }

  async function handleFileDownload(event, name) {
    try {
      const downloadUrl = await getSignedS3Url(props.data, '', 'getObject')
      downloadWithSignedUrl(downloadUrl, props.clientId, name)
    } catch (error) {
      console.error('File could not be downloaded ', error)
    }
  }

  const showOrHideExpiration = () => {
    if (props.year && props.year) {
      return (
        t('company_settings.card.expiration') +
        ': ' +
        (toMonthName(props.month) + ' ' + props.year)
      )
    }
  }

  return (
    <Card className={classes.fileCard}>
      <Box>
        <Typography className={classes.fileTitle}>{props.label}</Typography>
        {props.number && (
          <Typography className={classes.fileData}>{props.number}</Typography>
        )}
        {props.data ? (
          <Link
            onClick={async event =>
              await handleFileDownload(event, props.name, props.clientId)
            }
            target="_blank"
            rel="noopener"
          >
            <Typography className={classes.fileLink}>
              {props.name ?? 'file'}
            </Typography>
          </Link>
        ) : (
          <Typography
            className={`${classes.fileData} ${classes.fileMissingData}`}
          >
            {t('company_settings.card.no_file')}
          </Typography>
        )}

        <Typography className={classes.fileData}>
          {showOrHideExpiration()}
        </Typography>
      </Box>
    </Card>
  )
}

export const ProfileInfoCard = props => {
  const classes = useStyles()
  const { t } = useTranslation()

  const handleEditProfile = () => {
    props.setComponent('profile')
    props.setOpen(true)
  }
  const handleEditInsurance = () => {
    props.setComponent('insurance')
    props.setOpen(true)
  }

  const businessHoursOfOperation = () => {
    if (
      (props?.profile?.business_hours?.time_from === null ||
        props?.profile?.business_hours?.time_from === undefined) &&
      (props?.profile?.business_hours?.time_to === null ||
        props?.profile?.business_hours?.time_to === undefined)
    ) {
      return t('company_profile.none')
    } else {
      return (
        props?.profile?.business_hours?.time_from?.replace(/^0+/, '') +
        ' - ' +
        props?.profile?.business_hours?.time_to?.replace(/^0+/, '')
      )
    }
  }

  const afterHoursOfOperation = () => {
    if (
      (props?.profile?.after_hours?.time_from === null ||
        props?.profile?.after_hours?.time_from === undefined) &&
      (props?.profile?.after_hours?.time_to === null ||
        props?.profile?.after_hours?.time_to === undefined)
    ) {
      return t('company_profile.none')
    } else {
      return (
        props?.profile?.after_hours?.time_from?.replace(/^0+/, '') +
        ' - ' +
        props?.profile?.after_hours?.time_to?.replace(/^0+/, '')
      )
    }
  }

  const weekendBusinessHoursOfOperation = () => {
    if (
      (props?.profile?.business_hours?.weekend_time_from === null ||
        props?.profile?.business_hours?.weekend_time_from === undefined) &&
      (props?.profile?.business_hours?.weekend_time_to === null ||
        props?.profile?.business_hours?.weekend_time_to === undefined)
    ) {
      return t('company_profile.none')
    } else {
      return (
        props?.profile?.business_hours?.weekend_time_from?.replace(/^0+/, '') +
        ' - ' +
        props?.profile?.business_hours?.weekend_time_to?.replace(/^0+/, '')
      )
    }
  }

  const weekendAfterHoursOfOperation = () => {
    if (
      (props?.profile?.after_hours?.weekend_time_from === null ||
        props?.profile?.after_hours?.weekend_time_from === undefined) &&
      (props?.profile?.after_hours?.weekend_time_to === null ||
        props?.profile?.after_hours?.weekend_time_to === undefined)
    ) {
      return t('company_profile.none')
    } else {
      return (
        props?.profile?.after_hours?.weekend_time_from?.replace(/^0+/, '') +
        ' - ' +
        props?.profile?.after_hours?.weekend_time_to?.replace(/^0+/, '')
      )
    }
  }

  const capitalizeFirstLetter = string => {
    return string?.charAt(0).toUpperCase() + string?.slice(1)
  }

  const support247 = props?.profile?.support_24_7

  return (
    <Card className={classes.card}>
      <Box display="flex" flexDirection="row">
        <Typography classes={{ root: classes.cardTitle }}>
          {t('company_settings.card.profile')}
        </Typography>
        <EditButton
          label={t('company_settings.buttons.edit')}
          onClick={handleEditProfile}
        />
      </Box>
      <Box className={classes.marginContainer}>
        <Box className={classes.sectionDivider}>
          <Typography classes={{ root: classes.cardSubtitle }}>
            {t('company_settings.card.name')}
          </Typography>
        </Box>

        <Box className={`${classes.boxContainer} ${classes.flex}`}>
          <Box flex={2}>
            <GlobalInput
              field="name"
              value={props?.profile?.name ?? 'N/A'}
              label={t('company_profile.labels.company_legal')}
              disabled
              className={classes.disabledText}
            />
          </Box>
          <Box flex={1} display="flex" className={classes.boxContainer}>
            <GlobalInput
              field="entity"
              value={props?.profile?.legal_entity ?? 'N/A'}
              label={t('company_profile.labels.entity')}
              disabled
              className={classes.disabledText}
            />
            <GlobalInput
              field="dba"
              value={props?.profile?.dba ?? 'N/A'}
              label={t('company_profile.labels.dba')}
              disabled
              className={classes.disabledText}
            />
          </Box>
        </Box>

        <Box className={`${classes.boxContainer} ${classes.flex}`}>
          <Box flex={2}>
            <GlobalInput
              field="address"
              value={
                props?.profile?.address?.address?.length
                  ? props?.profile?.address?.address
                  : 'N/A'
              }
              label={t('company_profile.labels.headquarters')}
              disabled
              className={classes.disabledText}
            />
          </Box>
          <Box flex={1} className={classes.flexNone}>
            <GlobalInput
              field="countries"
              value={
                props?.profile?.country?.length
                  ? props?.profile?.country
                  : 'N/A'
              }
              label={t('company_profile.labels.country')}
              disabled
              className={classes.disabledText}
            />
          </Box>
        </Box>

        <Box className={`${classes.boxContainer} ${classes.flex}`}>
          <Box
            flex={2}
            display="flex"
            flexDirection="row"
            className={`${classes.boxContainer} ${classes.justifyContent}`}
          >
            <GlobalNumberInput
              field="businesshours_phone"
              value={props?.profile?.business_hours?.phone ?? 'N/A'}
              label={t('company_profile.labels.business_hours')}
              disabled
              className={classes.phoneInput}
              format="(###) ###-####"
            />
            <GlobalInput
              field="business_days_of_operation"
              value={
                support247
                  ? t('company_profile.week')
                  : t('company_profile.weekdays')
              }
              label={t('company_profile.labels.business_days_operation')}
              disabled
              className={classes.disabledText}
            />
          </Box>
          <Box flex={1}>
            <GlobalInput
              field="business_times_of_operation"
              value={
                support247
                  ? t('company_profile.hours24')
                  : businessHoursOfOperation()
              }
              label={t('company_profile.labels.times_operation')}
              disabled
              className={classes.disabledText}
            />
          </Box>
        </Box>

        <Box
          className={`${classes.boxContainer} ${classes.flex}`}
          display={support247 ? 'none' : 'flex'}
        >
          <Box flex={2} className={`${classes.boxContainer} ${classes.flex}`}>
            <Box flex={1}></Box>
            <Box flex={1}>
              <GlobalInput
                field="business_days_of_operation"
                value={
                  props?.profile?.business_hours?.weekend_days === null ||
                  props?.profile?.business_hours?.weekend_days === undefined
                    ? 'None'
                    : capitalizeFirstLetter(
                        props?.profile?.business_hours?.weekend_days
                      )
                }
                label={t('company_profile.labels.business_days_operation')}
                disabled
                className={classes.disabledText}
              />
            </Box>
          </Box>
          <Box flex={1}>
            <GlobalInput
              field="business_times_of_operation"
              value={weekendBusinessHoursOfOperation()}
              label={t('company_profile.labels.times_operation')}
              disabled
              className={classes.disabledText}
            />
          </Box>
        </Box>

        <Box
          className={`${classes.boxContainer} ${classes.flex}`}
          display={support247 ? 'none' : 'flex'}
        >
          <Box
            flex={2}
            display="flex"
            flexDirection="row"
            className={classes.boxContainer}
          >
            <GlobalNumberInput
              field="afterhours_phone"
              value={
                props?.profile?.after_hours?.phone === ''
                  ? t('company_profile.none')
                  : props?.profile?.after_hours?.phone
              }
              label={t('company_profile.labels.after_hours_business_phone')}
              disabled
              className={classes.phoneInput}
              format="(###) ###-####"
            />
            <GlobalInput
              field="afterhours_days_of_operation"
              value={t('company_profile.weekdays')}
              label={t('company_profile.labels.days_operation_after')}
              disabled
              className={classes.disabledText}
            />
          </Box>
          <Box flex={1}>
            <GlobalInput
              field="afterhours_of_operation"
              value={afterHoursOfOperation()}
              label={t('company_profile.labels.hours_of_operation')}
              disabled
              className={classes.disabledText}
            />
          </Box>
        </Box>

        <Box className={`${classes.boxContainer} ${classes.flex}`}>
          <Box flex={2} className={`${classes.boxContainer} ${classes.flex}`}>
            <Box flex={1}></Box>
            <Box flex={1}>
              <GlobalInput
                field="afterhours_days_of_operation"
                value={
                  props?.profile?.after_hours?.weekend_days === null ||
                  props?.profile?.after_hours?.weekend_days === undefined
                    ? t('company_profile.none')
                    : capitalizeFirstLetter(
                        props?.profile?.after_hours?.weekend_days
                      )
                }
                label={t('company_profile.labels.days_operation_after')}
                disabled
                className={classes.disabledText}
              />
            </Box>
          </Box>
          <Box flex={1}>
            <GlobalInput
              field="afterhours_of_operation"
              value={weekendAfterHoursOfOperation()}
              label={t('company_profile.labels.hours_of_operation')}
              disabled
              className={classes.disabledText}
            />
          </Box>
        </Box>

        <Box className={`${classes.boxContainer} ${classes.flex}`}>
          <Box
            flex={2}
            display="flex"
            flexDirection="row"
            className={`${classes.boxContainer} ${classes.justifyContent}`}
          >
            <GlobalInput
              field="businesshours_phone"
              value={
                !props?.profile?.dispatch_email
                  ? t('company_profile.none')
                  : props?.profile?.dispatch_email
              }
              label={t('company_profile.labels.dispatch')}
              disabled
              className={classes.disabledText}
            />
            <GlobalInput
              field="countries"
              value={
                !props?.profile?.invoice_email
                  ? t('company_profile.none')
                  : props?.profile?.invoice_email
              }
              label={t('company_profile.labels.invoice_email')}
              disabled
              className={classes.disabledText}
            />
          </Box>
          <Box flex={1}></Box>
        </Box>
      </Box>

      <Box className={`${classes.sectionDivider} ${classes.flex}`}>
        <Typography classes={{ root: classes.cardSubtitle }}>
          {t('company_settings.card.insurance')}
        </Typography>
        <EditButton
          label={t('company_settings.buttons.edit')}
          onClick={handleEditInsurance}
        />
      </Box>
      {/* Insurance info */}
      <Box className={classes.flex}>
        <Box flex={2} marginRight="1em">
          <GlobalInput
            field="ein"
            value={props?.profile?.ein ?? 'N/A'}
            label={t('company_profile.labels.ein')}
            disabled
            className={classes.disabledText}
          />
          {props.profile?.states_registered?.map((register, index) => {
            return (
              <Box
                display="flex"
                flexDirection="row"
                className={classes.boxContainer}
                key={register.state + index}
              >
                <GlobalInput
                  field="state"
                  value={register.state ?? 'N/A'}
                  label={t('company_profile.labels.state')}
                  disabled
                  className={classes.disabledText}
                />
                <GlobalInput
                  field="license"
                  value={register.license ?? 'N/A'}
                  label={t('company_profile.labels.contractor_licence')}
                  disabled
                  className={classes.disabledText}
                />
              </Box>
            )
          })}
          {props.profile?.states_registered?.length === 0 && (
            <Box
              display="flex"
              flexDirection="row"
              className={classes.boxContainer}
            >
              <GlobalInput
                field="state"
                value={'N/A'}
                label={t('company_profile.labels.state')}
                disabled
                className={classes.disabledText}
              />
              <GlobalInput
                field="license"
                value={'N/A'}
                label={t('company_profile.labels.contractor_licence')}
                disabled
                className={classes.disabledText}
              />
            </Box>
          )}
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.questionDivider}
            >
              <Box flex={5}>
                <InputLabel className={classes.question}>
                  {t('company_profile.questions.trucks')}
                </InputLabel>
              </Box>
              <Box flex={1}>
                <Typography className={classes.questionPill}>
                  {props?.profile?.insurance_questions?.q_trucks ?? 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.questionDivider}
            >
              <Box flex={5}>
                <InputLabel className={classes.question}>
                  {t('company_profile.questions.field_employees')}
                </InputLabel>
              </Box>
              <Box flex={1}>
                <Typography className={classes.questionPill}>
                  {props?.profile?.insurance_questions?.q_field_employees ??
                    'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.questionDivider}
            >
              <Box flex={5}>
                <InputLabel className={classes.question}>
                  {t('company_profile.questions.administrative_employees')}
                </InputLabel>
              </Box>
              <Box flex={1}>
                <Typography className={classes.questionPill}>
                  {props?.profile?.insurance_questions
                    ?.q_administrative_employees ?? 'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.questionDivider}
            >
              <Box flex={5}>
                <InputLabel className={classes.question}>
                  {t('company_profile.questions.years')}
                </InputLabel>
              </Box>
              <Box flex={1}>
                <Typography className={classes.questionPill}>
                  {props?.profile?.insurance_questions?.q_business_years ??
                    'N/A'}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.questionDivider}
            >
              <Box flex={5}>
                <InputLabel className={classes.question}>
                  {t('company_profile.questions.subcontractors')}
                </InputLabel>
              </Box>
              <Box flex={1}>
                <Typography className={classes.questionPill}>
                  {props?.profile?.insurance_questions?.q_subcontractors
                    ? t('company_profile.yes')
                    : t('company_profile.no') ?? 'N/A'}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex={1} className={classes.fileCardMobile}>
          <FileCard
            label={t('company_settings.card.w9')}
            data={props.profile?.w9?.url}
            name={props.profile?.w9?.file_name}
            clientId={props.clientId}
          />
          <FileCard
            label={t('company_settings.card.coi')}
            data={props.profile?.coi?.url}
            name={props.profile?.coi?.file_name}
            month={props.profile?.coi?.coi_month}
            year={props.profile?.coi?.coi_year}
            clientId={props.clientId}
          />
          <FileCard
            label={t('company_settings.card.comp')}
            data={props.profile?.comp?.url}
            name={props.profile?.comp?.file_name}
            month={props.profile?.comp?.comp_month}
            year={props.profile?.comp?.comp_year}
            clientId={props.clientId}
          />
          <FileCard
            label={t('company_settings.card.bank')}
            data={props.profile?.bank?.url}
            name={props.profile?.bank?.file_name}
            clientId={props.clientId}
          />
        </Box>
      </Box>
    </Card>
  )
}
