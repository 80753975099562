import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
// mui components
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Typography,
  Grid
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  statusTable: {
    borderRadius: '10px 0px 0px 10px'
  },
  boxTitle: {
    color: 'white',
    fontSize: '18px',
    fontWeight: '800'
  },
  boxSub: {
    color: 'white',
    fontSize: '9px',
    fontWeight: '400',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    textAlign: 'center',
    marginTop: '-5px'
  },
  declined: {
    color: 'white',
    backgroundColor: theme.colors.invoiceColors.declined,
    width: '145px',
    height: '46px',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '600',
    border: '1px solid',
    transition: 'transform 380ms',
    '&:hover': {
      backgroundColor: theme.colors.invoiceColors.declined_solid,
      transform: 'scale(1.1)'
    }
  },
  open: {
    color: 'white',
    backgroundColor: theme.colors.invoiceColors.open,
    width: '145px',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '600',
    border: '1px solid',
    transition: 'transform 380ms',
    '&:hover': {
      backgroundColor: theme.colors.invoiceColors.open_solid,
      transform: 'scale(1.1)'
    }
  },
  notSubmitted: {
    color: 'white',
    backgroundColor: theme.colors.invoiceColors.not_submitted,
    width: '145px',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '600',
    border: '1px solid',
    borderRadius: '10px 0px 0px 10px',
    transition: 'transform 380ms',
    '&:hover': {
      backgroundColor: theme.colors.invoiceColors.not_submitted_solid,
      transform: 'scale(1.1)'
    }
  },
  drafts: {
    color: 'white',
    backgroundColor: theme.colors.invoiceColors.draft,
    width: '145px',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: '600',
    border: '1px solid',
    transition: 'transform 380ms',
    '&:hover': {
      backgroundColor: theme.colors.invoiceColors.draft_solid,
      transform: 'scale(1.1)'
    }
  },
  totalOpen: {
    color: 'white',
    backgroundColor: theme.colors.invoiceColors.approved,
    width: '245px',
    border: '1px solid',
    textAlign: 'center',
    transition: 'transform 380ms',
    '&:hover': {
      backgroundColor: theme.colors.invoiceColors.approved_solid,
      transform: 'scale(1.1)'
    }
  },
  tableRow: {
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      '& td': {
        width: '100%',
        padding: '10px 41px',
        height: 'auto',
        borderRadius: '0'
      },
      '& td:last-child': {
        gridColumn: 'span 2'
      },
      '& td:hover': {
        transform: 'none',
        filter: 'brightness(75%)'
      }
    },
    [theme.breakpoints.only('sm')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      '& td': {
        width: '100%',
        padding: '10px 41px',
        height: 'auto',
        borderRadius: '0'
      },
      '& td:hover': {
        transform: 'none',
        filter: 'brightness(75%)'
      },
      '& td:last-child': {
        gridColumn: 'span 2'
      }
    }
  }
}))

function createData(notSubmitted, declined, open, drafts, totalOpen) {
  return { notSubmitted, declined, open, drafts, totalOpen }
}

function StatusBar(props) {
  const { content, setSearchStatus } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [statusRows, setStatusRows] = useState([])

  useEffect(() => {
    let open = 0
    let ns = 0
    let declined = 0
    let drafts = 0
    let totalOpen = 0
    if (content?.length > 0) {
      content?.forEach(invoice => {
        if (invoice.status === t('invoices.in_states.open').toLowerCase()) {
          open++
          ns++
          totalOpen += invoice.total ? Number(invoice?.total) : 0
        }
        if (invoice.status === t('invoices.in_states.declined').toLowerCase()) {
          declined++
          ns++
          totalOpen += invoice.total ? Number(invoice?.total) : 0
        }
        if (invoice.status === t('invoices.in_states.draft').toLowerCase()) {
          drafts++
          ns++
          totalOpen += invoice.total ? Number(invoice?.total) : 0
        }
      })
    }
    const newRows = [createData(ns, declined, open, drafts, totalOpen)]
    setStatusRows(newRows)
  }, [content])

  const handleClick = (event, data) => {
    setSearchStatus(data.toLowerCase())
  }

  return (
    <Grid container justifyContent="flex-end">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          {statusRows?.map(row => (
            <TableRow className={classes.tableRow} key="in-table-body">
              <TableCell
                classes={{ root: classes.notSubmitted }}
                onClick={event =>
                  handleClick(event, t('invoices.in_states.not_submitted'))
                }
              >
                {row.notSubmitted} {t('invoices.in_states.not_submitted')}
              </TableCell>
              <TableCell
                classes={{ root: classes.declined }}
                onClick={event =>
                  handleClick(event, t('invoices.in_states.declined'))
                }
              >
                {row.declined} {t('invoices.in_states.declined')}
              </TableCell>
              <TableCell
                classes={{ root: classes.open }}
                onClick={event =>
                  handleClick(event, t('invoices.in_states.open'))
                }
              >
                {row.open} {t('invoices.in_states.open')}
              </TableCell>
              <TableCell
                classes={{ root: classes.drafts }}
                onClick={event =>
                  handleClick(event, t('invoices.in_states.draft'))
                }
              >
                {row.drafts} {t('invoices.in_states.drafts')}
              </TableCell>
              <TableCell
                classes={{
                  root: classes.totalOpen
                }}
                onClick={event =>
                  handleClick(event, t('invoices.in_states.not_submitted'))
                }
              >
                <Box classes={{ root: classes.boxTitle }}>
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={row.totalOpen}
                    prefix="$"
                    decimalSeparator="."
                    displayType="text"
                    type="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />

                  <Typography classes={{ root: classes.boxSub }}>
                    {t('invoices.in_states.total_open')}
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  )
}

export const InStatusBar = props => {
  const { content, setSearchStatus } = props
  return (
    <div>
      <StatusBar content={content} setSearchStatus={setSearchStatus} />
    </div>
  )
}
