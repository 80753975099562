// main components
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// mui components
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core'
import avatarImage from '../../assets/images/account_avatar.jpeg'

/** Redux */
import { useSelector } from 'react-redux'

/** Services **/
import { UpdateAccountInfo } from './UpdateAccountInfo'
import { getCompanyRoles } from '../../services/ApiService'
import { formatPhoneNumber } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    margin: '0px 15px 13px 62px',
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.colors.disabledField}`,
    [theme.breakpoints.down('sm')]: {
      margin: '0'
    }
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    margin: '23px 0px 22px 20px',
    font: 'Rubik',
    [theme.breakpoints.down('xs')]: {
      margin: '23px 0px 22px'
    }
  },
  field: {
    fontSize: '14px',
    fontWeight: '500',
    color: theme.colors.settings.fieldName
  },
  info: {
    fontSize: '14px',
    fontWeight: '400',
    color: theme.colors.settings.fieldInfo,
    marginBottom: '25px',
    wordWrap: 'break-word',
    paddingRight: '10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '12px'
    }
  },
  gridImg: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatar: {
    width: '140px',
    height: '140px'
  },
  content: {
    padding: '32px 0px 20px 0px'
  },
  infoCell: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  boxBlock: {
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  }
}))

export const AccountInfoCard = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const user = useSelector(state => state.auth.user)
  const [editDrawer, setEditDrawer] = useState(false)
  const [userInfo, setUserInfo] = useState(JSON.parse(JSON.stringify(user)))
  const [roles, setRoles] = useState()

  let firstLoad = true
  useEffect(async () => {
    try {
      if (firstLoad) {
        const response = await getCompanyRoles(
          userInfo.userInfo.originating_company
        )

        if (response) {
          setRoles(response)
        }
        firstLoad = false
      }
    } catch (error) {
      console.error(error)
    }
  }, [firstLoad])

  const handleClosePanel = u => {
    setEditDrawer(false)
    setUserInfo(u)
  }

  return (
    <div>
      <UpdateAccountInfo
        editDrawer={editDrawer}
        handleClosePanel={handleClosePanel}
        accountInfo={userInfo}
        accountOwner
        roles={roles}
      />
      <Card className={classes.card} data-testid={'account_info_card'}>
        <CardActionArea
          onClick={() => {
            setEditDrawer(true)
          }}
        >
          <Typography classes={{ root: classes.title }}>
            {t('account_settings.info_card.title').toUpperCase()}
          </Typography>
          <Divider />
          <CardContent classes={{ root: classes.content }}>
            <Box
              display="flex"
              flexDirection="row"
              className={classes.boxBlock}
            >
              <Box flex={1} classes={{ root: classes.gridImg }}>
                <Avatar
                  alt="profile"
                  src={userInfo.userInfo.photo_url ?? avatarImage}
                  classes={{ root: classes.avatar }}
                />
                <Typography classes={{ root: classes.info }}>
                  {t('account_settings.info_card.profile_pic')}
                </Typography>
              </Box>
              <Box flex={2}>
                <Box className={classes.infoRow}>
                  <Box className={classes.infoCell}>
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.name')}
                    </Typography>
                    <Typography
                      classes={{ root: classes.info }}
                    >{`${userInfo.userInfo.firstName} ${userInfo.userInfo.lastName}`}</Typography>
                  </Box>
                  <Box className={classes.infoCell}>
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.employee_id')}
                    </Typography>
                    <Typography classes={{ root: classes.info }}>
                      {userInfo.userInfo.employee_id ?? ''}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.infoRow}>
                  <Box className={classes.infoCell}>
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.email')}
                    </Typography>
                    <Typography classes={{ root: classes.info }}>
                      {userInfo.userInfo.email}
                    </Typography>
                  </Box>
                  <Box className={classes.infoCell}>
                    {' '}
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.phone_number')}
                    </Typography>
                    <Typography
                      classes={{ root: classes.info }}
                    >{`${formatPhoneNumber(
                      userInfo.userInfo.phone
                    )}`}</Typography>
                  </Box>
                </Box>
                <Box className={classes.infoRow}>
                  <Box className={classes.infoCell}>
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.username')}
                    </Typography>
                    <Typography classes={{ root: classes.info }}>
                      {userInfo.userInfo.username}
                    </Typography>
                  </Box>
                  <Box className={classes.infoCell}>
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.company_role')}
                    </Typography>
                    <Typography classes={{ root: classes.info }}>
                      {roles?.find(x => x.id === userInfo.userInfo.roles)
                        ?.name ?? ''}
                    </Typography>
                  </Box>
                </Box>
                <Box className={classes.infoRow}>
                  <Box className={classes.infoCell}>
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.user_title')}
                    </Typography>
                    <Typography classes={{ root: classes.info }}>
                      {userInfo.userInfo.role &&
                        t(
                          'create_account.user_roles.' + userInfo.userInfo.role
                        )}
                    </Typography>
                  </Box>
                  <Box className={classes.infoCell}>
                    <Typography classes={{ root: classes.field }}>
                      {t('account_settings.info_card.password')}
                    </Typography>
                    <Typography classes={{ root: classes.info }}>
                      {'***********'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  )
}
