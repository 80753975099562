import React from 'react'
import Routes from './Routes'
import customTheme from './styles/mui_theme'
import { MuiThemeProvider } from '@material-ui/core'

function App () {
  return (
    <MuiThemeProvider theme={customTheme}>
      <Routes/>
    </MuiThemeProvider>
  )
}

export default App
