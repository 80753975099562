import React from 'react'
import { Link, useLocation } from 'react-router-dom'

/** Redux **/
import { useSelector } from 'react-redux'

/** Material UI **/
import {
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  makeStyles,
  ThemeProvider,
  Typography
} from '@material-ui/core'

/** Components **/
import {
  buttonSettingsDisabled,
  enableButtonStyle
} from '../styles/mui_custom_theme'
import { store } from '../store'
import { authActions } from '../store/signIn'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { connectSubcontractorLogo } from '../lib/Constants'

const pjson = require('../../package.json')
const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      overflowY: 'scroll'
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.colors.backdropColor
  },
  logoContainer: {
    padding: '20px',
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '20px',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  logo: {
    height: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '15px'
    }
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 100px)',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '70%'
    }
  },
  background: { backgroundColor: 'white' },
  dialogPaper: {
    width: '577px',
    height: '250px',
    borderRadius: '9px'
  },
  dialogTitle: {
    fontWeight: '700',
    fontSize: '24px',
    textAlign: 'center',
    letterSpacing: '0.05em'
  },
  dialogContentFont: {
    fontSize: '18px',
    textAlign: 'center',
    color: theme.colors.basicDisabledButtonColor,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '21px'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: '10px'
  },
  footer: {
    width: '100%',
    height: '80px',
    paddingTop: '10px',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: '60px'
    }
  },
  footerText: {
    fontWeight: '300',
    color: theme.colors.text,
    fontSize: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px'
    }
  },
  link: {
    fontWeight: '300',
    fontSize: '20px',
    color: theme.colors.text,
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px'
    }
  },
  version: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-end',
    gap: '1em',
    [theme.breakpoints.up('sm')]: {
      paddingRight: '15px'
    }
  },
  footerMessage: {
    flex: '2',
    textAlign: 'center'
  },
  footerHeader: {
    fontSize: '14px',
    fontWeight: '600'
  },
  footerSubtitle: {
    fontSize: '10px',
    fontWeight: '400',
    color: theme.colors.grey
  }
}))

export const SignInContainer = props => {
  const { t } = useTranslation()
  const loading = useSelector(state => state.loading.loading)
  const classes = useStyles()
  const forceLogout = useSelector(state => state.auth.forceLogout)
  const invalidUser = useSelector(state => state.auth.invalidUser)
  const location = useLocation()

  return (
    <div className={classes.background}>
      <CssBaseline />
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.container}>
        <Box className={classes.logoContainer}>
          {location.pathname !== '/sign-in' && (
            <img className={classes.logo} src={connectSubcontractorLogo} />
          )}
        </Box>
        <Box className={classes.body}>{props.children}</Box>
        <Box className={classes.footer}>
          <Box flex="1" />
          <Box className={classes.footerMessage}>
            <Typography className={classes.footerHeader}>
              Powered by Automated Decision
            </Typography>
            <Typography className={classes.footerSubtitle}>
              We solve problems with technology for all things facilities.
            </Typography>
          </Box>
          <Box className={classes.version}>
            <Typography component={'span'} className={classes.footerText}>
              v{pjson.version}
            </Typography>
            <Link
              className={classes.link}
              rel="noopener noreferrer"
              to={{ pathname: 'https://automateddecision.com/contact-us/' }}
              target="_blank"
            >
              {t('sign_in.contact_us')}
            </Link>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={forceLogout || invalidUser}
        keepMounted
        onClose={() => forceLogout ? store.dispatch(authActions.setForceLogout()) : store.dispatch(authActions.setInvalidUser())}
        classes={{ paper: classes.dialogPaper }}
        BackdropProps={{ invisible: true }}
        style={{ zIndex: 2000 }}
        disableAutoFocus={false}
      >
        <DialogTitle
          disableTypography={true}
          classes={{ root: classes.dialogTitle }}
        >
          {forceLogout ? t('sign_in.logout.title') : t('sign_in.invalid_user.title')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <FormLabel className={classes.dialogContentFont}>
            {forceLogout ? t('sign_in.logout.message') : t('sign_in.invalid_user.message')}
          </FormLabel>

          <Box className={classes.dialogButtonContainer}>
            <ThemeProvider theme={buttonSettingsDisabled}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                type="submit"
                style={{
                  ...enableButtonStyle,
                  marginRight: 0
                }}
                onClick={() => forceLogout ? store.dispatch(authActions.setForceLogout()) : store.dispatch(authActions.setInvalidUser())}
              >
                Ok
              </Button>
            </ThemeProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}
