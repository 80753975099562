/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import { Box, MenuItem, Select } from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import CustomizedProgressBars from './ProgressBar'
import { NotesComponent } from './repair/NotesComponent'
import { PhotoComponent } from './repair/PhotoComponent'
import { getPhotosFromRepair } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '&.Mui-disabled': {
      background: theme.colors.errorColor,
      color: theme.constructionForm.disabledGrey
    }
  },
  additional: {
    fontSize: '9px',
    fontWeight: 700,
    alignItems: 'center',
    display: 'flex',
    color: theme.colors.black,
    marginLeft: '.5rem',
    marginTop: '.9rem'
  },
  photos: {
    fontSize: '9px',
    fontWeight: 700,
    alignItems: 'center',
    display: 'flex',
    color: theme.colors.black,
    marginLeft: '.5rem',
    marginTop: '.9rem'
  },
  heading: {
    fontSize: theme.typography.pxToRem(10),
    fontWeight: 700,
    alignItems: 'center',
    display: 'flex',
    color: 'black',
    marginLeft: '16px'
  },
  quantity: {
    fontSize: '10px',
    fontWeight: 700
  },
  customAccordion: {
    '& .MuiAccordion-root': {
      '&:not(:last-child)': {
        marginBottom: 0
      }
    },
    customAccordionSummaryContent: {
      '&.Mui-expanded': {
        margin: 0,
        backgroundColor: 'transparent'
      }
    },

    position: 'relative',
    marginBottom: '5px',
    '&.Mui-expanded': {
      minHeight: 40
    },
    '&.MuiAccordionSummary-root': {
      minHeight: 10
    },
    '&.MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px 0',
      marginLeft: '.5rem',
      '&.MuiIconButton-label': {
        marginLeft: '.5rem'
      }
    }
  },

  customExpandIcon: {
    backgroundColor: 'transparent !important',
    position: 'absolute',
    paddingTop: '4px',
    right: '0px',
    color: theme.colors.black,
    '&.Mui-disabled': {
      background: 'red',
      color: theme.constructionForm.disabledGrey
    }
  },
  customIconButtonLabel: {
    marginLeft: '1rem'
  },
  parentHeading: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    color: 'black'
  },
  subHeading: {
    fontWeight: 400,
    fontSize: '13px',
    color: 'black'
  },
  paragraph: {
    fontWeight: 400,
    fontSize: '13px',
    width: '90%',
    color: theme.colors.text
  },
  footerBadge: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  percentageAction: {
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.colors.black
  },
  percentageActionRed: {
    fontSize: '10px',
    textTransform: 'uppercase',
    fontWeight: 600,
    color: theme.colors.errorColor
  },
  sameItems: {
    fontSize: '9px',
    color: theme.colors.black,
    fontWeight: 700,
    marginLeft: '5px',
    textTransform: 'uppercase'
  },
  sameItemsValue: {
    fontSize: '13px',
    color: theme.colors.dialog.text_grey,
    fontWeight: 400,
    marginLeft: '5px'
  },
  itemHeading: {
    fontSize: '9px',
    fontWeight: 700,
    alignItems: 'center',
    display: 'flex',
    color: theme.colors.black,
    paddingTop: '1rem',
    marginLeft: '5px',
    textTransform: 'uppercase'
  },
  progressBar: {
    height: '100%',
    width: 60,
    borderRadius: 50,
    flexDirection: 'column-reverse',
    backgroundColor: theme.colors.white
  },
  percentageMargin: {
    marginBottom: '5px'
  },
  selectBox: {
    paddingRight: '0',
    '& .MuiSvgIcon-root': {
      display: 'none'
    },
    '&:before': {
      display: 'none'
    },
    '&:after': {
      display: 'none'
    },
    '& > .MuiSelect-root': {
      paddingRight: '0'
    },
    width: '100%',
    height: 50,
    fontWeight: 400,
    color: theme.colors.workOrders.scopeOfWorkBlue1 + '!important',
    backgroundColor: theme.colors.grey_2,
    borderRadius: '5px',
    paddingLeft: '15px',
    boxShadow: 'none',
    fontSize: '13px'
  },
  menuItem: {
    fontSize: theme.typography.pxToRem(10)
  },
  marginB: {
    marginBottom: '20px'
  },
  customAccordionSummaryRoot: {
    alignItems: 'flex-start !important',
    '&.Mui-expanded': {
      minHeight: '5px',
      height: '0rem',
      '&.MuiIconButton-label': {
        marginLeft: '.5rem'
      }
    }
  },
  expandIcon: {
    marginLeft: '-0.1rem !important'
  },
  title: {
    textTransform: 'uppercase'
  }
}))

const StyledINTERIORBadge = withStyles(theme => ({
  badge: {
    minWidth: '.9rem !important',
    height: '.9rem !important',
    backgroundColor: theme.colors.workOrders.scopeOfWorkBlue3,
    marginRight: '-5px'
  }
}))(Badge)

export function LineItems({
  lineItems,
  setFormData,
  isCompleted,
  formData,
  handleOpenPhotos
}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [toggle] = useState(true)
  const [index, setIndex] = useState(-1)

  const handleStatusChange = (event, id) => {
    const _value = event.target.value?.replace('% Complete', '')
    const index = lineItems.findIndex(item => item.id === id)
    const data = { ...formData }
    data.line_items[index].status.progress = _value
    setFormData(data)
  }

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const togleHandler = (_index, toggle) => {
    if (index === _index) {
      setIndex(-1)
    } else {
      setIndex(_index)
    }
  }

  const ChildComponent = ({
    product_name,
    item,
    id,
    task,
    productDescription,
    quantity,
    status,
    trade,
    _index
  }) => {
    const updatePhotos = photos => {
      let added_photos = null
      const data = { ...formData }
      if (photos?.length) {
        added_photos = {}
        let index = 0
        photos.forEach(photo => {
          added_photos[`photo_${index}`] = photo.uri
          added_photos[`photo_${index}_title`] = photo.title
            ? photo.title.toString()
            : null
          added_photos[`photo_${index}_attr`] = photo.attributes
            ? photo.attributes
            : null
          added_photos[`photo_${index}_id`] = `no_id${index}`
          index++
        })
      }

      if (added_photos === null && data.additional_photos) {
        delete data.line_items[_index].additional_photos
      } else data.line_items[_index].additional_photos = added_photos

      setFormData({ ...formData })
    }

    const updateNoteValue = async value => {
      const data = { ...formData }
      data.line_items[_index].notes = value
      setFormData({ ...formData })
    }

    const progressSelection =
      status.progress && status.progress !== '0'
        ? `${status.progress}% Completed`
        : ''

    const fcfStatusProgress =
      formData?.field_construction_forms?.[_index]?.data?.line_item?.status
        ?.progress

    return (
      <div key={_index} className={classes.root}>
        <Accordion
          TransitionProps={{ unmountOnExit: false }}
          expanded={expanded === `panel${_index + 1}`}
          onChange={handleChange(`panel${_index + 1}`)}
          className={classes.customAccordion}
          disableGutters={false}
        >
          <AccordionSummary
            classes={{
              root: classes.customAccordionSummaryRoot,
              content: classes.customAccordionSummaryContent,
              expandIcon: classes.customExpandIcon,
              label: classes.customIconButtonLabel
            }}
            onClick={() => togleHandler(_index, toggle)}
            style={{
              transition: '1s'
            }}
            expandIcon={<ExpandLessIcon className={classes.expandIcon} />}
          >
            {index !== _index && (
              <Box
                style={{
                  width: '100%'
                }}
              >
                <Box className={classes.parentHeading}>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: 'center'
                    }}
                  >
                    <StyledINTERIORBadge color="secondary" variant="dot" />
                    <Typography className={classes.heading}>
                      {formData?.field_construction_forms?.[_index]?.data
                        ?.line_item?.product_name || product_name}
                    </Typography>
                  </Box>

                  <Typography className={classes.quantity}>
                    QTY{' '}
                    {formData?.field_construction_forms?.[_index]?.data
                      ?.line_item?.quantity || quantity}
                  </Typography>
                </Box>
                <Typography className={classes.subHeading}>
                  {formData?.field_construction_forms?.[_index]?.data?.line_item
                    ?.item || item}
                </Typography>
                <div className={classes.percentageMargin}>
                  <Typography className={classes.paragraph}>
                    {formData?.field_construction_forms?.[_index]?.data
                      ?.line_item?.task || task}
                  </Typography>
                </div>

                <Box className={classes.footerBadge}>
                  <Typography
                    className={
                      (fcfStatusProgress && fcfStatusProgress === '0') ||
                      (status?.progress === '0' && !fcfStatusProgress)
                        ? classes.percentageActionRed
                        : classes.percentageAction
                    }
                  >
                    {(fcfStatusProgress && fcfStatusProgress === '0') ||
                    (status?.progress === '0' && !fcfStatusProgress)
                      ? `${fcfStatusProgress || status?.progress}% ` +
                        t(
                          'work_orders.construction_form.complete_action_required'
                        )
                      : `${fcfStatusProgress || status?.progress}% ` +
                        t('work_orders.construction_form.complete')}
                  </Typography>
                  <Box className={classes.progressBar}>
                    <CustomizedProgressBars
                      percentage={fcfStatusProgress || status?.progress}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Box
              style={{
                width: '100%'
              }}
            >
              <Box>
                <div className={classes.marginB}>
                  <Typography className={classes.sameItems}>
                    {t('work_orders.wo_details.trade')}
                  </Typography>
                  <Typography className={classes.sameItemsValue}>
                    {formData?.field_construction_forms?.[_index]?.data
                      .line_item?.trade || trade}
                  </Typography>
                </div>
                <Box>
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box>
                      <Typography className={classes.sameItems}>
                        {t('work_orders.construction_form.qty')}
                      </Typography>
                      <Typography className={classes.sameItemsValue}>
                        {quantity}
                      </Typography>
                    </Box>
                    <div className={classes.marginB}>
                      <Box>
                        <Typography className={classes.sameItems}>
                          {t('work_orders.construction_form.item')}
                        </Typography>
                        <Typography className={classes.sameItemsValue}>
                          {id}
                        </Typography>
                      </Box>
                    </div>
                  </Box>
                  <Box>
                    <Typography className={classes.sameItems}>
                      {t('work_orders.construction_form.product_name')}
                    </Typography>
                    <Typography className={classes.sameItemsValue}>
                      {formData?.field_construction_forms?.[_index]?.data
                        .line_item?.product_name || product_name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <div className={classes.marginB}>
                  <Typography className={classes.itemHeading}>
                    {t('work_orders.construction_form.completion_status')}
                  </Typography>
                  {formData?.field_construction_forms ? (
                    <Typography className={classes.sameItemsValue}>
                      {
                        formData?.field_construction_forms[_index]?.data
                          ?.line_item?.status.progress
                      }
                      %
                    </Typography>
                  ) : (
                    <>
                      <Select
                        className={classes.selectBox}
                        inputProps={{ 'aria-label': 'Without label' }}
                        value={progressSelection}
                        displayEmpty
                        onChange={e => handleStatusChange(e, id)}
                        renderValue={value =>
                          value === ''
                            ? t('work_orders.construction_form.select')
                            : value
                        }
                      >
                        <MenuItem
                          className={classes.menuItem}
                          disabled
                          value=""
                        >
                          {t('work_orders.construction_form.select')}
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          value={t('work_orders.construction_form.complete_0')}
                        >
                          {t('work_orders.construction_form.complete_0')}
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          value={t('work_orders.construction_form.complete_25')}
                        >
                          {t('work_orders.construction_form.complete_25')}
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          value={t('work_orders.construction_form.complete_50')}
                        >
                          {t('work_orders.construction_form.complete_50')}
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          value={t('work_orders.construction_form.complete_75')}
                        >
                          {t('work_orders.construction_form.complete_75')}
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          value={t(
                            'work_orders.construction_form.complete_100'
                          )}
                        >
                          {t('work_orders.construction_form.complete_100')}
                        </MenuItem>
                      </Select>
                    </>
                  )}
                </div>
                {formData.field_construction_forms ? (
                  <div className={classes.marginB}>
                    <NotesComponent
                      title={t(
                        'work_orders.construction_form.additional_comments'
                      )}
                      disabled={formData?.field_construction_forms}
                      notes={
                        formData?.field_construction_forms[_index]?.data?.notes
                      }
                      customClasses={classes.title}
                    />
                  </div>
                ) : (
                  <div className={classes.marginB}>
                    <NotesComponent
                      title={t(
                        'work_orders.construction_form.additional_comments'
                      )}
                      onUpdate={value => updateNoteValue(value)}
                      notes={formData ? formData.line_items[_index].notes : ''}
                      customClasses={classes.title}
                    />
                  </div>
                )}
                <PhotoComponent
                  onUpdate={photos => updatePhotos(photos)}
                  customClasses={classes.title}
                  disabled={formData?.field_construction_forms}
                  handleOpenPhotos={handleOpenPhotos}
                  minRequired={Number(status.progress) === 100 ? 1 : 0}
                  photos={
                    formData?.field_construction_forms
                      ? getPhotosFromRepair(
                          formData?.field_construction_forms[_index],
                          formData?.field_construction_forms[_index]?.data
                            ?.additional_photos
                        )
                      : formData?.line_items[_index]?.additional_photos
                      ? getPhotosFromRepair(
                          formData?.line_items[_index],
                          formData?.line_items[_index]?.additional_photos
                        )
                      : []
                  }
                />
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }
  return (
    <>
      {lineItems &&
        lineItems
          .filter(({ status }, _index) =>
            isCompleted
              ? formData?.field_construction_forms?.[_index]?.data?.line_item
                  ?.status?.progress !== '0'
              : true
          )
          .map(
            (
              {
                product_name,
                item,
                id,
                task,
                productDescription,
                quantity,
                status,
                trade
              },
              _index
            ) => (
              <ChildComponent
                key={_index}
                {...{
                  product_name,
                  item,
                  id,
                  task,
                  productDescription,
                  quantity,
                  status,
                  trade,
                  _index
                }}
              />
            )
          )}
    </>
  )
}
