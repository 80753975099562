import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useState, useEffect } from 'react'

/** Material UI **/
import {
  HighlightButton,
  SignInButton,
  CustomTextField
} from '../../styles/mui_custom_components'
/** FontAwesome **/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

/** Services **/
import { login } from '../../services/AuthService'

/** Validations **/
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

/** Redux **/
import { useDispatch } from 'react-redux'
import { authActions } from '../../store/signIn'
import { store } from '../../store'

/** Images **/
import googleIcon from '../../assets/icons/google.svg'
import microsoftIcon from '../../assets/icons/microsoft.svg'

import { LoadingSplash } from '../../components/LoadingSplash'
import { connectSubcontractorLogo } from '../../lib/Constants'

const useStyles = makeStyles(theme => ({
  mainContainer: {
    margin: '0 50px',
    transform: 'scale(0.80)',
    [theme.breakpoints.down('sm')]: {
      margin: '0'
    }
  },
  mainItem: {
    margin: '0 50px',
    [theme.breakpoints.down('sm')]: {
      margin: '0'
    }
  },
  logo: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '30px',
    [theme.breakpoints.down('sm')]: {
      height: '20px'
    }
  },
  icon: {
    width: '1em',
    position: 'relative',
    top: '-4px'
  },
  slogan: {
    fontWeight: '400',
    fontSize: '25px',
    margin: '1em',
    textAlign: 'center'
  },
  divider: {
    marginLeft: '0',
    marginTop: '35px',
    marginBottom: '35px'
  },
  link: {
    fontWeight: '300',
    fontSize: '20px',
    color: theme.colors.text,
    textDecoration: 'none'
  },
  rememberMe: {
    fontWeight: '300',
    fontSize: '20px',
    opacity: '90%'
  },
  errorMessage: {
    color: theme.colors.errorText,
    fontWeight: '300',
    fontSize: '20px',
    display: 'flex'
  },
  forgotPass: {
    display: 'flex',
    justifyContent: 'center',
    opacity: '90%',
    marginTop: '2em',
    fontSize: '20px',
    fontWeight: '300',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  createButton: {
    cursor: 'pointer',
    color: theme.colors.iconBlue,
    paddingLeft: '5px',
    fontSize: '20px',
    fontWeight: '300'
  },
  loginForm: {
    width: '80%',
    margin: 'auto'
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '1em'
  }
}))

const SignIn = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showErrors, setShowErrors] = useState(false)
  const [rememberMe, setRemember] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const authStore = store.getState().auth
    if (authStore.email && authStore.password) {
      setEmail(authStore.email)
      setPassword(authStore.password)
      setValue('email', authStore.email)
      setValue('password', authStore.password)
      setRemember(true)
    }
  }, [])

  /** VALIDATIONS **/
  const validationSchema = yup.object().shape({
    email: yup.string().required(t('general.messages.errors.required')),
    password: yup.string().required(t('general.messages.errors.required'))
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = async () => {
    try {
      setLoading(true)
      await login(email, password)
      if (rememberMe) {
        dispatch(authActions.setRememberEmail(email))
        dispatch(authActions.setRememberPassword(password))
      } else {
        const authStore = store.getState().auth
        if (authStore.email) {
          dispatch(authActions.removeRemember())
        }
      }
    } catch (error) {
      switch (error.code) {
        case 401:
          setShowErrors(t('sign_in.messages.wrong_user_password'))
          break
        case 402:
          setShowErrors(t('sign_in.messages.user_no_permission'))
          break
      }
      setLoading(false)
    }
  }

  const requestAccessClickHandler = event => {
    event.preventDefault()
    history.push('/create-account')
  }

  const handleEmailChange = event => {
    setShowErrors(null)
    setEmail(event.target.value)
  }

  const handlePasswordChange = event => {
    setShowErrors(null)
    setPassword(event.target.value)
  }

  const handleChangeRemember = event => {
    setRemember(!rememberMe)
  }

  return loading ? (
    <LoadingSplash />
  ) : (
    <div className={classes.mainContainer}>
      <Grid data-testid={'sign_in_page'}>
        <Grid className={classes.mainItem} item xs={12}>
          <img
            alt={'Connect AD Platform'}
            className={classes.logo}
            src={connectSubcontractorLogo}
          />
        </Grid>
        <Grid className={classes.mainItem} item xs={12}>
          <Typography className={classes.slogan}>
            {t('sign_in.slogan')}
          </Typography>
        </Grid>
        <Grid className={classes.mainItem} item xs={12}>
          {/* TODO: un hide buttons when needed */}
          <Box hidden={true} mt={5}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item>
                <SignInButton
                  type="button"
                  variant="contained"
                  startIcon={
                    <Icon>
                      <img
                        alt={'google'}
                        className={classes.icon}
                        src={googleIcon}
                      />
                    </Icon>
                  }
                >
                  {t('sign_in.sign_in_google')}
                </SignInButton>
              </Grid>
              <Grid item>
                <SignInButton
                  type="button"
                  variant="contained"
                  startIcon={
                    <Icon>
                      <img
                        alt={'microsoft'}
                        className={classes.icon}
                        src={microsoftIcon}
                      />
                    </Icon>
                  }
                >
                  {t('sign_in.sign_in_microsoft')}
                </SignInButton>
              </Grid>
            </Grid>
          </Box>

          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.loginForm}>
              <Divider className={classes.divider} variant="inset" />
              <CustomTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder={'Hello@company.com'}
                name="email"
                autoFocus
                autoComplete="off"
                type="text"
                error={!!errors.email}
                {...register('email')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="fa-regular fa-user" />
                    </InputAdornment>
                  )
                }}
                onInput={handleEmailChange}
                value={email}
              />
              <CustomTextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder={'••••••••••••••••'}
                type="password"
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                {...register('password')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon="fa-regular fa-lock" />
                    </InputAdornment>
                  )
                }}
                onInput={handlePasswordChange}
                value={password}
              />
              <Box hidden={!showErrors}>
                <Typography align={'center'} className={classes.errorMessage}>
                  {showErrors}
                </Typography>
              </Box>

              <Box hidden={!errors.password || !errors.email}>
                <Typography align={'left'} className={classes.errorMessage}>
                  {t('sign_in.messages.missing_fields')}
                </Typography>
              </Box>
              <Box className={classes.actionsContainer}>
                <Box>
                  <FormControlLabel
                    className={classes.rememberMe}
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={handleChangeRemember}
                        color="primary"
                        icon={
                          <CheckBoxOutlineBlankIcon
                            style={{ fontSize: '30px' }}
                          />
                        }
                        checkedIcon={
                          <CheckBoxIcon style={{ fontSize: '30px' }} />
                        }
                      />
                    }
                    label={
                      <Typography className={classes.rememberMe}>
                        {t('sign_in.remember_me')}
                      </Typography>
                    }
                  />
                </Box>

                <Box className={classes.buttonsContainer}>
                  <Link
                    data-testid="forgot_password"
                    className={classes.link}
                    to="/forgot-password"
                    variant="body2"
                  >
                    {t('sign_in.forgot_password')}
                  </Link>
                  <HighlightButton
                    data-testid="sign_in_button"
                    disabled={!email || !password}
                    type="submit"
                    variant="contained"
                  >
                    {t('sign_in.sign_in')}
                  </HighlightButton>
                </Box>
                <Box className={classes.forgotPass}>
                  {t('sign_in.no_account')}
                  <Typography
                    data-testid="create_account_button"
                    className={classes.createButton}
                    onClick={requestAccessClickHandler}
                  >
                    {t('sign_in.create_account')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </form>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignIn
