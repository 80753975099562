import { React, useState, useEffect, useRef } from 'react'

// mui components
import { makeStyles, Container, Grid, Typography, Box } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import GlobalCheckbox from '../form/TextCheckbox'
import GlobalChip from '../form/Chip'
import { ServiceCard } from './ServicesCard'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    fontSize: '14px',
    fontWeight: '600',
    paddingTop: '24px'
  },
  itemContainer: {
    padding: '24px 80px 0px 80px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0 0 0'
    }
  },
  noTopPadding: {
    paddingLeft: '80px',
    paddingRight: '80px',
    [theme.breakpoints.down('md')]: {
      padding: '0'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    color: theme.colors.black,
    lineHeight: '24px',
    letterSpacing: '0.05em'
  },
  title_secondary: {
    fontSize: '16px',
    fontWeight: '700',
    color: theme.colors.black,
    lineHeight: '19px',
    letterSpacing: '0.05em',
    marginLeft: '80px',
    marginTop: '36px'
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '17px',
    color: theme.colors.black,
    paddingTop: '9px',
    letterSpacing: '0em'
  },
  checkboxes: {
    gridGap: '27px',
    [theme.breakpoints.down('md')]: {
      gridGap: '12px'
    }
  },
  required: {
    color: theme.colors.errorText
  }
}))

export const ClientsComponent = props => {
  const [trades, setTrades] = useState()
  const [services, setServices] = useState()
  const classes = useStyles()
  const { t } = useTranslation()
  const [clients, setClients] = useState()
  const [tradesSelected, setTradesSelected] = useState(new Set())
  const [tradesAndServices, setTradesAndServices] = useState([])
  const { profile, servicesCatalog } = props
  const topContainer = useRef()

  useEffect(() => {
    setTrades(props?.displayTrades?.map(trade => trade.name).sort() ?? [])
    setServices(props?.displayTrades)
    // To make sure page starts from the top
    topContainer.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    // init objects
    if (profile?.trades) {
      const tradeNames = props?.displayTrades.map(trade => trade.name)
      const filteredTrades = profile?.trades.filter(trade => {
        return tradeNames.includes(trade.name)
      })
      setTradesSelected(new Set([...filteredTrades?.map(trade => trade.name)]))
      setTradesAndServices([...filteredTrades])
    }
    // init clients/industries
    const filteredArray = props.displayClients.reduce((arr, key) => {
      if (Object.prototype.hasOwnProperty.call(props.catalogClients, key)) {
        arr.push({
          key,
          label: props.catalogClients[key],
          serve: false
        })
      }
      return arr
    }, [])
    const clientsServed = [
      ...filteredArray?.map(client => {
        if (profile?.clients?.includes(client.key)) {
          return { ...client, serve: true }
        }
        return { ...client, serve: false }
      })
    ]
    setClients(clientsServed)
  }, [])

  const handleChange = (key, value) => {
    const objIdx = clients?.findIndex(obj => obj.key === key)
    const data = [...clients]
    data[objIdx].serve = value
    setClients(data)
    const servedClients = [
      ...data
        .filter(client => client.serve)
        .map(client => {
          return client.key
        })
    ]
    props.handleChange(servedClients, 'clients')
  }

  function removeTrade(trade) {
    const newSet = new Set(tradesSelected)
    // filter chips selected
    if (newSet.has(trade)) {
      newSet.delete(trade)
    }
    setTradesSelected(newSet)
    // delete from data object
    const obj =
      tradesAndServices.length > 0
        ? tradesAndServices.filter(data => data.name !== trade)
        : []
    setTradesAndServices(obj)
  }

  useEffect(() => {
    props.handleChange(tradesAndServices, 'trades')
  }, [tradesAndServices])

  function handleServiceSelected(trade, selection, rates) {
    const obj =
      tradesAndServices.length > 0
        ? tradesAndServices.filter(data => data.name !== trade)
        : []
    obj.push({ name: trade, services: [...selection], rates: { ...rates } })
    setTradesAndServices(obj)
    props.handleChange(obj, 'trades')
  }

  function handleRatesChanged(trade, rates) {
    const obj = tradesAndServices.map(item => {
      if (item.name === trade) {
        return { ...item, rates: { ...rates } }
      }
      return item
    })
    setTradesAndServices(obj)
    props.handleChange(obj, 'trades')
  }

  return (
    <div>
      <div ref={topContainer} />
      <Container classes={{ root: classes.infoContainer }}>
        <Grid container className={classes.itemContainer}>
          <Grid item xs={12}>
            <Typography classes={{ root: classes.title }}>
              {t('company_profile.clients_trades')}
            </Typography>
            <Typography classes={{ root: classes.subtitle }}>
              {t('company_profile.message.company_profile')}
            </Typography>
          </Grid>
        </Grid>
        <Typography classes={{ root: classes.title_secondary }}>
          <span className={classes.required}>*</span>
          {t('company_profile.questions.serve')}
        </Typography>
        <Box
          display="flex"
          className={`${classes.itemContainer} ${classes.checkboxes}`}
          flexWrap="wrap"
        >
          {clients &&
            clients?.map((client, index) => {
              return (
                <GlobalCheckbox
                  key={index}
                  name={client.key}
                  label={client.label}
                  checked={client.serve}
                  handleChange={handleChange}
                />
              )
            })}
        </Box>
        <Typography classes={{ root: classes.title_secondary }}>
          <span className={classes.required}>*</span>
          {t('company_profile.labels.trades')}
        </Typography>
        <Box display="flex" className={classes.noTopPadding}>
          <GlobalChip
            skipTranslate
            chips={trades}
            selected={tradesSelected}
            setSelected={setTradesSelected}
            searchVisible={true}
            parent="trades"
            removeItem={removeTrade}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.itemContainer}
        >
          {Array.from(tradesSelected).map(trade => {
            const servicesSelected = tradesAndServices.filter(
              item => item.name === trade
            )
            const allServices = services.filter(sv => sv.name === trade)
            if (Array.isArray(allServices) && allServices.length > 0) {
              const firstService = allServices[0]
              if (firstService && firstService.services) {
                const subservices = Object.keys(firstService.services)
                return (
                  <ServiceCard
                    key={trade}
                    trade={trade}
                    removeCard={removeTrade}
                    subservices={subservices}
                    handleSelection={handleServiceSelected}
                    handleRates={handleRatesChanged}
                    itemsSelected={servicesSelected}
                    hideRates={props?.hideRates}
                    readonlyRates={props?.readonlyRates}
                    mandatoryRates={props?.mandatoryRates}
                    servicesCatalog={servicesCatalog}
                  />
                )
              } else {
                console.error("The 'services' property is missing or invalid.")
              }
            } else {
              console.error('No matching services found in catalog.')
            }
            return ''
          })}
        </Box>
      </Container>
    </div>
  )
}
