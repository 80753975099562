import React from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import { getCompany } from '../../services/ApiService'
import moment from 'moment'
import { generateTermsPDF } from '../../lib/Global'
import { useDispatch } from 'react-redux'
import { loadingActions } from '../../store/loading'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    paddingTop: '24px'
  },
  titleContainer: {
    padding: '24px 80px 0px 80px',
    columnCount: '2',
    [theme.breakpoints.down('md')]: {
      columnCount: '1',
      padding: '24px 0 0 0'
    }
  },
  itemContainer: {
    margin: '10px 80px 0px 80px',
    height: '55vh',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginTop: '15px'
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    color: theme.colors.black,
    lineHeight: '24px',
    letterSpacing: '0.05em'
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '17px',
    color: theme.colors.black,
    paddingTop: '9px',
    letterSpacing: '0em'
  },
  downloadButton: {
    background: theme.colors.grey_2,
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: '400',
    borderRadius: '36px',
    width: '160px',
    alignSelf: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      alignSelf: 'center',
      marginRight: 0
    },
    '&.MuiButton-contained': {
      fontSize: '12px',
      width: '81px'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'start'
  }
}))

export const TermsComponent = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { content, originatingCompany, data } = props
  const dispatch = useDispatch()

  async function generatePDF() {
    dispatch(loadingActions.show())
    const company = await getCompany(originatingCompany)
    const signDate = moment(new Date(data?.signed_on)).format(
      t('general.format.short_date')
    )
    const signTime = moment(new Date(data?.signed_on)).format(
      t('general.format.time_am_pm')
    )
    generateTermsPDF(
      company.name,
      content,
      data?.signed,
      data?.name,
      signDate,
      signTime,
      t
    )
    dispatch(loadingActions.hide())
  }

  return (
    <Container classes={{ root: classes.infoContainer }}>
      <Grid container className={classes.titleContainer}>
        <Grid item xs={10}>
          <Typography classes={{ root: classes.title }}>
            {t('company_profile.terms')}
          </Typography>
          <Typography classes={{ root: classes.subtitle }}>
            {t('company_profile.message.terms')}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.buttonContainer}>
          <Button
            variant="contained"
            className={classes.downloadButton}
            onClick={generatePDF}
          >
            {t('company_profile.export_pdf')}
          </Button>
        </Grid>
      </Grid>
      <Box
        display="flex"
        className={classes.itemContainer}
        id="content-to-export"
      >
        {parse(content)}
      </Box>
    </Container>
  )
}
