// main components
import React from 'react'
import { MainContainer } from '../components/MainContainer'
import { useTranslation } from 'react-i18next'
import { AccountInfoCard } from '../components/accountsettings/AccountInfoCard'

// mui components
import { Typography, Container, makeStyles, Grid } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    padding: '0px 0px',
    margin: '0px 0px',
    maxWidth: '100%',
    width: '100%',
    height: '100%'
  },
  title: {
    fontSize: '20px',
    color: theme.colors.text,
    padding: '24px 0px 32px 62px',
    fontWeight: '700',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      padding: '0'
    }
  },
  center: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      paddingLeft: '2em'
    }
  }
}))

const AccountSettings = () => {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <MainContainer backgroundColor="white">
      <Container classes={{ root: classes.container }}>
        <Typography classes={{ root: classes.title }}>
          {t('account_settings.title').toUpperCase()}
        </Typography>
        <Grid container className={classes.center}>
          <Grid item sm={12} md={8} lg={6}>
            <AccountInfoCard />
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  )
}

export default AccountSettings
