export const contactUs = () => {
  return {
    title: 'CONTACT US',
    subtitle_1: 'Please enter your details and we will get back to you',
    company_name: 'Company Name',
    message: 'Message',
    email_sent: 'Email Sent!',
    subtitle_2: 'Thanks for submitting your request.',
    subtitle_3:
      'An Automated Decision representative will contact you shortly regarding your request.'
  }
}
