import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

// MUI Components
import {
  makeStyles,
  Container,
  Button,
  Box,
  InputBase,
  Grid
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

// main components
import { InvoiceTimeline } from '../components/invoices/InvoiceTimeline'
import { InvoiceStatus } from '../components/invoices/InvoiceStatus'
import { InvoiceHeaderComponent } from '../components/invoices/InvoiceHeader'
import { InvoiceDetailComponent } from '../components/invoices/InvoiceDetail'
import LoadingBox from '../components/invoices/LoadingBox'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getInvoice, getWoList, submitInvoice } from '../services/ApiService'
import { ScheduledDetailComponent } from '../components/invoices/ScheduledDetail'
import { McssfrScheduledDetailComponent } from '../components/invoices/McssfrScheduledDetail'
import { AdvancePaymentDetailComponent } from '../components/invoices/AdvancePaymentDetail'
import { useSelector } from 'react-redux'
import {
  invoiceGreenStatus,
  invoiceRedStatus,
  mcssfr,
  mcs360
} from '../lib/Constants'
import { isRunningInIframe } from '../lib/Global'

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    margin: '0px',
    padding: '0px',
    maxWidth: '100%',
    overflowY: 'auto'
  },
  container: {
    backgroundColor: theme.colors.mainContainerBackground,
    paddingLeft: '0px',
    paddingRight: '0px',
    margin: '0px',
    maxWidth: '100%',
    minWidth: 'fit-content'
  },
  mainContainer: {
    display: 'flex',
    backgroundColor: 'white',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0'
    }
  },
  leftContainer: {
    paddingRight: 0,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0'
    }
  },
  rightContainer: {
    width: '360px',
    padding: '0'
  },
  globalHeaderContainer: {
    height: '72px',
    backgroundColor: 'white',
    maxWidth: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  backButton: {
    fontSize: '14px',
    fontWeight: '500',
    height: '58px',
    width: '105px',
    textTransform: 'none',
    marginLeft: '68px',
    boxShadow: '6px 9px 43px 0px ' + theme.colors.invoiceColors.shadow,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '12px'
    }
  },
  invoiceHeader: {
    marginTop: '20px',
    marginLeft: '20px',
    boxShadow: '6px 9px 43px 0px ' + theme.colors.invoiceColors.shadow,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '12px'
    }
  },
  invoiceDetail: {
    overflowY: 'scroll',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  notesContainer: {
    padding: '20px 0px 20px',
    marginBottom: '200px'
  },
  notesContainerHidden: {
    display: 'none'
  },
  miscNotes: {
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.colors.invoiceColors.header_grey,
    fontSize: 12,
    fontWeight: 400,
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(1)
    },
    padding: '10px 12px 16px 8px'
  },
  miscNotesMandatory: {
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.colors.errorText,
    fontSize: 12,
    fontWeight: 400,
    width: '100%',
    'label + &': {
      marginTop: theme.spacing(1)
    },
    padding: '10px 12px 16px 8px'
  },
  miscLabel: {
    width: '200px',
    fontSize: 14,
    fontWeight: 500
  },
  // styles for InvoiceStatusBoxes
  firstBox: {
    color: 'white',
    background: theme.colors.invoiceColors.dark_grey,
    height: '56px',
    width: '160px',
    borderRadius: '10px 0 0 10px',
    borderLeft: '0.5px solid ' + theme.colors.invoiceColors.disabled_grey,
    textAlign: 'center',
    padding: '18px 0'
  },
  middleBox: {
    color: theme.colors.invoiceColors.disabled_grey,
    height: '56px',
    width: '160px',
    border: '0.5px solid ' + theme.colors.invoiceColors.disabled_grey,
    borderRight: 'none',
    textAlign: 'center',
    padding: '18px 0'
  },
  middleCompleteBox: {
    color: 'white',
    background: theme.colors.invoiceColors.dark_grey,
    height: '56px',
    width: '160px',
    borderLeft: '0.5px solid ' + theme.colors.invoiceColors.disabled_grey,
    textAlign: 'center',
    padding: '18px 0'
  },
  lastBox: {
    color: theme.colors.invoiceColors.disabled_grey,
    height: '56px',
    width: '160px',
    border: '0.5px solid ' + theme.colors.invoiceColors.disabled_grey,
    borderRadius: '0 10px 10px 0',
    textAlign: 'center',
    padding: '18px 0'
  },
  lastCompleteBox: {
    color: theme.colors.invoiceColors.approved_green,
    height: '56px',
    width: '160px',
    borderRadius: '0 10px 10px 0',
    border: '0.5px solid ' + theme.colors.invoiceColors.approved_green,
    textAlign: 'center',
    padding: '18px 0'
  },
  declinedBox: {
    height: '56px',
    width: '160px',
    border: '1px solid ' + theme.colors.invoiceColors.red_error,
    borderRadius: '0 10px 10px 0',
    textAlign: 'center',
    padding: '18px 0',
    color: theme.colors.invoiceColors.red_error
  },
  attachIcon: {
    width: '2px',
    color: theme.palette.primary.light
  },
  notesLabel: {
    backgroundColor: theme.colors.invoiceColors.summary_bg,
    fontSize: '12px',
    height: '22px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '15px',
    color: theme.colors.invoiceColors.dark_grey,
    fontWeight: '500',
    borderBottom: '0px',
    marginBottom: '15px'
  },
  notesLabelMandatory: {
    backgroundColor: theme.colors.invoiceColors.summary_bg,
    fontSize: '12px',
    height: '22px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: '15px',
    color: theme.colors.errorText,
    fontWeight: '500',
    borderBottom: '0px',
    marginBottom: '15px'
  },
  attachChip: {
    fontSize: '12px',
    backgroundColor: theme.colors.backdropColor,
    border: `0.5px solid ${theme.palette.primary.light}`,
    color: theme.palette.primary.light,
    marginTop: '4px'
  }
}))

function getWindowHeight() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size[1]
}

const CreateInvoice = () => {
  const params = useParams()
  const { t } = useTranslation()
  const classes = useStyles()
  const [labor, setLabor] = useState()
  const [travel, setTravel] = useState()
  const [material, setMaterial] = useState()
  const [freight, setFreight] = useState()
  const [other, setOther] = useState()
  const [allItems, setAllItems] = useState()
  const [totalAmount, setTotalAmount] = useState(0)
  const [laborAmount, setLaborAmount] = useState(0)
  const [travelAmount, setTravelAmount] = useState(0)
  const [materialAmount, setMaterialAmount] = useState(0)
  const [selectedCheck, setSelectedCheck] = useState(false)
  const [freightAmount, setFreightAmount] = useState(0)
  const [otherAmount, setOtherAmount] = useState(0)
  const location = useLocation()
  const [data, setData] = useState()
  const [auditTrail, setAuditTrail] = useState()
  const history = useHistory()
  const wHeight = getWindowHeight()
  const [enableAutoSave, setEnableAutoSave] = useState(false)
  const [notes, setNotes] = useState('')
  const [invoiceStatus, setInvoiceStatus] = useState()
  const [loading, setLoading] = useState(false)
  const [statusItems, setStatusItems] = useState([
    {
      label: t('invoices.in_states.work_complete'),
      complete: false,
      style: { root: classes.firstBox }
    },
    {
      label: t('invoices.in_states.draft'),
      complete: false,
      style: { root: classes.middleBox }
    },
    {
      label: t('invoices.in_states.sent'),
      complete: false,
      style: { root: classes.middleBox }
    },
    {
      label: t('invoices.in_states.approved'),
      complete: false,
      style: { root: classes.lastBox }
    }
  ])
  const [scheduledInvoice, setScheduledInvoice] = useState(false)
  const [multipleWoNumbers, setMultipleWoNumbers] = useState([])
  const [projectInvoice, setProjectInvoice] = useState(false)
  const [totalProjectAmount, setTotalProjectAmount] = useState(0)
  const [projectAmountPaid, setProjectAmountPaid] = useState(0)
  const [balanceDue, setBalanceDue] = useState(0)
  const [amountPaid, setBalDueAmountPaid] = useState(0)
  const userStore = useSelector(state => state.auth.user)
  const loadingFiles = useSelector(state => state.invoices.loadingFiles)
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  const [attachments, setAttachments] = useState([])
  const [isNotesFilled, setIsNotesFilled] = useState(false)

  const notesLabelConfig = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.invoices?.labels?.notes
  )

  function calculateTotal(items) {
    let total = 0
    items.forEach(item => {
      const lineItemsTotal = item.subtotal_line_items
      const markdownAmount = lineItemsTotal * item.sub_markdown
      total += lineItemsTotal - markdownAmount
    })
    return total
  }
  useEffect(async () => {
    setLoading(true)
    const searchParams = location.search
    const invoiceId = new URLSearchParams(searchParams).get('id')
    try {
      const response = await getInvoice(invoiceId)
      if (response?.content?.status === 'deleted') {
        history.push({ pathname: 'invoices' })
      }
      const tempTotalProjectAmount = calculateTotal(
        response?.content?.line_items
      )
      if (response?.content?.attachments) {
        setAttachments(response?.content?.attachments)
      }
      const advancePaymentValue = response?.content?.work_order?.advance_payment
      setTotalProjectAmount(tempTotalProjectAmount)
      setProjectAmountPaid(advancePaymentValue)
      setBalanceDue(tempTotalProjectAmount - advancePaymentValue)
      setBalDueAmountPaid(advancePaymentValue)

      setScheduledInvoice(response?.content?.scheduled)
      setProjectInvoice(response?.content?.project)
      if (response?.content?.scheduled) {
        const woResponse = await getWoList(
          response?.content?.work_order_list?.ids
        )
        const woNumbers = []
        woResponse.forEach(wo =>
          woNumbers.push({ id: wo.id, customer_po: wo.customer_po })
        )
        setMultipleWoNumbers(woNumbers)
      }
      setTimeout(() => {
        setLoading(false)
        setData(response?.content)
        setAuditTrail(response?.content?.history)
        setInvoiceStatus(response?.content?.status)
      }, 1000)
    } catch (error) {
      console.error('An error ocurred when opening the invoice: ', error)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    setAuditTrail(data?.history)
    handleStateChange(data?.status)
    setInvoiceStatus(data?.status)

    const dataItems = data?.line_items
    // initialize line items
    setLaborItems(dataItems?.filter(item => item.item === 'Labor'))
    setTravelItems(dataItems?.filter(item => item.item === 'Travel'))
    setMaterialItems(dataItems?.filter(item => item.item === 'Material'))
    setFreightItems(dataItems?.filter(item => item.item === 'Freight'))
    setOtherItems(
      dataItems?.filter(
        item =>
          item.item !== 'Labor' &&
          item.item !== 'Travel' &&
          item.item !== 'Material' &&
          item.item !== 'Freight'
      )
    )
    setNotes(data?.notes)
  }, [data])

  useEffect(() => {
    let lineItems = []
    if (laborAmount > 0) {
      labor?.forEach(item => {
        lineItems = [
          ...lineItems,
          {
            id: item.id,
            item: item.item,
            description: item.description,
            trade: item.trade,
            rate: item.rate,
            // uncomment if needed
            // tax: item.tax,
            quantity: item.quantity,
            amount: item.amount
          }
        ]
      })
    }
    if (travelAmount > 0) {
      travel?.forEach(item => {
        lineItems = [
          ...lineItems,
          {
            id: item.id,
            item: item.item,
            description: item.description,
            trade: item.trade,
            rate: item.rate,
            // uncomment if needed
            // tax: item.tax,
            quantity: item.quantity,
            amount: item.amount
          }
        ]
      })
    }
    if (materialAmount > 0) {
      material?.forEach(item => {
        lineItems = [
          ...lineItems,
          {
            id: item.id,
            item: item.item,
            description: item.description,
            trade: item.trade,
            rate: item.rate,
            // uncomment if needed
            // tax: item.tax,
            quantity: item.quantity,
            amount: item.amount
          }
        ]
      })
    }
    if (freightAmount > 0) {
      freight?.forEach(item => {
        lineItems = [
          ...lineItems,
          {
            id: item.id,
            item: item.item,
            description: item.description,
            trade: item.trade,
            rate: item.rate,
            // uncomment if needed
            // tax: item.tax,
            quantity: item.quantity,
            amount: item.amount
          }
        ]
      })
    }
    if (otherAmount !== 0) {
      other?.forEach(item => {
        lineItems = [
          ...lineItems,
          {
            id: item.id,
            item: item.item,
            description: item.description,
            trade: item.trade,
            rate: item.rate,
            // uncomment if needed
            // tax: item.tax,
            quantity: item.quantity,
            amount: item.amount
          }
        ]
      })
    }
    data?.project ? setAllItems(other) : setAllItems(lineItems)
    if (data?.line_items) {
      setBalanceDue(calculateTotal(data?.line_items) - amountPaid)
      setTotalProjectAmount(calculateTotal(data?.line_items))
    }
  }, [labor, travel, material, freight, other, totalAmount])

  useEffect(() => {
    setTotalAmount(
      laborAmount + travelAmount + materialAmount + freightAmount + otherAmount
    )
  }, [laborAmount, travelAmount, materialAmount, freightAmount, otherAmount])

  function handleStateChange(currentStatus) {
    const items = [...statusItems]
    items[0].style = { root: classes.firstBox }
    if (scheduledInvoice) {
      items[1].complete = true
      items[1].style = { root: classes.middleCompleteBox }
      items[2].complete = true
      items[2].style = { root: classes.middleCompleteBox }
      items[3].label = t('invoices.in_states.approved')
      items[3].complete = true
      items[3].style = { root: classes.lastCompleteBox }
      setStatusItems(items)
      return
    }
    if (currentStatus === t('invoices.in_states.open').toLowerCase()) {
      items[1].complete = false
      items[1].style = { root: classes.middleBox }
      items[2].complete = false
      items[2].style = { root: classes.middleBox }
      items[3].label = t('invoices.in_states.approved')
      items[3].complete = false
      items[3].style = { root: classes.lastBox }
      setStatusItems(items)
      return
    }
    if (currentStatus === t('invoices.in_states.draft').toLowerCase()) {
      items[1].complete = true
      items[1].style = { root: classes.middleCompleteBox }
      items[2].complete = false
      items[2].style = { root: classes.middleBox }
      items[3].label = t('invoices.in_states.approved')
      items[3].complete = false
      items[3].style = { root: classes.lastBox }
      setStatusItems(items)
      return
    }
    if (currentStatus === t('invoices.in_states.submitted').toLowerCase()) {
      items[1].complete = true
      items[1].style = { root: classes.middleCompleteBox }
      items[2].complete = true
      items[2].style = { root: classes.middleCompleteBox }
      items[3].label = t('invoices.in_states.approved')
      items[3].complete = false
      items[3].style = { root: classes.lastBox }
      setStatusItems(items)
      return
    }
    if (invoiceGreenStatus.includes(currentStatus)) {
      items[1].complete = true
      items[1].style = { root: classes.middleCompleteBox }
      items[2].complete = true
      items[2].style = { root: classes.middleCompleteBox }
      items[3].label = t('invoices.in_states.' + currentStatus)
      items[3].complete = true
      items[3].style = { root: classes.lastCompleteBox }
      setStatusItems(items)
      return
    }
    if (invoiceRedStatus.includes(currentStatus)) {
      items[1].complete = true
      items[1].style = { root: classes.middleCompleteBox }
      items[2].complete = true
      items[2].style = { root: classes.middleCompleteBox }
      items[3].label = t('invoices.in_states.' + currentStatus)
      items[3].complete = false
      items[3].style = { root: classes.declinedBox }
      setStatusItems(items)
    }
  }
  const [disableButtons, setDisableButtons] = useState(false)
  // Saving draft
  const saveItems = async () => {
    const lineItemsTmp = [
      ...labor,
      ...travel,
      ...material,
      ...freight,
      ...other
    ].filter(item => item.amount !== 0)
    let total = 0
    lineItemsTmp?.forEach(item => {
      total += item.amount
    })
    const requestTmp = {
      line_items: lineItemsTmp,
      total: total,
      status: t('invoices.in_states.draft').toLowerCase(),
      notes: notes
    }
    // call update function
    try {
      const response = await submitInvoice(data.id, { ...requestTmp }, false)
      setDisableButtons(false)
      setAuditTrail([...response.content.history])
      setEnableAutoSave(false)
      setDisableSubmitButton(false)
      return response.content
    } catch (error) {
      setDisableSubmitButton(false)
      console.error('An error ocurred when saving the draft: ', error)
    }
  }

  // callback functions for InvoiceDetail components
  const setLaborItems = items => {
    setLabor(items)
    let subtotal = 0
    items?.forEach(item => {
      subtotal += item.amount
    })
    setLaborAmount(subtotal)
  }
  const setTravelItems = items => {
    setTravel(items)
    let subtotal = 0
    items?.map(item => (subtotal += item.amount))
    setTravelAmount(subtotal)
  }
  const setMaterialItems = items => {
    setMaterial(items)
    let subtotal = 0
    items?.map(item => (subtotal += item.amount))
    setMaterialAmount(subtotal)
  }
  const setFreightItems = items => {
    setFreight(items)
    let subtotal = 0
    items?.map(item => (subtotal += item.amount))
    setFreightAmount(subtotal)
  }
  const setOtherItems = items => {
    setOther(items)
    let subtotal = 0
    items?.map(item => (subtotal += item.amount))
    setOtherAmount(subtotal)
  }

  // save draft on edit
  useEffect(() => {
    if (
      data?.status !== t('invoices.in_states.approved').toLowerCase() &&
      data?.status !== t('invoices.in_states.submitted').toLowerCase() &&
      data?.status !== t('invoices.in_states.paid').toLowerCase() &&
      data?.status !== t('invoices.in_states.hold').toLowerCase() &&
      data?.status !== t('invoices.in_states.void').toLowerCase()
    ) {
      if (enableAutoSave && !scheduledInvoice) {
        setDisableSubmitButton(true)
        Promise.all([saveItems()]).then(values => {
          handleStateChange(values[0]?.status)
          setInvoiceStatus(values[0]?.status)
        })
      }
    }
  }, [enableAutoSave])

  useEffect(() => {
    if (data?.notes?.trim() !== '') {
      setIsNotesFilled(true)
    } else {
      setIsNotesFilled(false)
    }
  }, [data?.notes])

  const handleChange = event => {
    event.preventDefault()
    setNotes(event.target.value)
    data.notes = event.target.value
    setData(data)
    if (event.target.value.trim() !== '') {
      setIsNotesFilled(true)
    } else {
      setIsNotesFilled(false)
    }
  }

  const handleSave = event => {
    event.preventDefault()
    setEnableAutoSave(true)
    if (!scheduledInvoice) {
      setNotes(event.target.value)
      setDisableSubmitButton(true)
      saveItems()
    }
  }

  const isValidItemSelected = () => {
    const arrayItems = [
      ...(labor || []),
      ...(travel || []),
      ...(material || []),
      ...(freight || []),
      ...(other || [])
    ]
    return arrayItems.every(({ item, quantity, amount, rate }) => {
      if (!quantity && !rate) return true
      if (item === '0' || amount === 0 || !amount) {
        return false
      }
      return item
    })
  }

  const validSelected = isValidItemSelected()

  useEffect(() => {
    setSelectedCheck(validSelected)
  }, [validSelected])

  const mandatoryNotesConfig = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.invoices?.mandatory_notes
  )

  return (
    <Container className={classes.scrollContainer}>
      <Container className={classes.container}>
        <Grid container spacing={2} className={classes.globalHeaderContainer}>
          <Grid item xs={2}>
            <Button
              disabled={loadingFiles}
              className={classes.backButton}
              variant="text"
              startIcon={<FontAwesomeIcon icon="fa-solid fa-angle-left" />}
              onClick={event => {
                if (loadingFiles) {
                  return
                }
                if (
                  data?.status !==
                    t('invoices.in_states.approved').toLowerCase() &&
                  data?.status !==
                    t('invoices.in_states.submitted').toLowerCase() &&
                  data?.status !== t('invoices.in_states.paid').toLowerCase() &&
                  data?.status !== t('invoices.in_states.hold').toLowerCase() &&
                  data?.status !== t('invoices.in_states.void').toLowerCase()
                ) {
                  setEnableAutoSave(true)
                }
                if (history.goBack.length === 0) {
                  if (isRunningInIframe()) {
                    history.push(
                      '/invoices-iframe/' +
                        params.id +
                        '/' +
                        params.access_token
                    )
                  } else {
                    history.push({ pathname: 'invoices' })
                  }
                } else {
                  history.goBack()
                }
              }}
            >
              {t('general.labels.back')}
            </Button>
          </Grid>
          <Grid item xs={8}>
            {loading ? (
              <LoadingBox height="50px" width="70%" margin="0px auto" />
            ) : (
              <InvoiceStatus currentStatus={statusItems} />
            )}
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Container className={classes.mainContainer}>
          <Container className={classes.leftContainer}>
            <Box className={classes.invoiceHeader} elevation={0}>
              <InvoiceHeaderComponent
                data={data}
                totalAmount={totalAmount}
                allItems={allItems}
                setData={setData}
                autoSave={setEnableAutoSave}
                currentStatus={invoiceStatus}
                loading={loading}
                scheduled={scheduledInvoice}
                multipleWoNumbers={multipleWoNumbers}
                isValidItemSelected={validSelected}
                selectedCheck={selectedCheck}
                balanceDue={balanceDue}
                project={projectInvoice}
                disableSubmitButton={disableSubmitButton}
                lienMandatory={
                  userStore?.userInfo?.configurations?.portals?.subcontractor
                    ?.invoices?.lien_mandatory
                }
                notes={notes}
                attachments={attachments}
                setAttachments={setAttachments}
                setDisableButtons={setDisableButtons}
                setNotes={setNotes}
              />
              <Box
                style={{ height: wHeight - 412 + 'px' }}
                className={classes.invoiceDetail}
              >
                {data?.scheduled ? (
                  <ScheduledDetailComponent
                    itemTag={t('invoices.items.scheduled')}
                    items={data?.line_items}
                  />
                ) : data?.project &&
                  data?.work_order.advance_payment_percentage > 0 ? (
                  <div>
                    <AdvancePaymentDetailComponent
                      itemTag={t('invoices.items.advance_payment')}
                      advancePaymentPercentage={
                        data?.work_order.advance_payment_percentage
                      }
                      woDateCreated={moment(
                        new Date(data?.date_created * 1000)
                      ).format(t('general.format.short_date'))}
                      projectAmountPaid={projectAmountPaid}
                      totalProjectAmount={totalProjectAmount}
                      status={data?.status}
                    />
                    <McssfrScheduledDetailComponent
                      itemTag={t('invoices.items.scheduled')}
                      items={data?.line_items}
                      setData={setData}
                      status={data?.status}
                    />
                  </div>
                ) : data?.project ? (
                  <McssfrScheduledDetailComponent
                    itemTag={t('invoices.items.scheduled')}
                    items={data?.line_items}
                    setData={setData}
                    status={data?.status}
                  />
                ) : (
                  <>
                    <InvoiceDetailComponent
                      itemTag={t('invoices.items.labor')}
                      tradeTag={data?.work_order?.category}
                      addItemTag={t('invoices.in_actions.add_labor')}
                      updateItems={setLaborItems}
                      items={labor}
                      status={data?.status}
                      enableAutoSave={enableAutoSave}
                      setEnableAutoSave={setEnableAutoSave}
                      loading={loading}
                      scheduled={scheduledInvoice}
                      disableButtons={disableButtons}
                      setDisableButtons={setDisableButtons}
                      setDisableSubmitButton={setDisableSubmitButton}
                    />
                    <InvoiceDetailComponent
                      itemTag={t('invoices.items.travel')}
                      tradeTag={data?.work_order?.category}
                      addItemTag={t('invoices.in_actions.add_travel')}
                      updateItems={setTravelItems}
                      items={travel}
                      status={data?.status}
                      enableAutoSave={enableAutoSave}
                      setEnableAutoSave={setEnableAutoSave}
                      loading={loading}
                      scheduled={scheduledInvoice}
                      disableButtons={disableButtons}
                      setDisableButtons={setDisableButtons}
                      setDisableSubmitButton={setDisableSubmitButton}
                    />
                    <InvoiceDetailComponent
                      itemTag={t('invoices.items.material')}
                      tradeTag={data?.work_order?.category}
                      addItemTag={t('invoices.in_actions.add_material')}
                      updateItems={setMaterialItems}
                      items={material}
                      status={data?.status}
                      enableAutoSave={enableAutoSave}
                      setEnableAutoSave={setEnableAutoSave}
                      loading={loading}
                      scheduled={scheduledInvoice}
                      disableButtons={disableButtons}
                      setDisableButtons={setDisableButtons}
                      setDisableSubmitButton={setDisableSubmitButton}
                    />
                    <InvoiceDetailComponent
                      itemTag={t('invoices.items.freight')}
                      tradeTag={data?.work_order?.category}
                      addItemTag={t('invoices.in_actions.add_freight')}
                      updateItems={setFreightItems}
                      items={freight}
                      status={data?.status}
                      enableAutoSave={enableAutoSave}
                      setEnableAutoSave={setEnableAutoSave}
                      loading={loading}
                      scheduled={scheduledInvoice}
                      disableButtons={disableButtons}
                      setDisableButtons={setDisableButtons}
                      setDisableSubmitButton={setDisableSubmitButton}
                    />
                    <InvoiceDetailComponent
                      itemTag={t('invoices.items.other')}
                      tradeTag={data?.work_order?.category}
                      addItemTag={t('invoices.in_actions.add_other')}
                      updateItems={setOtherItems}
                      items={other}
                      status={data?.status}
                      enableAutoSave={enableAutoSave}
                      setEnableAutoSave={setEnableAutoSave}
                      loading={loading}
                      scheduled={scheduledInvoice}
                      disableButtons={disableButtons}
                      setDisableButtons={setDisableButtons}
                      setDisableSubmitButton={setDisableSubmitButton}
                    />
                  </>
                )}
                <Box
                  className={
                    (userStore.userInfo.parent_subdomain === mcssfr ||
                      userStore.userInfo.parent_subdomain === mcs360) &&
                    data?.project
                      ? classes.notesContainerHidden
                      : classes.notesContainer
                  }
                >
                  <Box
                    colSpan={8}
                    classes={{
                      root:
                        (mandatoryNotesConfig && !data?.notes) || !isNotesFilled
                          ? classes.notesLabelMandatory
                          : classes.notesLabel
                    }}
                  >
                    {loading
                      ? ''
                      : (mandatoryNotesConfig ? '* ' : '') +
                        (notesLabelConfig || t('invoices.notes'))}
                  </Box>
                  {loading ? (
                    <LoadingBox width="50%" height="50px" />
                  ) : (
                    <InputBase
                      id="misc-input"
                      placeholder={t('invoices.notes_placeholder')}
                      multiline
                      rows={4}
                      classes={{
                        root:
                          mandatoryNotesConfig && !data?.notes
                            ? classes.miscNotesMandatory
                            : classes.miscNotes
                      }}
                      value={notes}
                      onChange={event => handleChange(event)}
                      onBlur={event => handleSave(event)}
                      disabled={
                        data?.status ===
                          t('invoices.in_states.approved').toLowerCase() ||
                        data?.status ===
                          t('invoices.in_states.submitted').toLowerCase() ||
                        data?.status ===
                          t('invoices.in_states.paid').toLowerCase() ||
                        data?.status ===
                          t('invoices.in_states.hold').toLowerCase() ||
                        data?.status ===
                          t('invoices.in_states.void').toLowerCase() ||
                        scheduledInvoice
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Container>
          <Container className={classes.rightContainer}>
            <InvoiceTimeline
              steps={auditTrail}
              wHeight={wHeight - 150 + 'px'}
              loading={loading}
            />
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
export default CreateInvoice
