export const woStatusOptions = [
  'open',
  'in_progress',
  'completed',
  'cancelled',
  'expired'
]
export const woStatus = {
  OPEN: 'open',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired'
}
export const woFixedStatus = ['completed', 'cancelled']
export const inStatusOptions = [
  'open',
  'declined',
  'submitted',
  'approved',
  'draft',
  'hold',
  'paid',
  'voided',
  'not_available'
]
export const inStatusSfrOptions = [
  'open',
  'draft',
  'submitted',
  'declined',
  'approved'
]
export const navBarHeaderHeight = '60px'
export const NO_RECORD_ITEM = 'no service required'
export const invoiceApproved = 'approved'
export const invoiceSubmitted = 'submitted'
export const invoiceNotSubmitted = ['open', 'declined', 'draft']
export const legalEntities = [
  {
    label: 'Limited Liability Company (LLC)',
    value: 'LLC'
  },
  {
    label: 'Limited Liability Company (LLC) - C-Corporation',
    value: 'LLC-C'
  },
  {
    label: 'Limited Liability Company (LLC) - S-Corporation',
    value: 'LLC-S'
  },
  {
    label: 'Limited Liability Company (LLC) - P-Partnership',
    value: 'LLC-P'
  },
  {
    label: 'C-Corporation',
    value: 'C-Corporation'
  },
  {
    label: 'S-Corporation',
    value: 'S-Corporation'
  },
  {
    label: 'Partnership (LLP)',
    value: 'LLP'
  },
  {
    label: 'Sole Proprietorship',
    value: 'Sole Proprietorship'
  }
]

export const workCompleteOptions = [
  {
    label: 'Work Complete',
    value: 'Work Complete'
  },
  {
    label: 'Return Visit',
    value: 'Return Visit'
  }
]

export const phoneRegex = /^((\([0-9]{3}\)[ -]?))*?[0-9]{3}[ -]?[0-9]{4}$/
export const mobileBreakpoint = 1150
export const maxFileSize = 10.0
export const minResolution = 1024
export const minRatio = 1.0 // 1:1
export const maxRatio = 1.78 // 16:9
export const companyProfileFiles = [
  'w9',
  'coi',
  'comp',
  'bank',
  'contractor_license',
  'direct_deposit_form'
]
export const adminRole = 'Super Admin'
export const alphaNumericRegex = /^[a-zA-Z0-9]*$/
export const alphaNumericDashRegex = /^[a-zA-Z0-9-]*$/
export const numericDashRegex = /^[0-9-]*$/
export const numericRegex = /^[0-9]*$/

export const apiPasswordMessage =
  'password must NOT have fewer than 6 characters.'

export const invoiceColumns = [
  'client_name',
  'client_location',
  'invoice',
  'won',
  'amount',
  'createDate',
  'dueDate',
  'inStatus'
]
export const woColumns = [
  'client_name',
  'site_name',
  'trade',
  'service',
  'won',
  'opendate',
  'duedate',
  'wostat',
  'invoices'
]
export const csvFileVersion = 'v03'

export const woLogStatus = {
  complete: 'complete',
  incomplete: 'incomplete'
}

export const woLogType = {
  CHECKOUT: 'checkOut',
  CHECKIN: 'checkIn',
  CANCELLED: 'cancelled',
  MESSAGE: 'message'
}

export const apiErrors = {
  EMAIL_EXISTS: 'User with this email already exists.',
  ROLES_STRING: 'Role must be defined',
  EMAIL_EXISTS_OTHER_PARENT:
    'User with this email exists in another reporting company.',
  USER_DELETED: 'User with this email has a deleted status.'
}
export const apiMessages = {
  EMAIL_SENT: 'Email sent successfully'
}
export const portalIdentifier = 'SUBCONTRACTOR'

export const mcssfr = 'mcssfr'
export const mcs360 = 'mcs360'

export const connectAdLogo =
  'https://ftcuploads.s3.amazonaws.com/logos/Connect+AD.png'

export const squareADLogo =
  'https://ftcuploads.s3.amazonaws.com/logos/AD+square+logo.png'

export const connectSubcontractorLogo =
  'https://ftcuploads.s3.amazonaws.com/logos/CONNECTAD+SUBCONTRACTOR.png'

export const inviteParams = {
  EXISTING_USER: 'invite',
  SUBCONTRACTOR: 'subcontractor',
  TECHNICIAN: 'technician'
}

export const rolePermissionsArr = [
  'workorders',
  'invoices',
  'users',
  'company_settings'
]

export const workCompleteMCSOptions = [
  {
    label: 'Work Complete',
    value: 'Work Complete'
  },
  {
    label: 'Return Visit',
    value: 'Return Visit Required'
  },
  {
    label: 'Needs Bid',
    value: 'Needs Bid'
  },
  {
    label: 'Needs Re-Scheduled',
    value: 'Needs Re-Scheduled'
  }
]

export const checkItemType = ['yes_check', 'toggle_check', 'single_check']

export const invoiceGreenStatus = ['approved', 'paid']
export const invoiceRedStatus = ['declined', 'hold']

export const invoiceDefaultColumns = [
  { field: 'client_name', visible: true },
  { field: 'client_location', visible: true },
  { field: 'invoice', visible: true },
  { field: 'won', visible: true },
  { field: 'amount', visible: true },
  { field: 'createDate', visible: true },
  { field: 'dueDate', visible: true },
  { field: 'inStatus', visible: true }
]

export const workorderDefaultColumns = [
  { field: 'client_name', visible: true },
  { field: 'site_name', visible: true },
  { field: 'trade', visible: true },
  { field: 'service', visible: true },
  { field: 'won', visible: true },
  { field: 'opendate', visible: true },
  { field: 'duedate', visible: true },
  { field: 'wostat', visible: true },
  { field: 'invoices', visible: true }
]

export const workorderCompletionType = {
  COMPLETED: 'Completed',
  CONFIRMATION: 'Confirmation',
  RETURN_VISIT: 'Return Visit',
  RETURN_VISIT_2: 'Return Visit2',
  RETURN_VISIT_CONFIRMATION: 'Return Visit Confirmation',
  COMPLETE_FORM: 'Complete Form'
}

export const Configs = {
  webSocketServerUrl: process.env.REACT_APP_WEB_SOCKET_SERVER_URL || '',
  webSocketServerUser: process.env.REACT_APP_WEB_SOCKET_SERVER_USER || '',
  webSocketServerPassword:
    process.env.REACT_APP_WEB_SOCKET_SERVER_PASSWORD || ''
}

export const defaultConfigRates = [
  {
    catalog_key: 'standard_rate',
    rate: 'hourly'
  },
  {
    catalog_key: 'emergency_rate',
    rate: 'hourly'
  },
  {
    catalog_key: 'travel_cost',
    rate: 'rate'
  }
]
export const docTypes = {
  pdf: {
    key: 'pdf',
    label: 'PDF'
  },
  doc: {
    key: 'doc',
    label: 'DOCX'
  },
  xls: {
    key: 'xls',
    label: 'EXCEL'
  },
  csv: {
    key: 'csv',
    label: 'CSV'
  }
}

export const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg']

export const mcsEditableInvoiceStatus = ['declined', 'draft']

export const sseClientKeys = {
  automateddecision: process.env.REACT_APP_SSE_KEY_AD,
  mcs360: process.env.REACT_APP_SSE_KEY_MCS,
  mcssfr: process.env.REACT_APP_SSE_KEY_MCSSFR,
  donovan: process.env.REACT_APP_SSE_KEY_DONOVAN,
  metrokleen: process.env.REACT_APP_SSE_KEY_METROKLEEN,
  serviceplusfm: process.env.REACT_APP_SSE_KEY_SERVICEPLUSFM,
  onsitefm: process.env.REACT_APP_SSE_KEY_ONSITE,
  johnstonenterprise: process.env.REACT_APP_SSE_KEY_JOHNSTON,
  wilcox: process.env.REACT_APP_SSE_KEY_WILCOX,
  envirousa: process.env.REACT_APP_SSE_KEY_ENVIROUSA,
  fmg: process.env.REACT_APP_SSE_KEY_FMG
}

export const paymentOptions = {
  CHECK: 'check',
  DIRECT: 'direct'
}

export const clientAuditIntegration = {
  internal: 'internal',
  salesforce: 'salesforce'
}

export const ATTACHMENT_MAX_FILE_SIZE = 5 * 1024 * 1024
export const S3_BASE_URL = process.env.REACT_APP_FTC_IMAGE_BASE_URL

export const refrigerantService = [
  {
    label: 'Charged',
    value: 'Charged'
  },
  {
    label: 'Recovered',
    value: 'Recovered'
  }
]

export const refrigerantWeight = {
  lbs: 'lbs',
  oz: 'oz'
}
