import React from 'react'

// mui components
import { makeStyles, CircularProgress } from '@material-ui/core'
import { Map, GoogleApiWrapper, Polygon } from 'google-maps-react'
import customTheme from '../styles/mui_theme'

const useStyles = makeStyles(theme => ({
  container: props => ({
    width: props.width ?? 600,
    height: props.height ?? 600,
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  })
}))

const style = {
  borderRadius: 16
}

const containerStyle = {
  position: 'relative'
}

const LoadingContainer = props => <CircularProgress color="inherit" />

// Return map bounds based on list of places
const getMapBounds = (map, maps, places) => {
  const bounds = new maps.LatLngBounds()

  places.forEach(place => {
    bounds.extend(new maps.LatLng(place.lat, place.lng))
  })
  return bounds
}

const apiIsLoaded = (map, maps, places) => {
  // Get bounds by our places
  const bounds = getMapBounds(map, maps, places)
  // Fit map to bounds
  map.fitBounds(bounds)
  // Bind the resize listener
  bindResizeListener(map, maps, bounds)
}

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
  maps.event.addDomListenerOnce(map, 'idle', () => {
    maps.event.addDomListener(window, 'resize', () => {
      map.fitBounds(bounds)
    })
  })
}

export function Maps(props) {
  const classes = useStyles({ ...props })
  const { markers, initialCenter, paths, zoom } = props
  const defaultZoom = 4

  return (
    <div className={classes.container}>
      <Map
        center={initialCenter ?? { lat: 41.530412, lng: -100.15697 }}
        containerStyle={containerStyle}
        style={style}
        google={props.google}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, markers)}
        zoom={paths.length === 0 ? defaultZoom : zoom}
        fullscreenControl={true}
        gestureHandling="greedy"
        streetViewControl={false}
      >
        {typeof paths !== 'undefined' &&
          paths?.length &&
          paths[0]?.map((path, index) => {
            try {
              const coordArray = []
              // build coordinates array for polygon
              path?.polygon_data?.length > 1 &&
                path?.polygon_data?.map(coord =>
                  coordArray.push({ lat: coord[1], lng: coord[0] })
                )
              return (
                <Polygon
                  key={path.zip + '-' + index}
                  paths={coordArray}
                  fillColor={customTheme.colors.profile.mapFill}
                  fillOpacity={0.5}
                  strokeColor={customTheme.colors.profile.mapStroke}
                  strokeOpacity={0.5}
                  strokeWeight={2}
                />
              )
            } catch (error) {
              console.log('Error on render: ', error)
              return ''
            }
          })}
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
  LoadingContainer: LoadingContainer
})(Maps)
