import { create } from 'apisauce'
import { store } from '../store'
import { refreshToken } from './AuthService'
import { authActions } from '../store/signIn'
import { isRunningInIframe } from '../lib/Global'

/**
 * Create an api of location API
 *
 * @type {ApisauceInstance}
 */
const locationApi = create({
  baseURL: 'https://countriesnow.space/api/v0.1/countries',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

/**
 * Create an api of google maps API
 *
 * @type {ApisauceInstance}
 */
const googleApi = create({
  baseURL: 'https://maps.googleapis.com/maps/api/geocode',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

/**
 * Create an api of zip code API
 *
 * @type {ApisauceInstance}
 */
const zipApi = create({
  baseURL: `${process.env.REACT_APP_FTC_API_SERVER_URL}/zip_code/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

export const callLocationApi = async (type, route, params = {}) => {
  let response
  switch (type) {
    case 'POST':
      response = await locationApi.post(route, params)
      break
    case 'GET':
      response = await locationApi.get(route, params)
      break
    default:
      throw {
        name: 'Method Not Allowed',
        message: 'Call type not supported',
        code: 405
      }
  }
  if (!response.ok) return {}
  return response.data
}

export const callGoogleApi = async (type, route, params = {}) => {
  let response
  // route = 'address=string'
  const newRoute = `/json?${route}&key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}`
  switch (type) {
    case 'POST':
      response = await googleApi.post(newRoute, params)
      break
    case 'GET':
      response = await googleApi.get(newRoute, params)
      break
    default:
      throw {
        name: 'Method Not Allowed',
        message: 'Call type not supported',
        code: 405
      }
  }
  return response
}

export const callZipApi = async (type, route, params = {}, attempt = 1) => {
  const authStore = store.getState().auth
  if (!isRunningInIframe()) {
    zipApi.setHeader('Authorization', `Bearer ${authStore.token.access_token}`)
  }

  let response
  switch (type) {
    case 'GET':
      response = await zipApi.get(route, params)
      break
    case 'POST':
      response = await zipApi.post(route, params)
      break
    default:
      throw {
        name: 'Method Not Allowed',
        message: 'Call type not supported',
        code: 405
      }
  }
  if (!response.ok) {
    if (response.status === 401) {
      if (attempt === 1) {
        try {
          await refreshToken(
            authStore.token.refresh_token,
            authStore.user.userId
          )
          return callZipApi(type, route, params, 2)
        } catch (error) {
          return callZipApi(type, route, params, 2)
        }
      } else {
        console.log('Unable to refresh token')
        store.dispatch(authActions.logout())
        store.dispatch(authActions.setForceLogout())
        return {}
      }
    }
  }
  return response.data
}

export const parseStateList = (list = []) => {
  const arrayResponse = []
  if (list && list?.length) {
    for (const item of list) {
      if (!item.state_code.includes('-')) {
        arrayResponse.push({
          value: item.name,
          label: item.name,
          code: item.state_code
        })
      }
    }
  }
  return arrayResponse
}

export const parseListResponse = (list = [], key = 'name') => {
  const arrayResponse = []
  if (list && list?.length) {
    for (const item of list) {
      if (key) {
        arrayResponse.push({ value: item[key], label: item[key] })
      } else {
        arrayResponse.push({ value: item, label: item })
      }
    }
  }
  return arrayResponse
}

export const parseCitiesResponse = (list = []) => {
  const arrayResponse = []
  if (list && list?.length) {
    for (const item of list) {
      if (!item.includes('County')) {
        arrayResponse.push({ value: item, label: item })
      }
    }
  }
  return arrayResponse
}

export const parseZipList = (list = []) => {
  const arrayResponse = []
  if (list && list?.length) {
    for (const item of list) {
      const { _source } = item
      // eslint-disable-next-line camelcase
      const { county, coordinate, primary_city, state, zip, id } = _source
      arrayResponse.push({
        county,
        lat: coordinate.lat,
        lon: coordinate.lon,
        city: primary_city,
        state,
        zip,
        selected: true,
        id
      })
    }
    return arrayResponse
  }
}

export const parseCitiesList = (list = []) => {
  const arrayResponse = []
  if (list.length) {
    for (const item of list) {
      const { _source } = item
      // eslint-disable-next-line camelcase
      const { primary_city } = _source
      const index = arrayResponse.findIndex(
        // eslint-disable-next-line camelcase
        object => object.value === primary_city
      )

      if (index === -1) {
        arrayResponse.push({
          value: primary_city,
          label: primary_city
        })
      }
    }
    return arrayResponse
  }
}
export const parsePolygonList = (list = []) => {
  const arrayResponse = []
  if (list?.length > 0) {
    for (const item of list) {
      try {
        const { _source } = item
        // eslint-disable-next-line camelcase
        const { polygon_data, zip, polygon_type } = _source
        const index = arrayResponse.findIndex(
          // eslint-disable-next-line camelcase
          object => object.zip === zip
        )
        if (index === -1) {
          // check array in polygon data
          // eslint-disable-next-line camelcase
          polygon_type === 'multi' // do somenthing
            ? polygon_data.map(polygon =>
                arrayResponse.push({
                  zip: zip,
                  polygon_data: polygon[0]
                })
              )
            : arrayResponse.push({
                zip: zip,
                // eslint-disable-next-line camelcase
                polygon_data: polygon_data
              })
        }
      } catch (error) {
        console.error('Error loading polygons item ', item, error)
      }
    }
    return arrayResponse
  }
}
export const setIframeZipAccessToken = accessToken => {
  zipApi.setHeader('Authorization', `Bearer ${accessToken}`)
}
