export const requestAccess = () => {
  return {
    title: 'CREATE ACCOUNT',
    subtitle_1: 'Please complete all the fields below',
    company_domain: 'Company domain',
    next: 'Next',
    enter_access_code: 'Enter registration code',
    have_account: 'Already have an account?',
    email_us: 'Email Us',
    first_name: 'First Name',
    last_name: 'Last Name',
    email_address: 'Email Address',
    create_account: 'Create Account',
    finish_account: 'Finish creating your account',
    create_message: 'Enter your registration code to create your account.',
    username: 'Username',
    user_title: 'Select Title',
    user_roles: {
      accounting: 'Accounting/AR',
      account_manager: 'Account Manager',
      administrator: 'Administrator',
      crew_member: 'Crew Member',
      crew_leader: 'Crew Leader',
      dispatcher: 'Dispatcher',
      field_supervisor: 'Field Supervisor',
      installer: 'Installer',
      manager: 'Manager',
      operations_manager: 'Operations Manager',
      owner: 'Owner',
      president: 'President',
      technician: 'Technician'
    },
    admin_roles: {
      accounting: 'Accounting/AR',
      account_manager: 'Account Manager',
      administrator: 'Administrator',
      dispatcher: 'Dispatcher',
      operations_manager: 'Operations Manager',
      owner: 'Owner',
      president: 'President'
    },
    mcs_roles: {
      super_admin: 'Super Admin',
      portal: 'Portal User',
      mobile: 'Mobile Only'
    },
    messages: {
      errors: {
        invalid_domain_structure: 'Invalid company code, please try again.',
        mandatory_field: 'Mandatory Field',
        invalid_email: 'Invalid Email'
      }
    }
  }
}
