export const companySettings = () => {
  return {
    title: 'CONFIGURACIÓN Y PERFIL DE LA EMPRESA',
    compliant: 'COMPLETO',
    mobile_only: 'Solo Mobil',
    missing: 'Faltante',
    reporting_company: 'Compañía Informante',
    info_card: {
      title: 'INFORMACIÓN DE LA EMPRESA',
      name: 'Nombre legal de la Empresa',
      name_placeholder: 'Ingrese el nombre de la empresa',
      legal_entity: 'Entidad Legal (LLC, C-Corp, etc)',
      address: 'Dirección de facturación de la empresa',
      street: 'Calle',
      city: 'Ciudad',
      zip_code: 'Código Postal',
      license: 'Licencia#',
      contractor_license: '# de Licencia de Contratista',
      phone: 'Número de teléfono',
      company_phone: 'Teléfono de la Empresa',
      fax: 'Número de Fax de la Empresa',
      mail: 'Correo Electrónico de la Empresa',
      dba: 'DBA de la Empresa',
      state: 'Estado(s) donde la Empresa esta Registrada',
      state_placholder: 'Ingrese el estado donde la empresa esta registrada',
      country: 'Pais(es) de Operación',
      website: 'Página web de la Empresa',
      edit_logo: 'Editar Logo',
      add_logo: 'Añadir Logo'
    },
    profile_card: {
      title: 'PERFIL',
      content: '¡Cosas increibles estan por venir!'
    },
    terms_card: {
      accepted: 'Aceptado',
      not_accepted: 'No aceptado'
    },
    roles_card: {
      title: 'ROLES',
      create_users: 'Crear Usuarios',
      edit_users: 'Editar Usuarios',
      delete_users: 'Eliminar Usuarios',
      wo_view: 'Ver Work Orders',
      workorders: 'Work Orders',
      invoices: 'Facturas',
      company_settings: 'Perfil de la Compañía',
      sites: 'Sitios',
      no_portal_access: 'Sin Acceso al Portal',
      manage_roles: 'Manejar Acceso',
      panel_new: 'Nuevo Access',
      panel_details: 'Detalles de Acceso',
      panel_name: 'Nombre',
      panel_users: 'Manejo de Usuarios',
      panel_dash: 'Visualización de Dashboard',
      panel_wo: 'Visualización de Work Orders',
      panel_prop: 'Visualización de Propuestas',
      panel_create: 'Crear',
      panel_delete: 'Eliminar',
      panel_save: 'Guardar',
      panel_edit: 'Editar',
      panel_yes: 'Sí',
      panel_no: 'No',
      panel_select: 'Seleccione',
      access_name: 'Nombre de Acceso',
      report_scheduler: 'Administrador de Reportes'
    },
    users_card: {
      title: 'Usuarios',
      name: 'Nombre',
      first_and_last_name: 'Nombre y Apellido',
      role: 'Título',
      company_role: 'Rol de compañía',
      access: 'Nivel de Acceso',
      panel_new: 'Nuevo Usuario',
      panel_details: 'Detalles de Usuario',
      panel_name: 'Nombre',
      panel_first_and_last_name: 'Nombre y Apellido',
      panel_mail: 'Correo Electrónico',
      panel_role: 'Título',
      panel_company_role: 'Rol en la Empresa',
      panel_portal: 'Acceso al portal web',
      user_name_placeholder: 'Nombre',
      user_email_placeholder: 'Email',
      user_role_placeholder: 'Rol',
      user_company_role_placeholder: 'Rol en la Empresa',
      pending: 'pendiente',
      user_already_exists: 'Usuario ya existente',
      search: 'Buscar usuario',
      add_users: 'Por favor agregue usuarios a esta sección'
    },
    support_card: {
      title: 'Soporte',
      policy: 'Política de Privacidad',
      conditions: 'Términos y Condiciones',
      technical: 'Soporte Técnico',
      terms_of_use: 'Condiciones de Uso',
      version: 'Versión del Software'
    },
    reports_card: {
      title: 'Reportes Programados',
      name: 'Nombre del reporte',
      frequency: 'Frecuencia',
      sites: 'Sitio(s)',
      status: 'Estado',
      creator: 'Creador',
      panel_title: 'Reporte',
      panel_name: 'Nombre de Reporte',
      panel_name_placeholder: 'Ingrese nombre',
      panel_mail_to: 'Destinatarios',
      panel_mail_placeholder: 'Ingrese dirección(es) y presione Enter',
      panel_mail_cc: 'Destinatarios (cc)',
      panel_client_name: 'Nombre del Cliente',
      panel_client_name_placeholder: 'Busque el Nombre del Cliente',
      send_on: 'Enviar en (día/hora)',
      data_range: 'Rango de consulta',
      trades: 'Comercio(s)',
      wo_status: 'Estado Orden de Trabajo',
      email_ends: 'Envío Termina',
      active_report: 'Activo',
      inactive_report: 'No Activo',
      view_more: 'Ver Más'
    },
    bes_reports_card: {
      name: 'Nombre',
      reports: 'Reportes Programados'
    },
    bes_notifications_card: {
      title: 'Notificaciones',
      name: 'Nombre',
      action: 'Acción',
      client: 'Cliente',
      creator: 'Creador',
      view_more: 'Ver Más'
    },
    bes_notifications_panel: {
      title: 'Notificación',
      name: 'Notificación Name',
      name_placeholder: 'Ingrese nombre',
      mail_to: 'Destinatarios',
      mail_placeholder: 'Ingrese dirección(es) y presione Enter',
      mail_cc: 'Destinatarios (cc)',
      client_name: 'Nombre del Cliente',
      client_name_placeholder: 'Busque el Nombre del Cliente',
      send_notifications_when: 'Envíe Notificaciones por Email Cuando',
      delete: 'Eliminar',
      save: 'Guardar',
      create: 'Crear',
      options: {
        client_log_in: 'Cliente Inicia Sesión',
        client_runs_report: 'Cliente Ejecuta Reporte',
        client_creates_user: 'Cliente Crea Usuario',
        client_submits_service_request: 'Cliente Envía Pedido de Servicio'
      }
    },
    buttons: {
      edit: 'Editar',
      show_more: 'Mostrar más',
      add: 'Añadir'
    },
    card: {
      profile: 'Perfil de Compañía',
      terms: 'Acuerdo de marco de servicios',
      trades: 'Comercios y Servicios',
      service: 'Área de servicio',
      web_users: 'Usuarios de portal web',
      field_users: 'Usuarios de FieldTech móbil',
      roles: 'Roles de usuario',
      name: 'Nombre y Detalles',
      insurance: 'Información de Compañía y Seguros',
      expiration: 'Válido hasta',
      coi: 'Número de póliza COI',
      comp: 'Compensación a empleados',
      w9: 'W9',
      bank: 'Cheque Bancario Anulado',
      save: 'Guardar',
      update: 'Actualizar logo',
      update_question:
        'Está seguro que desea actualizar el logo de la compañía?',
      cancel: 'Cancelar',
      no_file: 'Ningún documento cargado',
      miles: 'millas',
      more_areas: 'Seleccione mostrar más para ver más áreas de servicio'
    },
    terms_file: {
      confidential: 'Información Confidencial de %company%',
      accepted_by: 'Aceptado por %name% el %date% a las %time%',
      file_name: ' acuerdo de marco de servicios'
    }
  }
}
