import React, { useState } from 'react'
import { makeStyles, Menu, MenuItem } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Sort as SortIcon } from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
  menuItem: theme.filtersClasses.menuItem
}))
const sortStyle = {
  width: '15px',
  height: '15px',
  marginLeft: '6px',
  cursor: 'pointer'
}

export const SortMenu = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (field1, field2) => {
    let fieldName = ''
    switch (props.id) {
      case 'createDate':
        fieldName = 'date_created'
        break
      case 'dueDate':
        fieldName = 'due_date'
        break
      case 'inStatus':
        fieldName = 'status'
        break
      case 'won':
        fieldName = 'work_order.customer_po'
        break
      case 'client_name':
        fieldName = 'work_order.client_name'
        break
      case 'invoice':
        fieldName = 'invoice_number'
        break
      case 'payDate':
        fieldName = 'pay_date'
        break
      case 'achCheckNumber':
        fieldName = 'ach_check_number'
        break
      default:
        fieldName = props.id
    }
    props.setValues(prevState => ({
      ...prevState,
      [field1]: fieldName,
      [field2]: ''
    }))
    setAnchorEl(null)
  }

  const handleClean = () => {
    props.setValues(prevState => ({
      ...prevState,
      asc_sort: '',
      desc_sort: ''
    }))
    setAnchorEl(null)
  }

  return (
    <>
      <SortIcon onClick={handleClick} style={sortStyle} />
      <Menu
        id={props.id}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        getContentAnchorEl={null}
      >
        <MenuItem
          onClick={() => handleSelect('desc_sort', 'asc_sort')}
          className={classes.menuItem}
        >
          {t('invoices.sort')}{' '}
          {props.id === 'won' || props.id === 'amount' || props.id === 'invoice'
            ? t('invoices.highest')
            : props.id === 'dueDate' ||
              props.id === 'createDate' ||
              props.id === 'payDate'
            ? t('invoices.newest')
            : 'Z'}{' '}
          -{' '}
          {props.id === 'won' || props.id === 'amount' || props.id === 'invoice'
            ? t('invoices.lowest')
            : props.id === 'dueDate' ||
              props.id === 'createDate' ||
              props.id === 'payDate'
            ? t('invoices.oldest')
            : 'A'}{' '}
          :{''}
          <FontAwesomeIcon icon={['far', 'bars-sort']} />
        </MenuItem>
        <MenuItem
          onClick={() => handleSelect('asc_sort', 'desc_sort')}
          className={classes.menuItem}
        >
          {t('invoices.sort')}{' '}
          {props.id === 'won' || props.id === 'amount' || props.id === 'invoice'
            ? t('invoices.lowest')
            : props.id === 'dueDate' ||
              props.id === 'createDate' ||
              props.id === 'payDate'
            ? t('invoices.oldest')
            : 'A'}{' '}
          -{' '}
          {props.id === 'won' || props.id === 'amount' || props.id === 'invoice'
            ? t('invoices.highest')
            : props.id === 'dueDate' ||
              props.id === 'createDate' ||
              props.id === 'payDate'
            ? t('invoices.newest')
            : 'Z'}{' '}
          : <FontAwesomeIcon icon={['far', 'bars-sort']} />
        </MenuItem>
        {props.values.asc_sort === props.id ||
        props.values.desc_sort === props.id ? (
          <MenuItem onClick={handleClean} className={classes.menuItem}>
            {t('invoices.clear')}
          </MenuItem>
        ) : null}
      </Menu>
    </>
  )
}
