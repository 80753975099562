import React, { useEffect, useState } from 'react'
import { List } from 'antd'
import {
  AppBar, Box, Button,
  CssBaseline,
  Drawer,
  IconButton,
  ListItemText,
  makeStyles,
  useTheme
} from '@material-ui/core'
import { Hidden, ListItem, Toolbar, Typography, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'

const drawerWidth = 200

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      flexShrink: 0
    }
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  toolbar: {
    marginLeft: '5px',
    '&.MuiToolbar-root': {
      paddingLeft: '17px',
      minHeight: '30px',
      height: '30px'
    }
  },
  menuButton: {
    backgroundColor: theme.colors.white,
    marginRight: theme.spacing(2),
    '&.MuiIconButton-root': {
      boxShadow: '1px 2px 3px 0px rgba(0,0,0,0.59)',
      marginTop: '43px !important',
      padding: '7px',
      fontSize: '24px',
      borderRadius: '3px'
    }
  },
  hide: {
    display: 'none'
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: '45px !important',
    height: '45px !important'
  },
  content: {
    flexGrow: 1,
    paddingTop: 20,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      marginLeft: -drawerWidth
    }
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  contentStyle: {
    width: '100% !important',
    maxWidth: '100$ !important'
  },
  smallListItemText: {
    fontSize: '12px'
  },
  menuTitle: {
    '& .MuiTypography-root': {
      fontSize: '22px',
      fontWeight: '600'
    },
    paddingLeft: '16px'
  },
  selectedItem: {
    '& .MuiTypography-root': {
      color: theme.colors.iconBlue
    }
  },
  exitButton: {
    background: theme.colors.white,
    color: theme.colors.iconBlue,
    border: '1px solid ' + theme.colors.iconBlue,
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    borderRadius: '36px',
    width: '160px',
    alignSelf: 'flex-end',
    marginRight: '10%',
    marginBottom: '15px',
    marginTop: '15px'
  }
}))

export const SideBar = props => {
  const params = useParams()
  const location = useLocation()
  const { t } = useTranslation()
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const [open, setOpen] = React.useState(false)
  const [isMobileOpen, setIsMobileOpen] = React.useState(false)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [selectedItem, setSelectedItem] = useState(t('side_bar.company_profile'))

  useEffect(() => {
    setSelectedItem('')
    // get the part between the host and '-iframe'
    const pathname = location.pathname
    const pattern = /\/([\w-]+)-iframe\//
    const match = pathname.match(pattern)
    const dynamicPart = match ? match[1] : null

    switch (dynamicPart) {
      case 'company-settings':
        setSelectedItem(t('side_bar.company_profile'))
        break
      case 'work-orders':
        setSelectedItem(t('side_bar.work_orders'))
        break
      case 'invoices':
      case 'createInvoice':
        setSelectedItem(t('side_bar.invoices'))
        break
    }
  }, [])

  const handleSmallScreenDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleItemClick = (text) => {
    setSelectedItem(text)
    switch (text) {
      case t('side_bar.company_profile'):
        history.push('/company-settings-iframe/' + params.id + '/' + params.access_token)
        break
      case t('side_bar.work_orders'):
        history.push('/work-orders-iframe/' + params.id + '/' + params.access_token)
        break
      case t('side_bar.invoices'):
        history.push('/invoices-iframe/' + params.id + '/' + params.access_token)
        break
    }
  }

  const handleExit = () => {
    window.parent.postMessage('close', '*')
  }

  const drawerItem = (
    <div>
      <List>
        {[t('side_bar.company_profile'), t('side_bar.work_orders'), t('side_bar.invoices')].map((text, index) => (
          <ListItem
            button
            key={text}
            className={(selectedItem === text) ? classes.selectedItem : ''}
            onClick={() => handleItemClick(text)}
          >
            <ListItemText>
              <Typography className={classes.smallListItemText}>{text}</Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </div>
  )

  useEffect(() => {
    setOpen(!isSmallScreen)
  }, [isSmallScreen])

  useEffect(() => {
    const html = document.documentElement
    html.style.overflowX = 'hidden'
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        color="transparent"
        elevation={0}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            aria-label="open drawer"
            onClick={isSmallScreen ? handleSmallScreenDrawerToggle : handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <FontAwesomeIcon icon={['far', 'bars']} size="xs" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={isMobileOpen}
            onClose={handleSmallScreenDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {drawerItem}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="persistent"
            open={open}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <FontAwesomeIcon icon={['far', 'chevron-circle-left']} size="xs" />
              </IconButton>
            </div>
            <Box className={classes.menuTitle}>
              <Typography>Menu</Typography>
            </Box>
            {drawerItem}
            <Button
              elevation={0}
              variant="outlined"
              onClick={handleExit}
              className={classes.exitButton}
            >
              {t('side_bar.exit_masquerade')}
            </Button>
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <Box className={classes.contentStyle}>{props.children}</Box>
      </main>
    </div>
  )
}
