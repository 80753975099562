import React, { useState } from 'react'
import { FormLabel, makeStyles } from '@material-ui/core'
import { DateTimeFilter } from '../workorders/DateTimeFilter'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '28px'
  },
  field: {
    marginLeft: '5px',
    marginBottom: '4px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  fieldData: {
    marginLeft: '5px',
    marginBottom: '28px',
    color: theme.colors.textGray,
    fontSize: '14px',
    fontWeight: '400'
  },
  datePickerStyles: {
    ...theme.filtersClasses.mainInput2,
    '& .ant-picker-input': {
      boxShadow: 'none',
      '& input': {
        color: theme.colors.basicDisabledButtonColor,
        cursor: 'pointer',
        '&::placeholder': {
          color: theme.colors.placeholder,
          opacity: 1
        }
      }
    },
    cursor: 'pointer',
    boxShadow: 'none',
    zIndex: 9,
    paddingLeft: '14px'
  },
  mandatoryStyles: {
    color: theme.colors.errorColor
  }
}))

function DateTimePicker({
  question,
  disabled,
  answer,
  section,
  questionId,
  onUpdate
}) {
  const [time, setTime] = useState(answer)
  const mandatory = question?.mandatory
  const classes = useStyles()

  function handleUpdate(time) {
    setTime(time)
    onUpdate(section, questionId, time)
  }

  function disabledDate() {
    return false
  }

  return (
    <div className={classes.container}>
      <FormLabel
        component="legend"
        className={`${classes.field} ${
          mandatory && !answer && classes.mandatoryStyles
        }`}
      >
        {question?.title}
      </FormLabel>
      <DateTimeFilter
        disableTimeSelector={true}
        canSelectAnytime={true}
        datePickerStyles={classes.datePickerStyles}
        disabledTime={disabledDate}
        disabledDate={disabledDate}
        placeholder={question?.empty_state_message}
        disabled={disabled}
        id={'date_time'}
        values={time ?? ''}
        type={2}
        setValues={handleUpdate}
        filterContent={() => null}
      />
    </div>
  )
}

export default DateTimePicker
