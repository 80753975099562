import * as Api from '../lib/Api'
import { loadingActions } from '../store/loading'
import { login } from './AuthService'
import { store } from '../store'
import { toBase64 } from '../lib/Global'

export const createUser = async (
  accessCode,
  firstName,
  lastName,
  email,
  title,
  username,
  password,
  avoid_email = true
) => {
  store.dispatch(loadingActions.show())
  try {
    await Api.createUser(
      {
        company_id: accessCode,
        firstName,
        lastName,
        email,
        role: title,
        username,
        password
      },
      '1st'
    )
  } catch (error) {
    store.dispatch(loadingActions.hide())
    if (error.details?.details && error.details?.details[0]) {
      if (error.details.details[0].path.includes('email')) {
        throw { type: 'email', message: error.details?.details[0].message }
      }
      throw {
        message: `${error.details?.details[0].path.substring(1)} ${
          error.details?.details[0].message
        }`
      }
    }
    throw { type: 'email', message: error.message }
  }

  try {
    await Api.createUser(
      {
        company_id: accessCode,
        firstName,
        lastName,
        email,
        role: title,
        username,
        password
      },
      '2nd'
    )
  } catch (error) {
    store.dispatch(loadingActions.hide())
    if (error.details?.details && error.details?.details) {
      throw {
        message: `${error.details?.details[0].path.substring(1)} ${
          error.details?.details[0].message
        }`
      }
    }
    throw { type: 'username', message: error.message }
  }

  try {
    await Api.createUser(
      {
        company_id: accessCode,
        firstName,
        lastName,
        email,
        role: title,
        username,
        password,
        avoid_email
      },
      'final'
    )
  } catch (error) {
    store.dispatch(loadingActions.hide())
    if (error.details?.details && error.details?.details) {
      throw {
        message: `${error.details?.details[0].path.substring(1)} ${
          error.details?.details[0].message
        }`
      }
    }
    throw { message: error.message }
  }

  // Login Process
  await login(email, password)
  store.dispatch(loadingActions.hide())
}

export const createClientUser = async (
  accessCode,
  firstName,
  lastName,
  email,
  phone,
  username,
  photoUrl,
  roles,
  role,
  password,
  avoid_email = true
) => {
  store.dispatch(loadingActions.show())
  try {
    await Api.createUser(
      {
        company_id: accessCode,
        firstName,
        lastName,
        email,
        roles,
        role,
        phone,
        photo_url: photoUrl,
        username,
        password
      },
      '1st'
    )
  } catch (error) {
    store.dispatch(loadingActions.hide())
    if (error.details?.details && error.details?.details[0]) {
      if (error.details.details[0].path.includes('email')) {
        throw error.details?.details[0].message
      }
      throw `${error.details?.details[0].path.substring(1)} ${
        error.details?.details[0].message
      }`
    }
    throw error.message
  }

  try {
    await Api.createUser(
      {
        company_id: accessCode,
        firstName,
        lastName,
        email,
        roles,
        phone,
        photo_url: photoUrl,
        username,
        password
      },
      '2nd'
    )
  } catch (error) {
    store.dispatch(loadingActions.hide())
    if (error.details?.details && error.details?.details) {
      throw `${error.details?.details[0].path.substring(1)} ${
        error.details?.details[0].message
      }`
    }
    throw error.message
  }

  let newUser = null
  try {
    newUser = await Api.createUser(
      {
        company_id: accessCode,
        firstName,
        lastName,
        email,
        roles,
        role,
        phone,
        photo_url: photoUrl,
        username,
        password,
        avoid_email
      },
      'final'
    )
  } catch (error) {
    store.dispatch(loadingActions.hide())
    if (error.details?.details && error.details?.details) {
      throw `${error.details?.details[0].path.substring(1)} ${
        error.details?.details[0].message
      }`
    }
    throw error.message
  }

  store.dispatch(loadingActions.hide())
  return newUser
}

export const updateCompany = async (id, params) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.updateCompany(id, params)
    store.dispatch(loadingActions.hide())
    return response
  } catch (error) {
    store.dispatch(loadingActions.hide())
    throw error.message
  }
}

export const updateAccountSettings = async params => {
  store.dispatch(loadingActions.show())
  try {
    await Api.updateAccountSettings(params)
  } catch (error) {
    store.dispatch(loadingActions.hide())
    throw error.message
  }
  store.dispatch(loadingActions.hide())
}

export const updateClientUser = async (id, params) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.updateUser(id, params)
    store.dispatch(loadingActions.hide())
    return response
  } catch (error) {
    store.dispatch(loadingActions.hide())
    throw error
  }
}

export const deleteUser = async (id, companyId) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.deleteUser(id, companyId)
    store.dispatch(loadingActions.hide())
    return response
  } catch (error) {
    store.dispatch(loadingActions.hide())
    console.error('Delete user failed with error: ', error)
  }
}

export const getCompanyRoles = async companyId => {
  store.dispatch(loadingActions.show())
  try {
    const companyRoles = await Api.getCompanyRoles(companyId)
    store.dispatch(loadingActions.hide())
    return companyRoles
  } catch (e) {
    store.dispatch(loadingActions.hide())
    throw e
  }
}

export const workOrdersPortal = async (
  showAll,
  search,
  clientName,
  siteName,
  trades,
  service,
  woNumber,
  openDate,
  dueDate,
  status,
  invoices,
  gcScope,
  generalNotes,
  nte,
  priority,
  clientTrackingNumber,
  sort,
  perPage,
  page,
  integration
) => {
  const tradeString = trades.split('|').join(',')
  const statusString = status
    ? status.split('|').join(',')
    : 'open,in_progress,completed,cancelled,expired'
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await Api.workOrdersPortal(
      showAll,
      search,
      clientName,
      siteName,
      tradeString,
      service,
      woNumber,
      openDate,
      dueDate,
      statusString,
      invoices,
      gcScope,
      generalNotes,
      nte,
      priority,
      clientTrackingNumber,
      sort,
      perPage,
      page,
      integration
    )
    if (response) {
      return { status: true, content: response }
    } else {
      return { status: false, content: null }
    }
  } catch (err) {
    throw err
  }
}

export const exportWorkOrders = async (
  showAll,
  search,
  clientName,
  siteName,
  trades,
  service,
  woExtId,
  openDate,
  dueDate,
  status,
  invoices,
  priority,
  clientTrackingNumber,
  ascSort,
  descSort
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const tradeString = trades.replaceAll('|', ',')
    const statusString = status
      ? status.replaceAll('|', ',')
      : 'open,in_progress,completed,cancelled,expired'
    const sortFilter =
      ascSort || descSort
        ? ascSort
          ? ascSort + ''
          : descSort
          ? descSort + '_d'
          : ''
        : 'portal'
    const response = await Api.exportWorkOrders(
      showAll,
      search,
      clientName,
      siteName,
      tradeString,
      service,
      woExtId,
      openDate,
      dueDate,
      statusString,
      invoices,
      priority,
      clientTrackingNumber,
      sortFilter
    )
    if (response) {
      return { status: true, content: response }
    } else {
      return { status: false, content: null }
    }
  } catch (err) {
    throw err
  }
}
export const csvData = async (
  showAll,
  search,
  clientName,
  siteName,
  trades,
  service,
  woExtId,
  openDate,
  dueDate,
  status,
  invoices,
  priority,
  clientTrackingNumber,
  generalNotes,
  ascSort,
  descSort,
  ids,
  companyId
) => {
  const tradeString = trades.replaceAll('|', ',')
  const statusString = status
    ? status.replaceAll('|', ',')
    : 'open,in_progress,completed,cancelled,expired'
  const sortFilter =
    ascSort || descSort
      ? ascSort
        ? ascSort + ''
        : descSort
        ? descSort + '_d'
        : ''
      : 'portal'
  return await Api.csvData(
    showAll,
    search,
    clientName,
    siteName,
    tradeString,
    service,
    woExtId,
    openDate,
    dueDate,
    statusString,
    invoices,
    priority,
    clientTrackingNumber,
    generalNotes,
    sortFilter,
    ids,
    companyId
  )
}

export const csvInvoicesData = async (
  showAll,
  search,
  won,
  status,
  client,
  location,
  invoiceNumber,
  amount,
  createDate,
  dueDate,
  payDate,
  achCheckNumber,
  ascSort,
  descSort,
  companyId,
  perPage,
  page,
  ids
) => {
  return await Api.csvInvoicesData(
    showAll,
    search,
    won,
    status,
    client,
    location,
    invoiceNumber,
    amount,
    createDate,
    dueDate,
    payDate,
    achCheckNumber,
    ascSort ? ascSort + '' : descSort ? descSort + '_d' : '',
    companyId,
    perPage,
    page,
    ids
  )
}

export const invoicesPortal = async (
  showAll,
  search,
  won,
  inStatus,
  client,
  location,
  invoiceNumber,
  amount,
  createDate,
  dueDate,
  payDate,
  achCheckNumber,
  sort,
  companyId,
  perPage,
  skip
) => {
  try {
    const response = await Api.invoicesPortal(
      showAll,
      search,
      won,
      inStatus,
      client,
      location,
      invoiceNumber,
      amount,
      createDate,
      dueDate,
      payDate,
      achCheckNumber,
      sort,
      companyId,
      perPage,
      skip
    )
    if (response) {
      return { status: true, content: response }
    } else {
      return { status: false, content: null }
    }
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const submitInvoice = async (id, body, submitted) => {
  try {
    // remove unnecessary fields
    delete body.id
    delete body.date_created
    delete body.date_updated
    const response = await Api.submitInvoicePortal(id, submitted, body)
    if (response) {
      return { status: true, content: response }
    } else {
      return { status: false, content: null }
    }
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const getInvoice = async id => {
  try {
    const response = await Api.getInvoiceById(id)
    if (response) {
      return { status: true, content: response }
    } else {
      return { status: false, content: null }
    }
  } catch (error) {
    store.dispatch(loadingActions.hide())
    throw error
  }
}

export const getSitesAdvancedFiltersInfo = async clientId => {
  store.dispatch(loadingActions.show())
  try {
    const advancedFilters = await Api.getWorkOrderTrades()
    advancedFilters.services = [].concat.apply(
      [],
      advancedFilters.trades.map(item => item.trade_services)
    )
    const priorities = await Api.getWorkOrderPriorities(clientId)
    if (priorities) {
      advancedFilters.priorities = priorities.map(prio => {
        return { name: prio }
      })
    }
    store.dispatch(loadingActions.hide())
    return advancedFilters
  } catch (e) {
    store.dispatch(loadingActions.hide())
    throw e
  }
}

export const getCompany = async id => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.getCompany(id)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}
export const getContactOffline = async (id, originEmail) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.getContactOffline(id, originEmail)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const getCompanyProfile = async id => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.getCompanyProfile(id)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const getS3SignedURL = async (s3Key, action, contentType) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.getSignedURL(s3Key, action, contentType)
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const getCompanyUsers = async companyId => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.getCompanyUsers(companyId)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const createRolWithScopes = async (
  name,
  workorders,
  sites,
  companySettings
) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.createRolWithScopes(
      name,
      workorders === 'yes',
      sites === 'yes',
      companySettings === 'yes'
    )
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const updateRolWithScopes = async (
  id,
  name,
  workorders,
  sites,
  companySettings
) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.updateRolWithScopes(
      id,
      name,
      workorders === 'yes',
      sites === 'yes',
      companySettings === 'yes'
    )
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const deleteRolWithScopes = async id => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.deleteRolWithScopes(id)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const changeUserPassword = async password => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.changeUserPassword(password)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const createLog = async (
  logType,
  woId,
  userData,
  logData,
  iframe = false
) => {
  const timeZone = ''
  const woLog = {}
  woLog.latitude = '0'
  woLog.longitude = '0'
  woLog.type = logType
  woLog.offline = false
  if (logType === 'checkOut') {
    woLog.work_order_id = woId
    woLog.status = 'complete'
    woLog.id = logData.id
    woLog.user_time_zone = logData.timeZone
    woLog.date_created = Math.round(Date.now() / 1000)
    woLog.wo_log_id = logData.id
    woLog.return_visit = logData.return_visit
    woLog.return_time = logData.return_time
    woLog.return_time_end = logData.return_time_end
    woLog.clockout_option = logData.clockout_option
    woLog.return_reason = logData.return_reason
    const response = await Api.uploadWoLog(woLog, iframe)
    return response
  } else {
    woLog.work_order_id = woId
    woLog.user_time_zone = timeZone
    woLog.technicians_number = 0
    woLog.date_created = Math.round(Date.now() / 1000)
    woLog.user_id = userData.id
    woLog.technician_name = userData.firstName + userData.lastName
    woLog.status = 'complete'

    const response = await Api.uploadWoLog(woLog, iframe)
    return response
  }
}

export const getWoList = async ids => {
  const response = await Api.getWorkOrderList(ids)
  return response
}

export const sendInviteEmail = async (email, companyId) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.sendInviteEmail(email, companyId)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const addReportingCompany = async (affiliateId, userId) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.addReportingCompany(affiliateId, userId)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const getScreenConfigOnline = async (
  configId,
  trade,
  service,
  // eslint-disable-next-line camelcase
  work_type,
  iframe
) => {
  const params = {
    trade,
    service,
    work_type
  }
  try {
    const response = await Api.getScreenConfigOnline(configId, params, iframe)
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const getTermsAndConditions = async (companyId, content) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.getTermsAndConditions(companyId, content)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const getCompanyBillDisclosure = async companyId => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.getCompanyBillDisclosure(companyId)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const putCompanyBillDisclosure = async companyId => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.putCompanyBillDisclosure(companyId)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const createInvoiceAttachment = async validFiles => {
  try {
    const arrayResponse = []
    for (const validFile of validFiles) {
      const base64file = await toBase64(validFile.content)
      const substrings = base64file.split(',')
      const imgData = substrings?.[1]
      if (imgData) {
        const request = {
          invoice_id: validFile.invoice_id,
          content: imgData,
          title: validFile.title
        }
        const result = await Api.createInvoiceAttachment(request)
        arrayResponse.push(result)
      }
    }
    return arrayResponse
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const deleteInvoiceAttachment = async (invoiceId, fileId) => {
  return await Api.deleteInvoiceAttachment(invoiceId, fileId)
}

export const getWorkOrderAttachments = async woId => {
  const response = await Api.getWorkOrderAttachments(woId)
  return response?.work_order_attachments
}

export const getWorkMessages = async id => {
  try {
    const response = await Api.getWorkMessages(id)
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const createMessage = body => {
  return Api.createMessage(body)
}

export const editMessage = (id, body) => {
  return Api.editMessage(id, body)
}

export const deleteMessageFile = async (id, fileId) => {
  store.dispatch(loadingActions.show())
  try {
    const response = await Api.deleteMessageFile(id, fileId)
    store.dispatch(loadingActions.hide())
    return response
  } catch (err) {
    store.dispatch(loadingActions.hide())
    throw err
  }
}

export const createMessageFile = (id, body) => {
  return Api.createMessageFile(id, body)
}

export const editExternalMessage = (id, body) => {
  return Api.editExternalMessage(id, body)
}

export const createExternalMessage = body => {
  return Api.createExternalMessage(body)
}
