import { makeStyles } from '@material-ui/core'
import React from 'react'
import { FilePreview } from './FilePreview'
import { imageExtensions } from '../../lib/Constants'

const useStyles = makeStyles(theme => ({
  topContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  imagePreview: {
    width: 100,
    height: 100,
    overflow: 'hidden',
    margin: 10,
    position: 'relative',
    border: `1px solid ${theme.colors.textGray}`,
    borderRadius: 10,
    objectFit: 'cover'
  },
  pdfContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: 10,
    position: 'relative'
  },
  foldedSheet: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 20,
    height: 20,
    background: `linear-gradient(to left top, ${theme.colors.white} 50%, ${theme.colors.textGray} 48%)`,
    borderTopLeftRadius: 10
  },
  filesGrid: {
    width: '100%',
    margin: '13px 3px 10px 3px'
  },
  fileDiv: {
    width: 100,
    height: 105,
    overflow: 'hidden',
    margin: 10,
    position: 'relative'
  }
}))
const AttachmentComponent = props => {
  const { attachments } = props
  const classes = useStyles()

  const handleClick = attachmentUrl => {
    window.open(attachmentUrl, '_blank')
  }
  return (
    <div className={classes.topContainer}>
      {attachments.map((attachment, index) => (
        <div key={index}>
          {imageExtensions.includes(attachment.extension.toLowerCase()) ? (
            <img
              src={attachment.attachment_url}
              alt={attachment.name}
              className={classes.imagePreview}
              onClick={() => handleClick(attachment.attachment_url)}
            />
          ) : (
            <div
              className={classes.fileDiv}
              onClick={() => handleClick(attachment.attachment_url)}
            >
              <FilePreview
                fileType={attachment.extension.substring(1)}
                filePath={attachment.attachment_url}
                fileName={attachment.name}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default AttachmentComponent
