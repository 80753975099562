import { Box, Button, Card, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import GlobalNumberInput from '../form/NumberInput'
import { useSelector } from 'react-redux'
import { defaultConfigRates } from '../../lib/Constants'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    boxShadow: '6px 9px 43px ' + theme.colors.profile.shadowCard,
    marginBottom: '2em',
    padding: '1em',
    color: theme.colors.text
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: '700'
  },
  editButton: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '0',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.05em',
    lineHeight: '19px'
  },
  cardSubtitle: {
    fontSize: '16px',
    fontWeight: '500'
  },
  sectionDivider: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  ratesContainer: {
    gap: '1em',
    marginTop: '1em',
    minWidth: '29em',
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gridGap: '5px',
      minWidth: '100%'
    }
  },
  smallChip: {
    border: '1px solid ' + theme.colors.textGray,
    borderRadius: '45px',
    fontSize: '12px',
    fontWeight: '400',
    padding: '9px 12px',
    margin: '20px 20px 0px 0px',
    lineHeight: '14px',
    cursor: 'default'
  },
  largeChip: {
    border: '1px solid ' + theme.colors.textGray,
    borderRadius: '45px',
    marginRight: '20px',
    marginBottom: '20px',
    padding: '12px 25px',
    fontSize: '20px',
    fontWeight: '500',
    cursor: 'default',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      width: '100%'
    }
  }
}))

const EditButton = props => {
  const classes = useStyles()
  return (
    <Button className={classes.editButton} onClick={props.onClick}>
      {props.label}
    </Button>
  )
}

export const TradesServicesCard = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { clients, trades, catalogClients, servicesCatalog } = props
  const configRates = useSelector(state => state.auth.user?.userInfo?.configurations?.portals?.subcontractor?.onboarding?.compliance?.tradesServices?.rates)
  const companyRates = configRates && configRates.length > 0 ? configRates : defaultConfigRates
  const subcontractorRates = useSelector(state => state.auth.user?.userInfo?.subcontractor_rates)

  const handleEditProfile = () => {
    props.setComponent('trades')
    props.setOpen(true)
  }

  return (
    <Card className={classes.card}>
      <Box display="flex" flexDirection="row">
        <Typography classes={{ root: classes.cardTitle }}>
          {t('company_settings.card.trades')}
        </Typography>
        <EditButton
          label={t('company_settings.buttons.edit')}
          onClick={handleEditProfile}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        className={classes.sectionDivider}
      >
        {catalogClients &&
          clients?.map((client, index) => {
            return (
              <Typography key={index} className={classes.largeChip}>
                {catalogClients[client]}
              </Typography>
            )
          })}
      </Box>
      {trades?.map(trade => {
        return (
          <Box display="flex" flexDirection="column" key={trade.name}>
            <Typography classes={{ root: classes.cardSubtitle }}>
              {trade.name}
            </Typography>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {trade?.services?.map((service, index) => {
                return (
                  <Typography key={index} className={classes.smallChip}>
                    {servicesCatalog[service]}
                  </Typography>
                )
              })}
            </Box>

            {!props?.hideRates ? (
              <Box
                className={classes.chipsContainer}
                display="flex"
                flexDirection="row"
              >
                <Box
                  flex={2}
                  display="flex"
                  flexDirection="row"
                  className={classes.ratesContainer}
                >
                  {companyRates?.map((rate, ind) => <GlobalNumberInput
                      key={ind}
                      field={rate.catalog_key}
                      placeholder={rate.rate === 'hourly' ? t('company_profile.placeholder.rate') : t('company_profile.labels.travel_cost')}
                      value={trade?.rates?.[rate.catalog_key]}
                      label={subcontractorRates?.[rate.catalog_key] + (rate.rate === 'hourly' ? ' [$/hr]' : ' [$]')}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      decimalSeparator="."
                      disabled
                    />
                  )}
                </Box>
                <Box flex={1}></Box>
              </Box>
            ) : (
              <div>
                <br />
              </div>
            )}
          </Box>
        )
      })}
    </Card>
  )
}
