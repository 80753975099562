import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

/** Redux **/
import { useDispatch } from 'react-redux'
import { loadingActions } from '../../store/loading'

/** Material UI **/
import { EmailOutlined } from '@material-ui/icons'
import {
  BasicButton,
  CustomTextField,
  HighlightButton
} from '../../styles/mui_custom_components'
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  Typography
} from '@material-ui/core'

/** Validations **/
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

/** Services **/
import { requestResetPassword } from '../../services/AuthService'

const useStyles = makeStyles(theme => ({
  mainGrid: {
    overflow: 'auto',
    minHeight: '667px',
    width: '736px',
    marginTop: '-80px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '557px',
      width: 'auto'
    }
  },
  mainItem: {
    maxWidth: '40em',
    paddingTop: '4em',
    marginTop: '-80px',
    transform: 'scale(0.8)'
  },
  subtitleText: {
    fontSize: '20px',
    paddingTop: '0.5em',
    opacity: '90%',
    fontWeight: '300'
  },
  title: {
    fontWeight: '700',
    fontSize: '30px'
  },
  emailSent: {
    fontWeight: '700',
    fontSize: '22px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 'auto',
      fontSize: '30px'
    }
  },
  emailSentSubtitleText: {
    fontWeight: '300',
    fontSize: '20px',
    paddingTop: '25px'
  },
  emailField: {
    marginTop: 0,
    '& .MuiOutlinedInput-root': {
      '&.Mui-error fieldset': {
        borderColor: theme.colors.mainBlue
      }
    }
  },
  sendButton: {
    height: '60px',
    width: '120px',
    borderRadius: '45px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  },
  linkBox: {
    width: 'max-content',
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0'
    }
  },
  responsiveCenter: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  backButton: {
    marginTop: '80px'
  },
  marginBtnMobile: {
    padding: '24px 33px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '20px'
    }
  }
}))

const ForgotPassword = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [errorApi, setErrorApi] = useState('')
  const [emailSent, setEmailSent] = useState(false)

  /** VALIDATIONS **/
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(t('general.messages.errors.required'))
      .email(t('general.messages.errors.email'))
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = async data => {
    dispatch(loadingActions.show())
    try {
      const response = await requestResetPassword(email)

      if (response && response.status) {
        dispatch(loadingActions.hide())
        history.replace('/')
      } else {
        dispatch(loadingActions.hide())
        setEmailSent(true)
      }
    } catch (error) {
      dispatch(loadingActions.hide())
      if (error.code === 404) {
        setErrorApi(t('forgot_password.email_not_found'))
      }
      console.error(error)
    }
  }

  const handleEmailChange = event => {
    setEmail(event.target.value)
    setErrorApi('')
  }

  const backClickHandler = event => {
    event.preventDefault()
    history.push('/sign-in')
  }

  return (
    <div>
      <Grid
        className={classes.mainGrid}
        data-testid={'forgot_password_page'}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid className={classes.mainItem} item xs={12}>
          {!emailSent ? (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={classes.title}
                  component="h4"
                  variant="h4"
                >
                  {t('forgot_password.title')}
                </Typography>
              </Grid>
              <Grid className={classes.subtitle} item xs={12}>
                <Typography
                  align="center"
                  component="h2"
                  variant="subtitle1"
                  className={classes.subtitleText}
                >
                  {t('forgot_password.subtitle_1')}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box mt={4}>
                  <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="center">
                      <Grid item xs={12} sm={10} md={10}>
                        <CustomTextField
                          className={classes.emailField}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          placeholder={t('forgot_password.email')}
                          name="email"
                          autoComplete="off"
                          autoFocus
                          error={!!errors.email || !!errorApi}
                          helperText={
                            (errors.email && errors.email.message) || errorApi
                          }
                          {...register('email')}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailOutlined />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <HighlightButton
                                data-testid={'submit_button'}
                                disabled={!email}
                                className={classes.sendButton}
                                type="submit"
                                variant="contained"
                              >
                                {t('forgot_password.send')}
                              </HighlightButton>
                            ),
                            style: {
                              borderRadius: '45px',
                              height: '60px',
                              fontSize: '20px',
                              paddingRight: 0
                            }
                          }}
                          onInput={handleEmailChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={10}
                        md={10}
                        className={classes.linkBox}
                      >
                        <BasicButton
                          data-testid="back_log_in"
                          type="button"
                          variant="contained"
                          onClick={backClickHandler}
                        >
                          {t('forgot_password.back')}
                        </BasicButton>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={classes.emailSent}
                  component="h4"
                  variant="h4"
                >
                  {t('forgot_password.email_sent')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  component="h2"
                  variant="subtitle1"
                  className={classes.emailSentSubtitleText}
                >
                  {t('forgot_password.subtitle_2')}
                </Typography>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                item
                xs={12}
                className={classes.marginBtnMobile}
              >
                <HighlightButton
                  type="submit"
                  variant="contained"
                  onClick={backClickHandler}
                >
                  {t('forgot_password.back_to_log_in')}
                </HighlightButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default ForgotPassword
