/* eslint-disable no-undef */
import React from 'react'

/** Material UI **/
import { styled } from '@mui/material/styles'
import { FormLabel, makeStyles, Typography } from '@material-ui/core'
import FormControl from '@mui/material/FormControl'
import NumberFormat from 'react-number-format'
import customTheme from '../../styles/mui_theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

const BootstrapInput = styled(NumberFormat)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  borderRadius: 45,
  position: 'relative',
  backgroundColor: customTheme.colors.white,
  border: '1px solid ' + customTheme.colors.profile.border_input,
  fontSize: 12,
  width: '100%',
  padding: '10px 12px',
  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow'
  ]),
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"'
  ].join(','),
  '&:focus': {
    border: '1px solid ' + theme.palette.primary.main,
    boxShadow: '0 0 0 0.2rem ' + customTheme.colors.profile.box_shadow,
    outline: 'none'
  },
  '&::placeholder': {
    textOverflow: 'ellipsis !important'
  },
  '&:invalid': {
    borderColor: customTheme.colors.errorText
  }
}))

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    height: '10px',
    textAlign: 'left',
    marginBottom: '10px'
  },
  label: {
    color: customTheme.colors.grey,
    fontSize: '12px',
    marginBottom: '0.5em',
    textAlign: 'left',
    marginLeft: '5px',
    marginRight: '5px',
    display: 'flex'
  },
  error: {
    fontSize: '10px',
    color: theme.colors.errorText,
    marginLeft: '15px'
  },
  infoButton: {
    color: theme.colors.iconBlue,
    alignSelf: 'center'
  },
  required: {
    color: theme.colors.errorText
  },
  borderError: {
    borderColor: theme.colors.errorText
  },
  title: {
    flex: 1,
    fontSize: '12px',
    color: customTheme.colors.grey
  }
}))

export default function GlobalNumberInput(props) {
  const classes = useStyles()
  const {
    label,
    onChange,
    validate,
    error,
    helperText,
    infoButtonCallback,
    ...rest
  } = props

  const handleChange = event => {
    if (validate) {
      onChange(event)
      return
    }
    onChange(event.target.value, props.field)
  }

  return (
    <FormControl variant="standard" fullWidth={true}>
      {label ? (
        <Typography id="text-label" className={classes.label}>
          {props.required && <span className={classes.required}>*</span>}
          <Typography id="text-label" className={classes.title}>
            {label}
          </Typography>
          {infoButtonCallback && (
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={classes.infoButton}
              onClick={infoButtonCallback}
            />
          )}
        </Typography>
      ) : (
        ''
      )}
      <BootstrapInput {...rest} onBlur={handleChange} />
      <div className={classes.bottomSpacing}>
        {error && <FormLabel className={classes.error}>{helperText}</FormLabel>}
      </div>
    </FormControl>
  )
}
