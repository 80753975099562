import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Card,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core'
import Maps from '../Maps'
import GlobalChip from '../form/Chip'
import { cloneDeep } from 'lodash'
import {
  averageGeoLocation,
  getCoordinatesFromZipList, isRunningInIframe,
  parseMapPin,
  serviceAreasArray
} from '../../lib/Global'
import {
  callZipApi,
  parsePolygonList,
  parseZipList
} from '../../services/ApiLocationService'
import { useDispatch } from 'react-redux'
import { loadingActions } from '../../store/loading'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    boxShadow: '6px 9px 43px ' + theme.colors.profile.shadowCard,
    marginBottom: '2em',
    padding: '1em',
    color: theme.colors.text
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: '700'
  },
  editButton: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '0',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.05em',
    lineHeight: '19px'
  },
  cardSubtitle: {
    fontSize: '14px',
    fontWeight: '300'
  },
  moreAreas: {
    fontSize: '12px',
    fontWeight: '300',
    fontStyle: 'italic',
    lineHeight: '14px',
    color: theme.colors.grey,
    paddingTop: '0.5em'
  },
  buttonsContainer: {
    marginBottom: '0.5em'
  },
  sectionDivider: {
    marginTop: '2em'
  },
  ratesContainer: {
    gap: '1em',
    marginTop: '1em'
  },
  loadingMap: {
    textAlign: 'center',
    paddingTop: 300 / 2 - 50,
    paddingBottom: 300 / 2 - 50
  }
}))

const EditButton = props => {
  const classes = useStyles()
  return (
    <Button className={classes.editButton} onClick={props.onClick}>
      {props.label}
    </Button>
  )
}

export const ServiceAreaCard = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const gridSize = useRef()
  const [mapContainerWidth, setMapContainerWidth] = useState(0)
  const { serviceAreas, moreAreas } = props

  const [areaSelected, setAreaSelected] = useState(new Set())
  // map variables
  const [centerRadius, setCenterRadius] = useState(null)
  const [initialPosition, setInitialPosition] = useState()
  const [markers, setMarkers] = useState([])
  const [paths, setPaths] = useState([])
  const [currentServiceArea, setCurrentServiceArea] = useState()
  const [zoom, setZoom] = useState()
  const dispatch = useDispatch()
  const [mapReady, setMapReady] = useState()

  useEffect(() => {
    setMapContainerWidth(gridSize.current.clientWidth)
  }, [])

  useEffect(() => {
    if (props.chips?.length > 0) {
      setSelected(new Set([props.chips[0]]))
    }
  }, [props.chips])

  useEffect(() => {
    if (mapReady) dispatch(loadingActions.hide())
  }, [mapReady])

  const handleEditProfile = () => {
    props.setComponent('service')
    props.setOpen(true)
  }

  const setSelected = async selection => {
    dispatch(loadingActions.show())
    const currentFilter = cloneDeep(areaSelected)
    const iterator = currentFilter[Symbol.iterator]()
    // Get current Value in set
    const currentItem = iterator.next().value
    if (currentItem && selection.has(currentItem) && selection.size > 1) {
      selection.delete(currentItem)
    }
    setAreaSelected(selection)
    const values = selection.values()
    const currentValue = values.next().value
    if (currentValue) {
      const area = getServiceSelected(currentValue)
      if (!area?.zip) {
        area.zip = await getZipCodesFiltered(area)
      }
      await parseDataToMapView(area)
      setCurrentServiceArea(area)
    }
  }

  const getServiceSelected = serviceAreaSelected => {
    const arrayOfServiceLabels = serviceAreasArray(serviceAreas)
    const index = arrayOfServiceLabels.indexOf(serviceAreaSelected)
    if (index > -1) {
      return serviceAreas[index]
    }
    return null
  }

  const filterUnselectedZipCodes = (zipCodeList, unselected) => {
    if (!zipCodeList?.length) return []
    const { cities, zip, county } = unselected
    const newZipList = []
    for (const item of zipCodeList) {
      if (
        cities?.includes(item.city) ||
        zip?.includes(item.zip) ||
        county?.includes(item.county)
      ) {
        item.selected = false
      }
      newZipList.push(item)
    }
    return newZipList
  }

  const getZipCodesFiltered = async serviceArea => {
    // eslint-disable-next-line camelcase
    const { state_code, city, radius, lat, lng, unselected } = serviceArea
    let zip = []
    if (radius && lat && lng) {
      zip = await callZipApi('GET', `/radius/${radius}/${lat}/${lng}`)
    } else {
      if (city?.includes('All Cities')) {
        // eslint-disable-next-line camelcase
        zip = await callZipApi('GET', `/state/${state_code}`)
      } else {
        zip =
          // eslint-disable-next-line camelcase
          (await callZipApi('POST', `/cities/state/${state_code}`, {
            cities: JSON.stringify(city)
          })) ?? []
        if (zip?.length) {
          zip = zip?.filter(
            item =>
              // eslint-disable-next-line camelcase
              item._source?.state === state_code &&
              city.includes(item._source?.primary_city) &&
              item._score > 8
          )
        }
      }
    }
    zip = parseZipList(zip)
    if (unselected) {
      zip = filterUnselectedZipCodes(zip, unselected)
    }
    return zip
  }

  const parseDataToMapView = async serviceAreaSelected => {
    const selectionZipArray = cloneDeep(serviceAreaSelected.zip)
    if (!selectionZipArray?.length) return
    if (serviceAreaSelected.radius) {
      setCenterRadius({
        lat: serviceAreaSelected.lat,
        lng: serviceAreaSelected.lng,
        radius: ~~serviceAreaSelected.radius
      })
    }
    const cities = selectionZipArray
    const markList = parseMapPin(cities)
    setMarkers(markList)
    findZoom(markList)

    const midPoint = averageGeoLocation(markList)
    setInitialPosition(midPoint)

    // zip id list to query polygons
    const zipIds = []
    markList?.map(async marker => {
      zipIds.push(marker.id)
    })
    // query polygon data for selected area
    const zipResponse = await getCoordinatesFromZipList(zipIds, setMapReady)
    const pathList = []
    // parse pathlist from response
    pathList.push(parsePolygonList(zipResponse))
    setPaths(pathList)
  }

  const findZoom = markers => {
    let minLat = 90
    let maxLat = -90
    let minLng = 180
    let maxLng = -180
    markers?.forEach(mark => {
      if (mark.lat < minLat) minLat = mark.lat
      if (mark.lat > maxLat) maxLat = mark.lat
      if (mark.lng < minLng) minLng = mark.lng
      if (mark.lng > maxLng) maxLng = mark.lng
    })
    const coordDiff = maxLat - minLat + (maxLng - minLng)
    let zoomCalc
    if (coordDiff === 0) zoomCalc = 12
    else if (coordDiff < 0.8) zoomCalc = 10
    else if (coordDiff < 1) zoomCalc = 9
    else if (coordDiff < 2) zoomCalc = 8
    else if (coordDiff < 4) zoomCalc = 7
    else zoomCalc = 6

    setZoom(zoomCalc)
  }

  const serviceAreaLocation = () => {
    if (currentServiceArea?.state) {
      return currentServiceArea.state
    }
    if (currentServiceArea?.address && currentServiceArea?.radius) {
      return (
        currentServiceArea.address +
        ' - ' +
        currentServiceArea.radius +
        ' ' +
        t('company_settings.card.miles')
      )
    }
    return ''
  }

  return (
    <Card className={classes.card} ref={gridSize}>
      <Box display="flex" flexDirection="row">
        <Typography classes={{ root: classes.cardTitle }}>
          {t('company_settings.card.service')}
        </Typography>
        <EditButton
          label={t('company_settings.buttons.show_more')}
          onClick={handleEditProfile}
        />
      </Box>
      {props.chips?.length > 0 && (
        <Box
          display="flex"
          className={classes.buttonsContainer}
          flexDirection="column"
        >
          <Typography classes={{ root: classes.cardSubtitle }}>
            {serviceAreaLocation()}
          </Typography>
          <GlobalChip
            chips={props.chips}
            selected={areaSelected}
            setSelected={setSelected}
            searchVisible={false}
            skipTranslate={true}
            parent="area"
            removeItem={() => {}}
          />

          {moreAreas && (
            <Typography classes={{ root: classes.moreAreas }}>
              {t('company_settings.card.more_areas')}
            </Typography>
          )}
        </Box>
      )}
      <Box height={300}>
        {props.chips ? (
          <Maps
            height={300}
            width={isRunningInIframe() ? 'auto' : mapContainerWidth}
            markers={markers}
            initialCenter={initialPosition}
            circle={centerRadius}
            onMarkerClick={() => {}}
            paths={paths}
            zoom={zoom}
          />
        ) : (
          <div className={classes.loadingMap}>
            <CircularProgress color="inherit" />
          </div>
        )}
      </Box>
    </Card>
  )
}
