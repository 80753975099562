export const invoices = () => {
  return {
    search_placeholder: 'Buscar',
    export_selected: 'Descargar',
    export_invoice_selected: 'Descargar',
    download_selected: 'Descargar Seleccionados',
    download_all: 'Descargar Todos',
    filter_counts: 'Contadores',
    filter_sites: 'Usar Mis Sitios',
    advanced_button: 'Búsqueda Avanzada',
    reset_button: 'Limpiar Filtros',
    counts: 'Contadores',
    data_export: 'Exportar Información',
    export_message:
      'Esto podría tomar unos minutos. Se le enviará el reporte mediante email. Por favor asegúrese de buscar en la carpeta de spam.',
    description_placeholder: 'Ingrese descripción',
    notes: 'Notas',
    notes_placeholder: 'Describe lo que hiciste en tu visita de servicio.',
    invoice_placeholder: 'Ingrese su #',
    invoice: 'factura',
    summary: 'Resumen',
    fill_message: 'Llene su factura y envíe para aprobación',
    paid_message: 'Pagado ',
    paid_by_message: 'con {type}',
    paid_on_message: 'el {date}',
    declined_message:
      'Ajuste su factura y vuelva a enviarla. Los detalles de rechazo se pueden encontrar en la auditoría.',
    submitted_message: 'Gracias! Su factura será revisada pronto!',
    invoice_number: 'Factura #',
    wo_number: 'WO #',
    wo_multiple: 'WO # Multiples',
    wo_completed: 'Trabajo completado',
    bill_to: 'Facturar a',
    nte: 'NTE $',
    nte_message:
      'El monto total supera el NTE. Ajuste su total para que sea más bajo que el NTE o comuníquese con su representante para que ajuste el NTE.',
    nte_exceed_title: 'Supera el valor NTE',
    nte_exceed_message: 'El total supera el valor NTE. ¿Quieres continuar?',
    service_notes_title: 'Notas de servicio',
    date_format: 'DD/MM/yyyy',
    date_ranges: {
      today: 'Hoy',
      yesterday: 'Ayer',
      this_month: 'Este Mes',
      last_month: 'Mes Pasado',
      last_two_months: 'Dos Últimos Meses',
      last_three_months: 'Tres Últimos Meses',
      last_six_months: 'Seis Últimos Meses'
    },
    in_actions: {
      create: 'Crear',
      submit: 'Enviar',
      add_labor: 'Añadir elemento de mano de obra',
      add_travel: 'Añadir elemento de viaje',
      add_material: 'Añadir artículo de materiales',
      add_freight: 'Añadir artículo de flete',
      add_other: 'Añadir otro artículo'
    },
    action_messages: {
      draft_saved: 'Borrador guardado',
      invoice_submitted: 'Factura enviada correctamente',
      invoice_submit_error: 'La factura no fue enviada',
      pending_approval: 'Pendiente de aprobación del cliente'
    },
    in_states: {
      open: 'Abierto',
      declined: 'Rechazado',
      sent: 'Enviado',
      approved: 'Aprobado',
      draft: 'Borrador',
      drafts: 'Borradores',
      not_submitted: 'No enviado',
      no_status: 'Sin Estado',
      total_open: 'Total de facturas abiertas',
      total_submitted: 'Total de facturas enviadas',
      submitted: 'Enviado',
      work_complete: 'Trabajo completo',
      not_available: 'No Disponible',
      receipt: 'Recibo',
      paid: 'Pagado',
      hold: 'Retenido',
      voided: 'Anulado'
    },
    audit_actions: {
      create: 'Factura generada a partir del trabajo terminado',
      edit: 'Editado',
      submit: 'Enviado',
      decline: 'Rechazado',
      approve: 'Aprobado',
      auto_create:
        'Recibo auto-generado por finalización de actividades mensuales',
      auto_approve: 'Recibo auto-aprobado basado en trabajos completos',
      paid: 'Pagado',
      hold: 'Retenido',
      voided: 'Anulado'
    },
    column_names: {
      client_name: 'Cliente',
      client_location: 'Ubicación',
      invoice: 'Factura #',
      won: 'Orden de Trabajo #',
      amount: 'Cantidad $',
      createDate: 'Fecha de creación',
      dueDate: 'Fecha de vencimiento',
      inStatus: 'Estado',
      terms: 'Términos',
      total_amount: 'Monto total',
      item: 'Item',
      description: 'Descripción',
      trade: 'Comercio',
      rate: 'Tarifa',
      tax: 'Impuesto',
      qty: 'Cantidad',
      item_amount: 'Subtotal',
      product_family: 'Familia de Producto',
      task: 'Tarea',
      date_sent: 'Fecha',
      scheduled_services_amount: 'Monto de Servicios Programados',
      percentage_paid: '% Pagado',
      amount_paid: 'Monto Pagado',
      balance_due: 'Saldo Por Pagar',
      payDate: 'Fecha de Pago',
      achCheckNumber: 'Cheque #'
    },
    items: {
      labor: 'Mano de Obra',
      travel: 'Viaje',
      material: 'Material',
      freight: 'Transporte',
      other: 'Otros',
      discount: 'Descuento',
      management: 'Tarifa de gestión',
      markup: 'Margen',
      overhead: 'Gastos generales y beneficios',
      rental: 'Tarifa de arriendo',
      shipping: 'Envío y manejo',
      scheduled: 'Servicios Programados',
      advance_payment: 'Pago Adelantado',
      refrigerant: 'Refrigerante'
    },
    toggle_labels: {
      all: 'Todos',
      custom: 'Personalizado',
      not_selected: 'No Seleccionado',
      select_all: 'Seleccionar Todos'
    },
    select_one: 'Seleccione',
    apply_advanced: 'Aplicar',
    activities: 'Actividades',
    audit: 'Auditoría',
    details: 'Detalles',
    due: 'Vencimiento',
    empty_message_title: 'Whoops..',
    empty_message:
      'No hay facturas en su cuenta. ¡Póngase en contacto con su empresa de gestión para obtener un nuevo trabajo!',
    empty_message_filters:
      'La consulta no arrojó resultados. Revise sus criterios de búsqueda e intente de nuevo.',
    please: 'Por favor',
    try: 'intente nuevamente',
    clear_search: 'Limpiar la Busqueda',
    sort: 'Ordenar',
    clear: 'Limpiar',
    highest: 'Mayor',
    lowest: 'Menor',
    oldest: 'Antiguo',
    newest: 'Reciente',
    attach: 'Adjunta a tu factura',
    add: 'Adjuntar',
    attach_error: 'Máx. 3 Archivos',
    pop_up_warning:
      'Oculte esta ventana emergente, pero entiendo que estos términos se aplicarán a todas las facturas a futuro.',
    messages: {
      max_records:
        'Aviso: La generación de informes está limitada a un máximo de 5000 registros. Reduzca el número de registros seleccionados.'
    },
    refrigerant_item: {
      type: 'Tipo de Refrigerante',
      service: 'Servicio',
      weight: 'Peso Total'
    }
  }
}
