export const companyProfile = () => {
  return {
    company_profile: 'Perfil de Compañía',
    info_insurance: 'Información de Compañía y Seguros',
    clients_trades: 'Clientes y Comercios',
    service_area: 'Agregar Área de Servicio',
    saved_service_area: 'Área de Servicios Agregadas',
    multiple_trades_assigned: 'Múltiples Oficios Asignados',
    service_area_idx: 'Área de Servicio',
    terms: 'Marco de Servicios',
    users: 'Usuarios',
    continue: 'Continuar',
    accept: 'Aceptar',
    read_terms: 'He leido el acuerdo de marco de servicios',
    export_pdf: 'Descargar',
    yes: 'Si',
    no: 'No',
    none: 'Ninguno',
    weekdays: 'Lunes - Viernes',
    week: 'Lunes - Domingo',
    weekend: 'Sábado - Domingo',
    saturday: 'Sábado',
    sunday: 'Domingo',
    hours24: '24 Horas',
    sample_check_format: 'Formato de Ejemplo de Cheque',
    payment_options: {
      check: 'Cheque',
      direct: 'Depósito Directo'
    },
    labels: {
      company_legal: 'Nombre legal de copañía',
      upload: 'Cargar logo de la compañía',
      country: 'País(es) de Operación',
      entity: 'Entidad Legal',
      business_hours: 'Teléfono de horario laboral',
      dba: 'Compañía DBA',
      full_dba: 'Compañía (En representación de)',
      after_hours: 'Teléfono para horario extendido',
      after_hours_business_phone: 'Teléfono para horario extendido',
      headquarters: 'Dirección de facturación de la empresa',
      dispatch: 'Correo para despacho de Orden de Trabajo',
      invoice_email: 'Correo para Citas/Factuación',
      address: 'Dirección de la compañía',
      days_operation: 'Días laborables',
      business_days_operation: 'Días laborables',
      times_operation: 'Horario laboral',
      days_operation_after: 'Días laborables con horario extendido',
      times_operation_after: 'Horario extendido',
      hours_of_operation: 'Horario laboral',
      upload_file: 'Cargar archivo',
      ein: 'Número de identificación del empleador',
      state: 'Estado(s) que la Compañía está registrada',
      contractor_licence: 'Licencia de contratista #',
      coi: 'Política COI #',
      comp: 'Póliza de compensación a empleados #',
      bank: 'Nombre de Banco',
      account_number: 'Número de cuenta bancaria',
      routing_number: 'Número de ruta bancaria',
      trades: 'Elija su(s) oficio(s)',
      standard_rate: 'Tarifa estándar ($/hora)',
      emergency_rate: 'Tarifa de emergencia ($/hora)',
      travel_cost: 'Costo de mobilización ($)',
      select_in_map:
        'Selecciona tu área de servicio usando los selectores de abajo.',
      no_users:
        'No ha agregado ningún usuario para su compañía.',
      no_users_message:
        'Los usuarios del Portal Web tendrán acceso a este portal para ver trabajos, actualizar formularios de servicios y crear facturas. Los usuarios de FieldTech Connect tendrán acceso a la aplicación móvil para capturar sus servicios de campo.',
      incomplete_users: 'Sus cambios no se han guardado. ¿Le gustaría continuar y salir de esta pantalla?',
      web_users: 'Usuarios del Portal Web',
      add_web_users: 'Agregar Más Usuarios del Portal Web',
      app_users: 'FieldTech Usuarios Móviles',
      add_app_users: 'Agregar Más Usuarios Móviles FTC',
      created: 'Creado',
      add_service_area: 'Agregar área de servicio',
      loading: 'Cargando archivo...',
      payment_options: 'Opciones de Pago',
      bank_check_payee: 'Cuenta de Pago'
    },
    title: {
      no_users: 'Faltan Usuarios',
      unsaved_changes: 'Cambios sin guardar'
    },
    placeholder: {
      company_legal: 'Ingrese el nombre de compañía',
      country: 'Seleccionar',
      entity: 'Seleccionar',
      business_hours: 'Ingrese el teléfono para horario normal',
      dba: 'Ingrese la compañía DBA',
      after_hours: 'Ingrese el teléfono para horario extendido',
      headquarters: 'Seleccionar',
      dispatch: 'Ingrese correo de despacho',
      invoice_email: 'Ingrese correo para Citas/Facturación',
      address: 'Ingrese la dirección',
      ein: 'Ingrese el número de identificación del empleador',
      select_required: 'Seleccionar',
      contractor_licence: 'Ingrese licencia #',
      coi: 'Ingrese el número de COI',
      comp: 'Ingrese el número de póliza de compensaciones',
      bank: 'Ingrese nombre del banco',
      account_number: 'Ingrese el número de cuenta',
      routing_number: 'Ingrese el número de ruta',
      month: 'Mes',
      year: 'Año',
      rate: 'Ingrese tarifa',
      cost: 'Ingrese costo $',
      no_service_area: 'Agregue su(s) área(s) de servicio',
      select_state: 'Seleccionar Estado',
      select_territory: 'Seleccionar Territorio',
      select_city: 'Seleccionar Ciudad',
      select_county: 'Seleccionar Condado',
      select_country: 'Seleccionar País',
      select_zip: 'Seleccionar código zip',
      assign_trade: 'Asignar Comercio',
      search: 'Buscar',
      search_address: 'Buscar Dirección',
      mile_radius: 'radio en millas',
      no_geo_coordinates: 'Las Geocoordenadas estarán disponibles pronto',
      open: 'Abierto',
      close: 'Cerrado',
      bank_check_payee: 'Ingrese la cuenta de pago'
    },
    message: {
      company_profile:
        'Puede volver en cualquier momento y editar su información.',
      terms:
        'Por favor revise y acepte los términos y condiciones de servicio.',
      rates:
        'Las tarifas ingresadas no son las tarifas acordadas, solo son sugeridas. Se proporcionará documentación adicional.'
    },
    upload: {
      w9: 'Cargar su forma W9',
      coi: 'Cargar su certificado de seguro',
      comp: 'Cargar su compensación para empleados',
      void: 'Cheque bancario anulado'
    },
    questions: {
      support_hours: 'Provee soporte 24 / 7?',
      trucks: '¿Cuántos camiones propiedad de la compañía tiene?',
      field_employees: '¿Cuantos empleados de campo?',
      administrative_employees: '¿Cuántos empleados administrativos?',
      years: '¿Cuántos años en el mercado ha estado su compañía?',
      subcontractors: '¿Utiliza subcontratistas?',
      coi: '¿Cuándo expira su COI?',
      comp: 'Fecha de vencimiento de la compensación a trabajadores',
      serve: '¿A qué cliente(s) brinda servicios?'
    },
    clients: {
      commercial: 'Comercial',
      industrial: 'Industrial',
      educational: 'Educativo',
      residential: 'Residencial',
      property_preservation: 'Preservación de la Propiedad',
      medical: 'Médico'
    },
    trades: {
      beverage_equipment: 'Equipo de bebidas',
      disaster_relief: 'Ayuda en casos de desastre',
      doors: 'Puertas',
      fire_systems: 'Sistemas contra incendios',
      floor_care: 'Cuidado de suelos y limpieza',
      flooring: 'Pisos',
      fuel_systems: 'Sistemas de combustible',
      gates: 'Puertas/Rejas',
      glass: 'Vidrio',
      kitchen_quipment: 'Equipamiento de cocina',
      landscaping: 'Paisajismo',
      locks: 'Cerraduras',
      parking_lot: 'Estacionamiento',
      pest_control: 'Control de plagas',
      refrigeration: 'Refrigeración',
      roof: 'Techo',
      snow_removal: 'Remoción de nieve',
      electrical: 'Eléctrico',
      plumbing: 'Plomería',
      hvac: 'Climatización',
      general_maintenance: 'Mantenimiento General',
      appliances: 'Electrodomésticos',
      painting: 'Pintura',
      carpentry: 'Carpintería',
      epoxy_flooring: 'Piso Epoxi',
      concrete: 'Hormigón',
      wall_coverings: 'Revestimientos de paredes',
      asset_protection: 'Protección de activos',
      masonry: 'Albañilería',
      eifs_stucco: 'Reparación de E.I.F.S./Estuco',
      fencing: 'Cercado'
    },
    services: {
      beverage_equipment: 'Equipo de bebidas',
      disaster_relief: 'Ayuda en casos de desastre',
      dock: 'Muelle/Niveladores',
      overhead_doors: 'Puertas basculantes',
      doors: 'Puertas',
      entrance_doors: 'Puertas de entrada',
      fire_alarm: 'Alarma de incendio',
      fire_extinguisher: 'Extintor de incendios',
      fire_systems: 'Sistemas contra incendios',
      fire_back_flow: 'Sistemas contra incendios - Flujo inverso',
      floor_care: 'Cuidado del suelo',
      janitorial: 'Conserjería',
      windows: 'Ventanas',
      flooring: 'Instalación de pisos',
      automatic_tank: 'Indicador de tanque automático',
      compressed_gas: 'Gas Natural Comprimido',
      fuel_dispenser: 'Dispensador de combustible',
      fuel_environmental: 'Combustible Ambiental',
      underground_storage: 'Tanque de almacenamiento subterráneo',
      gates: 'Puertas/Rejas',
      glass: 'Vidrio',
      film: 'Láminas de seguridad',
      kitchen_equipment: 'Equipamiento de cocina',
      exhaust_cleaning: 'Limpieza de campana extractora',
      landscaping: 'Paisajismo/jardín',
      hardscaping: 'Paisajismo/permanente',
      irrigation: 'Riego',
      locks: 'Cerraduras',
      safes: 'Cajas fuertes',
      parking_lot: 'Estacionamiento',
      pest_control: 'Control de plagas',
      refrigeration: 'Refrigeración',
      roof: 'Techo',
      snow_removal: 'Remoción de nieve',
      exterior_lighting: 'Iluminación exterior',
      interior_lighting: 'Iluminación interior',
      exterior_signage: 'Señalización exterior',
      major_electrical: 'Electricidad mayor',
      generators: 'Generadores',
      minor_electrical: 'Electricidad menor',
      back_flow: 'Flujo de retorno',
      minor_plumbing: 'Plomería menor',
      water_systems: 'Sistemas de agua',
      grease_traps: 'Trampas de Grasa',
      septic: 'Séptico',
      well_systems: 'Sistemas de pozos',
      major_plumbing: 'Plomería mayor',
      water_heater: 'Calentador de agua',
      hvac: 'Climatización',
      temperature_controls: 'Controles de temperatura',
      concrete: 'Concreto',
      general_maintenance: 'Mantenimiento General',
      blinds: 'Persianas',
      fencing: 'Cercado/Puertas',
      paint: 'Pintura',
      foundation: 'Cimientos',
      power_washing: 'Lavado a presión',
      cabinets: 'Gabinetes/Encimeras',
      inspections: 'Inspecciones',
      fireplace: 'Chimeneas',
      appliances: 'Electrodomésticos',
      exterior_painting: 'Pintura exterior',
      interior_painting: 'Pintura de interiores',
      general_carpentry: 'Carpintería General',
      cabinets_carpentry: 'Gabinetes',
      trim_work: 'Recorte',
      tile: 'Mosaico',
      framing: 'Enmarcado',
      forming: 'Formado',
      vct: 'VCT',
      lvt: 'LVT',
      carpet_tile: 'Alfombra de mosaico',
      carpet: 'Alfombra',
      epoxy_flooring: 'Piso Epoxi',
      interior_flatwork: 'Reparación de pisos',
      sidewalks: 'Veredas',
      driveways: 'Carreteras',
      foundations: 'Cimientos',
      retaining_wall: 'Paredes de retenimiento',
      concrete_repair: 'Reparación de concreto',
      wall_coverings: 'Revestimientos de paredes',
      asset_protection: 'Protección de activos',
      masonry: 'Albañilería',
      eifs_stucco: 'Reparación de E.I.F.S./Estuco',
      installation: 'Instalación',
      repair: 'Reparación'
    },
    area: {
      city: 'Ciudad',
      zip: 'Zip',
      county: 'Condado',
      trades: 'Comercio'
    },
    error: {
      email: 'El Correo Electrónico introducido no es válido',
      file_size: 'El tamaño del archivo no debe ser mayor a {size}MB',
      file_resolution: 'La resolución del archivo debe ser al menos {res}px',
      file_aspect_ratio:
        'La relación de aspecto del archivo debe estar entre 1:1 y 16:9',
      hours_operation: 'El horario laboral es incorrecto',
      general_error:
        'Ha ocurrido un problema al completar su petición. Por favor contacte a su administrador',
      user_error: 'Este nombre de usuario ya fue tomado. Por favor escoja otro',
      roles_error: 'Seleccione Título',
      phone: 'Ingrese un número de telefono valido'
    },
    roles_card: {
      and: 'y',
      manage: 'Administra',
      workorders: 'órdenes de Trabajo',
      invoices: 'facturas',
      company_settings: 'configuración de compañía',
      account_settings: 'configuración de cuenta',
      locations: 'locaciones',
      users: 'usuarios',
      view_only: 'Solo Vista',
      coming_soon: 'Muy Pronto'
    }
  }
}
