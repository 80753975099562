export const navBar = () => {
  return {
    work_orders: 'Órdenes de Trabajo',
    invoices: 'Facturas',
    sites: 'Sitios',
    proposals: 'Propuestas',
    manage_account: 'Administrar Cuenta',
    account_settings: 'Configuración de la Cuenta',
    company_settings: 'Configuración de la Empresa',
    client_company_settings: 'Configuración de la Empresa Cliente',
    help: 'Ayuda',
    send_feedback: 'Enviar Comentarios',
    log_out: 'Cerrar Sesión',
    new_work_snack: 'Existen nuevas órdenes de trabajo: WO# {wo_number}',
    update_work_snack: 'Existen nuevas actividades para la página de Órdenes de trabajo',
    update_invoice_snack: 'Existen nuevas actividades para la página de Facturas',
    refresh_message: 'REFRESCAR para ver los cambios en esta lista'
  }
}
