import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  FilledInput,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  makeStyles
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  fieldTitle: {
    marginBottom: '5px',
    marginLeft: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  root: {
    '& .MuiFilledInput-input': {
      border: `1px solid ${theme.colors.signInButton.background}`,
      color: `${theme.colors.iconBlue} !important`,
      backgroundColor: theme.colors.signInButton.background,
      cursor: 'pointer',
      paddingTop: '15px',
      paddingBottom: '15px',
      borderRadius: '8px'
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.colors.signInButton.background,
      cursor: 'pointer'
    },
    '& .MuiFilledInput-underline:before': {
      border: 'transparent'
    },
    '& .MuiFilledInput-underline:after': {
      border: 'transparent'
    },
    '& .MuiFilledInput-multiline': {
      paddingTop: '15px',
      paddingBottom: '15px'
    },
    marginBottom: '12px'
  },
  selectedItem: {
    '& .MuiFilledInput-input': {
      fontSize: '14px',
      color: `${theme.colors.basicDisabledButtonColor} !important`,
      lineHeight: '20px'
    }
  },
  placeholder: {
    '& .MuiFilledInput-input': {
      fontSize: '14px',
      color: `${theme.colors.placeholder} !important`,
      lineHeight: '20px'
    }
  },
  select: {
    fontSize: '16px',
    width: '100%',
    borderRadius: '8px'
  },
  menuItem: {
    '& .MuiListItemText-primary': {
      fontSize: '14px',
      paddingBottom: '5px',
      maxWidth: '200px',
      textWrap: 'wrap'
    }
  },
  checkIcon: {
    color: theme.colors.checkBlue
  },
  taskList: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px'
  },
  selectedTask: {
    display: 'flex',
    alignContent: 'center'
  },
  dotIcon: {
    fontSize: '8px',
    paddingRight: '15px',
    color: theme.colors.workOrders.tab.duedate,
    alignItems: 'center',
    alignSelf: 'center',
    paddingBottom: '2px'
  },
  item: {
    color: theme.colors.workOrders.counts,
    fontSize: '14px',
    fontWeight: '400',
    marginBottom: '0px',
    marginLeft: '5px'
  },
  mandatory: {
    color: theme.colors.errorColor
  }
}))

export default function DropDownSelector(props) {
  const {
    section,
    questionId,
    question,
    answer,
    options,
    onUpdate,
    readOnly,
    mandatory
  } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const [valueSelected, setValueSelected] = useState([])

  useEffect(() => {
    const selectedItems = options
      .filter(option => option.selected)
      .map(option => option.name)

    setValueSelected(selectedItems ?? [])
  }, [])

  useEffect(() => {
    const selectedItems = question?.options.map(option =>
      valueSelected.includes(option)
    )
    onUpdate(section, questionId, selectedItems)
  }, [valueSelected])

  const handleChange = event => {
    const {
      target: { value }
    } = event
    setValueSelected(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }
  const renderSelectedValues = () => {
    if (valueSelected?.length > 0) {
      return question?.options.map(
        selectedItem =>
          valueSelected?.includes(selectedItem) && (
            <div key={selectedItem} className={classes.menuItem}>
              {selectedItem}
            </div>
          )
      )
    } else {
      return (
        <div className={classes.menuItem}>{t('general.labels.select')}</div>
      )
    }
  }

  return (
    <Box className={classes.selectContainer}>
      {!readOnly ? (
        <div>
          <FormLabel
            component="legend"
            className={`${classes.fieldTitle} ${
              !readOnly && mandatory ? classes.mandatory : ''
            }`}
          >
            {question.title}
          </FormLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple={question.selection_type === 'multiple_selections'}
            value={valueSelected}
            onChange={handleChange}
            input={
              <FilledInput
                className={`${classes.root} ${
                  valueSelected ? (valueSelected.length === 0 ? classes.placeholder : classes.selectedItem) : ''
                } ${classes.select}`}
                disableUnderline
              />
            }
            renderValue={renderSelectedValues}
            displayEmpty
            inputProps={{
              'aria-label': 'Select',
              placeholder: t('general.labels.select')
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {options.map(item => (
              <MenuItem key={item.name} value={item.name}>
                <ListItemText
                  className={classes.menuItem}
                  primary={item.name}
                />
                {valueSelected?.indexOf(item.name) > -1 && (
                  <FontAwesomeIcon
                    icon={['fas', 'check']}
                    className={classes.checkIcon}
                  />
                )}
              </MenuItem>
            ))}
          </Select>
        </div>
      ) : (
        answer?.some(element => element) && (
          <div>
            <FormLabel component="legend" className={classes.fieldTitle}>
              {question.title}
            </FormLabel>
            {options.map(
              item =>
                item.selected && (
                  <Grid key={item.name} container className={classes.taskList}>
                    <Grid item>
                      <FormLabel component="legend" className={classes.item}>
                        <span><FontAwesomeIcon
                          icon={['fas', 'circle-small']}
                          className={classes.dotIcon}
                        /></span>
                        {item.name}
                      </FormLabel>
                    </Grid>
                  </Grid>
                )
            )}
          </div>
        )
      )}
    </Box>
  )
}
