/* eslint-disable no-undef */
import React from 'react'
import Autocomplete from 'react-google-autocomplete'
/** Material UI **/
import {
  makeStyles
} from '@material-ui/core'
import FormControl from '@mui/material/FormControl'

const useStyles = makeStyles(() => ({
  bottomSpacing: {
    marginBottom: 10
  }
}))

const options = {
  types: ['address'],
  listViewDisplayed: 'auto',
  componentRestrictions: { country: 'us' },
  fields: ['']
}

export default function AddressAutocomplete(props) {
  const classes = useStyles()

  const handleSelect = (place) => {
    props.onSelect(place)
  }

  return (
    <FormControl variant="standard" fullWidth={true}>
      <Autocomplete
        {...props}
        apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
        onPlaceSelected={(place) => {
          handleSelect(place)
        }}
        style={{
          height: 39,
          borderRadius: 45,
          paddingLeft: 10,
          border: '1px solid lightgray',
          fontSize: 12,
          marginTop: 26
        }}
        options={options}
      />

      <div className={classes.bottomSpacing}></div>
    </FormControl>
  )
}
