export const forgotPassword = () => {
  return {
    title: 'FORGOT PASSWORD',
    email_sent: 'PASSWORD RESET EMAIL SENT',
    subtitle_1: 'Enter the email address that was used to create your account.',
    subtitle_2:
      'Your password reset email is on its way.  Be sure to check your spam or junk folders if you do not receive it.',
    email: 'Email',
    send: 'Send',
    back_to_log_in: 'Back to login',
    back: 'Back',
    email_not_found: 'Sorry, your email is not in our system'
  }
}
