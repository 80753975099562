import { createSlice } from '@reduxjs/toolkit'

const initialWorkOrdersState = {
  etaUpdateLog: null
}

const workOrdersSlice = createSlice({
  name: 'workOrders',
  initialState: initialWorkOrdersState,
  reducers: {
    setEtaUpdateLog (state, action) {
      state.etaUpdateLog = action.payload
    }
  }
})

export const { setEtaUpdateLog } = workOrdersSlice.actions

export const selectEta = (state) => state?.workOrders?.etaUpdateLog

export default workOrdersSlice.reducer
