import React, { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// mui components
import {
  Step,
  StepConnector,
  StepLabel,
  Typography,
  Box,
  Grid
} from '@material-ui/core'
import { auditTrailStyles } from '../../styles/classes/WorkOrderClasses'
import { AuditUpdateEditor } from './AuditUpdateEditor'
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw
} from 'draft-js'
import Connector from '../../assets/images/message_connector.svg'
import Reply from '../../assets/images/reply_icon.svg'
import Edit from '../../assets/images/edit_icon.svg'
import { HighlightOff } from '@material-ui/icons'
import { toBase64, getOriginatingCompanyName } from '../../lib/Global'
import { useSelector } from 'react-redux'
import {
  createMessage,
  editMessage,
  deleteMessageFile,
  createMessageFile,
  editExternalMessage,
  createExternalMessage
} from '../../services/ApiService'
import parse from 'html-react-parser'
import draftToHtml from 'draftjs-to-html'
import { FilePreview } from '../form/FilePreview'
import { woLogType } from '../../lib/Constants'

export const AuditStep = props => {
  const {
    event,
    idx,
    length,
    editorFlag,
    setEditorFlag,
    getAuditMessages,
    tabValue,
    setReady,
    woLength
  } = props
  const { t } = useTranslation()
  const boxRef = useRef()

  const [files, setFiles] = useState([])
  const [actualFiles, setActualFiles] = useState([])
  const [oldFiles, setOldFiles] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editor, setEditor] = useState()
  const [auditData, setAudit] = useState()
  const [source, setSource] = useState('internal')
  const [newHeight, setNewHeight] = useState(0)
  const userStore = useSelector(state => state.auth.user)

  const classes = auditTrailStyles({ boxHeight: `${newHeight}px` })()

  const handleEdit = (idx2, content, files, actualSource) => {
    if (editor === idx2) return
    if (editor && editor?.includes('update')) {
      setEditor()
      return
    }
    setEditor(`${idx2}-update`)
    if (files) {
      setOldFiles(files)
      setActualFiles(files)
    }
    if (content) {
      const blocksFromHTML = convertFromHTML(content)
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      setEditorState(EditorState.createWithContent(state))
    }
    if (actualSource) setSource(actualSource)
  }

  useEffect(() => {
    setAudit(event)
  }, [event, tabValue])

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (boxRef.current) {
        setNewHeight(boxRef.current.offsetHeight + 17)
      }
    }, 100)
    return () => {
      clearTimeout(timer1)
    }
  }, [boxRef.current?.offsetHeight])

  useEffect(() => {
    if (editor && editor.includes('update')) return
    setEditorState(EditorState.createEmpty())
    setFiles([])
    setActualFiles([])
  }, [editor])

  const transformFiles = async (id, companyId, workOrderId, actualSource) => {
    try {
      const documentIds = []
      for (const file of oldFiles) {
        const found = actualFiles.find(actual => actual.id === file.id)
        if (!found) await deleteMessageFile(id, file.id)
      }
      for (const actual of actualFiles) {
        documentIds.push(actual.external_id)
      }
      for (const file of files) {
        const result = await toBase64(file)
        const extension = file.name.split('.').pop()
        const response = await createMessageFile(id, {
          data: result,
          contentType: extension,
          name: file.name,
          date_created: Math.floor(new Date().getTime() / 1000),
          companyId,
          workOrderId,
          source: actualSource
        })
        if (actualSource === 'external' && typeof response === 'string') {
          documentIds.push(response)
        }
      }
      return documentIds
    } catch (error) {
      console.error('Error transforming files: ', error)
    }
  }

  const getHTMLcontent = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState, null, null, null)
    const styledHTML = markup
      .replaceAll('<br>', '<p>&nbsp;</p>')
      .replaceAll('<span', '<p')
      .replaceAll('</span>', '</p>')
    const htmlNode = document.createElement('div')
    htmlNode.innerHTML = styledHTML
    htmlNode.querySelectorAll('*').forEach(function (node) {
      node.removeAttribute('style')
    })
    return htmlNode.innerHTML
  }

  const handleUpdateNonAssociatedMessage = async () => {
    setReady(false)
    try {
      const updatedMessage = {
        ...auditData,
        content: getHTMLcontent(),
        timestamp: Math.floor(new Date().getTime() / 1000),
        source: source
      }
      delete updatedMessage.id
      delete updatedMessage.replies
      delete updatedMessage.time
      delete updatedMessage.files
      delete updatedMessage.messageId
      delete updatedMessage.workOrderLogId
      await editMessage(auditData.id, updatedMessage)
      const documentIds = await transformFiles(
        auditData.id,
        auditData.companyId,
        auditData.workOrderId,
        updatedMessage.source
      )
      if (source === 'external') {
        updatedMessage.id = auditData.id
        updatedMessage.documentIds = documentIds
        await editExternalMessage(auditData.id, updatedMessage)
      }
      setReady(true)
      saveAndRetreive()
    } catch (error) {
      console.error(error)
      setReady(true)
    }
  }

  const handleSaveAssociatedMessage = async () => {
    setReady(false)
    try {
      const newMessage = {
        content: getHTMLcontent(),
        userName: `${userStore.userInfo.firstName} ${userStore.userInfo.lastName}`,
        companyName: userStore.userInfo.company_name,
        source,
        timestamp: Math.floor(new Date().getTime() / 1000),
        type:
          event.type === 'checkIn'
            ? 'clocked_in'
            : event.type === 'checkOut'
            ? 'clocked_out'
            : event.type,
        workOrderId: event.id,
        userId: userStore.userId,
        companyId: userStore.userInfo.originating_company
      }
      const createMessageRes = await createMessage(newMessage)
      const documentIds = await transformFiles(
        createMessageRes.id,
        userStore.userInfo.company_id,
        event.id,
        source
      )
      if (source === 'external') {
        newMessage.id = createMessageRes.id
        newMessage.documentIds = documentIds
        newMessage.client_date = moment(new Date()).format(
          t('general.date_formats.audit_trail_format')
        )
        await createExternalMessage(newMessage)
      }
      setReady(true)
      saveAndRetreive()
    } catch (error) {
      console.error(error)
      setReady(true)
    }
  }

  const handleNonAssociatedReply = async () => {
    setReady(false)
    try {
      const newMessage = {
        content: getHTMLcontent(),
        userName: `${userStore.userInfo.firstName} ${userStore.userInfo.lastName}`,
        companyName: userStore.userInfo.company_name,
        source: event.source,
        timestamp: Math.floor(new Date().getTime() / 1000),
        messageId: event.id,
        type:
          event.type === 'checkIn'
            ? 'clocked_in'
            : event.type === 'checkOut'
            ? 'clocked_out'
            : event.type,
        workOrderId: event.workOrderId,
        userId: userStore.userId,
        companyId: userStore.userInfo.originating_company
      }
      const createMessageRes = await createMessage(newMessage)
      const documentIds = await transformFiles(
        createMessageRes.id,
        userStore.userInfo.company_id,
        event.workOrderId,
        event.source
      )
      if (event.source === 'external') {
        newMessage.id = createMessageRes.id
        newMessage.documentIds = documentIds
        newMessage.client_date = moment(new Date()).format(
          t('general.date_formats.audit_trail_format')
        )
        await createExternalMessage(newMessage)
      }
      setReady(true)
      saveAndRetreive()
    } catch (error) {
      console.error(error)
      setReady(true)
    }
  }

  const handleUpdateAssociatedMessage = async () => {
    setReady(false)
    try {
      const splittedFlag = editor.split('-')
      const foundMessage = auditData.messages[parseInt(splittedFlag[0])]
      if (foundMessage) {
        const updatedMessage = {
          ...foundMessage,
          content: getHTMLcontent(),
          timestamp: Math.floor(new Date().getTime() / 1000)
        }
        delete updatedMessage.id
        delete updatedMessage.replies
        delete updatedMessage.time
        delete updatedMessage.files
        delete updatedMessage.messageId
        await editMessage(foundMessage.id, updatedMessage)
        const documentIds = await transformFiles(
          foundMessage.id,
          foundMessage.companyId,
          foundMessage.workOrderId,
          updatedMessage.source
        )
        if (source === 'external') {
          updatedMessage.id = foundMessage.id
          updatedMessage.documentIds = documentIds
          await editExternalMessage(foundMessage.id, updatedMessage)
        }
        setReady(true)
        saveAndRetreive()
      }
    } catch (error) {
      console.error(error)
      setReady(true)
    }
  }

  const handleAssociatedReply = async () => {
    setReady(false)
    try {
      const foundMessage = auditData.messages[editor]
      if (foundMessage) {
        const newMessage = {
          content: getHTMLcontent(),
          userName: `${userStore.userInfo.firstName} ${userStore.userInfo.lastName}`,
          companyName: userStore.userInfo.company_name,
          source: foundMessage.source,
          timestamp: Math.floor(new Date().getTime() / 1000),
          messageId: foundMessage.id,
          type: foundMessage.type,
          workOrderId: event.id,
          userId: userStore.userId,
          companyId: userStore.userInfo.originating_company
        }
        const createMessageRes = await createMessage(newMessage)
        const documentIds = await transformFiles(
          createMessageRes.id,
          userStore.userInfo.company_id,
          event.id,
          foundMessage.source
        )
        if (foundMessage.source === 'external') {
          newMessage.id = createMessageRes.id
          newMessage.documentIds = documentIds
          newMessage.client_date = moment(new Date()).format(
            t('general.date_formats.audit_trail_format')
          )
          await createExternalMessage(newMessage)
        }
        setReady(true)
        saveAndRetreive()
      }
    } catch (error) {
      console.error(error)
      setReady(true)
    }
  }

  const saveAndRetreive = () => {
    getAuditMessages()
    setEditorFlag()
    setEditor()
  }

  const supportedImageFormats = ['png', 'jpeg', 'jpg', 'svg']

  const isImageFormat = contentType => {
    return supportedImageFormats.some(format => contentType.includes(format))
  }

  const handleDownloadFile = (content, name) => {
    const a = document.createElement('a')
    a.href = content
    a.download = name
    a.click()
  }

  return (
    <Step
      key={idx}
      className={
        idx !== length
          ? classes.stepper
          : auditData?.messages?.length > 0
          ? classes.stepperLast
          : classes.stepperNoConnector
      }
    >
      <div className={classes.trailContainer} key={idx}>
        <StepLabel
          ref={boxRef}
          StepIconComponent={() => (
            <div
              className={`${classes.roundItem} ${classes[auditData?.type]}`}
            />
          )}
        >
          <div className={classes.labelsDiv}>
            {/* Audit Trail not associated message */}
            {auditData?.type === woLogType.MESSAGE && (
              <Box className={classes.messageBox} marginTop="10px">
                {editor === 'message-update' ? (
                  <Box className={classes.replyEditorBox}>
                    <AuditUpdateEditor
                      editorState={editorState}
                      files={files}
                      setEditorState={setEditorState}
                      setFiles={setFiles}
                      reply
                      handleSave={handleUpdateNonAssociatedMessage}
                      source={source}
                      setSource={setSource}
                      actualFiles={actualFiles}
                      setActualFiles={setActualFiles}
                    />
                  </Box>
                ) : (
                  <div>
                    <Typography margin={0} className={classes.messageTypo}>
                      {parse(auditData?.content)}
                    </Typography>
                    <Typography className={classes.author}>
                      {`${auditData?.userName} - ${moment(
                        new Date(auditData?.timestamp * 1000)
                      ).format(t('general.date_formats.audit_trail_format'))}`}
                    </Typography>
                    <Typography className={classes.environment}>
                      {auditData?.source === 'internal'
                        ? t('work_orders.audit_trail.internal')
                        : getOriginatingCompanyName(userStore.userInfo)}
                    </Typography>
                    <Grid container width="260px">
                      {auditData?.files?.map((file, fid) => (
                        <Grid key={auditData?.id + '-' + fid} xs={4} mt="5px">
                          {isImageFormat(file.contentType) ? (
                            <Box
                              onClick={() =>
                                handleDownloadFile(file.url, file.name)
                              }
                              className={classes.imageBox}
                            >
                              <img
                                src={file.url}
                                className={classes.imageAttach}
                              />
                            </Box>
                          ) : (
                            <div
                              onClick={() =>
                                handleDownloadFile(file.url, file.name)
                              }
                              className={classes.fileDiv}
                            >
                              <FilePreview
                                filePath={file.url}
                                fileType={file.contentType}
                                fileName={auditData?.id + '-box-' + fid}
                              />
                            </div>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
                {auditData?.replies?.map((reply, idx3) => (
                  <Box key={idx3} className={classes.replyBox}>
                    <Typography className={classes.messageTypo}>
                      {parse(reply.content)}
                    </Typography>
                    <Typography className={classes.author}>
                      {reply.userName}
                      &nbsp;{t('work_orders.audit_trail.at')}&nbsp;
                      {reply.source === 'internal'
                        ? t('work_orders.audit_trail.internal')
                        : getOriginatingCompanyName(userStore.userInfo)}
                      &nbsp;
                      {moment(new Date(reply.timestamp * 1000)).format(
                        t('general.date_formats.audit_trail_format')
                      )}
                    </Typography>
                    <Grid container width="260px">
                      {reply?.files?.map((file, fid) => (
                        <Grid key={reply?.id + '-' + fid} xs={4} mt="5px">
                          {isImageFormat(file.contentType) ? (
                            <Box
                              onClick={() =>
                                handleDownloadFile(file.url, file.name)
                              }
                              className={classes.imageBox}
                            >
                              <img
                                src={file.url}
                                className={classes.imageAttach}
                              />
                            </Box>
                          ) : (
                            <div
                              onClick={() =>
                                handleDownloadFile(file.url, file.name)
                              }
                              className={classes.fileDiv}
                            >
                              <FilePreview
                                filePath={file.url}
                                fileType={file.contentType}
                                fileName={reply?.id + '-box-' + fid}
                              />
                            </div>
                          )}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
                <div className={classes.replyEditDiv}>
                  <div
                    className={classes.buttonDiv}
                    onClick={() => {
                      if (editor === 'message') setEditor()
                      else setEditor('message')
                    }}
                  >
                    {editor === 'message' ? (
                      <HighlightOff className={classes.highlightoff} />
                    ) : (
                      <img className={classes.replyIcon} src={Reply} />
                    )}
                    <Typography
                      className={
                        editor === 'message'
                          ? classes.buttonlabelRed
                          : classes.buttonlabel
                      }
                    >
                      {editor === 'message'
                        ? t('work_orders.audit_trail.cancel_button')
                        : t('work_orders.audit_trail.reply')}
                    </Typography>
                  </div>
                  {auditData.userId === userStore.userId && (
                    <div
                      className={
                        editor === 'message'
                          ? classes.buttonBlurryDiv
                          : classes.buttonDiv
                      }
                      onClick={() =>
                        handleEdit(
                          'message',
                          auditData?.content,
                          auditData.files,
                          auditData.source
                        )
                      }
                    >
                      {editor && editor?.includes('update') ? (
                        <HighlightOff className={classes.highlightoff} />
                      ) : (
                        <img src={Edit} />
                      )}
                      <Typography
                        className={
                          editor && editor?.includes('update')
                            ? classes.buttonlabelRed
                            : classes.buttonlabel
                        }
                      >
                        {editor && editor?.includes('update')
                          ? t('work_orders.audit_trail.cancel_button')
                          : t('work_orders.audit_trail.edit')}
                      </Typography>
                    </div>
                  )}
                </div>
                {editor === 'message' && (
                  <Box className={classes.replyEditorBox}>
                    <AuditUpdateEditor
                      editorState={editorState}
                      files={files}
                      setEditorState={setEditorState}
                      setFiles={setFiles}
                      reply
                      handleSave={handleNonAssociatedReply}
                      source={source}
                      setSource={setSource}
                      actualFiles={actualFiles}
                      setActualFiles={setActualFiles}
                    />
                  </Box>
                )}
              </Box>
            )}
            {/* Audit Trail title */}
            {auditData?.type !== woLogType.MESSAGE && (
              <Typography className={classes.type}>
                {auditData?.type === woLogType.CANCELLED
                  ? t('work_orders.audit_trail.cancelled').replace(
                      '{v1}',
                      auditData?.trip
                    )
                  : t(`work_orders.audit_trail.${auditData?.type}`)}
                {woLength > 1
                  ? ` (${t('work_orders.sa_abbr')} ${auditData?.trip})`
                  : ''}
              </Typography>
            )}
            {/* Audit Trail author and date */}
            {auditData?.type !== woLogType.MESSAGE && (
              <Typography className={classes.author}>
                {userStore.userInfo.company_name +
                  ` - ${moment(new Date(auditData?.time * 1000)).format(
                    t('general.date_formats.audit_trail_format')
                  )}`}
              </Typography>
            )}
            {/* Audit Trail associated messages */}
            <Box className={classes.messagesBox}>
              {auditData?.messages?.length ? (
                <Box key={idx} className={classes.messageBox}>
                  <img className={classes.connector} src={Connector} />
                  {auditData?.messages.map((message, idx2) => (
                    <Box key={idx2} marginBottom="15px">
                      {editor === idx2 + '-update' ? (
                        <Box className={classes.replyEditorBox}>
                          <AuditUpdateEditor
                            editorState={editorState}
                            files={files}
                            setEditorState={setEditorState}
                            setFiles={setFiles}
                            reply
                            handleSave={handleUpdateAssociatedMessage}
                            source={source}
                            setSource={setSource}
                            actualFiles={actualFiles}
                            setActualFiles={setActualFiles}
                          />
                        </Box>
                      ) : (
                        <div>
                          <Typography className={classes.messageTypo}>
                            {parse(message.content)}
                          </Typography>
                          <Typography className={classes.author}>
                            {message?.userName +
                              '-' +
                              moment(new Date(message.timestamp * 1000)).format(
                                t('general.date_formats.audit_trail_format')
                              )}
                          </Typography>
                          <Typography className={classes.environment}>
                            {message.source === 'internal'
                              ? t('work_orders.audit_trail.internal')
                              : getOriginatingCompanyName(userStore.userInfo)}
                          </Typography>
                          <Grid container width="260px">
                            {message?.files?.map((file, fid) => (
                              <Grid
                                key={message?.id + '-' + fid}
                                xs={4}
                                mt="5px"
                              >
                                {isImageFormat(file.contentType) ? (
                                  <Box
                                    onClick={() =>
                                      handleDownloadFile(file.url, file.name)
                                    }
                                    className={classes.imageBox}
                                  >
                                    <img
                                      src={file.url}
                                      className={classes.imageAttach}
                                    />
                                  </Box>
                                ) : (
                                  <div
                                    onClick={() =>
                                      handleDownloadFile(file.url, file.name)
                                    }
                                    className={classes.fileDiv}
                                  >
                                    <FilePreview
                                      filePath={file.url}
                                      fileType={file.contentType}
                                      fileName={message?.id + '-box-' + fid}
                                    />
                                  </div>
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      )}
                      {message?.replies.map((reply, idx3) => (
                        <Box key={idx3} className={classes.replyBox}>
                          <Typography className={classes.messageTypo}>
                            {parse(reply.content)}
                          </Typography>
                          <Typography className={classes.author}>
                            {reply.userName}
                            &nbsp;{t('work_orders.audit_trail.at')}&nbsp;
                            {reply.source === 'internal'
                              ? t('work_orders.audit_trail.internal')
                              : getOriginatingCompanyName(userStore.userInfo)}
                            &nbsp;
                            {moment(new Date(reply.timestamp * 1000)).format(
                              t('general.date_formats.audit_trail_format')
                            )}
                          </Typography>
                          <Grid container width="260px">
                            {reply?.files?.map((file, fid) => (
                              <Grid key={reply?.id + '-' + fid} xs={4} mt="5px">
                                {isImageFormat(file.contentType) ? (
                                  <Box
                                    onClick={() =>
                                      handleDownloadFile(file.url, file.name)
                                    }
                                    className={classes.imageBox}
                                  >
                                    <img
                                      src={file.url}
                                      className={classes.imageAttach}
                                    />
                                  </Box>
                                ) : (
                                  <div
                                    onClick={() =>
                                      handleDownloadFile(file.url, file.name)
                                    }
                                    className={classes.fileDiv}
                                  >
                                    <FilePreview
                                      filePath={file.url}
                                      fileType={file.contentType}
                                      fileName={reply?.id + '-box-' + fid}
                                    />
                                  </div>
                                )}
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      ))}
                      <div className={classes.replyEditDiv}>
                        <div
                          className={classes.buttonDiv}
                          onClick={() => {
                            if (editor === idx2) setEditor()
                            else setEditor(idx2)
                          }}
                        >
                          {editor === idx2 ? (
                            <HighlightOff className={classes.highlightoff} />
                          ) : (
                            <img className={classes.replyIcon} src={Reply} />
                          )}
                          <Typography
                            className={
                              editor === idx2
                                ? classes.buttonlabelRed
                                : classes.buttonlabel
                            }
                          >
                            {editor === idx2
                              ? t('work_orders.audit_trail.cancel_button')
                              : t('work_orders.audit_trail.reply')}
                          </Typography>
                        </div>
                        {message.userId === userStore.userId && (
                          <div
                            className={
                              editor === idx2
                                ? classes.buttonBlurryDiv
                                : classes.buttonDiv
                            }
                            onClick={() =>
                              handleEdit(
                                idx2,
                                message.content,
                                message.files,
                                message.source
                              )
                            }
                          >
                            {editor && editor?.includes('update') ? (
                              <HighlightOff className={classes.highlightoff} />
                            ) : (
                              <img src={Edit} />
                            )}
                            <Typography
                              className={
                                editor && editor?.includes('update')
                                  ? classes.buttonlabelRed
                                  : classes.buttonlabel
                              }
                            >
                              {editor && editor?.includes('update')
                                ? t('work_orders.audit_trail.cancel_button')
                                : t('work_orders.audit_trail.edit')}
                            </Typography>
                          </div>
                        )}
                      </div>
                      {editor === idx2 && (
                        <Box className={classes.replyEditorBox}>
                          <AuditUpdateEditor
                            editorState={editorState}
                            files={files}
                            setEditorState={setEditorState}
                            setFiles={setFiles}
                            reply
                            handleSave={handleAssociatedReply}
                            source={source}
                            setSource={setSource}
                          />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              ) : auditData?.type !== woLogType.MESSAGE ? (
                <Box className={classes.shareBox}>
                  {editorFlag === idx ? (
                    <AuditUpdateEditor
                      key="newAssociatedMessage"
                      editorState={editorState}
                      files={files}
                      setEditorState={setEditorState}
                      setFiles={setFiles}
                      handleSave={handleSaveAssociatedMessage}
                      source={source}
                      setSource={setSource}
                    />
                  ) : (
                    <Typography
                      className={classes.shareTypo}
                      onClick={() => setEditorFlag(idx)}
                    >
                      {t('work_orders.audit_trail.share')}...
                    </Typography>
                  )}
                </Box>
              ) : null}
            </Box>
          </div>
        </StepLabel>
      </div>
      <StepConnector />
    </Step>
  )
}
