/* eslint-disable no-undef */
import React from 'react'

/** Material UI **/
import { makeStyles, Typography } from '@material-ui/core'
import FormControl from '@mui/material/FormControl'
import { TimePicker } from 'antd'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    marginBottom: 10
  },
  label: {
    color: theme.colors.grey,
    fontSize: '12px',
    marginBottom: '0.5em'
  },
  timeInput: {
    'label + &': {
      marginTop: theme.spacing(3)
    },
    borderRadius: 45,
    border: '1px solid ' + theme.colors.profile.border_input,
    fontSize: 12,
    width: '9em',
    height: '39.25px',
    padding: '8px 10px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    '& .ant-picker-dropdown': {
      zIndex: '3000'
    },
    '& .ant-picker-input > input': {
      fontSize: '12px'
    }
  },
  timepicker: {
    '& .ant-picker-footer': {
      display: 'none'
    },
    '& .ant-picker-time-panel-column': {
      fontWeight: 'normal'
    }
  }
}))

export default function GlobalTimeInput(props) {
  const classes = useStyles()

  const handleChange = time => {
    const timeString = time && moment(time).format('hh:mm A')
    props.onChange(timeString, props.field)
  }

  return (
    <FormControl variant="standard" fullWidth={true}>
      {props.label && (
        <Typography id="text-label" className={classes.label}>
          {props.label}
        </Typography>
      )}
      <TimePicker
        className={classes.timeInput}
        {...props}
        popupStyle={{ zIndex: 2000 }}
        popupClassName={classes.timepicker}
        value={props.value}
        onChange={handleChange}
        onSelect={handleChange}
        id="time-input"
        minuteStep={30}
        showNow={false}
        allowClear={true}
        getPopupContainer={trigger => trigger.parentElement}
        inputReadOnly
      />
      <div className={classes.bottomSpacing}></div>
    </FormControl>
  )
}
