import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import RubikVariable from '../assets/fonts/Rubik-VariableFont_wght.ttf'

const Rubik = {
  fontFamily: 'Rubik',
  fontSize: '20px',
  fontStyle: 'normal',
  src: `
    local('Rubik'),
    local('Rubik-VariableFont_wght'),
    url(${RubikVariable})
  `
}

let customTheme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        paddingTop: '0px',
        paddingBottom: '0px'
      },
      head: {
        lineHeight: 'none'
      }
    },
    MuiCssBaseline: {
      /* Try to avoid importants */
      '@global': {
        '@font-face': [Rubik],
        '.MuiInputBase-input': {
          color: '#333333 !important'
        },
        '.MuiInputBase-input.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.38) !important'
        },
        '.MuiCheckbox-root': {
          color: '#E5E5E5 !important'
        },
        '.MuiCheckbox-colorPrimary.Mui-checked': {
          color: '#0072CE !important'
        },
        '.MuiInputAdornment-root': {
          color: '#333333 !important'
        },
        '.MuiButton-contained.Mui-disabled': {
          backgroundColor: '#F2F2F2 !important',
          color: '#BDBDBD !important'
        },
        '.MuiButtonBase-root.Mui-disabled > .MuiButton-label': {
          color: '#BDBDBD'
        },
        '.MuiButton-contained.Mui-disabled > .MuiButton-label': {
          color: '#BDBDBD'
        },
        '.MuiButtonBase-root.Mui-disabled': {
          color: '#BDBDBD !important',
          border: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
          // Target dark mode preference
          body: {
            '-webkit-appearance': 'none' // Prevent iOS dark mode
          }
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '10px'
      }
    }
  },
  palette: {
    primary: {
      light: '#47A0F4',
      main: '#0072CE',
      dark: '#0072CE',
      contrastText: '#fff'
    }
  },
  colors: {
    blue: '#2F80ED',
    black: '#000000',
    labels: 'rgba(0, 0, 0, 0.6)',
    text: '#333333',
    transparent: 'rgba(0,0,0,0.0)',
    textGray: '#BDBDBD',
    clearAdornment: '#8E8E8E',
    errorText: '#F44337',
    errorColor: '#EB5757',
    checkDomain: '#8CD38B',
    backdropColor: '#FFFFFF',
    navBarColor: '#FFFFFF',
    highlightButtonText: '#FFFFFF',
    basicButtonBackground: '#FFFFFF',
    basicDisabledButtonBackground: '#E5E5E5',
    basicDisabledButtonColor: '#4f4f4f',
    yellow: '#F2C94C',
    white: '#FFFFFF',
    grey: '#828282',
    grey_2: '#F2F2F2',
    grey_3: '#C9C9C9',
    buttonGrey: '#B0B0B0',
    firstNameAccountInfo: '#B8B8B8',
    placeholder: '#929292',
    complianceBlue: '#E2EBF3',
    error: '#F44336',
    purple: '#BB6BD9',
    tradesBackground: '#F8F8F8',
    signInButton: {
      background: '#F2F2F2',
      label: '#676767'
    },
    checkBlue: '#4696EC',
    dividers: '#EAEBEB',
    settings: {
      fieldInfo: '#6C7172'
    },
    workStatusBorder: 'rgba(71,160,244,0.1)',
    infoWindowBackground: '#FBFBFB',
    columnTitle: '#6C7172',
    disabledField: '#E0E0E0',
    mainBlue: '#0072CE',
    iconBlue: '#2F80ED',
    opaqueBackground: 'rgba(53, 53, 53, 0.8)',
    company: {
      title: '#333333',
      inputBorder: '#0000003A',
      roleButton: '#E0E0E018',
      iconColor: '#B0B0B0'
    },
    insertedRow: '#8ebeff63',
    workOrders: {
      counts: '#6C7172',
      chipBack: '#F1F1F1',
      selectedRow: 'rgba(93, 181, 224, 0.1)',
      dotsBack: '#E0E0E0',
      columnTitle: '#6C7172',
      emptyText: '#6C7172',
      detailsTitle: '#6C7172',
      emptyCardTitle: '#333333',
      downloadIcon: '#C4C4C4',
      detailsCardBorderColor: 'rgba(216, 216, 216, 0.25)',
      tab: {
        description: '#121212',
        wonum: '#212121',
        duedate: '#828282'
      },
      buttonPrimary: '#EEEEEE',
      gradient: '#DCDCDC',
      etaText: '#A0A0A0',
      scopeOfWorkBlue3: '#56CCF2',
      scopeOfWorkBlue1: '#2F80ED',
      pdf: '#D85140',
      doc: '#2F80ED',
      excel: '#27AE60'
    },
    workOrderColors: {
      open: '#2F80ED',
      completed: '#4F4F4F',
      in_progress: '#219653',
      active: '#8BC400',
      draft: '#828282',
      submitted: '#4F4F4F',
      declined: '#EB5757',
      approved: '#27AE60',
      no_work_order: '#CE004A',
      not_available: '#BDBDBD'
    },
    invoiceColors: {
      open: 'rgba(77, 166, 233, 0.85)',
      open_solid: 'rgba(77, 166, 233)',
      declined: 'rgba(235, 87, 87, 0.85)',
      declined_solid: 'rgba(235, 87, 87)',
      sent: '#333333',
      approved: 'rgba(143, 200, 61, 0.85)',
      approved_solid: 'rgba(143, 200, 61)',
      draft: 'rgba(86, 86, 86, 0.85)',
      draft_solid: 'rgba(86, 86, 86)',
      no_status: '#333333',
      not_submitted: 'rgba(175, 175, 175, 0.85)',
      not_submitted_solid: 'rgba(175, 175, 175)',
      summary_bg: '#F5F5F5',
      borders: '#F8F8F8',
      dark_grey: '#4F4F4F',
      table_text: '#828282',
      disabled_grey: '#BDBDBD',
      blue_button: '#2F80ED',
      red_error: '#EB5757',
      approved_green: '#219653',
      header_grey: '#CED4DA',
      labels_grey: '#6C7172',
      shadow: '#D8D8D840',
      invoice_created: '#2D9CDB',
      invoice_approved: '#8BC400'
    },
    profile: {
      text_grey: '#212121',
      avatar_bg: '#F9F9F9',
      avatar_icon: '#4F4F4F',
      border_input: '#ced4da',
      border_focus: '#80bdff',
      box_shadow: 'rgba(0,123,255,.25)',
      borders: '#F2F2F2',
      darkCard: '#E0E0E0',
      shadowCard: 'rgba(216, 216, 216, 0.25)',
      disabled_bg: '#F5F5F5',
      mapFill: '#AF1199',
      mapStroke: '#FFFFFF'
    },
    filters: {
      leftColumnBackground: '#FDFDFD',
      tagsBorder: '#EBEBEB',
      fieldsBorder: '#E0E0E0',
      fieldsLabels: '#6C7172',
      fieldsBackground: '#FFFFFF',
      selectedChips: 'blue',
      selectedExceptionsDividers: '#B9B9B9'
    },
    dialog: {
      text_grey: '#4F4F4F',
      text_white: 'white',
      ok_button: '#2F80ED',
      hover: '#BDBDBD'
    }
  },
  filtersClasses: {
    mainInput1: {
      fontSize: '12px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      width: 'auto',
      marginLeft: '0px',
      height: '32px',
      display: 'flex',
      '&.MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)'
        }
      }
    },
    mainInput2: {
      fontSize: '14px',
      backgroundColor: '#F2F2F2',
      borderRadius: '6px',
      marginLeft: '0px',
      height: '52px',
      display: 'flex',
      border: 'none',
      '& .MuiInputBase-root': {
        '& fieldset': {
          border: 'none'
        }
      },
      '&.MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        '& input': {
          color: '#4F4F4F !important',
          '&::placeholder': {
            color: '#2F80ED !important',
            opacity: 1
          }
        }
      }
    },
    searchInput1: {
      fontSize: '12px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      width: 'auto',
      marginLeft: '0px',
      height: '36px',
      border: '0px'
    },
    searchInput2: {
      fontSize: '12px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      width: 'auto',
      marginLeft: '0px',
      height: '32px',
      border: '0px'
    },
    menuItem: {
      fontSize: '12px',
      width: '100%',
      display: 'flex',
      maxWidth: '352px',
      flex: 1
    },
    selectItem: {
      fontSize: '14px',
      width: '100%',
      display: 'flex',
      maxWidth: '352px',
      flex: 1
    }
  },
  typography: {
    fontFamily: 'Rubik',
    fontWeight: 400,
    fontSize: 18,
    fontStyle: 'normal',
    allVariants: {
      color: '#333333'
    }
  },
  progressBar: {
    blue25: '#007AFF',
    blue50: '#2D9CDB',
    green75: '#31CAAE',
    green100: '#6FCF97',
    bgcolor: '#e0e0de'
  },
  constructionForm: {
    disabledGrey: '#c0c0c0'
  }
})

customTheme = responsiveFontSizes(customTheme)

export default customTheme
