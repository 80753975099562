/* eslint-disable no-unused-vars */
import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

/** Redux **/
import { useSelector } from 'react-redux'

/** Sign in **/
import SignIn from './pages/signIn/SignIn'

import { userHasAuthorization } from './services/AuthService'
import ForgotPassword from './pages/signIn/ForgotPassword'
import CreateAccount from './pages/createAccount/CreateAccount'
import ForgotPasswordCode from './pages/signIn/ForgotPasswordCode'
// Uncomment for contact us functionality
// import ContactUs from './pages/signIn/ContactUs'
import WorkOrders from './pages/WorkOrders'
import Invoices from './pages/Invoices'
import CreateInvoice from './pages/CreateInvoice'
import CompanySettings from './pages/CompanySettings'
import AccountSettings from './pages/AccountSettings'
import DeepLinkScreen from './pages/DeepLinkScreen'
import CompanyProfile from './pages/CompanyProfile'
import LandingSetUpScreen from './pages/admin/LandingSetup'

import { SignInContainer } from './components/SignInContainer'
import { AdminContainer } from './components/AdminContainer'
import { ServiceAppointment } from './pages/ServiceAppointment'

// Animations
import {
  AnimatedRoutes,
  RouteTransition
} from './lib/routeAnimations/RouteTransition'
import { MainContainer } from './components/FixedMainContainer'

const Routes = () => {
  const token = useSelector(state => state.auth.token)
  const user = useSelector(state => state.auth.user)
  const redirect = useSelector(state => state.auth.redirect)
  // App Stack
  if (token && user) {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path={['/service-appointment/*']}>
            <Route path="/service-appointment/:id/:access_token">
              <ServiceAppointment />
            </Route>
          </Route>
          <Route exact path={['/company-settings-iframe/*']}>
            <Route path="/company-settings-iframe/:id/:access_token">
              <MainContainer>
                <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                  <CompanySettings />
                </AnimatedRoutes>
              </MainContainer>
            </Route>
          </Route>
          <Route exact path={['/work-orders-iframe/*']}>
            <Route path="/work-orders-iframe/:id/:access_token">
              <MainContainer>
                <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                  <WorkOrders />
                </AnimatedRoutes>
              </MainContainer>
            </Route>
          </Route>
          <Route exact path={['/invoices-iframe/*']}>
            <Route path="/invoices-iframe/:id/:access_token">
              <MainContainer>
                <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                  <Invoices />
                </AnimatedRoutes>
              </MainContainer>
            </Route>
          </Route>
          <RouteTransition exact path={['/createInvoice-iframe/*']}>
            <Route path="/createInvoice-iframe/:id/:access_token">
              <MainContainer>
                <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                  <CreateInvoice />
                </AnimatedRoutes>
              </MainContainer>
            </Route>
          </RouteTransition>

          <Route exact path={['/deep-link']}>
            <Route path="/deep-link">
              <DeepLinkScreen />
            </Route>
          </Route>

          <MainContainer>
            <AnimatedRoutes exitBeforeEnter={true} initial={false}>
              <Route exact path="/">
                <Redirect
                  to={redirect?.redirect ? redirect.redirect : '/work-orders'}
                />
              </Route>
              {userHasAuthorization('workorders:read') && (
                <Route exact path="/work-orders">
                  <WorkOrders />
                </Route>
              )}
              {userHasAuthorization('invoices:read') && (
                <Route exact path="/invoices">
                  {' '}
                  <Invoices />
                </Route>
              )}
              {userHasAuthorization('invoices:read') && (
                <RouteTransition exact path="/createInvoice">
                  <CreateInvoice />
                </RouteTransition>
              )}
              {userHasAuthorization('company_settings:read') && (
                <Route exact path="/company-settings">
                  <CompanySettings />
                </Route>
              )}
              {userHasAuthorization('company_settings:read') && (
                <Route exact path="/company-profile">
                  <CompanyProfile />
                </Route>
              )}
              <Route exact path="/account-settings">
                <AccountSettings />
              </Route>
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </AnimatedRoutes>
          </MainContainer>
        </Switch>
      </BrowserRouter>
    )
  }

  // Auth Stack
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to={'/sign-in'} />
        </Route>

        {/* Admin layout stack */}
        <Route exact path={['/admin-setup']}>
          <AdminContainer>
            <Route exact path="/admin-setup">
              <LandingSetUpScreen />
            </Route>
          </AdminContainer>
        </Route>

        <Route exact path={['/service-appointment/*']}>
          <Route path="/service-appointment/:id/:access_token">
            <ServiceAppointment />
          </Route>
        </Route>
        <Route exact path={['/company-settings-iframe/*']}>
          <Route path="/company-settings-iframe/:id/:access_token">
             <MainContainer>
              <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                  <CompanySettings />
               </AnimatedRoutes>
             </MainContainer>
          </Route>
        </Route>
        <Route exact path={['/work-orders-iframe/*']}>
          <Route path="/work-orders-iframe/:id/:access_token">
             <MainContainer>
              <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                <WorkOrders />
               </AnimatedRoutes>
             </MainContainer>
          </Route>
        </Route>
        <Route exact path={['/invoices-iframe/*']}>
          <Route path="/invoices-iframe/:id/:access_token">
             <MainContainer>
              <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                <Invoices />
               </AnimatedRoutes>
             </MainContainer>
          </Route>
        </Route>
        <RouteTransition exact path={['/createInvoice-iframe/*']}>
          <Route path="/createInvoice-iframe/:id/:access_token">
             <MainContainer>
              <AnimatedRoutes exitBeforeEnter={true} initial={false}>
                <CreateInvoice />
               </AnimatedRoutes>
             </MainContainer>
          </Route>
        </RouteTransition>

        <Route exact path={['/deep-link']}>
          <Route path="/deep-link">
            <DeepLinkScreen />
          </Route>
        </Route>

        {/* Auth layout stack */}
        <Route
          exact
          path={[
            '/sign-in',
            '/create-account',
            '/forgot-password',
            '/forgot-password/code'
          ]}
        >
          <SignInContainer>
            <Route exact path="/sign-in">
              <SignIn />
            </Route>
            <Route exact path="/create-account">
              <CreateAccount />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/forgot-password/code">
              <ForgotPasswordCode />
            </Route>
          </SignInContainer>
        </Route>

        <Route path="*">
          <Redirect to="/sign-in" />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
