import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, FormLabel, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.colors.iconBlue,
    marginRight: '5px'
  },
  button: {
    textTransform: 'none',
    '& .MuiFormLabel-root': {
      color: theme.colors.iconBlue,
      fontSize: '14px'
    },
    paddingLeft: '5px'
  }
}))

export const AddButton = props => {
  const { label, callback } = props
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Button className={classes.button} onClick={event => callback(event)}>
      <FontAwesomeIcon icon={['fas', 'circle-plus']} className={classes.icon} />
      <FormLabel>
        {t('general.labels.add') +
          ' ' +
          (typeof label !== 'undefined' && label !== null
            ? label
            : t('work_orders.trips.notes'))}
      </FormLabel>
    </Button>
  )
}
