export const workOrders = () => {
  return {
    search_placeholder: 'Buscar',
    export_selected: 'Descargar',
    download_selected: 'Descargar Seleccionados',
    download_all: 'Descargar Todos',
    filter_counts: 'Contadores',
    filter_sites: 'Usar Mis Sitios',
    advanced_button: 'Búsqueda Avanzada',
    reset_button: 'Limpiar Filtros',
    counts: 'Contadores',
    email: 'Email',
    cancel: 'Cancelar',
    data_export: 'Exportar Información',
    open_invoice: 'Abrir Factura',
    export_message:
      'El informe se enviará a user_email_address.  Desafortunadamente, excede el límite para ser descargado aquí.  Por favor, espere unos minutos para que este informe sea enviado y asegúrese de revisar sus carpetas de spam/basura.',
    date_ranges: {
      today: 'Hoy',
      yesterday: 'Ayer',
      this_month: 'Este Mes',
      last_month: 'Mes Pasado',
      last_two_months: 'Dos Últimos Meses',
      last_three_months: 'Tres Últimos Meses',
      last_six_months: 'Seis Últimos Meses'
    },
    wo_states: {
      open: 'Abierto',
      active: 'En proceso',
      returning: 'Regresando',
      complete: 'Completo',
      completed: 'Completado',
      completed_warning: 'Completado',
      in_progress: 'En Progreso',
      cancelled: 'Cancelado',
      expired: 'Expirado',
      no_status: 'Sin estado',
      incomplete: 'Incompleto',
      no_work_order: 'Sin Órdenes'
    },
    column_names: {
      client_name: 'Nombre del Cliente',
      site_name: 'Nombre del Sitio',
      trade: 'Comercio',
      service: 'Servicio',
      won: 'Orden de Trabajo #',
      opendate: 'Fecha de Inicio',
      duedate: 'Fecha de Fin',
      wostat: 'Estado WO',
      invoices: 'Estado de Factura',
      priority: 'Prioridad',
      gc_scope: 'Alcance GC Total',
      general_notes: 'Descripción',
      nte: 'NTE',
      client_tracking_number: '# de Rastreo'
    },
    advanced_fields: {
      state: 'Estado',
      city: 'Ciudad',
      site: 'Nombre de sitio',
      wonum: 'Número de orden de trabajo',
      open_date: 'Fecha de apertura de orden de trabajo',
      wodate: 'Fecha de orden de trabajo',
      past_due: 'Vencimiento',
      service: 'Servicio',
      trade: 'Comercio',
      wo_status: 'Estado de orden de trabajo',
      call_type: 'Tipo de Orden de Trabajo'
    },
    toggle_labels: {
      all: 'Todos',
      custom: 'Personalizado',
      not_selected: 'No Seleccionado',
      select_all: 'Seleccionar Todos'
    },
    details_fields: {
      open: 'Apertura',
      expiration: 'Expira',
      priority: 'Prioridad',
      billing: 'Método de facturación del cliente',
      type: 'Tipo',
      agreement: 'Convenio',
      trip: 'Viaje',
      description: 'Descripción',
      no_call_type: 'Tipo de Orden de Trabajo Indefinido'
    },
    missing_card: {
      trip_not_created: 'Viaje no creado',
      no_activities_found: 'No se encontraron actividades',
      no_activities_found_detail:
        'Un viaje para esta orden de trabajo no ha sido creado.',
      empty_trip:
        'No se pudo encontrar nueva información. Los detalles se actualizarán automáticamente después del check in y check out.'
    },
    csv: {
      client_name: 'Client Name',
      address: 'Address',
      store_name: 'Nombre del Sitio',
      sitetitle: 'Nombre de Sitio',
      sitesub: 'Ubicación',
      priority: 'Prioridad',
      city: 'Ciudad',
      state: 'Estado',
      trade: 'Comercio',
      service: 'Servicio',
      pon: 'PO #',
      won: 'Order de Trabajo #',
      sa_number: 'Cita de Servicio #',
      start_date: 'Fecha Inicio',
      end_date: 'Fecha Fin',
      eta: 'ETA',
      opendate: 'Fecha de Apertura',
      duedate: 'Fecha de Vencimiento',
      call_type: 'Tipo WO',
      wostat: 'Estado WO',
      site_address: 'Dirección del Sitio',
      site_zip: 'Zip del Sitio',
      trip_num: 'Número de Viaje',
      checkin_ftc: 'Ingreso FTC',
      checkout_ftc: 'Salida FTC',
      checkin_web: 'Ingreso Web',
      checkout_web: 'Salida Web',
      tasks_ftc: 'Tareas FTC',
      photos_ftc: 'Fotos FTC',
      tasks_web: 'Tareas Web',
      external_id: 'ID Externo',
      status: 'Estado',
      invoice_status: 'Estado de Factura',
      checkin: 'Entrada',
      checkout: 'Salida',
      time_logs: 'Registro Horario',
      tasks: 'Tareas',
      photo_title: 'Título de Foto',
      photo_url: 'URL de Foto',
      notes: 'Notas',
      notes_response: 'Notas Ingresadas',
      picker: 'Selección',
      picker_response: 'Selecciones hechas',
      signature: 'Firma',
      signature_url: 'URL de Firma',
      photo_title_ap: 'Título de Foto Adicional',
      photo_url_ap: 'Photo URL AP',
      photo_title_pb: 'Photo Title PB',
      photo_url_pb: 'Photo URL PB',
      photo_title_pa: 'Photo Title PA',
      photo_url_pa: 'Photo URL PA'
    },
    trips: {
      before: 'Antes',
      after: 'Despues',
      origin: 'Fuente',
      eta: 'ETA',
      checkin: 'Fecha Registro',
      checkout: 'Fecha Salida',
      no_checkin: 'Entrada no registrada',
      checklist: 'Tareas Realizadas',
      photos: 'Fotos',
      photos_after: 'Fotos Después',
      photos_before: 'Fotos Antes',
      web_trip: 'Portal Web',
      mobile: 'Móbil',
      description: 'Descripción del viaje',
      notes: 'Notas',
      signature: 'Firma',
      add_note: 'Añadir Nota',
      photos_remaining: '{v1} Fotos Restantes',
      one_photos_remaining: '1 Foto Restante',
      mandatory: '(requerido)',
      fields_required:
        'Todos los campos en rojo son requeridos para actualizar el viaje.',
      sign: 'firma',
      enter_signature_name: 'Ingrese el nombre para la firma',
      attachments: 'Archivos adjuntos'
    },
    audit_card: {
      legend: 'Audit Trial proximamente!\nGracias por su paciencia.',
      work_order: 'La Órden de Trabajo',
      created: 'ha sido creada',
      trip: 'El viaje',
      has_created: 'ha sido creado',
      eta_set: 'ETA definido para',
      check_in: 'El contratista ha registrado su ingreso',
      check_out: 'El contratista ha registrado su salida',
      has_changed: 'ha cambiado a'
    },
    apply_advanced: 'Aplicar',
    activities: 'Actividades',
    audit: 'Auditoría',
    details: 'Detalles',
    due: 'Vencimiento',
    empty_message_title: 'Whoops...',
    empty_message:
      'No hay órdenes de trabajo en su cuenta. ¡Póngase en contacto con su empresa de gestión para obtener un nuevo trabajo!',
    empty_message_filters:
      'La consulta no arrojó resultados. Revise sus criterios de búsqueda e intente de nuevo.',
    please: 'Por favor',
    try: 'intente nuevamente',
    clear_search: 'Limpiar la Busqueda',
    checkin_message:
      'Los equipos de campo no se han registrado. Los detalles capturados en el campo se actualizan automáticamente después del registro y la salida.',
    checkout_message:
      'Los datos capturados en el campo se actualizan automáticamente.',
    expired_message:
      'La Cita de Servicio ha expirado por inactividad. Contacte a su represntante si otra cita de servicio es requerida.',
    cancelled_message:
      'La Cita de Servicio ha sido cancelada. No hay servicios de campo.',
    images_message:
      'Las fotos se actualizarán automáticamente una vez que el equipo de campo se retire.',
    sort: 'Ordenar',
    clear: 'Limpiar',
    highest: 'Mayor',
    lowest: 'Menor',
    oldest: 'Antiguo',
    newest: 'Reciente',
    service_appointment: 'Cita de Servicio',
    service_short: 'Cita Serv.',
    field_service: 'Servicio de Campo',
    audit_trail: {
      created: 'Cita de Servicio {v1} creada',
      expired: 'Cita de Servicio {v1} expirada',
      cancelled: 'Cita de Servicio {v1} cancelada',
      checkIn: 'Registo Entrada, FTC',
      checkOut: 'Registro Salida, FTC',
      eta: 'Actualización ETA',
      invoiceCreated: 'Factura creada, Borrador',
      submit: 'Factura Enviada',
      decline: 'Factura Rechazada',
      approve: 'Factura Aprobada',
      share: 'Compartir una actualización',
      cancel: 'Cancelar actualización',
      wo_created: 'Orden de trabajo creada',
      to: 'Para',
      internal: 'Solo interno',
      edit: 'Editar',
      reply: 'Responder',
      share_button: 'Compartir',
      cancel_button: 'Cancelar',
      at: 'a',
      estimated_service_start: 'Actualización de ETA'
    },
    wo_details: {
      location: 'Nombre de la Localización',
      address: 'Dirección',
      priority: 'Prioridad',
      start_date: 'Fecha de Inicio',
      end_date: 'Fecha de Caducidad',
      trade: 'Oficio',
      services: 'Servicios',
      location_contacts: 'Contactos de la Localización',
      notes: 'Notas',
      attachments: 'Adjuntos',
      nte: 'NTE',
      service_appointment: 'Cita de Serv.',
      general_notes: 'Notas de Órdenes de Trabajo',
      instructions: 'Notas de Órdenes de Servicio'
    },
    upload_messages: {
      clock_in: 'Subiendo registro de entrada',
      clock_out: 'Subiendo registro de salida',
      repair: 'Subiendo formulario {v1}',
      resources: 'Subiendo recursos'
    },
    construction_form: {
      qty: 'Cantidad',
      item: 'Item',
      product_name: 'Nobre de Producto',
      completion_status: 'Estado Completado del Item',
      select: 'Seleccionar',
      additional_comments: 'Comentarios Adicionales',
      notes: 'Notas',
      photos: 'Fotos',
      complete_action_required: 'Acción Requerida',
      return_on: 'Retorno Para',
      ending_on: 'Terminar Para ',
      confirmation: 'Confirmación',
      uploading_consttruction_form: 'Subiendo Formulario de Construcción',
      work_complete: 'Trabajo Completado?',
      return_visit: 'Visita de Retorno',
      if_work_not_complete:
        'Si el trabajo no está completado seleccione ‘visita requerida’',
      confirm_work_complete: 'Confirmar Trabajo Completo?',
      by_selecting_yes_work_complete:
        'Al seleccionar “Si”, certificas que no se requieren servicios adicionales ni órdenes de trabajo, y que la propiedad es segura para su uso previsto.',
      complete_0: '0% Completado',
      complete_25: '25% Completado',
      complete_50: '50% Completado',
      complete_75: '75% Completado',
      complete_100: '100% Completado',
      complete: 'Completado'
    },
    messages: {
      max_records:
        'Advertencia: La generación de informes está limitada a un máximo de 5000 registros. Reduzca el número de registros seleccionados.'
    },
    line_item: {
      enter_name: 'Ingresar Nombre',
      item_number_name: 'Item {{number}} nombre',
      enter_description: 'Ingresar Descripción..',
      item_description: 'Descripción de Item',
      item: 'Item',
      name: 'Nombre',
      description: 'Descripción',
      qty: 'QTY',
      quantity: 'Cantidad',
      unit_price: 'Precio Unitario',
      total: 'Total',
      tax: 'Impuesto'
    },
    sa_abbr: 'Cita de Serv.',
    reason: 'Razón',
    enter_return_reason: 'Ingresar razón (opcional)',
    complete_form: 'Acciones requeridas',
    complete_all_required:
      'Todas las acciones de la orden de trabajo deben completarse antes de marcar la salida.',
    attachment: 'Archivo adjunto'
  }
}
