import React, { useRef } from 'react'
import {
  FormControl,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTranslation } from 'react-i18next'
import { AutoCompleteAddress } from './AutoCompleteAddress'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  root: {
    '& .MuiFilledInput-input': {
      border: `1px solid ${theme.colors.signInButton.background}`,
      color: `${theme.colors.text} !important`,
      backgroundColor: theme.colors.signInButton.background,
      borderRadius: '6px'
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.colors.signInButton.background,
      borderRadius: '6px'
    },
    '& .MuiIconButton-sizeSmall': {
      marginLeft: '20px'
    },
    '& .MuiIconButton-sizeSmall:hover': {
      backgroundColor: theme.colors.signInButton.background
    },
    '& .MuiFilledInput-adornedEnd': {
      paddingRight: 0
    },
    '& .MuiFormLabel-root': {
      top: '4px'
    },
    '& .Mui-focused': {
      color: theme.colors.workOrders.tab.duedate
    },
    '& .MuiFormHelperText-root': {
      fontSize: '10px'
    },
    '& .MuiFormLabel-asterisk': {
      color: theme.colors.errorText,
      position: 'absolute',
      left: '-2px'
    },
    marginTop: 'unset',
    marginBottom: '12px',
    flex: 1
  },
  label: {
    marginTop: 'unset',
    fontSize: 10,
    borderRadius: '8px',
    color: theme.colors.workOrders.tab.duedate,
    paddingLeft: '8px'
  },
  textField: {
    height: '52px',
    fontSize: 16,
    maxwidth: '303px',
    backgroundColor: theme.colors.signInButton.background
  },
  textFieldAnimation: {
    '& input': {
      '&.pac-target-input': {
        animationName: 'none'
      }
    }
  },
  icon: {
    color: theme.colors.company.iconColor,
    marginRight: '15px'
  }
}))

// eslint-disable-next-line react/display-name
export const TextInput = React.forwardRef(
  (
    {
      handleChange,
      id,
      name,
      value,
      placeholder,
      error,
      helperText,
      type,
      label,
      inputStyle,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles()
    const inputRef = useRef()
    const { t } = useTranslation()

    const handleChangeInt = event => {
      handleChange(event, id)
    }

    return (
      <FormControl className={classes.formControl}>
        {id !== 'address' && (
          <TextField
            id={id}
            name={name}
            key={id}
            value={value}
            label={label}
            onChange={handleChange}
            size="small"
            variant="filled"
            margin="normal"
            inputRef={inputRef}
            InputProps={{
              className: classes.textField,
              style: inputStyle,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {value && (
                    <IconButton
                      aria-label="Clear contents"
                      onClick={() => {
                        handleChange({ target: { name: name, value: '' } }, id)
                      }}
                      className={classes.icon}
                      size="small"
                    >
                      <FontAwesomeIcon
                        icon={['far', 'circle-xmark']}
                        size="xs"
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            InputLabelProps={{
              className: classes.label
            }}
            placeholder={
              placeholder ? t('account_settings.form.enter') + ' ' + label : ''
            }
            classes={{ root: classes.root }}
            autoComplete="off"
            error={error}
            helperText={helperText}
            ref={ref}
            type={type ?? 'text'}
            onInput={handleChangeInt}
            {...rest}
          />
        )}
        {id === 'address' && (
          <TextField
            id={id}
            name={name}
            key={id}
            value={value}
            onChange={handleChange}
            label={label}
            size="small"
            variant="filled"
            margin="normal"
            fullWidth
            inputRef={inputRef}
            InputProps={{
              className: clsx(
                classes.textField,
                value.length <= 0 ? classes.textFieldAnimation : ''
              ),
              style: inputStyle,
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {value && (
                    <IconButton
                      aria-label="Clear contents"
                      onClick={() => {
                        handleChange({ target: { name: name, value: '' } }, id)
                      }}
                      className={classes.icon}
                      size="small"
                    >
                      <FontAwesomeIcon icon={['far', 'xmark']} size="xs" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              inputComponent: AutoCompleteAddress
            }}
            InputLabelProps={{
              className: classes.label
            }}
            placeholder={
              placeholder ? t('account_settings.form.enter') + ' ' + label : ''
            }
            classes={{ root: classes.root }}
            autoComplete="off"
            error={error}
            helperText={helperText}
            ref={ref}
            type={type ?? 'text'}
            onInput={handleChangeInt}
            {...rest}
          />
        )}
      </FormControl>
    )
  }
)
