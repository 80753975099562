import React from 'react'
import {
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { TableContainer } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import LoadingBox from './LoadingBox'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  summaryLabel: {
    backgroundColor: theme.colors.invoiceColors.summary_bg,
    fontSize: '12px',
    height: '22px',
    paddingTop: '3px',
    paddingBottom: '3px',
    color: theme.colors.invoiceColors.dark_grey,
    fontWeight: '700',
    borderBottom: '0px'
  },
  tableHeader: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.invoiceColors.table_text,
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders,
    '& :last-child': {
      textAlign: 'right'
    }
  },
  tableRow: {
    height: '54px'
  },
  tableItem: {
    fontSize: '12px',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  dateItem: {
    fontSize: '12px',
    minWidth: '200px',
    maxWidth: '200px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  inputAmountItem: {
    fontSize: '12px',
    minWidth: '273px'
  },
  servicesAmount: {
    fontSize: '12px',
    width: '49%',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  percentage: {
    fontSize: '12px',
    minWidth: '220px',
    maxWidth: '220px',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  amountPaid: {
    '&.MuiInputBase-input.Mui-disabled': {
      color: theme.colors.invoiceColors.red_error + ' !important'
    },
    fontSize: '12px',
    minWidth: '90px',
    textAlign: 'right'
  },
  rightAlignment: {
    '& :last-child': {
      textAlign: 'right'
    }
  }
}))

function EnhancedTable(props) {
  const {
    itemTag,
    advancePaymentPercentage,
    loading,
    woDateCreated,
    projectAmountPaid,
    totalProjectAmount
  } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const columns = [
    {
      field: 'date_sent',
      headerName: t('invoices.column_names.date_sent')
    },
    {
      field: 'scheduled_services_amount',
      headerName: t('invoices.column_names.scheduled_services_amount')
    },
    {
      field: 'percentage_paid',
      headerName: t('invoices.column_names.percentage_paid')
    },
    {},
    {
      field: 'amount_paid',
      headerName: t('invoices.column_names.amount_paid')
    }
  ]

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={8} classes={{ root: classes.summaryLabel }}>
              {loading ? '' : `${itemTag}`}
            </TableCell>
          </TableRow>
          <TableRow className={classes.rightAlignment}>
            {columns.map((column, index) => (
              <TableCell
                key={column.field + index}
                classes={{ root: classes.tableHeader }}
              >
                {loading ? (
                  <LoadingBox width="100%" height="20px" />
                ) : (
                  column.headerName
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow classes={{ root: classes.tableRow }}>
            <TableCell classes={{ root: classes.dateItem }}>
              {woDateCreated}
            </TableCell>
            <TableCell classes={{ root: classes.servicesAmount }}>
              <NumberFormat
                name="amount"
                thousandSeparator
                customInput={Input}
                disableUnderline
                prefix="$"
                decimalScale={2}
                fixedDecimalScale={2}
                value={totalProjectAmount}
                disabled
                classes={{
                  input: classes.inputAmountItem
                }}
              />
            </TableCell>
            <TableCell classes={{ root: classes.percentage }}>
              {advancePaymentPercentage}%
            </TableCell>
            <TableCell classes={{ root: classes.tableItem }} />
            <TableCell classes={{ root: classes.tableItem }}>
              <NumberFormat
                name="amount"
                thousandSeparator
                customInput={Input}
                disableUnderline
                prefix=" - $"
                decimalScale={2}
                fixedDecimalScale={2}
                value={projectAmountPaid}
                disabled
                classes={{
                  input: classes.amountPaid
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

EnhancedTable.propTypes = {
  itemTag: PropTypes.string,
  loading: PropTypes.bool,
  advancePaymentPercentage: PropTypes.number,
  woDateCreated: PropTypes.string,
  projectAmountPaid: PropTypes.number,
  totalProjectAmount: PropTypes.number,
  status: PropTypes.string
}

export const AdvancePaymentDetailComponent = props => {
  const {
    itemTag,
    loading,
    advancePaymentPercentage,
    woDateCreated,
    projectAmountPaid,
    totalProjectAmount,
    status
  } = props
  return (
    <EnhancedTable
      itemTag={itemTag}
      loading={loading}
      advancePaymentPercentage={advancePaymentPercentage}
      woDateCreated={woDateCreated}
      projectAmountPaid={projectAmountPaid}
      totalProjectAmount={totalProjectAmount}
      status={status}
    />
  )
}
