export const navBar = () => {
  return {
    work_orders: 'Work Orders',
    invoices: 'Invoices',
    sites: 'Sites',
    proposals: 'Proposals',
    manage_account: 'Manage Accounts',
    account_settings: 'Account Settings',
    company_settings: 'Company Settings',
    client_company_settings: 'Client Company Settings',
    help: 'Help',
    send_feedback: 'Send Feedback',
    log_out: 'Log Out',
    update_work_snack: 'There are new activities for Work Orders tab.',
    update_invoice_snack: 'There are new activities for Invoices tab.',
    refresh_message: 'REFRESH to see the Updates on this list.'
  }
}
