import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  makeStyles,
  Paper,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import GlobalChip from '../form/Chip'
import GlobalNumberInput from '../form/NumberInput'
import { useSelector } from 'react-redux'
import { defaultConfigRates } from '../../lib/Constants'

const useStyles = makeStyles(theme => ({
  component: {
    width: '100%',
    borderRadius: '36px',
    marginTop: '20px',
    padding: '1em 1.5em'
  },
  service_title: {
    fontSize: '20px',
    color: theme.colors.iconBlue,
    fontWeight: '700',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  removeButton: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: theme.colors.buttonGrey,
    fontSize: '22px'
  },
  chipsContainer: {
    marginBottom: '1.5em'
  },
  ratesContainer: {
    gap: '1em',
    minWidth: '34em',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      minWidth: '100%'
    }
  },
  ratesMessage: {
    fontSize: '12px',
    fontStyle: 'italic',
    lineHeight: '14px',
    color: theme.colors.grey
  }
}))

export const ServiceCard = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [servicesSelected, setServicesSelected] = useState(new Set())
  const [rates, setRates] = useState({
    standard_rate: '',
    emergency_rate: '',
    travel_cost: ''
  })
  const configRates = useSelector(state => state.auth.user?.userInfo?.configurations?.portals?.subcontractor?.onboarding?.compliance?.tradesServices?.rates)
  const companyRates = configRates && configRates.length > 0 ? configRates : defaultConfigRates
  const subcontractorRates = useSelector(state => state.auth.user?.userInfo?.subcontractor_rates)

  function handleButtonRemove(event, item) {
    props.removeCard(item)
  }
  useEffect(() => {
    // get catalog_key from companyRates
    const ratesKeys = companyRates?.map(rate => rate.catalog_key)
    // filter itemsSelected by ratesKeys
    const filteredRates = {}
    ratesKeys.forEach(rateKey => {
      filteredRates[rateKey] = props.itemsSelected[0]?.rates[rateKey] || ''
    })
    setRates({ ...filteredRates })
    if (props.itemsSelected[0]?.services) {
      setServicesSelected(new Set([...props.itemsSelected[0]?.services]))
    }
  }, [])

  useEffect(() => {
    handleSelection()
  }, [servicesSelected])

  function handleSelection() {
    props.handleSelection(props.trade, servicesSelected, rates)
  }

  function handleRateChanged(value, rate) {
    const newRates = { ...rates, [rate]: value.slice(1) }
    setRates(newRates)
    props.handleRates(props.trade, newRates)
  }
  const withValueCap = inputObj => {
    const { value } = inputObj
    if (value <= 9999.99) return true
    return false
  }

  return (
    <Paper variant="outlined" className={classes.component}>
      {/* Trade title */}
      <Box display="flex" justifyContent="space-between">
        <Typography classes={{ root: classes.service_title }}>
          {props.trade}
        </Typography>
        <IconButton
          onClick={event => handleButtonRemove(event, props.trade)}
          className={classes.removeButton}
        >
          <FontAwesomeIcon icon="fa-light fa-circle-xmark" />
        </IconButton>
      </Box>
      {/* subservices */}
      <Box className={classes.chipsContainer}>
        {props.subservices.length > 0 && (
          <GlobalChip
            skipTranslate
            chips={props.subservices}
            selected={servicesSelected}
            setSelected={setServicesSelected}
            parent="services"
            allowNoSelection={false}
            useCatalog={props.servicesCatalog}
          />
        )}
      </Box>
      {/* rates container */}
      {!props.hideRates && (
        <Box className={classes.chipsContainer}>
          <Box display="flex" flexDirection="row">
            <Box flex={2} display="flex" className={classes.ratesContainer}>
              {companyRates?.map((rate, ind) => <GlobalNumberInput
                      key={ind}
                      onChange={handleRateChanged}
                      field={rate.catalog_key}
                      placeholder={t('company_profile.placeholder.rate') + (rate.rate === 'hourly' ? ' [$/hr]' : ' [$]')}
                      value={rates?.[rate.catalog_key]}
                      label={subcontractorRates?.[rate.catalog_key] + (rate.rate === 'hourly' ? ' [$/hr]' : ' [$]')}
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      decimalSeparator="."
                      isAllowed={withValueCap}
                      disabled={props?.readonlyRates}
                      required={props?.mandatoryRates}
                    />
              )}
            </Box>
            <Box flex={1}></Box>
          </Box>
          <Typography className={classes.ratesMessage}>
            {t('company_profile.message.rates')}
          </Typography>
        </Box>
      )}
    </Paper>
  )
}
