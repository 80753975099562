import React, { useState, useEffect } from 'react'
import { TableRow, useTheme } from '@material-ui/core'

export const FadingRow = (props) => {
  const theme = useTheme()
  const [backgroundColor, setBackgroundColor] = useState(theme.colors.insertedRow)

  useEffect(() => {
    // Change the background color to transparent after 3 seconds
    const timeoutId = setTimeout(() => {
      setBackgroundColor('transparent')
    }, 6000)

    // Clear the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId)
  }, []) // Empty dependency array ensures that this effect runs once after the initial render

  return (
    <TableRow style={{
        backgroundColor: backgroundColor,
        transition: 'background-color 1s ease-in-out'
      }} {...props}>
    </TableRow>
  )
}
