import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './signIn'
import loadingReducer from './loading'
import invoicesReducer from './invoices'
import workOrdersReducer from './workOrders'
import websocketReducer from './websocket'

// When running on iframe identify the store by companyId
let companyId = null
let isIframe = window !== window.top
if (window !== window.top) {
  const url = new URL(window.location.href)
  const parts = url.pathname.split('/')
  if (parts.length > 1) {
    companyId = parts[2]
  } else {
    isIframe = false
  }
}

const persistConfig = {
  key: (isIframe && companyId) ? companyId : 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'invoices']
}

const rootReducer = combineReducers({ auth: authReducer, loading: loadingReducer, invoices: invoicesReducer, workOrders: workOrdersReducer, websocket: websocketReducer })

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)
