import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// mui components
import {
  Card,
  CardContent,
  Grid,
  Stepper,
  Box,
  Button
} from '@material-ui/core'
import { auditTrailStyles } from '../../styles/classes/WorkOrderClasses'
import { AddCircle, HighlightOff } from '@material-ui/icons'
import { AuditUpdateEditor } from './AuditUpdateEditor'
import { EditorState, convertToRaw } from 'draft-js'
import { AuditStep } from './AuditStep'
import { toBase64 } from '../../lib/Global'
import { useSelector } from 'react-redux'
import {
  createMessage,
  createMessageFile,
  createExternalMessage
} from '../../services/ApiService'
import draftToHtml from 'draftjs-to-html'
import moment from 'moment'

export const AuditTrail = props => {
  const { workOrders, messages, tabValue, woId, getAuditMessages, setReady } =
    props
  const { t } = useTranslation()
  const classes = auditTrailStyles()()
  const userStore = useSelector(state => state.auth.user)

  const [eventList, setEventList] = useState([])
  const [editorFlag, setEditorFlag] = useState()
  const [files, setFiles] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [source, setSource] = useState('internal')

  useEffect(() => {
    const list = []
    const newmessages = messages || []
    newmessages.forEach(message => {
      if (message.type === 'message' && !message.messageId) {
        const replies = newmessages.filter(
          element => element.messageId === message.id
        )
        list.push({
          ...message,
          type: 'message',
          time: message.timestamp,
          replies
        })
      }
    })
    workOrders.forEach(wo => {
      const workMessages = newmessages.filter(
        message => message.workOrderId === wo.id
      )
      const createdMessages = getMessagesByType(workMessages, 'wo_created')
      list.push({
        id: wo.id,
        type: 'wo_created',
        time: wo.date_created + 4320,
        trip: wo.trip,
        messages: createdMessages
      })
      if (wo.est_service_start?.time) {
        const serviceMessages = getMessagesByType(
          workMessages,
          'estimated_service_start'
        )
        list.push({
          id: wo.id,
          type: 'estimated_service_start',
          time: wo.est_service_start?.time / 1000,
          trip: wo.trip,
          messages: serviceMessages
        })
      }
      // expiration_date audit trail shown after 3 days
      const displayExpirationDate = moment(new Date(wo.expiration_date * 1000))
        .add(3, 'days')
        .format('MM/DD/yy')
      if (
        displayExpirationDate <= moment().format('MM/DD/yy') &&
        wo.status !== 'cancelled' &&
        wo.status !== 'complete' &&
        wo.status !== 'completed'
      ) {
        const cancelledMessages = getMessagesByType(workMessages, 'expired')
        list.push({
          id: wo.id,
          type: 'expired',
          time: moment(wo.expiration_date * 1000)
            .add(3, 'days')
            .unix(),
          trip: wo.trip,
          messages: cancelledMessages
        })
      }
      // cancelled wo status with log type chekOut in audit trail
      if (wo.status === 'cancelled') {
        wo.logs?.forEach(log => {
          if (log.status === 'complete' && log.type === 'checkOut') {
            const cancelledMessages = getMessagesByType(
              workMessages,
              'cancelled'
            )
            list.push({
              id: wo.id,
              type: 'cancelled',
              time: log.date_created,
              trip: wo.trip,
              messages: cancelledMessages
            })
          }
        })
      } else {
        wo.logs?.forEach(log => {
          if (log.status === 'complete') {
            const outMessages = getMessagesByType(
              workMessages,
              log.type === 'checkIn' ? 'clocked_in' : 'clocked_out'
            )
            list.push({
              type: log.type,
              time: log.date_created,
              messages: outMessages,
              trip: wo.trip,
              id: wo.id
            })
          }
        })
      }
    })
    setEventList(list.sort((a, b) => b.time - a.time))
  }, [workOrders, messages])

  const getMessagesByType = (workMessages, type) => {
    const foundMessages = workMessages.filter(message => message.type === type)
    const finalMessages = []
    foundMessages.forEach(found => {
      if (!found.messageId) {
        const replies = foundMessages.filter(
          element => element.messageId === found.id
        )
        finalMessages.push({
          ...found,
          replies: replies ?? []
        })
      }
    })
    return finalMessages
  }

  useEffect(() => {
    setEditorState(EditorState.createEmpty())
    setFiles([])
  }, [editorFlag])

  const handleSaveMessage = async () => {
    setReady(false)
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    const markup = draftToHtml(rawContentState, null, null, null)
    const styledHTML = markup
      .replaceAll('<span', '<p')
      .replaceAll('</span>', '</p>')
    const htmlNode = document.createElement('div')
    htmlNode.innerHTML = styledHTML
    htmlNode.querySelectorAll('*').forEach(function (node) {
      node.removeAttribute('style')
    })
    const subcontractorPrefixedContent = htmlNode.innerHTML.replace(
      '<p>',
      '<p>#Subcontractor '
    )
    const messageData = {
      content: subcontractorPrefixedContent,
      userName: `${userStore.userInfo.firstName} ${userStore.userInfo.lastName}`,
      companyName: userStore.userInfo.company_name,
      source,
      timestamp: Math.floor(new Date().getTime() / 1000),
      workOrderId: woId,
      userId: userStore.userId,
      companyId: userStore.userInfo.originating_company,
      type: 'message'
    }
    const createMessageRes = await createMessage(messageData)
    const documentIds = []
    for (const file of files) {
      const result = await toBase64(file)
      const extension = file.name.split('.').pop()
      const response = await createMessageFile(createMessageRes.id, {
        data: result,
        contentType: extension,
        name: file.name,
        date_created: Math.floor(new Date().getTime() / 1000),
        companyId: userStore.userInfo.originating_company,
        workOrderId: woId,
        source
      })
      if (source === 'external' && typeof response === 'string') {
        documentIds.push(response)
      }
    }
    if (source === 'external') {
      messageData.id = createMessageRes.id
      messageData.documentIds = documentIds
      messageData.client_date = moment(new Date()).format(
        t('general.date_formats.audit_trail_format')
      )
      await createExternalMessage(messageData)
    }
    setReady(false)
    getAuditMessages()
    setEditorFlag()
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container>
          {editorFlag === 'N/A' ? (
            <Button
              onClick={() => setEditorFlag()}
              variant="text"
              className={classes.cancelButton}
            >
              <HighlightOff fontSize="small" className={classes.cancelIcon} />
              {t('work_orders.audit_trail.cancel')}
            </Button>
          ) : (
            <Button
              onClick={() => setEditorFlag('N/A')}
              variant="text"
              className={classes.shareButton}
            >
              <AddCircle fontSize="small" className={classes.addIcon} />
              {t('work_orders.audit_trail.share')}
            </Button>
          )}
          {editorFlag === 'N/A' && (
            <Box className={classes.shareBox} marginLeft="15px">
              <AuditUpdateEditor
                editorState={editorState}
                files={files}
                setEditorState={setEditorState}
                setFiles={setFiles}
                reply={false}
                handleSave={handleSaveMessage}
                source={source}
                setSource={setSource}
              />
            </Box>
          )}
          <Stepper
            orientation="vertical"
            connector={<div />}
            className={classes.stepperContainer}
          >
            {eventList.map((event, idx) => (
              <AuditStep
                event={event}
                key={idx}
                idx={idx}
                length={eventList.length - 1}
                editorFlag={editorFlag}
                setEditorFlag={setEditorFlag}
                tabValue={tabValue}
                woId={woId}
                getAuditMessages={getAuditMessages}
                setReady={setReady}
                woLength={workOrders.length}
              />
            ))}
            <div className={classes.bigAdornment} />
            <div className={classes.smallAdornment} />
          </Stepper>
        </Grid>
      </CardContent>
    </Card>
  )
}
