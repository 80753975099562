import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  FormLabel,
  Grid,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core'
import { PhotoPicker } from '../../PhotoPicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddButton } from '../../AddButton'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  fieldTitle: {
    marginBottom: '5px',
    marginLeft: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  photoTitle: {
    fontSize: '11px',
    height: '30px',
    marginTop: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  gridContainer: {
    marginLeft: '5px',
    maxWidth: '31%',
    height: '115px'
  },
  thumbContainer: {
    width: '95px',
    height: '90px',
    marginBottom: '6px',
    borderRadius: '8px'
  },
  thumb: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px'
  },
  fieldContainer: {
    backgroundColor: theme.colors.grey_2,
    borderRadius: '6px',
    minwidth: '303px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    height: '52px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonLabel: {
    fontSize: '14px',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    padding: '5px',
    flex: 1,
    maxHeight: '35px',
    overflowY: 'hidden'
  },
  menuItem: theme.filtersClasses.menuItem,
  icon: {
    alignSelf: 'center',
    paddingRight: '15px',
    color: theme.colors.grey
  },
  required: {
    fontSize: '12px',
    color: 'red',
    marginLeft: '2px'
  },
  mandatory: {
    color: theme.colors.errorColor
  }
}))

export default function PhotoSelector(props) {
  const classes = useStyles()
  const {
    question,
    answer,
    readOnly,
    onUpdate,
    section,
    questionId,
    mandatory,
    handleOpenPhotos
  } = props
  const photoRef = useRef()
  const [title, setTitle] = useState('')
  const [photoId, setPhotoId] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const inputRef = useRef()
  const { t } = useTranslation()
  const [options, setOptions] = useState()
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    const opt = question?.photos.map((obj, idx) => {
      return {
        title: obj.title,
        mandatory: obj.required,
        id: idx + '_' + obj.title.toLowerCase().trim().replaceAll(' ', '_')
      }
    })
    setPhotos(answer ?? [])
    setOptions(opt)
  }, [])

  const setPhotoUrl = async (url, exif) => {
    photos.push({
      title: title,
      uri: url,
      attributes: {
        ...exif,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      id: photoId
    })
    onUpdate(section, questionId, [...photos])
  }

  const removePhoto = deletedPhoto => {
    const index = photos.indexOf(deletedPhoto)
    photos.splice(index, 1)
    onUpdate(section, questionId, [...photos])
  }

  return (
    <Box>
      <FormLabel
        component="legend"
        className={`${classes.fieldTitle} ${
          !readOnly && mandatory ? classes.mandatory : ''
        }`}
      >
        {question.title}
      </FormLabel>
      {readOnly && (
        <Grid container>
          {photos &&
            photos.map((photo, ind) => (
              <Grid
                key={photo.id}
                item
                xs={4}
                md={4}
                className={classes.gridContainer}
              >
                <div className={classes.thumbContainer}>
                  <img
                    src={(photo.baseurl ?? '') + photo.url}
                    className={classes.thumb}
                    onClick={() => handleOpenPhotos(ind, photos)}
                  />
                </div>
                {photo?.title && (
                  <div className={classes.photoTitle}>
                    <legend className={classes.photoTitle}>
                      {photo?.title}
                    </legend>
                  </div>
                )}
              </Grid>
            ))}
        </Grid>
      )}
      {!readOnly && (
        <Box>
          <PhotoPicker
            key={'photo-picker'}
            photoRef={photoRef}
            callback={setPhotoUrl}
          />

          {photos && photos?.map((photo, idx) => (
            <Grid
              key={photo.url + photo.title + idx}
              className={classes.fieldContainer}
              item
              xs={12}
            >
              <FormLabel className={classes.buttonLabel}>
                {photo.title ? photo.title : 'Photo ' + (idx + 1)}
              </FormLabel>
              <FontAwesomeIcon
                icon={['far', 'times-circle']}
                onClick={() => removePhoto(photo)}
                className={classes.icon}
              />
            </Grid>
          ))}
          {options?.length !== photos?.length && (
            <AddButton
              label={t('work_orders.trips.photos')}
              callback={event => {
                if (options && options.length > 0) {
                  setAnchorEl(event.currentTarget)
                } else photoRef.current.click()
              }}
            />
          )}
          {anchorEl && (
            <Menu
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              onClose={() => setAnchorEl(null)}
              open={true}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              {options.map(option => {
                const id = typeof option === 'object' ? option.id : option
                if (
                  photos.filter(photo => photo.id === id).length !== 0
                ) {
                  return null
                }

                return (
                  <MenuItem
                    key={option.title}
                    value={title}
                    classes={{ root: classes.menuItem }}
                    onClick={() => {
                      setAnchorEl(null)
                      setTitle(option.title)
                      setPhotoId(option.id)
                      photoRef.current.click()
                    }}
                    style={{ width: inputRef?.current?.clientWidth }}
                  >
                    {option.title ?? option}
                    {typeof option === 'object' && option.mandatory && (
                      <FormLabel className={classes.required}>
                        {t('work_orders.trips.mandatory')}
                      </FormLabel>
                    )}
                  </MenuItem>
                )
              })}
            </Menu>
          )}
        </Box>
      )}
    </Box>
  )
}
