import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  FormLabel,
  Grid,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AddButton } from '../../AddButton'
import { useTranslation } from 'react-i18next'
import { FilePicker } from '../../FilePicker'

const useStyles = makeStyles(theme => ({
  fieldTitle: {
    marginBottom: '5px',
    marginLeft: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  photoTitle: {
    fontSize: '11px',
    height: '30px',
    marginTop: '0px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  gridContainer: {
    marginLeft: '5px',
    maxWidth: '31%',
    height: '115px'
  },
  thumbContainer: {
    width: '95px',
    height: '90px',
    marginBottom: '6px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  thumb: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px'
  },
  fieldContainer: {
    backgroundColor: theme.colors.grey_2,
    borderRadius: '6px',
    minwidth: '303px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    height: '52px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonLabel: {
    fontSize: '14px',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    padding: '5px',
    flex: 1,
    maxHeight: '35px',
    overflowY: 'hidden'
  },
  menuItem: theme.filtersClasses.menuItem,
  menuItemWidth: {
    width: '100%'
  },
  icon: {
    alignSelf: 'center',
    paddingRight: '15px',
    color: theme.colors.grey
  },
  required: {
    fontSize: '12px',
    color: 'red',
    marginLeft: '2px'
  },
  mandatory: {
    color: theme.colors.errorColor
  },
  fileIcons: {
    width: '50px',
    height: '50px'
  },
  fileIconRed: {
    color: theme.colors.errorText
  },
  fileIconGreen: {
    color: theme.colors.invoiceColors.approved_green
  },
  fileIconBlue: {
    color: theme.colors.blue
  }
}))

export default function FileSelector(props) {
  const classes = useStyles()
  const {
    question,
    answer,
    readOnly,
    onUpdate,
    section,
    questionId,
    mandatory,
    handleOpenPhotos
  } = props
  const fileRef = useRef()
  const [title, setTitle] = useState('')
  const [fileId, setFileId] = useState('')
  const [anchorEl, setAnchorEl] = useState(null)
  const inputRef = useRef()
  const { t } = useTranslation()
  const [options, setOptions] = useState()
  const [files, setFiles] = useState([])

  const [imageFiles, setImageFiles] = useState([])
  const menuContainerRef = useRef(null)

  useEffect(() => {
    const opt = question?.attachments?.map((obj, idx) => {
      return {
        title: obj.title,
        mandatory: obj.required,
        id: idx + '_' + obj.title.toLowerCase().trim().replaceAll(' ', '_')
      }
    })
    setFiles(answer ?? [])
    setOptions(opt)
  }, [])

  const setFileUrl = async (url, attributes) => {
    files.push({
      title: title,
      uri: url,
      attributes: {
        ...attributes,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      id: fileId,
      is_file: true,
      mime_type: attributes.type,
      file_extension: attributes.name.substring(
        attributes.name.lastIndexOf('.') + 1
      )
    })
    onUpdate(section, questionId, [...files])
  }

  const removeFile = deletedFile => {
    const index = files.indexOf(deletedFile)
    files.splice(index, 1)
    onUpdate(section, questionId, [...files])
  }

  useEffect(() => {
    const images = files.filter(isImageFile).map((file, index) => ({
      id: file.id,
      url: (file.baseurl ?? '') + file.url,
      title: file.title,
      attributes: file.attributes
    }))
    setImageFiles(images)
  }, [files])

  const isImageFile = file => {
    const imageExtensions = ['jpg', 'jpeg', 'png']
    const extension = file.file_extension.toLowerCase()
    return imageExtensions.includes(extension)
  }

  const getFileIcon = fileExtension => {
    switch (fileExtension.toLowerCase()) {
      case 'pdf':
        return { icon: 'fa-light fa-file-pdf', colorClass: classes.fileIconRed }
      case 'csv':
        return {
          icon: 'fa-light fa-file-csv',
          colorClass: classes.fileIconGreen
        }
      case 'docx':
      case 'doc':
        return {
          icon: 'fa-light fa-file-word',
          colorClass: classes.fileIconBlue
        }
      default:
        return { icon: 'fa-light fa-file', colorClass: classes.fileIconBlue }
    }
  }

  const handleFileClick = (index, file) => {
    if (isImageFile(file)) {
      const imageIndex = imageFiles.findIndex(img => img.id === file.id)
      handleOpenPhotos(imageIndex, imageFiles)
    }
  }

  const handleFileSelection = (selectedTitle, selectedId) => {
    setTitle(selectedTitle)
    setFileId(selectedId)
    // Delay the file picker dialog to ensure the acceptType is set correctly
    setTimeout(() => {
      fileRef.current.click()
    }, 100)
  }

  useEffect(() => {
    if (menuContainerRef.current && inputRef.current) {
      menuContainerRef.current.style.width = `${inputRef.current.clientWidth}px`
    }
  }, [])

  return (
    <Box>
      <FormLabel
        component="legend"
        className={`${classes.fieldTitle} ${
          !readOnly && mandatory ? classes.mandatory : ''
        }`}
      >
        {question.title}
      </FormLabel>
      {readOnly && (
        <Grid container>
          {files &&
            files.map((file, ind) => (
              <Grid
                key={file.id}
                item
                xs={4}
                md={4}
                className={classes.gridContainer}
              >
                <div
                  className={classes.thumbContainer}
                  onClick={() => handleFileClick(ind, file)}
                >
                  {isImageFile(file) ? (
                    <img
                      src={(file.baseurl ?? '') + file.url}
                      className={classes.thumb}
                      alt={file.title}
                    />
                  ) : (
                    <a
                      href={(file.baseurl ?? '') + file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon
                        icon={getFileIcon(file.file_extension).icon}
                        className={`${classes.fileIcons} ${
                          getFileIcon(file.file_extension).colorClass
                        }`}
                      />
                    </a>
                  )}
                </div>
                {file?.title && (
                  <div className={classes.photoTitle}>
                    <legend className={classes.photoTitle}>
                      {file?.title}
                    </legend>
                  </div>
                )}
              </Grid>
            ))}
        </Grid>
      )}
      {!readOnly && (
        <Box>
          <FilePicker
            key={title}
            fileRef={fileRef}
            callback={setFileUrl}
            question={question}
            title={title}
          />

          {files &&
            files?.map((file, idx) => (
              <Grid
                key={file.url + file.title + idx}
                className={classes.fieldContainer}
                item
                xs={12}
              >
                <FormLabel className={classes.buttonLabel}>
                  {file.title
                    ? file.title
                    : t('work_orders.attachment') + ' ' + (idx + 1)}
                </FormLabel>
                <FontAwesomeIcon
                  icon={['far', 'times-circle']}
                  onClick={() => removeFile(file)}
                  className={classes.icon}
                />
              </Grid>
            ))}
          {options?.length !== files?.length && (
            <AddButton
              label={t('work_orders.trips.attachments')}
              callback={event => {
                if (options && options.length > 0) {
                  setAnchorEl(event.currentTarget)
                } else {
                  handleFileSelection('', '')
                }
              }}
            />
          )}
          {anchorEl && (
            <div ref={menuContainerRef}>
              <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                onClose={() => setAnchorEl(null)}
                open={true}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                {options.map(option => {
                  const id = typeof option === 'object' ? option.id : option
                  if (files.filter(file => file.id === id).length !== 0) {
                    return null
                  }

                  return (
                    <MenuItem
                      key={option.title}
                      value={title}
                      classes={{
                        root: `${classes.menuItem} ${classes.menuItemWidth}`
                      }}
                      onClick={() => {
                        setAnchorEl(null)
                        handleFileSelection(option.title, option.id)
                      }}
                    >
                      {option.title ?? option}
                      {typeof option === 'object' && option.mandatory && (
                        <FormLabel className={classes.required}>
                          {t('work_orders.trips.mandatory')}
                        </FormLabel>
                      )}
                    </MenuItem>
                  )
                })}
              </Menu>
            </div>
          )}
        </Box>
      )}
    </Box>
  )
}
