export const forgotPasswordCode = () => {
  return {
    title: 'UPDATE PASSWORD',
    subtitle_1: 'Please Update your pasword',
    code: 'Code',
    new_password: 'Enter New Password',
    re_enter_new_password: 'Re-enter New Password',
    reset_password: 'Sign In'
  }
}
