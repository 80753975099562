import React from 'react'
import EXIF from 'exif-js'
import moment from 'moment'
import { resizeImage } from '../lib/Global'

export const PhotoPicker = props => {
  const { callback, photoRef } = props

  const resetFileInput = inputRef => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const changeFileHandler = async event => {
    const file = event.target.files[0]

    try {
      const { dataUrl } = await resizeImage(file)

      let attributes = {}
      EXIF.getData(file, function () {
        const exifData = EXIF.getAllTags(this)
        let timestamp = new Date().getTime()
        if (exifData?.DateTimeOriginal) {
          const parsedDate = moment(
            exifData?.DateTimeOriginal,
            'YYYY:MM:DD HH:mm:ss'
          )
          timestamp = parsedDate.valueOf()
        }
        if (exifData?.DateTimeDigitized) {
          const parsedDate = moment(
            exifData?.DateTimeDigitized,
            'YYYY:MM:DD HH:mm:ss'
          )
          timestamp = parsedDate.valueOf()
        }
        if (exifData?.DateTime) {
          const parsedDate = moment(exifData?.DateTime, 'YYYY:MM:DD HH:mm:ss')
          timestamp = parsedDate.valueOf()
        }
        attributes = {
          lat: exifData?.GPSLatitude ? exifData.GPSLatitude : 0,
          lon: exifData?.GPSLongitude ? exifData.GPSLongitude : 0,
          exif: exifData ? 'disabled' : 'enabled',
          timestamp,
          type: 'library'
        }
        callback(dataUrl, attributes)
      })
    } catch (error) {
      console.error('Error processing image:', error)
    }

    // Reset the file input value after processing the image
    resetFileInput(photoRef)
  }

  return (
    <input
      ref={photoRef}
      style={{ display: 'none' }}
      type="file"
      name="file"
      onChange={changeFileHandler}
    />
  )
}
