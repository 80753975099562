import React, { useState } from 'react'
import { FormLabel, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { DateTimeFilter } from './DateTimeFilter'
import moment from 'moment'
import { uploadETA } from '../../lib/Api'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '28px'
  },
  field: {
    marginLeft: '5px',
    marginBottom: '4px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  fieldData: {
    marginLeft: '5px',
    marginBottom: '28px',
    color: theme.colors.textGray,
    fontSize: '14px',
    fontWeight: '400'
  }
}))

export const EtaSelect = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { woId, data, disabled, type, onUpdate, maxDate } = props
  const [eta, setEta] = useState(data?.time)

  const updateEta = async time => {
    setEta(time)
    onUpdate(time)
    await uploadETA(woId, time, type === 'iframe')
  }

  return (
    <div className={classes.container}>
      <FormLabel component="legend" className={classes.field}>
        {t('work_orders.trips.eta')}
      </FormLabel>
      {disabled ? (
        <FormLabel component="legend" className={classes.fieldData}>
          {data && data?.time && data.time !== ''
            ? moment(new Date(eta)).format(t('general.format.short_date_time'))
            : t('general.labels.not_available')}
        </FormLabel>
      ) : (
        <DateTimeFilter
          id={'eta'}
          values={eta ?? ''}
          type={2}
          setValues={updateEta}
          filterContent={() => null}
          maxDate={maxDate}
        />
      )}
    </div>
  )
}
