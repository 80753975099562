import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './i18n'
import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './store/index'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

library.add(fal, fas, far)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
