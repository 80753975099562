/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'

/** Material UI **/
import { Box, Chip, makeStyles } from '@material-ui/core'
import GlobalInput from './TextInput'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    marginBottom: 10
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5em',
    paddingTop: '1em'
  },
  chip: {
    fontSize: '12px',
    fontWeight: '400',
    padding: '16px 0px',
    lineHeight: '14px',
    maxWidth: '250px'
  },
  chipSelected: {
    fontSize: '12px',
    fontWeight: '400',
    backgroundColor: theme.colors.iconBlue + ' !important',
    color: 'white',
    border: '1px solid ' + theme.colors.iconBlue,
    padding: '16px 0px',
    lineHeight: '14px',
    maxWidth: '250px'
  }
}))

export default function GlobalChip(props) {
  const classes = useStyles()
  const { allowNoSelection, singleSelection } = props
  const [chips, setChips] = useState(props.chips)
  const { t } = useTranslation()

  useEffect(() => {
    if (!allowNoSelection && chips?.length > 0) handleSelectionChanged(chips[0])
  }, [])
  useEffect(() => {
    setChips(props.chips)
  }, [props.chips])

  function handleSelectionChanged(id, event) {
    const newSet = new Set(props.selected)

    if (singleSelection) {
      if (newSet.has(id) && allowNoSelection) {
        newSet.clear()
      } else {
        newSet.clear()
        newSet.add(id)
      }
    } else {
      if (newSet.has(id)) {
        // filter chips selected
        newSet.delete(id)
        if (props.removeItem) props.removeItem(id)
      } else {
        newSet.add(id)
      }
    }

    if (!newSet.size && !allowNoSelection) {
      newSet.add(id)
    }
    props.setSelected(newSet)
  }

  // function for search input
  function handleFilterChange(value) {
    const result = value
      ? props.chips.filter(chip =>
          t('company_profile.' + props.parent + '.' + chip)
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      : props.chips
    setChips(result)
  }

  return (
    <Box display="flex" flexDirection="column">
      {props.searchVisible && (
        <Box width="260px" marginTop="1em">
          <GlobalInput
            placeholder="Search trades"
            onChange={handleFilterChange}
          />
        </Box>
      )}
      <Box role="group" className={classes.chipContainer}>
        {chips && chips?.map(c =>
          props.disabled ? (
            <Chip
              key={c}
              variant="default"
              label={
                props.skipTranslate
                  ? (props.useCatalog ? props.useCatalog[c] : c)
                  : t('company_profile.' + props.parent + '.' + c)
              }
              className={
                props.selected?.has(c) ? classes.chipSelected : classes.chip
              }
            ></Chip>
          ) : (
            <Chip
              key={c}
              onClick={event => handleSelectionChanged(c, event)}
              variant="default"
              label={
                props.skipTranslate
                  ? (props.useCatalog ? props.useCatalog[c] : c)
                  : t('company_profile.' + props.parent + '.' + c)
              }
              className={
                props.selected?.has(c) ? classes.chipSelected : classes.chip
              }
            ></Chip>
          )
        )}
      </Box>
    </Box>
  )
}
