import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  makeStyles,
  Checkbox
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  switchDialog: {
    borderRadius: '9px',
    padding: '15px',
    maxWidth: '700px',
    maxHeight: '420px'
  },
  title: {
    fontWeight: '700',
    fontSize: '20px',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.colors.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'
  },
  dialogContent: {
    fontSize: '14px',
    color: theme.colors.black,
    textAlign: 'center',
    fontWeight: '300',
    maxHeight: '223px'
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%'
  },
  buttonStyle: {
    borderRadius: '44px',
    textTransform: 'none',
    fontSize: '14px',
    padding: '10px 15px',
    fontWeight: 'bold',
    minWidth: '90px',
    color: theme.colors.dialog.text_grey,
    '&:hover': {
      backgroundColor: theme.colors.dialog.hover,
      borderColor: theme.colors.dialog.hover,
      boxShadow: 'none'
    }
  },
  acceptButton: {
    margin: 'auto',
    borderRadius: '44px',
    textTransform: 'none',
    fontSize: '20px',
    padding: '10px 15px',
    fontWeight: 'bold',
    width: '161px',
    height: '58px',
    color: theme.colors.dialog.text_white,
    border: '0.5px solid ' + theme.colors.dialog.ok_button,
    backgroundColor: theme.colors.dialog.ok_button,
    '&:hover': {
      backgroundColor: theme.colors.dialog.hover,
      borderColor: theme.colors.dialog.hover,
      boxShadow: 'none'
    }
  },
  warning: {
    fontSize: '12px',
    fontWeight: '300',
    color: theme.colors.black,
    lineHeight: '14px',
    margin: 'auto 0px'
  },
  checkbox: {
    transform: 'scale(0.8)'
  },
  checked: {
    transform: 'scale(0.8)',
    color: `${theme.palette.primary.light} !important`
  }
}))

export const SubmitConfirmation = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [checked, setChecked] = React.useState(false)

  return (
    <Dialog
      open={props.open}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      classes={{ paper: classes.switchDialog }}
      onClose={props.handleClose}
    >
      <DialogTitle>
        <Typography className={classes.title}>{props.title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          classes={{ root: classes.dialogContent }}
          id="alert-dialog-slide-description"
        >
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box
          className={`${classes.buttonsContainer}`}
        >
          <Button
            variant="outlined"
            className={classes.acceptButton}
            onClick={() => props.handleAccept(checked)}
          >
            {t('general.labels.accept')}
          </Button>
        </Box>
      </DialogActions>
      {!props.hideCheckBox && (
        <Box display="flex">
          <Checkbox
            size="small"
            classes={{ root: classes.checkbox, checked: classes.checked }}
            checked={checked}
            onChange={() => setChecked(!checked)}
          />
          <Typography className={classes.warning}>
            {t('invoices.pop_up_warning')}
          </Typography>
        </Box>
      )}
    </Dialog>
  )
}
