export const forgotPassword = () => {
  return {
    title: 'OLVIDÓ SU CONTRASEÑA',
    email_sent: 'UN EMAIL DE RESTABLECIMIENTO DE CONTRASEÑA ENVIADO',
    subtitle_1: 'Ingrese el correo que fue usado para crear su cuenta.',
    subtitle_2:
      'Su email de restablecimiento de contraseña esta en camino. Asegurese de revisar su carpeta de spam o basura para recibirlo.',
    email: 'Correo',
    send: 'Enviar',
    back_to_log_in: 'Regresar a Iniciar Sesión',
    back: 'Regresar',
    email_not_found: 'Lo sentimos, este email no se encuentra en el sistema'
  }
}
