import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  Link,
  makeStyles,
  ThemeProvider,
  useTheme
} from '@material-ui/core'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

/** Components **/
import LandingSetupHeader from '../../components/LandingSetupHeader'
import GlobalInput from '../../components/form/TextInput'
import GlobalSelect from '../../components/form/Select'
import GlobalNumberInput from '../../components/form/NumberInput'
import ftcLandingImage from '../../assets/images/landing1.png'

/** Services **/
import {
  buttonSettingsDisabled,
  checkboxTheme,
  enableButtonStyle
} from '../../styles/mui_custom_theme'
import * as ApiServices from '../../services/ApiService'
import { login } from '../../services/AuthService'
import { loadingActions } from '../../store/loading'
import { handleSpace, validatePhone } from '../../lib/Global'

const pjson = require('../../../package.json')

const useStyles = makeStyles(theme => ({
  mainContainer: {
    textAlign: '-webkit-center',
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  formContent: {
    margin: '0 25%'
  },
  bottomContent: {
    margin: '2em'
  },
  leftImage: {
    height: '300px',
    [theme.breakpoints.down('md')]: {
      height: '150px'
    }
  },
  leftGrid: {
    display: 'flex',
    paddingLeft: 0
  },
  continueButton: {
    '&.MuiButton-root': {
      width: '160px',
      height: '60px',
      borderRadius: '36px',
      fontWeight: '700',
      fontSize: '20px',
      alignSelf: 'center'
    },
    '&.MuiButtonBase-root.Mui-disabled': {
      backgroundColor: theme.colors.grey_2 + ' !important'
    }
  },
  version: {
    fontSize: '20px',
    fontWeight: '300',
    alignSelf: 'flex-end',
    marginBottom: '20px',
    marginRight: '50px'
  },
  check: {
    fontWeight: '400',
    fontSize: '12px',
    color: theme.colors.settings.fieldInfo,
    width: 'auto'
  },
  dialogPaper: {
    width: '577px',
    height: '302px',
    borderRadius: '8px'
  },
  dialogTitle: {
    fontWeight: '700',
    fontSize: '24px',
    textAlign: 'center',
    letterSpacing: '0.05em'
  },
  dialogContentFont: {
    fontSize: '18px',
    textAlign: 'center',
    color: theme.colors.basicDisabledButtonColor,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '21px'
  },
  dialogContentFontSplit: {
    fontSize: '18px',
    textAlign: 'center',
    color: theme.colors.basicDisabledButtonColor,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '21px'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: '10px'
  },
  invalidBorderColor: {
    '& .MuiInputBase-input': {
      '&:invalid': {
        borderColor: `${theme.colors.profile.border_input} !important`
      }
    }
  }
}))

const LandingSetUpScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isValid, setIsValid] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [openDialog, setOpenDialog] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const history = useHistory()
  const [dataLoaded, setDataLoaded] = useState(false)
  const theme = useTheme()
  const [phone, setPhone] = useState('')
  const [phoneError, setPhoneError] = useState({
    error: false,
    touched: false
  })

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .trim()
      .required(t('account_settings.messages.errors.required')),
    lastName: yup
      .string()
      .trim()
      .required(t('account_settings.messages.errors.required')),
    role: yup
      .string()
      .trim()
      .required(t('account_settings.messages.errors.required')),
    email: yup
      .string()
      .trim()
      .email(t('account_settings.messages.errors.email'))
      .required(t('account_settings.messages.errors.required')),
    username: yup
      .string()
      .trim()
      .min(6, t('general.messages.errors.length_6'))
      .required(t('account_settings.messages.errors.required')),
    password: yup
      .string()
      .trim()
      .min(6, t('general.messages.errors.length_6'))
      .required(t('account_settings.messages.errors.required'))
  })

  /** End VALIDATIONS **/

  const [contact, setContact] = useState()
  useEffect(async () => {
    const companyId = getCompanyId()
    const originEmail = getOriginEmail()
    const contactData = await ApiServices.getContactOffline(
      companyId,
      originEmail
    )
    if (contactData?.last_accessed) {
      history.replace('sign-in')
    }
    setContact(contactData)
    setPhone(contactData?.phone)
    setDataLoaded(true)
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      lastName: '',
      role: '',
      email: '',
      username: '',
      password: ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSaveForm(values)
    }
  })

  useEffect(() => {
    // set form initial values from DB
    formik.setValues({
      firstName: contact?.first_name,
      lastName: contact?.last_name,
      email: contact?.email ?? ''
    })
  }, [contact])

  useEffect(() => {
    validateForm()
  }, [acceptTerms, formik.values])

  useEffect(() => {
    setPhoneError({ error: !validatePhone(phone), touched: true })
    validateForm()
  }, [phone])

  const getWindowHeight = () => {
    const [size, setSize] = useState([0, 0])
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, [])
    return size[1]
  }

  const getCompanyId = () => {
    const windowUrl = window.location.search
    const params = new URLSearchParams(windowUrl)
    // eslint-disable-next-line dot-notation
    const companyId = params.get('company_id')
    return companyId
  }
  const getOriginEmail = () => {
    const windowUrl = window.location.search
    const params = new URLSearchParams(windowUrl)
    // eslint-disable-next-line dot-notation
    const originEmail = params.get('origin_email')
    return originEmail
  }

  const getAffiliateId = () => {
    const windowUrl = window.location.search
    const params = new URLSearchParams(windowUrl)
    // eslint-disable-next-line dot-notation
    const affiliateId = params.get('affiliate_id')
    return affiliateId
  }

  const validateForm = () => {
    // All field are required
    const requiredFields = [
      'firstName',
      'lastName',
      'role',
      'email',
      'username',
      'password'
    ]
    let validForm = true
    for (const key of requiredFields) {
      if (!formik.values[key]) {
        validForm = false
        break
      }
    }
    if (!validatePhone(phone)) {
      setPhoneError({ ...phoneError, error: !validatePhone(phone) })
      validForm = false
    }
    setIsValid(
      validForm && formik.isValid && acceptTerms && validatePhone(phone)
    )
  }

  const handleBlur = event => {
    formik.handleBlur(event)
    validateForm()
  }

  const handleFieldFocus = fieldName => {
    formik.setFieldTouched(fieldName, false)
  }

  function handleCustomChange(value, field) {
    if (field === 'phone') {
      setPhone(value.trim())
    }
  }

  const onSaveForm = async values => {
    const affiliateId = getAffiliateId()
    try {
      await ApiServices.createClientUser(
        affiliateId,
        values.firstName,
        values.lastName,
        values.email,
        phone,
        values.username,
        '',
        '',
        values.role,
        values.password
      )

      dispatch(loadingActions.show())
      await login(values.username, values.password, '/company-profile')
      dispatch(loadingActions.hide())
    } catch (e) {
      console.error('error', e)
      switch (true) {
        case e.includes('email'):
          formik.setFieldError(
            'email',
            t('account_settings.messages.errors.email_used')
          )
          break
        case e.toLowerCase().includes('username'):
          formik.setFieldError(
            'username',
            t('account_settings.messages.errors.username_taken')
          )
          break
        default:
          setErrorMessage(e)
          setOpenDialog(true)
          break
      }
    }
  }

  return (
    <div
      hidden={!dataLoaded}
      className={classes.mainContainer}
      style={{ height: getWindowHeight() }}
    >
      <LandingSetupHeader />
      <br />
      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => setOpenDialog(false)}
        classes={{ paper: classes.dialogPaper }}
        BackdropProps={{ invisible: true }}
        style={{ zIndex: 2000 }}
        disableAutoFocus={false}
      >
        <DialogTitle
          disableTypography={true}
          classes={{ root: classes.dialogTitle }}
        >
          {t('general.messages.errors.user_creation_title')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {errorMessage && errorMessage.split('\n').length > 1 ? (
            <Box flex={1} padding="40px">
              <FormLabel className={classes.dialogContentFontSplit}>
                {errorMessage.split('\n')[0]}
              </FormLabel>
              <FormLabel className={classes.dialogContentFontSplit}>
                {errorMessage.split('\n')[1]}
              </FormLabel>
            </Box>
          ) : (
            <FormLabel className={classes.dialogContentFont}>
              {errorMessage}
            </FormLabel>
          )}

          <Box className={classes.dialogButtonContainer}>
            <ThemeProvider theme={buttonSettingsDisabled}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                type="submit"
                style={{
                  ...enableButtonStyle,
                  marginRight: 0
                }}
                onClick={() => setOpenDialog(false)}
              >
                {t('company_profile.continue')}
              </Button>
            </ThemeProvider>
          </Box>
        </DialogContent>
      </Dialog>
      <form onSubmit={formik.handleSubmit} className={classes.formContent}>
        <GlobalInput
          label={t('account_settings.info_card.first_name')}
          placeholder={
            t('account_settings.form.enter') +
            ' ' +
            t('account_settings.info_card.first_name')
          }
          field="firstName"
          id="firstName"
          name="firstName"
          onChange={formik.handleChange}
          onFocus={() => handleFieldFocus('firstName')}
          onBlur={handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          validate
          value={formik.values.firstName}
          required
          className={classes.invalidBorderColor}
        />
        <GlobalInput
          label={t('account_settings.info_card.last_name')}
          placeholder={
            t('account_settings.form.enter') +
            ' ' +
            t('account_settings.info_card.last_name')
          }
          field="lastName"
          id="lastName"
          name="lastName"
          onChange={formik.handleChange}
          onFocus={() => handleFieldFocus('lastName')}
          onBlur={handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          validate
          value={formik.values.lastName}
          required
          className={classes.invalidBorderColor}
        />
        <GlobalSelect
          label={t('account_settings.info_card.user_title')}
          placeholder={
            t('account_settings.form.enter') +
            ' ' +
            t('account_settings.info_card.user_title')
          }
          field="role"
          id="role"
          name="role"
          options={Object.keys(
            t('create_account.admin_roles', { returnObjects: true })
          ).map(key => {
            return {
              value: key,
              label: t('create_account.admin_roles', {
                returnObjects: true
              })[key]
            }
          })}
          displayvalue
          onChange={formik.handleChange}
          onBlur={handleBlur}
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={formik.touched.role && formik.errors.role}
          validate
          value={formik.values.role}
          style={{
            textAlign: 'left',
            borderColor: theme.colors.profile.border_input
          }}
          required
        />
        <GlobalInput
          label={t('general.labels.email')}
          placeholder={
            t('account_settings.form.enter') + ' ' + t('general.labels.email')
          }
          field="email"
          id="email"
          name="email"
          onChange={formik.handleChange}
          onFocus={() => handleFieldFocus('email')}
          onBlur={handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          validate
          value={formik.values.email}
          required
          className={classes.invalidBorderColor}
        />
        <GlobalNumberInput
          label={
            t('work_orders.trips.mobile') +
            ' ' +
            t('account_settings.info_card.phone_number')
          }
          placeholder={
            t('account_settings.form.enter') +
            ' ' +
            t('account_settings.info_card.phone_number')
          }
          field="phone"
          id="phone"
          name="phone"
          format="##########"
          onChange={handleCustomChange}
          onFocus={e => {
            setPhoneError({ error: false, touched: true })
          }}
          onBlur={handleBlur}
          style={{
            borderColor: theme.colors.profile.border_input
          }}
          error={phoneError.error}
          helperText={
            phoneError.touched && phoneError.error
              ? t('general.messages.errors.phone')
              : ''
          }
          value={phone}
          required
        />
        <GlobalInput
          label={t('account_settings.info_card.username')}
          placeholder={
            t('invoices.in_actions.create') +
            ' ' +
            t('account_settings.info_card.username')
          }
          field="username"
          id="username"
          name="username"
          onChange={formik.handleChange}
          onFocus={e => {
            handleFieldFocus('username')
            e.target.select()
          }}
          onBlur={handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
          validate
          value={formik.values.username}
          required
          className={classes.invalidBorderColor}
          onKeyDown={e => handleSpace(e)}
        />
        <GlobalInput
          label={t('account_settings.info_card.password')}
          placeholder={
            t('account_settings.form.enter') +
            ' ' +
            t('account_settings.info_card.password')
          }
          field="password"
          type="password"
          id="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={handleBlur}
          onFocus={e => {
            handleFieldFocus('password')
            e.target.select()
          }}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          validate
          value={formik.values.password}
          required
          className={classes.invalidBorderColor}
          onKeyDown={e => handleSpace(e)}
        />
        <Box display="flex" alignItems="center">
          <ThemeProvider theme={checkboxTheme}>
            <Checkbox
              className={classes.checkbox}
              checked={acceptTerms}
              onClick={() => setAcceptTerms(!acceptTerms)}
            />
            <FormLabel classes={{ root: classes.check }}>
              I accept the ConnectAD Platform{' '}
              <Link target="_blank" href="https://automateddecision.com/tos/">
                terms and conditions
              </Link>
            </FormLabel>
          </ThemeProvider>
        </Box>
        <Box display="flex" className={classes.bottomContent}>
          <Box display="flex" justifyContent="flex-start" flex={2}>
            <img
              className={classes.leftImage}
              alt={'ftc_logo'}
              src={ftcLandingImage}
            />
          </Box>
          <Box display="flex" flex={1} flexDirection="column">
            <Box display="flex">
              <Button
                variant="contained"
                className={classes.continueButton}
                disabled={!isValid}
                type="submit"
              >
                {t('company_profile.continue')}
              </Button>
            </Box>
            <Box
              display="flex"
              alignitems="flex-end"
              justifyContent="flex-end"
              marginTop="auto"
            >
              <FormLabel className={classes.version}>
                v{pjson.version}
              </FormLabel>
            </Box>
          </Box>
        </Box>
      </form>
    </div>
  )
}

export default LandingSetUpScreen
