import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { FileThumbCorner } from '../../assets/icons/FileThumbCorner'
import { filePreviewStyles } from '../../styles/classes/WorkOrderClasses'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const FilePreview = ({ filePath, fileType, fileName }) => {
  const classes = filePreviewStyles()

  const fileViewer = useMemo(() => {
    return (
      <div>
        <Box className={classes.fileBox}>
          <FontAwesomeIcon
            icon={
              fileType === 'pdf'
                ? 'fa-regular fa-file-pdf'
                : fileType === 'xls' || fileType === 'xlsx'
                ? 'fa-regular fa-file-excel'
                : fileType === 'doc' || fileType === 'docx'
                ? 'fa-regular fa-file-word'
                : fileType === 'csv'
                ? 'fa-regular fa-file-csv'
                : 'fa-regular fa-file'
            }
            size="2xl"
          />
        </Box>
        <Box className={classes.thumbBox}>
          <FileThumbCorner type={fileType} name={fileName} />
        </Box>
      </div>
    )
  }, [filePath, fileType])

  return fileViewer
}
