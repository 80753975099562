import React from 'react'
import {
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { TableContainer } from '@mui/material'
import { useTranslation } from 'react-i18next'
import NumberFormat from 'react-number-format'
import LoadingBox from './LoadingBox'

const useStyles = makeStyles(theme => ({
  summaryLabel: {
    backgroundColor: theme.colors.invoiceColors.summary_bg,
    fontSize: '12px',
    height: '22px',
    paddingTop: '3px',
    paddingBottom: '3px',
    color: theme.colors.invoiceColors.dark_grey,
    fontWeight: '700',
    borderBottom: '0px'
  },
  tableHeader: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.invoiceColors.table_text,
    paddingTop: '5px',
    paddingBottom: '5px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  tableRow: {
    height: '54px'
  },
  tableItem: {
    fontSize: '12px',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  tableDescItem: {
    fontSize: '12px',
    width: '52%',
    color: theme.colors.invoiceColors.table_text,
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  selectItem: {
    fontSize: '12px',
    minWidth: '130px',
    maxWidth: '130px',
    borderBottom: '1px solid ' + theme.colors.invoiceColors.borders
  },
  descriptionItem: {
    fontSize: '12px',
    width: '100%',
    paddingLeft: '0px'
  },
  inputAmountItem: {
    fontSize: '12px',
    minWidth: '95px'
  }
}))

function EnhancedTable(props) {
  const { itemTag, items, loading, scheduled } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const columns = [
    {
      field: 'item',
      headerName: t('invoices.column_names.item')
    },
    {
      field: 'trade',
      headerName: t('invoices.column_names.trade')
    },
    {
      field: 'description',
      headerName: t('invoices.column_names.description')
    },
    {},
    {
      field: 'amount',
      headerName: t('invoices.column_names.item_amount')
    }
  ]

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={8} classes={{ root: classes.summaryLabel }}>
              {loading ? '' : `${itemTag}`}
            </TableCell>
          </TableRow>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={column.field + index}
                classes={{ root: classes.tableHeader }}
              >
                {loading ? (
                  <LoadingBox width="100%" height="20px" />
                ) : (
                  column.headerName
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((row, index) => (
            <TableRow
              key={row.id + '-' + index}
              classes={{ root: classes.tableRow }}
            >
              <TableCell classes={{ root: classes.selectItem }}>
                {row.item}
              </TableCell>
              <TableCell classes={{ root: classes.tableItem }}>
                {row.trade}
              </TableCell>
              <TableCell classes={{ root: classes.tableDescItem }}>
                <Input
                  classes={{ root: classes.descriptionItem }}
                  readOnly={true}
                  name="description"
                  multiline
                  maxRows={3}
                  disableUnderline
                  value={row.description}
                  disabled={scheduled}
                />
              </TableCell>
              <TableCell classes={{ root: classes.tableItem }} />
              <TableCell classes={{ root: classes.tableItem }}>
                <NumberFormat
                  name="amount"
                  thousandSeparator
                  customInput={Input}
                  disableUnderline
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={2}
                  value={row.amount}
                  disabled
                  classes={{
                    input: classes.inputAmountItem
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const ScheduledDetailComponent = props => {
  const { itemTag, items, loading, scheduled } = props
  return (
    <EnhancedTable
      itemTag={itemTag}
      items={items}
      loading={loading}
      scheduled={scheduled}
    />
  )
}
