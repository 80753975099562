import { useEffect, useState } from 'react'
import { invoicesPortal } from '../../services/ApiService'
import ReactGA from 'react-ga4'

// redux
import { useSelector, useDispatch } from 'react-redux'
import { invoicesActions } from '../../store/invoices'
import { useParams } from 'react-router-dom'
import { isRunningInIframe } from '../../lib/Global'

export const useInvoiceSearch = (
  showAll,
  search,
  won,
  inStatus,
  client,
  location,
  invoiceNumber,
  amount,
  createDate,
  dueDate,
  payDate,
  achCheckNumber,
  ascSort,
  descSort,
  companyId,
  perPage,
  page,
  searchEnabled
) => {
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [invoices, setInvoices] = useState([])
  const [openInvoices, setOpenInvoices] = useState([])
  const [chips, setChips] = useState({})
  const [hasMore, setHasMore] = useState(false)
  const invoicesStore = useSelector(state => state.invoices)
  const dispatch = useDispatch()

  if (isRunningInIframe()) {
    companyId = params.id
  }

  useEffect(() => {
    ReactGA.event({
      category: 'search',
      action: 'search_invoices'
    })
  }, [search])

  useEffect(async () => {
    try {
      if (invoicesStore.invoices && invoicesStore.invoices.length > 0) {
        setInvoices(invoicesStore.invoices)
        setChips({
          totalCount: invoicesStore.invoices.length
        })
        dispatch(invoicesActions.setInvoices([]))
        setOpenInvoices(invoicesStore.openInvoices)
        dispatch(invoicesActions.setOpenInvoices([]))
      } else {
        if (!searchEnabled) return
        setLoading(true)
        setError(false)
        const result = await invoicesPortal(
          showAll,
          search,
          won,
          inStatus,
          client,
          location,
          invoiceNumber,
          amount,
          createDate,
          dueDate,
          payDate,
          achCheckNumber,
          ascSort ? ascSort + '' : descSort ? descSort + '_d' : '',
          companyId,
          perPage,
          page
        )
        let count = invoices ? invoices.length : 0
        if (result && result.status) {
          if (page === 1) {
            setInvoices(result.content.invoices)
            count = result.content.invoices.length
          } else {
            setInvoices([...invoices, ...result.content.invoices])
            count = result.content.invoices.length + invoices.length
          }
          setChips({
            totalCount: result.content.totalCount
          })
        }
        setOpenInvoices(result.content.openInvoices)
        setHasMore(count < result.content.totalCount)
        setLoading(false)
      }
    } catch (error) {
      console.error(error)
    }
  }, [
    showAll,
    search,
    won,
    inStatus,
    client,
    location,
    invoiceNumber,
    amount,
    createDate,
    dueDate,
    payDate,
    achCheckNumber,
    ascSort,
    descSort,
    perPage,
    page,
    searchEnabled
  ])
  return { loading, error, invoices, setInvoices, chips, hasMore, openInvoices }
}
