import React, { useState } from 'react'
/** Components **/
import { Card, Button, ClickAwayListener } from '@mui/material'
import { useTranslation } from 'react-i18next'
/** Icons **/
import csvIcon from '../../assets/icons/csv.svg'
import ReactGA from 'react-ga4'
/** Services **/
import { makeStyles } from '@material-ui/core'
import { ConfirmDialog } from '../form/ConfirmDialog'
/** Styles **/
const useStyles = makeStyles(theme => ({
  Main: {
    position: 'absolute'
  },
  Image: {
    padding: '0px',
    width: '9px',
    height: '12px',
    marginRight: '10px'
  },
  csvItem: {
    '&.MuiButton-root': {
      justifyContent: 'left',
      width: '100%',
      fontSize: '12px',
      textTransform: 'none',
      color: theme.colors.text,
      fontWeight: 400
    }
  },
  Card: {
    top: '8px',
    borderRadius: '8px',
    position: 'absolute',
    zIndex: '100',
    width: '206px',
    padding: '12px'
  }
}))
export const ExportCard = props => {
  const { t } = useTranslation()
  const [showMessage, setShowMessage] = useState(false)

  // parent component i.e. work_orders or invoices, etc.
  const { parent } = props

  const handleClose = () => {
    props.setOpen(false)
    setShowMessage(false)
  }
  const handleDownload = () => {
    ReactGA.event({
      category: 'download',
      action: 'download_selected_' + parent
    })
    handleClose()
    props.requestData()
  }
  const handleDownloadAll = () => {
    ReactGA.event({
      category: 'download',
      action: 'download_all_' + parent
    })
    // If the file exceeds 5000 rows, it will be sent via email
    if (props.chips.totalCount <= 5000) {
      handleClose()
      props.requestData('all')
    } else {
      // TODO Send CSV Report via email
      setShowMessage(true)
    }
  }

  const handleAccept = () => {
    // TODO Send CSV Report via email
  }

  const classes = useStyles()
  return props.open ? (
    <div className={classes.Main}>
      <ClickAwayListener onClickAway={handleClose}>
        <Card className={classes.Card}>
          <Button className={classes.csvItem} onClick={handleDownload}>
            <img className={classes.Image} src={csvIcon} />
            {t(parent + '.download_selected')} ({props.selected.length})
          </Button>
          <Button className={classes.csvItem} onClick={handleDownloadAll}>
            <img className={classes.Image} src={csvIcon} />
            {t(parent + '.download_all')} ({props.chips.totalCount} total)
          </Button>
        </Card>
      </ClickAwayListener>
      <ConfirmDialog
        open={showMessage}
        setOpen={setShowMessage}
        message={t(parent + '.messages.max_records')}
        handleAccept={handleAccept}
        acceptlabel="Ok"
      />
    </div>
  ) : null
}
