import React, { useState, useEffect } from 'react'
import {
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  Box
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import { AddButton } from '../../AddButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: '0px'
  },
  fieldMessage: {
    marginBottom: '14px',
    color: theme.colors.workOrders.downloadIcon,
    fontSize: '14px',
    fontWeight: '400',
    marginLeft: '5px'
  },
  notesBox: {
    maxHeight: '10rem',
    overflowY: 'auto'
  },
  notes: {
    color: theme.colors.workOrders.counts,
    fontSize: '14px',
    fontWeight: '400',
    marginLeft: '5px',
    lineHeight: '22px !important'
  },
  photoField: {
    marginBottom: '2px',
    marginLeft: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  field: {
    marginBottom: '2px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  textField: {
    ...theme.filtersClasses.mainInput2,
    width: '100%',
    height: 'auto',
    border: 'none',
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '18px',
      padding: '11px 12px 20px 5px'
    },
    '& .MuiInputBase-multiline': {
      padding: '0px'
    }
  },
  numberField: {
    ...theme.filtersClasses.mainInput2,
    height: 'auto',
    border: 'none',
    fontSize: '14px',
    lineHeight: '18px',
    padding: '17px 12px 17px 5px',
    width: '285px',
    borderRadius: '8px'
  },
  mandatory: {
    color: theme.colors.errorColor
  },
  textAlign: {
    '& .MuiInputBase-input': {
      padding: '17px 12px 17px 19px'
    }
  },
  icon: {
    color: theme.colors.company.iconColor,
    marginRight: '15px'
  },
  clearIcon: {
    position: 'absolute',
    top: '20px',
    right: 0
  },
  clearIconNumber: {
    position: 'absolute',
    top: '25px',
    right: 0
  },
  numberContainer: {
    position: 'relative'
  }
}))

export const NotesComponent = props => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    title,
    notes,
    disabled,
    onUpdate,
    type,
    mandatory,
    catalogs,
    suffix,
    notAvailable,
    section,
    questionId,
    configSchema,
    alwaysExpanded,
    hideClearIcon
  } = props
  const [value, setValue] = useState(notes)
  const [expanded, setExpanded] = useState(alwaysExpanded || false)

  useEffect(() => {
    if (alwaysExpanded) {
      setExpanded(true)
    }
  }, [alwaysExpanded])

  const onBlur = text => {
    const blurValue = getSuffix() ? `${text} ${getSuffix()}` : text
    setValue(blurValue)
    if (configSchema === 'v2') {
      onUpdate(section, questionId, blurValue)
    } else {
      onUpdate(blurValue)
    }
  }

  const handleChangeValues = event => {
    const newValue =
      type === 'number'
        ? validateDecimals(event.target.value.toString())
        : event.target.value
    setValue(newValue)
    if (alwaysExpanded) {
      onUpdate(newValue)
    }
  }

  const validateDecimals = text => {
    const { decimals } = props
    if (decimals && text && text.includes('.')) {
      const numberOfDecimals = text.split('.')[1]?.length
      if (numberOfDecimals && numberOfDecimals >= decimals + 1) {
        // +1 for limit the new decimal
        return text.slice(0, -1)
      }
    }
    return text
  }

  const hasSuffix = text => {
    const textArray = text?.split(' ')
    return !!textArray && !!textArray[1]
  }

  const getNumber = text => {
    const textArray = text?.split(' ')
    return textArray[0]
  }

  const removeSuffix = () => {
    let newText = notes
    if (hasSuffix(newText)) {
      newText = getNumber(newText)
      setValue(newText)
    }
  }

  const getSuffix = () => {
    let suff = ''
    if (catalogs && suffix && catalogs[suffix]) {
      suff = catalogs[suffix]
    }
    return suff
  }

  return (
    <Grid className={classes.container}>
      {!alwaysExpanded && (
        <FormLabel
          component="legend"
          className={`${classes.photoField} ${
            !disabled && mandatory ? classes.mandatory : null
          } ${props?.customClasses?.title}`}
        >
          {title || t('work_orders.trips.notes')}
        </FormLabel>
      )}
      {disabled ? (
        <Box className={classes.notesBox}>
          <FormLabel
            classes={{
              root: notes ? classes.notes : classes.fieldMessage
            }}
          >
            {notes ||
              (notAvailable
                ? t('general.labels.not_available')
                : t('work_orders.checkout_message'))}
          </FormLabel>
        </Box>
      ) : !expanded && !notes ? (
        <AddButton label={title} callback={() => setExpanded(true)} />
      ) : type === 'number' ? (
        <div className={classes.numberContainer}>
          <NumberFormat
            thousandSeparator
            allowEmptyFormatting
            decimalScale={2}
            decimalSeparator="."
            value={value}
            onChange={handleChangeValues}
            onBlur={value !== notes ? () => onBlur(value) : null}
            className={`${classes.numberField}`}
            onFocus={removeSuffix}
          />
          {value && !hideClearIcon && (
            <InputAdornment position="end" className={classes.clearIconNumber}>
              <IconButton
                onClick={() => {
                  handleChangeValues({ target: { value: '' } })
                  if (configSchema === 'v2') {
                    onUpdate(section, questionId, '')
                  } else {
                    onUpdate('')
                  }
                }}
                className={classes.icon}
                size="small"
              >
                <FontAwesomeIcon icon={['far', 'xmark']} size="xs" />
              </IconButton>
            </InputAdornment>
          )}
        </div>
      ) : (
        <TextField
          className={`${classes.textField}`}
          multiline
          maxRows={10}
          minRows={4}
          value={value}
          type={type}
          onChange={handleChangeValues}
          onBlur={value !== notes ? () => onBlur(value) : null}
          InputProps={{
            placeholder: alwaysExpanded
              ? t('work_orders.enter_return_reason')
              : undefined,
            endAdornment: value && !hideClearIcon && (
              <InputAdornment position="end" className={classes.clearIcon}>
                <IconButton
                  onClick={() => {
                    handleChangeValues({ target: { value: '' } })
                    if (configSchema === 'v2') {
                      onUpdate(section, questionId, '')
                    } else {
                      onUpdate('')
                    }
                  }}
                  className={classes.icon}
                  size="small"
                >
                  <FontAwesomeIcon icon={['far', 'xmark']} size="xs" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      )}
    </Grid>
  )
}
