// main components
import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

import { TextInput } from './TextInput'

import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateAccountSettings } from '../../services/ApiService'

import imageToBase64 from 'image-to-base64/browser'
import avatarImage from '../../assets/images/account_avatar.jpeg'

// mui components
import {
  Avatar,
  FormGroup,
  Link,
  Drawer,
  FormLabel,
  Box,
  Typography,
  Button,
  ThemeProvider,
  Grid,
  useTheme
} from '@material-ui/core'
import { Selector } from './Selector'
import { store } from '../../store'
import { authActions } from '../../store/signIn'
import * as ApiServices from '../../services/ApiService'
import {
  buttonSettingsDisabled,
  disableButtonStyle,
  enableButtonStyle
} from '../../styles/mui_custom_theme'
import { isEqual } from 'lodash'
import {
  adminRole,
  apiErrors,
  apiMessages,
  navBarHeaderHeight
} from '../../lib/Constants'
import { useSelector } from 'react-redux'
import { ConfirmDialog } from '../form/ConfirmDialog'
import { handleSpace, isRunningInIframe } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  presentation: {
    padding: '0px 5px',
    paddingBottom: '40px',
    marginTop: '19px',
    overflowY: 'auto'
  },
  title: {
    color: 'black',
    fontSize: '20px',
    fontWeight: '700',
    font: 'Rubik',
    margin: '32px 0px'
  },
  save: {
    textTransform: 'none',
    fontSize: '18px',
    font: 'Rubik',
    fontWeight: '700',
    color: theme.colors.backgroundColor,
    borderRadius: '100px',
    borderColor: theme.colors.textGray,
    width: '161px',
    height: '49px',
    backgroundColor: theme.colors.textGray,
    float: 'right',
    marginRight: 36
  },
  errorMessage: {
    color: theme.colors.errorText,
    fontWeight: '400',
    fontSize: '12px'
  },
  avatar: {
    width: '140px',
    height: '140px'
  },
  photoPicker: {
    display: 'none'
  },
  photoPickerButton: {
    borderColor: 'transparent',
    color: theme.colors.basicDisabledButtonColor,
    fontSize: '10px',
    marginTop: '6px'
  },
  imageGroup: {
    alignItems: 'center',
    width: '313px'
  },
  drawerPaper: {
    maxHeight: `calc(100% - calc(${navBarHeaderHeight + ' + 12px'}))`,
    marginTop: navBarHeaderHeight,
    width: '360px',
    borderRadius: '8px',
    overflow: 'auto',
    overflowX: 'hidden',
    zIndex: 1200,
    boxSizing: 'content-box',
    marginRight: '19px',
    marginBottom: '20px',
    display: 'flex'
  },
  element: {
    marginTop: '20px'
  },
  footer: {
    marginTop: '10px',
    height: '49px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  drawerContainer: { height: '100%', position: 'relative' },
  drawerTitle: { alignItems: 'center', paddingLeft: '20px' },
  drawerContent: {
    marginLeft: '20px',
    marginRight: '20px'
  },
  displayXButton: {
    '& .MuiIconButton-label': {
      display: 'none'
    },
    '& .MuiInputBase-root': {
      flexWrap: 'wrap'
    },
    '& .MuiFilledInput-inputAdornedEnd': {
      paddingRight: '12px'
    }
  },
  switchDialog: {
    borderRadius: '20px'
  },
  dialogTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    letterSpacing: '0.05em'
  },
  dialogContent: {
    fontSize: '16px',
    color: theme.colors.basicDisabledButtonColor
  },
  saveButton: {
    fontSize: '18px !important'
  },
  deleteButton: {
    alignSelf: 'flex-start',
    marginLeft: '20px',
    marginRight: '0',
    color: theme.colors.errorColor,
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: '600',
    letterSpacing: '0.05em',
    lineHeight: '19px',
    padding: '10px',
    width: '120px'
  }
}))

export const UpdateAccountInfo = props => {
  const {
    editDrawer,
    handleClosePanel,
    accountInfo,
    roles,
    event,
    updateUsers,
    affiliateId,
    mobile
  } = props
  const photoPicker = useRef()
  const photo = useRef()
  const classes = useStyles()
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [photoBase64, setPhotoBase64] = useState()
  const [enableSave, setEnableSave] = useState()
  const userStore = useSelector(state => state.auth.user)
  const [disableRole, setDisableRole] = useState()
  const theme = useTheme()

  const [openAlert, setOpenAlert] = useState(false)
  const [sendInvite, setSendInvite] = useState(false)
  const [popupMessage, setPopupMessage] = useState()
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false)

  const [openConfirm, setOpenConfirm] = useState(false)

  const passwordPlaceHolder = accountInfo.userInfo.firstName ? '********' : ''
  const startingInfo = {
    firstName: accountInfo.userInfo.firstName,
    lastName: accountInfo.userInfo.lastName,
    email: accountInfo.userInfo.email,
    phone: accountInfo.userInfo.phone,
    username: accountInfo.userInfo.username,
    photo_url: accountInfo.userInfo.photo_url,
    roles: accountInfo.userInfo.roles,
    role: accountInfo.userInfo.role,
    password: passwordPlaceHolder,
    passwordConfirm: passwordPlaceHolder
  }
  const [updatedInfo, setUpdatedInfo] = useState({ ...startingInfo })
  const [disableDeleteUser, setDisableDeleteUser] = useState(false)
  const [reactivateUser, setReactivateUser] = useState(false)
  const [isIframe] = useState(isRunningInIframe())
  const [companyId, setCompanyId] = useState()

  /* Image Handling */
  let firstRender = true
  useEffect(async () => {
    try {
      setDisableDeleteUser(
        userStore?.userInfo?.email === accountInfo.userInfo.email
      )
      setDisableRole(userStore?.userInfo?.scopes?.name !== adminRole)
      setCompanyId(
        isIframe
          ? userStore?.userInfo?.lastLoginCompany
          : userStore?.userInfo?.company_id
      )

      if (firstRender) {
        let newBase64
        imageToBase64(accountInfo.userInfo.photo_url ?? avatarImage)
          .then(response => {
            newBase64 = `data:image/jpeg;base64,${response}`
            setPhotoBase64(newBase64)
          })
          .catch(error => {
            console.error(error)
          })
        firstRender = false
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (reactivateUser) {
      updateUsers()
    }
  }, [reactivateUser])

  const changeFileHandler = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setPhotoBase64(reader.result)
      setUpdatedInfo({ ...updatedInfo, photo_url: reader.result })
    }
    reader.onerror = function (error) {
      console.error('Error: ', error)
    }
  }

  const handleImagePicker = event => {
    photoPicker.current.click()
  }

  /* End Image Handling */
  useEffect(async () => {
    if (editDrawer) {
      try {
        setOpen(true)
      } catch (err) {
        console.error(err)
      }
    }
  }, [editDrawer])

  const handleClose = u => {
    setOpen(false)
    handleClosePanel(accountInfo)
  }

  useEffect(() => {
    let save = true
    if (
      !updatedInfo.firstName ||
      !updatedInfo.lastName ||
      !updatedInfo.email ||
      !updatedInfo.username ||
      (!updatedInfo.roles && !mobile) ||
      (updatedInfo.password && !updatedInfo.passwordConfirm) ||
      (!updatedInfo.password && updatedInfo.passwordConfirm) ||
      (!updatedInfo.password && !updatedInfo.passwordConfirm)
    ) {
      save = false
    }
    if (isEqual(updatedInfo, startingInfo)) save = false
    setEnableSave(save)
  }, [updatedInfo])

  /** VALIDATIONS **/
  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .required(t('account_settings.messages.errors.required')),
    lastName: yup
      .string()
      .required(t('account_settings.messages.errors.required')),
    email: yup
      .string()
      .required(t('account_settings.messages.errors.required'))
      .email(t('account_settings.messages.errors.email')),
    phone: yup
      .string()
      .trim()
      .required(t('account_settings.messages.errors.required'))
      .min(10, t('general.messages.errors.phone')),
    username: yup
      .string()
      .required(t('account_settings.messages.errors.required'))
      .min(6, t('general.messages.errors.length_6')),
    password: yup
      .string()
      .required(t('account_settings.messages.errors.required'))
      .min(6, t('general.messages.errors.length_6')),
    passwordConfirm: yup
      .string()
      .required(t('account_settings.messages.errors.required'))
      .min(6, t('general.messages.errors.length_6'))
      .oneOf(
        [yup.ref('password')],
        t('account_settings.messages.errors.password_match')
      )
  })

  /** End VALIDATIONS **/

  /** Submit Handle **/

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema)
  })

  const onSubmit = data => {
    handleChangeUser()
  }

  const handleChangeValues = event => {
    const value = event.target.value
    setUpdatedInfo({
      ...updatedInfo,
      [event.target.name]: value
    })
  }

  const handleChangeUser = async () => {
    if (event === 'new') {
      await handleNewUser()
      return
    }
    if (event === 'edit') {
      await handleUpdateUser()
      return
    }
    try {
      setErrorMessage(null)
      clearErrors()

      let newData = {
        firstName: updatedInfo.firstName,
        lastName: updatedInfo.lastName,
        email: updatedInfo.email,
        phone: updatedInfo.phone,
        username: updatedInfo.username,
        photo_url: updatedInfo.photo_url,
        roles: updatedInfo.roles === 'no_value' ? '' : updatedInfo.roles,
        role: updatedInfo.role
      }

      if (updatedInfo.password !== passwordPlaceHolder) {
        newData = { ...newData, password: updatedInfo.password }
      }

      await updateAccountSettings(newData)
      setOpen(false)

      const newUserData = JSON.parse(JSON.stringify(accountInfo))

      newUserData.userInfo.firstName = newData.firstName
      newUserData.userInfo.lastName = newData.lastName
      newUserData.userInfo.email = newData.email
      newUserData.userInfo.phone = newData.phone
      newUserData.userInfo.username = newData.username
      newUserData.userInfo.photo_url = newData.photo_url
      newUserData.userInfo.roles = newData.roles
      newUserData.userInfo.role = newData.role
      newUserData.userInfo.password = newData.password

      store.dispatch(authActions.setUser(newUserData))
      handleClosePanel(newUserData)
    } catch (e) {
      console.error(e)
      if (e.code === 400) {
        setErrorMessage(e.message)
      } else {
        setOpen(false)
      }
    }
  }

  const handleNewUser = async () => {
    setReactivateUser(false)
    try {
      setErrorMessage(null)
      clearErrors()
      const response = await ApiServices.createClientUser(
        affiliateId,
        updatedInfo.firstName,
        updatedInfo.lastName,
        updatedInfo.email,
        updatedInfo.phone,
        updatedInfo.username,
        updatedInfo.photo_url,
        props.mobile ? 'no_value' : updatedInfo.roles,
        updatedInfo.role,
        updatedInfo.password,
        false
      )
      if (response) {
        handleClose()
        updateUsers()
      }
    } catch (error) {
      switch (error) {
        case apiErrors.ROLES_STRING:
          setErrorMessage(t('company_profile.error.roles_error'))
          break
        case apiErrors.EMAIL_EXISTS_OTHER_PARENT:
          setSendInvite(true)
          setOpenAlert(true)
          setPopupMessage(t('general.switch_company.user_exists'))
          break
        case apiErrors.EMAIL_EXISTS:
          setSendInvite(false)
          setOpenAlert(true)
          setPopupMessage(t('general.switch_company.same_parent'))
          break
        case apiErrors.USER_DELETED:
          setSendInvite(true)
          setOpenAlert(true)
          setPopupMessage(t('general.switch_company.user_exists'))
          break
        default:
          setErrorMessage(error)
          break
      }
    }
  }

  const handleUpdateUser = async () => {
    try {
      setErrorMessage(null)
      clearErrors()
      const response = await ApiServices.updateClientUser(
        accountInfo.userInfo.id,
        {
          firstName: updatedInfo.firstName,
          lastName: updatedInfo.lastName,
          email: updatedInfo.email,
          phone: updatedInfo.phone,
          username: updatedInfo.username,
          photo_url: updatedInfo.photo_url,
          roles: updatedInfo.roles === 'no_value' ? '' : updatedInfo.roles,
          role: updatedInfo.role,
          password:
            updatedInfo.password === passwordPlaceHolder
              ? undefined
              : updatedInfo.password
        }
      )
      if (response) {
        handleClose()
        updateUsers()
      }
    } catch (e) {
      console.error(e)
      if (e.details && e.details?.details?.length > 0) {
        const error = e.details.details[0]
        const name =
          error.path.substr(1, error.path.length - 1) === 'role'
            ? 'title'
            : error.path.substr(1, error.path.length - 1)
        setErrorMessage(name + ' ' + error.message)
      } else if (e.message) {
        if (e.details && e.details.code === 1012) {
          setErrorMessage(t('company_profile.error.user_error'))
        } else {
          setErrorMessage(t('company_profile.error.general_error'))
        }
      } else setErrorMessage(e)
    }
  }

  const handleDeleteUser = async () => {
    const deleteResponse = await ApiServices.deleteUser(
      accountInfo.userInfo.id,
      companyId
    )
    if (deleteResponse) {
      setOpenDeleteAlert(false)
      setOpenConfirm(deleteResponse)
    }
  }

  const onError = (errors, e) => console.error(errors, e)

  /** End Submit Handle **/
  function formatPhoneNumber(evt) {
    // Only ASCII character in that range allowed
    const ASCIICode = evt.which ? evt.which : evt.keyCode
    const selectionLength = evt.target.selectionEnd - evt.target.selectionStart
    if (
      ASCIICode < 48 ||
      ASCIICode > 57 ||
      evt.target.value.length - selectionLength >= 10
    ) {
      evt.preventDefault()
    }
  }

  const handleCloseDialog = () => {
    setOpenAlert(false)
  }

  const hanldleAccept = async () => {
    setOpenAlert(false)
    const response = await ApiServices.sendInviteEmail(
      updatedInfo.email,
      companyId
    )
    if (response === apiMessages.EMAIL_SENT) {
      setReactivateUser(true)
    }
    handleClose()
  }

  return (
    <div>
      <Drawer
        BackdropProps={{ invisible: true }}
        anchor={'right'}
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.drawerPaper }}
        disableAutoFocus
      >
        <form
          noValidate
          onSubmit={handleSubmit(onSubmit, onError)}
          onReset={reset}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.drawerTitle}>
              <FormLabel component="legend" classes={{ root: classes.title }}>
                {event === 'new'
                  ? t('account_settings.info_card.new_user_title')
                  : t('account_settings.info_card.title')}
              </FormLabel>
            </div>

            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.drawerContent}>
                <FormGroup classes={{ root: classes.imageGroup }}>
                  <Avatar
                    alt="profile"
                    src={photoBase64}
                    ref={photo}
                    classes={{ root: classes.avatar }}
                  />

                  <Link
                    size="small"
                    onClick={handleImagePicker}
                    color="primary"
                    classes={{ root: classes.photoPickerButton }}
                    type="button"
                  >
                    {t(
                      updatedInfo.photo_url
                        ? 'account_settings.form.edit'
                        : 'account_settings.form.add'
                    )}
                  </Link>
                  <input
                    ref={photoPicker}
                    style={{ display: 'none' }}
                    type="file"
                    name="file"
                    onChange={changeFileHandler}
                  />
                </FormGroup>

                <div style={{ marginTop: 12 }}></div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    overflow: 'hidden'
                  }}
                >
                  <TextInput
                    value={updatedInfo.firstName}
                    id="firstName"
                    name="firstName"
                    handleChange={handleChangeValues}
                    label={t('account_settings.info_card.first_name')}
                    error={!!errors.firstName}
                    helperText={errors.firstName && errors.firstName.message}
                    {...register('firstName')}
                    inputStyle={{
                      width: '100%',
                      borderRightColor: 'red',
                      borderRightWidth: '1px',
                      borderRightStyle: 'solid',
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderColor: theme.colors.firstNameAccountInfo
                    }}
                    required
                    className={classes.displayXButton}
                  />

                  <TextInput
                    value={updatedInfo.lastName}
                    id="lastName"
                    name="lastName"
                    handleChange={handleChangeValues}
                    label={t('account_settings.info_card.last_name')}
                    error={!!errors.lastName}
                    helperText={errors.lastName && errors.lastName.message}
                    {...register('lastName')}
                    inputStyle={{
                      width: '100%',
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0
                    }}
                    required
                    className={classes.displayXButton}
                  />
                </div>

                <TextInput
                  value={updatedInfo.email}
                  id="email"
                  name="email"
                  handleChange={handleChangeValues}
                  label={t('account_settings.info_card.email')}
                  error={!!errors.email}
                  helperText={errors.email && errors.email.message}
                  {...register('email')}
                  required
                />
                <TextInput
                  value={updatedInfo.phone}
                  id="phone"
                  name="phone"
                  handleChange={event =>
                    handleChangeValues({
                      target: {
                        name: 'phone',
                        value: event.target.value.slice(0, 10)
                      }
                    })
                  }
                  onKeyPress={formatPhoneNumber}
                  label={t('account_settings.info_card.phone_number')}
                  error={!!errors.phone}
                  helperText={errors.phone && errors.phone.message}
                  {...register('phone')}
                  required
                />

                <Selector
                  id={'roles'}
                  value={
                    mobile
                      ? t('company_settings.mobile_only')
                      : roles?.find(x => x.id === updatedInfo.roles)?.name || ''
                  }
                  label={t('account_settings.info_card.company_role')}
                  handleChange={handleChangeValues}
                  options={
                    mobile
                      ? [
                          {
                            id: 'no_value',
                            name: t('company_settings.mobile_only')
                          }
                        ]
                      : roles && roles.length > 0
                      ? [...roles]
                      : []
                  }
                  error={!!errors.roles}
                  helperText={errors.roles && errors.roles.message}
                  disabled={disableRole}
                  {...register('roles')}
                  required
                />

                <Selector
                  id={'role'}
                  value={
                    t('create_account.user_roles', {
                      returnObjects: true
                    })[updatedInfo.role] ?? ''
                  }
                  label={t('company_settings.users_card.role')}
                  handleChange={handleChangeValues}
                  options={Object.keys(
                    t('create_account.user_roles', { returnObjects: true })
                  ).map(key => {
                    return {
                      id: key,
                      name: t('create_account.user_roles', {
                        returnObjects: true
                      })[key]
                    }
                  })}
                  error={!!errors.role}
                  helperText={errors.role && errors.role.message}
                  {...register('role')}
                />

                <TextInput
                  value={updatedInfo.username}
                  id="username"
                  handleChange={handleChangeValues}
                  label={
                    t('account_settings.info_card.username') +
                    ' ' +
                    t('account_settings.form.username_chars')
                  }
                  error={!!errors.username}
                  helperText={errors.username && errors.username.message}
                  {...register('username')}
                  required
                  onFocus={e => e.target.select()}
                  onKeyDown={e => handleSpace(e)}
                />

                <TextInput
                  value={updatedInfo.password}
                  id="password"
                  name="password"
                  type="password"
                  handleChange={handleChangeValues}
                  label={t('account_settings.info_card.password')}
                  error={!!errors.password}
                  helperText={errors.password && errors.password.message}
                  {...register('password')}
                  required
                  onFocus={e => e.target.select()}
                  onKeyDown={e => handleSpace(e)}
                />

                <TextInput
                  value={updatedInfo.passwordConfirm}
                  id="passwordConfirm"
                  name="passwordConfirm"
                  type="password"
                  handleChange={handleChangeValues}
                  label={t('account_settings.info_card.password_confirm')}
                  error={!!errors.passwordConfirm}
                  helperText={
                    errors.passwordConfirm && errors.passwordConfirm.message
                  }
                  {...register('passwordConfirm')}
                  required
                  onFocus={e => e.target.select()}
                  onKeyDown={e => handleSpace(e)}
                />

                <Box pt={1} hidden={!errorMessage}>
                  <Typography align={'left'} className={classes.errorMessage}>
                    {props.errorMessage
                      ? t('account_settings.form.' + props.errorMessage)
                      : errorMessage}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <div className={classes.footer}>
              <ThemeProvider theme={buttonSettingsDisabled}>
                {event !== 'new' && !disableRole && (
                  <Button
                    className={classes.deleteButton}
                    onClick={() => {
                      setOpenDeleteAlert(true)
                    }}
                    disabled={disableDeleteUser}
                  >
                    {t('account_settings.form.delete')}
                  </Button>
                )}
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  type="submit"
                  disabled={!enableSave}
                  className={classes.saveButton}
                  style={!enableSave ? disableButtonStyle : enableButtonStyle}
                >
                  {event === 'new'
                    ? t('company_settings.bes_notifications_panel.create')
                    : t('account_settings.form.save')}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </form>
        <ConfirmDialog
          open={openAlert}
          setOpen={setOpenAlert}
          title={
            sendInvite
              ? t('general.popup.send_invite')
              : t('general.popup.alert')
          }
          message={
            sendInvite ? t('general.switch_company.invite_user') : popupMessage
          }
          cancellabel={sendInvite ? t('general.popup.cancel') : null}
          acceptlabel={
            sendInvite ? t('general.popup.send') : t('general.popup.ok')
          }
          handleAccept={sendInvite ? hanldleAccept : handleCloseDialog}
        />
        <ConfirmDialog
          open={openDeleteAlert}
          setOpen={setOpenDeleteAlert}
          title={t('general.delete_user.title')}
          message={t('general.delete_user.message')}
          highlightText={t('general.delete_user.highlight_question')}
          cancellabel={t('general.labels.cancel')}
          acceptlabel={t('general.labels.continue')}
          handleAccept={handleDeleteUser}
        />
        <ConfirmDialog
          open={openConfirm}
          setOpen={setOpenConfirm}
          title={t('general.delete_confirmed.title')}
          message={t('general.delete_confirmed.message').replace(
            '%EMAIL%',
            updatedInfo.email
          )}
          acceptlabel={t('general.labels.continue')}
          handleAccept={() => {
            setOpenConfirm(false)
            setReactivateUser(false)
            updateUsers()
            handleClose()
          }}
        />
      </Drawer>
    </div>
  )
}
