import React, { useState, useRef } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'
import { auditTrailStyles } from '../../styles/classes/WorkOrderClasses'
import { AuditAttachButton } from './AuditAttachButton'
import {
  Typography,
  Box,
  Menu,
  MenuItem,
  Button,
  Divider,
  Grid
} from '@material-ui/core'
import { ExpandMore, ExpandLess, HighlightOff, Check } from '@material-ui/icons'
import { FilePreview } from '../form/FilePreview'
import '../../styles/AuditEditor.css'
import { useSelector } from 'react-redux'
import { getOriginatingCompanyName } from '../../lib/Global'
import { clientAuditIntegration } from '../../lib/Constants'
import { EditorState } from 'draft-js'

export const AuditUpdateEditor = props => {
  const { t } = useTranslation()
  const {
    editorState = EditorState.createEmpty(),
    setEditorState,
    source,
    setSource,
    actualFiles = [],
    setActualFiles
  } = props
  const [showPlaceholder, setShowPlaceholder] = useState(true)
  const [contentType, setContentType] = useState('unstyled')
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const editorRef = useRef()
  const classes = auditTrailStyles()()
  const userStore = useSelector(state => state.auth.user)
  const clientAudit =
    userStore.userInfo.configurations?.integrations?.subcontractor_audit

  const handleOnEditorStateChange = state => {
    setEditorState(state)
  }

  const changeFocus = () => {
    if (editorRef.current) {
      editorRef.current.focusEditor()
    }
  }

  const handleFileChange = incoming => {
    if (props.files) {
      props.setFiles([...props.files, ...incoming])
    }
  }

  const handleChange = value => {
    setSource(value)
    setAnchorEl(null)
  }

  const deleteValidFile = index => {
    if (props.files && props.files.length === 1) {
      props.setFiles([])
    } else if (props.files) {
      props.setFiles(props.files.filter((element, ind) => ind !== index))
    }
  }

  const deleteActualFile = index => {
    if (actualFiles && actualFiles.length === 1) {
      setActualFiles([])
    } else if (actualFiles) {
      setActualFiles(actualFiles.filter((element, ind) => ind !== index))
    }
  }

  const isImageFormat = contentType => {
    return (
      contentType?.includes('png') ||
      contentType?.includes('jpeg') ||
      contentType?.includes('jpg') ||
      contentType?.includes('svg')
    )
  }

  const handleOpen = currentTarget => {
    if (clientAudit && clientAudit === clientAuditIntegration.salesforce) {
      setAnchorEl(currentTarget)
    }
  }

  return (
    <div onClick={changeFocus}>
      {!props.reply && (
        <Box className={classes.topBar}>
          <Typography className={classes.toLabel}>
            {t('work_orders.audit_trail.to')}
          </Typography>
          {clientAudit && clientAudit === clientAuditIntegration.salesforce ? (
            <Box
              className={classes.envSelector}
              onClick={event => handleOpen(event.currentTarget)}
            >
              {source === 'internal'
                ? t('work_orders.audit_trail.internal')
                : getOriginatingCompanyName(userStore.userInfo)}
              <div>
                {open ? (
                  <ExpandLess className={classes.iconStyle} />
                ) : (
                  <ExpandMore className={classes.iconStyle} />
                )}
              </div>
            </Box>
          ) : (
            <Typography className={classes.toLabel}>
              {t('work_orders.audit_trail.internal')}
            </Typography>
          )}
          <Menu
            open={open}
            onClose={() => setAnchorEl(null)}
            anchorEl={anchorEl}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleChange('internal')}
            >
              {t('work_orders.audit_trail.internal')}
              {source === 'internal' && <Check className={classes.checkMark} />}
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleChange('external')}
            >
              {getOriginatingCompanyName(userStore.userInfo)}
              {source === 'external' && <Check className={classes.checkMark} />}
            </MenuItem>
          </Menu>
        </Box>
      )}
      <Editor
        ref={editorRef}
        editorState={editorState}
        toolbarClassName={
          props.reply ? classes.toolbarClassName : 'toolbarClassName'
        }
        wrapperClassName={
          props.reply ? classes.wrapperClassNameReply : classes.wrapperClassName
        }
        editorClassName="editorClassName"
        onEditorStateChange={handleOnEditorStateChange}
        onContentStateChange={content => {
          if (content?.blocks && content.blocks.length > 0) {
            setContentType(content.blocks[0].type)
          }
        }}
        placeholder={
          showPlaceholder ? `${t('work_orders.audit_trail.share')}...` : ''
        }
        toolbarCustomButtons={[
          <AuditAttachButton
            key="attachbutton"
            setFiles={handleFileChange}
            files={props.files}
            reply={props.reply}
          />
        ]}
        onBlur={() => {
          contentType === 'unstyled'
            ? setShowPlaceholder(true)
            : setShowPlaceholder(false)
        }}
        onFocus={() => {
          setShowPlaceholder(false)
        }}
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            options: []
          },
          list: {
            options: ['unordered', 'ordered']
          }
        }}
      />
      <div className={classes.shareButtonDiv}>
        <Button
          disabled={
            !editorState.getCurrentContent().hasText() &&
            (!props.files || !props.files.length)
          }
          onClick={props.handleSave}
          variant="contained"
          className={
            props.reply ? classes.shareMessageReply : classes.shareMessage
          }
        >
          {t('work_orders.audit_trail.share_button')}
        </Button>
      </div>
      {props.files && props.files.length ? (
        <div>
          <Divider className={classes.editorDivider} />
          {props.files.map((validFile, index) => (
            <Box key={index} className={classes.fileListBox}>
              <HighlightOff
                className={classes.deleteIcon}
                onClick={() => deleteValidFile(index)}
              />
              <Typography className={classes.fileName}>
                {validFile ? validFile.name : ''}
              </Typography>
            </Box>
          ))}
        </div>
      ) : null}
      <Grid container className={classes.filesGrid}>
        {actualFiles.map((file, fid) => (
          <Grid key={`editor-${fid}`} xs={4} mt="5px">
            <HighlightOff
              className={classes.deleteFile}
              onClick={() => deleteActualFile(fid)}
            />
            {isImageFormat(file.contentType) ? (
              <Box className={classes.imageBox}>
                <img src={file.url} className={classes.imageAttach} />
              </Box>
            ) : (
              <div className={classes.fileDiv}>
                <FilePreview
                  filePath={file.url}
                  fileType={file.contentType}
                  fileName={`editor-${fid}`}
                />
              </div>
            )}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
