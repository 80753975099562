import { makeStyles } from '@material-ui/core'
import React from 'react'

import { Box, Typography, TextField, TextareaAutosize } from '@mui/material'
import theme from '../../../styles/mui_theme'

const useStyles = makeStyles(theme => ({
  inputComponetStyle: {
    '&:hover fieldset': {
      borderColor: theme.colors.grey_2
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.colors.grey_2
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '6px',
      martginTop: '5px',
      '& fieldset': {
        borderColor: theme.colors.grey_2
      },
      '&:hover fieldset': {
        borderColor: theme.colors.grey_2
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.grey_2
      }
    },
    '& .MuiInputBase-root': {
      height: '30px',
      backgroundColor: theme.colors.grey_2,
      fontSize: '12px'
    },
    '& .MuiInputBase-input::placeholder': {
      color: theme.colors.textGray,
      opacity: '1'
    },
    '& .MuiInputBase-input': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.grey_2
    }
  },
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inputComponentContainer: {
    marginBottom: '5px'
  },
  divider: {
    height: '5px'
  },
  areaAutosize: {
    boxSizing: 'border-box',
    width: '100%',
    borderRadius: '6px',
    background: theme.colors.grey_2,
    fontSize: '12px',
    minHeight: '22px',
    border: 'none',
    paddingTop: '5px',
    paddingLeft: '14px',
    outline: 'none',
    '&::focus': {
      border: 'none'
    },
    '&::hover': {
      border: 'none'
    },
    '&::placeholder': {
      color: theme.colors.textGray,
      opacity: '1'
    }
  }
}))

function LineItemInput({
  label,
  value,
  onChange,
  placeholder,
  required,
  disabled,
  rightAction,
  customClasses,
  multiline,
  type,
  hideRightAction,
  textAreaAutosize
}) {
  const classes = useStyles()
  return (
    <Box className={classes.inputComponentContainer}>
      <Box className={classes.labelContainer}>
        <Typography
          // these styles won't work as a class for some reason
          fontSize={'12px'}
          letterSpacing={'1px'}
          fontFamily={'Rubik'}
          fontWeight={'600'}
          lineHeight={'14px'}
          color={
            required && !value ? theme.colors.errorColor : theme.colors.black
          }
        >
          {label}
        </Typography>
        {!!rightAction && !hideRightAction && rightAction}
      </Box>
      <div className={classes.divider} />
      {textAreaAutosize ? (
        <TextareaAutosize
          placeholder={placeholder}
          minRows={2}
          className={classes.areaAutosize}
          onChange={onChange}
          disabled={disabled}
          value={value}
        />
      ) : (
        <TextField
          type={type}
          onChange={onChange}
          disabled={disabled}
          id="outlined-basic"
          variant="outlined"
          value={value}
          placeholder={placeholder}
          fullWidth
          hiddenLabel
          className={[
            classes.inputComponetStyle,
            customClasses ?? customClasses
          ]}
          multiline={multiline}
          rows={multiline ? 2 : 1}
        />
      )}
    </Box>
  )
}

export default LineItemInput
