import { React, useEffect, useState } from 'react'

// mui components
import {
  makeStyles,
  Container,
  Grid,
  Typography,
  Box,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  ThemeProvider,
  Link
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import GlobalUploadFile from '../form/UploadFile'
import GlobalInput from '../form/TextInput'
import { ExpirationDate } from '../form/ExpirationDate'
import GlobalNumberInput from '../form/NumberInput'
import { YesNoQuestion } from '../form/YesNoQuestion'
import { StatesComponent } from './StatesComponent'
import {
  alphaNumericRegex,
  maxFileSize,
  numericDashRegex,
  paymentOptions
} from '../../lib/Constants'
import {
  buttonSettingsDisabled,
  enableButtonStyle
} from '../../styles/mui_custom_theme'
import { Button } from 'antd'
import checkImage from '../../assets/images/check.png'
import GlobalSelect from '../form/Select'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { downloadWithSignedUrl, getSignedS3Url } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  infoContainer: {
    fontSize: '14px',
    fontWeight: '600',
    paddingTop: '24px'
  },
  titleContainer: {
    padding: '24px 80px 0px 80px',
    columnCount: '2',
    [theme.breakpoints.down('md')]: {
      columnCount: '1',
      padding: '24px 0 0 0'
    }
  },
  itemContainer: {
    padding: '24px 80px 0px 80px',
    columnCount: '2',
    [theme.breakpoints.down('md')]: {
      columnCount: '1',
      padding: '24px 0 0 0',
      flexDirection: 'column'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    color: theme.colors.black,
    lineHeight: '24px',
    letterSpacing: '0.05em'
  },
  subtitle: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '17px',
    color: theme.colors.black,
    paddingTop: '9px',
    letterSpacing: '0em'
  },
  componentDivider: {
    marginRight: '10px'
  },
  questionDivider: {
    marginRight: '10px',
    marginTop: '10px'
  },
  question: {
    fontSize: '12px',
    paddingTop: '12px',
    color: theme.colors.grey
  },
  centerInput: {
    textAlign: 'center',
    marginRight: '0',
    marginLeft: 'auto',
    width: '6em !important'
  },
  rightColumn: {
    [theme.breakpoints.down('md')]: {
      paddingTop: '12px'
    }
  },
  dialogPaper: {
    width: '726px',
    maxWidth: '726px',
    height: '408px',
    maxHeight: '408px',
    borderRadius: '8px'
  },
  dialogTitle: {
    fontWeight: '700',
    fontSize: '24px',
    textAlign: 'center',
    letterSpacing: '0.05em'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  dialogButtonContainer: {
    alignSelf: 'flex-end',
    marginBottom: '10px'
  },
  image: {
    flex: 1,
    alignSelf: 'center',
    justifySelf: 'center'
  },
  required: {
    color: theme.colors.errorText
  },
  icon: {
    color: theme.colors.iconBlue,
    marginLeft: '5px'
  },
  downloadLink: {
    fontSize: '12px',
    color: theme.colors.iconBlue,
    textDecoration: 'none',
    marginLeft: '5px',
    '&:hover': {
      textDecoration: 'none',
      color: theme.colors.iconBlue
    }
  },
  marginTopUploadBtn: {
    marginTop: '4px'
  }
}))

const customStyles = makeStyles(theme => ({
  uploadBtn: {
    marginTop: '4px'
  }
}))

export const InsuranceComponent = props => {
  const classes = useStyles()
  const customClasses = customStyles()
  const { t } = useTranslation()
  const { profile, handleChange, handleFileChange, setFileUploaded } = props
  const [statesRegistered, setStatesRegistered] = useState()
  const [openCheck, setOpenCheck] = useState(false)

  const paymentConfiguration = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.onboarding?.compliance?.insurance?.payment
  )

  useEffect(async () => {
    if (profile?.states_registered) {
      setStatesRegistered([...profile?.states_registered])
    } else {
      setStatesRegistered([{ state: '', license: '' }])
    }
  }, [])

  useEffect(() => {
    if (profile) {
      handleChange(statesRegistered, 'states_registered')
    }
  }, [statesRegistered])

  function handleClear(field) {
    setFileUploaded(null, field)
  }

  async function handleFileDownload(event, path, name) {
    try {
      const downloadUrl = await getSignedS3Url(path, '', 'getObject')
      downloadWithSignedUrl(downloadUrl, props.clientId, name)
    } catch (error) {
      console.error('File could not be downloaded ', error)
    }
  }

  return (
    <Container classes={{ root: classes.infoContainer }}>
      <Grid container className={classes.titleContainer}>
        <Grid item xs={12}>
          <Typography classes={{ root: classes.title }}>
            {t('company_profile.info_insurance')}
          </Typography>
          <Typography classes={{ root: classes.subtitle }}>
            {t('company_profile.message.company_profile')}
          </Typography>
        </Grid>
      </Grid>
      <Box display="flex" className={classes.itemContainer}>
        <Box flex={1} className={classes.componentDivider}>
          <Box className={classes.componentDivider}>
            <GlobalInput
              onChange={handleChange}
              field="ein"
              placeholder={t('company_profile.placeholder.ein')}
              value={profile?.ein}
              label={t('company_profile.labels.ein')}
              required={
                props.requiredFields &&
                Object.prototype.hasOwnProperty.call(
                  props?.requiredFields,
                  'ein'
                )
              }
              checkRegex={numericDashRegex}
            />
          </Box>
          <StatesComponent
            states={props.states}
            data={statesRegistered}
            updateData={setStatesRegistered}
            required={
              props.requiredFields &&
              Object.prototype.hasOwnProperty.call(
                props?.requiredFields,
                'states_registered'
              )
            }
          />
          <Box
            display="flex"
            flexDirection="row"
            className={classes.questionDivider}
          >
            <Box flex={5}>
              <InputLabel className={classes.question}>
                {props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_trucks'
                  ) && <span className={classes.required}>*</span>}
                {t('company_profile.questions.trucks')}
              </InputLabel>
            </Box>
            <Box flex={1}>
              <GlobalNumberInput
                onChange={handleChange}
                field="q_trucks"
                value={profile?.insurance_questions?.q_trucks}
                className={classes.centerInput}
                format="###"
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_trucks'
                  )
                }
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.questionDivider}
          >
            <Box flex={5}>
              <InputLabel className={classes.question}>
                {props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_field_employees'
                  ) && <span className={classes.required}>*</span>}
                {t('company_profile.questions.field_employees')}
              </InputLabel>
            </Box>
            <Box flex={1}>
              <GlobalNumberInput
                onChange={handleChange}
                field="q_field_employees"
                value={profile?.insurance_questions?.q_field_employees}
                className={classes.centerInput}
                format="###"
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_field_employees'
                  )
                }
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.questionDivider}
          >
            <Box flex={5}>
              <InputLabel className={classes.question}>
                {props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_administrative_employees'
                  ) && <span className={classes.required}>*</span>}
                {t('company_profile.questions.administrative_employees')}
              </InputLabel>
            </Box>
            <Box flex={1}>
              <GlobalNumberInput
                onChange={handleChange}
                field="q_administrative_employees"
                value={profile?.insurance_questions?.q_administrative_employees}
                className={classes.centerInput}
                format="###"
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_administrative_employees'
                  )
                }
              />
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            className={classes.questionDivider}
          >
            <Box flex={5}>
              <InputLabel className={classes.question}>
                {props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_business_years'
                  ) && <span className={classes.required}>*</span>}
                {t('company_profile.questions.years')}
              </InputLabel>
            </Box>
            <Box flex={1}>
              <GlobalNumberInput
                onChange={handleChange}
                field="q_business_years"
                value={profile?.insurance_questions?.q_business_years}
                className={classes.centerInput}
                format="###"
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'q_business_years'
                  )
                }
              />
            </Box>
          </Box>
          <Box>
            <YesNoQuestion
              label={t('company_profile.questions.subcontractors')}
              field="q_subcontractors"
              onChange={handleChange}
              value={
                profile?.insurance_questions?.q_subcontractors ? 'yes' : 'no'
              }
            />
          </Box>
        </Box>
        {/* right column components */}
        <Box
          flex={1}
          className={`${classes.componentDivider} ${classes.rightColumn}`}
        >
          <Box display="flex" flexDirection="row">
            <Box flex={1} className={classes.componentDivider}>
              <GlobalUploadFile
                buttonLabel={t('company_profile.labels.upload_file')}
                label={t('company_profile.upload.contractor_license')}
                onChange={handleFileChange}
                field="contractor_license"
                accept=".pdf, image/*"
                value={profile?.contractor_license?.url}
                fileName={profile?.contractor_license?.file_name}
                handleClear={handleClear}
                error={props.fileError.contractor_license}
                helperText={t('company_profile.error.file_size').replace(
                  '$size$',
                  maxFileSize
                )}
                loadingLabel={t('company_profile.labels.loading')}
                handleFileDownload={handleFileDownload}
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'contractor_license'
                  )
                }
              />
              <GlobalUploadFile
                buttonLabel={t('company_profile.labels.upload_file')}
                label={t('company_profile.upload.w9')}
                onChange={handleFileChange}
                field="w9"
                accept=".pdf, image/*"
                value={profile?.w9?.url}
                fileName={profile?.w9?.file_name}
                handleClear={handleClear}
                error={props.fileError.w9}
                helperText={t('company_profile.error.file_size').replace(
                  '$size$',
                  maxFileSize
                )}
                loadingLabel={t('company_profile.labels.loading')}
                handleFileDownload={handleFileDownload}
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'w9'
                  )
                }
              />
            </Box>
            <Box flex={1} className={classes.componentDivider}></Box>
          </Box>
          <Box display="flex" flexDirection="row">
            <Box flex={1} className={classes.componentDivider}>
              <GlobalUploadFile
                buttonLabel={t('company_profile.labels.upload_file')}
                label={t('company_profile.upload.coi')}
                onChange={handleFileChange}
                field="coi"
                accept=".pdf, image/*"
                value={profile?.coi?.url}
                fileName={profile?.coi?.file_name}
                handleClear={handleClear}
                error={props.fileError.coi}
                helperText={t('company_profile.error.file_size').replace(
                  '$size$',
                  maxFileSize
                )}
                loadingLabel={t('company_profile.labels.loading')}
                handleFileDownload={handleFileDownload}
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'coi'
                  )
                }
              />
            </Box>
            <Box flex={1} className={classes.componentDivider}>
              <ExpirationDate
                label={t('company_profile.questions.coi')}
                month={profile?.coi?.coi_month}
                year={profile?.coi?.coi_year}
                month_field="coi_month"
                year_field="coi_year"
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '215px'
                    }
                  }
                }}
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'coi_date'
                  )
                }
              />
            </Box>
          </Box>
          <Box className={classes.componentDivider}>
            <GlobalInput
              onChange={handleChange}
              field="coi_policy"
              placeholder={t('company_profile.placeholder.coi')}
              value={profile?.coi?.coi_policy}
              label={t('company_profile.labels.coi')}
              required={
                props.requiredFields &&
                Object.prototype.hasOwnProperty.call(
                  props?.requiredFields,
                  'coi_policy'
                )
              }
              checkRegex={alphaNumericRegex}
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box flex={1} className={classes.componentDivider}>
              <GlobalUploadFile
                buttonLabel={t('company_profile.labels.upload_file')}
                label={t('company_profile.upload.comp')}
                onChange={handleFileChange}
                field="comp"
                accept=".pdf, image/*"
                value={profile?.comp?.url}
                fileName={profile?.comp?.file_name}
                handleClear={handleClear}
                error={props.fileError.comp}
                helperText={t('company_profile.error.file_size').replace(
                  '$size$',
                  maxFileSize
                )}
                loadingLabel={t('company_profile.labels.loading')}
                handleFileDownload={handleFileDownload}
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'comp'
                  )
                }
              />
            </Box>
            <Box flex={1} className={classes.componentDivider}>
              <ExpirationDate
                label={t('company_profile.questions.comp')}
                month={profile?.comp?.comp_month}
                year={profile?.comp?.comp_year}
                month_field="comp_month"
                year_field="comp_year"
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '215px'
                    }
                  }
                }}
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'comp_date'
                  )
                }
              />
            </Box>
          </Box>
          <Box className={classes.componentDivider}>
            <GlobalInput
              onChange={handleChange}
              field="comp_policy"
              placeholder={t('company_profile.placeholder.comp')}
              value={profile?.comp?.comp_policy}
              label={t('company_profile.labels.comp')}
              required={
                props.requiredFields &&
                Object.prototype.hasOwnProperty.call(
                  props?.requiredFields,
                  'comp_policy'
                )
              }
              checkRegex={alphaNumericRegex}
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <Box flex={1} className={classes.componentDivider}>
              <GlobalSelect
                id={'payment_options'}
                field={'payment_options'}
                label={t('company_profile.labels.payment_options')}
                placeholder={t('general.labels.select')}
                onChange={handleChange}
                value={profile?.bank?.payment_options}
                displayvalue
                options={Object.keys(
                  t('company_profile.payment_options', {
                    returnObjects: true
                  })
                ).map(key => ({
                  value: key,
                  label: t('company_profile.payment_options', {
                    returnObjects: true
                  })[key]
                }))}
                required={
                  props.requiredFields &&
                  Object.prototype.hasOwnProperty.call(
                    props?.requiredFields,
                    'payment_options'
                  )
                }
              />
            </Box>
            {profile?.bank?.payment_options === paymentOptions.DIRECT &&
            paymentConfiguration?.direct_deposit?.type === 'form_upload' ? (
              <Box flex={1} className={classes.componentDivider}>
                <Link
                  href={paymentConfiguration?.direct_deposit?.file}
                  target="_blank"
                  className={classes.downloadLink}
                >
                  {paymentConfiguration?.direct_deposit?.text}
                  <FontAwesomeIcon
                    icon={['fa-light', 'fa-file-arrow-down']}
                    className={classes.icon}
                  />
                </Link>
                <GlobalUploadFile
                  customClasses={customClasses}
                  className={classes.marginTopUploadBtn}
                  buttonLabel={t('company_profile.labels.upload_file')}
                  label={''}
                  onChange={handleFileChange}
                  field="direct_deposit_form"
                  accept=".pdf, image/*"
                  value={profile?.direct_deposit_form?.url}
                  fileName={profile?.direct_deposit_form?.file_name}
                  handleClear={handleClear}
                  error={props.fileError.direct_deposit_form}
                  helperText={t('company_profile.error.file_size').replace(
                    '$size$',
                    maxFileSize
                  )}
                  loadingLabel={t('company_profile.labels.loading')}
                  handleFileDownload={handleFileDownload}
                  required={
                    props.requiredFields &&
                    Object.prototype.hasOwnProperty.call(
                      props?.requiredFields,
                      'direct_deposit_form'
                    )
                  }
                />
              </Box>
            ) : (
              <Box flex={1} className={classes.componentDivider}></Box>
            )}
          </Box>
          {profile?.bank?.payment_options === paymentOptions.DIRECT &&
          paymentConfiguration?.direct_deposit?.type === 'form_upload' ? (
            <></>
          ) : (
            profile?.bank?.payment_options === paymentOptions.DIRECT && (
              <div>
                <Box display="flex" flexDirection="row">
                  <Box flex={1} className={classes.componentDivider}>
                    <GlobalInput
                      onChange={handleChange}
                      field="bank_name"
                      placeholder={t('company_profile.placeholder.bank')}
                      value={profile?.bank?.bank_name}
                      label={t('company_profile.labels.bank')}
                      required={
                        props.requiredFields &&
                        Object.prototype.hasOwnProperty.call(
                          props?.requiredFields,
                          'bank_name'
                        )
                      }
                    />
                  </Box>
                  <Box flex={1} className={classes.componentDivider} />
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex={1} className={classes.componentDivider}>
                    <GlobalNumberInput
                      onChange={handleChange}
                      field="bank_account"
                      placeholder={t(
                        'company_profile.placeholder.account_number'
                      )}
                      value={profile?.bank?.bank_account}
                      label={t('company_profile.labels.account_number')}
                      required={
                        props.requiredFields &&
                        Object.prototype.hasOwnProperty.call(
                          props?.requiredFields,
                          'bank_account'
                        )
                      }
                      decimalScale={0}
                      allowNegative={false}
                      infoButtonCallback={() => setOpenCheck(true)}
                      allowLeadingZeros
                      format="#################"
                    />
                  </Box>
                  <Box flex={1} className={classes.componentDivider}>
                    <GlobalNumberInput
                      onChange={handleChange}
                      field="bank_routing"
                      placeholder={t(
                        'company_profile.placeholder.routing_number'
                      )}
                      value={profile?.bank?.bank_routing}
                      label={t('company_profile.labels.routing_number')}
                      required={
                        props.requiredFields &&
                        Object.prototype.hasOwnProperty.call(
                          props?.requiredFields,
                          'bank_routing'
                        )
                      }
                      decimalScale={0}
                      allowNegative={false}
                      infoButtonCallback={() => setOpenCheck(true)}
                      format="#########"
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row">
                  <Box flex={1} className={classes.componentDivider}>
                    <GlobalUploadFile
                      buttonLabel={t('company_profile.labels.upload_file')}
                      label={t('company_profile.upload.void')}
                      onChange={handleFileChange}
                      field="bank"
                      accept=".pdf, image/*"
                      value={profile?.bank?.url}
                      fileName={profile?.bank?.file_name}
                      handleClear={handleClear}
                      error={props.fileError.bank}
                      helperText={t('company_profile.error.file_size').replace(
                        '$size$',
                        maxFileSize
                      )}
                      loadingLabel={t('company_profile.labels.loading')}
                      handleFileDownload={handleFileDownload}
                      required={
                        props.requiredFields &&
                        Object.prototype.hasOwnProperty.call(
                          props?.requiredFields,
                          'bank'
                        )
                      }
                    />
                  </Box>
                  <Box flex={1} className={classes.componentDivider} />
                </Box>
              </div>
            )
          )}
          {profile?.bank?.payment_options === paymentOptions.CHECK &&
            paymentConfiguration?.check_payee && (
              <Box flex={1} className={classes.componentDivider}>
                <GlobalInput
                  onChange={handleChange}
                  field="bank_check_payee"
                  placeholder={t('company_profile.placeholder.bank_check_payee')}
                  value={profile?.bank?.bank_check_payee}
                  label={t('company_profile.labels.bank_check_payee')}
                  required={
                    props.requiredFields &&
                    Object.prototype.hasOwnProperty.call(
                      props?.requiredFields,
                      'bank_check_payee'
                    )
                  }
                />
              </Box>
            )}
        </Box>
      </Box>
      <Dialog
        open={openCheck}
        keepMounted
        onClose={() => setOpenCheck(false)}
        classes={{ paper: classes.dialogPaper }}
        style={{ zIndex: 2000 }}
        disableAutoFocus={false}
      >
        <DialogTitle
          disableTypography={true}
          classes={{ root: classes.dialogTitle }}
        >
          {t('company_profile.sample_check_format')}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <img src={checkImage} className={classes.image} />
          <Box className={classes.dialogButtonContainer}>
            <ThemeProvider theme={buttonSettingsDisabled}>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                type="submit"
                style={{
                  ...enableButtonStyle,
                  marginRight: 0
                }}
                onClick={() => setOpenCheck(false)}
              >
                {t('company_profile.placeholder.close')}
              </Button>
            </ThemeProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </Container>
  )
}
