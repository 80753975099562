// main components
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UpdateAccountInfo } from '../accountsettings/UpdateAccountInfo'

// redux
import { useSelector } from 'react-redux'

// mui components
import {
  Card,
  CardActions,
  CardContent,
  makeStyles,
  Typography,
  Box,
  Button
} from '@material-ui/core'

// services
import { userHasAuthorization } from '../../services/AuthService'
import GlobalChip from '../form/Chip'
import GlobalInput from '../form/TextInput'
import { formatPhoneNumber } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: '8px',
    boxShadow: '6px 9px 43px ' + theme.colors.profile.shadowCard,
    marginBottom: '2em',
    padding: '1em',
    color: theme.colors.text,
    minHeight: '470px'
  },
  cardTitle: {
    fontSize: '20px',
    fontWeight: '700',
    minWidth: 'fit-content',
    marginRight: '1em',
    [theme.breakpoints.down('xs')]: {
      minWidth: '130px',
      marginRight: '0'
    }
  },
  actions: {
    padding: '0px 0px',
    display: 'flex',
    alignItems: 'baseline'
  },
  content: {
    padding: '0px 20px 30px 0px'
  },
  editButton: {
    alignSelf: 'flex-start',
    marginLeft: 'auto',
    marginRight: '0',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: '600',
    letterSpacing: '0.05em',
    lineHeight: '19px',
    boxShadow: 'none',
    border: 'none'
  },
  itemDivider: {
    borderBottom: '1px solid ' + theme.colors.grey_2
  },
  userItem: {
    fontSize: '16px',
    fontWeight: 600,
    minWidth: '10em',
    cursor: 'pointer'
  },
  lineItem: {
    padding: '1em 0 1em'
  },
  searchBar: {
    width: '40%',
    [theme.breakpoints.down('xs')]: {
      width: 'fit-content',
      marginRight: '35px'
    }
  },
  usersList: {
    maxHeight: '350px',
    overflow: 'auto'
  },
  noItems: {
    display: 'flex',
    height: '100rem',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export const UsersCard = props => {
  const { roles, users } = props
  const classes = useStyles()
  const { t } = useTranslation()
  const userStore = useSelector(state => state.auth.user)

  const [openPanel, setOpenPanel] = useState(false)
  const [panelEvent, setPanelEvent] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)
  const [roleOptions, setRoleOptions] = useState(null)
  const [filterUser, setFilterUser] = useState(null)

  useEffect(() => {
    setFilterUser(users)
  }, [users])

  useEffect(() => {
    if (!roles || roles.length === 0) return

    const updatedRoles = {}
    roles.forEach(item => {
      updatedRoles[item.id] = item.name
    })
    setRoleOptions(updatedRoles)
  }, [roles])

  const handleNewPanel = () => {
    setSelectedUser({
      firstName: null,
      lastName: null,
      photo_url: null,
      roles: null,
      username: null
    })
    setErrorMessage(null)
    setPanelEvent('new')
    setOpenPanel(true)
  }

  const handleEditPanel = obj => {
    if (userHasAuthorization('company_settings:write')) {
      setSelectedUser(obj)
      setErrorMessage(null)
      setPanelEvent('edit')
      setOpenPanel(true)
    }
  }

  const EditButton = props => {
    const classes = useStyles()
    return (
      <Button className={classes.editButton} onClick={props.onClick}>
        {props.label}
      </Button>
    )
  }

  // function for search input
  function handleFilterChange(value) {
    const result = value
      ? users.filter(user =>
          (user.firstName + ' ' + user.lastName)
            .toLowerCase()
            .includes(value.toLowerCase())
        )
      : props.users
    setFilterUser(result)
  }

  return (
    <Card className={classes.card} data-testid={'company_users_card'}>
      <CardActions disableSpacing classes={{ root: classes.actions }}>
        <Typography classes={{ root: classes.cardTitle }}>
          {props.cardtitle}
        </Typography>
        <GlobalInput
          className={classes.searchBar}
          placeholder={t('company_settings.users_card.search')}
          onChange={handleFilterChange}
        />
        {userHasAuthorization('users:write') ? (
          <EditButton
            label={t('company_settings.buttons.add')}
            onClick={handleNewPanel}
            data-testid={'add_company_user_button'}
          />
        ) : (
          ''
        )}
      </CardActions>
      <CardContent classes={{ root: classes.content }}>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.usersList}
        >
          {filterUser?.length > 0 ? (
            filterUser?.map((user, index) => (
              <div
                key={user.lastName + '_' + index}
                className={classes.itemDivider}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  className={classes.lineItem}
                  onClick={() => handleEditPanel(user)}
                >
                  <Typography classes={{ root: classes.userItem }}>
                    {user.firstName} {user.lastName}
                  </Typography>
                  <GlobalChip
                    chips={[
                      formatPhoneNumber(user.phone),
                      user.role && t('create_account.user_roles.' + user.role),
                      roleOptions && user.roles && user.roles !== 'no_value'
                        ? roleOptions[user.roles]
                        : t('company_settings.mobile_only')
                    ].filter(
                      item => typeof item !== 'undefined' && item !== '' && item
                    )}
                    selected={new Set()}
                    setSelected={() => {}}
                    skipTranslate={true}
                  />
                </Box>
              </div>
            ))
          ) : (
            <Box className={classes.noItems}>
              {t('company_settings.users_card.add_users')}
            </Box>
          )}
        </Box>
      </CardContent>
      {selectedUser && (
        <UpdateAccountInfo
          errorMessage={errorMessage}
          editDrawer={openPanel}
          handleClosePanel={() => {
            setOpenPanel(false)
            setSelectedUser(null)
          }}
          accountInfo={{ ...userStore, userInfo: selectedUser }}
          updateUsers={props.updateUsers}
          event={panelEvent}
          roles={roles}
          roleOptions={roleOptions}
          affiliateId={props.company.affiliate_id}
          accountOwner={selectedUser.id === userStore.userInfo.id}
          mobile={props.mobile}
          setReloadUsers={props.setReloadUsers}
        />
      )}
    </Card>
  )
}
