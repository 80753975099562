import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  open: null,
  workOrders: [],
  invoices: []
}

/**
 * Create loading slice to show and hide a loader overlay
 *
 * @type {Slice<{loading: boolean}, {hide(*): void, show(*): void}, string>}
 */
const websocketSlice = createSlice({
  name: 'websocket',
  initialState: initialState,
  reducers: {
    setSnackState (state, action) {
      state.open = action.payload
    },
    setWorkOrders (state, action) {
      state.workOrders = action.payload
    },
    setInvoices (state, action) {
      state.invoices = action.payload
    }
  }
})

export const websocketActions = websocketSlice.actions

export default websocketSlice.reducer
