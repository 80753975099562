import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect
} from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import NumberFormat from 'react-number-format'

// main components
import { InputFieldFilter } from '../workorders/InputFieldFilter'
import { DateFilter } from '../workorders/DateFilter'
import { SlideFilter } from '../workorders/SlideFilter'
import { SortMenu } from './SortMenu'
import { FadingRow } from '../FadingRow'

// mui components
import {
  makeStyles,
  Checkbox,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ThemeProvider,
  Box,
  LinearProgress,
  InputBase
} from '@material-ui/core'

// Constants
import {
  inStatusOptions,
  inStatusSfrOptions,
  invoiceDefaultColumns,
  mcssfr
} from '../../lib/Constants'

// Themes
import { checkboxTheme } from '../../styles/mui_custom_theme'

// Redux
import { useDispatch, useSelector } from 'react-redux'
import { invoicesActions } from '../../store/invoices'
import { getMainTableFilters, isRunningInIframe } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    overflowY: 'hidden',
    overflowX: 'auto',
    padding: '0px',
    margin: '0px'
  },
  bodyTableContainer: {
    minWidth: '1550px',
    padding: '0px',
    margin: '0px',
    overflowX: 'hidden',
    overflowY: 'overlay'
  },
  headTable: {
    height: '61px',
    minWidth: '1550px',
    tableLayout: 'fixed',
    lineHeight: 'none'
  },
  bodyTable: {
    tableLayout: 'fixed'
  },
  label: {
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    paddingTop: '7px',
    paddingRight: '7px'
  },
  labelCounter: {
    height: '18px',
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    paddingTop: '7px',
    paddingRight: '7px'
  },
  selected: {
    backgroundColor: `${theme.colors.workOrders.selectedRow} !important`,
    '&:hover': {
      backgroundColor: theme.colors.workOrders.selectedRow
    }
  },
  avatar: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    marginRight: '10px'
  },
  columnTitle: {
    fontSize: '12px',
    fontWeight: '500',
    color: theme.colors.workOrders.columnTitle,
    paddingLeft: '13px',
    cursor: 'default',
    height: '36px'
  },
  tableCell: {
    fontSize: '12px',
    fontWeight: '400',
    paddingLeft: '13px',
    paddingTop: '0px',
    color: theme.colors.workOrders.columnTitle,
    paddingRight: '14px'
  },
  tableRow: {
    height: '68px'
  },
  tableRowInserted: {
    height: '68px',
    backgroundColor: theme.colors.insertedRow
  },
  headCell: {
    margin: '0px',
    paddingBottom: '5px',
    paddingTop: '0px',
    paddingRight: '10px',
    paddingLeft: '0px',
    color: theme.colors.workOrders.columnTitle
  },
  lastHeadCell: {
    margin: '0px',
    padding: '0px',
    paddingRight: '12px',
    paddingBottom: '5px',
    color: theme.colors.workOrders.columnTitle
  },
  empty1: {
    color: theme.colors.disabledButtonColor,
    fontSize: '30px',
    fontWeight: '600',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '60px',
    marginBottom: '40px'
  },
  empty2: {
    color: theme.colors.workOrders.tab.duedate,
    fontSize: '16px',
    fontWeight: '400',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  boxTitle: {
    paddingTop: '2px',
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.workOrders.columnTitle
  },
  boxSub: {
    fontSize: '10px',
    fontWeight: '400',
    color: theme.colors.workOrders.downloadIcon,
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    marginTop: '-5px'
  },
  colorPrimary: {
    background: theme.colors.workOrders.buttonPrimary
  },
  barColorPrimary: {
    background: `linear-gradient(to right, ${theme.colors.workOrders.buttonPrimary},${theme.colors.workOrders.gradient},${theme.colors.workOrders.buttonPrimary})`
  },
  dimensions: {
    height: '21px',
    width: '100px'
  },
  body: {
    maxHeight: '680px',
    backgroundColor: 'white',
    overflowY: 'scroll'
  },
  open: {
    height: '13px',
    paddingLeft: '0px',
    color: theme.colors.invoiceColors.open
  },
  declined: {
    height: '13px',
    color: theme.colors.invoiceColors.declined
  },
  sent: {
    height: '13px'
  },
  approved: {
    height: '13px',
    color: theme.colors.invoiceColors.approved
  },
  draft: {
    height: '13px'
  },
  noIcon: {
    width: '13px',
    height: '13px',
    color: theme.colors.text,
    marginBottom: '-2px',
    marginRight: '8px'
  },
  checkboxTablecell: {
    width: '48px'
  },
  emptyTableCell: {
    borderBottom: 'none'
  },
  masqueradeRow: {
    marginTop: '50px'
  },
  ellipsis: {
    color: theme.colors.workOrders.downloadIcon
  },
  tableHead: {
    backgroundColor: theme.colors.filters.leftColumnBackground
  },
  table_row: {
    height: '68px'
  }
}))

function titleCase(str) {
  const splitStr = str.replace('_', ' ').toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

let userStore

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    filtersCatalog,
    filters,
    setFilters,
    pageConfiguration
  } = props

  userStore = useSelector(state => state.auth.user)

  const inStatusOptions = filtersCatalog.invoicesStatus.map(item => {
    return {
      label: titleCase(item),
      value: item
    }
  })

  const defaultFilters = [
    { id: 'client_name', type: 'InputFieldFilter', default: true, checkMinCharacters: 4 },
    { id: 'client_location', type: 'InputFieldFilter', default: true, checkMinCharacters: 4 },
    { id: 'invoice', type: 'InputFieldFilter', default: true, checkMinCharacters: 2 },
    { id: 'won', type: 'InputFieldFilter', default: true, checkMinCharacters: 4 },
    { id: 'amount', type: 'InputFieldFilter', default: true },
    { id: 'createDate', type: 'DateFilter', default: true },
    { id: 'dueDate', type: 'DateFilter', default: true },
    {
      id: 'inStatus',
      type: 'SlideFilter',
      options: inStatusOptions,
      default: true
    },
    { id: 'payDate', type: 'DateFilter', default: false },
    { id: 'achCheckNumber', type: 'InputFieldFilter', default: false }
  ]

  const filterComponents = getMainTableFilters(
    defaultFilters,
    pageConfiguration?.columns
  )

  const classes = useStyles()
  const { t } = useTranslation()

  let count = 0
  const checked = rowCount > 0 && numSelected === rowCount
  if (checked) {
    count = props.chips.totalCount
  } else {
    count = numSelected
  }

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell className={classes.checkboxTablecell} padding="checkbox">
          <ThemeProvider theme={checkboxTheme}>
            <div className={classes.labelCounter}>
              {count !== 0 ? numberWithCommas(count) : ''}
            </div>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </ThemeProvider>
        </TableCell>
        {props.columns &&
          props.columns
            ?.filter(column => column.visible)
            .map((column, index, columns) => {
              const headCell = filterComponents.find(
                filter => filter.id === column.field
              )
              if (!headCell) {
                return null
              }
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  classes={
                    index + 1 !== columns.length
                      ? { root: classes.headCell }
                      : { root: classes.lastHeadCell }
                  }
                >
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    classes={{ root: classes.columnTitle }}
                  >
                    {headCell.type !== 'Button'
                      ? t('invoices.column_names.' + headCell.id)
                      : ''}
                    {(headCell.id === 'dueDate' ||
                      headCell.id === 'inStatus' ||
                      headCell.id === 'client_name' ||
                      headCell.id === 'won' ||
                      headCell.id === 'invoice' ||
                      headCell.id === 'payDate' ||
                      headCell.id === 'achCheckNumber') && (
                      <SortMenu
                        id={headCell.id}
                        setValues={setFilters}
                        values={filters}
                      />
                    )}
                  </TableSortLabel>
                  <div>
                    {headCell.type === 'Button' ? (
                      <div id={headCell.id} />
                    ) : (
                      false
                    )}

                    {headCell.type === 'InputFieldFilter' ? (
                      <InputFieldFilter
                        id={headCell.id}
                        values={filters}
                        type={1}
                        setValues={setFilters}
                        placeholder={t('general.labels.search')}
                        height={headCell.height}
                        filterContent={() => null}
                        checkMinCharacters={headCell.checkMinCharacters ?? 1}
                      />
                    ) : (
                      false
                    )}
                    {headCell.type === 'DateFilter' ? (
                      <DateFilter
                        id={headCell.id}
                        values={filters}
                        type={1}
                        setValues={setFilters}
                        height={headCell.height}
                        filterContent={() => null}
                        range
                        testid={'date_filter'}
                      />
                    ) : (
                      false
                    )}
                    {headCell.type === 'SlideFilter' ? (
                      <SlideFilter
                        id={headCell.id}
                        values={props.filters}
                        type={1}
                        setValues={props.setFilters}
                        options={headCell.options ?? []}
                        height={headCell.height}
                        filterContent={() => null}
                        testid={'filter_open'}
                        hasAll={true}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </TableCell>
              )
            })}

        {<TableCell padding="checkbox" size="small" />}
      </TableRow>
    </TableHead>
  )
}

function EnhancedTable(props) {
  const params = useParams()
  const wHeight = getWindowHeight()
  const {
    content,
    selected,
    setSelected,
    loading,
    hasMore,
    setTablePage,
    filters,
    setFilters,
    validateFilters,
    setSearchEnabled,
    openInvoices
  } = props
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [showEmpty, setShowEmpty] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const [filtersCatalog, setFiltersCatalog] = useState({
    invoicesStatus: []
  })
  const [allSelected, setAllSelected] = useState()

  const pageConfiguration = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.invoices
  )
  const columns = pageConfiguration?.columns
    ? pageConfiguration?.columns.map(col => ({ ...col, visible: true }))
    : invoiceDefaultColumns

  const observer = useRef()
  const lastTableElement = useCallback(
    node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setTablePage(prevTablePage => prevTablePage + 1)
          setSearchEnabled(true)
          setTimeout(() => {
            setSearchEnabled(false)
          }, 500)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    loadFiltersCatalogs()
  }, [])

  useEffect(() => {
    setShowEmpty(false)
    if (!loading && selected.length > 0 && allSelected) {
      reselectAllItems()
    }
  }, [loading])

  const loadFiltersCatalogs = async () => {
    try {
      if (userStore && userStore.userInfo.parent_subdomain === mcssfr) {
        setFiltersCatalog({
          invoicesStatus: inStatusSfrOptions
        })
      } else {
        setFiltersCatalog({
          invoicesStatus: inStatusOptions
        })
      }
    } catch (err) {
      console.error(err)
    }
  }

  const classes = useStyles()
  const { t } = useTranslation()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = content.map(n => n._id)
      setSelected(newSelecteds)
      setAllSelected(true)
      return
    }
    setAllSelected(false)
    setSelected([])
  }

  const handleSelect = (event, name) => {
    event.stopPropagation()
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const reselectAllItems = () => {
    setSelected([])
    const newSelecteds = content.map(n => n._id)
    setSelected(newSelecteds)
  }

  const handleClick = (event, data) => {
    event.stopPropagation()
    dispatch(invoicesActions.setInvoices(content))
    dispatch(invoicesActions.setFilters(filters))
    dispatch(invoicesActions.setOpenInvoices(openInvoices))
    if (isRunningInIframe()) {
      history.push({ pathname: '/createInvoice-iframe/' + params.id + '/' + params.access_token, search: '?id=' + data })
    } else {
      history.push({ pathname: 'createInvoice', search: '?id=' + data })
    }
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const emptyComponent = () => (
    <TableBody key="empty-body">
      <TableRow>
        <TableCell className={classes.emptyTableCell} colSpan={10}>
          {!validateFilters() ? (
            <>
              <Box classes={{ root: classes.empty1 }}>
                {t('invoices.empty_message_title')}
              </Box>
              <Box classes={{ root: classes.empty2 }}>
                {t('invoices.empty_message_filters')}
              </Box>
            </>
          ) : (
            <>
              <Box classes={{ root: classes.empty1 }}>
                {t('invoices.empty_message_title')}
              </Box>
              <Box classes={{ root: classes.empty2 }}>
                {t('invoices.empty_message')}
              </Box>
            </>
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  )

  const progressComponent = columns => {
    const auxArray = []
    for (let i = 1; i <= 4; i++) {
      auxArray.push(progressRow(columns, i))
    }
    return <TableBody>{auxArray.map((obj, ind) => obj)}</TableBody>
  }

  const progressRow = (columns, i) => (
    <TableRow
      key={`progress-${i}`}
      id={`progress-${i}`}
      onClick={() => null}
      tabIndex={-1}
      classes={{ root: classes.table_row }}
    >
      <TableCell key={'chk-' + i + '--1'} padding="checkbox">
        <ThemeProvider theme={checkboxTheme}>
          <Checkbox onClick={() => null} checked={false} />
        </ThemeProvider>
      </TableCell>
      {columns?.map((column, index) => {
        if (!column.visible) {
          return null
        }
        return column.type !== 'Button' ? (
          <TableCell
            key={column.field + '-' + i + '-' + index}
            align="left"
            classes={{ root: classes.tableCell }}
          >
            <LinearProgress
              style={{ width: '100%' }}
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary,
                root: classes.dimensions
              }}
            />
          </TableCell>
        ) : (
          false
        )
      })}
    </TableRow>
  )

  const mappingColumns = (columns, row) => {
    return columns.map((column, index) => {
      if (!column.visible) return null
      switch (column.field) {
        case 'client_name':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.work_order
                ? row.work_order?.client_name
                : row?.work_order_list?.location_name ?? ''}
            </TableCell>
          )
        case 'client_location':
          return (
            <TableCell
              key={column.field + index}
              id={`enhanced-table-checkbox-${index}`}
              scope="row"
              classes={{ root: classes.tableCell }}
            >
              <Box classes={{ root: classes.boxTitle }}>
                {row.work_order
                  ? row.work_order?.address
                  : row?.work_order_list?.location_address ??
                    ''}
              </Box>
            </TableCell>
          )
        case 'invoice':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.invoice_number}
            </TableCell>
          )
        case 'won':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.work_order
                ? row.work_order?.customer_po
                : 'Multiple'}
            </TableCell>
          )
        case 'amount':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              <NumberFormat
                id="total-input"
                thousandSeparator
                customInput={InputBase}
                prefix="$"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={2}
                value={
                  row.project
                    ? row.line_items?.reduce(
                        (acc, item) => acc + item.amount,
                        0
                      )
                    : row.total
                }
                displayType="text"
              />
            </TableCell>
          )
        case 'createDate':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.completed
                ? moment(
                    new Date(row.completed * 1000)
                  ).format(t('general.format.short_date'))
                : moment(
                    new Date(row.date_created * 1000)
                  ).format(t('general.format.short_date')) ??
                  ''}
            </TableCell>
          )
        case 'dueDate':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.due_date
                ? moment(
                    new Date(row.due_date * 1000)
                  ).format(t('general.format.short_date'))
                : ''}
            </TableCell>
          )
        case 'inStatus':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              <div
                style={{ display: 'inline' }}
                className={classes[row.status]}
              >
                {row.status
                  ? t(`invoices.in_states.${row.status}`)
                  : t('invoices.in_states.no_status')}
              </div>
            </TableCell>
          )
        case 'payDate':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.pay_date
                ? moment(
                    new Date(row.pay_date * 1000)
                  ).format(t('general.format.short_date'))
                : ''}
            </TableCell>
          )
          case 'achCheckNumber':
            return (
              <TableCell
                key={column.field + index}
                align="left"
                classes={{ root: classes.tableCell }}
              >
                {row.ach_check_number}
              </TableCell>
            )
        default:
          return null
      }
    })
  }

  const enableEmpty = () => {
    setTimeout(() => {
      setShowEmpty(true)
    }, 10)
  }

  return (
    <div>
      <TableContainer classes={{ root: classes.tableContainer }}>
        <Table
          className={classes.headTable}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            chips={props.chips}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={content ? content?.length : 0}
            filtersCatalog={filtersCatalog}
            filters={filters}
            setFilters={setFilters}
            columns={columns}
            validateFilters={validateFilters}
            pageConfiguration={pageConfiguration}
          />
        </Table>
        <TableContainer
          style={{ height: wHeight - 208 + 'px' }}
          classes={{ root: classes.bodyTableContainer }}
        >
          <Table
            classes={{ root: classes.bodyTable }}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            // data-testid={'wo_main_table'}
          >
            {content && content.length > 0 ? (
              <TableBody key="in-table-body" classes={{ root: classes.body }}>
                {content.map((row, index) => {
                  let tableReference = null
                  if (content.length === index + 1) {
                    tableReference = lastTableElement
                  }
                  return (row.socket ? <FadingRow
                    ref={tableReference}
                    hover
                    role="checkbox"
                    aria-checked={isSelected(row._id)}
                    tabIndex={-1}
                    key={row._id}
                    selected={isSelected(row._id)}
                    classes={{ selected: classes.selected }}
                    className={row.socket ? classes.tableRowInserted : classes.tableRow}
                    onClick={event => handleClick(event, row._id)}
                  >
                    <TableCell padding="checkbox">
                      <ThemeProvider theme={checkboxTheme}>
                        <Checkbox
                          onClick={event => handleSelect(event, row._id)}
                          checked={isSelected(row._id)}
                          inputProps={{
                            'aria-labelledby': `enhanced-table-checkbox-${index}`
                          }}
                        />
                      </ThemeProvider>
                    </TableCell>
                    {mappingColumns(columns, row)}
                    {<TableCell padding="checkbox" size="small" />}
                  </FadingRow>
                    : <TableRow
                      ref={tableReference}
                      hover
                      role="checkbox"
                      aria-checked={isSelected(row._id)}
                      tabIndex={-1}
                      key={row._id}
                      selected={isSelected(row._id)}
                      classes={{ selected: classes.selected }}
                      className={row.socket ? classes.tableRowInserted : classes.tableRow}
                      onClick={event => handleClick(event, row._id)}
                    >
                      <TableCell padding="checkbox">
                        <ThemeProvider theme={checkboxTheme}>
                          <Checkbox
                            onClick={event => handleSelect(event, row._id)}
                            checked={isSelected(row._id)}
                            inputProps={{
                              'aria-labelledby': `enhanced-table-checkbox-${index}`
                            }}
                          />
                        </ThemeProvider>
                      </TableCell>
                      {mappingColumns(columns, row)}
                      {<TableCell padding="checkbox" size="small" />}
                    </TableRow>
                  )
                })}
                {content.length > 0 && loading && progressRow(columns, 1)}
              </TableBody>
            ) : loading ? (
              progressComponent(columns)
            ) : (
              <>
                {enableEmpty()}
                {showEmpty && emptyComponent()}
              </>
            )}
          </Table>
        </TableContainer>
      </TableContainer>
    </div>
  )
}
export const MainTable = props => {
  const {
    content,
    openAdvanced,
    setOpenAdvanced,
    selected,
    setSelected,
    loading,
    hasMore,
    setTablePage,
    filters,
    setFilters,
    handleCleanFilters,
    validateFilters,
    setSearchEnabled,
    openInvoices,
    totalCount
  } = props

  return (
    <div>
      <EnhancedTable
        chips={props.chips}
        content={content}
        openAdvanced={openAdvanced}
        setOpenAdvanced={setOpenAdvanced}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        hasMore={hasMore}
        setTablePage={setTablePage}
        filters={filters}
        setFilters={setFilters}
        handleCleanFilters={handleCleanFilters}
        validateFilters={validateFilters}
        setSearchEnabled={setSearchEnabled}
        openInvoices={openInvoices}
        totalCount={totalCount}
      />
    </div>
  )
}

function numberWithCommas(x) {
  if (!x) return '0'
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getWindowHeight() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size[1]
}
