import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SingleLineItem from './SingleLineItem'

import { makeStyles } from '@material-ui/core'
import customTheme from '../../../styles/mui_theme'

import { v4 as uuidv4 } from 'uuid'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    marginLeft: '5px'
  },
  divider: {
    height: '10px'
  }
}))

const emptyItem = {
  name: '',
  description: '',
  quantity: '',
  number: '',
  price: '',
  tax: '',
  total_price: ''
}

const LineItemsComponent = ({
  lineItemsList,
  onUpdate,
  section,
  questionId,
  disabled,
  question
}) => {
  const classes = useStyles()
  const itemsLimit = question.items_limit ?? 1
  const [lineItems, setLineItems] = useState(lineItemsList)
  const [isAddButtonActive, setIsAddButtonActive] = useState()

  useEffect(() => {
    if (!lineItems || lineItems.length === 0) {
      addLineItemHandler()
    }
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      checkMandatory(lineItems)
      onUpdate(section, questionId, [...lineItems])
    }, 500)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [lineItems])

  const checkMandatory = answer => {
    let fullfilledItems = true
    if (answer?.length === 0) {
      fullfilledItems = false
    }
    if (answer?.length > 0) {
      for (const item of answer) {
        if (!item.quantity || !item.name) {
          fullfilledItems = false
          break
        }
      }
    }
    setIsAddButtonActive(fullfilledItems)
  }

  const addLineItemHandler = () => {
    setLineItems(oldLineItems => {
      const id = uuidv4()
      const newLineItem = {
        ...emptyItem,
        id: id
      }
      if (oldLineItems !== null) {
        return [...oldLineItems, newLineItem]
      } else {
        return [{ ...newLineItem }]
      }
    })
  }

  const removeLineItemHandler = index => {
    setLineItems(prevItems => {
      const newItems = [...prevItems]
      newItems.splice(index, 1)
      return newItems
    })
  }

  function countDots(inputString) {
    const dots = inputString.match(/\./g)
    return dots ? dots.length : 0
  }

  function replaceDots(inputString) {
    let firstDotProcessed = false

    return inputString.replace(/\./g, function (match) {
      if (!firstDotProcessed) {
        firstDotProcessed = true
        return match // Keep the first dot
      } else {
        return '' // Replace subsequent dots with an empty string
      }
    })
  }

  const removeNonNumericCharacters = inputString => {
    if (countDots(inputString) > 1) {
      const modValue = inputString?.replace(/[^0-9.]+/gi, '')
      return replaceDots(modValue)
    } else {
      return inputString?.replace(/[^0-9.]+/gi, '')
    }
  }

  const updateFieldsHandler = (index, field, value) => {
    let modValue = value
    if (field === 'quantity') {
      modValue = removeNonNumericCharacters(modValue)
    }
    setLineItems(prevItems => {
      const newItems = [...prevItems]
      newItems[index][field] = modValue
      return newItems
    })
  }

  const AddButton = () => {
    return (
      <Button
        // this does not work as a class
        sx={{
          textTransform: 'none',
          color: customTheme.colors.blue
        }}
        size="small"
        startIcon={<FontAwesomeIcon icon="fa-solid fa-circle-plus" />}
        onClick={addLineItemHandler}
        disabled={disabled || !isAddButtonActive}
      >
        Item
      </Button>
    )
  }

  return (
    <Box className={classes.container}>
      {lineItems &&
        lineItems?.map((item, index) => {
          return (
            <SingleLineItem
              hideRightAction={lineItems?.length === 1}
              key={index}
              index={index}
              item={item}
              onChange={updateFieldsHandler}
              onRemove={removeLineItemHandler}
              disabled={disabled}
            />
          )
        })}
      {lineItems && lineItems.length < itemsLimit && !disabled && <AddButton />}
      {disabled && <div className={classes.divider} />}
    </Box>
  )
}

export default LineItemsComponent
