/* eslint-disable prefer-const */
import React, { useState, useEffect, useRef } from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { useTranslation } from 'react-i18next'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'
import {
  FormControl,
  OutlinedInput,
  ThemeProvider,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  calendarTitleStyle,
  muiThemeDateFilter,
  muiThemeHeaderDate
} from '../../styles/mui_custom_theme'

const moment = require('moment')

const useStyles = makeStyles(theme => ({
  datePicker: {
    '& .MuiPickersModal-dialog': {
      backgroundColor: 'red !important'
    }
  },
  mainInput1: {
    ...theme.filtersClasses.mainInput1,
    cursor: 'pointer',
    caretColor: 'transparent',
    '& .MuiInputBase-input': {
      cursor: 'pointer !important'
    }
  },
  filledInput: {
    ...theme.filtersClasses.mainInput1,
    cursor: 'pointer',
    caretColor: 'transparent',
    '& .MuiInputBase-input': {
      cursor: 'pointer !important'
    },
    fontSize: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingRight: '9px'
    }
  },
  mainInput2: {
    ...theme.filtersClasses.mainInput2,
    cursor: 'pointer',
    caretColor: 'transparent',
    '& .MuiInputBase-input': {
      cursor: 'pointer !important'
    }
  }
}))

export const DateFilter = props => {
  const [selectedDate, setSelectedDate] = useState('')
  const [openCalendar, setCalendar] = useState(false)

  // Rage Variables
  const [selectedDateFrom, setDateFrom] = useState(new Date())
  const [maxDateFrom, setMaxDateFrom] = useState(null)
  const [openCalendarFrom, setCalendarFrom] = useState(false)
  const [selectedDateTo, setDateTo] = useState(new Date())
  const [openCalendarTo, setCalendarTo] = useState(false)

  const rootRef = useRef()
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChange = date => {
    let formattedDate = ''
    if (props.range) {
      formattedDate =
        moment(selectedDateFrom).format('yyyy/MM/DD') +
        ' - ' +
        moment(date).format('yyyy/MM/DD')
      props.setValues(prevState => ({
        ...prevState,
        [props.id]: moment(date).format('YYYY-MM-DD HH:mm:ss Z').toLowerCase()
      }))
      // set displayed short date
      const displayDate =
        moment(selectedDateFrom).format(t('general.format.filter_date')) +
        ' - ' +
        moment(date).format(t('general.format.filter_date'))
      setSelectedDate(displayDate)
    } else {
      formattedDate = moment(date).format('MM/DD/yyyy')
      setSelectedDate(formattedDate)
    }
    props.setValues(prevState => ({
      ...prevState,
      [props.id]: formattedDate
    }))
  }

  const handleClean = () => {
    setSelectedDate('')
    props.setValues(prevState => ({
      ...prevState,
      [props.id]: ''
    }))
    setTimeout(() => {
      setCalendar(false)
    }, 5)
  }

  useEffect(() => {
    if (props.values[props.id] === '') {
      setSelectedDate('')
    }
  }, [props.values])

  const disablePastDates = date => {
    return !(date >= selectedDateFrom)
  }

  const setMaxDate = date => {
    setMaxDateFrom(moment(date).add(1, 'month'))
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl variant="outlined" fullWidth>
        <OutlinedInput
          data-testid={props.testid}
          id="component-outlined"
          ref={rootRef}
          key={props.id}
          value={selectedDate}
          placeholder={t('general.labels.select')}
          onClick={() => setCalendarFrom(true)}
          classes={{
            root:
              props.type === 1
                ? selectedDate === ''
                  ? classes.mainInput1
                  : classes.filledInput
                : classes.mainInput2
          }}
          autoComplete="off"
          readOnly
          endAdornment={
            selectedDate.length > 0 ? (
              <FontAwesomeIcon icon={('far', 'times')} onClick={handleClean} />
            ) : (
              <FontAwesomeIcon
                icon={['far', 'angle-down']}
                onClick={() => setCalendarFrom(true)}
              />
            )
          }
        />
      </FormControl>
      {props.range ? (
        <ThemeProvider theme={muiThemeDateFilter}>
          <MuiPickersUtilsProvider
            key="date-picker-dialog-from"
            utils={DateFnsUtils}
          >
            <ThemeProvider
              key="date-picker-dialog-from"
              theme={muiThemeHeaderDate}
            >
              <KeyboardDatePicker
                disableToolbar={false}
                ToolbarComponent={() => (
                  <div style={calendarTitleStyle}>From:</div>
                )}
                InputProps={{ className: classes.picker }}
                format="MM/dd/yyyy"
                margin="normal"
                variant="inline"
                id="date-picker-dialog-from"
                key="date-picker-dialog-from"
                value={selectedDateFrom}
                onChange={date => {
                  setDateFrom(date)
                  setMaxDate(date)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                PopoverProps={{
                  anchorEl: () => rootRef.current,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  PaperProps: {
                    style: {
                      backgroundColor: 'white',
                      borderRadius: '8px'
                    }
                  }
                }}
                TextFieldComponent={() => null}
                open={openCalendarFrom}
                onOpen={() => setCalendarFrom(true)}
                onClose={() => {
                  setCalendarTo(false)
                  setCalendarFrom(false)
                }}
                onAccept={() => {
                  setCalendarTo(true)
                  setTimeout(() => setCalendarFrom(false), 100)
                }}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider
            key="date-picker-dialog-to"
            utils={DateFnsUtils}
          >
            <ThemeProvider
              key="date-picker-dialog-to"
              theme={muiThemeHeaderDate}
            >
              <KeyboardDatePicker
                disableToolbar={false}
                ToolbarComponent={() => (
                  <div style={calendarTitleStyle}>To:</div>
                )}
                format="MM/dd/yyyy"
                margin="normal"
                variant="inline"
                id="date-picker-dialog-to"
                key="date-picker-dialog-to"
                value={selectedDateTo}
                onChange={date => {
                  setDateTo(date)
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                PopoverProps={{
                  anchorEl: () => rootRef.current,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  PaperProps: {
                    style: {
                      backgroundColor: 'white',
                      borderRadius: '8px'
                    }
                  }
                }}
                TextFieldComponent={() => null}
                open={openCalendarTo}
                onOpen={() => setCalendarTo(true)}
                onClose={() => {
                  setCalendarTo(false)
                  setCalendarFrom(false)
                }}
                onAccept={date => {
                  if (!openCalendarFrom) {
                    setCalendarTo(false)
                    handleChange(date)
                  }
                }}
                shouldDisableDate={disablePastDates}
                maxDate={maxDateFrom}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={muiThemeDateFilter}>
          <KeyboardDatePicker
            disableToolbar={true}
            format="MM/dd/yyyy"
            margin="normal"
            variant="inline"
            id="date-picker-inline"
            value={selectedDate}
            onChange={handleChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            PopoverProps={{
              anchorEl: () => rootRef.current,
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              PaperProps: {
                style: {
                  backgroundColor: 'white',
                  borderRadius: '8px'
                }
              },
              'data-testid': props.testid + '_popover'
            }}
            DialogProps={{ className: classes.datePicker }}
            TextFieldComponent={() => null}
            open={openCalendar}
            onOpen={event => {
              event.preventDefault()
              event.target.focus({ preventScroll: true })
              setCalendar(true)
            }}
            onClose={() => {
              setCalendar(false)
            }}
            onAccept={() => setCalendar(false)}
            onFocus={event => {
              event.preventDefault()
              event.target.focus({ preventScroll: false })
            }}
          />
        </ThemeProvider>
      )}
    </MuiPickersUtilsProvider>
  )
}
