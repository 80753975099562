import React from 'react'

/** Material UI **/
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  makeStyles
} from '@material-ui/core'

/** Components **/
import { NavBar } from './NavBar'

/** Redux **/
import { useSelector } from 'react-redux'
import { navBarHeaderHeight } from '../lib/Constants'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    backgroundColor: theme.colors.mainContainerBackground,
    paddingLeft: '0px',
    paddingRight: '0px',
    overflowY: 'auto'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.colors.backdropColor
  },
  navBarOffset: {
    height: navBarHeaderHeight
  }
}))

export const MainContainer = props => {
  const classes = useStyles()
  const loadingStore = useSelector(state => state.loading)

  return (
    <div>
      <CssBaseline />
      <Container
        className={classes.container}
        maxWidth={false}
        style={{
          backgroundColor: props.backgroundColor
            ? props.backgroundColor
            : 'inherit'
        }}
      >
        <Backdrop className={classes.backdrop} open={loadingStore.loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <NavBar />
        <Box className={classes.navBarOffset}></Box>
        <Box>{props.children}</Box>
      </Container>
    </div>
  )
}
