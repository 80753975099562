/* eslint-disable camelcase */
import { React, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

// mui components
import {
  makeStyles,
  Container,
  Typography,
  Grid,
  Button,
  Box,
  Backdrop,
  CircularProgress
} from '@material-ui/core'

import {
  callLocationApi,
  parseStateList,
  callZipApi,
  parseZipList,
  parseCitiesList,
  parseCitiesResponse,
  parsePolygonList
} from '../../services/ApiLocationService'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  averageGeoLocation,
  getCoordinatesFromZipList,
  parseMapPin
} from '../../lib/Global'

import GlobalChip from '../form/Chip'
import GlobalNumberInput from '../form/NumberInput'
import GlobalSelect from '../form/Select'
import AddressAutocomplete from '../form/AddressAutocomplete'
import Maps from '../Maps'
import AreaServiceFilterComponent from './AreaServiceFilterComponent'

import { cloneDeep } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { loadingActions } from '../../store/loading'

const mapDimension = window.screen.width / 3 - 10

const useStyles = makeStyles(theme => ({
  infoContainer: {
    fontSize: '14px',
    fontWeight: '600',
    paddingTop: '24px'
  },
  itemContainer: {
    padding: '24px 80px 0px 80px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0 0 0'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  mapContainer: {
    height: 600
  },
  globalLabelContainer: {
    padding: '24px 80px 0px 80px',
    marginTop: '24px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0 0 0',
      flexDirection: 'column'
    }
  },
  tradeAndServiceContainer: {
    padding: '0px 80px 0px 80px',
    marginTop: '-25px',
    [theme.breakpoints.down('md')]: {
      padding: '24px 0 0 0',
      flexDirection: 'column'
    }
  },
  requiredAsterisk: {
    color: theme.colors.errorText
  },
  title: {
    fontSize: '20px',
    fontWeight: '700',
    color: theme.colors.black,
    lineHeight: '24px',
    letterSpacing: '0.05em',
    marginBottom: '24px'
  },
  subtitlePlaceholder: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '17px',
    color: theme.colors.errorText,
    paddingTop: '9px'
  },
  labelTitle: {
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '17px'
  },
  verticalSpace: {
    paddingRight: '10px'
  },
  addServiceArea: {
    color: 'white',
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: '400',
    borderRadius: '100px',
    width: '140px',
    alignSelf: 'flex-start',
    marginTop: '30px',
    backgroundColor: theme.colors.iconBlue
  },
  mapDimension: {
    width: 300,
    height: 300
  },
  topPadding: {
    paddingTop: 10
  },
  noTopPadding: {
    paddingLeft: 15
  },
  iconContent: {
    paddingTop: 8
  },
  icon: {
    color: theme.colors.buttonGrey,
    marginLeft: '5px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.colors.backdropColor
  },
  addressButton: {
    backgroundColor: 'red'
  },
  loadingMap: {
    textAlign: 'center',
    paddingTop: mapDimension / 2 - 50,
    paddingBottom: mapDimension / 2 - 50
  },
  buttonLoading: {
    position: 'absolute',
    paddingTop: 6
  },
  noTopPaddingChips: {
    marginTop: '-1em',
    paddingLeft: 15
  },
  comingSoonContainer: {
    padding: '24px 80px',
    display: 'flex',
    justifyContent: 'center'
  },
  comingSoonPlaceholder: {
    fontSize: '20px',
    fontWeight: '300',
    lineHeight: '17px',
    color: theme.colors.grey,
    textAlign: 'center'
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  inputMobiles: {
    [theme.breakpoints.down('xs')]: {
      width: 'fit-content !important',
      border: '1px solid ' + theme.colors.profile.border_input,
      borderRadius: '45px'
    }
  },
  flexWrap: {
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap'
    }
  },
  smallChip: {
    border: '1px solid ' + theme.colors.profile.border_input,
    borderRadius: '45px',
    fontSize: '12px',
    fontWeight: '400',
    padding: '12px',
    lineHeight: '14px',
    cursor: 'default',
    marginBottom: '15px'
  },
  smallChipSelect: {
    border: '1px solid ' + theme.colors.profile.border_input,
    borderRadius: '45px',
    fontSize: '12px',
    fontWeight: '400',
    padding: '5px',
    lineHeight: '14px',
    cursor: 'default',
    marginBottom: '15px'
  },
  emptyChip: {
    display: 'none'
  }
}))

export const ServiceComponent = props => {
  const gridSize = useRef()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { profile } = props
  const [formArea, setFormArea] = useState({})
  const [mapContainerWidth, setMapContainerWidth] = useState(0)
  const [states, setStateList] = useState([])
  const [cities, setCitiesList] = useState([])
  const [services, setServices] = useState([])
  const [formView, setFormView] = useState(true)
  const [mapLoading, setMapLoading] = useState(false)
  const [filterAreaSelected, setFilterAreaSelected] = useState({})
  const [countries, setCountries] = useState({
    value: 'United States',
    label: 'United States'
  })
  const [country, setCountry] = useState('United States')
  const [mapReady, setMapReady] = useState()

  // Map components
  const [markers, setMarkers] = useState([])
  const [paths, setPaths] = useState([])
  const [initialPosition, setInitialPosition] = useState()
  const [centerRadius, setCenterRadius] = useState(null)

  const [disableButtons, setDisableButtons] = useState(false)

  const loading = false

  const [areaSelected, setAreaSelected] = useState(new Set())
  const [displayMap, setDisplayMap] = useState(true)
  const [zoom, setZoom] = useState()
  const [trades, setTrades] = useState([])
  const tradesConfig = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.onboarding?.compliance?.serviceArea?.mandatory_trades
  )
  const [tradesSearchForm, setTradesSearchForm] = useState([])

  useEffect(() => {
    // To make sure page starts from the top
    setCountriesFromProfile()
    setMapContainerWidth(gridSize.current.clientWidth)
    if (profile?.service_area) {
      setServices(profile?.service_area)
    }
  }, [])

  const resetFromView = () => {
    setFormView(false)
    setTimeout(() => {
      setFormView(true)
    }, 50)
  }

  useEffect(() => {
    loadAsyncStates()
  }, [country])

  useEffect(() => {
    if (mapReady) dispatch(loadingActions.hide())
  }, [mapReady])

  const setCountriesFromProfile = () => {
    if (props.profile?.country?.length) {
      const supportedCountries = props.profile?.country.filter(
        country => country !== 'Mexico'
      )
      setCountries(
        supportedCountries.map(item => {
          return { value: item, label: item }
        })
      )
      if (supportedCountries.length > 0) setCountry(props.profile?.country[0])
    } else {
      loadAsyncStates()
    }
  }

  const loadAsyncStates = async () => {
    dispatch(loadingActions.show)
    const statesData = await callLocationApi('POST', '/states', {
      country: country
    })
    if (statesData?.data?.states) {
      setStateList(parseStateList(statesData?.data?.states))
      setCitiesList([])
      const newFormArea = cloneDeep(formArea)
      newFormArea.state = []
      newFormArea.city = []
      setFormArea(newFormArea)
    }
    dispatch(loadingActions.hide)
  }

  const loadAsyncCity = async state => {
    let allCities
    let citiesData
    if (country === 'Mexico') {
      citiesData = await callLocationApi('POST', '/state/cities', {
        country: country,
        state
      })
      if (citiesData.data) {
        allCities = parseCitiesResponse(citiesData?.data, null)
      }
    } else {
      citiesData = await callZipApi('GET', `/state/${getStateCode(state)}`)
      allCities = parseCitiesList(citiesData)
      allCities.sort(compareCity)
    }
    if (citiesData) {
      allCities.unshift({ value: 'All Cities', label: 'All Cities' })
      setCitiesList(allCities)
    }
    dispatch(loadingActions.hide)
  }
  function compareCity(a, b) {
    if (a.value.toLowerCase() < b.value.toLowerCase()) {
      return -1
    }
    if (a.value.toLowerCase() > b.value.toLowerCase()) {
      return 1
    }
    return 0
  }

  const removeSelectedArea = index => {
    const newServiceList = cloneDeep(services)
    newServiceList.splice(index, 1)
    setServices(newServiceList)
    props.handleChange(newServiceList, 'service_area')
    resetFromView()
    setAreaSelected(new Set())

    // Reset map components
    setCenterRadius(null)
    setMarkers([])
    setPaths([])
    setInitialPosition(null)
    resetMapViewLoading()
  }

  const renderAreaSelected = (serviceAreaItem, index) => {
    const savedTrades = (serviceAreaItem?.trades || [])
      .map(trade => ({ label: trade, value: trade }))
      .filter(savedTrade =>
        tradeOptions.some(option => option.value === savedTrade.value)
      )

    return (
      <>
        <Grid container key={index} className={classes.flexWrap}>
          <Grid item xs={12} sm={12} md={3} className={classes.verticalSpace}>
            <Typography className={classes.smallChip}>
              {t('company_profile.service_area_idx') + ` ${index + 1}`}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={12} md={3} className={classes.verticalSpace}>
            <Typography className={classes.smallChip}>
              {serviceAreaItem?.state ?? serviceAreaItem?.address}
            </Typography>
          </Grid>
          <Grid item xs={10} sm={12} md={3} className={classes.verticalSpace}>
            <Typography className={classes.smallChip}>
              {serviceAreaItem?.city
                ? serviceAreaItem?.city.join(', ')
                : `${serviceAreaItem?.radius} ${t(
                    'company_profile.placeholder.mile_radius'
                  )}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            className={`${classes.verticalSpace} ${classes.iconContent}`}
          >
            <a onClick={() => removeSelectedArea(index)}>
              <FontAwesomeIcon
                icon={['fa-regular', 'fa-circle-xmark']}
                size="xl"
                className={classes.icon}
              />
            </a>
          </Grid>
        </Grid>
        {savedTrades && (
          <Grid container className={classes.flexWrap}>
            <Grid item xs={10} sm={12} md={3} className={classes.verticalSpace}>
              {serviceAreaItem?.trades?.length <= 1 ? (
                <Typography
                  className={
                    !serviceAreaItem?.trades?.length
                      ? classes.emptyChip
                      : classes.smallChip
                  }
                >
                  {serviceAreaItem?.trades}
                </Typography>
              ) : (
                <GlobalSelect
                  options={savedTrades}
                  label={' '}
                  field="savedTrades"
                  onChange={() => {}}
                  placeholder={t('company_profile.multiple_trades_assigned')}
                  notSelectable
                  className={
                    !serviceAreaItem?.trades
                      ? classes.emptyChip
                      : classes.smallChipSelect
                  }
                />
              )}
            </Grid>
          </Grid>
        )}
      </>
    )
  }

  const onChangeForm = (value, field) => {
    const currentForm = cloneDeep(formArea)
    currentForm[field] = value
    switch (field) {
      case 'state':
        loadAsyncCity(value)
        currentForm.city = ['All Cities']
        break
      case 'city':
        if (
          value.includes('All Cities') &&
          value.indexOf('All Cities') === value.length - 1
        ) {
          currentForm.city = ['All Cities']
        } else {
          if (currentForm.city.indexOf('All Cities') !== -1) {
            currentForm.city.splice(currentForm.city.indexOf('All Cities'), 1)
          }
        }
        break
      default:
        break
    }
    setFormArea(currentForm)
  }

  const getStateCode = stateName => {
    const selectedState = states.find(item => item.value === stateName)
    return selectedState?.code ?? ''
  }

  const addAreaService = async () => {
    setDisableButtons(true)
    const newServices = cloneDeep(services)
    const { state, city, address, miles } = formArea
    let zip = []
    const stateSelected = getStateCode(state)
    if (city?.includes('All Cities')) {
      zip = await callZipApi('GET', `/state/${stateSelected}`)
    } else {
      zip = await callZipApi('POST', `/cities/state/${stateSelected}`, {
        cities: JSON.stringify(city)
      })
      if (zip?.length) {
        zip = zip?.filter(
          item =>
            item._source?.state === stateSelected &&
            city.includes(item._source?.primary_city) &&
            item._score > 8
        ) // Filter cities for te same state
      } else {
        zip = []
      }
    }
    setTrades([])
    const state_code = getStateCode(state)
    const newArea = {
      state,
      city,
      address,
      radius: miles,
      state_code,
      zip: parseZipList(zip),
      trades: trades
    }
    newServices.push(newArea)
    setServices(newServices)
    props.handleChange(newServices, 'service_area')
    setFormArea({})
    resetFromView()
    setDisableButtons(false)
    setDisplayMap(true)
  }

  const addAreaServiceFormSearch = async () => {
    setDisableButtons(true)
    const newServices = cloneDeep(services)
    const { address, miles, lat, lng } = formArea
    const zip = await callZipApi('GET', `/radius/${miles}/${lat}/${lng}`)
    const newArea = {
      address,
      radius: miles,
      lat,
      lng,
      zip: parseZipList(zip),
      trades: tradesSearchForm
    }
    newServices.push(newArea)
    setServices(newServices)
    props.handleChange(newServices, 'service_area')
    setFormArea({})
    resetFromView()
    setDisableButtons(false)
    setTradesSearchForm([])
  }

  const onChangeSearchAddress = place => {
    const { formatted_address } = place
    if (formatted_address) {
      const newFormArea = cloneDeep(formArea)
      newFormArea.address = formatted_address
      newFormArea.lat = place.geometry.location.lat()
      newFormArea.lng = place.geometry.location.lng()
      setFormArea(newFormArea)
    }
  }

  const servicesArray = servicesArray => {
    return servicesArray.map((item, index) => `Service Area ${index + 1}`)
  }

  const filterUnselectedZipCodes = (zipCodeList, unselected) => {
    if (!zipCodeList?.length) return []
    const { cities, zip, county } = unselected
    const newZipList = []
    for (const item of zipCodeList) {
      if (
        cities?.includes(item.city) ||
        zip?.includes(item.zip) ||
        county?.includes(item.county)
      ) {
        item.selected = false
      }
      newZipList.push(item)
    }
    return newZipList
  }

  const getZipCodesFiltered = async serviceArea => {
    const { state_code, city, radius, lat, lng, unselected } = serviceArea
    let zip = []
    if (radius && lat && lng) {
      zip = await callZipApi('GET', `/radius/${radius}/${lat}/${lng}`)
    } else {
      if (city?.includes('All Cities')) {
        zip = await callZipApi('GET', `/state/${state_code}`)
      } else {
        zip =
          (await callZipApi('POST', `/cities/state/${state_code}`, {
            cities: JSON.stringify(city)
          })) ?? []
        if (zip?.length) {
          zip = zip?.filter(
            item =>
              item._source?.state === state_code &&
              city.includes(item._source?.primary_city) &&
              item._score > 8
          )
        }
      }
    }
    zip = parseZipList(zip)
    if (unselected) {
      zip = filterUnselectedZipCodes(zip, unselected)
    }
    return zip
  }

  const setAreaTypeOption = async selection => {
    setMapReady(false)
    dispatch(loadingActions.show())

    const currentFilter = cloneDeep(areaSelected)
    const iterator = currentFilter[Symbol.iterator]()
    // Get current Value in set
    const currentItem = iterator.next().value
    if (currentItem && selection.has(currentItem) && selection.size > 1) {
      selection.delete(currentItem)
    }
    setAreaSelected(selection)
    const values = selection.values()
    const currentValue = values.next().value
    if (currentValue) {
      const currentServiceArea = getServiceSelected(currentValue)
      if (!currentServiceArea?.zip) {
        setMapLoading(true)
        currentServiceArea.zip = await getZipCodesFiltered(currentServiceArea)
        updateServiceAreaFromFilters(currentServiceArea)
        setMapLoading(false)
      }
      await parseDataToMapView(currentServiceArea)
      setFilterAreaSelected(cloneDeep(currentServiceArea))
    }
    resetMapViewLoading()
  }

  const getServiceSelected = serviceAreaSelected => {
    const arrayOfServiceLabels = servicesArray(services)
    const index = arrayOfServiceLabels.indexOf(serviceAreaSelected)
    if (index > -1) {
      return services[index]
    }
    return null
  }

  const resetMapViewLoading = () => {
    setMapLoading(true)
    setTimeout(() => {
      setMapLoading(false)
    }, 500)
  }

  const parseDataToMapView = async (
    serviceAreaSelected,
    refreshView = true
  ) => {
    const selectionZipArray = cloneDeep(serviceAreaSelected.zip)
    if (!selectionZipArray?.length) return
    if (serviceAreaSelected.radius) {
      setCenterRadius({
        lat: serviceAreaSelected.lat,
        lng: serviceAreaSelected.lng,
        radius: ~~serviceAreaSelected.radius
      })
    }
    const cities = selectionZipArray
    const markList = parseMapPin(cities)
    setMarkers(markList)
    // zip id list to query polygons
    const zipIds = []
    markList.map(async marker => {
      zipIds.push(marker.id)
    })
    // query polygon data for selected area
    const zipResponse = await getCoordinatesFromZipList(zipIds, setMapReady)
    const pathList = []
    // parse pathlist from response
    pathList.push(parsePolygonList(zipResponse))
    setPaths(pathList)

    findZoom(markList)
    const midPoint = averageGeoLocation(markList)
    setInitialPosition(midPoint)

    if (refreshView) resetMapViewLoading()
    setDisplayMap(true)
  }

  const updateServiceAreaFromFilters = serviceArea => {
    const newArrayOfServices = cloneDeep(services)
    const index = newArrayOfServices.findIndex(
      item =>
        item.radius === serviceArea.radius &&
        JSON.stringify(item.city) === JSON.stringify(serviceArea.city) &&
        item.state === serviceArea.state &&
        item.address === serviceArea.address
    )
    if (index > -1) {
      newArrayOfServices[index] = cloneDeep(serviceArea)
      setServices(newArrayOfServices)
      props.handleChange(newArrayOfServices, 'service_area')
    }
  }

  const onMarkerClick = async zipCode => {
    if (zipCode) return // TODO: remove this line to allow the unselect functionality
    const currentFilter = cloneDeep(areaSelected)
    const iterator = currentFilter[Symbol.iterator]()
    const currentItem = iterator.next().value
    const currentServiceArea = cloneDeep(getServiceSelected(currentItem) ?? {})
    if (currentServiceArea?.zip) {
      const allZipList = cloneDeep(currentServiceArea.zip)
      const newZipSelection = allZipList.map(item => {
        if (item.zip === zipCode) item.selected = false
        return item
      })
      currentServiceArea.zip = newZipSelection
      await parseDataToMapView(currentServiceArea, false)
    }
  }

  const findZoom = markers => {
    let minLat = 90
    let maxLat = -90
    let minLng = 180
    let maxLng = -180
    markers?.forEach(mark => {
      if (mark.lat < minLat) minLat = mark.lat
      if (mark.lat > maxLat) maxLat = mark.lat
      if (mark.lng < minLng) minLng = mark.lng
      if (mark.lng > maxLng) maxLng = mark.lng
    })
    const coordDiff = maxLat - minLat + (maxLng - minLng)
    let zoomCalc
    if (coordDiff === 0) zoomCalc = 12
    else if (coordDiff < 0.8) zoomCalc = 10
    else if (coordDiff < 1) zoomCalc = 9
    else if (coordDiff < 2) zoomCalc = 8
    else if (coordDiff < 4) zoomCalc = 7
    else zoomCalc = 6

    setZoom(zoomCalc)
  }

  const tradeOptions = profile?.trades?.map(trade => ({
    value: trade.name,
    label: trade.name
  }))

  const handleTradeChange = event => {
    setTrades(event)
  }
  const handleTradeSearchFormChange = event => {
    setTradesSearchForm(event)
  }

  return (
    <Container classes={{ root: classes.infoContainer }}>
      <Grid container className={classes.globalLabelContainer}>
        <Grid item xs={12}>
          {(props.profile?.country?.length !== 1 ||
            props.profile?.country[0] !== 'Mexico') &&
            services.length > 0 && (
              <Typography classes={{ root: classes.title }}>
                {t('company_profile.saved_service_area')}
              </Typography>
            )}
          {(props.profile?.country?.length !== 1 ||
            props.profile?.country[0] !== 'Mexico') &&
            (!services.length ? (
              <>
                <Typography classes={{ root: classes.subtitlePlaceholder }}>
                  <span className={classes.required}>*</span>
                  {t('company_profile.placeholder.no_service_area')}
                </Typography>
              </>
            ) : (
              <>
                {services.map((item, index) => renderAreaSelected(item, index))}
              </>
            ))}
        </Grid>
      </Grid>

      {props.profile?.country?.length === 1 &&
      props.profile?.country[0] === 'Mexico' ? (
        <Grid container className={classes.comingSoonContainer}>
          <Typography classes={{ root: classes.comingSoonPlaceholder }}>
            {t('company_profile.placeholder.no_geo_coordinates')}
          </Typography>
        </Grid>
      ) : (
        <>
          <Grid container className={classes.globalLabelContainer}>
            <Grid item xs={12}>
              <Typography classes={{ root: classes.title }}>
                {t('company_profile.service_area')}
              </Typography>
              <Typography classes={{ root: classes.labelTitle }}>
                {t('company_profile.labels.select_in_map')}
              </Typography>
            </Grid>

            {formView && countries.length > 1 && (
              <Grid item xs={3} className={classes.verticalSpace}>
                <br />
                <GlobalSelect
                  options={countries}
                  onChange={(value, field) => setCountry(value)}
                  label={' '}
                  field="country"
                  placeholder={`${t(
                    'company_profile.placeholder.select_country'
                  )}`}
                  value={country ?? ''}
                  multiple={false}
                />
              </Grid>
            )}

            {formView && (
              <Grid item xs={3} className={classes.verticalSpace}>
                <br />
                <GlobalSelect
                  options={states}
                  onChange={onChangeForm}
                  label={' '}
                  field="state"
                  placeholder={`${
                    country === 'Canada'
                      ? t('company_profile.placeholder.select_territory')
                      : t('company_profile.placeholder.select_state')
                  }`}
                  value={formArea?.state ?? ''}
                  multiple={false}
                  disabled={formView && countries.length > 1 && !country}
                />
              </Grid>
            )}

            {formView && (
              <Grid item xs={3} className={classes.verticalSpace}>
                <br />
                <GlobalSelect
                  options={cities ?? []}
                  onChange={onChangeForm}
                  label={' '}
                  field="city"
                  placeholder={`${t(
                    'company_profile.placeholder.select_city'
                  )}`}
                  value={formArea?.city ?? []}
                  multiple={true}
                  disabled={!formArea?.state || formArea.state.length === 0}
                />
              </Grid>
            )}
            {formView && (
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  component="span"
                  className={classes.addServiceArea}
                  disabled={
                    !(
                      formArea?.state &&
                      formArea?.city?.length &&
                      (!tradesConfig || trades?.length) &&
                      !disableButtons
                    )
                  }
                  onClick={addAreaService}
                >
                  {t('company_profile.labels.add_service_area')}
                  {formArea?.state &&
                    formArea?.city?.length > 0 &&
                    disableButtons && (
                      <div className={classes.buttonLoading}>
                        <CircularProgress color="inherit" size={20} />{' '}
                      </div>
                    )}
                </Button>
              </Grid>
            )}

            {!formView && (
              <Grid item xs={12}>
                <CircularProgress color="inherit" />
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.tradeAndServiceContainer}>
            {formView && (
              <Grid item xs={3} className={classes.verticalSpace}>
                <br />
                <GlobalSelect
                  options={tradeOptions}
                  onChange={handleTradeChange}
                  label={' '}
                  field="trades"
                  placeholder={
                    <span>
                      {tradesConfig && (
                        <span className={classes.requiredAsterisk}>*</span>
                      )}
                      {t('company_profile.placeholder.assign_trade')}
                    </span>
                  }
                  value={trades ?? []}
                  multiple={true}
                />
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.itemContainer}>
            <Grid item xs={12}>
              <Typography classes={{ root: classes.labelTitle }}>
                {'OR, select your sevice area using a simple radius.'}
              </Typography>
            </Grid>

            {formView && (
              <Grid item xs={3} className={classes.verticalSpace}>
                <AddressAutocomplete
                  placeholder={t('company_profile.placeholder.search_address')}
                  onSelect={place => {
                    onChangeSearchAddress(place)
                  }}
                  className={classes.inputMobiles}
                />
              </Grid>
            )}

            {formView && (
              <Grid item xs={3} className={classes.verticalSpace}>
                <br />
                <GlobalNumberInput
                  onChange={onChangeForm}
                  field="miles"
                  placeholder={'Enter radius in miles'}
                  value={formArea?.miles}
                  label={' '}
                  error={parseFloat(formArea?.miles) > 150}
                  helperText={'Cannot be more than 150 miles'}
                  allowNegative={false}
                  className={classes.inputMobiles}
                  format="#########"
                />
              </Grid>
            )}

            {formView && (
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  component="span"
                  className={classes.addServiceArea}
                  onClick={addAreaServiceFormSearch}
                  disabled={
                    !(
                      formArea?.miles &&
                      parseFloat(formArea?.miles) <= 150 &&
                      formArea?.address &&
                      (!tradesConfig || tradesSearchForm?.length) &&
                      !disableButtons
                    )
                  }
                >
                  {t('company_profile.labels.add_service_area')}
                  {formArea?.miles &&
                    parseFloat(formArea?.miles) <= 150 &&
                    formArea?.address &&
                    disableButtons && (
                      <div className={classes.buttonLoading}>
                        <CircularProgress color="inherit" size={20} />{' '}
                      </div>
                    )}
                </Button>
              </Grid>
            )}

            {!formView && (
              <Grid item xs={12}>
                <CircularProgress color="inherit" />
              </Grid>
            )}

            {!formView && (
              <Grid item xs={12}>
                <CircularProgress color="inherit" />
              </Grid>
            )}
          </Grid>
          <Grid container className={classes.tradeAndServiceContainer}>
            {formView && (
              <Grid item xs={3} className={classes.verticalSpace}>
                <br />
                <GlobalSelect
                  options={tradeOptions}
                  onChange={handleTradeSearchFormChange}
                  label={' '}
                  field="tradesSearchForm"
                  placeholder={
                    <span>
                      {tradesConfig && (
                        <span className={classes.requiredAsterisk}>*</span>
                      )}
                      {t('company_profile.placeholder.assign_trade')}
                    </span>
                  }
                  value={tradesSearchForm ?? []}
                  multiple={true}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Box className={classes.mapContainer}>
        <Grid container className={classes.itemContainer}>
          <Grid item xs={12} sm={12} md={7} lg={7} xl={7} ref={gridSize}>
            {displayMap ? (
              <Maps
                height={mapContainerWidth}
                width={mapContainerWidth}
                markers={markers}
                initialCenter={initialPosition}
                circle={centerRadius}
                onMarkerClick={onMarkerClick}
                paths={paths}
                zoom={zoom}
              />
            ) : (
              <div className={classes.loadingMap}>
                <CircularProgress color="inherit" />
              </div>
            )}
          </Grid>

          {/* MOBILE */}
          <Grid
            item
            xs={5}
            className={`${classes.verticalSpace} ${classes.mobile}`}
          >
            {services.length > 0 && formView && (
              <Box display="flex" className={classes.noTopPadding}>
                <GlobalChip
                  chips={servicesArray(services)}
                  selected={areaSelected}
                  setSelected={setAreaTypeOption}
                  searchVisible={false}
                  skipTranslate={true}
                  parent="area"
                  removeItem={() => {}}
                  allowNoSelection={false}
                />
              </Box>
            )}
          </Grid>

          {/* DESKTOP */}
          <Grid
            item
            xs={5}
            className={`${classes.verticalSpace} ${classes.desktop}`}
          >
            {services.length > 0 && formView && (
              <Box display="flex" className={classes.noTopPaddingChips}>
                <GlobalChip
                  chips={servicesArray(services)}
                  selected={areaSelected}
                  setSelected={setAreaTypeOption}
                  searchVisible={false}
                  skipTranslate={true}
                  parent="area"
                  removeItem={() => {}}
                  allowNoSelection={false}
                />
              </Box>
            )}

            {!mapLoading && (
              <AreaServiceFilterComponent
                trades={props.profile.trades}
                currentSelection={filterAreaSelected}
                updateMapView={parseDataToMapView}
                onUpdateServiceArea={updateServiceAreaFromFilters}
                disabled={areaSelected.size === 0}
              />
            )}
          </Grid>
        </Grid>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Container>
  )
}
