import { signIn } from './signIn'
import { requestAccess } from './requestAccess'
import { forgotPassword } from './forgotPassword'
import { general } from './general'
import { navBar } from './navBar'
import { sideBar } from './sideBar'
import { forgotPasswordCode } from './forgotPasswordCode'
import { contactUs } from './contactUs'
import { workOrders } from './workOrders'
import { companySettings } from './companySettings'
import { accountSettings } from './accountSettings'
import { invoices } from './invoices'
import { companyProfile } from './companyProfile'

export const i18nEs = () => {
  return {
    sign_in: signIn(),
    create_account: requestAccess(),
    forgot_password: forgotPassword(),
    forgot_password_code: forgotPasswordCode(),
    nav_bar: navBar(),
    side_bar: sideBar(),
    general: general(),
    contact_us: contactUs(),
    work_orders: workOrders(),
    invoices: invoices(),
    company_settings: companySettings(),
    account_settings: accountSettings(),
    company_profile: companyProfile()
  }
}
