import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { Box, useTheme } from '@material-ui/core'
const useStyles = makeStyles({})

export default function CustomizedProgressBars({ percentage }) {
  const classes = useStyles()
  const theme = useTheme()
  const [color, setColor] = useState(theme.progressBar.green100)

  useEffect(() => {
    switch (true) {
      case +percentage === 0:
        setColor(theme.colors.errorColor)
        break
      case percentage <= 25:
        setColor(theme.progressBar.blue25)
        break
      case percentage <= 50:
        setColor(theme.progressBar.blue50)
        break
      case percentage <= 75:
        setColor(theme.progressBar.green75)
        break
      case percentage <= 100:
        setColor(theme.progressBar.green100)
        break
      default:
        setColor(theme.colors.errorColor)
        break
    }
  }, [percentage])

  const linearProgressStyles = {
    containerStyles: {
      height: 13,
      width: '100%',
      backgroundColor: `${
        +percentage === 0 ? 'transparent' : theme.progressBar.bgcolor
      }`,
      borderRadius: 50,
      transform: 'rotate(-90deg)',
      position: 'absolute',
      left: '1.9rem',
      bottom: '0.9rem'
    },
    fillerStyles: {
      height: '100%',
      width: `${+percentage === 0 ? 20 : +percentage}%`,
      backgroundColor: color,
      borderRadius: 'inherit',
      textAlign: 'right'
    },
    labelStyles: {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }
  }

  return (
    <div className={classes.root}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'relative'
        }}
      >
        <Box style={linearProgressStyles.containerStyles}>
          <Box style={linearProgressStyles.fillerStyles}></Box>
        </Box>
      </Box>
    </div>
  )
}
