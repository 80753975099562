import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Typography,
  makeStyles,
  IconButton,
  InputLabel,
  InputBase,
  Link,
  Input,
  Grid,
  Menu,
  MenuItem,
  Divider,
  CircularProgress
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import {
  submitInvoice,
  createInvoiceAttachment,
  putCompanyBillDisclosure,
  deleteInvoiceAttachment
} from '../../services/ApiService'
import { DetailedInfo } from '../workorders/DetailedInfo'
import { SubmitConfirmation } from './SubmitConfirmation'
import ReactGA from 'react-ga4'
import { LoadingButton } from '@mui/lab'
import LoadingBox from './LoadingBox'
import {
  alphaNumericDashRegex,
  invoiceGreenStatus,
  invoiceRedStatus,
  mcssfr,
  mcs360
} from '../../lib/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from '../../store/signIn'
import attachIcon from '../../assets/icons/attach.svg'
import { invoicesActions } from '../../store/invoices'
import { ConfirmDialog } from '../form/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  companyInfo: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.colors.invoiceColors.table_text,
    fontSize: '12px',
    fontWeight: '500'
  },
  companySubInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: '0px',
    color: theme.colors.invoiceColors.table_text,
    fontSize: '10px'
  },
  billInfo: {
    flex: 1,
    justifyContent: 'flex-start',
    marginTop: '0px',
    color: theme.colors.invoiceColors.table_text,
    fontSize: '10px'
  },
  nteMessage: {
    flex: 1,
    textAlign: 'center',
    color: theme.colors.invoiceColors.red_error,
    fontSize: '10px'
  },
  statusLabel: {
    fontSize: '40px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.dark_grey,
    lineHeight: '47px',
    textAlign: 'center'
  },
  nteLabel: {
    fontSize: '16px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.dark_grey,
    lineHeight: '19px',
    textAlign: 'center'
  },
  noPadding: {
    padding: '0 !important'
  },
  nteHide: {
    display: 'none'
  },
  nteShow: {
    marginTop: '0px'
  },
  declinedLabel: {
    fontSize: '40px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.red_error,
    marginTop: '20px',
    textAlign: 'center'
  },
  draftLabel: {
    fontSize: '40px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.table_text,
    marginTop: '20px',
    textAlign: 'center'
  },
  approvedLabel: {
    fontSize: '40px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.approved_green,
    marginTop: '20px',
    textAlign: 'center'
  },
  scheduledLabel: {
    fontSize: '22px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.approved_green,
    textAlign: 'center'
  },
  scheduledDeclinedLabel: {
    fontSize: '12px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.red_error,
    textAlign: 'center'
  },
  scheduledNormalLabel: {
    fontSize: '22px',
    fontWeight: '700',
    color: theme.colors.invoiceColors.dark_grey,
    textAlign: 'center'
  },
  statusMessage: {
    fontSize: '10px',
    textAlign: 'center',
    color: theme.colors.invoiceColors.dark_grey,
    fontWeight: '400'
  },
  clientInfo: {
    borderRadius: '8px',
    border: '1px solid ' + theme.colors.invoiceColors.header_grey,
    color: theme.colors.invoiceColors.table_text,
    fontSize: '12px',
    width: 'max-content',
    minWidth: '60px',
    padding: '8px 48px 8px 12px',
    'label + &': {
      marginTop: theme.spacing(0.5)
    },
    marginRight: theme.spacing(2)
  },
  clientInfoLocationWidth: {
    width: 'auto'
  },
  totalContainer: {
    borderRadius: '8px',
    border: '1px solid ' + theme.colors.invoiceColors.header_grey,
    color: theme.colors.invoiceColors.table_text,
    fontSize: '12px',
    padding: '3px',
    width: '160px',
    marginleft: 'auto',
    marginRight: '0',
    'label + &': {
      marginTop: theme.spacing(0.5)
    }
  },
  clientInfoAmount: {
    textAlign: 'center',
    fontWeight: '500'
  },
  bootstrapFormLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: theme.colors.invoiceColors.labels_grey
  },
  createButton: {
    '&.MuiLoadingButton-root': {
      border: '1px solid ' + theme.colors.invoiceColors.blue_button,
      color: theme.colors.invoiceColors.blue_button,
      textTransform: 'capitalize',
      width: '160px',
      height: '56px',
      borderRadius: '10px',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.16px',
      '&.Mui-disabled': {
        border: '1px solid ' + theme.colors.invoiceColors.disabled_grey
      }
    }
  },
  invoiceNumber: {
    fontSize: '12px',
    paddingLeft: '3px',
    color: theme.colors.invoiceColors.dark_grey,
    fontWeight: '700',
    width: '80px'
  },
  headerContainer: {
    flexGrow: 1,
    margin: '0px 20px 20px',
    paddingTop: '8px'
  },
  invoiceNumberContainer: {
    textAlign: 'right',
    fontSize: '18px',
    fontWeight: '700'
  },
  wo_container: {
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'none',
    textDecoration: 'underline'
  },
  wo_menu: {
    '&.MuiMenu-list': {
      padding: '0'
    }
  },
  wo_item: {
    textAlign: 'right',
    fontSize: '12px',
    padding: '10px',
    fontWeight: '400',
    textTransform: 'none',
    width: '150px'
  },
  wo_completed: {
    textAlign: 'right',
    fontSize: '10px',
    fontWeight: '500',
    color: theme.colors.invoiceColors.dark_grey
  },
  internal_number: {
    fontSize: '12px',
    fontWeight: '500',
    color: theme.colors.invoiceColors.dark_grey,
    lineHeight: '14px',
    letterSpacing: '0.01em',
    textAlign: 'right'
  },
  pointer: {
    cursor: 'pointer'
  },
  loadingDiv: {
    height: '10px'
  },
  bottomHeader: { margin: '0 8px 0 10px', width: '100%' },
  hide: {
    display: 'none'
  },
  loadingBox_1: {
    width: '100%',
    height: '50px',
    margin: '11px 0px'
  },
  loadingBox_2: {
    width: '100%',
    height: '30px',
    margin: '20px'
  },
  flexBox_end: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  bottomContainer: {
    display: 'flex',
    width: '100%',
    padding: '10px'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  attachInput: {
    display: 'none'
  },
  attachDiv: {
    display: 'flex'
  },
  attachButton: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: theme.colors.white,
    color: theme.colors.iconBlue,
    width: '15px',
    marginLeft: 'auto'
  },
  attachTypo: {
    textAlign: 'initial',
    color: theme.colors.iconBlue,
    fontSize: '10px',
    fontWeight: '500',
    margin: 'auto 0px auto 10px',
    display: '-webkit-box'
  },
  attachTypoOld: {
    cursor: 'pointer',
    textAlign: 'initial',
    color: theme.colors.iconBlue,
    fontSize: '10px',
    fontWeight: '500',
    margin: 'auto 0px auto 10px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  endingAttach: {
    textAlign: 'initial',
    color: theme.colors.iconBlue,
    fontSize: '10px',
    fontWeight: '500',
    margin: 'auto 0px auto 0px'
  },
  endingAttachOld: {
    cursor: 'pointer',
    textAlign: 'initial',
    color: theme.colors.iconBlue,
    fontSize: '10px',
    fontWeight: '500',
    margin: 'auto 0px auto 0px',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  attachmentBox: {
    cursor: 'pointer',
    display: 'flex',
    textAlign: 'right'
  },
  loadingBox: {
    display: 'flex',
    textAlign: 'right',
    marginLeft: '3px'
  },
  deleteIcon: {
    cursor: 'pointer',
    border: 'none',
    backgroundColor: theme.colors.white,
    color: theme.colors.grey,
    width: '15px'
  },
  listAttahcments: {
    display: 'flex',
    textAlign: 'right',
    flexWrap: 'wrap',
    justifyContent: 'flex-end'
  },
  boxAttahcment: {
    display: 'flex',
    textAlign: 'right'
  },
  attachError: {
    color: theme.colors.errorColor,
    fontSize: '10px',
    textAlign: 'end'
  },
  attachIcon: {
    width: '17px',
    margin: '0px 3px 3px 0px'
  },
  loadingFiles: {
    marginLeft: 'auto',
    marginTop: '3px',
    color: theme.colors.iconBlue
  }
}))

function InvoiceHeader(props) {
  const {
    data,
    totalAmount,
    allItems,
    setData,
    autoSave,
    currentStatus,
    loading,
    scheduled,
    multipleWoNumbers,
    balanceDue,
    project,
    disableSubmitButton,
    lienMandatory,
    notes,
    attachments,
    setAttachments,
    setDisableButtons
  } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const fileInput = useRef(null)
  const [submitEnabled, setSubmitEnabled] = useState(false)
  const [selectedWo, setSelectedWo] = useState(null)
  const [nteMessageEnabled, setNteMessageEnabled] = useState(false)
  const [managementAddress, setManagementAddress] = useState('')
  const [subcontractorAddress, setSubcontractorAddress] = useState('')
  const [reload, setReload] = useState(false)
  const [submitConfirmation, setSubmitConfirmation] = useState({
    title: '',
    content: '',
    open: false
  })
  const fileAvailable = false
  const [isLoading, setLoading] = useState(false)
  const userData = useSelector(state => state.auth?.user?.userInfo)
  const billDisclosureStore = useSelector(state => state.auth.billDisclosure)
  const loadingFiles = useSelector(state => state.invoices.loadingFiles)
  const [internalInvoiceNumber, setInternalInvoiceNumber] = useState(
    data?.internal_number
  )
  const [hideDeleteAttachment, setHideDeleteAttachment] = useState(false)

  useEffect(() => {
    dispatch(invoicesActions.setLoadingFiles(false))
  }, [])

  const canExceedNTEConfig = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.invoices?.can_exceed_nte
  )
  const mandatoryNotesConfig = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.invoices?.mandatory_notes
  )

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [dialogNotes, setDialogNotes] = useState('')
  const [nteExceedConfirmDialogOpen, setNteExceedConfirmDialogOpen] =
    useState(false)
  const [nteValue, setNteValue] = useState(0)

  useEffect(() => {
    if (selectedWo === undefined) {
      setSelectedWo(data?.work_order)
    }
    if (typeof data?.management_company?.address === 'object') {
      setManagementAddress(data?.management_company?.address?.address)
    }
    if (typeof data?.management_company?.address === 'string') {
      setManagementAddress(data?.management_company?.address)
    }
    if (typeof data?.subcontractor_company?.address === 'object') {
      setSubcontractorAddress(data?.subcontractor_company?.address?.address)
    }
    if (typeof data?.subcontractor_company?.address === 'string') {
      setSubcontractorAddress(data?.subcontractor_company?.address)
    }
    if (
      currentStatus === t('invoices.in_states.approved').toLowerCase() ||
      currentStatus === t('invoices.in_states.submitted').toLowerCase() ||
      currentStatus === t('invoices.in_states.paid').toLowerCase() ||
      currentStatus === t('invoices.in_states.hold').toLowerCase() ||
      currentStatus === t('invoices.in_states.void').toLowerCase()
    ) {
      setSubmitEnabled(false)
      autoSave(false)
      return
    }
    const nteValue =
      data?.work_order?.nte !== null ? Number(data?.work_order?.nte) : 0
    if (
      totalAmount > 0 &&
      (nteValue === 0 || totalAmount <= nteValue || canExceedNTEConfig)
    ) {
      setSubmitEnabled(true)
      setNteMessageEnabled(false)
    } else if (nteValue) {
      setSubmitEnabled(false)
    } else if (!totalAmount) {
      setSubmitEnabled(false)
    }
    if (nteValue > 0 && totalAmount > nteValue) {
      setNteMessageEnabled(true)
    }
    if (!props.isValidItemSelected) {
      setSubmitEnabled(false)
    }
    if (balanceDue < 0) {
      setSubmitEnabled(false)
    }
    if (
      typeof data?.internal_number !== 'undefined' &&
      data?.internal_number !== null
    ) {
      setInternalInvoiceNumber(data.internal_number)
    }
    setNteValue(nteValue)
  }, [data, totalAmount, balanceDue, notes, mandatoryNotesConfig])

  const handleSubmit = async (event, submitData) => {
    try {
      if (
        billDisclosureStore?.title &&
        billDisclosureStore?.content &&
        (!billDisclosureStore?.accepted || lienMandatory)
      ) {
        setSubmitConfirmation({ ...billDisclosureStore, open: true })
      } else if (mandatoryNotesConfig && !data.notes?.trim() && !project) {
        setConfirmDialogOpen(true)
      } else if (
        canExceedNTEConfig &&
        totalAmount > nteValue &&
        nteValue > 0 &&
        !project
      ) {
        setNteExceedConfirmDialogOpen(true)
      } else {
        await callInvoiceSubmit(submitData)
      }
    } catch (error) {
      console.error('Error submitting invoice: ', error)
    }
  }

  const callInvoiceSubmit = async submitData => {
    autoSave(false)
    setDisableButtons(true)
    setHideDeleteAttachment(true)
    const tempRequestBody = {
      ...data,
      status: t('invoices.in_states.submitted').toLowerCase(),
      work_order: submitData.work_order,
      due_date: submitData.due_date,
      term: submitData.term,
      history: [...submitData.history],
      internal_number: submitData.internal_number,
      notes: submitData.notes,
      attachments,
      line_items: allItems,
      total: totalAmount
    }
    try {
      setLoading(true)
      const response = await submitInvoice(submitData.id, tempRequestBody, true)
      setData(response.content)
      setLoading(false)
      setSubmitEnabled(false)
      setDisableButtons(false)
      setHideDeleteAttachment(false)
    } catch (error) {
      console.error('Error submitting invoice: ', error)
    }
  }

  const handleChange = event => {
    event.preventDefault()
    setInternalInvoiceNumber(event.target.value)
  }

  const saveInternalNumber = event => {
    data.internal_number = internalInvoiceNumber
    setData(data)
    autoSave(true)
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    handleCloseWoPanel(null)
    setAnchorEl(event.currentTarget)
  }
  const handleWoPanelEvent = wo => {
    setAnchorEl(null)
    if (wo.customer_po) {
      ReactGA.event({
        category: 'show',
        action: 'show_work_order_detail'
      })
      setSelectedWo(wo)
    }
  }
  const handleCloseWoPanel = () => {
    setSelectedWo(null)
  }

  const paidMessage = () => {
    let message = t('invoices.paid_message')
    if (data?.payment_type && data?.ach_check_number) {
      message +=
        t('invoices.paid_by_message').replace('{type}', data.payment_type) +
        ' ' +
        data.ach_check_number
    } else if (data?.payment_type) {
      message += t('invoices.paid_by_message').replace(
        '{type}',
        data.payment_type
      )
    } else if (data?.ach_check_number) {
      message += t('invoices.paid_by_message').replace(
        '{type}',
        data.ach_check_number
      )
    }
    if (data?.pay_date) {
      const payDate = moment(new Date(data.pay_date * 1000)).format(
        t('general.format.short_date')
      )
      message += ' ' + t('invoices.paid_on_message').replace('{date}', payDate)
    }
    return message
  }

  const deleteValidFile = async actualFile => {
    if (scheduled) return
    dispatch(invoicesActions.setLoadingFiles(true))
    await deleteInvoiceAttachment(data.id, actualFile.external_id)
    if (attachments.length === 1) {
      setAttachments([])
    } else {
      setAttachments(
        attachments.filter(
          (element, ind) => element.external_id !== actualFile.external_id
        )
      )
    }
    dispatch(invoicesActions.setLoadingFiles(false))
  }

  const handleClickAttachment = event => {
    if (scheduled) return
    fileInput.current.click()
  }

  const onFileChange = e => {
    if (scheduled) return
    const toInsert = []
    e.target.files.forEach(element => {
      const duplicate = attachments.find(
        attachment => attachment.title === element.name
      )
      if (!duplicate) toInsert.push(element)
    })
    if (toInsert.length > 0) {
      const attachmentsLength = attachments.length
      saveAttachmentsDraft(toInsert.slice(-(3 - attachmentsLength)))
    }
    e.target.value = ''
  }

  const saveAttachmentsDraft = async toInsert => {
    dispatch(invoicesActions.setLoadingFiles(true))
    const validFiles = []
    for (const attachment of toInsert) {
      validFiles.push({
        invoice_id: data.id,
        title: attachment.name,
        content: attachment
      })
    }
    const attachmentsRes = await createInvoiceAttachment(validFiles)
    const partialRequestBody = {
      status: t('invoices.in_states.draft').toLowerCase(),
      attachments: [...attachments, ...attachmentsRes]
    }
    const response = await submitInvoice(data.id, partialRequestBody, false)
    setAttachments(response.content.attachments)
    dispatch(invoicesActions.setLoadingFiles(false))
  }

  const getTruncatedText = text => {
    if (text.length > 20) {
      const start = text.slice(0, 5)
      const pointIndex = text.lastIndexOf('.')
      const end = text.slice(-(5 + (text.length - pointIndex)))
      return start + '...' + end
    }
    return text
  }

  const downloadURI = (uri, name) => {
    const link = document.createElement('a')
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    link.remove()
  }

  const handleAcceptSubmit = async value => {
    if (value) {
      const newbill = await putCompanyBillDisclosure(userData.company_id)
      dispatch(
        authActions.setBillDisclosure({
          ...billDisclosureStore,
          ...newbill.bill_disclosure
        })
      )
    }
    setSubmitConfirmation(state => ({ ...state, open: false }))
    await callInvoiceSubmit(data)
  }
  const handleCloseSubmitConfirmation = () => {
    setSubmitConfirmation(state => ({ ...state, open: false }))
  }

  const handleDialogNotesChange = e => {
    setDialogNotes(e.target.value)
  }

  const handleConfirmAccept = () => {
    setConfirmDialogOpen(false)
    props.setNotes(dialogNotes)
    data.notes = dialogNotes
    setData(data)
    handleSubmit(null, { ...data, notes: dialogNotes })
  }

  const handleNteExceedConfirmAccept = async () => {
    try {
      setNteExceedConfirmDialogOpen(false)
      await callInvoiceSubmit(data)
    } catch (error) {
      console.error('Error submitting invoice: ', error)
    }
  }

  return (
    <div>
      <SubmitConfirmation
        open={submitConfirmation.open}
        setOpen={value =>
          setSubmitConfirmation(state => ({ ...state, open: value }))
        }
        title={submitConfirmation.title}
        message={submitConfirmation.content}
        handleAccept={handleAcceptSubmit}
        hideCheckBox={lienMandatory}
        handleClose={handleCloseSubmitConfirmation}
      />
      <DetailedInfo
        workOrder={selectedWo}
        handleClosePanel={handleCloseWoPanel}
        viewMode
        reload={reload}
        setReload={setReload}
      />
      <Box classes={{ root: classes.headerContainer }}>
        <Grid container spacing={2}>
          {/* top header container */}
          <Grid container item spacing={3}>
            <Grid item xs={4}>
              {loading ? (
                <LoadingBox className={classes.loadingBox_1} />
              ) : (
                <Box>
                  <Box className={classes.companyInfo}>
                    {data?.subcontractor_company?.name}
                  </Box>
                  <Box className={classes.companySubInfo}>
                    {subcontractorAddress}
                    <br />
                    {data?.subcontractor_company?.phone}
                    <br />
                    {data?.subcontractor_company?.email}
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item xs={4}>
              {loading ? (
                <div>
                  <LoadingBox
                    className={classes.loadingBox_1}
                    height="20px"
                    margin="0px auto"
                  />
                  <div className={classes.loadingDiv} />
                  <LoadingBox width="70%" height="20px" margin="0px auto" />
                </div>
              ) : (
                <Box>
                  <Typography
                    classes={{
                      root: invoiceRedStatus.includes(currentStatus)
                        ? classes.declinedLabel
                        : invoiceGreenStatus.includes(currentStatus)
                        ? classes.approvedLabel
                        : currentStatus ===
                          t('invoices.in_states.draft').toLowerCase()
                        ? classes.draftLabel
                        : classes.statusLabel
                    }}
                  >
                    {scheduled || project
                      ? t('invoices.in_states.receipt')
                      : currentStatus ===
                        t('invoices.in_states.submitted').toLowerCase()
                      ? t('invoices.in_states.sent')
                      : typeof currentStatus === 'undefined'
                      ? ''
                      : t('invoices.in_states.' + currentStatus)}
                  </Typography>
                  <Typography
                    classes={{
                      root:
                        scheduled || project
                          ? currentStatus ===
                            t('invoices.in_states.declined').toLowerCase()
                            ? classes.scheduledDeclinedLabel
                            : currentStatus ===
                              t('invoices.in_states.approved').toLowerCase()
                            ? classes.scheduledLabel
                            : classes.scheduledNormalLabel
                          : classes.statusMessage
                    }}
                  >
                    {(scheduled || project) &&
                    currentStatus !==
                      t('invoices.in_states.declined').toLowerCase()
                      ? currentStatus !==
                        t('invoices.in_states.approved').toLowerCase()
                        ? t('invoices.action_messages.pending_approval')
                        : t('invoices.in_states.approved')
                      : currentStatus ===
                        t('invoices.in_states.declined').toLowerCase()
                      ? t('invoices.declined_message')
                      : currentStatus ===
                        t('invoices.in_states.approved').toLowerCase()
                      ? ''
                      : currentStatus ===
                        t('invoices.in_states.submitted').toLowerCase()
                      ? t('invoices.submitted_message')
                      : currentStatus ===
                        t('invoices.in_states.paid').toLowerCase()
                      ? paidMessage()
                      : t('invoices.fill_message')}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={4}>
              {loading ? (
                <LoadingBox className={classes.loadingBox_1} />
              ) : (
                <Box>
                  <Box classes={{ root: classes.invoiceNumberContainer }}>
                    {fileAvailable ? (
                      <IconButton size="small">
                        <FontAwesomeIcon icon="fa-regular fa-arrow-down-to-bracket" />
                      </IconButton>
                    ) : (
                      ' '
                    )}
                    {t('invoices.invoice_number')} {data?.invoice_number}
                  </Box>
                  <Box classes={{ root: classes.wo_container }}>
                    {scheduled ? (
                      <Box>
                        <Link
                          color="inherit"
                          underline="always"
                          onClick={handleClick}
                        >
                          {t('invoices.wo_multiple')}
                        </Link>
                        <Menu
                          id="workorder-menu"
                          open={open}
                          onClose={handleWoPanelEvent}
                          anchorEl={anchorEl}
                          style={{ marginTop: '2em' }}
                          classes={{ list: classes.wo_menu }}
                        >
                          {multipleWoNumbers.map((wo, index, array) => (
                            <div key={wo.id}>
                              <MenuItem
                                onClick={() => handleWoPanelEvent(wo)}
                                className={classes.wo_item}
                              >
                                {t('invoices.wo_number') + ' ' + wo.customer_po}
                              </MenuItem>
                              <Divider />
                            </div>
                          ))}
                        </Menu>
                      </Box>
                    ) : (
                      <Link
                        color="inherit"
                        underline="always"
                        onClick={event => {
                          event.stopPropagation()
                          ReactGA.event({
                            category: 'show',
                            action: 'show_work_order_detail'
                          })
                          setSelectedWo({
                            ...data?.work_order,
                            status: 'completed'
                          })
                        }}
                      >
                        {t('invoices.wo_number')}{' '}
                        {data?.work_order?.customer_po}
                      </Link>
                    )}
                  </Box>
                  <Box classes={{ root: classes.wo_completed }}>
                    {!data?.scheduled && t('invoices.wo_completed')}{' '}
                    {!data?.scheduled && data?.completed
                      ? moment(new Date(data.completed * 1000)).format(
                          t('general.format.short_date')
                        )
                      : ''}
                  </Box>
                  <Box className={classes.internal_number}>
                    {!data?.scheduled && t('invoices.invoice_number')}
                    {!data?.scheduled && (
                      <Input
                        classes={{ root: classes.invoiceNumber }}
                        placeholder={t('invoices.invoice_placeholder')}
                        disableUnderline
                        value={internalInvoiceNumber}
                        disabled={
                          currentStatus ===
                            t('invoices.in_states.approved').toLowerCase() ||
                          currentStatus ===
                            t('invoices.in_states.submitted').toLowerCase() ||
                          currentStatus ===
                            t('invoices.in_states.paid').toLowerCase() ||
                          currentStatus ===
                            t('invoices.in_states.hold').toLowerCase() ||
                          currentStatus ===
                            t('invoices.in_states.void').toLowerCase() ||
                          scheduled
                        }
                        onKeyDown={event => {
                          if (
                            !alphaNumericDashRegex.test(event.key) &&
                            event.key !== 'Backspace'
                          ) {
                            event.preventDefault()
                          }
                        }}
                        onChange={event => handleChange(event)}
                        inputProps={{
                          maxLength: 30
                        }}
                        onBlur={event => saveInternalNumber(event)}
                      />
                    )}
                  </Box>
                  <input
                    ref={fileInput}
                    name="file"
                    type="file"
                    multiple
                    onChange={onFileChange}
                    className={classes.attachInput}
                  />
                  {attachments.length === 0 &&
                  (currentStatus ===
                    t('invoices.in_states.open').toLowerCase() ||
                    currentStatus ===
                      t('invoices.in_states.draft').toLowerCase() ||
                    currentStatus ===
                      t('invoices.in_states.declined').toLowerCase()) ? (
                    loadingFiles ? (
                      <Box className={classes.loadingBox}>
                        <CircularProgress
                          className={classes.loadingFiles}
                          size={16}
                        />
                      </Box>
                    ) : (
                      <Box
                        onClick={handleClickAttachment}
                        className={classes.attachmentBox}
                      >
                        <button className={classes.attachButton}>
                          <img
                            src={attachIcon}
                            className={classes.attachIcon}
                          />
                        </button>
                        <Typography className={classes.attachTypo}>
                          {t('invoices.attach')}
                        </Typography>
                      </Box>
                    )
                  ) : (
                    <Box className={classes.listAttahcments}>
                      {attachments.length > 0 &&
                        attachments.map((validFile, index) => (
                          <Box
                            className={classes.boxAttahcment}
                            marginLeft={index === 0 ? 'auto' : '2px'}
                            key={index}
                          >
                            {(currentStatus ===
                              t('invoices.in_states.open').toLowerCase() ||
                              currentStatus ===
                                t('invoices.in_states.draft').toLowerCase() ||
                              currentStatus ===
                                t(
                                  'invoices.in_states.declined'
                                ).toLowerCase()) &&
                              !hideDeleteAttachment && (
                                <button
                                  onClick={() => deleteValidFile(validFile)}
                                  className={classes.deleteIcon}
                                >
                                  <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                                </button>
                              )}
                            <Typography
                              onClick={() =>
                                downloadURI(validFile.content, validFile.title)
                              }
                              className={classes.attachTypoOld}
                            >
                              {getTruncatedText(
                                validFile ? validFile.title : ''
                              )}
                            </Typography>
                          </Box>
                        ))}
                      {attachments.length < 3 &&
                        !loadingFiles &&
                        (currentStatus ===
                          t('invoices.in_states.open').toLowerCase() ||
                          currentStatus ===
                            t('invoices.in_states.draft').toLowerCase() ||
                          currentStatus ===
                            t('invoices.in_states.declined').toLowerCase()) && (
                          <Box
                            onClick={handleClickAttachment}
                            className={classes.attachmentBox}
                          >
                            <button className={classes.attachButton}>
                              <FontAwesomeIcon icon="fa-regular fa-circle-plus" />
                            </button>
                            <Typography className={classes.attachTypo}>
                              {t('invoices.add')}
                            </Typography>
                          </Box>
                        )}
                      {loadingFiles && (
                        <Box className={classes.loadingBox}>
                          <CircularProgress
                            className={classes.loadingFiles}
                            size={16}
                          />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>
          {/* middle header container */}
          <Grid container item spacing={3}>
            <Grid item xs={3}>
              {loading ? (
                <LoadingBox classname={classes.loadingbox_1} />
              ) : (
                <Box>
                  <Typography classes={{ root: classes.billInfo }}>
                    <Box>
                      {t('invoices.bill_to')}:
                      <br />
                      {data?.management_company.name}
                      <br />
                      {managementAddress}
                      <br />
                      {data?.management_company.phone}
                    </Box>
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid className={classes.noPadding} item xs={6}>
              <Box className={project ? classes.nteHide : classes.nteShow}>
                <Typography
                  classes={{
                    root: classes.nteLabel
                  }}
                >
                  {typeof data?.work_order?.nte !== 'undefined' &&
                  data?.work_order?.nte !== null
                    ? t('invoices.nte') + data.work_order?.nte?.toFixed(2)
                    : ''}
                </Typography>
                <Box classes={{ root: classes.nteMessage }}>
                  {' '}
                  {/* {nteMessageEnabled ? t('invoices.nte_message') : ''}{' '} */}
                  {nteMessageEnabled
                    ? canExceedNTEConfig
                      ? t('invoices.nte_message_2')
                      : t('invoices.nte_message')
                    : ''}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3}>
              {loading ? (
                <LoadingBox classname={classes.loadingbox_1} />
              ) : (
                <Box className={classes.flexBox_end}>
                  <LoadingButton
                    onClick={event => handleSubmit(event, data)}
                    classes={{ root: classes.createButton }}
                    loading={isLoading}
                    variant="outlined"
                    disabled={
                      scheduled || !submitEnabled || disableSubmitButton
                    }
                  >
                    {!isLoading && t('invoices.in_actions.submit')}
                  </LoadingButton>
                </Box>
              )}
            </Grid>
          </Grid>
          {/* bottom header container */}
          <Box className={classes.bottomContainer}>
            <Box className={classes.flexRow}>
              {loading ? (
                <LoadingBox className={classes.loadingBox_2} />
              ) : (
                <Box>
                  <InputLabel
                    shrink
                    htmlFor="client-input"
                    className={classes.bootstrapFormLabel}
                  >
                    {t('invoices.column_names.client_name')}
                  </InputLabel>
                  <Box classes={{ root: classes.clientInfo }}>
                    {data?.scheduled
                      ? data?.work_order_list?.client_name
                      : data?.work_order?.client_name}
                  </Box>
                </Box>
              )}
              {loading ? (
                <LoadingBox className={classes.loadingBox_2} />
              ) : (
                <Box>
                  <InputLabel
                    shrink
                    htmlFor="location-input"
                    className={classes.bootstrapFormLabel}
                  >
                    {t('invoices.column_names.client_location')}
                  </InputLabel>
                  <Box
                    classes={{
                      root: `${classes.clientInfoLocationWidth} ${classes.clientInfo}`
                    }}
                  >
                    {data?.scheduled
                      ? data?.work_order_list?.location_address
                      : data?.work_order?.address}
                  </Box>
                </Box>
              )}
              {loading ? (
                <LoadingBox className={classes.loadingBox_2} />
              ) : (
                <Box>
                  <InputLabel
                    shrink
                    htmlFor="date-input"
                    className={
                      userData?.parent_subdomain !== mcssfr &&
                      userData.parent_subdomain !== mcs360
                        ? classes.bootstrapFormLabel
                        : classes.hide
                    }
                  >
                    {t('invoices.column_names.dueDate')}
                  </InputLabel>
                  <Box
                    classes={{
                      root:
                        userData?.parent_subdomain !== mcssfr &&
                        userData.parent_subdomain !== mcs360
                          ? classes.clientInfo
                          : classes.hide
                    }}
                  >
                    {data?.due_date
                      ? moment(new Date(data.due_date * 1000)).format(
                          t('general.format.short_date')
                        )
                      : moment(new Date(data?.date_created * 1000))
                          .add(30, 'days')
                          .format(t('general.format.short_date'))}
                  </Box>
                </Box>
              )}
              {loading ? (
                <LoadingBox className={classes.loadingBox_2} />
              ) : (
                <Box>
                  <InputLabel
                    shrink
                    htmlFor="terms-input"
                    className={
                      userData?.parent_subdomain !== mcssfr &&
                      userData.parent_subdomain !== mcs360
                        ? classes.bootstrapFormLabel
                        : classes.hide
                    }
                  >
                    {t('invoices.column_names.terms')}
                  </InputLabel>
                  <Box
                    classes={{
                      root:
                        userData?.parent_subdomain !== mcssfr &&
                        userData.parent_subdomain !== mcs360
                          ? classes.clientInfo
                          : classes.hide
                    }}
                  >
                    {data?.term}
                  </Box>
                </Box>
              )}
            </Box>
            <Box marginLeft="auto">
              {loading ? (
                <LoadingBox className={classes.loadingBox_2} />
              ) : (
                <Box className={classes.flexBox_end}>
                  <div>
                    <InputLabel
                      shrink
                      htmlFor="total-input"
                      className={classes.bootstrapFormLabel}
                    >
                      {userData &&
                      userData.parent_subdomain !== mcssfr &&
                      userData.parent_subdomain !== mcs360
                        ? t('invoices.column_names.total_amount')
                        : t('invoices.column_names.balance_due')}
                    </InputLabel>
                    <NumberFormat
                      id="total-input"
                      thousandSeparator
                      customInput={InputBase}
                      disabled
                      prefix="$"
                      decimalSeparator="."
                      decimalScale={2}
                      fixedDecimalScale={2}
                      value={
                        scheduled
                          ? data?.total
                          : project
                          ? balanceDue
                          : totalAmount
                      }
                      classes={{
                        root: classes.totalContainer,
                        input: classes.clientInfoAmount
                      }}
                    />
                  </div>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>
        <ConfirmDialog
          open={confirmDialogOpen}
          style={{ height: '500px', width: '800px', overflowY: 'hidden' }}
          title={t('invoices.service_notes_title')}
          cancellabel={t('general.labels.cancel')}
          acceptlabel={t('general.labels.done')}
          handleCancel={() => setConfirmDialogOpen(false)}
          handleAccept={handleConfirmAccept}
          dialogNotes={dialogNotes}
          handleDialogNotesChange={handleDialogNotesChange}
          showInputBase={true}
        />
        <ConfirmDialog
          open={nteExceedConfirmDialogOpen}
          title={t('invoices.nte_exceed_title')}
          cancellabel={t('general.labels.cancel')}
          acceptlabel={t('general.labels.submit')}
          handleCancel={() => setNteExceedConfirmDialogOpen(false)}
          handleAccept={handleNteExceedConfirmAccept}
          message={t('invoices.nte_exceed_message')}
        />
      </Box>
    </div>
  )
}

export const InvoiceHeaderComponent = props => {
  const {
    data,
    totalAmount,
    allItems,
    setData,
    autoSave,
    currentStatus,
    loading,
    scheduled,
    multipleWoNumbers,
    isValidItemSelected,
    balanceDue,
    project,
    disableSubmitButton,
    lienMandatory,
    notes,
    attachments,
    setAttachments,
    setDisableButtons,
    setNotes
  } = props
  return (
    <InvoiceHeader
      data={data}
      totalAmount={totalAmount}
      allItems={allItems}
      setData={setData}
      autoSave={autoSave}
      currentStatus={currentStatus}
      loading={loading}
      scheduled={scheduled}
      multipleWoNumbers={multipleWoNumbers}
      isValidItemSelected={isValidItemSelected}
      balanceDue={balanceDue}
      project={project}
      disableSubmitButton={disableSubmitButton}
      lienMandatory={lienMandatory}
      notes={notes}
      attachments={attachments}
      setAttachments={setAttachments}
      setDisableButtons={setDisableButtons}
      setNotes={setNotes}
    />
  )
}
