import React from 'react'
import { motion } from 'framer-motion/dist/framer-motion'

export const MountTransition = ({ children, slide = 0 }) => (
  <motion.div
    exit={{ opacity: 0, x: slide }}
    initial={{ opacity: 0, x: slide }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ bounce: 0 }}
  >
    {children}
  </motion.div>
)
