import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect
} from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

// main components
import { InputFieldFilter } from './InputFieldFilter'
import { DateFilter } from './DateFilter'
import { DetailedInfo } from './DetailedInfo'
import { SlideFilter } from './SlideFilter'
import { SortMenu } from './SortMenu'

// mui components
import {
  makeStyles,
  Checkbox,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
  LinearProgress,
  Typography,
  ThemeProvider,
  InputBase
} from '@material-ui/core'
import { FiberManualRecord as PointIcon } from '@material-ui/icons'
import { FadingRow } from '../FadingRow'

// service
import {
  inStatusOptions,
  woStatusOptions,
  workorderDefaultColumns,
  woStatus
} from '../../lib/Constants'
import { getSitesAdvancedFiltersInfo } from '../../services/ApiService'

import ReactGA from 'react-ga4'
import { checkboxTheme } from '../../styles/mui_custom_theme'
import {
  getMainTableFilters,
  getWOstatus,
  isRunningInIframe
} from '../../lib/Global'
import { useSelector, useDispatch } from 'react-redux'
import { websocketActions } from '../../store/websocket'
import NumberFormat from 'react-number-format'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    overflowY: 'hidden',
    overflowX: 'auto',
    padding: '0px',
    margin: '0px'
  },
  bodyTableContainer: {
    minWidth: '1550px',
    padding: '0px',
    margin: '0px',
    overflowX: 'hidden',
    overflowY: 'overlay'
  },
  headTable: {
    height: '61px',
    minWidth: '1550px',
    tableLayout: 'fixed',
    lineHeight: 'none'
  },
  bodyTable: {
    tableLayout: 'fixed'
  },
  label: {
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    paddingTop: '7px',
    paddingRight: '7px'
  },
  labelCounter: {
    height: '18px',
    fontSize: '12px',
    fontWeight: '700',
    textAlign: 'center',
    paddingTop: '7px',
    paddingRight: '7px'
  },
  selected: {
    backgroundColor: `${theme.colors.workOrders.selectedRow} !important`,
    '&:hover': {
      backgroundColor: theme.colors.workOrders.selectedRow
    }
  },
  avatar: {
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    marginRight: '10px'
  },
  columnTitle: {
    fontSize: '12px',
    fontWeight: '500',
    color: theme.colors.workOrders.columnTitle,
    paddingLeft: '13px',
    cursor: 'default',
    height: '36px'
  },
  tableCell: {
    fontSize: '12px',
    fontWeight: '400',
    paddingLeft: '13px',
    paddingTop: '6px',
    color: theme.colors.workOrders.columnTitle,
    overflowWrap: 'break-word'
  },
  tableRow: {
    height: '68px'
  },
  headCell: {
    margin: '0px',
    paddingBottom: '5px',
    paddingTop: '0px',
    paddingRight: '10px',
    paddingLeft: '0px',
    color: theme.colors.workOrders.columnTitle
  },
  lastHeadCell: {
    margin: '0px',
    padding: '0px',
    paddingRight: '12px',
    paddingBottom: '5px',
    color: theme.colors.workOrders.columnTitle
  },
  empty1: {
    color: theme.colors.disabledButtonColor,
    fontSize: '30px',
    fontWeight: '600',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '60px',
    marginBottom: '40px'
  },
  empty2: {
    color: theme.colors.workOrders.tab.duedate,
    fontSize: '16px',
    fontWeight: '400',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'center'
  },
  boxTitle: {
    paddingTop: '2px',
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.workOrders.columnTitle,
    overflowWrap: 'break-word'
  },
  boxSub: {
    fontSize: '10px',
    fontWeight: '400',
    color: theme.colors.workOrders.downloadIcon,
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden',
    marginTop: '-5px',
    paddingTop: '0.4em',
    lineHeight: '11px'
  },
  services: {
    fontSize: '12px',
    fontWeight: '400',
    color: theme.colors.workOrders.columnTitle,
    lineClamp: 2,
    display: '-webkit-box',
    boxOrient: 'vertical',
    overflow: 'hidden'
  },
  colorPrimary: {
    background: theme.colors.workOrders.buttonPrimary
  },
  barColorPrimary: {
    background: `linear-gradient(to right, ${theme.colors.workOrders.buttonPrimary},${theme.colors.workOrders.gradient},${theme.colors.workOrders.buttonPrimary})`
  },
  dimensions: {
    height: '21px',
    width: '100px'
  },
  body: {
    maxHeight: '680px',
    backgroundColor: 'white',
    overflowY: 'scroll'
  },
  open: {
    width: '13px',
    height: '13px',
    color: theme.colors.workOrderColors.open,
    marginBottom: '-2px',
    marginRight: '8px'
  },
  in_progress: {
    width: '13px',
    height: '13px',
    color: theme.colors.workOrderColors.active,
    marginBottom: '-2px',
    marginRight: '8px'
  },
  completed: {
    width: '13px',
    height: '13px',
    marginBottom: '-2px',
    marginRight: '8px'
  },
  completed_warning: {
    width: '13px',
    height: '13px',
    color: theme.colors.yellow,
    marginBottom: '-2px',
    marginRight: '8px'
  },
  cancelled: {
    width: '13px',
    height: '13px',
    color: theme.colors.grey,
    marginBottom: '-2px',
    marginRight: '8px'
  },
  expired: {
    width: '13px',
    height: '13px',
    color: theme.colors.workOrderColors.declined,
    marginBottom: '-2px',
    marginRight: '8px'
  },
  invoice_open: {
    height: '13px',
    marginBottom: '-2px',
    color: theme.colors.workOrderColors.open,
    marginRight: '8px'
  },
  draft: {
    height: '13px',
    marginBottom: '-2px',
    color: theme.colors.workOrderColors.draft,
    marginRight: '8px'
  },
  submitted: {
    height: '13px',
    marginBottom: '-2px',
    color: theme.colors.workOrderColors.submitted,
    marginRight: '8px'
  },
  declined: {
    height: '13px',
    marginBottom: '-2px',
    color: theme.colors.workOrderColors.declined,
    marginRight: '8px'
  },
  approved: {
    height: '13px',
    marginBottom: '-2px',
    color: theme.colors.workOrderColors.approved,
    marginRight: '8px'
  },
  not_available: {
    height: '13px',
    marginBottom: '-2px',
    color: theme.colors.workOrderColors.not_available,
    marginRight: '8px'
  },
  noIcon: {
    width: '13px',
    height: '13px',
    color: theme.colors.text,
    marginBottom: '-2px',
    marginRight: '8px'
  },
  checkboxTablecell: {
    width: '48px'
  },
  emptyTableCell: {
    borderBottom: 'none'
  },
  masqueradeRow: {
    marginTop: '50px'
  },
  woMenu: {
    backgroundColor: theme.colors.disabledField,
    borderRadius: '45px',
    height: '24px',
    width: '24px',
    textAlign: 'center',
    fontSize: '20px'
  },
  ellipsis: {
    color: theme.colors.workOrders.downloadIcon
  },
  tableHead: {
    backgroundColor: theme.colors.filters.leftColumnBackground
  },
  tableRowInserted: {
    height: '68px',
    backgroundColor: theme.colors.insertedRow
  },
  inlineStyle: {
    display: 'inline'
  }
}))

function titleCase(str) {
  const splitStr = str.replace('_', ' ').toLowerCase().split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    filtersCatalog,
    filters,
    setFilters,
    columns,
    pageConfiguration
  } = props

  const classes = useStyles()
  const { t } = useTranslation()

  const tradesOptions =
    filtersCatalog.trades &&
    filtersCatalog.trades.map(item => {
      return { label: titleCase(item.name), value: item.name }
    })
  const servicesOptions =
    filtersCatalog.services &&
    filtersCatalog.services.map(item => {
      return { label: titleCase(item.name), value: item.name }
    })

  const woStatusOptions = filtersCatalog.workOrdersStatus.map(item => {
    return {
      label: titleCase(t(`work_orders.wo_states.${item}`)),
      value: item
    }
  })

  const canWOsExpire = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.workorders?.can_wos_expire
  )

  const inStatusOptions =
    filtersCatalog.invoices &&
    filtersCatalog.invoices.map(item => {
      return {
        label: titleCase(t(`invoices.in_states.${item}`)),
        value: item
      }
    })

  const priorityOptions =
    filtersCatalog.priorities &&
    filtersCatalog.priorities.map(item => {
      return { label: titleCase(item.name), value: item.name }
    })

  const filteredWoStatusOptions = canWOsExpire
    ? woStatusOptions.filter(status => status.value !== woStatus.EXPIRED)
    : woStatusOptions

  const defaultFilters = [
    {
      id: 'client_name',
      type: 'InputFieldFilter',
      default: true,
      checkMinCharacters: 4
    },
    {
      id: 'site_name',
      type: 'InputFieldFilter',
      default: true,
      checkMinCharacters: 4
    },
    { id: 'trade', type: 'SlideFilter', options: tradesOptions, default: true },
    {
      id: 'service',
      type: 'SlideFilter',
      options: servicesOptions,
      default: true
    },
    {
      id: 'won',
      type: 'InputFieldFilter',
      default: true,
      checkMinCharacters: 2
    },
    { id: 'opendate', type: 'DateFilter', default: true },
    { id: 'duedate', type: 'DateFilter', default: true },
    {
      id: 'wostat',
      type: 'SlideFilter',
      options: filteredWoStatusOptions,
      default: true
    },
    {
      id: 'invoices',
      type: 'SlideFilter',
      options: inStatusOptions,
      default: true
    },
    {
      id: 'priority',
      type: 'SlideFilter',
      options: priorityOptions,
      default: false
    },
    {
      id: 'gc_scope',
      type: 'InputFieldFilter',
      default: false
    },
    {
      id: 'nte',
      type: 'InputFieldFilter',
      default: false
    },
    {
      id: 'client_tracking_number',
      type: 'InputFieldFilter',
      default: false
    },
    {
      id: 'general_notes',
      type: 'InputFieldFilter',
      default: false,
      checkMinCharacters: 4
    }
  ]

  const filterComponents = getMainTableFilters(
    defaultFilters,
    pageConfiguration?.columns
  )

  let count = 0
  const checked = rowCount > 0 && numSelected === rowCount
  if (checked) {
    count = props.chips.totalCount
  } else {
    count = numSelected
  }

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell className={classes.checkboxTablecell} padding="checkbox">
          <ThemeProvider theme={checkboxTheme}>
            <div className={classes.labelCounter}>
              {count !== 0 ? numberWithCommas(count) : ''}
            </div>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </ThemeProvider>
        </TableCell>
        {columns &&
          columns
            ?.filter(column => column.visible)
            .map((column, index, columns) => {
              const headCell = filterComponents.find(
                filter => filter.id === column.field
              )
              if (!headCell) {
                return null
              }
              return (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  classes={
                    index + 1 !== columns.length
                      ? { root: classes.headCell }
                      : { root: classes.lastHeadCell }
                  }
                >
                  <TableSortLabel
                    hideSortIcon
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    classes={{ root: classes.columnTitle }}
                  >
                    {headCell.type !== 'Button'
                      ? t('work_orders.column_names.' + headCell.id)
                      : ''}
                    {(headCell.id === 'opendate' ||
                      headCell.id === 'duedate' ||
                      headCell.id === 'wostat' ||
                      headCell.id === 'invoices' ||
                      headCell.id === 'won' ||
                      headCell.id === 'gc_scope' ||
                      headCell.id === 'nte' ||
                      headCell.id === 'client_name' ||
                      headCell.id === 'priority' ||
                      headCell.id === 'client_tracking_number') && (
                      <SortMenu
                        id={headCell.id}
                        setValues={setFilters}
                        values={filters}
                      />
                    )}
                  </TableSortLabel>
                  <div>
                    {headCell.type === 'Button' ? (
                      <div id={headCell.id} />
                    ) : (
                      false
                    )}
                    {headCell.type === 'InputFieldFilter' ? (
                      <InputFieldFilter
                        id={headCell.id}
                        values={filters}
                        type={1}
                        setValues={setFilters}
                        placeholder={t('general.labels.search')}
                        height={headCell.height}
                        filterContent={() => null}
                        checkMinCharacters={headCell.checkMinCharacters ?? 1}
                      />
                    ) : (
                      false
                    )}
                    {headCell.type === 'DateFilter' ? (
                      <DateFilter
                        id={headCell.id}
                        values={filters}
                        type={1}
                        setValues={setFilters}
                        height={headCell.height}
                        filterContent={() => null}
                        range
                        testid={'date_filter'}
                      />
                    ) : (
                      false
                    )}
                    {headCell.type === 'SlideFilter' ? (
                      <SlideFilter
                        id={headCell.id}
                        values={props.filters}
                        type={1}
                        setValues={props.setFilters}
                        options={headCell.options ?? []}
                        height={headCell.height}
                        filterContent={() => null}
                        testid={'filter_open'}
                        hasAll={true}
                      />
                    ) : (
                      false
                    )}
                  </div>
                </TableCell>
              )
            })}

        {<TableCell padding="checkbox" size="small" />}
      </TableRow>
    </TableHead>
  )
}

function EnhancedTable(props) {
  const wHeight = getWindowHeight()
  const {
    content,
    selected,
    setSelected,
    loading,
    hasMore,
    setTablePage,
    filters,
    setFilters,
    validateFilters,
    setSearchEnabled,
    exportCSVByIds,
    setWorkOrders
  } = props
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [woNum, setWoNum] = useState(null)
  const [reload, setReload] = useState(false)
  const [filtersCatalog, setFiltersCatalog] = useState({
    states: [],
    workOrdersStatus: [],
    trades: [],
    callTypes: [],
    services: [],
    invoices: [],
    priorities: []
  })
  const [allSelected, setAllSelected] = useState(false)
  const websocketStore = useSelector(state => state.websocket)

  const pageConfiguration = useSelector(
    state =>
      state.auth?.user?.userInfo?.configurations?.portals?.subcontractor
        ?.workorders
  )
  const clientId = useSelector(
    state => state.auth?.user?.userInfo?.parent_subdomain
  )
  const user = useSelector(state => state.auth.user.userInfo)

  const dispatch = useDispatch()

  const columns = pageConfiguration?.columns
    ? pageConfiguration?.columns.map(col => ({ ...col, visible: true }))
    : workorderDefaultColumns

  const observer = useRef()
  const lastTableElement = useCallback(
    node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setTablePage(prevTablePage => prevTablePage + 1)
          setSearchEnabled(true)
          setTimeout(() => {
            setSearchEnabled(false)
          }, 500)
        }
      })
      if (node) observer.current.observe(node)
    },
    [loading, hasMore]
  )

  useEffect(() => {
    // Make sure the WO download call has updated the token before this call is made
    setTimeout(() => {
      loadFiltersCatalogs()
    }, 2000)
  }, [])

  useEffect(() => {
    if (!loading && selected.length > 0 && allSelected) {
      reselectAllItems()
    }
  }, [loading])

  useEffect(() => {
    console.info('websocket work orders', websocketStore.workOrders)
    if (websocketStore.workOrders && websocketStore.workOrders.length > 0) {
      const foundIds = []
      const socketRecords = []
      websocketStore.workOrders.forEach(workOrder => {
        const found = content.find(
          wo => wo.external_id === workOrder.external_id
        )
        if (found) {
          socketRecords.push({
            ...found,
            ...workOrder
          })
          foundIds.push(found.external_id)
        } else {
          socketRecords.push(workOrder)
        }
      })
      if (woNum) {
        const match = websocketStore.workOrders.find(
          w => w.external_id === woNum.external_id
        )
        // Check if the WO has been updated and reload side panel
        if (match) {
          setWoNum(match)
          setReload(true)
        }
      }
      setWorkOrders(records => [
        ...socketRecords,
        ...records.filter(work => !foundIds.includes(work.external_id))
      ])
      dispatch(websocketActions.setWorkOrders([]))
    }
  }, [websocketStore.workOrders])

  const loadFiltersCatalogs = async () => {
    try {
      const filters = await getSitesAdvancedFiltersInfo(
        isRunningInIframe() ? user?.subdomain?.child : clientId
      )
      setFiltersCatalog({
        workOrdersStatus: woStatusOptions,
        invoices: inStatusOptions,
        trades: filters.trades,
        services: filters.services,
        priorities: filters.priorities
      })
    } catch (err) {
      console.error(err)
      setFiltersCatalog({
        workOrdersStatus: woStatusOptions,
        invoices: inStatusOptions
      })
    }
  }

  const classes = useStyles()
  const { t } = useTranslation()

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = content.map(n => n.external_id)
      setSelected(newSelecteds)
      setAllSelected(true)
      return
    }
    setAllSelected(false)
    setSelected([])
  }

  const reselectAllItems = () => {
    setSelected([])
    const newSelecteds = content.map(n => n.external_id)
    setSelected(newSelecteds)
  }

  const handleClick = (event, name) => {
    event.stopPropagation()
    const selectedIndex = selected.indexOf(name)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      )
    }
    setSelected(newSelected)
  }

  const isSelected = name => selected.indexOf(name) !== -1

  const emptyComponent = () => (
    <TableBody key="empty-body">
      <TableRow>
        <TableCell className={classes.emptyTableCell} colSpan={10}>
          {!validateFilters() ? (
            <>
              <Box classes={{ root: classes.empty1 }}>
                {t('work_orders.empty_message_title')}
              </Box>
              <Box classes={{ root: classes.empty2 }}>
                {t('work_orders.empty_message_filters')}
              </Box>
            </>
          ) : (
            <>
              <Box classes={{ root: classes.empty1 }}>
                {t('work_orders.empty_message_title')}
              </Box>
              <Box classes={{ root: classes.empty2 }}>
                {t('work_orders.empty_message')}
              </Box>
            </>
          )}
        </TableCell>
      </TableRow>
    </TableBody>
  )

  const progressComponent = columns => {
    const auxArray = []
    for (let i = 1; i <= 4; i++) {
      auxArray.push(progressRow(columns, i))
    }
    return <TableBody>{auxArray.map((obj, ind) => obj)}</TableBody>
  }

  const handleClosePanel = () => {
    setWoNum(null)
  }

  const truncateText = (text, numWords) => {
    const words = text.split(' ')
    if (words.length > numWords) {
      return words.slice(0, numWords).join(' ') + '...'
    } else {
      return text
    }
  }

  const progressRow = (columns, i) => (
    <TableRow
      key={`progress-${i}`}
      id={`progress-${i}`}
      onClick={() => null}
      tabIndex={-1}
      style={{ height: '68px' }}
    >
      <TableCell key={'chk-' + i + '--1'} padding="checkbox">
        <ThemeProvider theme={checkboxTheme}>
          <Checkbox onClick={() => null} checked={false} />
        </ThemeProvider>
      </TableCell>
      {columns?.map((column, index) => {
        if (!column.visible) {
          return null
        }
        return column.type !== 'Button' ? (
          <TableCell
            key={column.field + '-' + i + '-' + index}
            align="left"
            style={{ paddingLeft: '8px', paddingRight: '14px' }}
            classes={{ root: classes.tableCell }}
          >
            <LinearProgress
              style={{ width: '100%' }}
              classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary,
                root: classes.dimensions
              }}
            />
          </TableCell>
        ) : (
          false
        )
      })}
      {<TableCell padding="checkbox" size="small" />}
    </TableRow>
  )

  const mappingColumns = (columns, row) => {
    return columns.map((column, index) => {
      if (!column.visible) return null
      switch (column.field) {
        case 'client_name':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.client_name ?? ''}
            </TableCell>
          )
        case 'site_name':
          return (
            <TableCell
              key={column.field + index}
              id={`enhanced-table-checkbox-${index}`}
              scope="row"
              classes={{ root: classes.tableCell }}
            >
              <Box classes={{ root: classes.boxTitle }}>
                {row.store_name ?? ''}
              </Box>
              <Typography classes={{ root: classes.boxSub }}>
                {row.address}
              </Typography>
            </TableCell>
          )
        case 'trade':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              <div className={classes.inlineStyle}>{row.category}</div>
            </TableCell>
          )
        case 'service':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              <Typography classes={{ root: classes.services }}>
                {row.services?.map(item => item.name).join(',')}
              </Typography>
            </TableCell>
          )
        case 'won':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.customer_po ?? row.external_id}
            </TableCell>
          )
        case 'gc_scope':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              <NumberFormat
                id="gc-input"
                thousandSeparator
                customInput={InputBase}
                prefix="$"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={2}
                value={row.gc_scope}
                displayType="text"
              />
            </TableCell>
          )
        case 'general_notes':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {truncateText(row.general_notes ?? '', 8)}
            </TableCell>
          )
        case 'nte':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              <NumberFormat
                id="nte-input"
                thousandSeparator
                customInput={InputBase}
                prefix="$"
                decimalSeparator="."
                decimalScale={2}
                fixedDecimalScale={2}
                value={row.nte}
                displayType="text"
              />
            </TableCell>
          )
        case 'opendate':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.open_date
                ? moment(new Date(row.open_date * 1000)).format(
                    t('general.format.short_date')
                  )
                : ''}
            </TableCell>
          )
        case 'duedate':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.open_date
                ? moment(new Date(row.expiration_date * 1000)).format(
                    t('general.format.short_date')
                  )
                : ''}
            </TableCell>
          )
        case 'wostat':
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row.status ? (
                <PointIcon className={classes[getWOstatus(row)]} />
              ) : (
                <PointIcon className={classes.noIcon} />
              )}
              <div className={classes.inlineStyle}>
                {row.status
                  ? t(`work_orders.wo_states.${getWOstatus(row)}`)
                  : t('work_orders.wo_states.no_status')}
              </div>
            </TableCell>
          )
        case 'invoices': {
          const invoice = row?.invoices?.filter(n => n)
          // row.invoice = invoice[0]
          return (
            <TableCell
              key={column.field + index}
              align="left"
              className={`${classes.tableCell} ${
                invoice?.[0] && invoice?.[0].status !== 'deleted'
                  ? classes[
                      invoice[0].status === 'open'
                        ? 'invoice_open'
                        : invoice[0].status
                    ]
                  : classes.not_available
              }`}
            >
              {invoice?.[0] && invoice?.[0].status !== 'deleted'
                ? t(`invoices.in_states.${invoice[0].status}`)
                : t('invoices.in_states.not_available')}
            </TableCell>
          )
        }
        default:
          return (
            <TableCell
              key={column.field + index}
              align="left"
              classes={{ root: classes.tableCell }}
            >
              {row[column.field] ?? ''}
            </TableCell>
          )
      }
    })
  }

  return (
    <div>
      <DetailedInfo
        workOrder={woNum}
        handleClosePanel={handleClosePanel}
        exportCSVByIds={exportCSVByIds}
        reload={reload}
        setReload={setReload}
      />
      <TableContainer classes={{ root: classes.tableContainer }}>
        <Table
          className={classes.headTable}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
          data-testid={'wo_main_table_head'}
        >
          <EnhancedTableHead
            chips={props.chips}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={content ? content?.length : 0}
            filtersCatalog={filtersCatalog}
            filters={filters}
            setFilters={setFilters}
            columns={columns}
            pageConfiguration={pageConfiguration}
          />
        </Table>
        <TableContainer
          style={{ height: wHeight - 208 + 'px' }}
          classes={{ root: classes.bodyTableContainer }}
        >
          <Table
            classes={{ root: classes.bodyTable }}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
            data-testid={'wo_main_table'}
          >
            {content && content.length > 0 ? (
              <TableBody key="wo-table-body" classes={{ root: classes.body }}>
                {content.map((row, index) => {
                  if (row.status === 'pending') return null
                  let tableReference = null
                  if (content.length === index + 1) {
                    tableReference = lastTableElement
                  }
                  return row.socket ? (
                    <FadingRow
                      ref={tableReference}
                      hover
                      onClick={event => {
                        event.stopPropagation()
                        ReactGA.event({
                          category: 'show',
                          action: 'show_work_order_detail'
                        })
                        setWoNum(row)
                      }}
                      role="checkbox"
                      aria-checked={isSelected(row.external_id)}
                      tabIndex={-1}
                      key={row.external_id}
                      selected={
                        isSelected(row.external_id) ||
                        woNum?.external_id === row.external_id
                      }
                      classes={{ selected: classes.selected }}
                      className={
                        row.socket ? classes.tableRowInserted : classes.tableRow
                      }
                    >
                      <TableCell padding="checkbox">
                        <ThemeProvider theme={checkboxTheme}>
                          <Checkbox
                            onClick={event =>
                              handleClick(event, row.external_id)
                            }
                            checked={isSelected(row.external_id)}
                            inputProps={{
                              'aria-labelledby': `enhanced-table-checkbox-${index}`
                            }}
                          />
                        </ThemeProvider>
                      </TableCell>
                      {mappingColumns(columns, row)}
                      {<TableCell padding="checkbox" size="small" />}
                    </FadingRow>
                  ) : (
                    <TableRow
                      ref={tableReference}
                      hover
                      onClick={event => {
                        event.stopPropagation()
                        ReactGA.event({
                          category: 'show',
                          action: 'show_work_order_detail'
                        })
                        setWoNum(row)
                      }}
                      role="checkbox"
                      aria-checked={isSelected(row.external_id)}
                      tabIndex={-1}
                      key={row.external_id}
                      selected={
                        isSelected(row.external_id) ||
                        woNum?.external_id === row.external_id
                      }
                      classes={{ selected: classes.selected }}
                      className={
                        row.socket ? classes.tableRowInserted : classes.tableRow
                      }
                    >
                      <TableCell padding="checkbox">
                        <ThemeProvider theme={checkboxTheme}>
                          <Checkbox
                            onClick={event =>
                              handleClick(event, row.external_id)
                            }
                            checked={isSelected(row.external_id)}
                            inputProps={{
                              'aria-labelledby': `enhanced-table-checkbox-${index}`
                            }}
                          />
                        </ThemeProvider>
                      </TableCell>
                      {mappingColumns(columns, row)}
                      {<TableCell padding="checkbox" size="small" />}
                    </TableRow>
                  )
                })}
                {content.length > 0 && loading && progressRow(columns, 1)}
              </TableBody>
            ) : loading ? (
              progressComponent(columns)
            ) : (
              emptyComponent()
            )}
          </Table>
        </TableContainer>
      </TableContainer>
    </div>
  )
}
export const MainTable = props => {
  const {
    content,
    openAdvanced,
    setOpenAdvanced,
    selected,
    setSelected,
    loading,
    hasMore,
    setTablePage,
    filters,
    setFilters,
    validateFilters,
    setSearchEnabled,
    exportCSVByIds,
    setWorkOrders
  } = props

  return (
    <div>
      <EnhancedTable
        chips={props.chips}
        content={content}
        openAdvanced={openAdvanced}
        setOpenAdvanced={setOpenAdvanced}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        hasMore={hasMore}
        setTablePage={setTablePage}
        filters={filters}
        setFilters={setFilters}
        validateFilters={validateFilters}
        setSearchEnabled={setSearchEnabled}
        exportCSVByIds={exportCSVByIds}
        setWorkOrders={setWorkOrders}
      />
    </div>
  )
}

function numberWithCommas(x) {
  if (!x) return '0'
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function getWindowHeight() {
  const [size, setSize] = useState([0, 0])
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight])
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size[1]
}
