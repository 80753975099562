/* eslint-disable no-undef */
import React from 'react'

/** Material UI **/
import { alpha, styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import { FormLabel, makeStyles, Typography } from '@material-ui/core'
import FormControl from '@mui/material/FormControl'
import customTheme from '../../styles/mui_theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 45,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? 'white' : '#2b2b2b',
    border: '1px solid ' + customTheme.colors.profile.border_input,
    fontSize: 12,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow'
    ]),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    },
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: customTheme.colors.profile.text_grey,
      opacity: '1'
    },
    '&:invalid': {
      borderColor: customTheme.colors.errorText
    }
  }
}))

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    height: '10px',
    textAlign: 'left',
    marginBottom: '10px'
  },
  label: {
    color: customTheme.colors.grey,
    fontSize: '12px',
    marginBottom: '0.5em',
    textAlign: 'left',
    marginLeft: '5px',
    marginRight: '5px',
    display: 'flex',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'normal'
    }
  },
  error: {
    marginTop: '0',
    fontSize: '10px',
    color: theme.colors.errorText,
    marginLeft: '15px',
    height: '0'
  },
  infoButton: {
    color: theme.colors.iconBlue,
    alignSelf: 'center'
  },
  required: {
    color: theme.colors.errorText
  },
  title: {
    flex: 1,
    fontSize: '12px',
    color: customTheme.colors.grey
  },
  controlContainer: {
    minWidth: 'auto !important'
  }
}))

export default function GlobalInput(props) {
  const classes = useStyles()
  const {
    label,
    onChange,
    validate,
    error,
    helperText,
    infoButtonCallback,
    checkRegex,
    ...rest
  } = props

  const handleChange = event => {
    let validExp = true
    if (checkRegex) {
      if (event.target.value === '' || checkRegex.test(event.target.value)) {
        validExp = true
      } else {
        validExp = false
      }
    }
    if (validExp) {
      if (validate) {
        onChange(event)
        return
      }
      onChange(event.target.value, props.field, props.index)
    }
  }

  return (
    <FormControl
      variant="standard"
      fullWidth={true}
      className={classes.controlContainer}
    >
      {label && (
        <Typography id="text-label" className={classes.label}>
          {props.required && <span className={classes.required}>*</span>}
          <Typography id="text-label" className={classes.title}>
            {label}
          </Typography>
          {infoButtonCallback && (
            <FontAwesomeIcon
              icon={faCircleInfo}
              className={classes.infoButton}
              onClick={infoButtonCallback}
            />
          )}
        </Typography>
      )}
      <BootstrapInput {...rest} onChange={handleChange} />
      <div className={classes.bottomSpacing}>
        {error && <FormLabel className={classes.error}>{helperText}</FormLabel>}
      </div>
    </FormControl>
  )
}
