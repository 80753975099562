import { makeStyles } from '@material-ui/core'

export const assetsStyles = makeStyles(theme => ({
  thumbnail: {
    width: '100%',
    paddingLeft: '10px',
    paddingTop: '2px',
    fontSize: '10px !important',
    fontWeight: '700'
  },
  thumbDiv: {
    marginTop: '0px',
    backgroundColor: theme.colors.backdropColor,
    marginRight: '-2px'
  },
  typoDiv: {
    display: 'flex'
  }
}))
