import React, { useEffect, useState } from 'react'
import { ATTACHMENT_MAX_FILE_SIZE } from '../lib/Constants'
import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { resizeImage } from '../lib/Global'

const useStyles = makeStyles(theme => ({
  errorMessage: {
    color: theme.colors.errorText,
    fontWeight: '400',
    fontSize: '12px',
    padding: '5px'
  },
  hide: {
    display: 'none'
  }
}))

const ACCEPT_TYPES = {
  all_document_formats:
    'image/jpeg, image/png, application/pdf, text/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpeg_and_png_only: 'image/jpeg, image/png',
  pdf_only: 'application/pdf',
  csv_spreadsheet_only: 'text/csv',
  docx_only:
    'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}

export const FilePicker = props => {
  const { callback, fileRef, question, title } = props
  const classes = useStyles()
  const [acceptType, setAcceptType] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    updateAcceptType()
  }, [title, question])

  const updateAcceptType = () => {
    const attachment = question?.attachments.find(att => att.title === title)
    if (attachment) {
      const newAcceptType = getAcceptAttribute(attachment.attributes)
      setAcceptType(newAcceptType)
      if (fileRef.current) {
        fileRef.current.accept = newAcceptType
      }
    }
  }

  const resetFileInput = inputRef => {
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const changeFileHandler = async event => {
    const file = event.target.files[0]

    // Check file size
    if (file.size > ATTACHMENT_MAX_FILE_SIZE) {
      setErrorMessage(t('general.attachment_picker.size_message'))
      resetFileInput(fileRef)
      return
    }

    setErrorMessage('')

    try {
      const attributes = {
        name: file.name,
        type: file.type,
        size: file.size,
        lastModified: file.lastModified,
        timestamp: new Date().getTime()
      }

      if (file.type.startsWith('image/')) {
        const { dataUrl, width, height } = await resizeImage(file)
        attributes.thumbnail = dataUrl
        attributes.width = width
        attributes.height = height
        callback(dataUrl, attributes)
      } else {
        const reader = new FileReader()
        reader.onload = readerEvent => {
          callback(readerEvent.target.result, attributes)
        }
        reader.onerror = error => console.error('Error: ', error)
        reader.readAsDataURL(file)
      }
    } catch (error) {
      console.error('Error processing file:', error)
    }

    // Reset the file input value after processing the file
    resetFileInput(fileRef)
  }

  const getAcceptAttribute = attribute => {
    return ACCEPT_TYPES[attribute] || ACCEPT_TYPES.all_document_formats
  }

  return (
    <>
      <input
        ref={fileRef}
        className={classes.hide}
        type="file"
        name="file"
        onChange={changeFileHandler}
        accept={acceptType}
      />
      {errorMessage && (
        <FormHelperText className={classes.errorMessage}>
          {errorMessage}
        </FormHelperText>
      )}
    </>
  )
}
