import React, { useEffect, useState } from 'react'

/** Material UI **/
import {
  FormControl,
  FormLabel,
  InputBase,
  makeStyles,
  MenuItem,
  Select,
  styled,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import customTheme from '../../styles/mui_theme'
import { useTranslation } from 'react-i18next'
import { isRunningInIframe } from '../../lib/Global'

const BootstrapInput = styled(InputBase)(({ theme, notSelectable }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInputBase-input': {
    borderRadius: 45,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    fontSize: 12,
    padding: '7px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 45,
      borderColor: customTheme.colors.profile.border_focus,
      boxShadow: notSelectable
        ? ''
        : '0 0 0 0.2rem ' + customTheme.colors.profile.box_shadow
    }
  },
  [theme.breakpoints.down('xs')]: {
    width: 'fit-content !important',
    border: '1px solid ' + theme.colors.profile.border_input,
    borderRadius: '45px'
  }
}))

const useStyles = makeStyles(theme => ({
  bottomSpacing: {
    height: '10px',
    textAlign: 'left',
    marginBottom: '10px'
  },
  text: {
    textAlign: 'initial',
    fontStyle: 'normal'
  },
  label: {
    color: customTheme.colors.grey,
    fontSize: '12px',
    marginBottom: '0.5em',
    textAlign: 'left',
    marginLeft: '5px'
  },
  itemContent: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  error: {
    fontSize: '10px',
    color: theme.colors.errorText,
    marginLeft: '15px'
  },
  iconMargin: {
    marginRight: '7px',
    top: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0'
    }
  },
  required: {
    color: theme.colors.errorText
  },
  highlightField: {
    border: '1px solid ' + theme.colors.errorText,
    borderRadius: '45px'
  },
  normalField: {
    border: '1px solid ' + theme.colors.profile.border_input,
    borderRadius: '45px'
  },
  menuItem: {
    fontSize: '12px'
  },
  menuItemReadOnly: {
    pointerEvents: 'none',
    fontSize: '12px'
  },
  blueCheck: {
    fontSize: '14px',
    color: customTheme.colors.checkBlue
  },
  highlightAnimation: {
    animationName: '$highlightAnimation',
    animationDuration: '2s',
    animationTimingFunction: 'linear',
    animationIterationCount: '4'
  },
  '@keyframes highlightAnimation': {
    '0%, 100%': {
      border: '1px solid ' + theme.colors.profile.border_input
    },
    '50%': {
      border: '1px solid ' + theme.colors.iconBlue
    }
  },
  smallScreenOverride: {
    [theme.breakpoints.down('xs')]: {
      width: '100% !important'
    }
  }
}))

export default function GlobalSelect(props) {
  const classes = useStyles()
  const {
    label,
    field,
    index,
    onChange,
    options,
    placeholder,
    multiple,
    displayvalue,
    error,
    helperText,
    validate,
    uniqValue,
    disabled,
    required,
    highlight,
    notSelectable,
    returnFullOption,
    smallScreenOverride
  } = props
  const [fieldError, setFieldError] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (!props.value && required) {
      setFieldError(true)
    }
  }, [])

  const handleChange = event => {
    const selectedOption = options.find(
      option => option.value === event.target.value
    )
    const returnValue = returnFullOption ? selectedOption : event.target.value
    if (event.target.value.length === 0 && required) {
      setFieldError(true)
    } else {
      setFieldError(false)
    }
    if (validate) {
      if (returnFullOption) {
        onChange(returnValue)
      } else {
        onChange(event)
      }
      return
    }
    onChange(returnValue, field, index)
  }
  const getLabel = value => {
    if (!value) return ''
    const itemSelected = options.find(item => item.value === value)
    return itemSelected?.label || ''
  }

  const showSelectedStatus = item => {
    const currentValue = props.value
    if (props.multiple && currentValue?.length) {
      return currentValue.includes(item)
    }
    return false
  }

  const autoFocus = () => {
    return !isRunningInIframe()
  }
  const disableAutoFocus = () => {
    return !!isRunningInIframe()
  }

  return (
    <FormControl variant="standard" fullWidth={true}>
      {label ? (
        <Typography id="text-label" className={classes.label}>
          {required && <span className={classes.required}>*</span>}
          {label}
        </Typography>
      ) : (
        ''
      )}
      <Select
        className={`${
          required && fieldError
            ? classes.highlightField
            : `${classes.normalField} ${
                highlight ? classes.highlightAnimation : ''
              }`
        } ${smallScreenOverride ? classes.smallScreenOverride : ''}`}
        {...props}
        onChange={handleChange}
        disabled={disabled}
        classes={{ icon: classes.iconMargin }}
        input={<BootstrapInput />}
        displayEmpty
        renderValue={selected => {
          if (!selected || selected?.length === 0) {
            return <em className={classes.text}>{placeholder}</em>
          }
          if (uniqValue) {
            return uniqValue
          }
          if (multiple) {
            return selected?.join(', ')
          } else {
            if (displayvalue) return getLabel(selected)
            return selected
          }
        }}
        MenuProps={{
          autoFocus: autoFocus(),
          disableAutoFocusItem: disableAutoFocus(),
          disableEnforceFocus: disableAutoFocus(),
          disableAutoFocus: disableAutoFocus(),
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {!!options?.length && options?.length > 0 ? (
          options.map((item, index) => (
            <MenuItem
              key={index}
              value={item.value}
              className={
                notSelectable ? classes.menuItemReadOnly : classes.menuItem
              }
            >
              <div className={classes.itemContent}>
                {item.label}
                {showSelectedStatus(item.value) && (
                  <FontAwesomeIcon
                    icon="fa-solid fa-check"
                    className={classes.blueCheck}
                  />
                )}
              </div>
            </MenuItem>
          ))
        ) : (
          <MenuItem className={classes.menuItem}>
            <div className={classes.itemContent}>
              {t('general.labels.no_items')}
            </div>
          </MenuItem>
        )}
      </Select>

      <div className={classes.bottomSpacing}>
        {error && <FormLabel className={classes.error}>{helperText}</FormLabel>}
      </div>
    </FormControl>
  )
}
