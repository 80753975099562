import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { validateAccessCode } from '../../lib/Api'

/** Material UI **/
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Typography
} from '@material-ui/core'
import {
  BasicButton,
  CustomTextField,
  HighlightButton
} from '../../styles/mui_custom_components'

/** FontAwesome **/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/** Services **/
import { createUser } from '../../services/ApiService'
import { apiPasswordMessage } from '../../lib/Constants'
import { validateEmail } from '../../lib/Global'

const useStyles = makeStyles(theme => ({
  mainGrid: {
    overflow: 'auto',
    minHeight: '667px',
    width: '736px',
    marginTop: '-80px',
    [theme.breakpoints.down('sm')]: {
      minHeight: '557px',
      width: 'auto'
    }
  },
  mainItem: {
    transform: 'scale(0.80)',
    width: '80%'
  },
  headerText: {
    textAlign: 'center'
  },
  next: {
    width: 50,
    textTransform: 'unset !important'
  },
  title: {
    fontWeight: '700',
    fontSize: '30px'
  },
  title2: {
    fontWeight: '700',
    fontSize: '30px',
    paddingBottom: '20px'
  },
  subtitleText: {
    fontSize: '20px',
    paddingTop: '0.5em',
    opacity: '90%',
    fontWeight: '300',
    color: theme.colors.black
  },
  signIn: {
    fontSize: '18px',
    fontWeight: '300',
    color: theme.colors.black,
    paddingLeft: '15px'
  },
  domainField: {
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '80px'
    }
  },
  nextButton: {
    height: '61px',
    borderRadius: '45px',
    width: '5em',
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    backgroundColor: `${theme.colors.iconBlue} !important`,
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${theme.colors.textGray} !important`
    },
    '&.MuiButtonBase-root.Mui-disabled > .MuiButton-label': {
      color: `${theme.colors.white} !important`
    }
  },
  textFieldStyle: {
    maxHeight: '62px',
    '&.Mui-focused .MuiSelect-root': {
      backgroundColor: 'white'
    }
  },
  errorMessage: {
    color: theme.colors.errorText,
    fontWeight: '300',
    fontSize: '14px',
    paddingLeft: '20px'
  },
  downArrowIcon: {
    right: '20px',
    top: 'calc(50% - 12px)',
    color: theme.colors.iconBlue,
    marginRight: '10px',
    pointerEvents: 'none',
    position: 'absolute'
  },
  eyeIcon: {
    color: theme.colors.iconBlue,
    cursor: 'pointer'
  },
  titleMenu: {
    '&.MuiMenu-paper': {
      minWidth: '300px !important',
      borderRadius: '12px'
    }
  },
  menuItem: {
    fontSize: '18px',
    fontWeight: '400',
    maxHeight: '50px',
    '&.Mui-selected': {
      backgroundColor: 'white'
    }
  },
  menuItemPlaceholder: { color: theme.colors.placeholder, fontSize: '20px' },
  checkCircle: {
    color: theme.colors.checkDomain,
    fontSize: '1.2em'
  },
  errorColor: {
    '& .MuiInputBase-input': {
      color: `${theme.colors.error} !important`
    }
  },
  selectContainer: {
    display: 'flex',
    flex: 1,
    maxHeight: '50px',
    justifyContent: 'flex-end'
  },
  select: {
    color: theme.colors.iconBlue
  },
  responsiveCenter: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  buttons: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      margin: 'auto'
    }
  },
  nextBtn: {
    width: '139px',
    backgroundColor: `${theme.colors.iconBlue} !important`,
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: `${theme.colors.textGray} !important`
    },
    '&.MuiButtonBase-root.Mui-disabled > .MuiButton-label': {
      color: `${theme.colors.white} !important`
    }
  }
}))

const CreateAccount = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()
  const [accessCode, setAccessCode] = useState('')
  const [company, setCompany] = useState('')
  const [accessCodeError, setAccessCodeError] = useState('')
  const [accessCodeValid, setAccessCodeValid] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [title, setTitle] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passVisible, setPassVisible] = useState(false)
  const [email, setEmail] = useState('')
  const [disableButton, setDisableButton] = useState(true)
  const [creationError, setCreationError] = useState('')
  const [emailError, setEmailError] = useState('')
  const [usernameError, setUsernameError] = useState('')
  const [open, setOpen] = useState(true)
  const [firstNameErr, setFirstNameErr] = useState('')
  const [lastNameErr, setLastNameErr] = useState('')
  const [titleErr, setTitleErr] = useState('')
  const [emailErr, setEmailErr] = useState('')
  const [usernameErr, setUsernameErr] = useState('')
  const [passwordErr, setPasswordErr] = useState('')

  const handleValidateClick = async () => {
    const response = await validateAccessCode(accessCode)
    if (response && response !== 'FALSE') {
      setCompany(response)
      setAccessCodeValid(true)
    } else {
      setAccessCodeError(
        t('create_account.messages.errors.invalid_domain_structure')
      )
    }
  }

  const onSubmit = async data => {
    try {
      await createUser(
        accessCode,
        firstName,
        lastName,
        email,
        title,
        username,
        password
      )
    } catch (error) {
      switch (error.type) {
        case 'username':
          setUsernameError(error.message)
          break
        case 'email':
          setEmailError(error.message)
          break
      }
      if (error.message === apiPasswordMessage) {
        setCreationError(t('general.messages.errors.length_6'))
      } else {
        setCreationError(error.message)
      }
    }
  }

  const handleAccessCodeChange = event => {
    setAccessCodeError('')
    setAccessCodeValid(false)
    if (event.key === 'Enter') {
      handleValidateClick()
    }
    setAccessCode(event.target.value)
  }
  const handlePaste = event => {
    setAccessCode(event.clipboardData.getData('text'))
  }

  const backClickHandler = event => {
    event.preventDefault()
    history.push('/sign-in')
  }

  useEffect(() => {
    setCreationError('')
    setUsernameError('')
    setEmailError('')
    if (firstName && lastName && email && title && username && password) {
      setDisableButton(!validateEmail(email))
    } else {
      setDisableButton(true)
    }
  }, [firstName, lastName, email, title, username, password])

  const firstNameOnblur = event => {
    if (event.target.value === '') {
      setFirstNameErr(t('create_account.messages.errors.mandatory_field'))
    } else {
      setFirstNameErr('')
    }
  }

  const lastNameOnblur = event => {
    if (event.target.value === '') {
      setLastNameErr(t('create_account.messages.errors.mandatory_field'))
    } else {
      setLastNameErr('')
    }
  }

  const titleOnblur = event => {
    if (event.target.value === 'none') {
      setTitleErr(t('create_account.messages.errors.mandatory_field'))
    } else {
      setTitleErr('')
    }
  }

  const emailOnblur = event => {
    if (!validateEmail(event.target.value) && event.target.value !== '') {
      setEmailErr(t('create_account.messages.errors.invalid_email'))
    } else if (event.target.value === '') {
      setEmailErr(t('create_account.messages.errors.mandatory_field'))
    } else {
      setEmailErr('')
    }
  }

  const usernameOnblur = event => {
    if (event.target.value === '') {
      setUsernameErr(t('create_account.messages.errors.mandatory_field'))
    } else {
      setUsernameErr('')
    }
  }

  const passwordOnblur = event => {
    if (event.target.value === '') {
      setPasswordErr(t('create_account.messages.errors.mandatory_field'))
    } else {
      setPasswordErr('')
    }
  }

  return (
    <div>
      <Grid
        className={classes.mainGrid}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid className={classes.mainItem} item xs={12}>
          <Box className={classes.headerText}>
            <Typography
              className={accessCodeValid ? classes.title2 : classes.title}
            >
              {!accessCodeValid
                ? t('create_account.create_account').toUpperCase()
                : t('create_account.finish_account').toUpperCase()}
            </Typography>
            {!accessCodeValid && (
              <Typography className={classes.subtitleText}>
                {t('create_account.create_message')}
              </Typography>
            )}
          </Box>
          <Grid
            data-testid="create_account_page"
            container
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={12} md={12}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Box pt={2}>
                    <CustomTextField
                      className={classes.domainField}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="domain"
                      placeholder={t('create_account.enter_access_code')}
                      name="domain"
                      autoFocus
                      autoComplete="off"
                      error={!!accessCodeError}
                      helperText={accessCodeError}
                      disabled={accessCodeValid}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FontAwesomeIcon
                              icon={['far', 'angle-left']}
                              size="sm"
                            />
                            <FontAwesomeIcon
                              icon={['far', 'angle-right']}
                              size="sm"
                            />
                          </InputAdornment>
                        ),
                        endAdornment: accessCodeValid ? (
                          <InputAdornment position="start">
                            <FontAwesomeIcon
                              icon={['fas', 'check-circle']}
                              className={classes.checkCircle}
                              size="2x"
                            />
                          </InputAdornment>
                        ) : (
                          <HighlightButton
                            data-testid="next_button"
                            disabled={!accessCode}
                            className={classes.nextButton}
                            type="button"
                            variant="contained"
                            onClick={handleValidateClick}
                          >
                            {t('create_account.next')}
                          </HighlightButton>
                        ),
                        className: `${classes.textFieldStyle} ${
                          !accessCodeError ? '' : classes.errorColor
                        }`,
                        style: {
                          paddingRight: accessCodeValid ? null : 0,
                          fontWeight: accessCode ? 'bold' : '300'
                        }
                      }}
                      onKeyUp={handleAccessCodeChange}
                      onPaste={handlePaste}
                    />
                  </Box>
                  <Box pt={3} hidden={accessCodeValid}>
                    <Typography className={classes.signIn}>
                      {t('create_account.have_account')}{' '}
                      {
                        <Link data-testid="go_back" to="/">
                          {t('sign_in.sign_in')}
                        </Link>
                      }
                    </Typography>
                    <Box pt={8}>
                      <Grid item>
                        <BasicButton
                          data-testid="back_log_in"
                          type="button"
                          variant="contained"
                          onClick={backClickHandler}
                        >
                          {t('forgot_password.back')}
                        </BasicButton>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box>
            <form hidden={!accessCodeValid} noValidate onSubmit={onSubmit}>
              <Grid container justifyContent="center" spacing={4}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <CustomTextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="company"
                    name="company"
                    type="text"
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={['far', 'coffee']} size="lg" />
                        </InputAdornment>
                      ),
                      className: classes.textFieldStyle,
                      style: {
                        fontWeight: 'bold'
                      }
                    }}
                    value={company}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <CustomTextField
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    name="first_name"
                    placeholder={t('create_account.first_name')}
                    id="first_name"
                    autoComplete="off"
                    onBlur={firstNameOnblur}
                    onFocus={() => setFirstNameErr('')}
                    error={firstNameErr}
                    helperText={firstNameErr}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={['far', 'user']} size="lg" />
                        </InputAdornment>
                      ),
                      autoComplete: 'disabled',
                      className: classes.textFieldStyle
                    }}
                    onInput={event => setFirstName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <CustomTextField
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    name="last_name"
                    placeholder={t('create_account.last_name')}
                    onBlur={lastNameOnblur}
                    onFocus={() => setLastNameErr('')}
                    helperText={lastNameErr}
                    error={lastNameErr}
                    id="last_name"
                    autoComplete="off"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={['far', 'user']} size="lg" />
                        </InputAdornment>
                      ),
                      autoComplete: 'disabled',
                      className: classes.textFieldStyle
                    }}
                    onInput={event => setLastName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    id="title"
                    name="title"
                    select
                    defaultValue={'none'}
                    onBlur={titleOnblur}
                    onFocus={() => setTitleErr('')}
                    helperText={titleErr}
                    error={titleErr}
                    SelectProps={{
                      // eslint-disable-next-line
                      IconComponent: props => (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={['far', 'angle-down']}
                            {...props}
                            className={classes.downArrowIcon}
                            size="lg"
                          />
                        </InputAdornment>
                      ),
                      onOpen: () => {
                        setOpen(true)
                      },
                      onClose: event => {
                        setOpen(false)
                      },
                      onChange: event => {
                        setTitle(event.target.value)
                      },
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: classes.titleMenu
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={['far', 'universal-access']}
                            size="lg"
                          />
                        </InputAdornment>
                      ),
                      className: classes.textFieldStyle
                    }}
                  >
                    <MenuItem
                      value="none"
                      key={0}
                      disabled
                      style={{ display: 'none' }}
                    >
                      <Typography className={classes.menuItemPlaceholder}>
                        {t('create_account.user_title')}
                      </Typography>
                    </MenuItem>
                    {Object.keys(
                      t('create_account.user_roles', { returnObjects: true })
                    ).map(key => {
                      return (
                        <MenuItem
                          key={key}
                          value={key}
                          className={classes.menuItem}
                        >
                          {
                            t('create_account.user_roles', {
                              returnObjects: true
                            })[key]
                          }
                          {title === key && open && (
                            <div className={classes.selectContainer}>
                              <FontAwesomeIcon
                                icon={['fal', 'check']}
                                className={classes.select}
                              />
                            </div>
                          )}
                        </MenuItem>
                      )
                    })}
                  </CustomTextField>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    id="email"
                    placeholder={t('create_account.email_address')}
                    onBlur={emailOnblur}
                    onFocus={() => setEmailErr('')}
                    helperText={emailErr}
                    error={emailErr}
                    name="email"
                    autoComplete="off"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={['far', 'envelope']}
                            size="lg"
                          />
                        </InputAdornment>
                      ),
                      autoComplete: 'disabled',
                      className: `${classes.textFieldStyle} ${
                        !emailError ? '' : classes.errorColor
                      }`
                    }}
                    onInput={event => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    id="username"
                    placeholder={t('create_account.username')}
                    onBlur={usernameOnblur}
                    onFocus={() => setUsernameErr('')}
                    helperText={usernameErr}
                    error={usernameErr}
                    name="username"
                    autoComplete="off"
                    type="text"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={['far', 'user-circle']}
                            size="lg"
                          />
                        </InputAdornment>
                      ),
                      className: `${classes.textFieldStyle} ${
                        !usernameError ? '' : classes.errorColor
                      }`
                    }}
                    onInput={event => setUsername(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <CustomTextField
                    variant="outlined"
                    margin="none"
                    required
                    fullWidth
                    id="password"
                    placeholder={t('sign_in.password')}
                    onBlur={passwordOnblur}
                    onFocus={() => setPasswordErr('')}
                    helperText={passwordErr}
                    error={passwordErr}
                    name="password"
                    autoComplete="new-password"
                    type={passVisible ? 'text' : 'password'}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon icon={['far', 'key']} size="lg" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          <FontAwesomeIcon
                            icon={['far', 'eye-slash']}
                            onClick={() => setPassVisible(!passVisible)}
                            className={classes.eyeIcon}
                            size="lg"
                          />
                        </InputAdornment>
                      ),
                      className: classes.textFieldStyle
                    }}
                    onInput={event => setPassword(event.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid
                className={classes.buttons}
                container
                justifyContent="flex-end"
                spacing={3}
              >
                <Grid item xs={12}>
                  <Typography align={'left'} className={classes.errorMessage}>
                    {creationError}
                  </Typography>
                </Grid>
                <Grid item>
                  <HighlightButton
                    disabled={disableButton}
                    variant="contained"
                    onClick={onSubmit}
                    className={classes.nextBtn}
                  >
                    {t('create_account.next')}
                  </HighlightButton>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
      </Grid>
    </div>
  )
}

export default CreateAccount
