import React, { useEffect, useState } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { addReportingCompany } from '../services/ApiService'
import { getParentCompanies, getUser } from '../lib/Api'
import { store } from '../store'
import { authActions } from '../store/signIn'
import { Box, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connectAdLogo, inviteParams, squareADLogo } from '../lib/Constants'

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw'
  },
  centerLogo: {
    height: '59px',
    marginTop: 'auto'
  },
  bottomLogo: {
    height: '90px',
    marginTop: 'auto',
    paddingBottom: '29px'
  },
  loadingIcon: {
    color: '#828282'
  },
  errorTitle: {
    fontSize: '16px',
    fontWeight: '700',
    fontFamily: 'Rubik',
    lineHeight: '18px',
    letterSpacing: '0.5px'
  },
  errorMessage: {
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Rubik',
    letterSpacing: '0.5px'
  },
  errorContainer: {
    textAlign: 'center',
    paddingTop: '50px'
  }
}))

const DeepLinkScreen = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const tokenInfo = useSelector(state => state.auth.token)
  const classes = useStyles()
  const [redirect, setRedirect] = useState(false)
  const [inviteMessage, setInviteMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState(null)

  useEffect(async () => {
    setTimeout(async () => {
      await handleRedirect()
    }, 1500)
  }, [])

  useEffect(() => {
    if (redirect) {
      // message will be needed for login screen v2
      history.push(`/sign-in?message='${inviteMessage}'`)
    }
  }, [redirect])

  async function handleRedirect() {
    const windowUrl = window.location.search
    const params = new URLSearchParams(windowUrl)
    // eslint-disable-next-line dot-notation
    const affiliateId = params.get('affiliate_id')
    const option = params.get('option')

    if (isAndroid || isIOS) {
      if (option === 'invite') {
        window.location.replace(`ftcapp://invite?affiliate_id=${affiliateId}`)
      } else {
        window.location.replace(
          `ftcapp://registration?affiliate_id=${affiliateId}`
        )
      }
      if (isIOS) {
        setTimeout(() => {
          window.location.replace(
            'https://itunes.apple.com/us/app/fieldtech-connect/id1345116164'
          )
        }, 5000)
      }
      if (isAndroid) {
        setTimeout(() => {
          window.location.replace(
            'https://play.google.com/store/apps/details?id=com.automateddecision.ftc.adftc&pageId=none'
          )
        }, 5000)
      }
    } else {
      // Remove once subcontractor onboarding process is done
      // document.location.href = process.env.REACT_APP_FTC_PORTAL_URL
      let userId
      switch (option) {
        case inviteParams.SUBCONTRACTOR:
          window.location.replace(
            'https://automateddecision.com/connected-platform/#Subcontractor'
          )
          break
        case inviteParams.TECHNICIAN:
          window.location.replace(
            'https://automateddecision.com/fieldtech-connect-mobile/#'
          )
          break
        default:
          userId = params.get('user_id')
          if (option === inviteParams.EXISTING_USER && userId) {
            let response
            try {
              response = await addReportingCompany(affiliateId, userId)
              if (response) {
                // show error if invitation from the same parent
                if (response === 'same_parent') {
                  setErrorMessage(t('general.messages.errors.same_parent'))
                } else if (response === 'invitation_accepted') {
                  // redirtect if invitation was already accepted
                  setInviteMessage('inviteAccepted')
                  setRedirect(true)
                } else {
                  // refresh user data if it is logged in
                  const user = await getUser()
                  user.parentCompanies = await getParentCompanies()
                  store.dispatch(
                    authActions.setUser({
                      ...tokenInfo,
                      userInfo: user
                    })
                  )
                  setRedirect(true)
                }
              } else {
                // TODO: handle null response
                setInviteMessage('Error')
                setRedirect(true)
              }
            } catch (error) {
              console.error(error)
              if (response) {
                setInviteMessage('inviteAccepted')
              }
              setRedirect(true)
            }
          } else {
            setRedirect(true)
          }
          break
      }
      return <p>{t('general.messages.navigation.redirecting')}</p>
    }
  }

  if (errorMessage) {
    return (
      <div className={classes.errorContainer}>
        <h1 className={classes.errorTitle}>
          {t('general.messages.errors.error')}
        </h1>
        <p className={classes.errorMessage}>{errorMessage}</p>
      </div>
    )
  }

  return (
    <Box className={classes.loadingContainer}>
      <img className={classes.centerLogo} src={connectAdLogo} />
      <FontAwesomeIcon
        className={classes.loadingIcon}
        icon="fa-light fa-loader"
        fade
        size="2xl"
      />
      <img className={classes.bottomLogo} src={squareADLogo} />
    </Box>
  )
}

export default DeepLinkScreen
