import React, { useEffect, useRef, useState } from 'react'
import { FormLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ImageSearch as ImageIcon } from '@material-ui/icons'
import { PhotoPicker } from '../../PhotoPicker'
import { AddButton } from '../../AddButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
  photoField: {
    marginLeft: '5px',
    marginBottom: '8px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  signatureContainer: {
    marginLeft: '5px',
    width: '100%',
    marginBottom: '6px',
    borderRadius: '8px'
  },
  thumb: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: '8px'
  },
  empty: {
    color: theme.colors.workOrders.downloadIcon,
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    marginBottom: '10px'
  },
  fieldContainer: {
    backgroundColor: theme.colors.grey_2,
    borderRadius: '6px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginBottom: '5px',
    minHeight: '52px',
    justifyContent: 'space-between',
    flex: 1
  },
  buttonLabel: {
    fontSize: '14px',
    color: theme.colors.iconBlue,
    textTransform: 'none',
    padding: '17px 12px 17px 5px',
    width: '90%'
  },
  icon: {
    alignSelf: 'center',
    paddingRight: '5px',
    color: theme.colors.grey
  },
  mandatory: {
    color: theme.colors.errorColor
  },
  textField: {
    ...theme.filtersClasses.mainInput2,
    height: 'auto',
    border: 'none',
    '& .MuiInputBase-input': {
      fontSize: '14px',
      lineHeight: '18px',
      paddingLeft: '5px'
    }
  },
  notes: {
    color: theme.colors.workOrders.counts,
    fontSize: '14px',
    fontWeight: '400',
    marginLeft: '5px'
  },
  fieldMessage: {
    marginLeft: '5px',
    marginTop: '5px',
    color: theme.colors.workOrders.downloadIcon,
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'left',
    marginBottom: '10px'
  }
}))

export const SignatureComponent = props => {
  const {
    data,
    handleOpenPhotos,
    disabled,
    headerText,
    onUpdate,
    mandatory,
    notAvailable,
    configSchema,
    section,
    questionId
  } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const photoRef = useRef()
  const [name, setName] = useState('')
  const [imageUrl, setImageUrl] = useState()

  useEffect(() => {
    let urlTmp = data?.url ?? data?.image
    if (urlTmp) {
      if (configSchema === 'v2') {
        urlTmp = urlTmp?.includes('http')
          ? data?.is_signature
            ? urlTmp.replace('.jpg', '_L.jpg')
            : urlTmp
          : process.env.REACT_APP_FTC_IMAGE_BASE_URL +
            (data?.is_signature ? urlTmp.replace('.jpg', '_L.jpg') : urlTmp)
      } else {
        if (urlTmp) {
          urlTmp = urlTmp?.includes('http')
            ? urlTmp.replace('.jpg', '_L.jpg')
            : process.env.REACT_APP_FTC_IMAGE_BASE_URL +
              urlTmp.replace('.jpg', '_L.jpg')
        }
      }
      setImageUrl(urlTmp)
    }
  }, [])

  useEffect(() => {
    setName(data?.name)
  }, [data?.name])

  const noImagesComp = () => (
    <div style={{ textAlign: 'center' }}>
      {!notAvailable && <ImageIcon color="disabled" />}
      <FormLabel
        component="legend"
        classes={{ root: notAvailable ? classes.fieldMessage : classes.empty }}
      >
        {notAvailable
          ? t('general.labels.not_available')
          : t('work_orders.images_message')}
      </FormLabel>
    </div>
  )

  const setPhotoUrl = url => {
    updateData({
      name: data?.name,
      image: url
    })
  }

  const removePhoto = () => {
    updateData({
      name: data?.name,
      url: null
    })
  }

  const updateData = value => {
    if (configSchema === 'v2') {
      const v2Data = [value]
      onUpdate(section, questionId, v2Data)
    } else {
      onUpdate(value)
    }
  }

  return (
    <>
      <FormLabel
        component="legend"
        className={`${classes.photoField} ${
          !disabled && mandatory ? classes.mandatory : null
        }`}
      >
        {headerText}
      </FormLabel>
      {disabled ? (
        <>
          {imageUrl ? (
            <Grid item >
              <div className={classes.signatureContainer}>
                <img
                  src={imageUrl}
                  className={classes.thumb}
                  onClick={() =>
                    handleOpenPhotos(0, [
                      {
                        uri: imageUrl
                      }
                    ])
                  }
                />
              </div>
            </Grid>
          )
          : <Typography className={classes.fieldMessage}>{t('work_orders.checkout_message')}</Typography>}
          {(data?.name || data?.title) && (
            <FormLabel component="legend" classes={{ root: classes.notes }}>
              {data.name ?? data?.title}
            </FormLabel>
          )}
          {!data?.image && !data?.name && !questionId && noImagesComp()}
        </>
      ) : (
        <>
          <PhotoPicker
            key={'photo-picker'}
            photoRef={photoRef}
            callback={setPhotoUrl}
          />
          {data?.image ? (
            <Grid className={classes.fieldContainer} item xs={12}>
              <FormLabel className={classes.buttonLabel}>
                {headerText}
              </FormLabel>
              <FontAwesomeIcon
                icon={['far', 'times-circle']}
                onClick={() => removePhoto()}
                className={classes.icon}
              />
            </Grid>
          ) : (
            <AddButton
              label={t('work_orders.trips.sign')}
              callback={() => photoRef.current.click()}
            />
          )}
          {
            <TextField
              variant="outlined"
              className={classes.textField}
              placeholder={t('work_orders.trips.enter_signature_name')}
              value={name}
              onChange={event => setName(event.target.value)}
              onBlur={() =>
                updateData({
                  image: data?.image,
                  name: name
                })
              }
            />
          }
        </>
      )}

      {/* TODO Update when signature field is modified */}
      {<div style={{ marginBottom: '20px' }} />}
    </>
  )
}
