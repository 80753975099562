import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, FormLabel, makeStyles } from '@material-ui/core'
import GlobalSelect from './form/Select'
import { authActions } from '../store/signIn'
import { getParentCompanies, getUser, setOriginatingCompany } from '../lib/Api'
import { store } from '../store'
import { loadingActions } from '../store/loading'
import { useTranslation } from 'react-i18next'
import { ConfirmDialog } from './form/ConfirmDialog'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '17px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '14px',
      marginBottom: '12px'
    }
  },
  switchDialog: {
    borderRadius: '9px'
  },
  dialogTitle: {
    '.&MuiTypography-root': {
      fontWeight: 'bold',
      fontSize: '13px',
      letterSpacing: '0.05em'
    }
  },
  dialogContent: {
    fontSize: '16px',
    color: theme.colors.basicDisabledButtonColor
  }
}))

export const CompanySwitch = props => {
  const user = useSelector(state => state.auth.user.userInfo)
  const classes = useStyles()

  const tokenInfo = useSelector(state => state.auth.token)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const nodeRef = useRef()
  const { hideLabel } = props
  const [open, setOpen] = useState(false)
  const [newCompany, setNewCompany] = useState(false)

  const handleChange = company => {
    setNewCompany(company)
    setOpen(true)
  }

  const handleChangeCompany = async () => {
    dispatch(loadingActions.show())
    // update originating company HEADER
    setOriginatingCompany(newCompany)
    // get updated user info
    const newUser = await getUser(false, true)
    newUser.parentCompanies = await getParentCompanies()

    store.dispatch(
      authActions.setUser({
        ...tokenInfo,
        userInfo: newUser
      })
    )
    location.reload()
  }

  return (
    !user.masquerade &&
    user.parentCompanies?.count > 1 && (
      <Box ref={nodeRef}>
        {!hideLabel && (
          <FormLabel className={classes.title}>
            {t('company_settings.reporting_company')}
          </FormLabel>
        )}
        <GlobalSelect
          value={user.originating_company}
          displayvalue
          options={user.parentCompanies.companies.map(item => ({
            label: item.name,
            value: item.id
          }))}
          onChange={handleChange}
          highlight
        />

        <ConfirmDialog
          open={open}
          setOpen={setOpen}
          title={t('general.switch_company.title')}
          message={t('general.switch_company.message')}
          cancellabel={t('general.labels.cancel')}
          acceptlabel={t('general.labels.continue')}
          handleAccept={handleChangeCompany}
        />
      </Box>
    )
  )
}
