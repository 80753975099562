export const forgotPasswordCode = () => {
  return {
    title: 'ACTUALIZAR CONTRASEÑA',
    subtitle_1: 'Porfavor actualice su contraseña.',
    code: 'Código',
    new_password: 'Ingrese Nueva Contraseña',
    re_enter_new_password: 'Ingrese nuevamente la nueva contraseña',
    reset_password: 'Iniciar Sesión'
  }
}
