import { create } from 'apisauce'
import { store } from '../store'
import { refreshToken } from '../services/AuthService'
import { authActions } from '../store/signIn'
import { portalIdentifier } from './Constants'
import { isRunningInIframe } from './Global'

/* TODO: FIX CAMEL CASE */
/* eslint-disable camelcase */

/**
 * Create an api of FTC API
 *
 * @type {ApisauceInstance}
 */
const api = create({
  baseURL: process.env.REACT_APP_FTC_API_SERVER_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    PORTAL: portalIdentifier
  },
  timeout: 30000
})

/**
 * Create an api of FTC API for iframe
 * This API is needed to make sure credentials are not mixed up if a user has an already existing session
 *
 * @type {ApisauceInstance}
 */
const iframeApi = create({
  baseURL: process.env.REACT_APP_FTC_API_SERVER_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    IFRAME: 'TRUE',
    PORTAL: portalIdentifier,
    sfsource: 'TRUE'
  },
  timeout: 30000
})

/**
 * Remove headers from API
 */
export const removeAuthorizationHeader = () => {
  api.deleteHeader('ORIGINATING-COMPANY-ID')
  api.deleteHeader('Authorization')
}

/**
 * Process response
 *
 * @param response
 * @returns {*}
 */
const processApiResponse = response => {
  if (!response.ok) {
    const title = `${response.status} - ${response.problem}`
    let message = `There was an error calling to FTC API: ${response.originalError}`

    if (response.data && response.data.error) {
      message = `${response.data.error.message}`
    }

    throw {
      name: title,
      message,
      code: response.status,
      details: response.data ? response.data.error : null
    }
  }

  return response.data
}

/**
 * Performs a call to the BV API
 *
 * @param {string} type The REST method
 * @param {string} route The API URI
 * @param {object} params The parameters to be sent to the API
 * @param {number} attempt The attempt number
 *
 * @throws {object} Will throw an error if the access token could not be refreshed
 *
 * @returns {object} The API response data
 */
const callAPI = async (
  type,
  route,
  params = {},
  authorized = 1,
  attempt = 1,
  switching = false
) => {
  const authStore = store.getState().auth
  let response

  if (authorized && authStore?.token?.access_token) {
    api.setHeader('Authorization', `Bearer ${authStore.token.access_token}`)
    if (authStore?.user?.userInfo && !switching) {
      api.setHeader(
        'ORIGINATING-COMPANY-ID',
        authStore.user.userInfo.originating_company
      )
    }
  }

  switch (type) {
    case 'POST':
      response = await api.post(route, params)
      break
    case 'PUT':
      response = await api.put(route, params)
      break
    case 'DELETE':
      response = await api.delete(route, params)
      break
    case 'GET':
      response = await api.get(route, params)
      break
    case 'PATCH':
      response = await api.patch(route, params)
      break
    default:
      throw {
        name: 'Method Not Allowed',
        message: 'Call type not supported',
        code: 405
      }
  }

  if (!response.ok) {
    if (response.status === 401 && authorized) {
      if (attempt === 1 || attempt === 2) {
        attempt++
        try {
          await refreshToken(
            authStore.token.refresh_token,
            authStore.user.userId
          )
          return callAPI(type, route, params, authorized, attempt)
        } catch (error) {
          return callAPI(type, route, params, authorized, attempt)
        }
      } else {
        store.dispatch(authActions.logout())
        console.log('Unable to refresh token in callApi')
        if (response?.data?.error?.message?.toLowerCase() === 'invalid user') {
          store.dispatch(authActions.setInvalidUser())
        } else {
          store.dispatch(authActions.setForceLogout())
        }
        return processApiResponse(response)
      }
    }
  }
  return processApiResponse(response)
}

const callIframeAPI = async (type, route, params = {}) => {
  let response
  switch (type) {
    case 'POST':
      response = await iframeApi.post(route, params)
      break
    case 'PUT':
      response = await iframeApi.put(route, params)
      break
    case 'DELETE':
      response = await iframeApi.delete(route, params)
      break
    case 'GET':
      response = await iframeApi.get(route, params)
      break
    case 'PATCH':
      response = await iframeApi.patch(route, params)
      break
    default:
      throw {
        name: 'Method Not Allowed',
        message: 'Call type not supported',
        code: 405
      }
  }

  if (!response.ok) {
    if (response.status === 401) {
      console.log('Unable to refresh token')
      return processApiResponse(response)
    }
  }
  return processApiResponse(response)
}

/**
 * Get User
 *
 * @returns {Promise<object>} The API response data
 */
export const getUser = async (iframe = false, switching = false) => {
  try {
    if (iframe) return await callIframeAPI('GET', '/users/me')
    return await callAPI('GET', '/users/me', {}, 1, 1, switching)
  } catch (error) {
    console.error('Error fetching user:', error)
    throw error
  }
}

/**
 * Get Parent companies for current user
 */
export const getParentCompanies = async () => {
  return await callAPI('GET', '/companies/availablecompanies', {
    page: 1,
    limit: 25
  })
}

/**
 * Reset Password
 *
 * @returns {Promise<object>} The API response data
 */
export const resetPasswordRequest = async email => {
  return await callAPI('POST', '/users/forgotpassword', { email }, false)
}

/**
 * Validate access code
 *
 * @returns {Promise<object>} The API response data
 */
export const validateAccessCode = async id => {
  return await callAPI('GET', '/users/validateaccesscode', { id }, false)
}

/**
 * Send invite email for second reporting company
 */
export const sendInviteEmail = async (email, companyId) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('GET', 'users/sendInviteEmail', { email, companyId })
}

/**
 * Associate user to a new company with the affiliate Id and user Id provided
 * @returns {Promise<object>} The API response data
 */
export const addReportingCompany = async (affiliateId, userId) => {
  return await callAPI(
    'POST',
    '/users/addcompanyofflinebycode',
    {
      affiliate_id: affiliateId,
      user_id: userId
    },
    false
  )
}

/**
 * Create user
 *
 * @returns {Promise<object>} The API response data
 */
export const createUser = async (params, step) => {
  const response = await callAPI('POST', `/users?step=${step}`, params, false)
  if (!response || response.status === 204) return true
  return response
}

export const workOrdersPortal = async (
  showAll = false,
  query = '',
  client_name = '',
  address = '',
  trades = '',
  service = '',
  wo_number = '',
  open_date = '',
  expiration_date = '',
  status = '',
  invoices = '',
  gc_scope = '',
  general_notes = '',
  nte = '',
  priority = '',
  client_tracking_number = '',
  sort = '',
  limit = 25,
  page = 1,
  integration = '',
  singleWorkorder = false,
  external_id = ''
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    return await selectedAPI(
      'GET',
      '/workorders',
      new URLSearchParams({
        showAll,
        query,
        client_name,
        address,
        trades,
        service,
        wo_number,
        open_date,
        expiration_date,
        status,
        invoices,
        gc_scope,
        general_notes,
        nte,
        priority,
        client_tracking_number,
        sort,
        limit,
        page,
        group_portal: true,
        integration,
        singleWorkorder,
        external_id
      })
    )
  } catch (err) {
    throw err
  }
}

export const csvData = async (
  showAll = false,
  query = '',
  clientName = '',
  address = '',
  trades = '',
  service = '',
  woExtId = '',
  openDate = '',
  expirationDate = '',
  status = '',
  invoices = '',
  priority = '',
  clientTrackingNumber = '',
  generalNotes = '',
  sort = '',
  ids = '',
  companyId = ''
) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI(
    'GET',
    '/reports/workorders',
    new URLSearchParams({
      showAll,
      query,
      clientName,
      address,
      trades,
      service,
      woExtId,
      openDate,
      expirationDate,
      status,
      invoices,
      priority,
      clientTrackingNumber,
      sort,
      groupPortal: true,
      ids,
      companyId,
      generalNotes
    })
  )
}

export const csvInvoicesData = async (
  showAll = false,
  query = '',
  won = '',
  status = '',
  client = '',
  location = '',
  invoiceNumber = '',
  amount = '',
  createDate = '',
  dueDate = '',
  payDate = '',
  achCheckNumber = '',
  sort = '',
  companyId = '',
  limit = 25,
  skip = 1,
  ids = ''
) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const queryObject = {
    showAll,
    query,
    won,
    status,
    client,
    location,
    invoiceNumber,
    amount,
    createDate,
    dueDate,
    payDate,
    achCheckNumber,
    sort,
    companyId,
    limit,
    skip,
    ids
  }

  if (!amount) {
    delete queryObject.amount
  }

  if (!ids) {
    delete queryObject.ids
  }

  return await selectedAPI('POST', '/invoices/reports', queryObject)
}
export const exportWorkOrders = async (
  showAll = false,
  query = '',
  client_name = '',
  address = '',
  trades = '',
  service = '',
  woExtId = '',
  openDate = '',
  expiration_date = '',
  status = '',
  invoices = '',
  priority = '',
  client_tracking_number,
  sort = '',
  general_notes = ''
) => {
  // TODO process email response
  return await callAPI(
    'GET',
    '/reports/workorders',
    new URLSearchParams({
      showAll,
      query,
      client_name,
      address,
      trades,
      service,
      woExtId,
      openDate,
      expiration_date,
      status,
      invoices,
      priority,
      client_tracking_number,
      sort,
      group_portal: true,
      general_notes
    })
  )
}

export const invoicesPortal = async (
  showAll = false,
  query = '',
  won = '',
  inStatus = '',
  client = '',
  location = '',
  invoiceNumber = '',
  amount = '',
  createDate = '',
  dueDate = '',
  payDate = '',
  achCheckNumber = '',
  sort = '',
  companyId = '',
  limit = 25,
  skip = 1
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    return await selectedAPI(
      'GET',
      `/invoices/company/${companyId}`,
      new URLSearchParams({
        showAll,
        query,
        won,
        inStatus,
        client,
        location,
        invoiceNumber,
        amount,
        createDate,
        dueDate,
        payDate,
        achCheckNumber,
        sort,
        limit,
        skip
      })
    )
  } catch (err) {
    throw err
  }
}

export const submitInvoicePortal = async (
  id = '',
  submit = false,
  body = {}
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    return await selectedAPI(
      'PUT',
      `/invoices/${id}/?` + new URLSearchParams({ submit }),
      { ...body },
      true
    )
  } catch (err) {
    throw err
  }
}

export const getInvoiceById = async (id = '') => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  // eslint-disable-next-line no-useless-catch
  try {
    return await selectedAPI('GET', `/invoices/${id}`, true)
  } catch (error) {
    throw error
  }
}

/**
 * Update Account Settings
 *
 * @returns {Promise<object>} The API response data
 */
export const updateAccountSettings = async params => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI('PUT', '/users/me', params, true)
  if (!response || response.status === 204) return true
  return response
}

/**
 * Update User
 *
 * @returns {Promise<object>} The API response data
 */
export const updateUser = async (id, params) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI('PUT', `/users/${id}`, params, true)
  if (!response || response.status === 204) return true
  return response
}

/**
 * Delete User
 */
export const deleteUser = async (id, companyId) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('POST', '/users/deleteuser', {
    id: id,
    company_id: companyId
  })
}

/**
 * GET Trades list for current user
 * @returns Trades list
 */
export const getWorkOrderTrades = async () => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('GET', '/trades/wotrades')
    return response
  } catch {
    return false
  }
}
/**
 * GET Trades list for current user
 * @returns Trades list
 */
export const getWorkOrderPriorities = async client_id => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('GET', '/workorders/priorities/client', {
      client_id
    })
    return response
  } catch {
    return false
  }
}

/**
 * GET Selected company
 * @returns Company
 */
export const getCompany = async id => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI('GET', `/companies/${id}`)
  return response
}

/**
 * GET Company contact
 * @returns Contact
 */
export const getContactOffline = async (id, originEmail) => {
  const response = await callAPI(
    'GET',
    `/company-contact-offline/${id}`,
    {
      origin_email: originEmail
    },
    false
  )
  return response
}

/**
 * GET Selected company Profile
 * @returns CompanyProfile
 */
export const getCompanyProfile = async id => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI('GET', `/companyProfile/${id}`)
  return response
}

/**
 * GET Terms and conditions by companyId
 * @returns TermsAndConditions
 */
export const getTermsAndConditions = async (companyId, content) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI(
    'GET',
    `/terms/company/${companyId}`,
    new URLSearchParams({
      content
    })
  )
  return response
}

/**
 * GET file from S3 bucket
 * @returns Buffer
 */
export const getCompanyFile = async key => {
  const encodedURI = encodeURIComponent(key)
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI(
    'GET',
    `/companyFileUploaded/${encodedURI}`
  )
  return response
}

/**
 * PUT Update selected company
 * @returns Company
 */
export const updateCompany = async (id, params) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI('PUT', `/companies/${id}`, params)
  return response
}

/**
 * POST signed URL to download file
 * @param string path
 * @param string contentType
 * @param string action
 * @returns Created or updated log
 */
export const getSignedURL = async (path, action, contentType) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI(
    'POST',
    '/companies/generate-doc-signed-url',
    {
      path: path,
      contentType: contentType,
      action: action
    }
  )
  return response
}

/**
 * Get client users
 *
 * @param companyId
 * @returns {Promise<object[]>} The API response data
 */
export const getCompanyUsers = async (companyId = 0) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('GET', `users/companyusers/${companyId}`)
}

/**
 * Get company roles
 *
 * @param companyId
 * @returns {Promise<object[]>} The API response data
 */
export const getRoles = async (companyId = 0) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('GET', `roles/company/${companyId}`)
}

/**
 * Create Role with access scopes
 * @param name
 * @param workorders
 * @param sites
 * @param companySettings
 * @returns {Promise<object>} The API response data
 */
export const createRolWithScopes = async (
  name,
  workorders,
  sites,
  companySettings
) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  isRunningInIframe()
    ? iframeApi.setHeader('Content-Type', 'application/json')
    : api.setHeader('Content-Type', 'application/json')
  return await selectedAPI('POST', '/roles', {
    name,
    workorders,
    sites,
    companySettings
  })
}

/**
 * Update Role with access scopes
 * @param id
 * @param name
 * @param workorders
 * @param sites
 * @param companySettings
 * @returns {Promise<object>} The API response data
 */
export const updateRolWithScopes = async (
  id,
  name,
  workorders,
  sites,
  companySettings
) => {
  api.setHeader('Content-Type', 'application/json')
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('PUT', `/roles/${id}`, {
    name,
    workorders,
    sites,
    companySettings
  })
}

/**
 * Delete Role with Scopes
 * @param id
 * @returns {Promise<boolean>} The API response data
 */
export const deleteRolWithScopes = async id => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('DELETE', `/roles/${id}`)
}

/**
 * GET Company Roles list
 * @returns Company Roles list
 */
export const getCompanyRoles = async companyId => {
  try {
    const response = await callAPI('GET', '/roles/company/' + companyId)
    return response
  } catch {
    return false
  }
}

/**
 * Change user password
 * @param password
 * @returns {Promise<object>} The API response data
 */
export const changeUserPassword = async password => {
  api.setHeader('Content-Type', 'application/json')
  return await callAPI('PUT', '/api/users/change-password', {
    password
  })
}

/**
 * Upload ETA
 * @param id id of the WO,
 * @param time eta time
 * @returns {boolean} true if the call was successful, false if it isn't
 */
export const uploadETA = async (id, time, iframe = false) => {
  const selectedAPI = iframe ? callIframeAPI : callAPI
  try {
    return await selectedAPI('POST', '/workorders/eta', {
      woId: id,
      eta: {
        time: time,
        confirmation: true,
        reason: '',
        updatedAt: ~~(Date.now() / 1000)
      }
    })
  } catch {
    return false
  }
}

// Uncomment for contact us functionality
/**
 * Post contactUs email
 *
 * @returns {Promise<object>} The API response data
 */
// export const sendContactUsEmail = async (email, name, company, message) => {
//   return await callAPI(
//     'POST',
//     '/users/contactusemail',
//     {
//       email,
//       name,
//       company,
//       message
//     },
//     false
//   )
// }

/**
 * Check in/out from a WO
 * @param {object} woLog
 * @returns Created or updated log
 */
export const uploadWoLog = async (woLog, iframe = false) => {
  const selectedAPI = iframe || isRunningInIframe() ? callIframeAPI : callAPI
  let response = null
  if (woLog.type === 'checkIn') {
    response = await selectedAPI('POST', 'workorderlogs', {
      work_order_id: woLog.work_order_id,
      latitude: woLog.latitude,
      longitude: woLog.longitude,
      type: woLog.type,
      user_time_zone: woLog.user_time_zone,
      technicians_number: woLog.technicians_number,
      date_created: woLog.date_created,
      wo_log_id: woLog.wo_log_id,
      offline: false
    })
  } else {
    response = await selectedAPI('POST', 'workorderlogs', {
      work_order_id: woLog.work_order_id,
      latitude: woLog.latitude,
      longitude: woLog.longitude,
      type: woLog.type,
      wo_log_id: woLog.wo_log_id,
      return_visit: woLog.return_visit,
      return_time: woLog.return_time,
      return_time_end: woLog.return_time_end,
      user_time_zone: woLog.user_time_zone,
      date_created: woLog.date_created,
      status: woLog.status,
      offline: false,
      cancel_trip: woLog.cancel_trip,
      clockout_option: woLog.clockout_option,
      return_reason: woLog.return_reason
    })
  }
  return response
}

export const uploadRepair = async (repair, iframe = false, screenConfigId) => {
  const selectedAPI = iframe || isRunningInIframe() ? callIframeAPI : callAPI
  // Ensure the id for the repair is always set
  if (!repair.id && repair._id) repair.id = repair._id
  let response
  if (!repair.id) {
    // SAVE
    response = await selectedAPI('POST', 'repairs', {
      date_created: repair.date_created,
      work_order_id: repair.work_order_id,
      user_id: repair.user_id,
      status: repair.status,
      type: repair.type,
      data: repair.data,
      sync_error: repair.sync_error,
      schema_version: 'v2',
      screen_config_id: screenConfigId
    })
  } else {
    // UPDATE
    response = await selectedAPI('PUT', `repairs/${repair.id}`, {
      work_order_id: repair.work_order_id,
      user_id: repair.user_id,
      status: repair.status,
      data: repair.data,
      sync_error: repair.sync_error,
      screen_config_id: screenConfigId
    })
  }
  return response
}

export const uploadResource = async (data, path, contentType) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI('POST', 'resources/upload-resource', {
    data,
    path,
    contentType
  })
  return response
}

export const uploadConstructionForm = async (
  constructionForm,
  iframe = false
) => {
  const selectedApi = iframe || isRunningInIframe() ? callIframeAPI : callAPI
  // Ensure the id for the repair is always set
  if (!constructionForm.id && constructionForm._id) {
    constructionForm.id = constructionForm._id
  }
  let response
  if (!constructionForm.id) {
    // SAVE
    response = await selectedApi('POST', 'field-construction-forms', {
      date_created: constructionForm.date_created,
      work_order_id: constructionForm.work_order_id,
      user_id: constructionForm.user_id,
      status: constructionForm.status,
      type: constructionForm.type,
      data: constructionForm.data,
      sync_error: constructionForm.sync_error,
      check_out_log_id: constructionForm.check_out_log_id,
      wo_external_id: constructionForm.wo_external_id,
      external_id: constructionForm.external_id
    })
  }
  return response
}

/*
 * GET Selected WorkOrder
 * @returns WorkOrder
 */
export const getWorkOrder = async id => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('GET', `/workorders/${id}`)
}

/*
 * GET Selected WorkOrder
 * @returns WorkOrder
 */
export const getWorkOrderList = async ids => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('GET', `/workorders/list/${ids}`)
}

/**
 * Download WO by id
 * @param {string} id
 * @returns WorkOrder
 */
export const getWoByIdWithAuth = async (iframe, id) => {
  const selectedAPI = iframe ? callIframeAPI : callAPI
  return await selectedAPI('GET', `workorders/data/${id}`)
}

export const setIframeAccessToken = accessToken => {
  iframeApi.setHeader('Authorization', `Bearer ${accessToken}`)
}

export const setIframeOriginatingCompany = originatingCompany => {
  iframeApi.setHeader('ORIGINATING-COMPANY-ID', originatingCompany)
}

export const setIframeAppVersion = appVersion => {
  iframeApi.setHeader('APP-VERSION', appVersion)
}
export const setOriginatingCompany = originatingCompany => {
  api.setHeader('ORIGINATING-COMPANY-ID', originatingCompany)
}

/**
 * Send Notification
 * @param option
 * @param clientId
 * @param extraInfo
 * @returns {Promise<object>} The API response data
 */
export const sendNotification = async (option, clientId, userId, extraInfo) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  isRunningInIframe()
    ? iframeApi.setHeader('Content-Type', 'application/json')
    : api.setHeader('Content-Type', 'application/json')
  return await selectedAPI('POST', '/api/notifications/send', {
    option,
    clientId,
    userId,
    extraInfo
  })
}

export const getIframeApi = async () => {
  return iframeApi
}

/**
 *
 * @param {*} params
 * @param {*} configId
 * @returns screen config
 */

export const getScreenConfigOnline = async (
  configId,
  params,
  iframe = false
) => {
  if (iframe) {
    return await callIframeAPI(
      'GET',
      `/screen-configurations/${configId}/services-config`,
      {
        trade: params.trade,
        service: params.service,
        work_type: params.work_type
      }
    )
  }
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI(
      'GET',
      `/screen-configurations/${configId}/services-config`,
      {
        trade: params.trade,
        service: params.service,
        work_type: params.work_type
      }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

/**
 * Create Invoice Attachment
 *
 * @returns {Promise<object>} The API response data
 */
export const createInvoiceAttachment = async body => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI('POST', '/invoices/file', body)
  return response
}

/**
 * Delete Invoice Attachment
 *
 * @returns {Promise<object>} The API response data
 */
export const deleteInvoiceAttachment = async (invoice_id, file_id) => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  const response = await selectedAPI(
    'DELETE',
    `/invoices/${invoice_id}/file/${file_id}`
  )
  return response
}

/*
 * GET company bill disclosure config
 */
export const getCompanyBillDisclosure = async id => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('GET', `/companies/billdisclosure/${id}`)
}

/*
 * PUT company bill disclosure config
 */
export const putCompanyBillDisclosure = async id => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('PUT', `/companies/billdisclosure/${id}`)
}

/**
 * GET workorder attachments
 * @param {string} id
 */
export const getWorkOrderAttachments = async id => {
  const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
  return await selectedAPI('GET', `/workorders/${id}/attachments`)
}

/**
 * POST Work Messages
 * @returns Messages related to Work order
 */
export const createMessage = async body => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('POST', '/messages', body)
    return response
  } catch {
    return false
  }
}

/**
 * PUT Work Messages
 * @returns Messages related to Work order
 */
export const editMessage = async (id, body) => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('PUT', `/messages/${id}`, body)
    return response
  } catch {
    return false
  }
}

/**
 * DELETE Work Messages File
 * @returns Message File related to Work order deletion confirmation
 */
export const deleteMessageFile = async (id, fileId) => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI(
      'DELETE',
      `/messages/${id}/file/${fileId}`
    )
    return response
  } catch {
    return false
  }
}

/**
 * POST Work Message File
 * @returns Messages File related to Work order
 */
export const createMessageFile = async (id, body) => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('POST', `/messages/file/${id}`, body)
    return response
  } catch {
    return false
  }
}

/**
 * PUT Work Messages in Salesforce
 * @returns Messages related to Work order Salesforce
 */
export const editExternalMessage = async (id, body) => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('PUT', `/messages/external/${id}`, body)
    return response
  } catch {
    return false
  }
}

/**
 * POST Work Messages Salesforce
 * @returns Messages related to Work order Salesforce
 */
export const createExternalMessage = async body => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('POST', '/messages/external', body)
    return response
  } catch {
    return false
  }
}

/**
 * GET Work Messages
 * @returns Messages related to Work order
 */
export const getWorkMessages = async id => {
  try {
    const selectedAPI = isRunningInIframe() ? callIframeAPI : callAPI
    const response = await selectedAPI('GET', `/messages/workorder/${id}`)
    return response
  } catch {
    return false
  }
}
