import { createSlice } from '@reduxjs/toolkit'

const initialAuthState = {
  token: null,
  user: null,
  client: null,
  forceLogout: null,
  redirect: '/work-orders',
  billDisclosure: null,
  invalidUser: null
}

/**
 * Create a slice of reducer for auth
 *
 * @type {Slice<{user: null, token: null}, {logout(*): void, login(*, *): void}, string>}
 */
const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login(state, action) {
      state.token = action.payload
    },
    logout(state) {
      state.token = null
      state.user = null
      state.client = null
      state.redirect = '/work-orders'
      state.billDisclosure = null
    },
    setForceLogout(state) {
      state.forceLogout = !state.forceLogout
    },
    setToken(state, action) {
      state.token = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setRedirect(state, action) {
      state.redirect = action.payload
    },
    resetRedirect(state, action) {
      state.redirect = '/work-orders'
    },
    setRememberEmail(state, action) {
      state.email = action.payload
    },
    setRememberPassword(state, action) {
      state.password = action.payload
    },
    removeRemember(state) {
      state.email = null
      state.password = null
    },
    setClient(state, action) {
      state.client = action.payload
    },
    removeClient(state) {
      state.client = null
    },
    setBillDisclosure(state, action) {
      state.billDisclosure = action.payload
    },
    setInvalidUser(state) {
      state.invalidUser = !state.invalidUser
    }
  }
})

export const authActions = authSlice.actions

export default authSlice.reducer
