export const companySettings = () => {
  return {
    title: 'COMPANY SETTINGS & PROFILE',
    compliant: 'COMPLIANT',
    mobile_only: 'Mobile Only',
    missing: 'Missing',
    reporting_company: 'Reporting Company',
    info_card: {
      title: 'COMPANY INFO',
      name: 'Company Legal Name',
      name_placeholder: 'Enter company name',
      legal_entity: 'Legal Entity (LLC, C-Corp, etc)',
      address: 'Company Billing Address',
      street: 'Street',
      city: 'City',
      zip_code: 'Zip/Postal Code',
      license: 'License#',
      contractor_license: 'Contractor License #',
      phone: 'Phone Number',
      company_phone: 'Company Phone Number',
      fax: 'Fax Number',
      mail: 'Company Email',
      dba: 'Comp. DBA',
      state: 'State(s) Company is Registered',
      state_placholder: 'Enter state company is registered',
      country: 'Country(s) of Operation',
      website: 'Company Website',
      edit_logo: 'Edit Logo',
      add_logo: 'Add Logo'
    },
    profile_card: {
      title: 'PROFILE',
      content: 'Amazing things are coming soon!'
    },
    terms_card: {
      accepted: 'Accepted',
      not_accepted: 'Not Accepted'
    },
    roles_card: {
      title: 'ROLES',
      create_users: 'Create Users',
      edit_users: 'Edit Users',
      delete_users: 'Delete Users',
      wo_view: 'View Work Orders',
      workorders: 'Work Orders',
      invoices: 'Invoices',
      company_settings: 'Company Profile',
      sites: 'Sites',
      no_portal_access: 'No Portal Access',
      manage_access: 'Manage Access',
      panel_new: 'New Access',
      panel_details: 'Access Details',
      panel_name: 'Name',
      panel_users: 'Manage Users',
      panel_dash: 'View Dashboard',
      panel_wo: 'View Work Orders',
      panel_prop: 'View Proposals',
      panel_create: 'Create',
      panel_delete: 'Delete',
      panel_save: 'Save',
      panel_edit: 'Edit',
      panel_yes: 'Yes',
      panel_no: 'No',
      panel_select: 'Select',
      access_name: 'Access Name',
      report_scheduler: 'Report Scheduler Admin'
    },
    users_card: {
      title: 'USERS',
      name: 'Name',
      first_and_last_name: 'First and Last Name',
      role: 'Title',
      company_role: 'Company Role',
      access: 'Access Level',
      panel_new: 'New User',
      panel_details: 'User Details',
      panel_name: 'Name',
      panel_first_and_last_name: 'First and Last Name',
      panel_mail: 'Email Address',
      panel_role: 'Title',
      panel_company_role: 'Company Role',
      panel_portal: 'Web portal access',
      user_name_placeholder: 'User Name and Last Name',
      user_email_placeholder: 'User Email Address',
      user_role_placeholder: 'User Role',
      user_company_role_placeholder: 'User Company Role',
      pending: 'pending',
      user_already_exists: 'User already exists',
      search: 'Search user',
      add_users: 'Please add users to this section'
    },
    support_card: {
      title: 'Support',
      policy: 'Privacy Policy',
      conditions: 'Terms and Conditions',
      technical: 'Technical Support',
      terms_of_use: 'Terms of Use',
      version: 'Software Version'
    },
    reports_card: {
      title: 'Scheduled Reports',
      name: 'Report name',
      frequency: 'Frequency',
      sites: 'Site(s)',
      status: 'Status',
      creator: 'Creator',
      panel_title: 'Report',
      panel_name: 'Report Name',
      panel_name_placeholder: 'Enter name',
      panel_mail_to: 'Recipients (To)',
      panel_mail_placeholder: 'Enter email address and press enter',
      panel_mail_cc: 'Recipients (cc)',
      panel_client_name: 'Client Name',
      panel_client_name_placeholder: 'Search Client Name',
      send_on: 'Send Email On (day/time)',
      data_range: 'Date range of data in report',
      trades: 'Trade(s)',
      wo_status: 'Work Order Status',
      email_ends: 'Email Ends',
      active_report: 'Active',
      inactive_report: 'Inactive',
      view_more: 'View More'
    },
    bes_reports_card: {
      name: 'Name',
      reports: 'Scheduled Reports'
    },
    bes_notifications_card: {
      title: 'Notifications',
      name: 'Name',
      action: 'Action',
      client: 'Client',
      creator: 'Creator',
      view_more: 'View More'
    },
    bes_notifications_panel: {
      title: 'Notification',
      name: 'Notification Name',
      name_placeholder: 'Enter name',
      mail_to: 'Recipients (To)',
      mail_placeholder: 'Enter email address and press enter',
      mail_cc: 'Recipients (cc)',
      client_name: 'Client Name',
      client_name_placeholder: 'Search Client Name',
      send_notifications_when: 'Send Email Notification When',
      delete: 'Delete',
      save: 'Save',
      create: 'Create',
      options: {
        client_log_in: 'Client Log In',
        client_runs_report: 'Client Runs Report',
        client_creates_user: 'Client Creates User',
        client_submits_service_request: 'Client Submits Service Request'
      }
    },
    buttons: {
      edit: 'Edit',
      show_more: 'Show More',
      add: 'Add'
    },
    card: {
      profile: 'Company Profile',
      terms: 'Master Service Agreements',
      trades: 'Trades & Services',
      service: 'Service Area',
      web_users: 'Web Portal Users',
      field_users: 'FieldTech Mobile Users',
      roles: 'User Roles',
      name: 'Name and Details',
      insurance: 'Company Info & Insurance',
      expiration: 'Expiration',
      coi: 'COI',
      comp: 'Workers Comp',
      w9: 'W9',
      bank: 'Voided Bank Check',
      save: 'Save',
      update: 'Update logo',
      update_question: 'Are you sure you want to update the Company logo?',
      cancel: 'Cancel',
      no_file: 'Missing',
      miles: 'miles',
      more_areas: 'Select show more to view more service areas'
    },
    terms_file: {
      confidential: '%company% Confidential Information',
      accepted_by: 'Accepted by %name% on %date% at %time%',
      file_name: ' Master Services Agreement'
    }
  }
}
