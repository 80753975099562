import React, { useState, useEffect } from 'react'
import {
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  makeStyles
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const useStyles = makeStyles(theme => ({
  mainInput1: theme.filtersClasses.mainInput1,
  mainInput2: theme.filtersClasses.mainInput2
}))

export const InputFieldFilter = props => {
  const classes = useStyles()
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (props.values[props.id] === '') {
      setSearch('')
    }
    if (props.values) {
      setSearch(props.values[props.id])
    }
  }, [props.isOpen, props.values[props.id]])

  const handleChange = event => {
    setSearch(event.target.value)
  }

  const handleEnter = event => {
    if (
      event.keyCode === 13 &&
      (!search || search.length >= props.checkMinCharacters)
    ) {
      props.setValues(prevState => ({
        ...prevState,
        [props.id]: search
      }))
    }
  }

  const handleClearSearch = () => {
    setTimeout(() => {
      props.setValues(prevState => ({
        ...prevState,
        [props.id]: ''
      }))
      setSearch('')
    }, 1000)
  }

  const handleBlurSearch = event => {
    if (!search || search.length >= props.checkMinCharacters) {
      /* Blur delayed if clear exists */
      setTimeout(() => {
        props.setValues(prevState => ({
          ...prevState,
          [props.id]: search
        }))
      }, 100)
    }
  }

  return (
    <FormControl variant="outlined" fullWidth>
      <OutlinedInput
        id="component-outlined"
        key={props.id}
        value={search}
        placeholder={props.placeholder}
        onChange={handleChange}
        onKeyDown={handleEnter}
        onBlur={handleBlurSearch}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClearSearch}>
              {search && search.length > 0 ? (
                <FontAwesomeIcon icon={['far', 'times']} fontSize="small" />
              ) : (
                <div />
              )}
            </IconButton>
          </InputAdornment>
        }
        startAdornment={
          <InputAdornment position="start">
            <FontAwesomeIcon
              icon={['far', 'search']}
              fontSize="small"
              color="grey"
            />
          </InputAdornment>
        }
        type="text"
        classes={{
          root: props.type === 1 ? classes.mainInput1 : classes.mainInput2
        }}
        autoComplete="off"
        style={{
          paddingRight: '0px'
        }}
      />
    </FormControl>
  )
}
