import React from 'react'
import { styled, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { OutlinedInput, Tabs } from '@material-ui/core'
import { Tab } from '@mui/material'
import { navBarHeaderHeight } from '../lib/Constants'
import customTheme from './mui_theme'

export const BasicButton = withStyles(theme => ({
  root: {
    background: theme.colors.basicButtonBackground,
    width: 'auto',
    height: '60px',
    textTransform: 'unset !important',
    border: `1px solid ${theme.palette.primary.main}`,
    paddingLeft: '35px',
    paddingRight: '35px',
    borderRadius: '36px',
    minWidth: '144px'
  },
  contained: {
    boxShadow: '0 0 0 0'
  },
  label: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '20px'
  }
}))(Button)

export const HighlightButton = withStyles(theme => ({
  root: {
    background: theme.palette.primary.main,
    width: 'auto',
    height: '60px',
    textTransform: 'unset !important',
    paddingLeft: '35px',
    paddingRight: '35px',
    borderRadius: '36px'
  },
  contained: {
    boxShadow: '0 0 0 0'
  },
  label: {
    color: theme.colors.highlightButtonText,
    fontWeight: 'bold',
    fontSize: '20px'
  }
}))(Button)

export const SignInButton = withStyles(theme => ({
  root: {
    background: theme.colors.signInButton.background,
    height: '60px',
    textTransform: 'unset !important',
    paddingLeft: '35px',
    paddingRight: '30px'
  },
  contained: {
    boxShadow: '0 0 0 0'
  },
  label: {
    color: theme.colors.signInButton.label
  }
}))(Button)

export const CustomTextField = withStyles(theme => ({
  root: {
    height: '60px',
    '& .MuiInputBase-root': {
      borderRadius: '45px',
      '& input': {
        fontSize: '20px',
        WebkitBoxShadow: '0 0 0 1000px white inset'
      }
    },
    '& .MuiFormLabel-root': {
      fontSize: '20px'
    }
  }
}))(TextField)

export const CustomOutlinedInput = withStyles(theme => ({
  root: {
    height: '40px',
    '& .MuiInputBase-root': {
      '& input': {
        fontSize: '16px'
      }
    },
    '& .MuiInputBase-input': {
      fontSize: '20px'
    }
  }
}))(OutlinedInput)

export const StyledNavTabs = withStyles(theme => ({
  root: {
    '& .MuiTabs-flexContainer': {
      height: navBarHeaderHeight
    }
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 78,
      width: '100%',
      backgroundColor: theme.palette.primary.dark
    }
  }
}))(props => <Tabs {...props} />)

export const StyledNavTab = withStyles(theme => ({
  root: {
    minWidth: '70px',
    textTransform: 'none',
    color: theme.colors.text,
    fontWeight: '400',
    fontSize: '16px',
    height: navBarHeaderHeight,
    '&:focus': {
      opacity: 1
    },
    '&$selected': {
      color: theme.colors.iconBlue,
      textDecoration: 'underline'
    },
    '&$root': {
      paddingTop: 0,
      fontWeight: '400',
      fontSize: '16px',
      textTransform: 'none',
      textDecorationThickness: '3px',
      textUnderlineOffset: '5px',
      opacity: 1
    },
    '&.MuiButtonBase-root': {
      height: navBarHeaderHeight,
      minHeight: navBarHeaderHeight,
      paddingBottom: 0,
      paddingLeft: '16px',
      paddingRight: '16px',
      fontFamily: 'Rubik',
      minWidth: 'auto'
    }
  },
  selected: {
    color: theme.colors.iconBlue
  }
}))(props => <Tab disableRipple {...props} />)

export const BootstrapSelectContainer = styled(Button)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '&.MuiButtonBase-root': {
    borderRadius: 45,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid ' + customTheme.colors.profile.border_input,
    fontSize: 12,
    padding: '7px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 45,
      borderColor: customTheme.colors.profile.border_focus,
      boxShadow: '0 0 0 0.2rem ' + customTheme.colors.profile.box_shadow
    }
  }
}))
