import React from 'react'

/** Material UI **/
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 50,
    font: 'Rubik',
    fontWeight: 400,
    textAlign: 'start',
    paddingTop: '40px',
    lineHeight: '40px',
    marginLeft: '2rem',
    marginRight: '2rem'
  },
  ad: {
    color: 'gray'
  },
  subText: {
    fontSize: 14,
    textAlign: 'justify',
    lineHeight: '17px',
    margin: '0 2rem'
  }
}))

const LandingSetupHeader = () => {
  const classes = useStyles()
  return (
    <Box>
      <h2 className={classes.title}>
        CONNECT<span className={classes.ad}>AD</span> PLATFORM
      </h2>
      <p className={classes.subText}>
        Tell us more about you and your company so we can get you connected!
        After creating your account you can come back any time to finish setting
        up your company. Work on getting your company to be 100% compliant so
        you can begin receiving work immediately.
      </p>
    </Box>
  )
}

export default LandingSetupHeader
