/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

// mui components
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import {
  Box,
  FormLabel,
  Grid,
  Grow,
  ThemeProvider,
  Button,
  Divider,
  Typography
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@material-ui/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { RepairData } from './RepairData'
import { useDispatch, useSelector } from 'react-redux'
import { EtaSelect } from './EtaSelect'
// eslint-disable-next-line
import { createLog, getScreenConfigOnline } from '../../services/ApiService'
// eslint-disable-next-line
import {
  getWoByIdWithAuth,
  uploadConstructionForm,
  uploadRepair,
  uploadResource
} from '../../lib/Api'
import {
  buttonSettingsDisabled,
  disableButtonWOStyle,
  enableButtonWOStyle
} from '../../styles/mui_custom_theme'
import { cloneDeep } from 'lodash'
import {
  woFixedStatus,
  workCompleteOptions,
  workorderCompletionType,
  woStatus,
  woLogStatus,
  woLogType,
  S3_BASE_URL
} from '../../lib/Constants'
import {
  getContentTypeFromBase64,
  getFileExtensionFromMimeType,
  getWOstatus,
  isBase64Data,
  isRunningInIframe
} from '../../lib/Global'
import { FieldServiceForm } from './FieldServiceForm'
import { LineItems } from './LineItems'
import { ConfirmDialog } from '../form/ConfirmDialog'
import GlobalSelect from '../form/Select'
import { DateTimeFilter } from './ReturnVisitDateTimeFilter'
import { store } from '../../store'
import { authActions } from '../../store/signIn'
import { NotesComponent } from './repair/NotesComponent'
import md5 from 'md5'
import { loadingActions } from '../../store/loading'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 345,
    padding: '0px 0px',
    boxShadow: '6px 9px 43px ' + theme.colors.workOrders.detailsCardBorderColor,
    borderRadius: '8px',
    marginBottom: '5px'
  },
  actions: {
    cursor: 'pointer',
    height: '68px',
    padding: '0px 0px'
  },
  chipRoot: {
    marginRight: 'auto',
    fontSize: '12px',
    alignSelf: 'center',
    minWidth: 'fit-content',
    paddingLeft: '10px'
  },
  trip: {
    padding: '1px 0px',
    marginLeft: '20px',
    marginRight: '10px',
    color: theme.colors.text,
    fontSize: '18px',
    fontWeight: '500',
    alignSelf: 'center'
  },
  field: {
    marginLeft: '5px',
    marginBottom: '4px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  fieldData: {
    marginLeft: '5px',
    marginBottom: '28px',
    color: theme.colors.workOrders.counts,
    fontSize: '14px',
    fontWeight: '400'
  },
  fieldDisabledData: {
    marginLeft: '5px',
    marginBottom: '28px',
    color: theme.colors.textGray,
    fontSize: '14px',
    fontWeight: '400'
  },
  fieldMessage: {
    marginLeft: '5px',
    marginBottom: '28px',
    color: theme.colors.workOrders.downloadIcon,
    fontSize: '14px',
    fontWeight: '400'
  },
  empty: {
    color: theme.colors.workOrders.downloadIcon,
    fontSize: '14px',
    fontWeight: '400',
    textAlign: 'center',
    marginBottom: '10px'
  },
  tripGrid: {
    display: 'flex',
    flexDirection: 'row',
    verticalAlign: 'middle',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  statusChip: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 'auto'
  },
  error: {
    color: theme.colors.errorColor,
    fontSize: '12px',
    fontWeight: '700',
    marginBottom: '10px'
  },
  errorContainer: {
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '70px'
  },
  errorBox: {
    marginBottom: '20px'
  },
  serviceType: { display: 'flex', alignItems: 'center', marginBottom: '28px' },
  divider: { flex: 1, marginRight: '20px', marginLeft: '20px' },
  serviceTypeTitle: {
    fontWeight: '700',
    fontSize: '14px'
  },
  dialogMessage: {
    fontSize: '18px',
    fontWeight: 400,
    marginBottom: '12px'
  },
  dialogTitle: {
    fontWeight: 700,
    fontSize: '24px'
  },
  dialogConfirmation: {
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: '14px'
  },
  dialogReturn: {
    fontWeight: 600,
    fontSize: '16px',
    color: theme.colors.grey,
    marginBottom: '14px'
  },
  dialogReturnDate: {
    fontWeight: 600,
    fontSize: '22px',
    color: theme.colors.text,
    marginBottom: '14px'
  },
  dialogReturnDateTop: {
    fontWeight: 600,
    fontSize: '22px',
    color: theme.colors.text
  }
}))

const customStyles = makeStyles(theme => ({
  buttonsContainer: {
    justifyContent: 'flex-end',
    gap: '20px',
    marginRight: '17px'
  }
}))

export const ActivitiesCard = props => {
  const {
    data,
    setPhotos,
    setPhotoIndex,
    updateWoData,
    setReady,
    type,
    getWindowHeight,
    externalUser,
    setMessage,
    handleRefetch,
    setFinished,
    etaTime,
    disableSubmit
  } = props
  const wHeight = getWindowHeight ? getWindowHeight() : null
  const classes = useStyles()
  const customClasses = customStyles()
  const [expanded, setExpanded] = useState(false)
  const [pendingTasks, setPendingTasks] = useState(false)
  const [mandatoryComplete, setMandatoryComplete] = useState(false)
  const [newRepair, setNewRepair] = useState({ ...props.tempData })
  const { t } = useTranslation()
  const configurations = useSelector(
    state => state.auth?.user?.userInfo?.configurations
  )
  const currentUser = useSelector(state => state.auth.user)
  const userData = useSelector(state => state.auth?.user?.userInfo)
  const serviceTypeOptions =
    useSelector(state => state.auth?.user?.userInfo?.service_types) ?? []
  const [configs, setConfigs] = useState(null)
  const [configRequired, setConfigRequired] = useState(false)
  const [screenConfigId, setScreenConfigId] = useState()
  const [hasConfigV2, setHasConfigV2] = useState()
  const [infoSubmitted, setInfoSubmitted] = useState(false)
  const [open, setOpen] = useState(false)
  const [typeKey, setTypeKey] = useState()
  const [formData, setFormData] = useState({})
  const [confirmDailogKey, setConfirmDailogKey] = useState(0)
  const [returnReason, setReturnReason] = useState('')
  const [submitClicked, setSubmitClicked] = useState(false)
  const dispatch = useDispatch()

  const initConfig = onlineScreenConfig => {
    const category = data?.category?.toLowerCase()
    let eq_services = null
    let screen_configs = null
    let schema_version = null
    if (type === 'iframe') {
      eq_services = externalUser.configurations.wo_action?.eq_services
      schema_version = externalUser.configurations?.schema_version
      screen_configs = externalUser.configurations.wo_action?.screen_configs
    } else {
      eq_services = configurations.wo_action?.eq_services
      schema_version = configurations?.schema_version
      screen_configs = configurations.wo_action?.screen_configs
    }
    // check configs V2
    let configId = null
    if (
      (schema_version === 'v2' &&
        !(
          data?.service?.[0]?.screen_config_id === null &&
          data?.status === 'completed'
        )) ||
      (typeof data?.service?.[0]?.screen_config_id !== 'undefined' &&
        data?.service?.[0]?.screen_config_id !== null)
    ) {
      configId = data?.service ? data?.service?.[0]?.screen_config_id : null
      setScreenConfigId(configId)
    } else {
      setScreenConfigId(null)
    }

    let configsTmp = eq_services?.default
    // check screen configs
    if (
      (typeof data?.service?.[0]?.screen_config_id !== 'undefined' &&
        data?.service?.[0]?.screen_config_id !== null) ||
      (schema_version === 'v2' &&
        !(
          (typeof data?.service?.[0]?.screen_config_id === 'undefined' ||
            data?.service?.[0]?.screen_config_id === null) &&
          data?.status === 'completed'
        ))
    ) {
      if (configId) {
        configsTmp = screen_configs.find(config => config.id === configId)
      } else if (
        (typeof data?.service?.[0]?.screen_config_id === 'undefined' ||
          data?.service?.[0]?.screen_config_id === null) &&
        data?.status === 'completed'
      ) {
        configsTmp = eq_services[category]
      } else {
        configsTmp = onlineScreenConfig
        setScreenConfigId(configsTmp.id)
      }
    } else if (
      eq_services[category] &&
      Object.keys(eq_services[category]).length > 0
    ) {
      configsTmp = eq_services[category]
    }
    const service_type = configsTmp?.service_type ?? null
    const multiple_service = configsTmp?.multiple_service ?? null
    if (service_type) {
      const { config_required, options } = service_type
      if (config_required) {
        // Set the new object structure
        setConfigRequired(true)
        const objectOptions = {}
        if (options.length) {
          for (const key of options) {
            objectOptions[key] = eq_services[key]
          }
          configsTmp = {
            service_type,
            multiple_service,
            object_options: objectOptions
          }
        }
      }
    }

    // Set repairs for the pre render functionality
    if (
      (!data.service || data.service.length === 0) &&
      multiple_service?.pre_render
    ) {
      const arrayOfRepairs = []
      for (const service of service_type.options) {
        const repair = {
          work_order_id: data?.id,
          type: 'service',
          data: {
            service_type: service
          },
          user_id: (type === 'iframe' ? externalUser : userData).id,
          external_id: data.external_id,
          access: ['adftc'],
          status: 'active',
          date_created: Math.round(Date.now() / 1000)
        }
        arrayOfRepairs.push(repair)
      }
      data.service = arrayOfRepairs
    }
    setConfigs(configsTmp)
    if (
      schema_version === 'v2' &&
      onlineScreenConfig &&
      typeof data?.service?.[0]?.screen_config_id !== 'undefined'
    ) {
      localizeScreenConfig(screen_configs, configsTmp)
    }
  }

  useEffect(() => {
    init()
  }, [data])

  const _getScreenConfigOnline = async woData => {
    const trade = woData.category ?? 'default'
    const service = woData.services?.[0]?.name ?? 'default'
    const workType = woData.call_type ?? 'default'
    let configId = null
    if (type === 'iframe') {
      configId = externalUser.config_id
    } else {
      configId = userData.config_id
    }
    const config = await getScreenConfigOnline(
      configId,
      trade,
      service,
      workType,
      type === 'iframe'
    )
    return config
  }

  const init = async () => {
    // let schema_version = null
    let onlineScreenConfig = null
    // always check for available configs
    onlineScreenConfig = await _getScreenConfigOnline(data)
    initConfig(onlineScreenConfig)
  }

  useEffect(() => {
    setFormData(data)
  }, [data])

  useEffect(() => {
    if (props.index === 0) {
      setExpanded(true)
    }
  }, [props])

  useEffect(() => {
    props.setTempData(newRepair)
    if (!configs) return
    _setPendingTasks()
  }, [data, newRepair, configs])

  useEffect(() => {
    // determine V2 configs for dynamic form render
    setHasConfigV2(
      typeof screenConfigId !== 'undefined' && screenConfigId !== null
    )
  }, [screenConfigId])

  const handleExpandClick = ind => {
    setExpanded(!expanded)
  }

  const localizeScreenConfig = (screenConfigs, onlineScreenConfig) => {
    const foundConfig = screenConfigs?.find(
      sConfig => sConfig.id === onlineScreenConfig.id
    )
    if (!foundConfig && configs !== null) {
      const newScreenConfigs = [...screenConfigs, configs]
      const newConfigurations = { ...configurations }
      newConfigurations.wo_action.screen_configs = newScreenConfigs
      store.dispatch(
        authActions.setUser({
          ...currentUser,
          userInfo: {
            ...userData,
            configurations: newConfigurations
          }
        })
      )
    }
  }

  const noCheckInComp = () => (
    <Box marginBottom={'20px'}>
      <FormLabel component="legend" classes={{ root: classes.field }}>
        {t('work_orders.trips.checkin')}
      </FormLabel>
      <FormLabel
        component="legend"
        classes={{ root: classes.fieldDisabledData }}
      >
        {t('work_orders.trips.no_checkin')}
      </FormLabel>
      <FormLabel component="legend" classes={{ root: classes.field }}>
        {t('work_orders.trips.checkout')}
      </FormLabel>
      <FormLabel
        component="legend"
        classes={{ root: classes.fieldDisabledData }}
      >
        {t('general.labels.not_available')}
      </FormLabel>
    </Box>
  )

  const _setPendingTasks = () => {
    let pendingServices = 0
    if (!data?.service || data.service.length === 0) {
      pendingServices += _checkPendingServices(configs, newRepair)
    } else {
      for (let i = 0; i < data?.service.length; i++) {
        pendingServices += _checkPendingServices(configs, data?.service[i])
      }
    }
    setPendingTasks(pendingServices)
    return pendingServices
  }

  const validWoTasks = workOrder => {
    // If WO doesn't have any tasks return false
    let tasks = []
    if (workOrder.services && workOrder.services.length > 0) {
      tasks = workOrder.services.filter(service => {
        return (
          service.tasks &&
          Array.isArray(service.tasks) &&
          service.tasks.length > 0
        )
      })
    }

    return !(!tasks || tasks.length < 1)
  }

  const checkPhotoRequirements = (configs, repairData) => {
    let hasAll = true
    const newFormat =
      configs.titles?.options && typeof configs.titles?.options[0] === 'object'
    if (newFormat && configs.titles?.unique) {
      const mandatoryTitles = configs.titles.options
        .filter(title => title.mandatory)
        .map(title => title.title)
      let availableTitles = 0
      for (let i = 0; i < configs.max; i++) {
        if (
          !!repairData[`photo_${i}_title`] &&
          mandatoryTitles.includes(repairData[`photo_${i}_title`])
        ) {
          availableTitles++
        }
      }
      if (availableTitles < configs.min) hasAll = false
    } else {
      for (let i = 0; i < configs.min; i++) {
        if (!repairData[`photo_${i}`]) hasAll = false
      }
    }

    return hasAll
  }

  const _checkPendingServices = (servicesConfig, repair) => {
    let count = 0
    let serviceForCheck = cloneDeep(servicesConfig)
    const { service_type, object_options } = servicesConfig
    if (service_type?.config_required && object_options) {
      const { data } = repair
      const serviceType = data?.service_type
        ? object_options[data.service_type]
        : null
      if (serviceType) serviceForCheck = cloneDeep(serviceType)
    }
    for (const service in serviceForCheck) {
      switch (true) {
        case service.includes('photos_before'):
        case service.includes('photos_after'):
        case service.includes('additional_photos'): {
          if (serviceForCheck[service].min < 1) break

          if (
            !repair.data ||
            !repair.data[service] ||
            Object.keys(repair.data[service]).length < 1
          ) {
            count++
            continue
          }

          const hasAll = checkPhotoRequirements(
            serviceForCheck[service],
            repair.data[service]
          )
          if (hasAll === false) count++
          break
        }
        case service.includes('signature'):
          if (!serviceForCheck[service].mandatory) continue
          if (repair?.data && !repair.data[service]?.image) count++
          if (
            repair?.data &&
            serviceForCheck[service].print_name_mandatory &&
            !repair.data[service]?.name
          ) {
            count++
          }
          break
        case service.includes('tasks'):
        case service.includes('cost_code_materials'): {
          // If WO doesn't have any tasks don't do anything
          if (!validWoTasks(data)) continue
          if (!data.services || data.services.length < 1) {
            continue
          }
          if (!serviceForCheck[service].mandatory) continue
          let tasksPending = false
          if (!repair.data?.services || repair.data.services.length < 1) {
            tasksPending = true
          } else {
            repair.data.services.forEach(repairService => {
              switch (service) {
                case 'tasks':
                  if (!repairService.tasks || repairService.tasks.length < 1) {
                    tasksPending = true
                  }
                  break
                case 'cost_code_materials':
                  repairService.tasks.forEach(task => {
                    if (task.daily.length < 1) tasksPending = true
                  })
                  break
              }
            })
          }

          if (tasksPending) count++
          break
        }
        case service.includes('parts'): {
          const partsOptions =
            (type === 'iframe' ? externalUser : userData)?.parts_options || {}
          const validateParts =
            (serviceForCheck[service]?.options?.length > 0 &&
              Object.keys(partsOptions)?.length > 0) ||
            false
          const inventoryParts = serviceForCheck[service]?.inventory || false
          let incompletePartDescription = false
          let incompletePartOption = false

          if (repair?.data && repair.data.parts?.length) {
            repair.data.parts.forEach(part => {
              if (!incompletePartDescription && !part.description) {
                incompletePartDescription = true
              }
              if (!incompletePartOption && !part.location_option) {
                incompletePartOption = true
              }
            })
          }

          if (incompletePartDescription && !inventoryParts) count++
          if (incompletePartOption && validateParts) count++
          break
        }
        case service.includes('service_type'):
          if (
            repair?.data &&
            serviceForCheck[service]?.mandatory &&
            !repair.data[service]
          ) {
            count++
          }
          break
        case service.includes('labor'): {
          for (const laborConfig in serviceForCheck[service]) {
            if (serviceForCheck[service][laborConfig].mandatory) {
              if (!repair.data.labor || !repair.data.labor[laborConfig]) {
                count++
              }
            }
          }
          break
        }
        case service.includes('picker'):
        case service.includes('numeric'):
        case service.includes('notes'):
          if (
            repair?.data &&
            serviceForCheck[service]?.mandatory &&
            !repair?.data[service]
          ) {
            count++
          }
          break
      }
    }
    return count
  }

  const getPhotoQuestions = () => {
    const photoQuestion = []
    configs.configurations.forEach(config => {
      config.page_questions.forEach(pageQuestion => {
        const sectionRoot = Object.keys(pageQuestion)[0]
        const questionElement = pageQuestion[sectionRoot].questions
        questionElement.map(q => {
          if (
            q[Object.keys(q)[0]]?.type === 'photos' ||
            q[Object.keys(q)[0]]?.type === 'signature' ||
            q[Object.keys(q)[0]]?.type === 'attachments'
          ) {
            photoQuestion.push({
              questionId: Object.keys(q)[0],
              section: sectionRoot,
              repairPhotoKey: `${sectionRoot}|${Object.keys(q)[0]}`
            })
          }
          return true
        })
      })
    })
    return photoQuestion
  }

  const onDemandWO = !formData.line_items?.length && !formData.non_project

  const nonProjectWO = formData.non_project

  const clockOutConfigOptions = useSelector(state => {
    const clockOutOptions =
      type === 'iframe'
        ? externalUser?.configurations?.wo_action?.wo_logs?.clock_out?.options
        : state.auth?.user?.userInfo?.configurations?.wo_action?.wo_logs
            ?.clock_out?.options

    if (clockOutOptions) {
      const trade = formData.trade?.toLowerCase()
      const defaultOptions = clockOutOptions.default?.options || []

      if (trade && clockOutOptions[trade]?.options) {
        return clockOutOptions[trade].options
      } else {
        return defaultOptions
      }
    }
  })

  const hasCalendar = useSelector(state => {
    if (type === 'iframe') {
      return externalUser?.configurations?.wo_action?.wo_logs?.clock_out
        ?.multipass?.default?.calendar
    } else {
      return state.auth?.user?.userInfo?.configurations?.wo_action?.wo_logs
        ?.clock_out?.multipass?.default?.calendar
    }
  })

  const returnVisitConfigOptions = useSelector(state => {
    if (type === 'iframe') {
      return externalUser?.configurations?.wo_action?.wo_logs?.clock_out
        ?.multipass?.default?.options
    } else {
      return state.auth?.user?.userInfo?.configurations?.wo_action?.wo_logs
        ?.clock_out?.multipass?.default?.options
    }
  })
  const transformedReturnVisitOptions = Object.entries(
    returnVisitConfigOptions || {}
  ).map(([key, value]) => ({
    value: key,
    label: value
  }))

  const hasBypassValidation = clockOutConfigOptions?.some(
    option => option.bypass_validation === true
  )

  const configOndemandOptions = (clockOutConfigOptions || [])
    .filter(option => {
      if (onDemandWO) {
        return option.label !== 'Needs Re-Scheduled'
      } else if (nonProjectWO) {
        return true
      }
      return false
    })
    .map(option => ({
      ...option,
      value: option.label
    }))

  const [completeDailogValue, setCompleteDailogValue] = useState(
    configOndemandOptions[0]
  )

  const defaultReturnVisitOption =
    transformedReturnVisitOptions.length > 0
      ? transformedReturnVisitOptions[0].value
      : null

  const [selectedReturnVisitOption, setSelectedReturnVisitOption] = useState(
    defaultReturnVisitOption
  )

  useEffect(() => {
    if (configOndemandOptions.length > 0) {
      setCompleteDailogValue(prevValue => {
        return prevValue &&
          configOndemandOptions.some(option => option.value === prevValue.value)
          ? prevValue
          : configOndemandOptions[0]
      })
    }
  }, [configOndemandOptions])

  const uploadSingleResource = async (uri, path) => {
    if (isBase64Data(uri)) {
      const contentType = getContentTypeFromBase64(uri)
      const url = await uploadResource(uri, path, contentType)
      return {
        url,
        baseurl: S3_BASE_URL,
        is_file: true,
        file_extension: getFileExtensionFromMimeType(contentType),
        mime_type: contentType
      }
    }
    return null
  }

  const processRepairResources = async repairData => {
    const processedData = { ...repairData }

    const photoQuestions = getPhotoQuestions()
    const woId = processedData.work_order_id
    const failedUploads = []

    const uploadPhoto = async photo => {
      const repairFolder = photo.is_file
        ? 'repair_equipment_files'
        : 'repair_equipment'
      const prefixName = photo.is_file ? 'file_' : 'photo_'
      const fileExt = photo.is_file ? photo.file_extension : 'png'
      const uid = md5(photo.uri)
      const path = `work_orders/${woId}/${repairFolder}/${prefixName}${uid}.${fileExt}`
      setReady(false)
      setMessage(t('work_orders.upload_messages.resources'))
      const uploadedResource = await uploadSingleResource(photo.uri, path)
      if (uploadedResource) {
        delete photo.uri
        return {
          ...photo,
          url: path,
          baseurl: S3_BASE_URL
        }
      }
      throw new Error('Upload failed')
    }

    if (processedData.data) {
      const repairPhotos = {}

      for (const question of photoQuestions) {
        const { section, questionId, repairPhotoKey } = question
        if (
          processedData.data[section] &&
          processedData.data[section][questionId]
        ) {
          const photos = processedData.data[section][questionId]
          repairPhotos[repairPhotoKey] = []

          for (let i = 0; i < photos.length; i++) {
            const photo = photos[i]
            if (photo.uri) {
              try {
                const uploadedPhoto = await uploadPhoto(photo)
                repairPhotos[repairPhotoKey].push(uploadedPhoto)
              } catch (error) {
                console.error(`Failed to upload resource: ${error.message}`)
                failedUploads.push({ photo, repairPhotoKey })
              }
            } else {
              repairPhotos[repairPhotoKey].push(photo)
            }
          }

          // Remove the original photo data from the section
          delete processedData.data[section][questionId]
        }
      }

      // Retry failed uploads
      let retryAttempts = 3
      while (failedUploads.length > 0 && retryAttempts > 0) {
        console.log(
          `Retrying failed uploads. Attempt ${4 - retryAttempts} of 3`
        )
        const failedUploadsCopy = [...failedUploads]
        failedUploads.length = 0

        for (const failedUpload of failedUploadsCopy) {
          try {
            const uploadedPhoto = await uploadPhoto(failedUpload.photo)
            repairPhotos[failedUpload.repairPhotoKey].push(uploadedPhoto)
          } catch (error) {
            console.error(`Retry failed for resource: ${error.message}`)
            failedUploads.push(failedUpload)
          }
        }

        retryAttempts--
      }

      // Add the processed repair_photos to the data
      processedData.data.repair_photos = repairPhotos
    }

    return processedData
  }

  const handleSubmit = async () => {
    dispatch(loadingActions.show())
    setSubmitClicked(true)
    if (onDemandWO || nonProjectWO) {
      setOpen(false)
    }
    let submitData = {}
    if (hasConfigV2) {
      // build repair object with new configs
      submitData = { ...newRepair, screen_config_id: configs.id }
      submitData = await processRepairResources(submitData)
    } else {
      submitData = { ...newRepair }
    }
    setReady(false)
    const checkOutLogData = {}
    let checkInLog = false
    if (!(data.logs && data?.logs?.length > 0) || data?.status === 'open') {
      setMessage(t('work_orders.upload_messages.clock_in'))
      // Check into the WO
      const response = await createLog(
        'checkIn',
        data?.id,
        type === 'iframe' ? externalUser : userData,
        null,
        type === 'iframe'
      )
      data.logs.push(response)
      checkOutLogData.id = response.checkout_log_id
      checkOutLogData.timeZone = response.timeZone
      checkOutLogData.date_created = response.date_created

      if (response?.type === 'checkIn' && response?.status === 'complete') {
        checkInLog = true
      }
    } else {
      checkInLog = true
    }
    // check response
    if (checkInLog) {
      if (!data?.service || data.service.length === 0) {
        data.service = [{ ...submitData }]
      } else {
        data.service.push({ ...submitData })
      }

      for (let i = 0; i < data?.service.length || 0; i++) {
        setMessage(
          data.service[i].data?.service_type &&
            serviceTypeOptions[data.service[i].data.service_type]
            ? t('work_orders.upload_messages.repair').replace(
                '{v1}',
                // Make sure the Form word is not repeated
                serviceTypeOptions[data.service[i].data.service_type].replace(
                  ' Form',
                  ''
                )
              )
            : t('work_orders.field_service')
        )
        const response = await uploadRepair(
          data.service[i],
          type === 'iframe',
          configs.id
        )
        data.service[i] = response
      }

      // Check out the WO
      const existingLog = data?.logs?.filter(
        log => log.type === 'checkOut' && log.status === 'incomplete'
      )

      // Prepare log data
      const logData =
        completeDailogValue?.label === 'Work Complete'
          ? { ...checkOutLogData }
          : { ...checkOutLogData, clockout_option: completeDailogValue?.label }

      setMessage(t('work_orders.upload_messages.clock_out'))
      let checkOutLog = false
      if (existingLog.length > 0) {
        const newLog = await createLog(
          'checkOut',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          existingLog[0],
          type === 'iframe'
        )
        data.logs = data?.logs?.map(log => {
          if (log.id === newLog.id) return newLog
          return log
        })
        checkOutLog = newLog.status === 'complete' && newLog.type === 'checkOut'
      } else {
        const response = await createLog(
          'checkOut',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          logData,
          type === 'iframe'
        )
        data.logs.push(response)
        checkOutLog =
          response.status === 'complete' && response.type === 'checkOut'
      }
      data.status = 'completed'
      if (checkOutLog) {
        // Download WO with invoice
        const updatedWo = await getWoByIdWithAuth(
          type === 'iframe' || isRunningInIframe(),
          data.id
        )
        updateWoData(updatedWo)
        if (type === 'iframe') {
          setFinished(true)
        }
        setReady(true)
        setMessage(null)
      }
    }
    dispatch(loadingActions.hide())
  }

  const handleMCSSubmit = () => {
    setTypeKey(workorderCompletionType.COMPLETED)
    setOpen(true)
  }

  const getDialogTitle = type => {
    switch (type) {
      case workorderCompletionType.COMPLETED:
        return (
          <Typography className={classes.dialogTitle}>
            {t('work_orders.construction_form.work_complete')}
          </Typography>
        )
      case workorderCompletionType.CONFIRMATION:
        return (
          <Typography className={classes.dialogTitle}>
            {t('work_orders.construction_form.confirmation')}
          </Typography>
        )
      case workorderCompletionType.RETURN_VISIT:
        return (
          <Typography className={classes.dialogTitle}>
            {t('work_orders.construction_form.return_on')}
          </Typography>
        )
      case workorderCompletionType.RETURN_VISIT_2:
        return (
          <Typography className={classes.dialogTitle}>
            {t('work_orders.construction_form.ending_on')}
          </Typography>
        )
      case workorderCompletionType.RETURN_VISIT_CONFIRMATION:
        return (
          <Typography className={classes.dialogTitle}>
            {t('work_orders.construction_form.confirmation')}
          </Typography>
        )
      case workorderCompletionType.COMPLETE_FORM:
        return (
          <Typography className={classes.dialogTitle}>
            {t('work_orders.complete_form')}
          </Typography>
        )
    }
  }

  const handleInputChange = event => {
    setReturnReason(event)
  }

  const getDialogMessage = type => {
    switch (type) {
      case workorderCompletionType.COMPLETED:
        return (
          <>
            <Typography className={classes.dialogMessage}>
              {t('work_orders.construction_form.if_work_not_complete')}
            </Typography>
            <GlobalSelect
              options={
                onDemandWO || nonProjectWO
                  ? configOndemandOptions
                  : workCompleteOptions
              }
              onChange={selectedValue => {
                setCompleteDailogValue(selectedValue)
              }}
              field="work_complete"
              value={completeDailogValue?.value}
              returnFullOption
              smallScreenOverride
            />
          </>
        )
      case workorderCompletionType.CONFIRMATION:
        return (
          <>
            <Typography className={classes.dialogMessage}>
              {t('work_orders.construction_form.confirm_work_complete')}
            </Typography>
          </>
        )
      case workorderCompletionType.RETURN_VISIT:
        return hasCalendar ? (
          <DateTimeFilter
            id={'start'}
            values={formData.return_time}
            type={2}
            setValues={e => {
              setFormData(prev => {
                return { ...prev, return_time: e }
              })
            }}
            filterContent={() => null}
          />
        ) : (
          <GlobalSelect
            options={transformedReturnVisitOptions}
            onChange={handleReturnVisitChange}
            value={selectedReturnVisitOption}
            displayvalue
            smallScreenOverride
          />
        )
      case workorderCompletionType.RETURN_VISIT_2:
        return (
          <DateTimeFilter
            id={'end'}
            defaultDate={moment(new Date(formData.return_time))
              .add(1, 'day')
              .format(t('general.format.short_date_time'))}
            minDate={formData.return_time}
            values={formData.return_time_end}
            type={2}
            setValues={e => {
              setFormData(prev => {
                return { ...prev, return_time_end: e }
              })
            }}
            filterContent={() => null}
          />
        )
      case workorderCompletionType.RETURN_VISIT_CONFIRMATION:
        return (
          <>
            <Typography className={classes.dialogReturn}>
              {t('work_orders.construction_form.return_on')}
            </Typography>
            <Typography className={classes.dialogReturnDateTop}>
              {moment(formData.return_time).format('dddd, MMMM Do')}
            </Typography>
            <Typography className={classes.dialogReturnDate}>
              {moment(formData.return_time).format('[at] h:mm A')}
            </Typography>
            <Typography className={classes.dialogReturn}>
              {t('work_orders.construction_form.ending_on')}
            </Typography>
            <Typography className={classes.dialogReturnDateTop}>
              {moment(formData.return_time_end).format('dddd, MMMM Do')}
            </Typography>
            <Typography className={classes.dialogReturnDate}>
              {moment(formData.return_time_end).format('[at] h:mm A')}
            </Typography>
            <Typography className={classes.dialogReturn}>
              {t('work_orders.reason')}
            </Typography>
            <NotesComponent
              alwaysExpanded
              hideClearIcon
              notes={returnReason}
              onUpdate={handleInputChange}
            />
          </>
        )
      case workorderCompletionType.COMPLETE_FORM:
        return (
          <>
            <Typography className={classes.dialogMessage}>
              {t('work_orders.complete_all_required')}
            </Typography>
          </>
        )
    }
  }

  const getCancelLabel = type => {
    switch (type) {
      case workorderCompletionType.RETURN_VISIT_2:
        return t('general.labels.back')
      case workorderCompletionType.RETURN_VISIT_CONFIRMATION:
        return t('general.labels.back')
      default:
        return t('general.labels.cancel')
    }
  }

  const getAcceptLabel = type => {
    switch (type) {
      case workorderCompletionType.RETURN_VISIT_CONFIRMATION:
        return t('general.labels.confirm')
      case workorderCompletionType.COMPLETED:
        return t('general.labels.continue')
      case workorderCompletionType.CONFIRMATION:
        return t('general.labels.yes')
      default:
        return t('general.labels.next')
    }
  }

  const handleOpenPhotos = (ind, photos) => {
    setPhotos(photos)
    setPhotoIndex(ind)
  }

  const handleReturnVisitConfirmation = async () => {
    try {
      setReady(false)
      setOpen(false)
      const checkOutLogData = {}
      if (!data.logs || data?.status === woStatus.OPEN) {
        setMessage(t('work_orders.upload_messages.clock_in'))
        // Check into the WO
        const response = await createLog(
          'checkIn',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          null,
          type === 'iframe'
        )
        data.logs.push(response)
        checkOutLogData.id = response.checkout_log_id
        checkOutLogData.timeZone = response.timeZone
        checkOutLogData.date_created = response.date_created
      }
      for (let i = 0; i < data?.line_items.length; i++) {
        setMessage(
          t('work_orders.construction_form.uploading_consttruction_form')
        )
        const line_item = {
          ...data?.line_items[i],
          status: {
            ...data?.line_items[i].status,
            user_id: userData?.id,
            date: Math.round(Date.now() / 1000),
            firstName: userData?.firstName,
            lastName: userData?.lastName
          }
        }
        const body = {
          work_order_id: data?.id,
          type: 'scope_of_work',
          external_id: data?.line_items[i]?.id,
          data: {
            additional_photos: data?.line_items[i]?.additional_photos,
            line_item,
            notes: data?.line_items[i]?.notes
          },
          user_id: userData?.id,
          wo_external_id: data?.external_id,
          status: 'active',
          date_created: Math.round(Date.now() / 1000),
          check_out_log_id: checkOutLogData?.id
        }
        delete body?.data.line_item?.additional_photos
        await uploadConstructionForm(body, type === 'iframe')
      }
      // check out WO
      const existingLog = data?.logs?.filter(
        log =>
          log.type === woLogType.CHECKOUT &&
          log.status === woLogStatus.incomplete
      )
      setMessage(t('work_orders.upload_messages.clock_out'))
      let checkOutLog = false
      if (existingLog.lenght > 0) {
        const newLog = await createLog(
          'checkOut',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          existingLog[0],
          type === 'iframe'
        )
        data.logs = data?.logs.map(log => {
          if (log.id === newLog.id) return newLog
          return log
        })
        checkOutLog =
          newLog.status === woLogStatus.complete &&
          newLog.type === woLogType.CHECKOUT
      } else {
        const response = await createLog(
          'checkOut',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          {
            ...checkOutLogData,
            return_time: Math.floor(formData?.return_time / 1000),
            return_time_end: Math.floor(formData?.return_time_end / 1000),
            return_visit: '#',
            clockout_option: 'Return visit required',
            return_reason: returnReason
          },
          type === 'iframe'
        )
        data.logs.push(response)
        checkOutLog =
          response.status === woLogStatus.complete &&
          response.type === woLogType.CHECKOUT
      }
      data.status = woStatus.COMPLETED
      if (checkOutLog) {
        // Download WO with invoice
        const updatedWo = await getWoByIdWithAuth(
          type === 'iframe' || isRunningInIframe(),
          data.id
        )
        updateWoData(updatedWo)
        setReady(true)
      }
      await handleRefetch()
      setMessage(null)
    } catch (error) {
      console.error('ERROR: ', error)
    }
  }

  const handleReturnVisitChange = selectedValue => {
    setSelectedReturnVisitOption(selectedValue)
  }

  const isCompleteFormType = typeKey === workorderCompletionType.COMPLETE_FORM

  const project = formData.line_items?.length > 0 && !formData.scheduled

  const handleComplete = () => {
    if (
      !project &&
      !completeDailogValue?.bypass_validation &&
      (hasConfigV2 ? !mandatoryComplete : pendingTasks > 0)
    ) {
      setTypeKey(workorderCompletionType.COMPLETE_FORM)
    } else {
      if (completeDailogValue?.case === 'complete') {
        setTypeKey(workorderCompletionType.CONFIRMATION)
      } else if (completeDailogValue?.case === 'null') {
        handleSubmit()
      } else {
        setTypeKey(workorderCompletionType.RETURN_VISIT)
      }
    }
    // refresh the component
    setConfirmDailogKey(confirmDailogKey + 1)
  }

  const handleConfirmationSubmit = async () => {
    try {
      setReady(false)
      setOpen(false)
      const checkOutLogData = {}
      setMessage(t('work_orders.upload_messages.clock_in'))

      if (!data.logs || data?.status === 'open') {
        setMessage(t('work_orders.upload_messages.clock_in'))
        // Check into the WO
        const response = await createLog(
          'checkIn',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          null,
          type === 'iframe'
        )
        data.logs.push(response)
        checkOutLogData.id = response.checkout_log_id
        checkOutLogData.timeZone = response.timeZone
        checkOutLogData.date_created = response.date_created
      }

      for (let i = 0; i < data?.line_items.length; i++) {
        setMessage(
          t('work_orders.construction_form.uploading_consttruction_form')
        )
        const line_item = {
          ...data?.line_items[i],
          status: {
            ...data?.line_items[i].status,
            user_id: userData?.id,
            date: Math.round(Date.now() / 1000),
            firstName: userData?.firstName,
            lastName: userData?.lastName
          }
        }

        const body = {
          work_order_id: data?.id,
          type: 'scope_of_work',
          external_id: data?.line_items[i]?.id,
          data: {
            additional_photos: data?.line_items[i]?.additional_photos,
            line_item,
            notes: data?.line_items[i]?.notes
          },
          user_id: userData?.id,
          wo_external_id: data?.external_id,
          status: 'active',
          date_created: Math.round(Date.now() / 1000),
          check_out_log_id: checkOutLogData?.id
        }

        delete body?.data.line_item?.additional_photos
        await uploadConstructionForm(body, type === 'iframe')
      }

      // check out WO
      const existingLog = data?.logs?.filter(
        log =>
          log.type === woLogType.CHECKOUT &&
          log.status === woLogStatus.incomplete
      )

      setMessage(t('work_orders.upload_messages.clock_out'))

      let checkOutLog = false
      if (existingLog.lenght > 0) {
        const newLog = await createLog(
          'checkOut',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          existingLog[0],
          type === 'iframe'
        )
        data.logs = data?.logs.map(log => {
          if (log.id === newLog.id) return newLog
          return log
        })
        checkOutLog =
          newLog.status === woLogStatus.complete &&
          newLog.type === woLogType.CHECKOUT
      } else {
        const response = await createLog(
          'checkOut',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          checkOutLogData,
          type === 'iframe'
        )
        data.logs.push(response)
        checkOutLog =
          response.status === woLogStatus.complete &&
          response.type === woLogType.CHECKOUT
      }
      data.status = woStatus.COMPLETED
      if (checkOutLog) {
        // Download WO with invoice
        const updatedWo = await getWoByIdWithAuth(
          type === 'iframe' || isRunningInIframe(),
          data.id
        )
        updateWoData(updatedWo)
        setReady(true)
      }
      await handleRefetch(true)
      setMessage(null)
    } catch (error) {
      console.error('ERROR: ', error)
    }
  }
  const handleOnDemandReturnVisitConfirmation = async () => {
    try {
      setOpen(false)
      let submitData = {}
      if (hasConfigV2) {
        // build repair object with new configs
        submitData = { ...newRepair, screen_config_id: configs.id }
        submitData.data = submitData.data || {}
        submitData.type = submitData.type || 'service'
        submitData.work_order_id = data.id
        const photoQuestions = getPhotoQuestions()
        const repairPhotos = {}
        if (photoQuestions.length > 0) {
          photoQuestions.map(question => {
            repairPhotos[question.repairPhotoKey] =
              newRepair.data?.[question.section]?.[question.questionId]
            if (
              Object.prototype.hasOwnProperty.call(
                submitData.data?.[question?.section] || {},
                question.questionId
              )
            ) {
              delete submitData.data?.[question.section]?.[question.questionId]
            }
            return true
          })
        }
        submitData.data.repair_photos = { ...repairPhotos }
      } else {
        submitData = { ...newRepair }
      }
      setReady(false)
      const checkOutLogData = {}
      let checkInLog = false
      if (
        !(data.logs && data?.logs?.length > 0) ||
        data?.status === woStatus.OPEN
      ) {
        setMessage(t('work_orders.upload_messages.clock_in'))
        // Check into the WO
        const response = await createLog(
          'checkIn',
          data?.id,
          type === 'iframe' ? externalUser : userData,
          null,
          type === 'iframe'
        )
        data.logs.push(response)
        checkOutLogData.id = response.checkout_log_id
        checkOutLogData.timeZone = response.timeZone
        checkOutLogData.date_created = response.date_created
        if (
          response?.type === woLogType.CHECKIN &&
          response?.status === woLogStatus.complete
        ) {
          checkInLog = true
        }
      } else {
        checkInLog = true
      }
      // check response
      if (checkInLog) {
        if (!data?.service || data.service.length === 0) {
          data.service = [{ ...submitData }]
        } else {
          data.service.push({ ...submitData })
        }

        for (let i = 0; i < data?.service.length || 0; i++) {
          setMessage(
            data.service[i].data?.service_type &&
              serviceTypeOptions[data.service[i].data.service_type]
              ? t('work_orders.upload_messages.repair').replace(
                  '{v1}',
                  // Make sure the Form word is not repeated
                  serviceTypeOptions[data.service[i].data.service_type].replace(
                    ' Form',
                    ''
                  )
                )
              : t('work_orders.field_service')
          )
          const response = await uploadRepair(
            data.service[i],
            type === 'iframe',
            configs.id
          )
          data.service[i] = response
        }

        // Check out the WO
        const existingLog = data?.logs?.filter(
          log =>
            log.type === woLogType.CHECKOUT &&
            log.status === woLogStatus.incomplete
        )

        // Prepare log data
        const logData = hasCalendar
          ? {
              ...checkOutLogData,
              return_time: Math.floor(formData?.return_time / 1000),
              return_time_end: Math.floor(formData?.return_time_end / 1000),
              return_visit: '#',
              clockout_option: 'Return visit required',
              return_reason: returnReason
            }
          : {
              ...checkOutLogData,
              return_visit: selectedReturnVisitOption,
              clockout_option: 'Return visit required'
            }

        setMessage(t('work_orders.upload_messages.clock_out'))
        let checkOutLog = false
        if (existingLog.length > 0) {
          const newLog = await createLog(
            'checkOut',
            data?.id,
            type === 'iframe' ? externalUser : userData,
            existingLog[0],
            type === 'iframe'
          )
          data.logs = data?.logs?.map(log => {
            if (log.id === newLog.id) return newLog
            return log
          })
          checkOutLog =
            newLog.status === woLogStatus.complete &&
            newLog.type === woLogType.CHECKOUT
        } else {
          const response = await createLog(
            'checkOut',
            data?.id,
            type === 'iframe' ? externalUser : userData,
            logData,
            type === 'iframe'
          )
          data.logs.push(response)
          checkOutLog =
            response.status === woLogStatus.complete &&
            response.type === woLogType.CHECKOUT
        }
        data.status = woStatus.COMPLETED
        if (checkOutLog) {
          // Download WO with invoice
          const updatedWo = await getWoByIdWithAuth(
            type === 'iframe' || isRunningInIframe(),
            data.id
          )
          updateWoData(updatedWo)
          if (type === 'iframe') {
            setFinished(true)
          }
          setReady(true)
          setMessage(null)
        }
      }
    } catch (error) {
      console.error('ERROR: ', error)
    }
  }

  const handleBack = type => {
    switch (type) {
      case workorderCompletionType.RETURN_VISIT_2:
        return () => {
          setTypeKey(workorderCompletionType.RETURN_VISIT)
        }
      case workorderCompletionType.RETURN_VISIT_CONFIRMATION:
        return () => {
          setTypeKey(workorderCompletionType.RETURN_VISIT_2)
        }
    }
  }

  const getDialogSubmit = type => {
    switch (type) {
      case workorderCompletionType.COMPLETED:
        return handleComplete
      case workorderCompletionType.CONFIRMATION:
        if (onDemandWO || nonProjectWO) {
          return handleSubmit
        } else {
          return handleConfirmationSubmit // project
        }
      case workorderCompletionType.RETURN_VISIT:
        return () => {
          if (hasCalendar) {
            setTypeKey(workorderCompletionType.RETURN_VISIT_2)
          } else {
            handleOnDemandReturnVisitConfirmation()
          }
        }
      case workorderCompletionType.RETURN_VISIT_2:
        return () => {
          setTypeKey(workorderCompletionType.RETURN_VISIT_CONFIRMATION)
        }
      case workorderCompletionType.RETURN_VISIT_CONFIRMATION:
        if (onDemandWO || nonProjectWO) {
          return handleOnDemandReturnVisitConfirmation
        } else {
          return handleReturnVisitConfirmation
        }
    }
  }

  const handleSubmitConstructionForm = () => {
    if (formData.line_items?.every(({ status }) => status.progress === '100')) {
      setTypeKey(workorderCompletionType.COMPLETED)
    } else {
      setTypeKey(workorderCompletionType.RETURN_VISIT)
    }
    setOpen(true)
  }

  const handleEtaUpdate = eta => {
    // update ETA object
    const newEta = data?.est_service_start ? data?.est_service_start : {}
    newEta.time = eta
    newEta.updatedAt = moment().unix()
    newEta.confirmation = true
    // update WO data
    updateWoData({ ...data, est_service_start: newEta, updateEta: true })
  }

  const isSubmitDisabled = formData?.line_items?.some(
    ({ additional_photos, status }) => {
      return status.progress === '100' && !additional_photos
    }
  )

  const isCompleted = formData.status === 'completed'

  return (
    <Card
      className={classes.root}
      style={type ? { overflowY: 'auto', height: wHeight } : null}
    >
      <CardActions
        disableSpacing
        onClick={type !== 'iframe' ? handleExpandClick : null}
        className={classes.actions}
      >
        <Grid container style={{ height: '48px' }}>
          <Grid item md={12} className={classes.tripGrid}>
            <FormLabel component="legend" className={classes.trip}>
              {`${t('work_orders.service_short')} ${data?.trip || ''}`}
              <FormLabel classes={{ root: classes.chipRoot }}>
                {t(`work_orders.wo_states.${data?.status}`)}
              </FormLabel>
            </FormLabel>
            {type !== 'iframe' ? (
              <>
                {expanded ? (
                  <Grow in={expanded}>
                    <IconButton aria-expanded={expanded} aria-label="show more">
                      <ExpandLessIcon />
                    </IconButton>
                  </Grow>
                ) : (
                  <Grow in={!expanded}>
                    <IconButton
                      aria-expanded={!expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Grow>
                )}
              </>
            ) : (
              <IconButton disabled />
            )}
          </Grid>
        </Grid>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {
          <CardContent style={{ paddingBottom: '3px' }}>
            <FormLabel component="legend" classes={{ root: classes.field }}>
              {t('work_orders.trips.origin')}
            </FormLabel>
            <FormLabel component="legend" classes={{ root: classes.fieldData }}>
              {t('work_orders.trips.mobile') + ' '}
              <FontAwesomeIcon icon={['far', 'mobile-screen-button']} />
            </FormLabel>
            <EtaSelect
              data={data?.est_service_start ? data?.est_service_start : etaTime}
              disabled={getWOstatus(data) !== 'open'}
              woId={data.id}
              type={type}
              onUpdate={eta => handleEtaUpdate(eta)}
              maxDate={data?.scheduled_date}
            />

            <div>
              {/* Check In */}
              {data?.logs?.length > 0 &&
                data.logs.map(log => {
                  if (log.type === 'checkIn') {
                    return (
                      <div key={log.date_created}>
                        <FormLabel
                          component="legend"
                          classes={{ root: classes.field }}
                        >
                          {t('work_orders.trips.checkin')}
                        </FormLabel>
                        <FormLabel
                          component="legend"
                          classes={{ root: classes.fieldData }}
                        >
                          {moment(new Date(log.date_created * 1000)).format(
                            t('general.format.short_date_time')
                          )}
                        </FormLabel>
                      </div>
                    )
                  }
                  return null
                })}
              {/* Check Out */}
              {data?.logs?.length > 0 &&
                data.logs.map(log => {
                  if (log.type === 'checkOut') {
                    return (
                      <div key={log.date_created}>
                        <FormLabel
                          component="legend"
                          classes={{ root: classes.field }}
                        >
                          {t('work_orders.trips.checkout')}
                        </FormLabel>
                        <FormLabel
                          component="legend"
                          classes={{
                            root:
                              log.status !== 'incomplete'
                                ? classes.fieldData
                                : classes.fieldMessage
                          }}
                        >
                          {log.status !== 'incomplete'
                            ? moment(new Date(log.date_created * 1000)).format(
                                t('general.format.short_date_time')
                              )
                            : t('work_orders.checkout_message')}
                        </FormLabel>
                      </div>
                    )
                  }
                  return null
                })}
            </div>
            {data?.logs?.length === 0 && noCheckInComp()}
            <Box className={classes.serviceType}>
              <Divider className={classes.divider} />
              {data?.line_items && data?.scheduled === false ? (
                <div>
                  <FormLabel className={classes.serviceTypeTitle}>
                    Scope Of Work
                  </FormLabel>
                </div>
              ) : (
                <FormLabel className={classes.serviceTypeTitle}>
                  {t('work_orders.field_service')}
                </FormLabel>
              )}
              <Divider className={classes.divider} />
            </Box>
            {!screenConfigId && !hasConfigV2
              ? data?.service &&
                data.service.map((service, index) => (
                  <RepairData
                    disabled={
                      woFixedStatus.includes(data.status) ||
                      getWOstatus(data) === 'expired'
                    }
                    configRequired={configRequired}
                    key={service.id}
                    woData={data}
                    repairData={service ?? {}}
                    configs={configs}
                    woServices={data.services ?? []}
                    setPhotos={setPhotos}
                    setPhotoIndex={setPhotoIndex}
                    serviceTypeOptions={
                      type === 'iframe'
                        ? externalUser.service_types
                        : serviceTypeOptions
                    }
                    onUpdate={repair => {
                      service = repair
                      _setPendingTasks()
                    }}
                    externalUser={externalUser}
                    expanded={index === 0}
                  />
                ))
              : hasConfigV2 &&
                data?.service &&
                data.service.map(service => (
                  <div key={0}>
                    <FieldServiceForm
                      key={service.id}
                      repairData={service ?? {}}
                      config={configs}
                      readOnly={true}
                      woData={data}
                      externalUser={externalUser}
                      onUpdate={repair => {
                        service = repair
                      }}
                      setPhotos={setPhotos}
                      setPhotoIndex={setPhotoIndex}
                    />
                  </div>
                ))}
            {formData?.line_items && formData?.scheduled === false ? (
              <LineItems
                lineItems={formData?.line_items}
                {...{ formData, isCompleted, setFormData, handleOpenPhotos }}
              />
            ) : (
              (!data?.service || data?.service.length === 0) &&
              (!screenConfigId && !hasConfigV2 ? (
                <RepairData
                  disabled={
                    woFixedStatus.includes(data.status) ||
                    getWOstatus(data) === 'expired'
                  }
                  configRequired={configRequired}
                  key={0}
                  woData={data}
                  repairData={newRepair}
                  configs={configs}
                  woServices={data.services ?? []}
                  setPhotos={setPhotos}
                  setPhotoIndex={setPhotoIndex}
                  serviceTypeOptions={
                    type === 'iframe'
                      ? externalUser.service_types
                      : serviceTypeOptions
                  }
                  onUpdate={setNewRepair}
                  externalUser={externalUser}
                  expanded={true}
                />
              ) : (
                configs && (
                  <div key={0}>
                    <FieldServiceForm
                      key={0}
                      config={configs}
                      readOnly={
                        woFixedStatus.includes(data.status) ||
                        getWOstatus(data) === 'expired'
                      }
                      repairData={newRepair}
                      onUpdate={setNewRepair}
                      woData={data}
                      externalUser={externalUser}
                      setPhotos={setPhotos}
                      setPhotoIndex={setPhotoIndex}
                      setMandatoryComplete={setMandatoryComplete}
                    />
                  </div>
                )
              ))
            )}
            {!(
              woFixedStatus.includes(data.status) ||
              getWOstatus(data) === 'expired'
            ) && (
              <Grid item xs={12} className={classes.errorContainer}>
                {pendingTasks > 0 && (
                  <Grid item xs={12} className={classes.errorBox}>
                    <FormLabel className={classes.error}>
                      {t('work_orders.trips.fields_required')}
                    </FormLabel>
                  </Grid>
                )}
                <ThemeProvider theme={buttonSettingsDisabled}>
                  {formData.line_items?.length > 0 &&
                  !formData.scheduled &&
                  (userData?.parent_subdomain === 'mcs360' ||
                    externalUser?.parent_subdomain === 'mcs360' ||
                    externalUser?.parent_subdomain === 'mcssfr' ||
                    userData?.parent_subdomain === 'mcssfr') ? (
                    <Button
                      size="medium"
                      disabled={isSubmitDisabled}
                      style={
                        isSubmitDisabled
                          ? disableButtonWOStyle
                          : enableButtonWOStyle
                      }
                      onClick={handleSubmitConstructionForm}
                    >
                      {t('general.labels.submit')}
                    </Button>
                  ) : (
                    <Button
                      size="medium"
                      disabled={
                        !hasBypassValidation &&
                        ((hasConfigV2
                          ? !mandatoryComplete
                          : pendingTasks > 0 || infoSubmitted) ||
                          disableSubmit || submitClicked)
                      }
                      style={
                        !hasBypassValidation &&
                        (pendingTasks > 0 ||
                          (hasConfigV2 && !mandatoryComplete))
                          ? disableButtonWOStyle
                          : enableButtonWOStyle
                      }
                      onClick={async () => {
                        setInfoSubmitted(true)
                        !completeDailogValue
                          ? await handleSubmit()
                          : handleMCSSubmit()
                      }}
                    >
                      {t('general.labels.submit')}
                    </Button>
                  )}
                </ThemeProvider>
              </Grid>
            )}
          </CardContent>
        }
      </Collapse>
      <ConfirmDialog
        open={open}
        setOpen={setOpen}
        title={getDialogTitle(typeKey)}
        message={getDialogMessage(typeKey)}
        cancellabel={getCancelLabel(typeKey)}
        acceptlabel={isCompleteFormType ? null : getAcceptLabel(typeKey)}
        handleAccept={getDialogSubmit(typeKey)}
        handleCancel={handleBack(typeKey)}
        key={confirmDailogKey}
        customClasses={customClasses}
      />
    </Card>
  )
}
