import { Box, FormLabel, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import GlobalChip from '../../form/Chip'

const useStyles = makeStyles(theme => ({
  multiButtonContainer: {
    marginBottom: '30px'
  },
  fieldTitle: {
    marginBottom: '5px',
    marginLeft: '5px',
    color: theme.colors.text,
    fontSize: '12px',
    fontWeight: '700'
  },
  mandatory: {
    color: theme.colors.errorColor
  }
}))

export default function MultiButtonSelect(props) {
  const {
    options,
    readOnly,
    mandatory,
    question,
    answer,
    singleSelection,
    section,
    questionId,
    onUpdate
  } = props
  const classes = useStyles()
  const [itemsSelected, setItemsSelected] = useState(new Set())

  useEffect(() => {
    // initialize selected buttons based on answer
    if (!answer) return
    const selectedButtons = options.filter((item, index) => answer[index])
    setItemsSelected(new Set(selectedButtons))
  }, [])

  useEffect(() => {
    const selectedButtons = options.map(item => itemsSelected.has(item))
    onUpdate(section, questionId, selectedButtons)
  }, [itemsSelected])

  return (
    <Box className={classes.multiButtonContainer}>
      <FormLabel
        component="legend"
        className={`${classes.fieldTitle} ${
          !readOnly && mandatory ? classes.mandatory : ''
        }`}
      >
        {question.title}
      </FormLabel>
        <GlobalChip
          chips={options}
          selected={itemsSelected}
          setSelected={setItemsSelected}
          skipTranslate
          disabled={readOnly}
          allowNoSelection
          singleSelection={singleSelection}
        ></GlobalChip>
    </Box>
  )
}
