import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// mui components
import { makeStyles, Container, Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  statusContainer: {
    fontSize: '14px',
    fontWeight: '600',
    display: 'flex',
    justifyContent: 'center'
  }
}))

function completeIcon() {
  return <FontAwesomeIcon icon="fa-solid fa-check" />
}

function InvoiceStatusComponent(props) {
  const { currentStatus } = props
  const classes = useStyles()

  return (
    <Container classes={{ root: classes.statusContainer }}>
      {currentStatus?.map(row => (
        <Box key={row.label} classes={row.style}>
          {row.label} {row.complete ? completeIcon() : ''}
        </Box>
      ))}
    </Container>
  )
}
export const InvoiceStatus = props => {
  const { currentStatus } = props
  return (
    <div>
      <InvoiceStatusComponent currentStatus={currentStatus} />
    </div>
  )
}
