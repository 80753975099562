import React from 'react'
import LineItemInput from './LineItemInput'
import { Box, Divider, Grid, IconButton } from '@mui/material'

import { makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  singleLineItemContainer: {
    marginBottom: '20px'
  },
  dividerContainer: {
    width: '1px',
    justifyContent: 'center',
    display: 'flex'
  },
  divider: {
    backgroundColor: theme.colors.grey,
    width: '0.2px'
  },
  iconStyle: {
    fontSize: '14px',
    color: theme.colors.grey
  },
  description: {
    '& .MuiInputBase-root': {
      height: '50px', // Change height here
      backgroundColor: theme.colors.grey_2, // Change background color here
      fontSize: '14px'
    }
  },
  titleSpace: {
    height: '10px'
  }
}))

function SingleLineItem({
  item,
  index,
  onChange,
  onRemove,
  disabled,
  hideRightAction
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const RightAction = () => {
    if (!hideRightAction) {
      return (
        <IconButton
          aria-label="delete"
          onClick={() => onRemove(index)}
          disabled={disabled}
        >
          <FontAwesomeIcon
            icon="fa-sharp fa-regular fa-circle-xmark"
            className={classes.iconStyle}
          />
        </IconButton>
      )
    }
    return null
  }
  return (
    <Box className={classes.singleLineItemContainer}>
      <LineItemInput
        hideRightAction={hideRightAction}
        required={true}
        placeholder={t('work_orders.line_item.enter_name')}
        label={t('work_orders.line_item.item_number_name', {
          number: index + 1
        })}
        onChange={e => onChange(index, 'name', e.target.value)}
        value={item.name}
        disabled={disabled}
        rightAction={!disabled && <RightAction />}
      />
      <LineItemInput
        textAreaAutosize={true}
        customClasses={classes.description}
        multiline={true}
        disabled={disabled}
        placeholder={t('work_orders.line_item.enter_description')}
        label={t('work_orders.line_item.item_description')}
        onChange={e => onChange(index, 'description', e.target.value)}
        value={item.description}
      />
      <Grid container>
        <Grid item xs={7}>
          <LineItemInput
            disabled={disabled}
            placeholder={`${t('work_orders.line_item.item')} #`}
            label={`${t('work_orders.line_item.item')} #`}
            onChange={e => onChange(index, 'number', e.target.value)}
            value={item.number}
          />
        </Grid>

        <Grid item xs={1} className={classes.dividerContainer}>
          <Divider orientation="vertical" className={classes.divider} />
        </Grid>
        <Grid item xs={4}>
          <LineItemInput
            disabled={disabled}
            required={true}
            placeholder={t('work_orders.line_item.quantity')}
            label={t('work_orders.line_item.qty')}
            onChange={e => onChange(index, 'quantity', e.target.value)}
            value={item.quantity}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default SingleLineItem
